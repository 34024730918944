import queryString from 'query-string';

import { getHeadersFromLocalStorage } from './auth';

export const navigateByReturnToUrl = () => {
  const params = queryString.parse(window.location.search);

  if (params.return_to_url) {
    const [host, query] = (params.return_to_url as string).split('?');
    const modifiedQueryString = queryString.stringify({
      ...queryString.parse(query),
      ...getHeadersFromLocalStorage(),
    });

    window.location.href = `${host}?${modifiedQueryString}`;
  }
};
