import React from 'react';

import { Grid, Divider } from '@material-ui/core';

import { getTWithPathKey } from 'utils';
import { InfoTooltip, TabPanelItem, TabsComponent } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { PlanogramObjectData } from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/helper';
import InternalObjectSelector from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/InternalObjectSelector';
import { ControlButtonNavigationType } from 'types/enums';

import { Typography, Span, Box, Checkbox } from 'components/LinkBehavior/style';

const tabs: { value: ControlButtonNavigationType; label: string }[] = [
  { value: ControlButtonNavigationType.INTERNAL, label: 'Internal' },
  { value: ControlButtonNavigationType.EXTERNAL, label: 'External' },
  { value: ControlButtonNavigationType.BACK, label: 'Back' },
];

interface LinkBehaviorProps {
  handleChange: (value: any) => void;
  navigationType: ControlButtonNavigationType;
  navigationValueSelect?: string;
  navigationValueUrl?: string;
  checkedOpenInNewPage: boolean;
  withoutNavigation: boolean;
  planogramObjectData: PlanogramObjectData[];
}

const ControlButtonLinkBehaviour = ({
  handleChange,
  navigationType,
  navigationValueSelect,
  navigationValueUrl,
  checkedOpenInNewPage,
  withoutNavigation,
  planogramObjectData,
}: LinkBehaviorProps) => {
  const activeTab = tabs.findIndex(tab => tab.value === navigationType);
  const getT = getTWithPathKey('components.linkBehavior');

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box>
          <Divider />
        </Box>
        <Box mb={3}>
          <Box mb={2}>
            <Span>
              Choose behavior
              <InfoTooltip tooltip="You can set up the action for the Control Button" />
            </Span>
          </Box>
          <TabsComponent
            value={activeTab}
            setValue={key => handleChange({ navigationType: tabs[key].value })}
            tabNames={tabs.map(tab => tab.label)}
          />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <TabPanelItem value={activeTab} index={0}>
          <Typography variant="caption" display="block">
            Internal navigation between the spheres.
          </Typography>
          <Box pb="10px">
            {activeTab === 0 && (
              <InternalObjectSelector
                planogramObjectData={planogramObjectData}
                value={navigationValueSelect}
                useNavigation={withoutNavigation}
                handleChange={handleChange}
              />
            )}
          </Box>
        </TabPanelItem>
        <TabPanelItem value={activeTab} index={1}>
          <Typography variant="caption" display="block">
            The Control Button will use this link to redirect to defined URL.
          </Typography>
          <TextValidatorComponent
            label="Enter a complete url with http(s)://"
            value={navigationValueUrl ?? ''}
            fieldName="navigationValueUrl"
            handleChange={handleChange}
            validators={activeTab === 1 ? [VALIDATORS_MAP.required] : []}
          />
        </TabPanelItem>

        <TabPanelItem value={activeTab} index={2}>
          <Typography variant="caption" display="block">
            {getT('backActionDescription')}
          </Typography>
        </TabPanelItem>

        {navigationType !== 'back' && (
          <Grid item>
            <Checkbox
              color="primary"
              checked={checkedOpenInNewPage}
              onChange={e => handleChange({ openInNewPage: e.target.checked })}
            />
            <Typography variant="caption" display="inline">
              Open link in a new tab
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ControlButtonLinkBehaviour;
