import { styled, Typography as MuiTypography, Box as MuiBox, Checkbox as MuiCheckbox } from '@material-ui/core';

export const Span = styled('span')(({ theme }) => ({
  ...theme.typography.h4,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

export const Typography = styled(MuiTypography)({
  marginBottom: '8px',
  marginLeft: '12px',
});

export const Box = styled(MuiBox)({
  marginTop: '24px',
  marginBottom: '8px',
});

export const Checkbox = styled(MuiCheckbox)({
  padding: 0,
});
