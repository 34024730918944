export const ANIMATION_SETTINGS = {
  center: {
    title: 'Center',
    value: 'center',
  },
  top: {
    title: 'Top',
    value: 'top',
  },
  bottom: {
    title: 'Bottom',
    value: 'bottom',
  }
}
