import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, FaviconPreview, ImageChooseComponent, SettingsForm } from 'components';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { useSubmittingState } from 'hooks';
import { faviconsModel, planogramsModel } from 'models';

import { Typography } from '../style';
import { Box } from './style';
import { ImageUploadCaption } from 'components/style';

export function FaviconSettings({ planogramVersion, planogramId, handleServerError, setSuccessActionMessages }) {
  const [favicon, setFavicon] = useState({});
  const [savedFavicon, setSavedFavicon] = useState({});

  useEffect(() => {
    setFavicon(planogramVersion.favicon);
    setSavedFavicon(planogramVersion.favicon);
  }, [planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleSave = withHandlingSubmittingState(() =>
    planogramsModel
      .editVersion(planogramId, planogramVersion.id, { faviconId: favicon ? favicon.id : null })
      .then(data => {
        setSuccessActionMessages(data.success);
        setSavedFavicon(data.planogramVersion.favicon);
      })
      .catch(handleServerError),
  );

  const isSaveButtonVisible = !isSubmitting && !isEqual(favicon, savedFavicon);

  const getFavicons = params => faviconsModel.getFavicons(params).catch(handleServerError);

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={handleSave}>
      <CardComponent title="Favicon" xs={9} alignItems="center" isLoading={isSubmitting}>
        <Grid container spacing={6}>
          <Grid item sm={4} style={{ minWidth: '150px' }}>
            <ImageChooseComponent
              image={favicon}
              setImage={setFavicon}
              handleServerError={handleServerError}
              dialogTitle="Choose favicon"
              getPictures={getFavicons}
            />
          </Grid>
          <Grid item sm={8}>
            <ImageUploadCaption>{IMAGE_UPLOAD_CAPTION.favicon}</ImageUploadCaption>
          </Grid>
          <Grid item sm={4} style={{ minWidth: '150px' }}>
            <FaviconPreview iconUrl={favicon?.url} />
          </Grid>
          <Grid item sm={8}>
            <Box>
              <Typography variant="caption" className="information">
                The light version of the browser.
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={4} style={{ minWidth: '150px' }}>
            <FaviconPreview iconUrl={favicon?.url} version="dark" />
          </Grid>
          <Grid item sm={8}>
            <Box>
              <Typography variant="caption" className="information">
                Dark version of the browser.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardComponent>
    </SettingsForm>
  );
}

FaviconSettings.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  planogramId: PropTypes.string.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
