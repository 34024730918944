import { Link as MuiLink, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLORS } from 'theme/constants';

export const Link = withStyles(theme => ({
  root: {
    '&:hover': { textDecoration: 'none' },
    '&.blue': { color: COLORS.cozmosBlue },
    '&.blueUnderline': {
      color: COLORS.cozmosBlue,
      textDecoration: 'underline',
    },
    '&.sign-in-okta': {
      display: 'block',
      width: '100%',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      fontWeight: 600,
      padding: '6.5px 0',
      borderRadius: '4px',
      textAlign: 'center',
    },
  },
}))(MuiLink);

export const LinkButton = withStyles({
  root: {
    cursor: 'pointer',
    color: COLORS.cozmosBlue,

    '&.underline': { textDecoration: 'underline' },
  },
})(Box);
