import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import {
  AdminActionResponse,
  AdminAsUserResponse,
  AdminItemResponse,
  AdminsByClientParams,
  AdminsListResponse,
  CreateAdminParams,
  EditAdminParams,
  GetAdminsParams,
  PaginationParams,
  UserAttributes,
} from 'types';
import { setHeadersToLocalStorage, trimItems } from 'utils';

import { api } from './setupApi';

class AdminsModel {
  link = '/admins';

  clientAdminsLink = '/client_admins_list';

  systemAdminsLink = '/system_admins_list';

  currentAdminLink = '/current_admin_info';

  clientModeratorsLink = '/client_moderators_list';

  getAdmins = (params: GetAdminsParams): Promise<AdminsListResponse> =>
    api.get<AdminsListResponse>(this.link, { params }).then(({ data }) => data);

  getClientModerators = (params: PaginationParams): Promise<AdminsListResponse> =>
    api.get<AdminsListResponse>(`${this.link}/${this.clientModeratorsLink}`, { params }).then(({ data }) => data);

  getClientAdmins = (params: PaginationParams): Promise<AdminsListResponse> =>
    api.get<AdminsListResponse>(`${this.link}${this.clientAdminsLink}`, { params }).then(({ data }) => data);

  getSystemAdmins = (params: PaginationParams): Promise<AdminsListResponse> =>
    api.get<AdminsListResponse>(`${this.link}${this.systemAdminsLink}`, { params }).then(({ data }) => data);

  getAdminsByClient = (params: AdminsByClientParams): Promise<AdminsListResponse> =>
    api.get<AdminsListResponse>(this.link, { params }).then(({ data }) => data);

  getAdmin = (id: number): Promise<AdminItemResponse> =>
    api.get<AdminItemResponse>(`${this.link}/${id}`).then(({ data }) => data);

  switchAdmin = (id: number): Promise<UserAttributes> =>
    api.post<AdminAsUserResponse>(`${this.link}/login_as_user`, { id }).then(({ data }) => {
      setHeadersToLocalStorage(get(data, 'authToken'));

      return data.admin;
    });

  getCurrentAdmin = (): Promise<AdminItemResponse> =>
    api.get<AdminItemResponse>(`${this.link}${this.currentAdminLink}`).then(({ data }) => data);

  addAdmin = (data: CreateAdminParams): Promise<AxiosResponse<AdminActionResponse>> =>
    api.post(this.link, trimItems(data, ['firstName', 'lastName']));

  editAdmin = ({ id, ...data }: EditAdminParams): Promise<AdminActionResponse> =>
    api
      .put<AdminActionResponse>(`${this.link}/${id}`, trimItems(data, ['firstName', 'lastName']))
      .then(({ data }) => data);

  blockAdmin = (id: number, enabled: boolean): Promise<AxiosResponse<AdminActionResponse>> =>
    api.put(`${this.link}/${id}`, { enabled });

  deleteAdmin = (id: number) => api.delete(`${this.link}/${id}`);

  deleteOwnedClient = () => api.delete(`${this.link}/delete_owned_client`);
}

export const adminsModel = new AdminsModel();
