import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { ThemeMode } from 'types/enums';

import { COLORS, GRADIENTS } from './constants';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    mode: ThemeMode;
    sidebar: {
      background: string;
      text: string;
      activeText: string;
      activeBackground: string;
      headerText: string;
      hoverBackground: string;
      links: string;
      switchBackground: string;
    };
    auth: {
      background: string;
      formBackground: string;
      formText: string;
      formLink: string;
      divider: string;
      link: string;
      formButtonBackground: string;
    };
  }
  interface PaletteOptions {
    mode: ThemeMode;
    sidebar: {
      background: string;
      text: string;
      activeText: string;
      headerText: string;
      hoverBackground: string;
      activeBackground: string;
      links: string;
      switchBackground: string;
    };
    auth: {
      background: string;
      formBackground: string;
      formText: string;
      formLink: string;
      divider: string;
      link: string;
      formButtonBackground: string;
    };
  }
}

const globalPalette: Partial<PaletteOptions> = {
  primary: {
    main: COLORS.cozmosBlue,
    contrastText: COLORS.white,
  },
  secondary: {
    main: COLORS.cozmosBlue,
    contrastText: COLORS.white,
  },
};

export const lightModePalette: PaletteOptions = {
  ...globalPalette,
  mode: ThemeMode.LIGHT,
  sidebar: {
    background: COLORS.white,
    text: COLORS.cozmosPurple4,
    activeText: COLORS.white,
    headerText: COLORS.cozmosPurple4,
    activeBackground: COLORS.cozmosBlue,
    hoverBackground: COLORS.cozmosBlueLight,
    links: COLORS.cozmosBlue,
    switchBackground: COLORS.cozmosBlue,
  },
  auth: {
    background: COLORS.background,
    formBackground: COLORS.white,
    formText: COLORS.cozmosPurple4,
    formLink: COLORS.cozmosBlue,
    divider: COLORS.cozmosPurpleLight,
    link: COLORS.cozmosBlue,
    formButtonBackground: COLORS.cozmosBlue,
  },
};

export const darkModePalette: PaletteOptions = {
  ...globalPalette,
  mode: ThemeMode.DARK,
  sidebar: {
    background: COLORS.cozmosPurple4,
    text: COLORS.white,
    activeText: COLORS.white,
    headerText: COLORS.cozmosPurple2,
    activeBackground: GRADIENTS.cozmosGradient,
    hoverBackground: COLORS.cozmosBlueLight,
    links: COLORS.white,
    switchBackground: COLORS.cozmosPurple3,
  },
  auth: {
    background: COLORS.cozmosPurple4,
    formBackground: COLORS.cozmosPurple3,
    formText: COLORS.white,
    formLink: COLORS.white,
    divider: COLORS.cozmosPurple4,
    link: COLORS.white,
    formButtonBackground: COLORS.cozmosBlue,
  },
};
