import React, { useState, useEffect, useRef } from 'react';

import { Grid, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

import { errorResponseModel } from 'models';
import { getTWithPathKey } from 'utils';

import { AutocompleteValidatorComponent, TextValidatorComponent } from '../FormComponents';
import { VALIDATORS_MAP } from '../FormComponents/const';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import { TabPanelItem } from '../TabsComponent/TabPanelItem/TabPanelItem';
import { TabsComponent } from '../TabsComponent/TabsComponent';

import { NAVIGATION_TYPE } from './constants';

import { Typography, Span, Box, Checkbox } from './style';

export function LinkBehavior({
  handleServerError,
  handleChange,
  navigationType,
  navigationValueSelect,
  navigationValueUrl,
  checkedOpenInNewPage,
  setOpenInNewPage,
  name,
  availableNavigationTypes,
  tabNames,
}) {
  const [tabValue, setTabValue] = useState(0);
  const mounted = useRef(true);
  const [spheresList, setSpheresList] = useState([]);
  const getT = getTWithPathKey('components.linkBehavior');

  useEffect(() => {
    getPlanogramsList();

    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    setTabValue(availableNavigationTypes.indexOf(navigationType));
  }, [navigationType]);

  const getPlanogramsList = () =>
    errorResponseModel
      .getHostErrorResponses()
      .then(({ planograms }) => mounted.current && setSpheresList(planograms))
      .catch(error => handleServerError(error));

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box>
          <Divider />
        </Box>
        <Box mb={3}>
          <Box mb={2}>
            <Span>
              Choose behavior
              <InfoTooltip tooltip={`You can set up the action for the ${name}`} />
            </Span>
          </Box>
          <TabsComponent
            value={tabValue}
            setValue={key => handleChange({ navigationType: availableNavigationTypes[key] })}
            tabNames={tabNames}
          />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <TabPanelItem value={tabValue} index={0}>
          <Typography variant="caption" display="block">
            Internal navigation between the spheres.
          </Typography>
          <AutocompleteValidatorComponent
            value={Number(navigationValueSelect) || ''}
            fieldName="navigationValueSelect"
            handleChange={handleChange}
            selectList={spheresList}
            itemsNamePath="urlPath"
            label="Choose sphere"
            validators={tabValue === 0 ? [VALIDATORS_MAP.required] : []}
          />
        </TabPanelItem>
        <TabPanelItem value={tabValue} index={1}>
          <Typography variant="caption" display="block">
            {`The ${name} will use this link to redirect to defined URL.`}
          </Typography>
          <TextValidatorComponent
            label="Enter a complete url with http(s)://"
            value={navigationValueUrl || ''}
            fieldName="navigationValueUrl"
            handleChange={handleChange}
            validators={tabValue === 1 ? [VALIDATORS_MAP.required] : []}
          />
        </TabPanelItem>

        <TabPanelItem value={tabValue} index={2}>
          <Typography variant="caption" display="block">
            {getT('backActionDescription')}
          </Typography>
        </TabPanelItem>

        {navigationType !== NAVIGATION_TYPE.back && (
          <Grid item>
            <Checkbox color="primary" checked={checkedOpenInNewPage} onChange={setOpenInNewPage} />
            <Typography variant="caption" display="inline">
              Open link in a new tab
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

LinkBehavior.propTypes = {
  handleServerError: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  navigationType: PropTypes.oneOf(Object.values(NAVIGATION_TYPE)).isRequired,
  navigationValueSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  navigationValueUrl: PropTypes.string,
  checkedOpenInNewPage: PropTypes.bool.isRequired,
  setOpenInNewPage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  availableNavigationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LinkBehavior.defaultProps = {
  navigationValueSelect: null,
  navigationValueUrl: null,
};
