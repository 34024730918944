import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '../style';

export function TabPanelItem({ children, value, index, ...other }) {
  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {children}
    </Box>
  );
}

TabPanelItem.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

TabPanelItem.defaultProps = {
  children: null,
};
