export const PLANOGRAM_VERSION_STATUS = {
  published: 'published',
  publishing: 'publishing',
  generated: 'generated',
  generating: 'generating',
  draft: 'draft',
  drafting: 'drafting',
  errorGenerating: 'error_generating',
  publishedDrafted: 'published_drafted',
};

export const PLANOGRAM_ACTIONS = {
  stopGenerating: 'stop_generating',
};

export const DRAFTED_HISTORY_ITEMS_COUNT = 3;
