import { Avatar as MuiAvatar, InputBase, styled, Typography as MuiTypography } from '@material-ui/core';
import { darken } from 'polished';

import { TableComponent } from 'components';
import { COLORS } from 'theme/constants';

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  marginRight: `${theme.spacing(2)}px`,
}));

export const BigAvatar = styled(Avatar)({
  width: '50px',
  height: '50px',
  paddingRight: '0px',
  paddingLeft: '0px',
});

export const Search = styled('div')(({ theme }) => ({
  borderRadius: '2px',
  backgroundColor: COLORS.white,
  display: 'none',
  position: 'relative',
  width: '100%',

  '&:hover': {
    backgroundColor: darken(0.05, COLORS.white),
  },

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const SearchIconWrapper = styled('div')({
  width: '50px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    width: '22px',
    height: '22px',
  },
});

export const Typography = styled(MuiTypography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const FiltersWrap = styled('div')({
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  '& .filters-block': {
    padding: '0 10px',
  },
});

export const ProductsTable = styled(TableComponent)({
  '& > tbody > tr > td:first-child': {
    width: '1%',
    '& button': {
      display: 'none',
    },
  },
});
