import { AudioGetParams, AudioResponse, CreateAudio, EditAudio } from 'types/audio';

import { api } from './setupApi';

class AudioModel {
  prefix = '/audios';

  getAudio = (params?: AudioGetParams) => api.get<AudioResponse>(`${this.prefix}`, { params }).then(r => r.data);

  createAudio = (data: CreateAudio) => api.post(`${this.prefix}`, data);

  updateAudio = (id: number, data: EditAudio) => api.put(`${this.prefix}/${id}`, data);

  deleteAudio = (id: number) => api.delete(`${this.prefix}/${id}`);
}

export const audioModel = new AudioModel();
