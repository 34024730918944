import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { DefaultLandingMessage, TableComponent } from 'components';
import { LINKS } from 'components/constants';
import { ConfirmationDialog, NewTag } from 'components/Dialogs';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { useBusyProgressActions, useBusyProgressState } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { tagsModel } from 'models';
import { Tag } from 'types';
import { ActionType, OrderByParameter } from 'types/enums';
import { TableParams } from 'types/other';
import TEST_IDS from 'constants/testIds';

function ManageTags() {
  const { t } = useTranslation();
  const { isBusy } = useBusyProgressState();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError, resetServerErrors } = useServerErrorActions();

  const [tags, setTags] = useState<Tag[]>([]);
  const [tagToEdit, setTagToEdit] = useState<Tag | {}>();
  const [tagToDelete, setTagToDelete] = useState<Tag>();
  const [initialLoad, setInitialLoad] = useState(true);
  const [tableParams, setTableParams] = useState<TableParams>({ ...DEFAULT_TABLE_PARAMS });
  const [paginationItemsCount, setPaginationItemsCount] = useState(0);

  useEffect(() => {
    if (initialLoad) {
      withPageProgressHandler(getData)();
      setInitialLoad(false);
    } else getData();
  }, [tableParams]);

  const getData = () => {
    const { filterId, search, ...rest } = tableParams;

    return tagsModel
      .getTagsWithParams({
        ...rest,
        name: search,
      })
      .then(data => {
        setTags(data.tags);
        setPaginationItemsCount(data.tagsCount);
      })
      .catch(handleServerError);
  };

  const handleDelete = withPageProgressHandler(({ id }) => {
    setTagToDelete(undefined);
    return tagsModel.deleteTag(id).then(getData).catch(handleServerError);
  });

  const afterAction = (edited: boolean) => {
    if (edited) getData();
    else setTableParams({ ...tableParams, page: 0 });

    setTagToEdit(undefined);
    resetServerErrors();
  };

  const tableData = useMemo(
    () => ({
      headers: [
        { title: t('components.tableComponent.headers.tagName'), sortKey: OrderByParameter.NAME },
        { title: t('components.tableComponent.headers.itemsCount'), sortKey: OrderByParameter.PICTURES_COUNT },
        { title: t('components.tableComponent.headers.creator'), sortKey: OrderByParameter.ADMIN_NAME },
        { title: t('components.tableComponent.headers.updated'), sortKey: OrderByParameter.UPDATED_AT },
      ],
      rows: tags.map(tag => ({
        id: tag.id,
        cells: [tag.name, tag.picturesCount, tag.adminName, tag.updatedAt],
        onClick: () => setTagToEdit(tag),
        actions: [
          { type: ActionType.EDIT, onClick: () => setTagToEdit(tag), testId: TEST_IDS.manageTags.list.buttons.edit },
          {
            type: ActionType.DELETE,
            onClick: () => setTagToDelete(tag),
            testId: TEST_IDS.manageTags.list.buttons.edit,
          },
        ],
      })),
    }),
    [tags],
  );

  return (
    <PageWithHeader
      tabTitle={t('pages.tags.title')}
      headerText={`${t('pages.tags.title')}: ${paginationItemsCount}`}
      button={{
        text: t('pages.tags.addTag'),
        onClick: () => setTagToEdit({}),
      }}
    >
      <TableComponent
        data={tableData}
        count={paginationItemsCount}
        tableParams={tableParams}
        onParamsChange={params => setTableParams(prev => ({ ...prev, ...params }))}
        enableSearch
      />
      {!isBusy && paginationItemsCount === 0 && (
        <DefaultLandingMessage
          title={t('pages.tags.defaultLandingTitle')}
          body={t('pages.tags.defaultLandingMessage')}
          bodyLink={LINKS.sphereKnowledgeBase}
        />
      )}
      {tagToEdit && <NewTag tag={tagToEdit} name="" afterAction={afterAction} handleServerError={handleServerError} />}
      {tagToDelete && (
        <ConfirmationDialog
          open
          message={t('pages.tags.confirmDelete')}
          handleYes={() => handleDelete(tagToDelete)}
          handleNo={() => setTagToDelete(undefined)}
        />
      )}
    </PageWithHeader>
  );
}

export default ManageTags;
