import { Box as MuiBox, Typography as MuiTypography, styled } from '@material-ui/core';

export const Typography = styled(MuiTypography)({
  '& .bold': {
    fontWeight: 'bold',
  },
});

export const Box = styled(MuiBox)({
  marginTop: '28px',
  '& label': {
    fontWeight: 'bold',
  },
});
