import React from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { BackToList } from '../BackToList/BackToList';

import { Divider, GridTitleWrapper, GridChildrenWrapper, Typography } from './style';

export function PageHeader({ backLink, backLinkText, title, children }) {
  return (
    <>
      <Grid justify="space-between" container spacing={0}>
        <GridTitleWrapper item>
          {backLink && <BackToList link={backLink} name={backLinkText} />}
          <Typography variant="h1" gutterBottom display="inline">
            {title}
          </Typography>
        </GridTitleWrapper>
        <GridChildrenWrapper item>{children}</GridChildrenWrapper>
      </Grid>
      <Divider my={4} />
    </>
  );
}

PageHeader.propTypes = {
  backLink: PropTypes.string,
  backLinkText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]),
};
PageHeader.defaultProps = {
  backLink: '',
  backLinkText: undefined,
  title: '',
  children: null,
};
