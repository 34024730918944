import { Box as MuiBox, styled, Typography as MuiTypography, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
})(MuiBox);

export const Typography = styled(MuiTypography)({
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
});

export const Subtext = styled(MuiTypography)({
  fontSize: '14px',
  color: COLORS.black,
});
