import { PurchasingFlowSettingsType } from 'types';

import { api } from './setupApi';

class PurchasingModel {
  link = '/purchasing_flow_settings';

  getSettings = (): Promise<PurchasingFlowSettingsType> =>
    api.get<PurchasingFlowSettingsType>(this.link).then(({ data }) => data);

  saveSettings = (value: string, id: number) => api.put(`${this.link}/${id}`, { purchasing_flow: value });
}

export const purchasingModel = new PurchasingModel();
