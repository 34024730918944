import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { CardComponent, PageHeader, PageHeaderNav } from 'components';
import { AutocompleteValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { errorResponseModel } from 'models';
import { ROUTES } from 'routes/constants';
import { COLORS } from 'theme/constants';

import { Switch } from 'components/style';

export default function ErrorResponseSettings() {
  const history = useHistory();
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  const [planograms, setPlanograms] = useState([]);
  const [responsesConfigs, setResponsesConfigs] = useState([]);

  useEffect(() => {
    getHostErrorResponses();
  }, []);

  const getHostErrorResponses = withPageProgressHandler(() =>
    errorResponseModel
      .getHostErrorResponses()
      .then(({ hostErrorResponses, planograms }) => {
        setPlanograms(planograms);
        setResponsesConfigs(
          hostErrorResponses.map(response => ({
            ...response,
            enabled: response.planogramId !== null,
          })),
        );
      })
      .catch(handleServerError)
  );

  const handleSwitch = id => {
    setResponsesConfigs(
      responsesConfigs.map(config => (config.id === id ? { ...config, enabled: !config.enabled } : config)),
    );
  };

  const handleSelect = (id, planogramId) => {
    setResponsesConfigs(responsesConfigs.map(config => (config.id === id ? { ...config, planogramId } : config)));
  };

  const handleSave = () =>
    errorResponseModel
      .editHostErrorResponse({
        hostErrorResponses: responsesConfigs.map(config => ({
          ...config,
          planogramId: config.enabled ? config.planogramId : null,
        })),
      })
      .then(() => history.push(ROUTES.settings));

  return (
    <ValidatorForm onSubmit={handleSave}>
      <PageHeader title={t('pages.settings.errorResponseSettings.title')} backLink={ROUTES.settings}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      <Grid container spacing={6}>
        <Grid item sm={8} xs={12}>
          {responsesConfigs.map(({ id, enabled, host, planogramId }) => (
            <CardComponent
              key={id}
              sm={12}
              title={t('pages.settings.errorResponseSettings.title')}
              CardHeaderNav={() => <Switch checked={enabled} onChange={() => handleSwitch(id)} />}
            >
              <Typography variant="h4" style={{ marginBottom: '10px' }}>
                Domain: {host}
              </Typography>
              {enabled && (
                <div>
                  {t('pages.settings.errorResponseSettings.planogramSelectorHint')}
                  <span style={{ color: COLORS.cozmosBlue }}>
                    {t('pages.settings.errorResponseSettings.exampleLink')}
                  </span>
                  <span style={{ color: COLORS.red }}>
                    {t('pages.settings.errorResponseSettings.planogramSelectorHint1')}
                  </span>
                  <div />
                  <AutocompleteValidatorComponent
                    value={planogramId || ''}
                    validators={[VALIDATORS_MAP.required]}
                    fieldName="id"
                    handleChange={planogram => handleSelect(id, planogram.id)}
                    selectList={planograms}
                    itemsNamePath="urlPath"
                    label={t('pages.settings.errorResponseSettings.selectCapture')}
                  />
                  <span>{t('pages.settings.errorResponseSettings.planogramSelectorHint2')}</span>
                </div>
              )}
            </CardComponent>
          ))}
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
