import React, { CSSProperties, PropsWithChildren } from 'react';

import { FontItem } from 'types/fonts';
import { getFontFormat, getFontName, getFontFeatureStyle } from 'utils';

interface FontComponentProps {
  font?: FontItem;
  style?: CSSProperties;
}

export function CustomFontWrapper({ font, style = {}, children, ...props }: PropsWithChildren<FontComponentProps>) {
  const fontName = font ? getFontName(font.filename) : '';

  return font ? (
    <div
      style={{
        fontFamily: `"${fontName}"`,
        fontFeatureSettings: font.fontFeature ? font.fontFeature : undefined,
        ...style,
      }}
      {...props}
    >
      <style>
        {`@font-face {
        font-family: "${fontName}";
        src: url("${font.fileUrl}") format("${getFontFormat(font.filename)}")`}
      </style>
      {children}
    </div>
  ) : (
    <div style={{ ...style }} {...props}>
      {children}
    </div>
  );
}
