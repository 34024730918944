import { TextField as MuiTextField, withStyles } from '@material-ui/core';

export const TextField = withStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      ...theme.typography.caption,
    },
    '& .MuiFormLabel-root': {
      fontSize: 'unset',
    },
    '&.ml-4': { marginLeft: '24px' },
  },
}))(MuiTextField);
