import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { PageHeader, PageHeaderNav, OverlayColorsComponent, CardComponent, IFrameColorComponent } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { overlaysStylingModel } from 'models';
import { ROUTES } from 'routes/constants';
import { withSuccessActionsDialog } from 'utils/enhancers';

const STYLING_KEYS_TO_PICK = ['primaryColor', 'secondaryColor', 'iframePrimaryColor', 'iframeSecondaryColor'];

export const OverlaysSettings = ({ setSuccessActionMessages }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ colors: {}, overlaysStylingId: null });

  useEffect(() => {
    getOverlayStying();
  }, []);
  const history = useHistory();
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  const getOverlayStying = withPageProgressHandler(() =>
    overlaysStylingModel.getOverlaysStyling().then(stylings =>
      setState({
        colors: pick(stylings, STYLING_KEYS_TO_PICK),
        overlaysStylingId: stylings.id,
      })
    )
  );

  const onColorChange =
    name =>
    ({ hex }) =>
      setState({
        ...state,
        colors: { ...state.colors, [name]: hex },
      });

  const onSaveStylings = withPageProgressHandler(() =>
    overlaysStylingModel
      .updateOverlaysStyling(state.colors, state.overlaysStylingId)
      .then(data => setSuccessActionMessages(data.success))
      .catch(handleServerError),
  );

  return (
    <ValidatorForm onSubmit={onSaveStylings}>
      <PageHeader title={t('pages.settings.overlaysSettings.title')} backLink={ROUTES.settings}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      <Grid container>
        <CardComponent title="Overlays color style">
          <OverlayColorsComponent {...{ colors: state.colors, onColorChange }} />
          <IFrameColorComponent {...{
            primaryColorKey: 'iframePrimaryColor',
            primaryColorValue: state.colors.iframePrimaryColor,
            secondaryColorKey: 'iframeSecondaryColor',
            secondaryColorValue: state.colors.iframeSecondaryColor,
            onColorChange
          }} />
        </CardComponent>
      </Grid>
    </ValidatorForm>
  );
}

export default withSuccessActionsDialog(OverlaysSettings);
