import React from 'react';

export function AccessibilityLabelIcon() {
  /* eslint-disable */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#2D63F3" />
      <g fill="#fff">
        <path d="M9.657 6.286c.753.174 1.506-.293 1.68-1.047.048-.21.048-.425 0-.635-.12-.518-.522-.924-1.04-1.043-.753-.177-1.506.293-1.683 1.047-.048.209-.048.428 0 .637.12.519.525.922 1.043 1.04z" />
        <path d="M14.89 6.395c-.161-.338-.563-.483-.901-.322-1.249.593-2.639.908-4.02.915-1.412.006-2.834-.31-4.112-.915-.344-.164-.756-.01-.907.342-.148.338.013.73.347.889 1.107.521 2.31.853 3.53.979.116.013.203.11.203.225v1.836c0 .435-.09.864-.264 1.26l-1.64 3.756c-.123.283-.072.625.16.828.29.264.737.244 1-.046.052-.058.097-.122.126-.193l1.349-3.08c.051-.118.19-.173.309-.122.054.023.096.068.122.123l1.338 3.05c.139.32.483.519.821.438.38-.09.618-.47.528-.85-.01-.042-.023-.084-.042-.122L11.18 11.61c-.174-.396-.264-.827-.264-1.262V8.502c0-.116.087-.213.2-.222 1.193-.132 2.37-.464 3.452-.98.338-.164.483-.57.322-.905z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H10.3V13H0z" transform="translate(4.84961 3.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
