import { mapValues, trim } from 'lodash';

import { PaginationParams } from 'types/common';
import { FontEditParams, FontsListResponse } from 'types/fonts';

import { api } from './setupApi';

class FontsModel {
  link = '/fonts';

  getFonts = (params: PaginationParams): Promise<FontsListResponse> =>
    api.get<FontsListResponse>(this.link, { params }).then(({ data }) => data);

  addFont = (data: Record<'signedId', string>) => api.post(this.link, data);

  editFont = (id: number, data: FontEditParams) =>
    api.put(
      `${this.link}/${id}`,
      mapValues(data, val => (typeof val === 'string' ? trim(val) : val)),
    );

  deleteFont = (id: number) => api.delete(`${this.link}/${id}`);
}

export const fontsModel = new FontsModel();
