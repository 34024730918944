import { Chip, ListItem, ListItemText, styled } from '@material-ui/core';
import { darken, rgba } from 'polished';

import { COLORS } from 'theme/constants';

export const Link = styled(ListItem)(({ theme, activeClassName }) => ({
  padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(14)}px`,

  span: {
    color: rgba(theme.sidebar?.color, 0.7),
  },

  '&:hover span': {
    color: rgba(theme.sidebar?.color, 0.9),
  },

  [`&.${activeClassName}`]: {
    backgroundColor: darken(0.06, COLORS.white),

    span: {
      color: COLORS.darkText,
    },
  },
}));

export const LinkText = styled(ListItemText)(({ theme }) => ({
  color: COLORS.darkText,
  marginTop: 0,
  marginBottom: 0,
}));

export const LinkBadge = styled(Chip)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: theme.typography.fontWeightBold,
  height: '20px',
  position: 'absolute',
  right: '12px',
  top: '8px',
  background: theme.sidebar.badge.background,

  'span.MuiChip-label, span.MuiChip-label:hover': {
    cursor: 'pointer',
    color: theme.sidebar.badge.color,
    paddingLeft: `${theme.spacing(2)}px`,
    paddingRight: `${theme.spacing(2)}px`,
  },
}));
