import { Paper as MuiPaper, TableContainer as MuiTableContainer, withStyles, Box } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Paper = withStyles({
  root: {
    border: `solid 1px ${COLORS.greyBorder}`,
    borderRadius: '8px',
    marginBottom: '24px',
    padding: '28px',
  },
})(MuiPaper);

export const TableContainer = withStyles({
  root: {
    maxHeight: '50vh',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',

    '& .MuiTableRow-root': {
      marginBottom: '10px',
    },

    '& .MuiTableCell-root': {
      border: 'none',
      verticalAlign: 'top',
    },
  },
})(MuiTableContainer);

export const ImageContainer = withStyles({
  root: {
    width: '64px',
    height: '64px',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
})(Box);
