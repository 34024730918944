import React, { memo, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { compose } from 'lodash/fp';

import { DEFAULT_FUNC } from 'constants/technical';
import { FormValidationProps } from 'types';
import { withLocalization, withHandleNumberType, withPasswordShowing } from 'utils/enhancers';

import { withFormValidation } from '../withFormValidation/withFormValidation';

import { TextValidator, FormControl } from '../style';

interface TextValidatorCustomComponentProps extends FormValidationProps {
  fieldName: string;
  label?: string;
  value?: string | number;
  type?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  autoComplete?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onValidationChange?: (isValid: boolean) => void;
  disableMinHeight?: boolean;
  noWrap?: boolean;
  additionalClassName?: string;
  helperText?: string;
  onAutoFill?: () => void;
}

function TextValidatorCustomComponent({
  validators,
  label = '',
  value = '',
  fieldName,
  type = 'input',
  autoFocus = false,
  multiline = false,
  rows = 1,
  autoComplete = 'off',
  disabled = false,
  fullWidth = true,
  serverError,
  onChange,
  errorMessages,
  onValidationChange = DEFAULT_FUNC,
  disableMinHeight = false,
  additionalClassName = '',
  helperText,
  noWrap = false,
  onAutoFill = () => {},
  ...props
}: TextValidatorCustomComponentProps) {
  const [isValid, setIsValid] = useState<boolean>(true);

  const validatorListener = (result: boolean) => {
    if (result !== isValid) {
      setIsValid(result);
      onValidationChange(result);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // This is a workaround to detect autofill. When chrome autofills the input, the onChange event is not triggered.
  useEffect(() => {
    const onAnimationStart = (e: any) => {
      if (e.animationName === 'mui-auto-fill') {
        onAutoFill();
      }
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('animationstart', onAnimationStart);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('animationstart', onAnimationStart);
      }
    };
  }, [onAutoFill]);

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classnames({
        'no-min-height': disableMinHeight,
        'no-wrap': noWrap,
        [additionalClassName]: !!additionalClassName,
      })}
    >
      <TextValidator
        id={fieldName}
        validators={validators}
        errorMessages={errorMessages}
        margin="dense"
        label={label}
        fullWidth={fullWidth}
        variant="outlined"
        value={value}
        onChange={onChange}
        type={type}
        error={!isEmpty(serverError)}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
        autoComplete={autoComplete}
        disabled={disabled}
        helperText={serverError || helperText}
        inputRef={inputRef}
        {...props}
        validatorListener={validatorListener}
      />
    </FormControl>
  );
}

export const TextValidatorComponent = compose(
  withPasswordShowing,
  withFormValidation,
  withHandleNumberType,
  memo,
)(TextValidatorCustomComponent);
export const MultilanguageTextValidator = withLocalization(TextValidatorComponent);
