import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { get } from 'lodash';

import { CardComponent, PageHeader, ImageUploader } from 'components';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { logosModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey, withBackgroundForSystemImg } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';
import { useDeleteSelectedAssets } from 'hooks';

function Logo({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }) {
  const [images, setImages] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleDeleteAllRequest } = useDeleteSelectedAssets();
  const getT = getTWithPathKey('pages.assets.Logo');

  useEffect(() => {
    getLogos();
  }, [paginationParams]);

  const getLogos = withPageProgressHandler(() =>
    logosModel
      .getLogo(paginationParams)
      .then(({ logos, logosCount }) => {
        setImages(logos.map(withBackgroundForSystemImg));
        setPaginationItemsCount(logosCount);
      })
      .catch(handleServerError)
  );

  const loadImage = withPageProgressHandler(image => {
    const signedId = get(image, 'signed_id');

    return logosModel.postLogo({ signedId }).then(resetPaginationPage);
  });
  const unloadImage = withPageProgressHandler(imageId =>
    logosModel.deleteLogo(imageId).then(getLogos).catch(handleServerError));

  const handleEditImage = withPageProgressHandler((id, data) =>
    logosModel.editLogo(id, data).then(getLogos).catch(handleServerError)
  );

  return (
    <>
      <PageHeader title={`Logo: ${paginationItemsCount}`} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title={getT('title')}>
          <ImageUploader
            images={images}
            loadImage={loadImage}
            unloadImage={unloadImage}
            handleServerError={handleServerError}
            handleDeleteAll={assets => handleDeleteAllRequest(assets, logosModel.deleteLogo, getLogos)}
            text={getT('imageUploaderText')}
            accept={ACCEPT_EXTENSIONS.imgIcon}
            section={DOWNLOAD_SECTIONS.logo}
            withEdit
            handleEdit={handleEditImage}
            withUploadButton
            maxFiles={20}
          />
        </CardComponent>
      </Grid>
    </>
  );
}

Logo.propTypes = {
  ...withPaginationPropTypes,
};

export default withPagination(Logo);
