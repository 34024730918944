import { styled, FormControlLabel as MuiFormControlLabel, Radio as MuiRadio } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '&.selected': { border: `1px solid ${theme.palette.primary.main}` },
  margin: 0,
  border: `1px solid ${COLORS.greyBorder}`,
  minHeight: '86px',
  borderRadius: '4px',
  position: 'relative',
  padding: '14px 16px',
  width: '100%',
  height: '100%',
  '& .MuiRadio-root': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '3px',
  },
  '& > .MuiFormControlLabel-label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  '& > .MuiFormControlLabel-label .MuiTypography-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '6px 8px',
  },
}));

export const Radio = styled(MuiRadio)({
  color: COLORS.greyDefault,
});
