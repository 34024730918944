import { DispatchAction } from 'types/common';
import { AuthTypes, UserAttributes, UserState } from 'types/user';

export type LogInAction = DispatchAction<AuthTypes.LOG_IN | AuthTypes.LOG_OUT | AuthTypes.VERIFY_TOKEN, UserState>;
export type ToggleLoadingAction = DispatchAction<AuthTypes.TOGGLE_LOADING>;
export type UpdateUserDataAction = DispatchAction<AuthTypes.UPDATE_USER_DATA, UserAttributes>;

type ActionTypes = LogInAction | ToggleLoadingAction | UpdateUserDataAction;

export default (state: UserState, action: ActionTypes): UserState => {
  switch (action.type) {
    case AuthTypes.LOG_IN:
    case AuthTypes.LOG_OUT:
    case AuthTypes.VERIFY_TOKEN: {
      return { ...state, ...action.payload };
    }
    case AuthTypes.TOGGLE_LOADING: {
      const newCurrentUser = { ...state.currentUser, isLoading: !state.currentUser.isLoading };

      return { ...state, currentUser: newCurrentUser };
    }
    case AuthTypes.UPDATE_USER_DATA: {
      const newCurrentUser = {
        ...state.currentUser,
        attributes: { ...state.currentUser.attributes, ...action.payload },
      };

      return { ...state, currentUser: newCurrentUser };
    }
    default: {
      return state;
    }
  }
};
