import { PlanogramCloningState, PlanogramCloningTypes } from './types';

export default (state: PlanogramCloningState, action: any): PlanogramCloningState => {
  switch (action.type) {
    case (PlanogramCloningTypes.STORE_PLANOGRAM_ID):
    case (PlanogramCloningTypes.CLEAR_PLANOGRAM_STATE): {
      return { ...state, ...action.payload };
    }
    case (PlanogramCloningTypes.STORE_PLANOGRAM_STATUS): {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
