import React from 'react';

import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import { withAuthLayout, withDashboard, withErrorHandling, withSignIn } from '../utils/enhancers';

import { PUBLIC_ROUTES, ROUTES_WITHOUT_DASHBOARD } from './constants';

const SentryRoute = withSentryRouting(Route);

export function RouteComponent({ path, component, exact }) {
  const enhancers = [withErrorHandling];

  switch (true) {
    case PUBLIC_ROUTES.includes(path):
      enhancers.push(withAuthLayout);
      break;
    case ROUTES_WITHOUT_DASHBOARD.includes(path):
      enhancers.push(withSignIn);
      break;
    default:
      enhancers.push(withDashboard, withSignIn);
  }

  return <SentryRoute path={path} exact={exact} component={compose(...enhancers)(component)} />;
}

RouteComponent.propTypes = {
  path: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
};

RouteComponent.defaultProps = { exact: true, path: '' };
