import React, { createContext, PropsWithChildren, useState } from 'react';

import { categoriesModel } from 'models';
import { generateContextHook } from 'utils/helpers/contextHelpers';

import { useServerErrorActions } from '../ServerErrorContext';
import { ServerErrorType } from '../ServerErrorContext/types';

import { CategoriesState, CategoriesDispatch } from './types';

const initialState: CategoriesState = {
  categories: [],
};

const CategoriesStateContext = createContext<CategoriesState>(initialState);
const CategoriesDispatchContext = createContext<CategoriesDispatch>({
  getCategoriesList: () => Promise.resolve(),
});

const CategoriesProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, setState] = useState<CategoriesState>(initialState);
  const { handleServerError } = useServerErrorActions();

  const dispatchValue: CategoriesDispatch = {
    getCategoriesList: () =>
      categoriesModel
        .getCategoriesForSelect()
        .then(({ categories }) => setState({ ...state, categories }))
        .catch((error: ServerErrorType) => handleServerError(error)),
  };

  return (
    <CategoriesStateContext.Provider value={state}>
      <CategoriesDispatchContext.Provider value={dispatchValue}>{children}</CategoriesDispatchContext.Provider>
    </CategoriesStateContext.Provider>
  );
};

const useCategoriesState = generateContextHook(CategoriesStateContext, 'Categories');
const useCategoriesActions = generateContextHook(CategoriesDispatchContext, 'Categories');

export { CategoriesProvider, useCategoriesState, useCategoriesActions };
