import { ListItem, ListItemText as MuiListItemText, styled } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { rgba } from 'polished';

import { COLORS } from 'theme/constants';

import { LinkBadge } from '../SidebarLink/style';

export const Category = styled(ListItem)(({ theme, activeClassName }) => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.sidebar.text,
  ...theme.typography.body,
  height: '44px',
  paddingLeft: '24px',

  '& svg': {
    fontSize: '18px',
    marginRight: '20px',
  },

  '& svg path[stroke]': { stroke: theme.palette.sidebar.text },
  '& svg path[fill]': { fill: theme.palette.sidebar.text },

  [`&:hover`]: {
    background: theme.palette.sidebar.hoverBackground,
  },

  [`&.${activeClassName}`]: {
    background: theme.palette.sidebar.activeBackground,
    color: theme.palette.sidebar.activeText,
    '& svg path[stroke]': { stroke: theme.palette.sidebar.activeText },
    '& svg path[fill]': { fill: theme.palette.sidebar.activeText },
  },
}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  ...theme.typography.body,
}));

export const CategoryIconLess = styled(ExpandLess)({
  color: rgba(COLORS.darkText, 0.5),
});

export const CategoryIconMore = styled(ExpandMore)({
  color: rgba(COLORS.darkText, 0.5),
});

export const CategoryBadge = styled(LinkBadge)({
  top: '12px',
});
