import {
  QRCodeAsSvg,
  ResetEmailParams,
  ResetTFAParams,
  TFASettings,
  TFASettingsPostParams,
  TFASettingsPutParams,
  UserAttributes,
} from 'types/user';

import { api } from './setupApi';

class TFAModel {
  link = '/two_factor_settings';

  sendResetToEmailLink = '/send_reset_email';

  resetTwoFactor = '/reset_two_factor';

  getNewSettings = (): Promise<string> =>
    api.get<QRCodeAsSvg>(`${this.link}/new`).then(({ data: { qrCodeAsSvg } }) => qrCodeAsSvg);

  getSettings = (id: number): Promise<TFASettings> =>
    api.get<Record<'data', TFASettings>>(this.link, { params: { id } }).then(({ data: { data } }) => data);

  putSettings = (data: TFASettingsPutParams): Promise<TFASettings> =>
    api.put<TFASettings>(this.link, data).then(({ data }) => data);

  postSettings = (data: TFASettingsPostParams): Promise<UserAttributes> =>
    api.post<Record<'data', UserAttributes>>(this.link, data).then(({ data: { data } }) => data);

  deleteSettings = (id: number) => api.delete(`${this.link}/${id}`).then(({ data }) => data);

  sendResetToEmail = (data: ResetEmailParams) =>
    api.put(`${this.link}${this.sendResetToEmailLink}`, data).then(({ data }) => data);

  resetTFA = (params: ResetTFAParams) =>
    api.get(`${this.link}${this.resetTwoFactor}`, { params }).then(({ data }) => data);
}

export const tfaModel = new TFAModel();
