import { MenuItem as MuiMenuItem, IconButton as MuiIconButton, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const MenuItem = styled(MuiMenuItem)({
  padding: '0',
  '& button': {
    height: '44px',
    backgroundColor: COLORS.white,
  },
  '& button:disabled': {
    backgroundColor: COLORS.white,
  },
  '& button:hover': {
    backgroundColor: COLORS.greyBorder,
  },
});

export const IconButton = styled(MuiIconButton)({
  borderRadius: '2px',
  backgroundColor: COLORS.greyBorder,

  '&:hover': {
    boxShadow: '0 0 14px 0 rgba(53,64,82,.05)',
    backgroundColor: COLORS.greyBgHover,
  },
});
