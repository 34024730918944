import {
  Box,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  styled,
} from '@material-ui/core';

export const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '766px',
  },
});

export const DialogTitle = styled(MuiDialogTitle)({
  padding: '8px',
  fontSize: '14px',

  '& .MuiTypography-h6': {
    fontWeight: 'bold',
  },
});

export const DialogContent = styled(MuiDialogContent)({
  padding: '8px',
});

export const DialogActions = styled(MuiDialogActions)({
  display: 'flex',
  paddingTop: '20px',
});

export const TextComponentWrapper = styled(Box)({
  marginBottom: '-8px',
});

export const PreviewBox = styled(Box)({
  width: '100%',
  minHeight: '140px',
});
