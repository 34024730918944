import React, { ComponentType, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { PaginationComponent } from 'components/PaginationComponent/PaginationComponent';
import { PaginationParams, PaginationProps } from 'types/common';

export interface WithPaginationProps {
  initialPaginationParams: PaginationParams;
}

export const defaultParams = { perPage: 20, page: 0 };

export const withPagination =
  <T,>(Component: ComponentType<T & PaginationProps>) =>
  ({ initialPaginationParams = defaultParams, ...props }: WithPaginationProps): JSX.Element => {
    const [paginationParams, setPaginationParams] = useState<PaginationParams>(initialPaginationParams);
    const [totalPages, setTotalPages] = useState(0);
    const [paginationItemsCount, setPaginationItemsCount] = useState(0);

    useEffect(() => {
      setTotalPages(Math.ceil(paginationItemsCount / paginationParams.perPage));
    }, [paginationItemsCount, paginationParams.perPage]);

    const checkMaxPage = (count: number) => {
      if (Math.ceil(count / paginationParams.perPage) - 1 < paginationParams.page) {
        const newCurrentPage = paginationParams.page - 1 >= 0 ? paginationParams.page - 1 : 0;

        setPaginationParams(prev => ({
          ...prev,
          page: newCurrentPage,
        }));
      }
    };

    const handlePaginationItemsCount = (count: number) => {
      if (count === 0 && paginationParams.page === 0) {
        if (paginationItemsCount) {
          setPaginationItemsCount(count);
        }
        return;
      }
      checkMaxPage(count);
      setPaginationItemsCount(count);
    };

    const handleChangePerPage = (perPage: number) =>
      setPaginationParams(prev => ({
        ...prev,
        perPage,
      }));

    const resetPaginationPage = () =>
      setPaginationParams(prev => ({
        ...prev,
        page: initialPaginationParams.page,
      }));

    return (
      <>
        <Component
          {...(props as T)}
          paginationParams={paginationParams}
          paginationItemsCount={paginationItemsCount}
          setPaginationItemsCount={handlePaginationItemsCount}
          resetPaginationPage={resetPaginationPage}
          setPaginationPerPage={handleChangePerPage}
        />
        {totalPages > 1 && (
          <Box display="flex" justifyContent="center">
            <PaginationComponent
              countPages={totalPages}
              paginationParams={paginationParams}
              setPaginationParams={setPaginationParams}
              shape="rounded"
              className="with-border"
            />
          </Box>
        )}
      </>
    );
  };

export const withPaginationPropTypes = {
  setPaginationItemsCount: PropTypes.func.isRequired,
  resetPaginationPage: PropTypes.func.isRequired,
  setPaginationPerPage: PropTypes.func.isRequired,
  paginationItemsCount: PropTypes.number.isRequired,
  paginationParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
};
