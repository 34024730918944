import React, { useState } from 'react';

import { Dialog, Box } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { CookieIcon } from 'components/CustomIcons';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { getInlineFontStyles } from 'utils';

import { FontSettingsPropTypes } from '../FontSettingsEditDialog/constants';

import './style.scss';

/* eslint-disable react/no-danger */
export function InfoOverlayPreviewDialog({ overlay, planogramVersion, handleClose }) {
  const [textFadeVisible, setTextFadeVisible] = useState(true);

  const handleScroll = ({ target: { scrollHeight, offsetHeight, scrollTop } }) => {
    setTextFadeVisible(scrollHeight !== offsetHeight + scrollTop);
  };

  const getFontSettings = fieldName =>
    get(overlay, 'planogramInfoFonts', []).find(({ assignment }) => assignment === fieldName);

  return (
    <Dialog fullWidth classes={{ paper: 'dialog-container' }} open={Boolean(overlay)} onClose={handleClose}>
      <div
        style={{
          background: planogramVersion.primaryColor,
          color: getInlineFontStyles(getFontSettings('content')).color,
        }}
        className={`overlay-container ${overlay?.slug} ${overlay?.iconUrl ? '' : 'no-image'}`}
      >
        <Box className="overlay-button overlay-button-close will-close" onClick={handleClose}>
          <span />
        </Box>
        <CustomFontWrapper
          className="title overlay-title-font"
          font={getFontSettings('title')?.font}
          style={getInlineFontStyles(getFontSettings('title'))}
        >
          {overlay?.title}
        </CustomFontWrapper>
        {overlay?.iconUrl && (
          <div className="image-holder">
            <img src={overlay?.iconUrl} className="control-bnt-icon" alt="" />
          </div>
        )}
        <div className="main">
          <CustomFontWrapper
            className="text-wrapper"
            font={getFontSettings('content')?.font}
            style={getInlineFontStyles(getFontSettings('content'))}
          >
            <div
              className="text-content"
              dangerouslySetInnerHTML={{ __html: overlay?.content || '' }}
              onScroll={handleScroll}
            />
            <div className="cookies-btn">
              I want to read cookies
              <span>
                <CookieIcon />
              </span>
            </div>
            {textFadeVisible && <div style={{ color: planogramVersion.primaryColor }} className="text-fade" />}
          </CustomFontWrapper>
        </div>
      </div>
    </Dialog>
  );
}

InfoOverlayPreviewDialog.propTypes = {
  overlay: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    contentMarkdown: PropTypes.string,
    iconUrl: PropTypes.string,
    slug: PropTypes.string,
    fontSettings: PropTypes.shape({
      title: FontSettingsPropTypes,
      content: FontSettingsPropTypes,
    }),
  }),
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

InfoOverlayPreviewDialog.defaultProps = {
  overlay: null,
};
