import React from 'react';

import PropTypes from 'prop-types';

import { Tabs, Tab } from './style';

export function TabsComponent({
  value,
  setValue,
  tabNames,
  tabClassName,
  rootClassName,
  errorTabs,
  useIndex,
  useTranslate,
  getTranslate,
}) {
  const handleTabChange = (event, newValue) => setValue(newValue);

  const getClassNames = name => (errorTabs.includes(name) ? `${tabClassName} error` : tabClassName);

  const getLabel = labelText => (useTranslate ? getTranslate(labelText) : labelText);

  const getTabValue = tabName => (useIndex ? undefined : tabName);

  return (
    <Tabs value={value} onChange={handleTabChange} className={rootClassName} variant="scrollable" scrollButtons="off">
      {tabNames.map(tabName => (
        <Tab value={getTabValue(tabName)} label={getLabel(tabName)} key={tabName} className={getClassNames(tabName)} />
      ))}
    </Tabs>
  );
}

TabsComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorTabs: PropTypes.arrayOf(PropTypes.string),
  tabClassName: PropTypes.string,
  rootClassName: PropTypes.string,
  useIndex: PropTypes.bool,
  useTranslate: PropTypes.bool,
  getTranslate: PropTypes.func,
};

TabsComponent.defaultProps = {
  tabClassName: '',
  rootClassName: '',
  useIndex: true,
  useTranslate: false,
  getTranslate: text => text,
  errorTabs: [],
};
