import React, { memo } from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from 'components/propTypesConsts';
import { getInlineFontStyles } from 'utils';

import { Image } from '../style';
import { ButtonPreviewBox, Typography, ImageBox } from './style';

export function SearchPreviewComponent({ activeIcon, color, searchTitle, searchSettingFont }) {
  const getStyles = icon => {
    const styles = {};

    if (icon.buttonColor) {
      styles.backgroundColor = icon.buttonColor;
    }
    return styles;
  };

  const getInputStyles = icon => {
    const styles = getInlineFontStyles(searchSettingFont);

    if (icon?.inputColor) {
      styles.backgroundColor = icon.inputColor;
    }
    if (color) {
      styles.color = color;
    }

    return styles;
  };

  if (!activeIcon) {
    return null;
  }

  return (
    <CustomFontWrapper font={searchSettingFont?.font}>
      <Box ml={5}>
        <Box mt={3} mb={2}>
          <Typography>Active</Typography>
        </Box>
        <Box>
          <ButtonPreviewBox style={getInputStyles(activeIcon)} className="button-preview-box-search">
            <ButtonPreviewBox style={getStyles(activeIcon)} className="button-preview-icon">
              {activeIcon.url && (
                <ImageBox>
                  <Image src={activeIcon.url} />
                </ImageBox>
              )}
            </ButtonPreviewBox>
            <Box className="text">{searchTitle}</Box>
          </ButtonPreviewBox>
        </Box>
      </Box>
    </CustomFontWrapper>
  );
}

SearchPreviewComponent.propTypes = {
  activeIcon: PropTypes.shape({
    url: PropTypes.string,
    buttonColor: PropTypes.string,
    inputColor: PropTypes.string,
  }),
  color: PropTypes.string,
  searchTitle: PropTypes.string,
  searchSettingFont: fontSettingsProp,
};

SearchPreviewComponent.defaultProps = {
  activeIcon: null,
  searchSettingFont: null,
  color: '',
  searchTitle: '',
};

export const SearchPreview = memo(SearchPreviewComponent);
