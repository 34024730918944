import {
  CategoriesGetParams,
  CategoriesListResponse,
  CategoryItem,
  CategoryItemResponse,
  CategoryParams,
} from 'types/other';
import { trimItems } from 'utils';

import { api } from './setupApi';

class CategoriesModel {
  link = '/categories';

  getCategories = (params: CategoriesGetParams): Promise<CategoriesListResponse> =>
    api.get<CategoriesListResponse>(this.link, { params }).then(({ data }) => data);

  getCategoriesForSelect = (): Promise<Record<'categories', CategoryItem[]>> =>
    api.get<Record<'categories', CategoryItem[]>>(`${this.link}/for_select`).then(({ data }) => data);

  getCategory = (id: number): Promise<CategoryItemResponse> =>
    api.get<CategoryItemResponse>(`${this.link}/${id}`).then(({ data }) => data);

  addCategory = (data: CategoryParams): Promise<CategoryItem> =>
    api.post<CategoryItemResponse>(this.link, trimItems(data, ['name'])).then(({ data: { category } }) => category);

  editCategory = (id: number, data: CategoryParams): Promise<CategoryItemResponse> =>
    api.put<CategoryItemResponse>(`${this.link}/${id}`, trimItems(data, ['name'])).then(({ data }) => data);

  deleteCategory = (id: number) => api.delete(`${this.link}/${id}`);
}

export const categoriesModel = new CategoriesModel();
