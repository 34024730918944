import { PaginationParams } from 'types/common';
import { UploadedPicture, FaviconsListResponse } from 'types/images';

import { api } from './setupApi';

class FaviconsModel {
  link = '/favicons';

  getFavicons = (params: PaginationParams): Promise<FaviconsListResponse> =>
    api.get<FaviconsListResponse>(this.link, { params }).then(({ data }) => data);

  addFavicon = (data: Record<'signedId', string>) => api.post(this.link, data);

  deleteFavicon = (id: number) => api.delete(`${this.link}/${id}`);

  editFavicon = (id: number, data: UploadedPicture) => api.put(`${this.link}/${id}`, data);
}

export const faviconsModel = new FaviconsModel();
