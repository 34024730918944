export const PRODUCT_FIELD_TYPE = {
  BOOL: 'boolean',
  STRING: 'string',
  TEXT: 'text',
  INTEGER: 'integer',
  FLOAT: 'float',
  FILE: 'file',
  SELECT: 'select',
  ARRAY: 'array',
  OBJECT: 'object',
};
