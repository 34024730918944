import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { CardComponent } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { ClientShoppingProvider } from 'types';
import { getTWithPathKey } from 'utils';

import { Switch } from 'components/style';

interface Props {
  isMultipassEnabled: boolean;
  shopifyMultipassSecret: string;
  shopifyMultipassUrl: string;
  isLoading: boolean;
  onChange: (item: Partial<ClientShoppingProvider>) => void;
  setMultipassEnabled: () => void;
}

export const MultiPassComponent = ({
  isMultipassEnabled,
  shopifyMultipassSecret,
  shopifyMultipassUrl,
  isLoading,
  onChange,
  setMultipassEnabled,
}: Props) => {
  const getT = getTWithPathKey('pages.ecommerceProvider.multipass');

  const renderNav = () => <Switch checked={isMultipassEnabled} onChange={setMultipassEnabled} />;

  return (
    <CardComponent isLoading={isLoading} title={getT('header')} xs={9} CardHeaderNav={renderNav}>
      {isMultipassEnabled && (
        <Grid container>
          <Grid item sm={12}>
            <Box mt={5} mb={6}>
              <Typography className="information">{getT('message')}</Typography>
            </Box>
          </Grid>
          <Grid item container spacing={5}>
            <Grid item sm={8}>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required]}
                label={getT('shopifySecret')}
                value={shopifyMultipassSecret}
                fieldName="shopifyMultipassSecret"
                handleChange={onChange}
                autoComplete="shopify-multipass-secret"
                type="password"
              />
            </Grid>
            <Grid item sm={8}>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.https(getT('multipassRedirectLink'))]}
                label={getT('multipassRedirectLink')}
                value={shopifyMultipassUrl}
                fieldName="shopifyMultipassUrl"
                handleChange={onChange}
                autoComplete="multipass-redirect-url"
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </CardComponent>
  );
};
