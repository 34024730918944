import React from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { AuthCard, RedirectText, SmallInfoText, StyledButtonComponent } from '../style';

export const AuthMessage = ({ tabTitle, title, text, buttonText, onClick, disabled, footer, disclaimer }) => (
  <AuthCard>
    <Helmet title={tabTitle} />
    <Grid container direction="column" spacing={6}>
      {title && (
        <Grid item>
          <Typography variant="h2">{title}</Typography>
        </Grid>
      )}
      {text && (
        <Grid item>
          <SmallInfoText>{text}</SmallInfoText>
        </Grid>
      )}
      {buttonText && (
        <Grid item>
          <StyledButtonComponent fullWidth color="primary" text={buttonText} onClick={onClick} disabled={disabled} />
        </Grid>
      )}
      {disclaimer && (
        <Grid item>
          <SmallInfoText>{disclaimer}</SmallInfoText>
        </Grid>
      )}
      {footer && (
        <>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <RedirectText>{footer}</RedirectText>
          </Grid>
        </>
      )}
    </Grid>
  </AuthCard>
);

AuthMessage.propTypes = {
  tabTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.node,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  footer: PropTypes.node,
};
