import React, { useRef } from 'react';

import { head } from 'lodash';
import PropTypes from 'prop-types';

import { checkIsExtensionsAllowed, getServerError, getTWithPathKey } from 'utils';

import { Input, Button } from './style';

export function InputFile({ accept, text, setFile, handleServerError }) {
  const inputRef = useRef();
  const getT = getTWithPathKey('common.errors');

  const onChange = () => {
    const currentFile = head(inputRef.current.files);

    if (currentFile && checkIsExtensionsAllowed(currentFile, accept)) {
      setFile(currentFile);
    } else if (currentFile) {
      handleServerError(getServerError(getT('fileType')));
    }
  };

  return (
    <Button component="label" size="small" variant="text">
      <Input accept={accept} type="file" onChange={onChange} ref={inputRef} className="input" />
      {text}
    </Button>
  );
}

InputFile.propTypes = {
  accept: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
};
