import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { getServerError } from 'utils';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface DeleteCustomDomainDialogProps {
  domainName: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

enum TextFields {
  DOMAIN = 'domain',
}

export function DeleteCustomDomainDialog({ domainName, onClose, onSubmit }: DeleteCustomDomainDialogProps) {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [domain, setDomain] = useState('');
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const { handleServerError } = useServerErrorActions();

  const handleChange = (value: { [TextFields.DOMAIN]: string }) => setDomain(value[TextFields.DOMAIN]);

  const handleConfirm = () => {
    if (domain !== domainName) {
      handleServerError(getServerError(t('errorsTexts.incorrectCustomDomain'), TextFields.DOMAIN));
      return;
    }
    withHandlingSubmittingState(onSubmit)();
  };

  const renderConfirmation = () => (
    <DialogContent>
      <DialogContentText style={{ paddingLeft: 0 }}>
        {t('components.dialogs.deleteCustomDomain.confirmationDescription')}
      </DialogContentText>
      <ValidatorForm onSubmit={handleConfirm} autoComplete="off">
        <TextValidatorComponent
          validators={[VALIDATORS_MAP.required]}
          label="Custom Domain Name"
          value={domain}
          fieldName={TextFields.DOMAIN}
          handleChange={handleChange}
          autoFocus
        />
        <DialogActions>
          <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
          <ButtonComponent
            text={t('components.dialogs.deleteCustomDomain.confirmBtn')}
            type="submit"
            color="primary"
            disabled={!domain}
            loaderSize="1.35em"
            loading={isSubmitting}
          />
        </DialogActions>
      </ValidatorForm>
    </DialogContent>
  );

  const renderContent = () => (
    <DialogContent>
      <DialogContentText style={{ paddingLeft: 0 }}>
        <Trans
          i18nKey="components.dialogs.deleteCustomDomain.description"
          components={{ b: <b /> }}
          values={{ domainName }}
        />
      </DialogContentText>
      <DialogActions>
        <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
        <ButtonComponent
          text={t('components.dialogs.deleteCustomDomain.continueBtn')}
          color="primary"
          onClick={() => setShowConfirmation(true)}
        />
      </DialogActions>
    </DialogContent>
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>{t('components.dialogs.deleteCustomDomain.title')}</DialogTitle>
      {showConfirmation ? renderConfirmation() : renderContent()}
    </Dialog>
  );
}
