interface CountAndDataPath {
  countPath?: string;
  dataPath?: string;
}

export const getCountAndDataPath = (keys: string[]): CountAndDataPath => {
  const countPath = keys.find(key => key.toLowerCase().includes('count'));
  const dataPath = keys.find(key => !key.toLowerCase().includes('count'));

  return { countPath, dataPath };
};
