import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { getTWithPathKey } from '../../../utils';
import { ButtonComponent } from '../../ButtonComponent/ButtonComponent';

import { DialogActions, DialogContent, DialogTitle } from '../FontSettingsEditDialog/style';
import { Dialog } from '../style';

export interface FontFeatureDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function FontFeatureDialog({ isOpen, onClose }: FontFeatureDialogProps) {
  const getT = getTWithPathKey('dialogs.fontFeatureDialog');
  const getTButton = getTWithPathKey('common.buttons');

  return (
    <Dialog open={isOpen} onBackdropClick={onClose} maxWidth="md">
      <DialogTitle>{getT('title')}</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography>{getT('line-1')}</Typography>
        </Box>
        <Box mb={3}>
          <Typography>{getT('line-2')}</Typography>
        </Box>
        <Box mb={3}>
          <Typography>{getT('line-3')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonComponent onClick={onClose} text={getTButton('cancel')} />
      </DialogActions>
    </Dialog>
  );
}
