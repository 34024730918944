import React, { memo, useEffect, useState } from 'react';

import { Button, List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CardComponent } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { adminsModel } from 'models';
import { ROLES, ROUTES } from 'routes/constants';
import { getFullName } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

import { ListItem } from 'components/style';

const LINK_BY_ROLE_MAP = {
  [ROLES.clientAdmin]: ROUTES.editClientAdminNamespace,
  [ROLES.clientModerator]: ROUTES.editClientModeratorNamespace,
};

const AdminsCustomBlock = ({ clientId, role, setPaginationItemsCount, paginationParams }) => {
  const { handleServerError } = useServerErrorActions();
  const { t } = useTranslation();
  const TITLE_BY_ROLE_MAP = {
    [ROLES.clientAdmin]: t('pages.clients.editClient.adminsBlock.clientAdminTitle'),
    [ROLES.clientModerator]: t('pages.clients.editClient.adminsBlock.clientModeratorTitle'),
  };

  const [admins, setAdmins] = useState({
    admins: [],
    adminsCount: 0,
  });

  useEffect(() => {
    adminsModel
      .getAdminsByClient({
        clientId,
        role,
        ...paginationParams,
      })
      .then(setAdmins)
      .catch(handleServerError);
  }, [clientId, role, paginationParams.page, paginationParams.perPage]);

  useEffect(() => {
    setPaginationItemsCount(admins.adminsCount);
  }, [admins.adminsCount]);

  const renderAdminsList = () => (
    <List>
      {admins.admins.map(admin => (
        <ListItem key={admin.id}>
          <Button component={Link} to={`${LINK_BY_ROLE_MAP[role]}${admin.id}`} size="small" fullWidth>
            {getFullName(admin)}
          </Button>
        </ListItem>
      ))}
    </List>
  );

  return (
    <CardComponent sm={12} title={`${TITLE_BY_ROLE_MAP[role]} ${admins.adminsCount}`}>
      {!!admins.adminsCount && renderAdminsList()}
    </CardComponent>
  );
};

AdminsCustomBlock.propTypes = {
  ...withPaginationPropTypes,
  clientId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default memo(withPagination(AdminsCustomBlock));
