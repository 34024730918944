import { Card, Typography, Box, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Delete } from '@material-ui/icons';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { CopyToClipboardComponent } from 'components/CopyToClipboardComponent/CopyToClipboardComponent';
import { COLORS } from 'theme/constants';
import { CustomDomainVerificationStatus } from 'types/enums';

import { STATUS_COLOR_MAP } from './constants';

export const DescriptionBox = styled(Box)({
  borderRadius: '4px',
  border: 'solid 1.5px #006ced',
  padding: '10px',
});

export const Description = styled(Typography)({
  alignSelf: 'stretch',
  fontSize: '14px',
  lineHeight: 1.43,
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
});

export const Disclaimer = styled(Typography)({
  fontSize: '12px',
  lineHeight: 1.67,
  color: COLORS.greyText,
});

export const StyledButtonComponent = styled(ButtonComponent)({
  marginTop: '12px',
  marginLeft: '28px',
  width: '102px',
  height: '27px',
});

export const ExpandableDomainCard = styled(Card)({
  padding: '10px',
  borderRadius: 0,
  marginTop: '-1px',
});

export const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const DomainName = styled(Typography)({
  fontSize: '14px',
  lineHeight: 1.43,
  color: COLORS.black,
});

export const ExpandButton = styled(ButtonComponent)({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '6px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& span': {
    marginLeft: '3px',
  },
});

export const DeleteButton = styled(Delete)({
  cursor: 'pointer',
  height: '20px',
  color: '#808080',
});

export const RecordTitle = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 1.67,
  color: COLORS.black,
});

export const RecordTable = styled('table')({
  border: '1px solid #E0E0E0',
  borderCollapse: 'collapse',
  width: '100%',
  '& tr': {
    border: '1px solid #E0E0E0',
  },
  '& td': {
    padding: '4px 8px',
    fontSize: '12px',
    lineHeight: 1.67,
    color: COLORS.black,
    '&:nth-child(1)': {
      border: '1px solid #E0E0E0',
      width: '75px',
    },
    '&:nth-child(3)': {
      width: '20px',
    },
  },
});

export const CopyToClipboard = styled(CopyToClipboardComponent)({
  padding: '5px',
  '& svg': {
    height: '20px',
    width: '20px',
  },
});

export const RecordErrorMessage = styled(Typography)({
  fontSize: '14px',
  color: COLORS.verificationFailedText,
});

interface DomainStatusBadgeProps {
  status: CustomDomainVerificationStatus;
}

export const DomainStatusBadge = withStyles({
  root: {
    ' &.MuiButton-root': {
      fontSize: '12px',
      borderRadius: '6px',
      padding: '6px 12px',
      backgroundColor: ({ status }: DomainStatusBadgeProps) => STATUS_COLOR_MAP[status].backgroundColor,
      color: ({ status }: DomainStatusBadgeProps) => STATUS_COLOR_MAP[status].textColor,
      height: '27px',
    },
  },
})(Button);
