import React from 'react';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { getTWithPathKey } from 'utils';

import { Box, Typography, TextContainer } from './style';

export function ButtonPreviewWrapper({ children, isShopify }) {
  const getT = getTWithPathKey('components.buttonPreviewWrapper');

  return (
    <Grid container spacing={isShopify ? 4 : undefined}>
      <Grid item xs={6}>
        <Box className={classnames('dark', { shopify: isShopify })}>
          <TextContainer className="text-container">
            <Typography variant="h4" display="inline">
              {getT('titleForDarkBg')}
            </Typography>
          </TextContainer>
          {children}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classnames('light', { shopify: isShopify })}>
          <TextContainer className="text-container">
            <Typography variant="h4" display="inline">
              {getT('titleForLightBg')}
            </Typography>
          </TextContainer>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}

ButtonPreviewWrapper.propTypes = {
  isShopify: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]),
};

ButtonPreviewWrapper.defaultProps = { children: null, isShopify: false };
