import React from 'react';

export function ArrowDownIcon() {
  /* eslint-disable */
  return (
    <svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M16.493 10.494l-3.5 3.5c-.341.341-.895.341-1.237 0l-3.499-3.5C7.707 9.943 8.1 9 8.876 9h6.998c.779 0 1.168.944.619 1.494zm-7.618-.619l3.5 3.5 3.5-3.5h-7z"
        clipRule="evenodd"
      />
    </svg>
  );
}
