import { useState } from 'react';

/**
 * This hook is for tracking and controlling submitting state of component
 *
 * @returns
 * isSubmitting {bool}
 * withHandlingSubmittingState {func} Function for wrapping async function which will trigger some async actions
 * */

export type WithSubmittingStateFuncType = (fn: (...args: any[]) => Promise<void>) => (...args: any[]) => void;

export interface UseSubmittingStateReturnType {
  isSubmitting: boolean;
  withHandlingSubmittingState: WithSubmittingStateFuncType;
}

const useSubmittingState = (initialState = false): UseSubmittingStateReturnType => {
  const [isSubmitting, setIsSubmitting] = useState(initialState);

  const withHandlingSubmittingState: WithSubmittingStateFuncType =
    fn =>
    (...args: any[]) => {
      setIsSubmitting(true);
      fn(...args).finally(() => {
        setIsSubmitting(false);
      });
    };

  return { isSubmitting, withHandlingSubmittingState };
};

export default useSubmittingState;
