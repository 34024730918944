import React, { createContext, PropsWithChildren, useReducer } from 'react';

import { Dictionary } from 'lodash';

import { DEFAULT_FUNC } from 'constants/technical';
import { getErrorValue } from 'utils';
import { generateContextHook } from 'utils/helpers/contextHelpers';

import serverErrorReducer from './reducer';
import { ServerErrorActions, ServerErrorDispatchType } from './types';

const ServerErrorStateContext = createContext<Dictionary<string[]>>({});
const ServerErrorDispatchContext = createContext<ServerErrorDispatchType>({
  handleServerError: DEFAULT_FUNC,
  resetServerErrors: DEFAULT_FUNC,
  resetServerFormError: DEFAULT_FUNC,
});

const ServerErrorProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, dispatch] = useReducer(serverErrorReducer, {});

  const dispatchValue: ServerErrorDispatchType = {
    handleServerError: (error, formId = '') =>
      dispatch({
        type: ServerErrorActions.HANDLE_SERVER_ERROR,
        payload: getErrorValue(error, formId),
      }),
    resetServerErrors: () => dispatch({ type: ServerErrorActions.RESET_SERVER_ERRORS }),
    resetServerFormError: item => dispatch({ type: ServerErrorActions.RESET_SERVER_FORM_ERROR, payload: item }),
  };

  return (
    <ServerErrorStateContext.Provider value={state}>
      <ServerErrorDispatchContext.Provider value={dispatchValue}>{children}</ServerErrorDispatchContext.Provider>
    </ServerErrorStateContext.Provider>
  );
};

const useServerErrorState = generateContextHook(ServerErrorStateContext, 'ServerError');
const useServerErrorActions = generateContextHook(ServerErrorDispatchContext, 'ServerError');

export { ServerErrorProvider, useServerErrorState, useServerErrorActions };
