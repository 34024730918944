import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { cloneDeep, isEmpty, isEqual, set, pick } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent } from 'components/CardComponent/CardComponent';
import { SettingsForm } from 'components/SettingsForm/SettingsForm';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { otherAssetsModel } from 'models';
import { getTWithPathKey } from 'utils';

import { BUTTON_TYPES } from '../constants';

import { EcommerceUserProfileButton } from './EcommerceUserProfileButton';

import { Box } from '../style';

const FORM_ID = 'user-profile-form';

export const EcommerceUserProfileForm = ({
  buttonsSettings,
  renderHeaderNav,
  onSave,
  enabled
}) => {
  const [settings, setSettings] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [btnTypeIndexMap, setBtnTypeIndexMap] = useState([]);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const { handleServerError } = useServerErrorActions();
  const getT = getTWithPathKey('components.ecommerce.userProfile');

  useEffect(() => {
    if (!isEmpty(buttonsSettings)) updateSettingsStates(buttonsSettings);
  }, [buttonsSettings]);

  const handleSaveSettings = withHandlingSubmittingState(async ({ target: { id } }) => {
    if (id !== FORM_ID) return;

    const settingsToSaving = {
      ...settings,
      ecommerceIconButtons: settings?.ecommerceIconButtons?.map(btn => ({
        ...btn,
        color: btn.color.hex,
      })),
      backgroundColor: settings.backgroundColor.hex,
      secondaryColor: settings.secondaryColor.hex,
    };

    await onSave(settingsToSaving);
  });

  const updateSettingsStates = settingsToUpdate => {
    const newSettings = {
      ...settingsToUpdate,
      ecommerceIconButtons: settingsToUpdate.ecommerceIconButtons.map(item => ({
        ...item,
        color: { hex: item.color },
      })),
      backgroundColor: { hex: settingsToUpdate.backgroundColor },
      secondaryColor: { hex: settingsToUpdate.secondaryColor },
    };

    setBtnTypeIndexMap(
      settingsToUpdate?.ecommerceIconButtons?.reduce((acc, item, index) => ({ ...acc, [item.buttonType]: index }), {}),
    );
    setSettings(newSettings);
    setInitialSettings(cloneDeep(newSettings));
  };

  const getImages = params => otherAssetsModel.getImages(params).catch(handleServerError);

  const setButton = i => btnSettings =>
    setSettings(prev => ({
      ...prev,
      ecommerceIconButtons: [
        ...set(prev.ecommerceIconButtons, [i], { ...prev.ecommerceIconButtons[i], ...btnSettings }),
      ],
    }));

  const setAdditionalSettings = newSetting => setSettings(prev => ({ ...prev, ...newSetting }));

  const getBtnIndexByMap = type => btnTypeIndexMap[type];

  return (
    <SettingsForm
      isSaveButtonVisible={!isSubmitting && !isEqual(initialSettings, settings)}
      handleSave={handleSaveSettings}
      id={FORM_ID}
    >
      <CardComponent title={getT('title')} CardHeaderNav={renderHeaderNav} isLoading={isSubmitting} sm={8}>
        {enabled && !isEmpty(settings) && (
          <>
            <Grid item sm={12}>
              <Box mb={4}>
                <Typography variant="h6" className="information">
                  {getT('caption')}
                </Typography>
              </Box>
            </Grid>
            <EcommerceUserProfileButton
              key={BUTTON_TYPES.userProfile}
              setButtonSettings={setButton(btnTypeIndexMap[BUTTON_TYPES.userProfile])}
              getImages={getImages}
              buttonSettings={settings?.ecommerceIconButtons[getBtnIndexByMap(BUTTON_TYPES.userProfile)]}
              uploadSectionTitle={getT('profileUploadSectionTitle')}
              previewSectionTitle={getT('profilePreviewSectionTitle')}
              toggleButtonTitle={getT('toggleProfileButtonTitle')}
            />
            <EcommerceUserProfileButton
              key={BUTTON_TYPES.shoppingCart}
              setButtonSettings={setButton(btnTypeIndexMap[BUTTON_TYPES.shoppingCart])}
              getImages={getImages}
              buttonSettings={settings?.ecommerceIconButtons[getBtnIndexByMap(BUTTON_TYPES.shoppingCart)]}
              additionalSettings={pick(settings, ['backgroundColor', 'secondaryColor'])}
              setAdditionalSettings={setAdditionalSettings}
              uploadSectionTitle={getT('cartUploadSectionTitle')}
              previewSectionTitle={getT('cartPreviewSectionTitle')}
              toggleButtonTitle={getT('toggleCartButtonTitle')}
            />
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
};

EcommerceUserProfileForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  buttonsSettings: PropTypes.shape({}),
  renderHeaderNav: PropTypes.func,
  enabled: PropTypes.bool,
};

EcommerceUserProfileForm.defaultProps = {
  buttonsSettings: {},
  renderHeaderNav: () => null,
  enabled: true,
};
