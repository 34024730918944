import i18n from 'i18next';
import { initReactI18next, getI18n } from 'react-i18next';

import de from './assets/translations/de.json';
import en from './assets/translations/en.json';
import fr from './assets/translations/fr.json';
import pl from './assets/translations/pl.json';

export { getI18n };

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  fallbackLng: 'en',
  ns: 'translation',
  defaultNS: 'translation',
});

export default i18n;
