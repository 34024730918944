import { Box as MuiBox, styled } from '@material-ui/core';

export const ButtonPreviewBox = styled(MuiBox)({
  borderRadius: '100px',
  padding: '0 1.5vw',
  minWidth: '132px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '5.37vh',
  minHeight: '48px',
  maxWidth: '100%',
  '& span': {
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& > img': {
    marginRight: '7%',
    height: '1em',
    width: '1em',
    objectFit: 'contain',
  },
  '&.checkout': {
    width: '280px',
    justifyContent: 'space-between',
    '& > img': {
      marginRight: 0,
      marginLeft: '7%',
      maxHeight: '63%',
      order: 2,
    },
  },
});

export const ButtonContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100px',
  padding: '12px 0',
  maxWidth: '100%',
});
