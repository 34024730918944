import { ClientSubdomainCreateParams, SerializedClient, SerializedClientListResponse } from 'types/other';

import { api } from './setupApi';

class SubdomainsModel {
  link = 'clients/:id/client_subdomains';

  getLink = (id: number) => this.link.replace(':id', String(id));

  getClientSubdomains = (clientId: number): Promise<SerializedClient[]> =>
    api
      .get<SerializedClientListResponse>(this.getLink(clientId))
      .then(({ data: { serializedClients } }) => serializedClients);

  createSubdomains = (clientId: number, clientSubdomains: ClientSubdomainCreateParams[]) =>
    api.post(`${this.getLink(clientId)}/bulk_create`, { clientSubdomains });

  setSubdomainAsDefault = (clientId: number, subdomainId: number) =>
    api.put(`${this.getLink(clientId)}/${subdomainId}/set_as_default`);

  deleteSubdomains = (clientId: number, subdomains: number[]) =>
    api.delete(`${this.getLink(clientId)}/bulk_delete`, { data: { subdomains } });
}

export const subdomainsModel = new SubdomainsModel();
