import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { COLORS } from 'theme/constants';

const useInputStyles = makeStyles({
  root: {
    backgroundColor: COLORS.white,
    borderStyle: 'solid',
    width: '100%',
    padding: '8px 24px',
    height: '40px',
    fontFamily: 'inherit',
  },
});

const OverlayInputPreview = ({ style, value }) => (
  <input type="text" disabled value={value} className={useInputStyles().root} style={style} />
);

OverlayInputPreview.defaultProps = {
  style: {},
  value: '',
};

OverlayInputPreview.propTypes = {
  style: PropTypes.shape({}),
  value: PropTypes.string,
};

export { OverlayInputPreview };
