import { Chip as MuiChip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { COLORS } from 'theme/constants';

export const Chip = styled(MuiChip)(({ theme }) => ({
  height: '34px',
  borderRadius: 0,
  borderRight: `1px solid ${COLORS.cozmosPurple2}57`,
  overflow: 'hidden',
  minWidth: '70px',
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:first-child': {
    borderRadius: '4px 0 0 4px',
  },
  '& .MuiChip-label': {
    padding: '0 10px',
  },
  '& .MuiChip-deleteIcon': {
    width: '0.75em',
    height: '0.75em',
    marginRight: '10px',
  },
}));

export const NavigationAddButton = styled(ButtonComponent)(({ theme }) => ({
  minWidth: 'unset',
  padding: '8px',
  borderRadius: '0 4px 4px 0',
  '&:first-child': {
    borderLeft: 0,
  },
  '&.Mui-disabled .MuiButton-label': {
    color: theme.palette.primary.contrastText,
  },
  '& .MuiButton-label': {
    color: theme.palette.primary.main
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5em',
  },
}));
