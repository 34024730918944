export const PROMISE_STATUSES = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
};

export const NOTIFICATION_TYPES = {
  INSTAGRAM: 'Instagram',
  SHOPIFY: 'Shopify',
};

export const FILE_ERROR_CODES = {
  FILE_TYPE: 'incorrect-file-type',
};

export const ALIGNMENTS = {
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
};

export const ALIGNMENTS_OPTIONS = [
  {
    id: ALIGNMENTS.LEFT,
    label: 'Left',
  },
  {
    id: ALIGNMENTS.MIDDLE,
    label: 'Middle',
  },
  {
    id: ALIGNMENTS.RIGHT,
    label: 'Right',
  },
];

export const NAVIGATION_BUTTON_TYPES = {
  CONTROL_BUTTON: 'control_button',
  SEARCH: 'search_setting',
  MENU: 'menu',
};

export const NAVIGATION_BUTTON_TYPES_OPTIONS = [
  {
    id: NAVIGATION_BUTTON_TYPES.CONTROL_BUTTON,
    label: 'Control Button',
  },
  {
    id: NAVIGATION_BUTTON_TYPES.MENU,
    label: 'Menu',
  },
  {
    id: NAVIGATION_BUTTON_TYPES.SEARCH,
    label: 'Product Search',
  },
];

export const DEFAULT_FUNC = () => {};

export const OKTA_LOGIN_URL = 'https://login.okta.com';

export const SSO_LOGIN_ERROR_QUERY_PARAM = 'with_error';

export const DESCRIPTION_DIALOG_STORAGE_KEY = 'unlock-description-dialog';
export const THEME_MODE_KEY = 'theme-mode';
