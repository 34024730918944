import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/styles';

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    body: CSSProperties;
    linkNav: CSSProperties;
    h1Heading: CSSProperties;
    h2Heading: CSSProperties;
    h3Heading: CSSProperties;
    h4Heading: CSSProperties;
    paragraphLarge: CSSProperties;
    paragraph: CSSProperties;
    newCaption: CSSProperties;
    labelButtonLarge: CSSProperties;
    labelButtonSmall: CSSProperties;
    labelLozenge: CSSProperties;
  }

  interface TypographyOptions {
    body?: CSSProperties;
    linkNav?: CSSProperties;
    h1Heading: CSSProperties;
    h2Heading?: CSSProperties;
    h3Heading?: CSSProperties;
    h4Heading?: CSSProperties;
    paragraphLarge?: CSSProperties;
    paragraph?: CSSProperties;
    newCaption?: CSSProperties;
    labelButtonLarge?: CSSProperties;
    labelButtonSmall?: CSSProperties;
    labelLozenge?: CSSProperties;
  }
}

const typography: TypographyOptions = {
  fontFamily: [
    'Inter',
    'Nunito',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 1.16,
  },
  h2: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: '16px',
    fontWeight: 500,
  },
  h4: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: '12px',
    fontWeight: 600,
  },
  h6: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.43,
  },
  body1: {
    fontSize: 14,
  },
  subtitle1: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  button: {
    textTransform: 'none',
  },

  // Typography from Zeplin styleguide
  body: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  h1Heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  h2Heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  h3Heading: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  h4Heading: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  paragraphLarge: {
    fontSize: '22px',
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  paragraph: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.2,
  },
  caption: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  labelButtonLarge: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  labelButtonSmall: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  labelLozenge: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  linkNav: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
};

export default typography;
