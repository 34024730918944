import { ClientShoppingProvider } from 'types';

export const defaultSettings: ClientShoppingProvider = {
  connected: false,
  syncStatus: undefined,
  apiKey: '',
  shopName: '',
  password: '',
  storefrontAccessToken: '',
  sharedSecret: '',
  shopifyMultipassSecret: '',
  shopifyMultipassUrl: '',
};
