import React, { useEffect, useState } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccountCard } from 'components';
import { authModel } from 'models';
import { AUTH_HEADERS } from 'models/constants';
import { ROUTES } from 'routes/constants';
import { getQueryParams } from 'utils';

import { AuthCard, FormLink } from '../style';

const SelectAccountPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [accounts, setAccounts] = useState([]);
  const [preAuthToken, setPreAuthToken] = useState();

  useEffect(() => {
    const preAuthToken = getQueryParams().pre_auth_token;

    if (preAuthToken) {
      setPreAuthToken(preAuthToken);
      authModel.listAccounts({ token: preAuthToken }).then(response => {
        if (response.pubtoolToken) {
          localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, response.pubtoolToken);
          history.push(ROUTES.home);
        } else if (response.accounts.length === 0)
          history.push({
            pathname: ROUTES.authGoogleAccountNotFound,
            search: `?pre_auth_token=${preAuthToken}`,
          });
        else setAccounts(response.accounts);
      });
    } else history.push(ROUTES.authSignIn);
  }, []);

  const onSelectAccount = account => {
    authModel
      .socialSignIn({
        id: account.id,
        token: preAuthToken,
      })
      .then(response => {
        if (response.pubtoolToken) {
          localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, response.pubtoolToken);
          history.push(ROUTES.home);
        }
      });
  };

  const onBackToSignIn = () => history.push(ROUTES.authSignIn);

  if (accounts.length === 0) return null;

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.selectAccountPage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.selectAccountPage.title')}</Typography>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {accounts.map(account => (
            <Grid item key={account.client}>
              <AccountCard title={account.client} text={account.email} onClick={() => onSelectAccount(account)} />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <FormLink variant="text" text={t('pages.auth.common.toSignInBtn')} onClick={onBackToSignIn} />
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default SelectAccountPage;
