import React, { createContext, useReducer, PropsWithChildren } from 'react';

import { DEFAULT_FUNC, DESCRIPTION_DIALOG_STORAGE_KEY, THEME_MODE_KEY } from 'constants/technical';
import { AuthTypes, UserAttributes, UserState } from 'types/user';
import { clearLocalStorage } from 'utils';
import { generateContextHook } from 'utils/helpers/contextHelpers';

import authReducer from './reducer';
import { AuthDispatchContextType } from './types';

const initialState: UserState = {
  currentUser: {
    isLoading: false,
    isSignedIn: false,
    attributes: {} as UserAttributes,
  },
};

const AuthStateContext = createContext<UserState>(initialState);
const AuthDispatchContext = createContext<AuthDispatchContextType>({
  login: DEFAULT_FUNC,
  updateUserData: DEFAULT_FUNC,
  verifyToken: DEFAULT_FUNC,
  logOut: DEFAULT_FUNC,
  toggleLoading: DEFAULT_FUNC,
});

const AuthProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const dispatchValue: AuthDispatchContextType = {
    login: userData => dispatch({ type: AuthTypes.LOG_IN, payload: userData }),
    logOut: () => {
      clearLocalStorage([DESCRIPTION_DIALOG_STORAGE_KEY, THEME_MODE_KEY]);
      return dispatch({ type: AuthTypes.LOG_OUT, payload: initialState });
    },
    updateUserData: userAttributes => dispatch({ type: AuthTypes.UPDATE_USER_DATA, payload: userAttributes }),
    verifyToken: userData => dispatch({ type: AuthTypes.VERIFY_TOKEN, payload: userData }),
    toggleLoading: () => dispatch({ type: AuthTypes.TOGGLE_LOADING }),
  };

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatchValue}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = generateContextHook(AuthStateContext, 'Auth');
const useAuthActions = generateContextHook(AuthDispatchContext, 'Auth');

export { AuthProvider, useAuthState, useAuthActions };
