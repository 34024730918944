import React from 'react';

import { Box, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';

import { LINKS } from 'components/constants';
import ModeToggle from 'components/ModeToggle/ModeToggle';
import { useAuthState } from 'contexts/AuthContext';
import { ROUTES } from 'routes/constants';

import { ProfilePreview } from '../ProfilePreview/ProfilePreview';

import { SidebarCategory } from './SidebarCategory/SidebarCategory';
import { sidebarList } from './sidebarList';
import { SidebarNavLink } from './SidebarNavLink/SidebarNavLink';

import {
  List,
  Items,
  Brand,
  CozmosIcon,
  Typography,
  SidebarSection,
  SidebarFooter,
  HelpContainer,
  TermsContainer,
  SidebarLink,
  Drawer,
  SpaceName,
} from './style';

export function Sidebar() {
  const { currentUser } = useAuthState();

  const { t } = useTranslation();

  const renderCategory = category => (
    <SidebarCategory
      isCollapsable={false}
      name={category.id}
      style={category.style}
      to={category.path}
      activeClassName="active"
      component={SidebarNavLink}
      icon={category.icon}
      badge={category.badge}
      exact
    />
  );

  return (
    <Drawer variant="permanent">
      <Brand component={Link} to={ROUTES.home}>
        <CozmosIcon />
        <SpaceName>{currentUser.attributes.clientName}</SpaceName>
      </Brand>
      <PerfectScrollbar>
        <List disablePadding>
          {sidebarList(currentUser.attributes.role).map(category => (
            <React.Fragment key={category.id}>
              {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}
              {renderCategory(category)}
            </React.Fragment>
          ))}
        </List>
      </PerfectScrollbar>
      <Box marginTop="auto">
        <SidebarSection>{t('components.sidebar.helpAndGuides')}</SidebarSection>
        <HelpContainer>
          <SidebarLink href={LINKS.sphereKnowledgeBase} target="_blank" rel="noopener noreferrer">
            {t('components.sidebar.knowledgebaseLink')}
          </SidebarLink>
          <SidebarLink href={LINKS.cozmosDiscordCommunity} target="_blank" rel="noopener noreferrer">
            {t('components.sidebar.discordCommunityLink')}
          </SidebarLink>
          <SidebarLink href={`mailto:${LINKS.contactEmail}`}>{t('common.links.contact')}</SidebarLink>
        </HelpContainer>
        <SidebarFooter>
          <ProfilePreview />
          <TermsContainer>
            <SidebarLink href={LINKS.terms} target="_blank" rel="noopener noreferrer">
              {t('common.links.terms')}
            </SidebarLink>
            <SidebarLink href={LINKS.privacyPolicy} target="_blank" rel="noopener noreferrer">
              {t('common.links.privacy')}
            </SidebarLink>
          </TermsContainer>
          <ModeToggle />
        </SidebarFooter>
      </Box>
    </Drawer>
  );
}
