import React, { useState } from 'react';

import { Checkbox, Grid, Divider } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { getTWithPathKey } from 'utils';

import { Box, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '../style';

interface Props {
  isOpen: boolean;
  handleToggleDialog: () => void;
  handleReSync: (options: Array<string>) => Promise<void>;
}

// const initialSelectedOptions = ['Languages', 'Products', 'Currencies', 'Translations', 'Policies'];
const initialSelectedOptions = [
  {
    title: 'Languages',
    id: 'languages'
  },
  {
    title: 'Products',
    id: 'products'
  },
  {
    title: 'Currencies',
    id: 'currency'
  },
  {
    title: 'Translations',
    id: 'translations'
  },
  {
    title: 'Policies',
    id: 'policies'
  }
];

export function ResyncDataDialog({
  isOpen,
  handleToggleDialog,
  handleReSync,
}: Props) {
  const getT = getTWithPathKey('pages.ecommerceProvider.sync');
  const getCommonT = getTWithPathKey('common.buttons');
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const onCheckedCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    checked ?
      setSelectedOptions(prev => [...prev, value]) :
      setSelectedOptions(prev => prev.filter(el => el !== value));
  }

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setIsSelectAll(checked);

    checked ?
      setSelectedOptions(initialSelectedOptions.map(option => option.id)) :
      setSelectedOptions([]);
  }

  return isOpen ? (
    <Dialog open={isOpen} onClose={handleToggleDialog} fullWidth>
      <DialogTitle id="simple-dialog-title" className="pb-0">
        {getT('dialogTitle')}
      </DialogTitle>
      <ValidatorForm onSubmit={() => handleReSync(selectedOptions)}>
        <DialogContent>
          <Box mb={4}>
            <Typography className="hintDescription">{getT('syncTitle')}</Typography>
          </Box>
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <Box mt={-4} ml={-3} className="resyncCheckboxWrapper">
                <Checkbox checked={isSelectAll} disableRipple onChange={handleToggleAll} />
                <Typography variant="caption">{getT('toggleTitle')}</Typography>
              </Box>
            </Grid>
            {initialSelectedOptions.map((option, index) => (
              <Grid item xs={4} key={option.id}>
                <Box mt={-4} ml={-3} className={`resyncCheckboxWrapper ${isSelectAll ? 'fade' : ''}`}>
                  <Checkbox
                    checked={selectedOptions.includes(option.id)}
                    disabled={isSelectAll}
                    value={option.id}
                    disableRipple
                    onChange={onCheckedCheckboxChange}
                  />
                  <Typography variant="caption">{option.title}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box>
            <Divider />
          </Box>
          <Box mt={2}>
            <Typography className="hintDescription">{getT('hint')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={handleToggleDialog} text={getCommonT('cancel')} />
          <ButtonComponent
            disabled={!selectedOptions.length}
            type="submit"
            color="primary"
            text={getT('buttonTitle')}
          />
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  ) : null;
}
