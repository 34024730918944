import React from 'react';

import PropTypes from 'prop-types';

import { InfoOutlinedIcon, Tooltip } from './style';

export function InfoTooltip({ tooltip, size }) {
  return (
    <Tooltip title={tooltip} placement="right-start">
      <InfoOutlinedIcon className={size} />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  size: PropTypes.string,
};

InfoTooltip.defaultProps = {
  size: 'regular',
};
