import React, { memo } from 'react';

import { Box } from '@material-ui/core';

import { ButtonComponent, CardComponent, ItemWithData } from 'components';
import { useAuthState } from 'contexts/AuthContext';
import { passwordModel } from 'models';
import { ROLES } from 'routes/constants';
import { AdminInfo } from 'types';
import { AuthProvider } from 'types/user';
import { formatSSOProviderType } from 'utils/helpers/stringHelpers';

export interface AccountAccessProps {
  admin: AdminInfo;
  handleServerError: (error: any) => void;
  setSuccessActionMessages: (success: string) => void;
}

function AccountAccessComponent({ admin, handleServerError, setSuccessActionMessages }: AccountAccessProps) {
  const { currentUser } = useAuthState();

  const handleReset = () =>
    passwordModel
      .createPasswordAuthenticated({ clientName: admin.client.name, email: admin.email })
      .then(({ success }) => setSuccessActionMessages(success))
      .catch(handleServerError);

  const handleInvite = () =>
    passwordModel
      .createPasswordInviteAuthenticated({
        clientName: admin.client.name,
        email: admin.email,
      })
      .then(({ success }) => setSuccessActionMessages(success))
      .catch(handleServerError);

  if (admin.role === ROLES.systemAdmin)
    return (
      <CardComponent title="ACCOUNT ACCESS" xs={9}>
        <ItemWithData title="Login" data={admin.email} />
        <ItemWithData title="Password">
          <Box mb={3}>
            <ButtonComponent
              color="primary"
              variant="text"
              className="no-padding"
              onClick={handleReset}
              text="Reset password"
            />
          </Box>
          <Box>
            <ButtonComponent
              color="primary"
              variant="text"
              className="no-padding"
              onClick={handleInvite}
              text="Send invitation"
            />
          </Box>
        </ItemWithData>
      </CardComponent>
    );

  return (
    <CardComponent title="ACCOUNT ACCESS" xs={9}>
      {admin.provider === AuthProvider.Google && <ItemWithData title="Invited as" data={admin.invitedAs ?? '-'} />}
      <ItemWithData title="Validated login" data={admin.email} />
      {admin.provider === AuthProvider.Email && (
        <ItemWithData title="Password">
          <ButtonComponent
            color="primary"
            variant="text"
            className="no-padding"
            onClick={handleReset}
            text="Reset password"
          />
        </ItemWithData>
      )}
      <ItemWithData title="SSO Type" data={formatSSOProviderType(admin.provider)} />
    </CardComponent>
  );
}

export const AccountAccess = memo(AccountAccessComponent);
