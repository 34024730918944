import { api } from './setupApi';

class MaintenanceModel {
  link = '/maintenance';

  setIngressName = (ingressName: string) =>
    api.post(`${this.link}/kube_update_ingresses`, { indicator_ingress_name: ingressName }).then(({ data }) => data);
}

export const maintenanceModel = new MaintenanceModel();
