import { styled, TextField as MuiTextField } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const TextField = styled(MuiTextField)({
  '& .MuiInput-underline:before, & .MuiInput-underline:after': { content: 'none' },
  '& .MuiInputBase-input': { fontSize: '12px', padding: '0' },
});

export const CalendarTodayIcon = styled(CalendarToday)({
  fontSize: ICON_SIZE.regular,
  marginRight: '4px',
  color: COLORS.greyText,
});
