import React, { useState, useEffect, useRef, ChangeEvent } from 'react';

import { Grid, Typography, Box, TableCell, TableRow, Divider } from '@material-ui/core';
import { isArray, set, isEqual, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  CardComponent,
  ColorPicker,
  FontSettingsControlWrapper,
  ImageChooseComponent,
  SettingsForm,
  TabPanelItem,
  TabsComponent,
} from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { MultilanguageTextValidator } from 'components/FormComponents';
import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { useSubmittingState } from 'hooks';
import { CookiesPopUp, CookiesPopUpFont, PaginationParams, Props } from 'types';
import { otherAssetsModel, cookiesPopupModel } from 'models';
import {
  getFontSettingsWithNums,
  getFontSettingsWithPx,
  getImageThumbUrl,
  getTWithPathKey,
  handleGetTextValue
} from 'utils';
import { ComponentWithPageLanguageProps } from 'utils/enhancers/withPageLanguage';
import ExperienceCookiesDefaultIcon from 'assets/icons/assets/experience-cookies-default-icon.svg';

import { MultilanguageCookiesPopupPreview } from './CookiesPopupPreview/CookiesPopupPreview';

import { ImageUploadCaption, Switch } from 'components/style';

const defaultCookiesSettings: CookiesPopUp = {
  id: 1,
  primaryButtonColor: '#30B96B',
  secondaryButtonColor: '#181b25',
  backgroundColor: '#181B25',
  upperBackgroundColor: '#30B96B',
  cookiesPopUpFonts: [],
  description: [],
  headerText: [],
  upperHeaderText: [],
  useImage: null,
  hideIcon: false,
};
const FORM_ID = 'cookiesPopup';
const DEFAULT_UPPER_ICON = { id: 1, url: ExperienceCookiesDefaultIcon, isDefault: true };

export const CookiesPopup = ({
  pageLanguageTabValue,
  setSuccessActionMessages,
  handleValidationCheck
}: Props & ComponentWithPageLanguageProps): JSX.Element => {
  const getT = getTWithPathKey('components.ecommerce.userProfile');
  const [tabValue, setTabValue] = useState(0);
  const [initialCookiesSettings, setInitialCookiesSettings] = useState<CookiesPopUp>(defaultCookiesSettings);
  const [cookiesSettings, setCookiesSettings] = useState<CookiesPopUp>(defaultCookiesSettings);
  const [fontSettingsEdit, setFontSettingsEdit] = useState<CookiesPopUpFont | undefined>();
  const [fontSettingsIndexMap, setFontSettingsIndexMap] = useState<Record<string, number>>({});
  const refForm = useRef<HTMLFormElement>();
  const { t } = useTranslation();

  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    getCookiesData();
  }, []);

  const getCookiesData = withPageProgressHandler(() =>
    cookiesPopupModel.getData().then(setNewSettings).catch(handleServerError),
  );

  const setNewSettings = (data: CookiesPopUp) => {
    const withImage = Boolean(data.useImage);
    const settings: CookiesPopUp = {
      ...data,
      cookiesPopUpFonts: data.cookiesPopUpFonts.map((font, index) => {
        setFontSettingsIndexMap(prev => ({ ...prev, [font.assignment]: index }));
        return getFontSettingsWithNums(font);
      }) as CookiesPopUpFont[],
      bottomIcon: data.otherAssets?.find(asset => asset.title === 'bottom') ?? null,
      upperIcon: data.otherAssets?.find(asset => asset.title === 'upper') ?? DEFAULT_UPPER_ICON,
      useImage: withImage,
    };

    if (withImage) {
      setTabValue(1);
    }
    setInitialCookiesSettings(cloneDeep(settings));
    setCookiesSettings(settings);
  };
  const getOtherAssets = (params: PaginationParams) => otherAssetsModel.getImages(params).catch(handleServerError);
  const handleChangeText = (item: Record<string, any>) => setCookiesSettings(prev => ({ ...prev, ...item }));
  const handleChangeItem = (itemName: string) =>
    (value: string | boolean) => setCookiesSettings(prev => ({ ...prev, [itemName]: value }));
  const handleChangeColor =
    (itemName: string) =>
    ({ hex }: Record<string, string>) =>
      setCookiesSettings(prev => ({ ...prev, [itemName]: hex }));
  const closeFontSettingsDialog = () => setFontSettingsEdit(undefined);
  const handleFontSettingsSave = (settings: CookiesPopUpFont) => {
    const index: number = fontSettingsIndexMap?.[settings.assignment];

    closeFontSettingsDialog();
    setCookiesSettings(prev => ({
      ...prev,
      ...set(prev, ['cookiesPopUpFonts', index], settings),
    }));
  };

  const handleSubmit = withHandlingSubmittingState(async ({ target: { id } }) => {
    try {
      if (id !== FORM_ID) {
        return;
      }
      const { bottomIcon, upperIcon, otherAssets, ...rest } = cookiesSettings;
      const cookiesPopUpFonts = cookiesSettings.cookiesPopUpFonts.map(getFontSettingsWithPx);
      const withImage = getWithImage();
      const dataToSave = { ...rest, cookiesPopUpFonts, useImage: withImage } as CookiesPopUp;

      if (withImage) {
        dataToSave.bottomOtherAssetId = bottomIcon?.id;
      }
      if (!upperIcon.isDefault) {
        dataToSave.upperOtherAssetId = upperIcon?.id;
      }

      const data = await cookiesPopupModel.putData(dataToSave);

      setNewSettings(data.cookiesPopUp);
      setSuccessActionMessages(data.success);
    } catch (err) {
      handleServerError(err as ServerErrorType);
    }
  });

  const getIsSaveButtonVisible = () =>
    !isSubmitting && !isEqual(initialCookiesSettings, { ...cookiesSettings, useImage: getWithImage() });

  const getWithImage = () => tabValue === 1;

  const getHeaderTextValidators = () => (getWithImage() ? [] : [VALIDATORS_MAP.required]);

  // TODO investigate change form validation approach
  const handleRevalidateForm = () => refForm?.current?.submit();

  const handleChangeTab = (value: number) => {
    handleRevalidateForm();
    setTabValue(value);
  };

  const getItemValue = (item: string) =>
    isArray(cookiesSettings[item]) ? cookiesSettings[item][pageLanguageTabValue ?? 0]?.value : cookiesSettings[item];

  const getTransPath = (key: string) => `pages.settings.cookiesSettings.cookiesPopup.${key}`;

  return (
    <SettingsForm
      isSaveButtonVisible={getIsSaveButtonVisible()}
      handleSave={handleSubmit}
      id={FORM_ID}
      refForm={refForm}
    >
      <CardComponent sm={8} title={t(getTransPath('title'))} isLoading={isSubmitting}>
        <Grid container spacing={6}>
          <Grid item sm={12} lg={6}>
            <OneColumnExpandedTable title="Sphere Experience" caption="Edit font, colour and icon settings">
              <TableRow>
                <TableCell colSpan={2}>
                  <Grid item xs={5}>
                    <Box mb={4}>
                      <Typography variant="h4">Icon</Typography>
                    </Box>
                    <ImageChooseComponent
                      image={cookiesSettings.upperIcon}
                      setImage={val => {
                        if (!val.id) {
                          setCookiesSettings(prev => ({
                            ...prev,
                            upperIcon: { ...DEFAULT_UPPER_ICON }
                          }))
                        } else {
                          handleChangeItem('upperIcon')(val);
                        }
                      }}
                      handleServerError={handleServerError}
                      dialogTitle={t(getTransPath('dialogTitle'))}
                      getPictures={getOtherAssets}
                      withDelete={!cookiesSettings.upperIcon?.isDefault}
                    />
                    <Typography variant="subtitle1" className="information" style={{ marginTop: '10px' }}>
                      {getT('uploaderHint')}
                    </Typography>
                    <Box mb={2}>
                      <Typography variant="h4">
                        <span>Hide icon</span>
                        <Switch checked={cookiesSettings.hideIcon} onChange={({ target: { checked }}: ChangeEvent<HTMLInputElement>) => handleChangeItem('hideIcon')(checked)} />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <FontSettingsControlWrapper
                      fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.upper_header]}
                      handleSettingsEdit={setFontSettingsEdit}
                      multiLangValue={cookiesSettings.upperHeaderText}
                    >
                      <Grid item sm={9}>
                        <MultilanguageTextValidator
                          fieldName="upperHeaderText"
                          label="Header text"
                          value={cookiesSettings.upperHeaderText}
                          handleChange={handleChangeText}
                          pageLanguageTabValue={pageLanguageTabValue}
                          multilanguage={isArray(cookiesSettings.upperHeaderText)}
                          handleGetValue={handleGetTextValue('upperHeaderText')}
                        />
                      </Grid>
                    </FontSettingsControlWrapper>
                    <FontSettingsControlWrapper
                      wrapperClassName="mt-auto mb-auto"
                      fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.options]}
                      handleSettingsEdit={setFontSettingsEdit}
                    >
                      <Grid item style={{ margin: 'auto 0' }}>
                        <Typography variant="h4">Experience options font</Typography>
                      </Grid>
                    </FontSettingsControlWrapper>
                    <Grid item sm={12}>
                      <Box mt={6} mb={3}>
                        <Typography variant="h4">Background color</Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={12}>
                      <ColorPicker
                        setColor={handleChangeColor('upperBackgroundColor')}
                        color={{ hex: cookiesSettings.upperBackgroundColor }}
                      />
                    </Grid>
                    <Grid container>
                      <Grid item sm={12}>
                        <Box my={5}>
                          <Divider />
                        </Box>
                        <Typography variant="subtitle1" className="information">
                          Please note. The audio option will only appear
                          if the Sphere has audio enabled and media assigned.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </OneColumnExpandedTable>
            <OneColumnExpandedTable title="Cookies" caption="Edit text, font, colour and icon settings">
              <TableRow>
                <TableCell colSpan={2}>
                  <Grid item sm={12}>
                    <Typography variant="h4">{t(getTransPath('subtitle'))}</Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Box my={3}>
                      <TabsComponent value={tabValue} setValue={handleChangeTab} tabNames={['Text', 'Image']} />
                    </Box>
                  </Grid>
                  <Grid item sm={12}>
                    <TabPanelItem value={tabValue} index={0}>
                      <Grid container>
                        <FontSettingsControlWrapper
                          fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.header]}
                          handleSettingsEdit={setFontSettingsEdit}
                          multiLangValue={cookiesSettings.headerText}
                        >
                          <Grid item sm={9}>
                            <MultilanguageTextValidator
                              fieldName="headerText"
                              label="Header text"
                              value={cookiesSettings.headerText}
                              handleChange={handleChangeText}
                              pageLanguageTabValue={pageLanguageTabValue}
                              handleValidationCheck={handleValidationCheck}
                              validators={getHeaderTextValidators()}
                              multilanguage={isArray(cookiesSettings.headerText)}
                              handleGetValue={handleGetTextValue('headerText')}
                            />
                          </Grid>
                        </FontSettingsControlWrapper>
                      </Grid>
                    </TabPanelItem>
                  </Grid>
                  <Grid item sm={12}>
                    <TabPanelItem value={tabValue} mb={2} index={1}>
                      <Grid container spacing={3}>
                        <Grid item sm={4} style={{ minWidth: '150px' }}>
                          <ImageChooseComponent
                            image={cookiesSettings.bottomIcon}
                            setImage={handleChangeItem('bottomIcon')}
                            handleServerError={handleServerError}
                            dialogTitle={t(getTransPath('dialogTitle'))}
                            getPictures={getOtherAssets}
                          />
                        </Grid>
                        <Grid item sm={8}>
                          <ImageUploadCaption>Choose a file from already uploaded other assets.</ImageUploadCaption>
                        </Grid>
                      </Grid>
                    </TabPanelItem>
                  </Grid>
                  <Grid container>
                    <FontSettingsControlWrapper
                      fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.description]}
                      handleSettingsEdit={setFontSettingsEdit}
                      multiLangValue={cookiesSettings.description}
                    >
                      <Grid item sm={9}>
                        <MultilanguageTextValidator
                          fieldName="description"
                          label="Sub-header text"
                          value={cookiesSettings.description}
                          handleChange={handleChangeText}
                          pageLanguageTabValue={pageLanguageTabValue}
                          multilanguage={isArray(cookiesSettings.description)}
                          handleGetValue={handleGetTextValue('description')}
                        />
                      </Grid>
                    </FontSettingsControlWrapper>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h4">Analytic Cookies text description</Typography>
                  </Grid>
                  <Grid container>
                    <FontSettingsControlWrapper
                      fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.text]}
                      handleSettingsEdit={setFontSettingsEdit}
                      multiLangValue={cookiesSettings.text}
                    >
                      <Grid item sm={9}>
                        <MultilanguageTextValidator
                          InputLabelProps={{ shrink: false }}
                          fieldName="analyticText"
                          multiline
                          rows={6}
                          placeholder="These cookies are optional and collect information about how visitors use and experience our website in order to optimise design, operations, efficiency and to improve your user experience."
                          value={cookiesSettings.analyticText}
                          handleChange={handleChangeText}
                          pageLanguageTabValue={pageLanguageTabValue}
                          multilanguage={isArray(cookiesSettings.analyticText)}
                          handleGetValue={handleGetTextValue('analyticText')}
                        />
                      </Grid>
                    </FontSettingsControlWrapper>
                  </Grid>
                  <Grid item sm={12}>
                    <Box mt={2} mb={3}>
                      <Typography variant="h4">Background color</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={12}>
                    <ColorPicker
                      setColor={handleChangeColor('backgroundColor')}
                      color={{ hex: cookiesSettings.backgroundColor }}
                    />
                  </Grid>
                  <Box mt={6} mb={3}>
                    <Typography variant="h4">Primary button color</Typography>
                  </Box>
                  <Grid item sm={12}>
                    <ColorPicker setColor={handleChangeColor('primaryButtonColor')} color={{ hex: cookiesSettings.primaryButtonColor }} />
                  </Grid>
                  <Box mt={6} mb={3}>
                    <Typography variant="h4">Secondary button color</Typography>
                  </Box>
                  <Grid item sm={12}>
                    <ColorPicker setColor={handleChangeColor('secondaryButtonColor')} color={{ hex: cookiesSettings.secondaryButtonColor }} />
                  </Grid>
                  <Grid item sm={12}>
                    <Box mt={6}>
                      <Grid container>
                        <FontSettingsControlWrapper
                          wrapperClassName="mt-auto mb-auto"
                          fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.title]}
                          handleSettingsEdit={setFontSettingsEdit}
                        >
                          <Grid item style={{ margin: 'auto 0' }}>
                            <Typography variant="h4">Cookies pop-up title fonts</Typography>
                          </Grid>
                        </FontSettingsControlWrapper>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={12}>
                    <Box mt={6}>
                      <Grid container>
                        <FontSettingsControlWrapper
                          wrapperClassName="mt-auto mb-auto"
                          fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.text]}
                          handleSettingsEdit={setFontSettingsEdit}
                        >
                          <Grid item style={{ margin: 'auto 0' }}>
                            <Typography variant="h4">Cookies pop-up text fonts</Typography>
                          </Grid>
                        </FontSettingsControlWrapper>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={12}>
                    <Box mt={6}>
                      <Grid container>
                        <FontSettingsControlWrapper
                          wrapperClassName="mt-auto mb-auto"
                          fontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.button]}
                          handleSettingsEdit={setFontSettingsEdit}
                        >
                          <Grid item style={{ margin: 'auto 0' }}>
                            <Typography variant="h4">Cookies pop-up button fonts</Typography>
                          </Grid>
                        </FontSettingsControlWrapper>
                      </Grid>
                    </Box>
                  </Grid>
                </TableCell>
              </TableRow>
            </OneColumnExpandedTable>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Grid item sm={12}>
              <Box mb={3}>
                <Typography variant="h4">Preview</Typography>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <MultilanguageCookiesPopupPreview
                multilanguage={isArray(cookiesSettings.headerText) && isArray(cookiesSettings.description)}
                value={cookiesSettings.description}
                pageLanguageTabValue={pageLanguageTabValue}
                backgroundColor={cookiesSettings.backgroundColor}
                buttonColor={cookiesSettings.primaryButtonColor}
                headerText={getItemValue('headerText')}
                headerTextFontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.header]}
                description={getItemValue('description')}
                analyticText={getItemValue('analyticText')}
                descriptionFontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.description]}
                buttonFontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.button]}
                titlesFontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.title]}
                experienceOptionsSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.options]}
                experienceTitleSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.upper_header]}
                textFontSettings={cookiesSettings.cookiesPopUpFonts[fontSettingsIndexMap.text]}
                useImage={getWithImage()}
                headerImageLink={cookiesSettings?.bottomIcon?.id ? getImageThumbUrl(cookiesSettings.bottomIcon) : null}
                experienceTitle={getItemValue('upperHeaderText')}
                experienceBackgroundColor={cookiesSettings.upperBackgroundColor}
                experienceImageLink={getImageThumbUrl(cookiesSettings.upperIcon)}
                hideIcon={cookiesSettings.hideIcon}
                secondaryButtonColor={cookiesSettings.secondaryButtonColor}
                pageLanguageTabVal={pageLanguageTabValue}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardComponent>
      <FontSettingsEditDialog
        settings={fontSettingsEdit}
        handleClose={closeFontSettingsDialog}
        handleSet={handleFontSettingsSave}
      />
    </SettingsForm>
  );
}
