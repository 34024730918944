import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';

import { useAuthState } from 'contexts/AuthContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { ButtonComponent } from 'components';
import { clientsModel } from 'models';
import { ClientExtendedInfo } from 'types';

export default function DeleteSpace() {
  const { t } = useTranslation();

  const { currentUser } = useAuthState();
  const { clientId } = currentUser.attributes;
  const { withPageProgressHandler } = useBusyProgressActions();

  const [client, setClient] = useState<ClientExtendedInfo | undefined>();

  const [newName, setNewName] = useState<string>('');

  useEffect(() => {
    getClient();
  }, [clientId]);

  const getClient = withPageProgressHandler(() =>
    clientsModel.getClient(clientId).then(client => {
      setClient(client);
      setNewName(client?.fullName ?? '');
    })
  );

  const onSubmit = (e: React.SyntheticEvent) => {
    if (client && client.fullName !== newName) clientsModel.editClient(clientId, { fullName: newName });
    e.preventDefault();
  };

  return (
    <>
      <Grid container direction="row" spacing={5} alignItems="center">
        <Grid item>
          <TextField
            variant="outlined"
            label={t('pages.owners.manageSpace.info.legalName')}
            value={newName}
            disabled={client === undefined}
            onChange={e => setNewName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <ButtonComponent text={t('pages.owners.manageSpace.save')} onClick={onSubmit} color="secondary" />
        </Grid>
      </Grid>
    </>
  );
}
