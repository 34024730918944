import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { CardComponent } from 'components';
import { useAuthState } from 'contexts/AuthContext';

import DeleteSpace from './DeleteSpace';
import RenameSpace from './RenameSpace';

export default function ManageSpace() {
  const { t } = useTranslation();

  const { currentUser } = useAuthState();

  return (
    <>
      <div>
        <PageWithHeader
          tabTitle={t('pages.owners.manageSpace.title')}
          headerText={t('pages.owners.manageSpace.defaultLandingTitle')}
        >
          <CardComponent title={t('pages.owners.manageSpace.info.title')}>
            <RenameSpace />
          </CardComponent>
          <CardComponent title={t('pages.owners.manageSpace.owner.title')}>
            <Typography variant="h6" display="inline">
              {currentUser.attributes.email}
            </Typography>
          </CardComponent>
          <CardComponent title={t('pages.owners.manageSpace.delete.title')}>
            <DeleteSpace />
          </CardComponent>
        </PageWithHeader>
      </div>
    </>
  );
}
