import React, { ComponentType, Dispatch, useState, SetStateAction } from 'react';

import { CardComponent } from 'components/CardComponent/CardComponent';

import { Switch } from 'components/style';

export interface WithCardWrapperProps {
  title: string;
  withLoader?: boolean;
  loadingText?: string;
  CardHeaderNav?: ComponentType;
  withBlockSwitcher?: boolean;
  isSwitcherEnabled?: boolean;
  handleSwitcherChange?: (param: boolean) => void;
}

export interface ComponentWithCardWrapperProps {
  setLoader: Dispatch<SetStateAction<boolean>>;
  withBlockSwitcher?: boolean;
}

export const withCardWrapper =
  <T,>(Component: ComponentType<T & ComponentWithCardWrapperProps>) =>
  ({
    title = '',
    withLoader,
    loadingText,
    CardHeaderNav,
    withBlockSwitcher,
    isSwitcherEnabled,
    handleSwitcherChange = () => {},
    ...props
  }: WithCardWrapperProps): JSX.Element => {
    const [isLoader, setLoader] = useState(false);

    const renderPreviewNav = (): JSX.Element | null =>
      withBlockSwitcher ? (
        <Switch checked={isSwitcherEnabled} onChange={() => handleSwitcherChange(!isSwitcherEnabled)} />
      ) : null;

    const isBlockEnabled = withBlockSwitcher && isSwitcherEnabled;
    const isBlockVisible = isBlockEnabled || !withBlockSwitcher;

    return (
      <CardComponent
        title={title}
        isLoading={withLoader && isLoader}
        loadingText={withLoader ? loadingText : ''}
        sm={12}
        CardHeaderNav={renderPreviewNav}
      >
        {isBlockVisible && <Component {...(props as T)} setLoader={setLoader} withBlockSwitcher={withBlockSwitcher} />}
      </CardComponent>
    );
  };
