import React from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Typography, Grid } from './style';

interface AssetsItemComponentProps {
  icon?: JSX.Element;
  title: string;
  caption: string;
  to: string;
}

export function AssetsItemComponent({ icon, title, caption, to }: AssetsItemComponentProps) {
  const history = useHistory();

  const handleClick = () => (to ? history.push(to) : '');

  return (
    <Grid container wrap="nowrap" className={to ? 'link' : 'inactive'} onClick={handleClick}>
      <Grid item>
        <Box>{icon}</Box>
      </Grid>
      <Grid item>
        <Typography variant="h2" className="title">
          {title}
        </Typography>
        <Typography className="caption">{caption}</Typography>
      </Grid>
    </Grid>
  );
}
