import { InternalSubdomainsListResponse } from 'types/domains';

import { api } from './setupApi';

class InternalSubdomainsModel {
  link = '/internal_subdomains';

  getLink = (clientId: number) => `clients/${clientId}${this.link}`;

  getInternalSubdomains = (clientId: number): Promise<InternalSubdomainsListResponse> =>
    api.get<InternalSubdomainsListResponse>(this.getLink(clientId)).then(({ data }) => data);

  createInternalSubdomain = (subdomain: string, clientId: number) =>
    api.post(this.getLink(clientId), { subdomain }).then(({ data }) => data);

  updateInternalSubdomain = (subdomain: string, id: number, clientId: number) =>
    api.put(`${this.getLink(clientId)}/${id}`, { subdomain }).then(({ data }) => data);

  deleteInternalSubdomain = (id: number, clientId: number) => api.delete(`${this.getLink(clientId)}/${id}`);
}

export const internalSubdomainsModel = new InternalSubdomainsModel();
