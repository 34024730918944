import { styled } from '@material-ui/core';
import { TextValidator as ReactTextValidator } from 'react-material-ui-form-validator';

export const TextValidator = styled(ReactTextValidator)({
  '& legend': {
    maxWidth: 'none!important',
    visibility: 'visible!important',
  },
  '& .MuiFormLabel-root': { display: 'none' },
});
