import { styled, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Title = styled(Typography)({
  fontSize: '12px',
  fontWeight: 'bold',
  color: COLORS.cozmosPurple4,
});

export const Description = styled(Typography)({
  fontSize: '14px',
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
});

export const Caption = styled(Typography)({
  fontSize: '12px',
  color: COLORS.greyText,
});

export const Highlight = styled('span')({
  color: COLORS.black,
  fontWeight: 500,
});

export const Link = styled('a')({
  color: COLORS.cozmosBlue,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});
