import { Button as MuiButton, styled } from '@material-ui/core';

export const Button = styled(MuiButton)(({ theme }) => ({
  ...theme.typography.h5,
  padding: 0,
  color: 'inherit',
  textDecoration: 'none',
  opacity: '0.5',

  '&:hover': { opacity: '1' },
}));
