import {
  Box as MuiBox,
  Typography as MuiTypography,
  CircularProgress as MuiCircularProgress,
  withStyles,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '& .MuiIconButton-root': {
      marginRight: '16px',
      padding: '4px',
      '& .MuiSvgIcon-root': {
        height: ICON_SIZE.regular,
        width: ICON_SIZE.regular,
      },
    },
  },
})(MuiBox);

export const Typography = withStyles({
  root: {
    paddingRight: '6px',
    '&.success': {
      color: COLORS.greenDeep,
    },
    '&.error': {
      color: COLORS.red,
    },
    '&.isLoading': {
      color: COLORS.orange,
    },
  },
})(MuiTypography);

export const DoneIcon = withStyles({
  root: {
    height: ICON_SIZE.regular,
    width: ICON_SIZE.regular,
    marginRight: '5px',
    '&.success': {
      color: COLORS.greenDeep,
    },
    '&.error': {
      color: COLORS.red,
    },
  },
})(Done);

export const CircularProgress = withStyles({
  root: {
    height: `${ICON_SIZE.regular}!important`,
    width: `${ICON_SIZE.regular}!important`,
    marginRight: '5px',
  },
})(MuiCircularProgress);
