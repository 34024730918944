import { CSSProperties } from 'react';

import { head, isEmpty } from 'lodash';

export const getFontName = (fontName: string): string => `app-${head(fontName.split('.'))}`;

export const getFontFormat = (fileName: string): string => {
  const fontFormat = head(fileName.match(/\.[0-9a-z]+$/i))?.substring(1);

  switch (fontFormat) {
    case 'ttf':
      return 'truetype';
    case 'otf':
      return 'opentype';
    case 'woff':
      return 'woff';
    case 'woff2':
      return 'woff2';
    default:
      return '';
  }
};

export const getFontSettingsWithPx = (font: Record<string, string>): Record<string, any> => ({
  ...font,
  lineHeight: `${Number(font.lineHeight) / Number(font.fontSize)}`,
  letterSpacing: `${font.letterSpacing}px`,
  fontSize: `${font.fontSize}px`,
});

export const getFontSettingsWithNums = (font: Record<string, string>): Record<string, string> => {
  const fontSize = parseInt(font.fontSize, 10).toString();
  const lineHeight = Math.round(Number(font.lineHeight) * Number(fontSize)).toString();
  const letterSpacing = parseFloat(font.letterSpacing).toString();

  return { ...font, fontSize, lineHeight, letterSpacing };
};

export const getInlineFontStyles = (fontSettings: Record<string, string>): CSSProperties => {
  const styles: Record<string, string> = {};

  if (!isEmpty(fontSettings)) {
    styles.color = fontSettings.color;
    styles.fontSize = `${fontSettings.fontSize}px`;
    styles.fontWeight = fontSettings.fontWeight;
    styles.letterSpacing = `${fontSettings.letterSpacing}px`;
    styles.lineHeight = `${fontSettings.lineHeight}px`;
  }

  return styles;
};

export const getFontFeatureStyle = (fontFeature: string): string =>
  fontFeature.match(/^\w{4}$/) ? `"${fontFeature}"` : fontFeature;
