import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  DialogContentText as MuiDialogContentText,
  Typography as MuiTypography,
  Box as MuiBox,
  Checkbox as MuiCheckbox,
  Chip,
  withStyles,
} from '@material-ui/core';
import { ErrorOutline, Warning } from '@material-ui/icons';

import { COLORS } from 'theme/constants';

export const ErrorOutlineIcon = withStyles({
  root: { color: COLORS.red, fontSize: '20px', marginRight: '8px' },
})(ErrorOutline);

export const WarningIcon = withStyles({
  root: { color: COLORS.red, fontSize: '20px', marginRight: '8px' },
})(Warning);

export const Dialog = withStyles({
  root: {
    '& .MuiPaper-root': {
      padding: '28px',
      borderRadius: '8px',
    },
    '&.large .MuiPaper-root': {
      maxWidth: '70%',
    },
    '& .titleWrapper': {
      display: 'flex'
    },
    '& .titleWrapper > div': {
      fontSize: '12px'
    }
  },
})(MuiDialog);

export const DialogTitle = withStyles(theme => ({
  root: {
    padding: '0 0 20px 0',
    '&.pb-0': { paddingBottom: 0 },
    '& .MuiTypography-root': { ...theme.typography.h2 },
    '&#simple-dialog-title h2': {
      paddingRight: '20px'
    },
  },
}))(MuiDialogTitle);

export const DialogContent = withStyles({
  root: {
    padding: 0,
    '&.cloneDialog:first-child': {
      padding: 0,
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiDivider-root': {
      backgroundColor: COLORS.cozmosPurple2,
    },
  },
})(MuiDialogContent);

export const DialogActions = withStyles({
  root: { display: 'flex', padding: '0' },
})(MuiDialogActions);

export const DialogContentText = withStyles(theme => ({
  root: {
    padding: '0 0 15px 32px',
    color: COLORS.black,
    whiteSpace: 'pre-line',
    textDecoration: 'none',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}))(MuiDialogContentText);

export const Typography = withStyles({
  root: {
    '& .MuiCheckbox-root': {
      padding: '0 0 0 0',
      marginRight: '6px',
    },
    '&.plGroupSubtitle': {
      fontSize: "14px",
      lineHeight: "1.43",
      color: "#202020",
    },
    '&.plGroupLanguagesBlock': {
      fontWeight: 600,
      color: '#202020',
      marginBottom: '16px',
    },
    '&.plGroupSource': {
      fontWeight: 600,
      color: '#3a9984',
      marginLeft: '16px',
    },
    '&.plClone': {
      marginBottom: '12px',
      fontSize: '12px',
      fontWeight: 600,
      color: 'black'
    },
    '&.hintDescription': {
      color: 'black'
    },
    color: COLORS.greyText,
  },
})(MuiTypography);

export const Box = withStyles({
  root: {
    marginBottom: '20px',
    '&.mb-3': {
      marginBottom: '12px'
    },
    '& .MuiFormControl-root .MuiFormControl-marginDense': {
      marginTop: '0px',
    },
    '&.resyncCheckboxWrapper .MuiTypography-root': {
      color: 'black',
    },
    '&.resyncCheckboxWrapper': {
      marginBottom: '12px',
    },
    '&.resyncCheckboxWrapper.fade .MuiTypography-root': {
      opacity: 0.4,
    },
    '&.resyncCheckboxWrapper .MuiCheckbox-root.Mui-disabled': {
      color: COLORS.cozmosBlue,
      opacity: 0.4,
    },
  },
})(MuiBox);

export const Checkbox = withStyles({
  root: {
    padding: 0,
    marginRight: '12px',
    '&.resyncCheckbox': {
      color: 'black'
    }
  },
})(MuiCheckbox);

export const ChipTag = withStyles(theme => ({
  root: {
    height: "32px",
    display: "flex",
    borderRadius: "16px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    '& .MuiChip-label': {
      fontSize: '14px',
      paddingLeft: '12px',
      paddingRight: '12px',
      textTransform: 'uppercase',
      color: 'black',
      fontWeight: 400,
    },

    '& .MuiChip-deleteIcon': {
      width: '17px',
      height: '17px',
      margin: '0 10px 0 -5px',
    },

    '&.MuiChip-deletable:focus': {
      backgroundColor: theme.palette.primary.main,
    },

    '&.Mui-disabled': {
      backgroundColor: COLORS.lightGreyBlue,
      fontSize: '12px',
      fontWeight: 600,
      color: COLORS.greyBtnText,
      opacity: 1,
    },
  }
}))(Chip);
