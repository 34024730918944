import { PLANOGRAM_ACTIONS, PLANOGRAM_VERSION_STATUS } from '../../constants';

export const CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION = {
  [PLANOGRAM_VERSION_STATUS.drafting]:
    'By this action you will create new draft Sphere version and replace previous one. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.generating]:
    'By this action you will create new generated Sphere version and replace previous one. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.publishing]:
    'This Sphere version will be published and replace previous one. Generated and Draft versions will be lost. Do you want to proceed?',
  [PLANOGRAM_ACTIONS.stopGenerating]: 'Sphere generation will be stopped. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.publishedDrafted]:
    'By this action you will create new draft Sphere version and replace previous one. Do you want to proceed?',
};

export const CONFIRMATION_MESSAGE_NEW_VERSION = {
  [PLANOGRAM_VERSION_STATUS.drafting]: 'This Sphere version will be clone to draft. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.generating]: 'This Sphere version will be generated. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.publishing]:
    'This Sphere version will be published. Generated and Draft versions will be lost. Do you want to proceed?',
  [PLANOGRAM_ACTIONS.stopGenerating]: 'Sphere generation will be stopped. Do you want to proceed?',
  [PLANOGRAM_VERSION_STATUS.publishedDrafted]: 'This Sphere version will be clone to draft. Do you want to proceed?',
};

export const CONFIRM_TEXT = {
  [PLANOGRAM_VERSION_STATUS.drafting]: 'Clone',
  [PLANOGRAM_VERSION_STATUS.generating]: 'Generate',
  [PLANOGRAM_VERSION_STATUS.publishing]: 'Publish',
  [PLANOGRAM_ACTIONS.stopGenerating]: 'Confirm',
  [PLANOGRAM_VERSION_STATUS.publishedDrafted]: 'Clone',
};
