import React, { memo } from 'react';

import { FormControlLabel } from '@material-ui/core';
import { set } from 'lodash';
import PropTypes from 'prop-types';

import { ButtonComponent, CardComponent, IconWithConfirmation } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { getTWithPathKey } from 'utils';

import { Switch } from 'components/style';
import { VideoLinkRow } from './style';

const tKey = getTWithPathKey('pages.products.manageProduct.productVideos');

function ProductVideosComponent({ productVideos, setProductVideos, videoFirst, setVideoFirst, readonly }) {
  const handleChangeProduct = ({ target: { checked } }) => setVideoFirst({ videoFirst: checked });

  const handleChangeVideo = index => value => setProductVideos(oldVideos => [...set(oldVideos, index, value)]);

  const handleAddVideo = () => setProductVideos(oldVideos => [...oldVideos, { videoUrl: '' }]);

  const handleRemoveVideo = index => setProductVideos(oldVideos => oldVideos.filter((l, i) => i !== index));

  const renderVideoNav = () => (
    <FormControlLabel
      control={<Switch checked={videoFirst} onChange={handleChangeProduct} />}
      label={tKey('beforePicturesSwitchLabel')}
      labelPlacement="start"
    />
  );

  return (
    <CardComponent title="VIDEOS" sm={12} CardHeaderNav={renderVideoNav}>
      {productVideos.map((link, i) => (
        <VideoLinkRow key={link.id || `new-${i}`}>
          <TextValidatorComponent
            validators={readonly ? [] : [VALIDATORS_MAP.youtubeLink, VALIDATORS_MAP.required]}
            label={tKey('youtubeLinkInputLabel')}
            value={link.videoUrl || ''}
            fieldName="videoUrl"
            handleChange={handleChangeVideo(i)}
            disabled={readonly}
          />
          {!readonly && (
            <IconWithConfirmation
              value={i}
              message={tKey('removeLinkMessageConfirmation')}
              handleConfirm={handleRemoveVideo}
            />
          )}
        </VideoLinkRow>
      ))}
      {!readonly && <ButtonComponent text={tKey('addVideoButtonText')} onClick={handleAddVideo} />}
    </CardComponent>
  );
}

ProductVideosComponent.propTypes = {
  productVideos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      videoUrl: PropTypes.string,
    }),
  ).isRequired,
  setProductVideos: PropTypes.func.isRequired,
  videoFirst: PropTypes.bool.isRequired,
  setVideoFirst: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

ProductVideosComponent.defaultProps = {
  readonly: false,
};

export const ProductVideos = memo(ProductVideosComponent);
