import React from 'react';

import { CssBaseline, Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LINKS } from 'components/constants';
import ModeToggle from 'components/ModeToggle/ModeToggle';
import { useThemeMode } from 'contexts/ThemeContext';
import { ThemeMode } from 'types/enums';

import {
  GlobalStyle,
  Root,
  Typography,
  Link,
  ToggleWrapper,
  PageWrapper,
  CozmosLogoLight,
  CozmosLogoDark,
} from './style';

interface AuthProps {
  children: React.ReactNode;
}

function Auth({ children }: AuthProps) {
  const { themeMode } = useThemeMode();
  const { t } = useTranslation();

  if (!React.Children.count(children)) return null;

  return (
    <PageWrapper>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Grid container direction="column" spacing={6}>
          <Grid item>{themeMode === ThemeMode.DARK ? <CozmosLogoLight /> : <CozmosLogoDark />}</Grid>
          <Grid item container justify="center">
            {children}
          </Grid>
          <Grid item>
            <Typography>
              {`${t('pages.auth.common.contactText')} `}
              <Link href={`mailto:${LINKS.contactEmail}`}>{t('pages.auth.common.contactLink')}</Link>
            </Typography>
          </Grid>
          <Grid container item justify="center" spacing={6}>
            <Grid item>
              <Link href={LINKS.terms} target="_blank" rel="noopener noreferrer">
                {t('pages.auth.common.terms')}
              </Link>
            </Grid>
            <Grid item>
              <Link href={LINKS.privacyPolicy} target="_blank" rel="noopener noreferrer">
                {t('pages.auth.common.privacy')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <ToggleWrapper>
          <ModeToggle />
        </ToggleWrapper>
      </Root>
    </PageWrapper>
  );
}

export default Auth;
