import React from 'react';

import { Grid } from '@material-ui/core';

import { COLORS } from 'theme/constants';

import { ImageCircleContainer, PreviewContainer, PreviewContainerText } from './style';

interface AudioButtonsPreviewProps {
  stopButtonUrl: string;
  muteButtonUrl: string;
  unmuteButtonUrl: string;
  backgroundColor: string;
}

const AudioButtonsPreview = ({
  stopButtonUrl,
  muteButtonUrl,
  unmuteButtonUrl,
  backgroundColor,
}: AudioButtonsPreviewProps) => (
  <Grid container>
    <Grid item xs={6}>
      <PreviewContainer style={{ backgroundColor: COLORS.cozmosPurple4 }}>
        <PreviewContainerText style={{ color: COLORS.cozmosPurple2 }}>Dark</PreviewContainerText>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={muteButtonUrl} alt="muteIconPreview" />
        </ImageCircleContainer>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={unmuteButtonUrl} alt="unmuteIconPreview" />
        </ImageCircleContainer>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={stopButtonUrl} alt="stopIconPreview" />
        </ImageCircleContainer>
      </PreviewContainer>
    </Grid>
    <Grid item xs={6}>
      <PreviewContainer style={{ backgroundColor: COLORS.cozmosBlueLight }}>
        <PreviewContainerText style={{ color: COLORS.black }}>Light</PreviewContainerText>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={muteButtonUrl} alt="muteIconPreview" />
        </ImageCircleContainer>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={unmuteButtonUrl} alt="unmuteIconPreview" />
        </ImageCircleContainer>
        <ImageCircleContainer style={{ backgroundColor }}>
          <img src={stopButtonUrl} alt="stopIconPreview" />
        </ImageCircleContainer>
      </PreviewContainer>
    </Grid>
  </Grid>
);

export default AudioButtonsPreview;
