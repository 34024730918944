import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { keys } from 'lodash';
import PropTypes from 'prop-types';

import { SliderComponent } from 'components';
import { getRangeValue } from 'utils';

import { CAMERA_POSITION_SETTINGS } from '../constants';

import { Box } from './style';

export function CameraPositionSlider({ value, setValue }) {
  const getCurrentValueStyles = useMemo(
    () => ({
      bottom: `
      ${
        CAMERA_POSITION_SETTINGS.blockHeight *
        getRangeValue(value, [CAMERA_POSITION_SETTINGS.values.min, CAMERA_POSITION_SETTINGS.values.max])
      }px`,
    }),
    [value],
  );

  const markStepLabels = useMemo(
    () =>
      keys(CAMERA_POSITION_SETTINGS.values)
        .sort()
        .map(value => ({
          value: CAMERA_POSITION_SETTINGS.values[value],
          label: String(CAMERA_POSITION_SETTINGS.values[value]),
        })),
    [CAMERA_POSITION_SETTINGS],
  );

  return (
    <Box className="wrapper">
      <SliderComponent
        value={value}
        setValue={setValue}
        min={CAMERA_POSITION_SETTINGS.values.min}
        max={CAMERA_POSITION_SETTINGS.values.max}
        step={CAMERA_POSITION_SETTINGS.step}
        markStep={CAMERA_POSITION_SETTINGS.markStep}
        markStepLabels={markStepLabels}
        text=""
        toFixedValue={2}
        orientation="vertical"
      />
      <Box className="current-value" style={getCurrentValueStyles} />
      <Box className="optimal-values">
        <Typography variant="caption">The most optimal range value</Typography>
      </Box>
      <Box className="text-labels">
        <Typography variant="caption">Top</Typography>
        <Typography variant="caption">Middle</Typography>
        <Typography variant="caption">Bottom</Typography>
      </Box>
    </Box>
  );
}

CameraPositionSlider.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};
