import { Box as MuiBox, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = withStyles({
  root: {
    display: 'inline-flex',
    backgroundColor: COLORS.lightGreyBlue,
    borderRadius: '4px',
    minWidth: '26px',
    textAlign: 'center',
    padding: '0 5px',
    whiteSpace: 'nowrap',
    height: '24px',
    margin: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    '&.shopify-description': {
      order: 0,
    },
  },
})(MuiBox);
