import { PaginationParams } from 'types/common';
import { PicturesTagParams, Tag, TagsListResponse, TagsSearchParams } from 'types/images';
import { trimItems } from 'utils';

import { api } from './setupApi';

class TagsModel {
  link = '/tags';

  attachPicturesLink = '/tags/attach_pictures';

  linkRemoveImage = '/tags/detach_pictures';

  getTagsWithOrder = ({ page, perPage }: PaginationParams): Promise<TagsListResponse> =>
    api
      .get<TagsListResponse>(`${this.link}?order_by=created_at&order_dir=desc&page=${page}&per_page=${perPage}`)
      .then(({ data }) => data);

  getTagsWithParams = (params: TagsSearchParams): Promise<TagsListResponse> =>
    api.get<TagsListResponse>(this.link, { params }).then(({ data }) => data);

  addTag = (name: string, color: string) =>
    api.post(this.link, trimItems({ name, color }, ['name'])).then(({ data: { tag } }) => tag);

  editTag = (id: number, data: Pick<Tag, 'name' | 'color'>) =>
    api.put(`${this.link}/${id}`, trimItems(data, ['name'])).then(({ data: { tag } }) => tag);

  attachPicturesTag = (data: PicturesTagParams) =>
    api.put(`${this.attachPicturesLink}`, data).then(({ data: { tag } }) => tag);

  deleteTag = (id: number) => api.delete(`${this.link}/${id}`);

  deleteImage = (data: PicturesTagParams) => api.put(`${this.linkRemoveImage}`, data);
}

export const tagsModel = new TagsModel();
