import React, { memo } from 'react';

import { Divider } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BuyButtonPreview } from 'components/BuyButtonPreview/BuyButtonPreview';
import { ArrowDownIcon, CollapseIcon } from 'components/CustomIcons';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from 'components/propTypesConsts';
import { COLORS } from 'theme/constants';
import { getInlineFontStyles, getTWithPathKey } from 'utils';
import { withLocalization } from 'utils/enhancers';

import { BUTTON_TYPES, BUTTON_SHAPES, FONT_TYPES } from '../constants';

import { Box } from '../style';

function ProductOverlayModePreviewComponent({ settings, lang, currentLanguageCode, disabled }) {
  const getT = getTWithPathKey('components.ecommerce.productOverlayPreview');

  const getButtonForPreview = button => ({
    ...button,
    title: button.title[lang].value,
    color: disabled ? button.colorDisabled : button.color,
    borderColor: disabled ? button.borderColorDisabled : button.borderColor,
    borderWidth: disabled ? button.borderWidthDisabled : button.borderWidth,
    useBorder: disabled ? button.useBorderDisabled : button.useBorder,
  });

  const getButtonByType = type => settings.ecommerceButtons.find(({ buttonType }) => type === buttonType);

  const buyNowBtn = getButtonForPreview(getButtonByType(BUTTON_TYPES.buyNow));
  const addToCartBtn = getButtonForPreview(getButtonByType(BUTTON_TYPES.addToCart));
  const getFontSettings = name => settings?.ecommerceFonts.find(({ assignment }) => assignment === name);

  return (
    <CustomFontWrapper
      font={getFontSettings(FONT_TYPES.body).font}
      style={getInlineFontStyles(getFontSettings(FONT_TYPES.body))}
    >
      <Box className="table-wrapper" p={7} style={{ backgroundColor: settings.backgroundColor.hex }}>
        <Box mb={2}>
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.header).font}
            style={getInlineFontStyles(getFontSettings(FONT_TYPES.header))}
          >
            {getT('name', { lng: currentLanguageCode })}
          </CustomFontWrapper>
        </Box>
        <Box mb={8.5}>
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.price).font}
            style={getInlineFontStyles(getFontSettings(FONT_TYPES.price))}
          >
            <Box display="flex" alignItems="center">
              <Box className={classnames(disabled && 'disabled-price')}>{getT('price')}</Box>
              <Box ml={1} p="6px 16px" className="stock">
                {getT(disabled ? 'notAvailable' : 'stock', { lng: currentLanguageCode })}
              </Box>
            </Box>
          </CustomFontWrapper>
        </Box>
        <Box>
          <Box display="flex" mb={6}>
            <CustomFontWrapper
              font={getFontSettings(FONT_TYPES.captions).font}
              style={getInlineFontStyles(getFontSettings(FONT_TYPES.captions))}
            >
              <Box mr={6}>{getT('table.size', { lng: currentLanguageCode })}</Box>
            </CustomFontWrapper>
            <CustomFontWrapper
              font={getFontSettings(FONT_TYPES.options).font}
              style={getInlineFontStyles(getFontSettings(FONT_TYPES.options))}
            >
              <Box mr={10}>{getT('table.xs')}</Box>
            </CustomFontWrapper>
            <Box style={{ color: COLORS.black }}>
              <ArrowDownIcon />
            </Box>
          </Box>
          <Box display="flex">
            <CustomFontWrapper
              font={getFontSettings(FONT_TYPES.captions).font}
              style={getInlineFontStyles(getFontSettings(FONT_TYPES.captions))}
            >
              <Box mr={6}>{getT('table.color', { lng: currentLanguageCode })}</Box>
            </CustomFontWrapper>
            <CustomFontWrapper
              font={getFontSettings(FONT_TYPES.options).font}
              style={getInlineFontStyles(getFontSettings(FONT_TYPES.options))}
            >
              <Box mr={10}>{getT('table.blue', { lng: currentLanguageCode })}</Box>
            </CustomFontWrapper>
            <Box style={{ color: COLORS.black }}>
              <ArrowDownIcon />
            </Box>
          </Box>
        </Box>
        <CustomFontWrapper
          font={getFontSettings(FONT_TYPES.button).font}
          style={getInlineFontStyles(getFontSettings(FONT_TYPES.button))}
        >
          <Box className={`buttons-wrapper ${settings.buttonShape}`}>
            <BuyButtonPreview key={addToCartBtn.id} button={addToCartBtn} isCustomButton />
            <BuyButtonPreview key={buyNowBtn.id} button={buyNowBtn} isCustomButton />
          </Box>
        </CustomFontWrapper>
        <Box my={4}>
          <Divider
            style={{
              borderBottom: `1px solid ${settings.secondaryColor.hex}`,
            }}
          />
        </Box>
        <Box mb={4} pr={4} display="flex" justifyContent="space-between" alignItems="center">
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.captions).font}
            style={getInlineFontStyles(getFontSettings(FONT_TYPES.captions))}
          >
            {getT('productDetails', { lng: currentLanguageCode })}
          </CustomFontWrapper>
          <CollapseIcon />
        </Box>
        <Box mb={4}>
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.body).font}
            style={getInlineFontStyles(getFontSettings(FONT_TYPES.body))}
          >
            {getT('caption')}
          </CustomFontWrapper>
        </Box>
      </Box>
    </CustomFontWrapper>
  );
}

ProductOverlayModePreviewComponent.propTypes = {
  settings: PropTypes.shape({
    ecommerceFonts: PropTypes.arrayOf(fontSettingsProp),
    ecommerceButtons: PropTypes.arrayOf(PropTypes.shape({})),
    buttonShape: PropTypes.oneOf(Object.values(BUTTON_SHAPES)),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  lang: PropTypes.number.isRequired,
  currentLanguageCode: PropTypes.string.isRequired,
};

ProductOverlayModePreviewComponent.defaultProps = {
  disabled: false,
};

export const ProductOverlayModePreview = withLocalization(memo(ProductOverlayModePreviewComponent));
