import React, { memo, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { pick } from 'lodash';
import PropTypes from 'prop-types';

import { CustomFontWrapper } from '../FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from '../propTypesConsts';

import { Box, Image, ImageBox } from './style';

export function SearchNoResultsPreviewComponent({ icon, fontSettings, title }) {
  const getStyles = useMemo(
    () =>
      fontSettings
        ? {
            ...pick(fontSettings, ['color', 'fontWeight', 'letterSpacing']),
            fontSize: `${fontSettings.fontSize}px`,
            lineHeight: `${fontSettings.lineHeight}px`,
            fontFamily: 'inherit',
          }
        : {},
    [fontSettings],
  );

  return (
    <Box className="wrapper">
      <CustomFontWrapper font={fontSettings?.font}>
        <Box className="image-wrapper">
          <ImageBox>
            <Image src={icon?.url} />
          </ImageBox>
          <Typography style={getStyles}>{title}</Typography>
        </Box>
      </CustomFontWrapper>
    </Box>
  );
}

SearchNoResultsPreviewComponent.propTypes = {
  icon: PropTypes.shape({
    url: PropTypes.string,
  }),
  title: PropTypes.string,
  fontSettings: fontSettingsProp,
};

SearchNoResultsPreviewComponent.defaultProps = {
  icon: null,
  fontSettings: null,
  title: '',
};

export const SearchNoResultsPreview = memo(SearchNoResultsPreviewComponent);
