import { COLORS } from 'theme/constants';
import { Image, ImageWithInlineStyles } from 'types/images';

/**
 * Function for adding background for images in case they are not visible on white bg
 * @param
 * img {Object}
 * @return
 * Object {...img, inlineStyles }
 * */
export const withBackgroundForSystemImg = (img: Image): ImageWithInlineStyles =>
  img.isSystem ? { ...img, inlineStyles: { backgroundColor: COLORS.greyDefault } } : img;
