import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Pagination } from './style';

export function PaginationComponent({ countPages, paginationParams, setPaginationParams, shape, className }) {
  useEffect(() => {
    if (countPages && paginationParams.page > countPages) {
      setPaginationParams(prevParams => ({
        ...prevParams,
        page: countPages,
      }));
    }
  }, [paginationParams.page]);

  const handlePageChange = (event, page) => {
    event.stopPropagation();

    setPaginationParams(prevParams => ({
      ...prevParams,
      page: page - 1,
    }));
  };

  return (
    <>
      {countPages ? (
        <Pagination
          color="primary"
          onChange={handlePageChange}
          size="small"
          count={countPages}
          page={paginationParams.page + 1}
          shape={shape}
          className={className}
          siblingCount={0}
        />
      ) : null}
    </>
  );
}

PaginationComponent.propTypes = {
  countPages: PropTypes.number,
  paginationParams: PropTypes.shape({ page: PropTypes.number }).isRequired,
  setPaginationParams: PropTypes.func.isRequired,
  shape: PropTypes.string,
  className: PropTypes.string,
};

PaginationComponent.defaultProps = {
  countPages: 0,
  shape: 'round',
  className: '',
};
