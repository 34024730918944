import React, { useMemo } from 'react';

import { Divider, Typography, Checkbox, MenuItem, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Select } from './style';

interface ConfirmationRedirectProps {
  enabled: boolean;
  delay?: number;
  onChange: (enabled: boolean, delay?: number) => void;
}

enum CheckboxOptions {
  DEFAULT,
  OFF,
  CUSTOM,
}

const ConfirmationRedirect = ({ enabled, delay, onChange }: ConfirmationRedirectProps) => {
  const { t } = useTranslation();

  const checkedOption = useMemo(() => {
    if (!enabled) return CheckboxOptions.OFF;
    if (delay === 10) return CheckboxOptions.DEFAULT;
    return CheckboxOptions.CUSTOM;
  }, [enabled, delay]);

  const customValue = useMemo(() => {
    if (checkedOption === CheckboxOptions.CUSTOM) return delay;
    return undefined;
  }, [checkedOption, delay]);

  return (
    <>
      <Grid item sm={12}>
        <Box mt={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box mt={8}>
          <Typography variant="h2" className="uppercase">
            {t('pages.ecommerceProvider.confirmationRedirectTitle')}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box mt={6}>
          <Typography className="information">{t('pages.ecommerceProvider.confirmationRedirectCaption')}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box mt={6}>
          <Checkbox checked={checkedOption === CheckboxOptions.DEFAULT} onChange={() => onChange(true, 10)} />
          <Typography variant="caption">{t('pages.ecommerceProvider.confirmationRedirectDefaultOption')}</Typography>
        </Box>
        <Box>
          <Checkbox checked={checkedOption === CheckboxOptions.OFF} onChange={() => onChange(false)} />
          <Typography variant="caption">{t('pages.ecommerceProvider.confirmationRedirectOffOption')}</Typography>
        </Box>
        <Typography
          variant="body2"
          style={{
            marginLeft: '10px',
            color: '#454545',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          {t('pages.ecommerceProvider.confirmationRedirectCustomOption')}
        </Typography>
        <Box mt={1}>
          <Checkbox checked={checkedOption === CheckboxOptions.CUSTOM} onChange={() => onChange(true)} />
          <Select
            variant="outlined"
            value={customValue}
            disabled={checkedOption !== CheckboxOptions.CUSTOM}
            onChange={e => onChange(true, e.target.value as number)}
            displayEmpty
            renderValue={value =>
              value
                ? `${value} ${t('common.words.seconds')}`
                : t('pages.ecommerceProvider.confirmationRedirectSelectPlaceholder')
            }
          >
            <MenuItem value={20}>{`20 ${t('common.words.seconds')}`}</MenuItem>
            <MenuItem value={40}>{`40 ${t('common.words.seconds')}`}</MenuItem>
            <MenuItem value={60}>{`60 ${t('common.words.seconds')}`}</MenuItem>
          </Select>
        </Box>
      </Grid>
    </>
  );
};

export default ConfirmationRedirect;
