import { IconButton as MuiIconButton, Grid as MuiGrid, styled, Typography } from '@material-ui/core';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  paddingRight: 0,
  '& svg': {
    color: theme.palette.sidebar.links,
    height: '16px',
    width: '16px',
  },
}));

export const Grid = styled(MuiGrid)(({ theme }) => ({
  '&.admin-name': {
    color: theme.palette.sidebar.headerText,
    textDecoration: 'none',
    maxWidth: 'calc(100% - 42px)',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const PreviewText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body,
  display: 'inline',
}));
