import { trim, isArray } from 'lodash';

// TODO: add generic in all used places
export const trimItems = <T extends Record<string, string>>(object: T, itemsToTrim: string[] = []): T => ({
  ...object,
  ...itemsToTrim.reduce((acc, key) => ({ ...acc, [key]: trim(object[key]) }), {}),
});

const getTrimmedValue = (value: string | Record<string, string>[]) => {
  if (!value) {
    return value;
  }
  if (!isArray(value)) {
    return trim(value);
  }
  return value.map(field => ({
    ...field,
    value: trim(field.value),
  }));
};

export const trimMultilanguageItems = <T extends Record<string, string>>(object: T, itemsToTrim: string[] = []): T => ({
  ...object,
  ...itemsToTrim.reduce((acc, key) => ({ ...acc, [key]: getTrimmedValue(object[key]) }), {}),
});
