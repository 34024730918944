import React, { useState, memo } from 'react';

import { Grid, Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  FontSettingsControlWrapper,
  ImageChooseComponent,
  SearchNoResultsPreview,
} from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { TextValidatorComponent } from 'components/FormComponents';
import { otherAssetsModel } from 'models';

import { Typography } from '../../style';

import { ImageUploadCaption } from 'components/style';

export const FORM_ID = 'noSearchResults';

export function NoSearchResultsComponent({
  noResultsSettings,
  setNoResultsSettings,
  planogramVersionId,
  handleServerError,
}) {
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});

  const getOtherAssets = params => otherAssetsModel.getImages(params).catch(handleServerError);

  const handleEditItem = item => value => setNoResultsSettings(prev => ({ ...prev, [item]: value }));

  const handleEditInput = item => setNoResultsSettings(prev => ({ ...prev, ...item }));

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsSave = settings => {
    setFontSettingsEdit(null);
    handleEditItem(settings.fieldName)(settings);
  };

  const handleFontSettingsEdit = settings => () =>
    setFontSettingsEdit(settings ? { ...settings } : { planogramVersionId });

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Box mt={6}>
          <Divider />
        </Box>
        <Typography variant="h2" className="uppercase">
          No search results
        </Typography>
        <Typography variant="h4" display="inline">
          Icon
        </Typography>
      </Grid>
      <Grid item sm={4} style={{ minWidth: '150px' }}>
        <ImageChooseComponent
          image={noResultsSettings.noResultImage}
          setImage={handleEditItem('noResultImage')}
          handleServerError={handleServerError}
          dialogTitle="Choose no results image"
          getPictures={getOtherAssets}
        />
      </Grid>
      <Grid item sm={8}>
        <ImageUploadCaption>This image will be shown when no search results found.</ImageUploadCaption>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" display="inline">
          No results text message
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Grid container>
          <FontSettingsControlWrapper
            fontSettings={noResultsSettings.noResultSearchSettingFont}
            handleSettingsEdit={handleFontSettingsEdit(noResultsSettings.noResultSearchSettingFont)}
          >
            <Grid item sm={6}>
              <TextValidatorComponent
                fieldName="noResultSearchTitle"
                label="Label"
                value={noResultsSettings.noResultSearchTitle}
                handleChange={handleEditInput}
              />
            </Grid>
          </FontSettingsControlWrapper>
          <FontSettingsEditDialog
            settings={fontSettingsEdit}
            handleClose={closeFontSettingsDialog}
            handleSet={handleFontSettingsSave}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h4" display="inline">
          Preview no results
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <SearchNoResultsPreview
          fontSettings={noResultsSettings?.noResultSearchSettingFont}
          icon={noResultsSettings.noResultImage}
          title={noResultsSettings.noResultSearchTitle}
        />
      </Grid>
    </Grid>
  );
}

NoSearchResultsComponent.propTypes = {
  noResultsSettings: PropTypes.shape({
    noResultSearchSettingFont: PropTypes.shape({}),
    noResultImage: PropTypes.shape({
      url: PropTypes.string,
    }),
    noResultSearchTitle: PropTypes.string,
  }).isRequired,
  setNoResultsSettings: PropTypes.func.isRequired,
  planogramVersionId: PropTypes.number,
  handleServerError: PropTypes.func.isRequired,
};

NoSearchResultsComponent.defaultProps = { planogramVersionId: null };

export const NoSearchResults = memo(NoSearchResultsComponent);
