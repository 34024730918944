import React from 'react';

import PropTypes from 'prop-types';

import { FileUpload } from '../FileUpload/FileUpload';
import { InputFile } from '../InputFile/InputFile';

import { Box } from './style';

export function LoadFileComponent({
  accept,
  text,
  file,
  loadFile,
  loadAsText,
  handleServerError,
  validationError,
  isLoading,
}) {
  const setFile = file => {
    if (loadAsText) {
      const reader = new FileReader();

      reader.onload = ({ target: { result } }) => loadFile(result);

      reader.readAsText(file);
    } else {
      loadFile(file);
    }
  };

  const handleDelete = () => loadFile('');

  return (
    <Box>
      {file || isLoading ? (
        <FileUpload handleDelete={handleDelete} validationError={validationError} isLoading={isLoading} />
      ) : (
        <InputFile accept={accept} text={text} setFile={setFile} handleServerError={handleServerError} />
      )}
    </Box>
  );
}
LoadFileComponent.propTypes = {
  accept: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  file: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  loadFile: PropTypes.func.isRequired,
  loadAsText: PropTypes.bool,
  handleServerError: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

LoadFileComponent.defaultProps = {
  file: null,
  loadAsText: false,
  validationError: '',
};
