import React from 'react';

import { Grid } from '@material-ui/core';

import { CardComponent, PageHeader, AssetsItemComponent } from 'components';
import { useAuthState } from 'contexts/AuthContext';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { getAuthorizedRoutes } from 'utils/auth';
import { withErrorHandling } from 'utils/enhancers';
import { ReactComponent as PurchasingFlowIcon } from 'assets/icons/settings/purchasing-flow-icon.svg';
import { ReactComponent as ErrorResponseIcon } from 'assets/icons/settings/error-response-icon.svg';
import { ReactComponent as OverlayIcon } from 'assets/icons/settings/overlay-icon.svg';
import { ReactComponent as CurrencyIcon } from 'assets/icons/settings/currency-icon.svg';
import { ReactComponent as CookieIcon } from 'assets/icons/settings/cookie-icon.svg';
import { ReactComponent as EcommerceIcon } from 'assets/icons/settings/ecommerce-icon.svg';
import { ReactComponent as EcommerceUiIcon } from 'assets/icons/settings/ecommerce-ui-icon.svg';
import { ReactComponent as SSOIcon } from 'assets/icons/settings/sso-icon.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/settings/analytics-icon.svg';
import { ReactComponent as DomainIcon } from 'assets/icons/settings/domain-icon.svg';
import { ReactComponent as BillingIcon } from 'assets/icons/settings/billing-icon.svg';
import { ReactComponent as SocialMediaIcon } from 'assets/icons/settings/social-media.svg';
import { ReactComponent as AnimationIcon } from 'assets/icons/settings/animation-icon.svg';

const SETTINGS_ITEMS: (keyof typeof ROUTES)[] = [
  'purchasingFlowSettings',
  'currencySettings',
  'errorResponseSettings',
  'cookiesSettings',
  'overlaysSettings',
  'ecommerceProviders',
  'ecommerceOverlays',
  'ssoProviders',
  'analyticsProviders',
  'domainManagementSettings',
  'billingSettings',
  'socialIntegration',
  'animationSettings',
];

const SETTINGS_ICONS: Partial<Record<keyof typeof ROUTES, JSX.Element>> = {
  purchasingFlowSettings: <PurchasingFlowIcon />,
  currencySettings: <CurrencyIcon />,
  errorResponseSettings: <ErrorResponseIcon />,
  cookiesSettings: <CookieIcon />,
  overlaysSettings: <OverlayIcon />,
  ecommerceProviders: <EcommerceIcon />,
  ecommerceOverlays: <EcommerceUiIcon />,
  ssoProviders: <SSOIcon />,
  analyticsProviders: <AnalyticsIcon />,
  domainManagementSettings: <DomainIcon />,
  billingSettings: <BillingIcon />,
  socialIntegration: <SocialMediaIcon />,
  animationSettings: <AnimationIcon />,
};

const Settings = () => {
  const {
    currentUser: {
      attributes: { shoppingPlatformName, role },
    },
  } = useAuthState();

  const getT = getTWithPathKey('pages.settings');

  const isRouteAllowed = (route: string) => getAuthorizedRoutes(role).includes(route);

  const renderSettingItem = (itemName: keyof typeof ROUTES) => (
    <Grid key={itemName} item sm={6}>
      <AssetsItemComponent
        icon={SETTINGS_ICONS[itemName]}
        title={getT(`${itemName}.title`)}
        caption={getT(`${itemName}.caption`)}
        to={ROUTES[itemName]}
      />
    </Grid>
  );

  return (
    <>
      <PageHeader title={getT('title')} />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Grid container spacing={6}>
            {SETTINGS_ITEMS.map(item => {
              const isEcommerceHidden = item === 'ecommerceOverlays' && !shoppingPlatformName;

              if (isEcommerceHidden || !isRouteAllowed(ROUTES[item])) return null;
              return renderSettingItem(item);
            })}
          </Grid>
        </CardComponent>
      </Grid>
    </>
  );
};

export default withErrorHandling(Settings);
