import { FormControl as MuiFormControl, withStyles } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { TextValidator as ReactTextValidator } from 'react-material-ui-form-validator';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const TextValidator = withStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    root: {
      '& .MuiInputBase-input': {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
  },
  root: {
    '& textarea': { minHeight: '18px' },
    '& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormHelperText-root': {
      ...theme.typography.caption,
    },
    '& .MuiInputBase-input': {
      height: 'auto',
      textTransform: 'inherit',
    },
  },
}))(ReactTextValidator);

export const FormControl = withStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
    minHeight: '70px',
    '&.error *': { color: COLORS.redError, borderColor: COLORS.redError },
    '& .MuiFormHelperText-root': { margin: '4px 14px 0' },
    '& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormHelperText-root': {
      ...theme.typography.caption,
    },
    '&.no-min-height': {
      minHeight: 0,
    },
    '&.shopify-description': {
      order: 2,
    },
    '&.no-wrap .MuiFormHelperText-root': {
      marginLeft: '0',
      whiteSpace: 'nowrap'
    },
  },
}))(MuiFormControl);

export const ClearIcon = withStyles({
  root: {
    color: COLORS.greyIcon,
    position: 'absolute',
    right: '32px',
    top: '18px',
    height: ICON_SIZE.regular,
    width: ICON_SIZE.regular,
    cursor: 'pointer',
  },
})(Clear);
