import React, { useEffect, useRef } from 'react';

import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from 'react-feather';

import { useDebounce } from 'hooks';

import { Search, SearchIconWrapper, Input } from './style';

export function SearchComponent({ value, setValue, getSearch }) {
  const debouncedSearchTerm = useDebounce(value);
  const onChange = event => setValue(event.target.value);
  const mounted = useRef(false);

  useEffect(() => {
    if (isString(debouncedSearchTerm) && mounted.current) {
      getSearch();
    }

    mounted.current = true;
  }, [debouncedSearchTerm]);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input fullWidth value={value || ''} onChange={onChange} />
    </Search>
  );
}

SearchComponent.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  getSearch: PropTypes.func.isRequired,
};

SearchComponent.defaultProps = {
  value: null,
};
