import { Box, styled, Typography as MuiTypography, IconButton as MuiIconButton } from '@material-ui/core';
import { Image } from '@material-ui/icons';

import { COLORS } from 'theme/constants';

export const ImageUploadWrapper = styled(Box)({
  border: '1px dashed rgba(45, 99, 243, 0.5)',
  borderRadius: '4px',
  backgroundColor: COLORS.cozmosBlueLight,
  textAlign: 'center',
  padding: '16px',
  position: 'relative',
  '&.max-height img': { minHeight: '36px' },
  '& img': { maxHeight: '90px', margin: '0 auto' },
  '.pointer': { cursor: 'pointer' },
  '&.audio-settings-icon': {
    '& img': { height: '57px', marginBottom: '6px' },
  },
});

export const ImageUploadLabel = styled('span')({
  fontWeight: '600',
  cursor: 'pointer',
  color: COLORS.cozmosBlue,
});

export const ImageIcon = styled(Image)({
  color: COLORS.cozmosBlue,
});

export const Typography = styled(MuiTypography)({
  display: 'block',
  color: COLORS.cozmosBlue,
});

export const RemoveIconContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
});

export const IconButton = styled(MuiIconButton)({
  padding: '6px',
});
