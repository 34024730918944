import { Box as MuiBox, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';
import { getRangeValue } from 'utils';

import { CAMERA_POSITION_SETTINGS } from '../constants';

const topPadding = 32;

export const Box = withStyles(theme => ({
  root: {
    '&.wrapper': {
      position: 'relative',
      marginLeft: '36px',
      '& .slider-component': {
        height: `${CAMERA_POSITION_SETTINGS.blockHeight + topPadding}px`,
        position: 'relative',
        zIndex: 2,
      },
      '& .MuiSlider-markLabel': {
        textAlign: 'right',
        left: '-64px',
        width: '50px',
      },
      '& .MuiSlider-valueLabel': {
        left: '-42px',
        top: '-2px',
        transform: 'none',
        '& span': { transform: 'none' },
        '& > span': { height: 'auto', width: 'auto' },
        '& span span': {
          display: 'block',
          color: theme.palette.primary.main,
          minWidth: '38px',
          fontWeight: '600',
          height: '20px',
          padding: '3px 6px 2px 5px',
          borderRadius: '2px',
          boxShadow: '0 1px 2px 0 rgba(69, 69, 69, 0.5)',
          backgroundColor: COLORS.white,
        },
      },
    },
    '&.optimal-values': {
      background: COLORS.greenLight,
      color: COLORS.greyText,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: '16px',
      position: 'absolute',
      right: 0,
      left: '14px',
      top: `${
        topPadding +
        CAMERA_POSITION_SETTINGS.blockHeight *
          (1 -
            getRangeValue(CAMERA_POSITION_SETTINGS.values.optimalMax, [
              CAMERA_POSITION_SETTINGS.values.min,
              CAMERA_POSITION_SETTINGS.values.max,
            ]))
      }px`,
      bottom: `
      ${
        CAMERA_POSITION_SETTINGS.blockHeight *
        getRangeValue(CAMERA_POSITION_SETTINGS.values.optimalMin, [
          CAMERA_POSITION_SETTINGS.values.min,
          CAMERA_POSITION_SETTINGS.values.max,
        ])
      }px`,
    },
    '&.current-value': {
      background: theme.palette.primary.main,
      position: 'absolute',
      right: 0,
      zIndex: 1,
      height: '1px',
      left: '14px',
      top: 'auto',
      willChange: 'bottom',
    },
    '&.text-labels': {
      color: COLORS.greyText,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: '26px',
      top: '16px',
    },
  },
}))(MuiBox);
