import React from 'react';

import PropTypes from 'prop-types';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';

import { WarningIcon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '../style';

export function ConfirmationDialog({
  open,
  message,
  handleYes,
  handleNo,
  confirmText,
  showWarningIcon,
  title,
  children,
}) {
  return (
    <Dialog onClick={event => event.stopPropagation()} open={open} onClose={handleNo} fullWidth>
      <DialogTitle id="alert-dialog-title">
        {showWarningIcon && <WarningIcon />}
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ paddingLeft: showWarningIcon ? '32px' : '0' }}>
          {message}
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {handleNo && <ButtonComponent onClick={handleNo} text="Cancel" />}
        <ButtonComponent onClick={handleYes} type="submit" color="primary" text={confirmText} />
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.node.isRequired,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func,
  confirmText: PropTypes.string,
  showWarningIcon: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

ConfirmationDialog.defaultProps = {
  confirmText: 'Delete',
  showWarningIcon: true,
  title: 'Confirmation dialog',
};
