import React from 'react';

import { useThemeMode } from 'contexts/ThemeContext';
import { ThemeMode } from 'types/enums';

import { Label, ToggleSwitchContainer, ToggleSwitchInput, Switch, Handle, Container } from './style';

function ModeToggle() {
  const { themeMode, toggleTheme } = useThemeMode();

  const checked = themeMode === ThemeMode.DARK;

  return (
    <Container>
      <Label>Light</Label>
      <ToggleSwitchContainer>
        <ToggleSwitchInput type="checkbox" checked={checked} onChange={toggleTheme} />
        <Switch>
          <Handle checked={checked} />
        </Switch>
      </ToggleSwitchContainer>
      <Label>Dark</Label>
    </Container>
  );
}

export default ModeToggle;
