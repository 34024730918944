import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContentText as MuiDialogContentText,
  TableRow as MuiTableRow,
  Box,
  withStyles,
} from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Dialog = withStyles({
  root: {
    '& .MuiDialog-paper': {
      padding: '28px',
    },

    '& .MuiDialogTitle-root': {
      paddingTop: 0,
    },

    '& .MuiDialogContent-root, & .MuiDialogTitle-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& .MuiDialogActions-root': {
      padding: '13px 32px 0 0',
    },
  },
})(MuiDialog);

export const DialogTitle = withStyles({
  root: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
})(MuiDialogTitle);

export const DialogContentText = withStyles({
  root: {
    fontWeight: 'normal',
    color: COLORS.black,
  },
})(MuiDialogContentText);

export const TableRow = withStyles({
  root: {
    '&:first-of-type': {
      '& .MuiTableCell-body': {
        paddingTop: '0',
      },
    },

    '&:last-of-type': {
      '& .MuiTableCell-body': {
        borderBottom: 'none',
        paddingBottom: '0',
      },
    },

    '& .MuiTableCell-body': {
      verticalAlign: 'top',
      padding: '10px 17px',

      '&:first-of-type': {
        paddingLeft: '0',
      },

      '&:last-of-type': {
        paddingRight: '0',
      },

      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
})(MuiTableRow);

export const ImageContainer = withStyles({
  root: {
    width: '75px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    marginRight: '24px',

    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
})(Box);
