import { TOptions } from 'i18next';

import { getI18n } from '../i18n';

export const generateLocalizationKeyGetter =
  (path: string) =>
  (key: string): string =>
    `${path}.${key}`;

export const getTWithPathKey =
  (path: string) =>
  (key: string, options: TOptions = {}): string => {
    const i18n = getI18n();

    return i18n.t(generateLocalizationKeyGetter(path)(key), options);
  };

export const getTranslation = (path: string, options: TOptions = {}): string => {
  const i18n = getI18n();

  return i18n.t(path, options);
};
