import { Box as MuiBox, Checkbox as MuiCheckbox, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const ButtonContainer = styled(MuiBox)({
  display: 'flex',
  minHeight: '100px',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
});

export const ShopifyMobileButtonContainer = styled(MuiBox)({
  display: 'flex',
  minHeight: '36px',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
});

export const ShareButton = styled(MuiBox)({
  height: '84px',
  width: '84px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
});

export const ShareButtonMobile = styled(MuiBox)({
  height: '20px',
  display: 'flex',
  padding: '0 12px',
  width: '100%',
  minWidth: '96px',
  maxWidth: '136px',
  alignItems: 'center',
  borderRadius: '100px',
  justifyContent: 'space-between',
  '& > span': {
    marginRight: '6px',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& > img': {
    height: '80%',
  },
});

export const ShareButtonIcon = styled('img')({
  height: '50%',
  objectPosition: 'center',
  objectFit: 'contain',
});

export const TitleContainer = styled(MuiBox)({
  width: '100%',
  margin: '14px 0 4px 0',
  display: 'flex',
  justifyContent: 'space-between',
});

export const Checkbox = styled(MuiCheckbox)({
  marginRight: '12px',
  padding: 0,
});

export const ShareIconPreviewContainer = styled(MuiBox)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '6px 8px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '6px',
  borderColor: COLORS.greyBorder,
  backgroundColor: COLORS.bgLightPreview,

  '&.dark-theme': {
    color: COLORS.greyBorder,
    borderColor: COLORS.greyBorder,
    backgroundColor: COLORS.darkPreview,
  },

  '& > img': {
    width: '100%',
    height: '100%',
    maxWidth: '64px',
    maxHeight: '64px',
    marginRight: '6px',
    objectFit: 'contain',
  },
});
