import { styled, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Description = styled(Typography)({
  fontSize: '14px',
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
});

export const Definitions = styled(Typography)({
  fontSize: '12px',
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
  lineHeight: 1.67,
});

export const Highlight = styled('span')({
  color: COLORS.black,
  fontWeight: 500,
});
