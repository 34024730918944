import React from 'react';

import PropTypes from 'prop-types';

import { Box, DragIndicatorIcon } from './style';

export function SortableList({ list, textPath }) {
  return (
    <>
      {list.map(item => (
        <Box key={item.id} className={item.enabled ? '' : 'no-display'}>
          <DragIndicatorIcon />
          {item[textPath]}
        </Box>
      ))}
    </>
  );
}

SortableList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      enabled: PropTypes.bool,
      text: PropTypes.string,
    }),
  ),
  textPath: PropTypes.string,
};

SortableList.defaultProps = {
  list: [],
  textPath: 'text',
};
