import React, { createContext, PropsWithChildren, useState } from 'react';

import { pick } from 'lodash';

import { DEFAULT_FUNC } from 'constants/technical';
import { FontItem, FontSettingsType } from 'types/fonts';
import { generateContextHook } from 'utils/helpers/contextHelpers';

export interface FontSettingsStateType {
  savedSettings: FontSettingsType | null;
  fontList: FontItem[];
}

type FontSettingsParam = FontSettingsType & Record<string, string | number | FontItem>;

export interface FontSettingsDispatchType {
  saveFontSettings: (settings: FontSettingsParam) => void;
  saveFontList: (fontList: FontItem[]) => void;
  resetFontList: () => void;
  resetFontSettings: () => void;
}

const initialState: FontSettingsStateType = {
  savedSettings: null,
  fontList: [],
};

const FontSettingsStateContext = createContext<FontSettingsStateType>(initialState);
const FontSettingsDispatchContext = createContext<FontSettingsDispatchType>({
  saveFontSettings: DEFAULT_FUNC,
  saveFontList: DEFAULT_FUNC,
  resetFontList: DEFAULT_FUNC,
  resetFontSettings: DEFAULT_FUNC,
});

const FontSettingsProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, setState] = useState<FontSettingsStateType>(initialState);

  const dispatchValue: FontSettingsDispatchType = {
    saveFontSettings: settings =>
      setState(prev => ({
        ...prev,
        savedSettings: pick(settings, [
          'fontId',
          'fontWeight',
          'fontSize',
          'letterSpacing',
          'lineHeight',
          'color',
          'font',
        ]) as FontSettingsType,
      })),
    saveFontList: fontList =>
      setState(prev => ({
        ...prev,
        fontList,
      })),
    resetFontList: () => {
      setState(prev => {
        if (prev.fontList.length) {
          return {
            ...prev,
            fontList: initialState.fontList,
          };
        }

        return prev;
      });
    },
    resetFontSettings: () => setState(initialState),
  };

  return (
    <FontSettingsStateContext.Provider value={state}>
      <FontSettingsDispatchContext.Provider value={dispatchValue}>{children}</FontSettingsDispatchContext.Provider>
    </FontSettingsStateContext.Provider>
  );
};

const useFontSettingsState = generateContextHook(FontSettingsStateContext, 'FontSettings');
const useFontSettingsActions = generateContextHook(FontSettingsDispatchContext, 'FontSettings');

export { FontSettingsProvider, useFontSettingsState, useFontSettingsActions };
