import { Box as MuiBox, TableCell as MuiTableCell, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = withStyles(theme => ({
  root: {
    '&.image-preview-wrapper': {
      height: '68px',
      width: '68px',
      margin: '30px auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderRadius: '50%',
      padding: '15px',
      '& > img.rounded': {
        borderRadius: '50%',
        padding: '15px',
      },
    },
    '&.table-wrapper': { border: `1px solid ${COLORS.greyBorder}`, borderRadius: '6px' },
    '&.color-picker-wrap .block-picker': { width: '128px!important' },
    '&.buttons-wrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > div': {
        width: '50%',
        '& .MuiBox-root': {
          minHeight: 'auto',
          '& .MuiBox-root': {
            minWidth: '48px',
            padding: '8px 15px',
            height: 'auto',
            minHeight: '34px',
            justifyContent: 'center',
            width: '100%',
          },
        },
      },
    },
    '&.square .MuiBox-root .MuiBox-root': {
      borderRadius: 0,
    },
    '&.checkout .MuiBox-root .MuiBox-root': {
      justifyContent: 'center',
    },
    '&.disabled-price': {
      opacity: 0.4,
    },
    '&.stock': {
      textTransform: 'uppercase',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '.25em',
        opacity: 0.1,
        backgroundColor: 'currentColor',
      },
    },
    '&.shopping-cart-product': {
      display: 'flex',
      flexWrap: 'nowrap',
      height: '230px',

      '& .img-wrap': {
        height: '64px',
        width: '64px',
        border: `1px solid ${COLORS.greyBorder}`,
        backgroundColor: COLORS.white,
        display: 'flex',
        alignItems: 'center',
        flex: 'none',
        '& .information': { fontSize: '8px', fontWeight: 600 },
      },
      '& .product-wrap': {
        flex: 'auto',
      },
    },
    '&.btn-wrap': {
      '& .overlay-button-minus': { opacity: 0.6 },
      '&.regular .overlay-button': {
        minWidth: '32px',
        minHeight: '32px',
        width: '32px',
        height: '32px',
      },
      '&.small .overlay-button': {
        minWidth: '22px',
        minHeight: '22px',
        width: '22px',
        height: '22px',
      },
    },
    '&.counter': {
      ...theme.typography.h3,
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate(25%, -25%)',
    },
    '&.m0': {
      margin: 0,
    },
    '& .MuiSwitch-root': {
      marginLeft: '12px'
    },
  },
}))(MuiBox);

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

export const CloseIcon = withStyles({
  root: {
    width: ICON_SIZE.regular,
    height: ICON_SIZE.regular,
  },
})(Close);
