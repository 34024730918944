import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { ConfirmReservedSubdomainDialog } from 'components/Dialogs/ConfirmReservedSubdomainDialog/ConfirmReservedSubdomainDialog';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { clientsModel } from 'models';
import { InternalSubdomain } from 'types/domains';

import { ButtonWrapper, DeleteButton, RootDomainBadge } from './style';

interface InternalSubdomainEntryProps {
  subdomain: Partial<InternalSubdomain>;
  onDelete?: () => void;
  onSave: (subdomain: string) => void;
  disableDelete?: boolean;
}

export const InternalSubdomainEntry = ({
  subdomain,
  onDelete,
  onSave,
  disableDelete = false,
}: InternalSubdomainEntryProps) => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();

  const [inputValue, setInputValue] = useState(subdomain.subdomain ?? '');
  const [reservedSubdomain, setReservedSubdomain] = useState<string | undefined>();

  const checkIfSubdomainReserved = () =>
    clientsModel
      .checkReservedName(inputValue)
      .then(response => {
        if (response.data.isReserved) setReservedSubdomain(inputValue);
        else saveChange();
      })
      .catch(handleServerError);

  const saveChange = () => {
    setReservedSubdomain(undefined);
    onSave(inputValue);
  };

  const cancelChange = () => {
    setReservedSubdomain(undefined);
    if (subdomain.subdomain) setInputValue(subdomain.subdomain);
  };

  return (
    <Grid item container direction="row">
      {!subdomain.isRoot && (
        <Box
          style={{
            flexGrow: 1,
            paddingRight: 14,
          }}
        >
          <TextValidatorComponent
            validators={[VALIDATORS_MAP.isValidCustomDomain, VALIDATORS_MAP.required]}
            label={t('components.internalSubdomains.subdomainLabel')}
            value={inputValue}
            fieldName="subdomain"
            handleChange={({ subdomain }: { subdomain: string }) => setInputValue(subdomain)}
            disableMinHeight
          />
        </Box>
      )}
      <Box
        style={{
          flexGrow: subdomain.isRoot ? 1 : 0,
          minWidth: 180,
        }}
      >
        <TextValidatorComponent
          value={subdomain.host?.substring(subdomain?.subdomain?.length ?? 0)}
          fieldName="host"
          label={t('components.internalSubdomains.hostLabel')}
          disableMinHeight
          disabled
        />
      </Box>
      {subdomain.isRoot && (
        <ButtonWrapper>
          <RootDomainBadge disabled>{t('components.internalSubdomains.rootDomain')}</RootDomainBadge>
        </ButtonWrapper>
      )}
      {subdomain.subdomain !== inputValue && (
        <ButtonWrapper>
          <ButtonComponent
            style={{ minWidth: 50 }}
            text={t('components.internalSubdomains.save')}
            color="primary"
            type="submit"
            onClick={() => checkIfSubdomainReserved()}
          />
        </ButtonWrapper>
      )}
      {!disableDelete && (
        <ButtonWrapper>
          <DeleteButton
            onClick={() => {
              if (onDelete) onDelete();
            }}
          />
        </ButtonWrapper>
      )}
      {reservedSubdomain && (
        <ConfirmReservedSubdomainDialog onSubmit={saveChange} onClose={cancelChange} subdomain={reservedSubdomain} />
      )}
    </Grid>
  );
};
