import React, { useEffect, useState } from 'react';

import { Grid, Paper } from '@material-ui/core';

import { CardComponent, PageHeader, InputFile, FontComponent } from 'components';
import { FontFeatureDialog } from 'components/Dialogs';
import { TablePaginationComponent } from 'components/TablePaginationComponent/TablePaginationComponent';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useFontSettingsActions } from 'contexts/FontSettingsContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { activeStorageModel, fontsModel } from 'models';
import { ROUTES } from 'routes/constants';
import {FontEditParams, FontItem, PaginationParams} from 'types';

import { Typography, Box } from './style';

function Fonts() {
  const [fonts, setFonts] = useState<FontItem[]>([]);
  const [isFontFeatureDialogOpen, setIsFontFeatureDialogOpen] = useState(false);
  const { handleServerError } = useServerErrorActions();
  const { resetFontList } = useFontSettingsActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const [paginationItemsCount, setPaginationItemsCount] = useState<number>(0);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ perPage: 10, page: 0 });

  const resetPaginationPage = () => setPaginationParams(prev => ({ ...prev, page: 0 }));

  useEffect(() => {
    getFonts();
  }, [paginationParams]);

  const getFonts = withPageProgressHandler(() =>
    fontsModel
      .getFonts(paginationParams)
      .then(({ fonts, fontsCount }) => {
        setFonts(fonts);
        setPaginationItemsCount(fontsCount);
      })
      .catch(handleServerError)
  );

  const handleLoadFont = (data: Record<'signedId', string>) => {
    fontsModel
      .addFont({ ...data })
      .then(() => {
        resetFontList();
        resetPaginationPage();
      })
      .catch(handleServerError);
  };

  const handleSetFontFile = (file: File) => {
    activeStorageModel.createUpload(file).create((error, blob) => {
      if (error) {
        handleServerError(error as ServerErrorType);
      } else {
        handleLoadFont({ signedId: blob.signed_id });
      }
    });
  };

  const handleDeleteFont = (id: number, formID: string) =>
    fontsModel
      .deleteFont(id)
      .then(() => {
        resetFontList();
        getFonts();
      })
      .catch(error => handleServerError(error, formID));

  const handleSave = ((id: number, data: FontEditParams, formID: string) =>
    fontsModel
      .editFont(id, data)
      .then(() => {
        resetFontList();
        getFonts();
      })
      .catch(error => handleServerError(error, formID))
  );

  return (
    <>
      <PageHeader title={`Font: ${paginationItemsCount}`} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title="Upload fonts">
          <Typography>
            Upload the font and set the name to be used inside the system. Formats allowed{' '}
            <span className="bold">.woff .ttf .otf.</span>
          </Typography>
          {fonts.map(font => (
            <FontComponent
              font={font}
              key={font.id}
              onDelete={handleDeleteFont}
              onSave={handleSave}
              openFontFeatureDialog={() => setIsFontFeatureDialogOpen(true)}
            />
          ))}
          <FontFeatureDialog isOpen={isFontFeatureDialogOpen} onClose={() => setIsFontFeatureDialogOpen(false)} />
          <Box>
            <InputFile
              accept=".woff, .ttf, .otf"
              text="Upload font"
              setFile={handleSetFontFile}
              handleServerError={handleServerError}
            />
          </Box>
        </CardComponent>
      </Grid>
      <Paper>
        <TablePaginationComponent
          count={paginationItemsCount}
          paginationParams={paginationParams}
          onChangePaginationParams={setPaginationParams}
        />
      </Paper>
    </>
  );
}

export default Fonts;
