import { styled, Select as MuiSelect } from '@material-ui/core';

export const Select = styled(MuiSelect)({
  height: '28px',
  width: '150px',
  marginLeft: '5px',
  '& .MuiOutlinedInput-input': {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
});
