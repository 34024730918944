import { styled, withStyles, Box as MuiBox, Button } from '@material-ui/core';

import { ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '&.flex': { display: 'flex', justifyContent: 'center' },
    '&.image-wrapper': { height: ICON_SIZE.regular, width: ICON_SIZE.regular, marginRight: '6px' },
  },
})(MuiBox);

export const AuthenticatorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.auth.formLink,
}));
