import React, { useState, useEffect, useRef } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { includes, isEmpty } from 'lodash';
import { Box, Checkbox, Grid } from '@material-ui/core';

import { ButtonComponent, CardComponent, PageHeader } from 'components';
import { ROUTES } from 'routes/constants';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { FileWithSignedId, PaginationProps } from 'types';
import { useDeleteSelectedAssets } from 'hooks';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { withPagination } from 'utils/enhancers';
import { audioModel } from 'models/AudioModel';
import { DropzoneAudio } from 'components/DropzoneAudio/DropzoneAudio';
import Dropzone from 'components/Dropzone/Dropzone';
import { ConfirmationDialog } from 'components/Dialogs';
import { COLORS } from 'theme/constants';

import { Typography } from 'components/ImageUploader/style';

export default withPagination(
  ({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }: PaginationProps) => {
    const dropzoneRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const { withPageProgressHandler } = useBusyProgressActions();
    const { handleServerError } = useServerErrorActions();
    const { handleDeleteAllRequest } = useDeleteSelectedAssets();

    const [showDialog, setShowDialog] = useState(false);
    const [selected, setSelected] = useState(false);
    const [selectedAudios, setSelectedAudios] = useState<number[]>([]);
    const [playingId, setPlayingId] = useState<number>();
    const [openDropzone, setOpenDropzone] = useState(false);
    const [assets, setAssets] = useState<any[]>([]);

    const updateAssets = () =>
      audioModel.getAudio({ ...paginationParams }).then(r => {
        setPaginationItemsCount(r.count);
        setAssets(r.audios);
      });

    useEffect(() => {
      updateAssets();
    }, [paginationParams]);

    const haveFilesToDelete = () => !!assets.filter(audio => !audio.isSystem)?.length;

    const loadAudio = withPageProgressHandler((file: FileWithSignedId) =>
      audioModel
        .createAudio({
          seoDesc: '',
          seoTitle: file.name,
          h1Tag: '',
          signedId: file.signed_id,
        })
        .then(resetPaginationPage)
        .catch(handleServerError),
    );

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selected = event.target.checked;
      const selectedAudios = selected ?
        assets.map(audio => audio.id) : [];

      setSelected(selected);
      setSelectedAudios(selectedAudios);
    };

    const handleChange = (id: number) => {
      const updatedSelectedAudioIds = includes(selectedAudios, id)
        ? selectedAudios.filter(imageId => imageId !== id)
        : [...selectedAudios, id];

      setSelectedAudios(updatedSelectedAudioIds);
    };

    const updateOnDelete = () => {
      setSelectedAudios([]);
      setSelected(false);
      updateAssets();
    };

    const handleDelete = withPageProgressHandler((id: number) =>
      audioModel.deleteAudio(id).then(updateAssets).catch(handleServerError),
    );

    return (
      <>
        <PageHeader title={`${t('pages.assets.Audio.title')}: ${paginationItemsCount}`} backLink={ROUTES.assets} />
        <Grid container spacing={6}>
          <CardComponent sm={12} title={t('pages.assets.Audio.cardTitle')}>
            <Box>
              <Trans i18nKey={t('pages.assets.Audio.description')} components={{ br: <br /> }} />
              <div style={{ color: COLORS.cozmosPurple2, marginTop: '24px' }}>{t('pages.assets.Audio.disclaimer')}</div>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="flex-end" mb={-2} marginTop="10px">
              {haveFilesToDelete() && (
                <Box flexGrow={1} ml={-2}>
                  <Typography variant="caption">
                    <Checkbox
                      color="primary"
                      checked={selected}
                      onChange={handleSelectAll}
                    />
                    <span>Select All</span>
                  </Typography>
                  {selectedAudios.length > 0 && (
                    <Box mr={2} display="inline-flex">
                      <ButtonComponent color="primary" text="Delete Selected" onClick={() => setShowDialog(true)} />
                    </Box>
                  )}
                </Box>
              )}
              <ButtonComponent
                text={t('pages.assets.Audio.uploadButton')}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                  dropzoneRef.current?.click();
                }}
                variant="text"
                className="blue ml"
              />
            </Box>
            <Dropzone
              ref={dropzoneRef}
              loadFile={loadAudio}
              accept={ACCEPT_EXTENSIONS.audio}
              section={DOWNLOAD_SECTIONS.audio}
              onClose={() => setOpenDropzone(false)}
            >
              {assets.map(asset => (
                <DropzoneAudio
                  key={asset.id}
                  audio={asset}
                  selectedAudios={selectedAudios}
                  onChangeSelected={handleChange}
                  isPlaying={playingId === asset.id}
                  onTogglePlay={() => setPlayingId(asset.id === playingId ? undefined : asset.id)}
                  handleDelete={handleDelete}
                />
              ))}
            </Dropzone>
          </CardComponent>
        </Grid>
        {showDialog && (
          <ConfirmationDialog
            open={showDialog}
            message={`Are you sure you want to delete ${selectedAudios.length} object(-s)?`}
            handleYes={() => {
              setShowDialog(false);
              handleDeleteAllRequest(selectedAudios, audioModel.deleteAudio, updateOnDelete);
            }}
            handleNo={() => setShowDialog(false)}
            confirmText="Confirm"
            title="Confirmation dialog"
          />
        )}
      </>
    );
  },
);
