export interface ShoppingProviderItem {
  id: number;
  title: string;
}

export enum ShoppingProviderStatus {
  idle = 'idle', // stored as null in the database
  inProgress = 'in_progress',
  success = 'success',
  failed = 'failed',
  disconnectInProgress = 'disconnect_in_progress',
  disconnectFailed = 'disconnect_failed',
}

export interface ClientShoppingProvider {
  id?: number;
  apiKey: string;
  connected?: boolean;
  password: string;
  sharedSecret: string;
  shopName: string;
  storefrontAccessToken: string;
  shopifyMultipassSecret: string;
  shopifyMultipassUrl: string;
  syncStatus?: ShoppingProviderStatus;
  redirectDelay?: number;
  redirectEnabled?: boolean;
}

export interface ShoppingProvider extends ShoppingProviderItem {
  clientShoppingProvider: ClientShoppingProvider;
}

export interface PurchasingFlowSettingsType {
  id: number;
  defaultInfoIconUrl: string;
  purchasingFlow: string;
}

export interface NotificationItem {
  title: string;
  body: string;
  createdAt: string;
}

export interface RedirectDelayParams {
  redirectDelay?: number;
  redirectEnabled?: boolean;
}
