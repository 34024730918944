import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Box, Divider, InputAdornment, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { Loader } from 'components';
import { SearchIcon } from 'components/CustomIcons';
import { planogramsModel } from 'models';
import {
  MenuItemType,
  PlanogramObjectData,
  formatNavigationValue,
  getCategoryOptions,
  getDisplayValue,
  getObjectMenuItems,
  getPlanogramMenuItems,
} from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/helper';
import { VALIDATORS_MAP } from 'components/FormComponents/const';

import { Switch } from 'components/style';
import { BackButton } from 'components/Dialogs/GuideDialog/style';
import {
  HeaderText,
  MenuItem,
  NoOptionsText,
  ChipItem,
  Button,
  StickyItem,
  TextValidator,
  Tooltip,
} from './style';

interface InternalObjectSelectProps {
  planogramObjectData: PlanogramObjectData[];
  value?: string;
  useNavigation: boolean;
  handleChange: (value: { navigationValueSelect: string; withoutNavigation?: boolean }) => void;
}

export enum Steps {
  SELECT_SPHERE,
  SELECT_CATEGORY,
  SELECT_OBJECT,
  SELECT_ACTION,
}

const InternalObjectSelector = ({
  planogramObjectData,
  value,
  useNavigation,
  handleChange
}: InternalObjectSelectProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [parsedVal, setParsedVal] = useState<string | undefined>();
  const [step, setStep] = useState<Steps>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState<MenuItemType[]>([]);
  const keepOpen = useRef(false);
  const [selectedPlanogram, setSelectedPlanogram] = useState<MenuItemType>();
  const [selectedItem, setSelectedItem] = useState<MenuItemType>();
  const [selectedCategory, setSelectedCategory] = useState<MenuItemType>();
  const [applyAction, setApplyAction] = useState(false);
  const [withoutNavigation, setWithoutNavigation] = useState(false);
  const withAction = value?.includes('/action');

  useEffect(() => {
    if (value) {
      getSelectedValue(value);
    }
    setApplyAction(withAction ?? false);
    setWithoutNavigation(useNavigation);
  }, [planogramObjectData, value, useNavigation]);

  const getPlanogramObjects = async (itemId: string | number) => {
    const data = await planogramsModel.getPlanogramObjects(Number(itemId)).then(objects => objects);

    return planogramObjectData.map(planogram => {
      if (planogram.id.toString() === itemId) {
        planogram.objects = { ...data };
      }

      return planogram;
    });
  }

  const getSelectedValue = async (value: string) => {
    const [planogramName] = value.split('/');
    const planogramId = planogramObjectData.find(planogram => planogram.planogramName === planogramName)?.id.toString();

    if (!planogramId) {
      setParsedVal(undefined);
      return;
    }
    const planogramData = await getPlanogramObjects(planogramId);

    setParsedVal(getDisplayValue(value, planogramData));
  }

  const handleSelect = (e: React.ChangeEvent<{ value: string }>) => {
    const item = currentItems.find(item => item.id === e.target.value);

    if (!item) return;
    setSearchTerm('');
    if (step === Steps.SELECT_CATEGORY) {
      setStep(Steps.SELECT_OBJECT);
      handleSelectCategory(item);
    } else if (step === Steps.SELECT_OBJECT) {
      setStep(Steps.SELECT_ACTION);
      if (selectedPlanogram && selectedCategory) {
        setSelectedItem(item);
      }
    }
  };

  const handleSelectCategory = (item: MenuItemType) => {
    if (!selectedPlanogram || !item) return;
    setSelectedCategory(item);
    setCurrentItems(getObjectMenuItems(item.id, selectedPlanogram.id, planogramObjectData));
  };

  const handleSelectSphere = async (item: MenuItemType, link = false) => {
    if (link) {
      setStep(undefined);
      setSelectedPlanogram(undefined);
      const planogram = planogramObjectData.find(planogram => planogram.id.toString() === item.id);

      if (!planogram) return;
      const val = `${planogram.planogramName}/planogram/${planogram.id}/object`;

      handleChange({ navigationValueSelect: val, withoutNavigation: false });
      setParsedVal(getDisplayValue(val, planogramObjectData));
      return;
    }
    setIsLoading(true);
    setStep(Steps.SELECT_CATEGORY);
    const planogramData = await getPlanogramObjects(item.id);

    setIsLoading(false);
    setCurrentItems(getCategoryOptions(planogramData, item.id));
  };

  const handleSelectAction = () => {
    if (!selectedPlanogram || !selectedCategory || !selectedItem) return;
    const withAction = applyAction ? '/action' : '/object';
    const formattedValue = formatNavigationValue(
      selectedPlanogram.label,
      selectedCategory.id,
      selectedItem.id,
      withAction
    );

    handleChange({ navigationValueSelect: formattedValue, withoutNavigation });
    setParsedVal(getDisplayValue(formattedValue, planogramObjectData));
    setStep(undefined);
    setSelectedPlanogram(undefined);
  }

  const handleClickBack = () => {
    setSearchTerm('');
    if (step === Steps.SELECT_CATEGORY) {
      setStep(Steps.SELECT_SPHERE);
      setSelectedPlanogram(undefined);
      setCurrentItems(getPlanogramMenuItems(planogramObjectData));
    } else if (step === Steps.SELECT_OBJECT) {
      if (!selectedPlanogram) {
        return;
      }
      setStep(Steps.SELECT_CATEGORY);
      setSelectedCategory(undefined);
      setCurrentItems(getCategoryOptions(planogramObjectData, selectedPlanogram.id));
    } else if (step === Steps.SELECT_ACTION) {
      setStep(Steps.SELECT_OBJECT);
    }
  };

  const searchedItems = currentItems?.filter(item => item.label.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box style={{ marginTop: '8px' }}>
      <TextValidator
        value={value ?? ''}
        validators={[VALIDATORS_MAP.required.rule]}
        errorMessages={[VALIDATORS_MAP.required.message]}
        variant="outlined"
        fullWidth
        size="small"
        select
        label="Object"
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            style: { maxHeight: '360px' },
          },
          startAdornment: (
            <Box
              className="popup-label"
              onClick={() => {
                setStep(Steps.SELECT_SPHERE);
                setCurrentItems(getPlanogramMenuItems(planogramObjectData));
              }}
            />
          ),
          endAdornment: value && (
            <Tooltip
              title={
                `action is ${withAction ? 'on;' : 'off;'}
                ${withoutNavigation ? ' start action without navigating' : ' navigate to object'}`
              }
              placement="top"
              arrow
            >
              <Box className={`action-icon ${withAction ? 'active' : ''}`} />
            </Tooltip>
          ),
          IconComponent: KeyboardArrowDown,
          open: step !== undefined,
          onClose: () => {
            if (keepOpen.current) {
              keepOpen.current = false;
            } else {
              setStep(undefined);
              setSelectedItem(undefined);
            }
          },
          onOpen: () => {
            setStep(Steps.SELECT_SPHERE);
            setCurrentItems(getPlanogramMenuItems(planogramObjectData));
          },
          renderValue: () => parsedVal,
        }}
        onChange={handleSelect}
      >
        {(() => {
          if (step === undefined) {
            return [];
          }
          if (step === Steps.SELECT_SPHERE) {
            return [
              <Box key={`${step}-box`}>
                <TextValidator
                  key={step}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Sphere Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onMouseDown={() => {
                    keepOpen.current = true;
                  }}
                  style={{ padding: '0 16px 8px 16px' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    event.stopPropagation();
                  }}
                />
              </Box>,
              ...(searchedItems.length > 0
                ? searchedItems.map((item, index) => (
                  <MenuItem
                    onMouseDown={() => {
                      setSelectedPlanogram(item);
                      keepOpen.current = true;
                    }}
                    key={`${item.id}-${index}`}
                    value={item.id}
                    className={item.id === selectedPlanogram?.id ? 'selected' : ''}
                  >
                    {item.label}
                  </MenuItem>
                ))
                : [<NoOptionsText key="no-options">No options</NoOptionsText>]),
              selectedPlanogram && (
                <StickyItem
                  key="sticky-item"
                  onMouseDown={() => {
                    keepOpen.current = true;
                  }}
                >
                  <Box mb={3}>
                    <Divider />
                  </Box>
                  <Box pb={3} pl={1} pr={4} display="flex" justifyContent="space-between">
                    <Button
                      className="blue"
                      variant="text"
                      text="Link and finish"
                      onClick={() => handleSelectSphere(selectedPlanogram as MenuItemType, true)}
                    />
                    <Button
                      color="primary"
                      text="Next"
                      onClick={() => handleSelectSphere(selectedPlanogram as MenuItemType)}
                    />
                  </Box>
                </StickyItem>
              ),
            ];
          }
          if (step === Steps.SELECT_CATEGORY) {
            return [
              <BackButton
                key="back"
                onMouseDown={() => {
                  keepOpen.current = true;
                  handleClickBack();
                }}
                style={{ padding: '8px 16px 8px 16px' }}
              >{`< BACK`}</BackButton>,
              <Box>
                <HeaderText key="header">SELECT DESTINATION</HeaderText>
              </Box>,
              isLoading && <Loader />,
              ...(searchedItems.length > 0
                ? searchedItems.map((item, index) => (
                  !isLoading && (
                    <MenuItem key={`${item.id}-${index}`} value={item.id}>
                      {item.label}
                    </MenuItem>
                  )
                ))
                : [<NoOptionsText key="no-options">No options</NoOptionsText>]),
            ];
          }
          if (step === Steps.SELECT_ACTION) {
            return [
              <BackButton
                key="back"
                onMouseDown={() => {
                  keepOpen.current = true;
                  handleClickBack();
                }}
                style={{ padding: '8px 16px 8px 16px' }}
              >{`< BACK`}</BackButton>,
              <Box
                onMouseDown={() => {
                  keepOpen.current = true;
                }}
              >
                <Box px={4} pt={2}>
                  <ChipItem
                    clickable={false}
                    label={
                      selectedPlanogram &&
                      selectedCategory &&
                      selectedItem &&
                      `${selectedPlanogram.label}/${selectedItem.label}`
                    }
                  />
                  <Box pt={2}>
                    <Typography variant="h4">
                      <span>Apply object&apos;s action in the end</span>
                      <Switch
                        checked={applyAction}
                        onChange={({ target: { checked }}: ChangeEvent<HTMLInputElement>) => {
                          if (!checked) {
                            setWithoutNavigation(false);
                          }
                          setApplyAction(checked);
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h4">
                      <span>Start action without navigating</span>
                      <Switch
                        checked={withoutNavigation}
                        disabled={!applyAction}
                        onChange={
                          ({ target: { checked }}: ChangeEvent<HTMLInputElement>) => setWithoutNavigation(checked)
                        }
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box py={3}>
                  <Divider />
                </Box>
                <Box px={4} display="flex" justifyContent="flex-end">
                  <Button color="primary" text="Save" onClick={handleSelectAction} />
                </Box>
              </Box>,
            ];
          }
          return [
            <BackButton
              key="back"
              onMouseDown={() => {
                keepOpen.current = true;
                handleClickBack();
              }}
              style={{ padding: '8px 16px 8px 16px' }}
            >{`< BACK`}</BackButton>,
            <Box key={`${step}-box`}>
              <TextValidator
                key={step}
                variant="outlined"
                fullWidth
                size="small"
                placeholder={selectedCategory ? `${selectedCategory.id} Name` : 'Search'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onMouseDown={() => {
                  keepOpen.current = true;
                }}
                style={{ padding: '0 16px 8px 16px', textTransform: 'capitalize' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                onKeyDown={(event: React.KeyboardEvent) => {
                  event.stopPropagation();
                }}
              />
            </Box>,
            ...(searchedItems.length > 0
              ? searchedItems.map((item, index) => (
                  <MenuItem key={`${item.id}-${index}`} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))
              : [<NoOptionsText key="no-options">No options</NoOptionsText>]),
          ];
        })()}
      </TextValidator>
    </Box>
  );
};

export default InternalObjectSelector;
