import { Box as MuiBox, Typography as MuiTypography, Grid as MuiGrid, styled } from '@material-ui/core';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = styled(MuiBox)({
  backgroundColor: COLORS.cozmosBlueLight,
  color: COLORS.cozmosBlue,
  display: 'flex',
  width: 64,
  height: 64,
  marginRight: '14px',
  borderRadius: 6,
  position: 'relative',

  '& svg': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export const Typography = styled(MuiTypography)({
  '&.title': { marginBottom: '6px' },
  '&.caption': { color: COLORS.greyText },
});

export const Grid = styled(MuiGrid)({
  '&.link': { cursor: 'pointer' },
  '&.inactive': { opacity: 0.7 },
});
