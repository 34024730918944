import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';
import { getTWithPathKey } from 'utils';

import maintenanceImg from 'assets/img/maintainance.png';

export default function Maintenance() {
  const getT = getTWithPathKey('pages.maintenance');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h1" style={{ marginBottom: '12px' }}>
        {getT('title')}
      </Typography>
      <Typography variant="body1" style={{ color: COLORS.greyText }}>
        {getT('text')}
      </Typography>
      <img src={maintenanceImg} alt="Maintenance" />
    </Box>
  );
}
