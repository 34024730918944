import React, { ReactNode, useEffect, useState, ReactElement, useRef } from 'react';

import { Checkbox } from '@material-ui/core';
import { includes, isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AccessibilityLabelIcon, AddToGalleryLabelIcon } from 'components/CustomIcons';
import { DEFAULT_FUNC, FILE_ERROR_CODES } from 'constants/technical';
import { Image, Tag, FileValidator, HandleServerErrorFunc } from 'types';
import { FileLoadFunc, ImageWithInlineStyles } from 'types/images';
import { IframeContent } from 'types/other';
import { checkPdfFile } from 'utils';
import { onFilesDrop } from 'utils/helpers/dropzoneHelpers';

import { ButtonComponent } from '../ButtonComponent/ButtonComponent';
import { DropzoneImage, DropzoneImageProps } from '../DropzoneImage/DropzoneImage';
import { SortableListWrapper } from '../SortableList/SortableListWrapper';
import Dropzone from '../Dropzone/Dropzone';
import { ConfirmationDialog } from '../Dialogs';

import { ACCEPT_EXTENSIONS, EXTENSIONS_NAMES, sectionsWithAccessibilityHintTextFullWidth } from './constants';

import { Typography, Grid, Box } from './style';

type ImageReadonlyFunc = (image: Image) => boolean;

export interface ImageUploaderProps {
  images: Image[];
  loadImage: FileLoadFunc<number | null, void>;
  section: string;
  unloadImage: (imageId: number, tagId?: number) => void;
  handleServerError: HandleServerErrorFunc;
  accept?: string;
  activeTag?: Tag;
  additionalText?: string;
  additionalValidators?: FileValidator[];
  headerText?: string;
  isImageReadonly?: ImageReadonlyFunc | boolean;
  isSelectable?: boolean;
  isUploadEnabled?: boolean;
  maxFiles?: number;
  readonly?: boolean;
  selectButtonText?: string;
  smallView?: boolean;
  text?: string;
  thumbsOnly?: boolean;
  withCaptcha?: boolean;
  withEdit?: boolean;
  withInfo?: boolean;
  withOrder?: boolean;
  withPdfUpload?: boolean;
  withTags?: boolean;
  withUploadButton?: boolean;
  withUseOnSphere?: boolean;
  handleEdit?: (imageId: number, image: Image) => void;
  handleInfo?: (image: Image) => void;
  handleSelectComplete?: (selectedImagesIds: number[]) => void;
  handleDeleteAll?: (images: number[]) => Promise<void>;
  handleUnselect?: (imageId: number) => void;
  loadPdf?: FileLoadFunc<number | null, void>;
  onSelect?: (selectedImagesIds: number[]) => void;
  renderNav?: () => JSX.Element | boolean;
  renderTabs?: () => JSX.Element | boolean;
  setImagesWithOrder?: (images: Image[]) => void;
  withAddToGallery?: boolean;
  handleAddToGallery?: (image: ImageWithInlineStyles) => void;
  uploadButtonText?: string;
  showAddToGallery?: boolean;
  dropzoneText?: ReactNode;
  fallbacks?: Record<string, string | ((props: DropzoneImageProps) => ReactElement)>;
  hasMultiselect?: boolean;
}

export const ImageUploader = ({
  images,
  unloadImage,
  loadImage,
  loadPdf,
  activeTag,
  handleServerError,
  withTags = false,
  withUploadButton = false,
  selectButtonText,
  handleSelectComplete,
  handleDeleteAll,
  handleUnselect = DEFAULT_FUNC,
  isSelectable = true,
  withOrder = false,
  withPdfUpload = false,
  setImagesWithOrder = DEFAULT_FUNC,
  text = '',
  additionalText = '',
  accept = ACCEPT_EXTENSIONS.img,
  maxFiles = 100,
  smallView = false,
  renderNav,
  withEdit = false,
  handleEdit,
  headerText = '',
  onSelect = DEFAULT_FUNC,
  additionalValidators = [],
  withUseOnSphere = false,
  thumbsOnly = false,
  section,
  withCaptcha,
  renderTabs,
  isUploadEnabled = false,
  isImageReadonly = false,
  readonly = false,
  withInfo = false,
  handleInfo = DEFAULT_FUNC,
  withAddToGallery = false,
  handleAddToGallery = DEFAULT_FUNC,
  uploadButtonText,
  showAddToGallery = true,
  dropzoneText = '',
  fallbacks = {},
  hasMultiselect = true,
}: ImageUploaderProps) => {
  const dropzoneRef = useRef<HTMLInputElement>(null);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState(false);
  const [openPdfUpload, setOpenPdfUpload] = useState(false);
  const [selectedImagesIds, setSelectedImagesIds] = useState<number[]>([]);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (activeTag) {
      setSelectedImagesIds([]);
    }
  }, [activeTag?.id]);

  const onChangeSelected = (id: number) => {
    const updatedSelectedImagesIds = includes(selectedImagesIds, id)
      ? selectedImagesIds.filter(imageId => imageId !== id)
      : [...selectedImagesIds, id];

    setSelectedImagesIds(updatedSelectedImagesIds);
    setSelected(images.length === updatedSelectedImagesIds.length);

    if (onSelect) {
      onSelect(updatedSelectedImagesIds);
    }
  };

  const getDropzoneImageProp = (value: ImageReadonlyFunc | boolean, image: Image) =>
    typeof value === 'function' ? value(image) : value;

  const onRemoveImage = (imageId: number) => unloadImage(imageId, activeTag?.id);
  const checkShowControls = (): boolean => isSelectable;

  const onClickSelectComplete = () => {
    handleSelectComplete?.(selectedImagesIds);
    setSelectedImagesIds([]);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.checked;
    const selectedImages = selected ?
      images
        .filter(image => !image.isSystem)
        .map(image => image.id) :
      [];

    setSelected(selected);
    setSelectedImagesIds(selectedImages);
  };

  const onClickDeleteAll = () => {
    setShowDialog(false);
    handleDeleteAll?.(selectedImagesIds).then(() => {
      setSelected(false);
      setSelectedImagesIds([]);
    });
  };

  const onClickUnselect = (id: number) => handleUnselect?.(id);

  const haveImagesToDelete = () => images.filter(image => !image.isSystem).length > 0;

  const setList = (newImages: Image[]) =>
    !isImgLoading && !isEqual(images, newImages) ? setImagesWithOrder?.(newImages) : null;

  const renderImagesList = () =>
    images.map(it => {
      const { contentType } = it as IframeContent;
      const image = { ...it };

      if (contentType !== undefined) {
        const Fallback = fallbacks[contentType];

        if (typeof Fallback === 'function')
          return (
            <Fallback
              key={image.id}
              image={image}
              onChangeSelected={onChangeSelected}
              selectedImages={selectedImagesIds}
              onClickUnselect={onClickUnselect}
              onRemoveImage={onRemoveImage}
              withCheckbox={checkShowControls() && !image.isSystem}
              withEdit={withEdit}
              handleEdit={handleEdit}
              smallView={smallView}
              status={image.status}
              withUseOnSphere={withUseOnSphere}
              withTags={withTags}
              thumbsOnly={thumbsOnly}
              withInfo={withInfo}
              handleInfo={handleInfo}
              withRemove={!getDropzoneImageProp(isImageReadonly!, image)}
              withAddToGallery={withAddToGallery}
              showAddToGallery={showAddToGallery}
              handleAddToGallery={handleAddToGallery}
            />
          );
        if (typeof Fallback === 'string') image.url = Fallback;
      }

      return (
        <DropzoneImage
          key={image.id}
          image={image}
          onChangeSelected={onChangeSelected}
          selectedImages={selectedImagesIds}
          onClickUnselect={onClickUnselect}
          onRemoveImage={onRemoveImage}
          withCheckbox={checkShowControls() && !image.isSystem}
          withEdit={withEdit}
          handleEdit={handleEdit}
          smallView={smallView}
          status={image.status}
          withUseOnSphere={withUseOnSphere}
          withTags={withTags}
          thumbsOnly={thumbsOnly}
          withInfo={withInfo}
          handleInfo={handleInfo}
          withRemove={!getDropzoneImageProp(isImageReadonly!, image)}
          withAddToGallery={withAddToGallery}
          showAddToGallery={showAddToGallery}
          handleAddToGallery={handleAddToGallery}
        />
      );
    });
  const accessibilityHintTextWidth = sectionsWithAccessibilityHintTextFullWidth.includes(section) ? 1 : 'auto';

  const isUploadDisable = !isUploadEnabled && readonly;

  const pdfValidator: FileValidator = {
    rule: checkPdfFile,
    message: t('errorsTexts.imageType'),
    code: FILE_ERROR_CODES.FILE_TYPE,
  };

  return (
    <>
      <Typography>{text ?? t('components.imageUploader.imageUploaderText')}</Typography>
      {additionalText && <Typography>{additionalText}</Typography>}
      <Grid container justify="space-between">
        {handleEdit && (
          <Box display="flex" alignItems="center" width={accessibilityHintTextWidth}>
            <Box mt={2.25} mr={2}>
              <AccessibilityLabelIcon />
            </Box>
            <Typography variant="caption" className="accessibility-hint-text">
              {t('components.imageUploader.accessibilityHint')}
            </Typography>
          </Box>
        )}
        {withAddToGallery && (
          <Box display="flex" alignItems="center" width={1}>
            <Box mt={2.25} mr={2}>
              <AddToGalleryLabelIcon />
            </Box>
            <Typography variant="caption" className="accessibility-hint-text">
              {t('components.imageUploader.addToGalleryHint')}
            </Typography>
          </Box>
        )}
        {activeTag?.id !== undefined && (
          <Box display="flex" width="100%">
            <Typography className="name">{activeTag.name}</Typography>
          </Box>
        )}
        {headerText && (
          <Typography variant="caption" className="text-wrapper information">
            {headerText}
          </Typography>
        )}
        {renderTabs && renderTabs()}
        <Box display="flex" flexGrow={1} justifyContent="flex-end" mb={-2}>
          {hasMultiselect && haveImagesToDelete() && (
            <Box flexGrow={1} ml={-2}>
              <Typography variant="caption">
                <Checkbox
                  color="primary"
                  checked={selected}
                  onChange={handleSelectAll}
                />
                <span>Select All</span>
              </Typography>
              {!!selectedImagesIds?.length && (
                <Box mr={2} display="inline-flex">
                  <ButtonComponent color="primary" text="Delete Selected" onClick={() => setShowDialog(true)} />
                </Box>
              )}
              {handleSelectComplete && activeTag?.id && !isEmpty(selectedImagesIds) && (
                <ButtonComponent text={selectButtonText} onClick={onClickSelectComplete} />
              )}
            </Box>
          )}
          {withUploadButton && !isUploadDisable && (
            <ButtonComponent
              text={uploadButtonText || t('components.imageUploader.uploadPNG')}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                dropzoneRef.current?.click();
                setOpenImageUpload(true);
              }}
              variant="text"
              className="blue ml"
            />
          )}
          {withPdfUpload && loadPdf && !isUploadDisable && (
            <Box display="flex" width="auto">
              <ButtonComponent
                text={t('components.imageUploader.uploadPDF')}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                  dropzoneRef.current?.click();
                  setOpenImageUpload(true);
                }}
                variant="text"
                className="blue ml"
              />
            </Box>
          )}
          {renderNav && renderNav()}
        </Box>
      </Grid>
      <Dropzone
        loadFile={openPdfUpload ? loadPdf! : loadImage}
        additionalValidators={openPdfUpload ? [pdfValidator] : additionalValidators}
        section={section}
        accept={openPdfUpload ? `.${EXTENSIONS_NAMES.pdf}` : accept}
        activeTag={activeTag}
        maxFiles={maxFiles}
        readonly={readonly}
        withCaptcha={withCaptcha}
        dropzoneText={dropzoneText}
        onClose={() => {
          setOpenImageUpload(false);
          setOpenPdfUpload(false);
        }}
        ref={dropzoneRef}
        setIsFileLoading={setIsImgLoading}
        getFilesFromEvent={
          withPdfUpload ? event => onFilesDrop(event, handleServerError, pdfValidator, accept) : undefined
        }
      >
        {images.length && withOrder ? (
          <SortableListWrapper list={images} setList={setList}>
            {renderImagesList()}
          </SortableListWrapper>
        ) : (
          renderImagesList()
        )}
      </Dropzone>
      {showDialog && (
        <ConfirmationDialog
          open={showDialog}
          message={`Are you sure you want to delete ${selectedImagesIds.length} object(-s)?`}
          handleYes={onClickDeleteAll}
          handleNo={() => setShowDialog(false)}
          confirmText="Confirm"
          title="Confirmation dialog"
        />
      )}
    </>
  );
};
