import { head, last } from 'lodash';

export interface MarkStepLabels {
  value: number;
  label: string;
}

export const getValueWithText =
  (text: string) =>
  (value: string | number): string =>
    `${value}${text}`;

export const getMarks = (
  min: number,
  max: number,
  step: number,
  text: string,
  toFixedValue?: number,
  markStepLabels?: MarkStepLabels[],
): MarkStepLabels[] => {
  const marks: MarkStepLabels[] = [];

  for (let i = min; i <= max; i = Number((i + step).toFixed(toFixedValue))) {
    marks.push({ value: i, label: '' });
  }

  head(marks)!.label = getValueWithText(text)(head(marks)!.value);
  last(marks)!.label = getValueWithText(text)(last(marks)!.value);

  if (markStepLabels) {
    const getValue = (item: MarkStepLabels) => item.value;
    const marksValues = new Set([...marks.map(getValue), ...markStepLabels.map(getValue)]);
    const sortedValues = Array.from(marksValues).sort((a, b) => (a > b ? 1 : -1));

    const labels = markStepLabels.reduce(
      (acc, { value, label }) => ({ ...acc, [value]: label }),
      {} as Record<string, string>,
    );

    return sortedValues.map(value => ({
      value,
      label: labels[value],
    }));
  }
  return marks;
};
