import React from 'react';

import { CardActionArea, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardText, Card, ArrowForwardIcon } from './style';

export const AccountCard = ({ title, text, onClick }) => (
  <CardActionArea onClick={onClick}>
    <Card variant="outlined">
      <Grid item container justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item>
              <CardText>{text}</CardText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ArrowForwardIcon />
        </Grid>
      </Grid>
    </Card>
  </CardActionArea>
);

AccountCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};
