import { Box as MuiBox, Grid as MuiGrid, styled } from '@material-ui/core';

export const StyledGrid = styled(MuiGrid)({
  textAlign: 'right',
});

export const Box = styled(MuiBox)({
  width: '100%',
  '&.title-container': {
    margin: '24px 0 10px 0',
  },
  '&.subtitle-container': {
    margin: '40px 0 16px 0',
  },
});
