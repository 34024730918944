import React, { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { CardComponent, ItemWithData, PageHeader } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { dashboardModel } from 'models';

function ClientAdminDashboard({ handleServerError }) {
  const [state, setState] = React.useState({});
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getDashboardInfo();
  }, []);

  const getDashboardInfo = withPageProgressHandler(() => {
    dashboardModel
      .getDashboardInfo()
      .then(setState)
      .catch(handleServerError);
  });

  return (
    <Grid>
      <Helmet title="Dashboard" />
      <PageHeader title="Dashboard" />
      <CardComponent sm={12}>
        <ItemWithData title="Amount of products" data={state.productsCount} />
        <ItemWithData title="Amount of spheres" data={state.planogramsCount} />
        <ItemWithData title="Amount of pictures" data={state.picturesCount} />
      </CardComponent>
    </Grid>
  );
}

ClientAdminDashboard.propTypes = {
  handleServerError: PropTypes.func.isRequired,
};

export default ClientAdminDashboard;
