import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { activeStorageModel } from 'models';

import { ACCEPT_EXTENSIONS } from '../ImageUploader/constants';
import { LoadFileComponent } from '../LoadFileComponent/LoadFileComponent';

import { IconButton, RemoveIconContainer } from '../ImageChooseComponent/style';
import { ImageIcon, ImageUploadWrapper, UploadedImage } from './style';

export function ImageUploadComponent({
  imageUrl,
  handleImageUpload,
  handleServerError,
  accept,
  withDelete,
  wrapperClassName,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleImageFileSet = file => {
    if (file) {
      setIsLoading(true);
      activeStorageModel.createUpload(file).create((error, blob) => {
        setIsLoading(false);
        if (error) {
          handleServerError(error);
        } else handleImageUpload(blob);
      });
    } else {
      handleImageUpload(null);
    }
  };

  const handleRemove = () => handleImageFileSet();

  return (
    <ImageUploadWrapper className={wrapperClassName}>
      {imageUrl ? (
        <UploadedImage src={imageUrl} />
      ) : (
        <Box>
          <ImageIcon fontSize="large" />
        </Box>
      )}
      <LoadFileComponent
        accept={accept}
        text={imageUrl ? 'Replace image' : 'Upload image'}
        file={imageUrl || !isLoading ? null : imageUrl}
        loadFile={handleImageFileSet}
        handleServerError={handleServerError}
        isLoading={isLoading}
      />
      {imageUrl && withDelete && (
        <RemoveIconContainer>
          <IconButton onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        </RemoveIconContainer>
      )}
    </ImageUploadWrapper>
  );
}
ImageUploadComponent.propTypes = {
  imageUrl: PropTypes.string,
  handleImageUpload: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  accept: PropTypes.string,
  withDelete: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

ImageUploadComponent.defaultProps = {
  imageUrl: null,
  accept: ACCEPT_EXTENSIONS.imgIcon,
  withDelete: false,
  wrapperClassName: '',
};
