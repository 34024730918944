import { Button as MuiButton, Box as MuiBox, Typography as MuiTypography, withStyles } from '@material-ui/core';
import MuiAddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '&.tags-wrapper': { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' },
    '&.tags-item': { marginTop: '5px', width: '49%' },
  },
})(MuiBox);

export const Button = withStyles(theme => ({
  root: {
    minHeight: '36px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    backgroundColor: 'white',

    '&.focus': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&.active': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '&.flex': { display: 'flex' },
    '&.active .name': { fontWeight: '700' },
  },
}))(MuiButton);

export const Typography = withStyles({
  root: {
    '&.count': { fontSize: '10px', color: COLORS.greyText },
    '&.name': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
})(MuiTypography);

export const AddCircleOutlineIcon = withStyles({
  root: {
    height: ICON_SIZE.regular,
    width: ICON_SIZE.regular,
    color: COLORS.greyIcon,
    marginLeft: 'auto',
  },
})(MuiAddCircleOutlineIcon);
