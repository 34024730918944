import { Box as MuiBox, styled } from '@material-ui/core';

export const Box = styled(MuiBox)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  zIndex: '11',
  top: '0',
  right: '0',
  flexWrap: 'wrap-reverse',
});
export const Dot = styled(MuiBox)({
  borderRadius: '50%',
  display: 'inline-block',
  height: '10px',
  width: '10px',
  margin: '2px',
  flex: 'none',
});
