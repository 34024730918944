import { Box as MuiBox, withStyles } from '@material-ui/core';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import TextFieldsRounded from '@material-ui/icons/TextFieldsRounded';

import { ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    margin: '12px 0 2px 16px',
    '&.mt-auto': { marginTop: 'auto' },
    '&.mb-auto': { marginBottom: 'auto' },
    '&.mb-6': { marginBottom: '30px' },
    '&.display-flex': { display: 'flex', alignItems: 'center' },
    '&.flexShrink-0': { flexShrink: 0 },
  },
})(MuiBox);

export const TextFieldsRoundedIcon = withStyles({
  root: {
    fontSize: ICON_SIZE.middle,
  },
})(TextFieldsRounded);

export const FileCopyOutlinedIcon = withStyles({
  root: {
    fontSize: ICON_SIZE.middle,
  },
})(FileCopyOutlined);
