import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function EditIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <defs>
        <path id="e1avvcl8ya" d="M21.7 7.3l-5-5c-.4-.4-1-.4-1.4 0l-13 13c-.2.2-.3.4-.3.7v5c0 .6.4 1 1 1h5c.3 0 .5-.1.7-.3l13-13c.4-.4.4-1 0-1.4zM7.6 20H4v-3.6l12-12L19.6 8l-12 12z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="j8bj1hphvb" fill="#fff">
            <use xlinkHref="#e1avvcl8ya"/>
          </mask>
          <use fill="currentColor" fillRule="nonzero" xlinkHref="#e1avvcl8ya"/>
          <g fill="currentColor" fillOpacity=".87" mask="url(#j8bj1hphvb)">
            <g>
              <path d="M0 0H24V24H0z"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
