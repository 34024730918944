import { Box, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const PreviewButton = styled(Box)({
  display: 'flex',
  padding: '13% 0',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: COLORS.violetLight,
  cursor: 'pointer',
  borderRadius: '4px',

  '& h4': {
    marginTop: '8px'
  }
});
