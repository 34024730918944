import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { get } from 'lodash';

import { CardComponent, PageHeader, ImageUploader } from 'components';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { controlButtonModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';
import { useDeleteSelectedAssets } from 'hooks';

function ControlButton({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }) {
  const [images, setImages] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleDeleteAllRequest } = useDeleteSelectedAssets();
  const getT = getTWithPathKey('pages.assets.ControlButton');

  useEffect(() => {
    getImages();
  }, [paginationParams]);

  const getImages = withPageProgressHandler(() =>
    controlButtonModel
      .getImages(paginationParams)
      .then(({ controlButtons, controlButtonsCount }) => {
        setImages(controlButtons);
        setPaginationItemsCount(controlButtonsCount);
      })
      .catch(handleServerError)
  );

  const loadImage = image => {
    const signedId = get(image, 'signed_id');

    return controlButtonModel.postImage({ signedId }).then(resetPaginationPage);
  };

  const unloadImage = imageId => {
    controlButtonModel.deleteImage(imageId).then(getImages).catch(handleServerError);
  };

  const handleDeleteAllImages = withPageProgressHandler(imageIds => {
    const actions = imageIds.map(id =>
      controlButtonModel
        .deleteImage(id)
        .catch(handleServerError)
    );

    return handleDeleteAllRequest(actions, getImages);
  });

  const handleEditImage = (id, data) => controlButtonModel.editImage(id, data).then(getImages).catch(handleServerError);

  return (
    <>
      <PageHeader title={`Control button: ${paginationItemsCount}`} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title={getT('title')}>
          <ImageUploader
            images={images}
            loadImage={loadImage}
            unloadImage={unloadImage}
            handleServerError={handleServerError}
            handleDeleteAll={handleDeleteAllImages}
            text={getT('imageUploaderText')}
            accept={ACCEPT_EXTENSIONS.imgIcon}
            section={DOWNLOAD_SECTIONS.controlButton}
            handleEdit={handleEditImage}
            withEdit
            withUploadButton
            maxFiles={10}
          />
        </CardComponent>
      </Grid>
    </>
  );
}

ControlButton.propTypes = {
  ...withPaginationPropTypes,
};

export default withPagination(ControlButton);
