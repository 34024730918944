import React from 'react';

import { useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { ROLES } from 'routes/constants';

import ClientAdminDashboard from '../dashboards/ClientAdminDashboard';
import SystemAdminDashboard from '../dashboards/SystemAdminDashboard';

export default function Heatmap() {
  const {
    currentUser: {
      attributes: { role },
    },
  } = useAuthState();
  const { handleServerError } = useServerErrorActions();

  return role === ROLES.systemAdmin ? (
    <SystemAdminDashboard handleServerError={handleServerError} />
  ) : (
    <ClientAdminDashboard handleServerError={handleServerError} />
  );
}
