import React, { memo } from 'react';

import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';
import { AnimationTypes, EntranceAnimationTypes } from 'types/entranceAnimation';
import { getInlineFontStyles } from 'utils';
import { ComponentWithPageLanguageProps } from 'utils/enhancers/withPageLanguage';

import { Box } from './style';

export interface EntranceAnimationProps {
  settings: EntranceAnimationTypes;
}

export const EntranceAnimationPreviewComponent = ({
  settings,
  pageLanguageTabValue = 0,
}: EntranceAnimationProps & ComponentWithPageLanguageProps): JSX.Element => {
  const blurColor = `${settings.backgroundColor}E6`;

  const renderIcon = (item: AnimationTypes) => {
    if (item.otherAsset) {
      return <img src={item.otherAsset.url} className="indicator-icon" alt="" />;
    }

    return null;
  }

  return (
    <ButtonPreviewWrapper>
      <Box id="entrance-guide">
        {settings?.entranceAnimationFonts?.map((item: AnimationTypes) => (
          <CustomFontWrapper
            key={`${item.actionType}-settings`}
            font={item.desktop.font}
            style={getInlineFontStyles(item.desktop)}
          >
            <Box
              my={4}
              className={`${item.actionType}-indicator guide-indicator scroll-indicator ${item.hidden ? 'hidden' : ''}`}
              style={{
                backgroundColor: settings.backgroundColor,
                boxShadow: `${settings.disableGlow ? '' : `0 2px 50px 0 ${blurColor}`}`
              }}
            >
              <Box className="indicator-container">
                <Box className={`indicator-text ${!item.otherAsset?.url ? 'without-icon': ''}`}>{item.desktop.title[pageLanguageTabValue].value}</Box>
                {renderIcon(item)}
              </Box>
            </Box>
          </CustomFontWrapper>
        ))}
      </Box>
    </ButtonPreviewWrapper>
  )
}

export const EntranceAnimationPreview = memo(EntranceAnimationPreviewComponent);
