import { styled } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';

import { COLORS } from 'theme/constants';

export const Pagination = styled(MuiPagination)({
  textAlign: 'center',
  '&.with-border': {
    display: 'inline-block',
    alignSelf: 'center',
    padding: '4px 28px',
    border: `1px solid ${COLORS.greyBorder}`,
    borderRadius: '4px',
  },
  '& .MuiButtonBase-root': { fontSize: '12px' },
  '& .MuiPagination-ul': { display: 'inline-flex' },
});
