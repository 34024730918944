import { AnimationSettingsType } from './animation';
import { PaginationParams } from './common';
import { PlanogramVersion } from './planograms';
import { AuthProvider, UserAttributes } from './user';
import { EntranceAnimationTypes } from './entranceAnimation';
import { SphereLoader } from './sphereLoader';

export interface ClientPlanogramInfo {
  planogramsCount: number;
  lastUpdate: string | null;
  planogramVersions: PlanogramVersion[] | null;
}

export interface Client {
  id: number;
  adminsQuantity: number;
  domain: string;
  fullName: string;
  name: string;
  internal_host: string;
  internal_subdomain: string;
  updatedAt: string;
}

export interface ClientExtendedInfo extends Client {
  deleted: boolean;
  ecommercePlatformEnabled: boolean;
  email: string;
  externalDomain: string | null;
  imageUsedOnPlanogramDefault: string | null;
  matomoSiteId: string | number | null;
  phone: string;
  planogramsInfo: ClientPlanogramInfo;
  productImagesRequired: boolean;
  shoppingPlatform: string | null;
  sphereDomains: string[];
  useSubdomainWithExternalDomain: boolean;
  animationSettings: AnimationSettingsType;
  entranceAnimation: EntranceAnimationTypes;
  sphereLoader: SphereLoader;
}

export interface ClientInfo {
  id: number;
  name: string;
}

export interface GetClientsParams {
  page?: number;
  perPage: number | 'all';
  name?: string;
}

export interface ClientsListResponse {
  clients: Client[];
  count: number;
}

export interface AddClientParams extends Record<string, any> {
  email: string;
  fullName: string;
  name: string;
  phone: string;
  ignoreWarnings: boolean;
}

export interface EditClientParams extends Record<string, any> {
  fullName?: string;
  matomoSiteId?: string | number | null;
}

export interface EditClientResponse {
  client: ClientExtendedInfo;
  success: string[];
}

export interface AdminsByClientParams extends PaginationParams {
  clientId: number;
  role: string;
}

export interface AdminInfo {
  id: number;
  client: ClientInfo;
  email: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  lastPlanogramsUpdate: string | null;
  otpNeedEnable: boolean;
  planogramsQuantity: number;
  role: string;
  provider: AuthProvider;
  invitedAs: string;
  updatedAt: string;
}

export interface AdminsListResponse {
  admins: AdminInfo[];
  adminsCount: number;
}

export interface AdminItemResponse {
  admin: AdminInfo;
  adminsCount: number;
}

export interface AdminAsUserResponse {
  admin: UserAttributes;
  authToken: Record<string, string>;
}

export interface AdminActionResponse {
  admin: AdminInfo;
  success: string[];
}

export enum SubscriptionTier {
  Starter = 1,
  Professional = 2,
  Enterprise = 3,
}

export type SubscriptionState =
  | 'active'
  | 'trialing'
  | 'past_due'
  | 'unpaid'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'paused';

export interface SubscriptionResponse {
  tier: SubscriptionTier;
  state: SubscriptionState;
  periodStart: string;
  periodEnd: string;
  trialEnd: string;
}

export interface CreateAdminParams extends Record<string, any> {
  clientId: number;
  email: string;
  firstName: string;
  lastName: string;
  otpNeedEnable: boolean;
  password: string;
  passwordConfirmation: string;
  role: string;
}

export interface EditAdminParams extends Record<string, any> {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetAdminsParams extends PaginationParams {
  role?: string;
  firstName?: string;
  lastName?: string;
}

export interface ReservedClient {
  id: number;
  clientName: string;
  email: string;
  createdAt: string;
}

export interface ReservedClientName {
  isReserved: boolean;
}
