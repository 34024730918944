import { styled } from '@material-ui/core';

export const ImageChooseWrapper = styled('div')({
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
    padding: '0',
    '& img': {
      width: '42px',
      height: '42px',
    },
    '& svg': {
      width: '26px',
      height: '26px',
    },
  },
});
