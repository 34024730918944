import React from 'react';

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import { Typography } from './style';

export function RadioGroupItem({ item }) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h6">{item.title}</Typography>
      </Box>
      <Typography variant="caption" className="info">
        {item.text}
      </Typography>
    </>
  );
}

RadioGroupItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};
