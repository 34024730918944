import React, { ChangeEvent } from 'react';

import classnames from 'classnames';
import { Grid, RadioGroup } from '@material-ui/core';

import { EntranceAnimationRadioItem } from 'components/EntranceAnimationRadioItem/EntranceAnimationRadioItem';

import { FormControlLabel, Radio } from './style';

interface EntranceAnimationRadioGroupProps {
  items: Array<{value: string, title: string}>;
  groupValue: string;
  setGroupValue: (value: string) => void;
}

export const EntranceAnimationRadioGroup = ({
  items,
  groupValue,
  setGroupValue,
}: EntranceAnimationRadioGroupProps) => {
  const handleDefaultChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setGroupValue(value);
  };

  const getClassName = (value: string) => (value === groupValue ? 'selected' : '');

  return (
    <RadioGroup value={groupValue} onChange={handleDefaultChange}>
      <Grid container spacing={2}>
        {items.map(item => (
          <Grid item sm={4} key={item.value}>
            <FormControlLabel
              value={item.value}
              control={<Radio color="primary" size="small" />}
              label={<EntranceAnimationRadioItem item={item} />}
              className={classnames(getClassName(item.value))}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}
