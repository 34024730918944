import { ROUTES } from 'routes/constants';
import {
  PasswordInviteParams,
  PasswordInviteWithCaptcha,
  PasswordUpdateParams,
  VerifyPasswordResetParams,
} from 'types/user';
import { getCurrentHostAndProtocol } from 'utils';

import { api } from './setupApi';

class PasswordModel {
  changePasswordLink = '/auth/password';

  changePasswordAuthenticatedLink = '/auth/password/reset_authenticated';

  redirectLink = `${getCurrentHostAndProtocol()}${ROUTES.createPassword}`;

  verifyPasswordResetLink = '/auth/password/verify_reset_password_token';

  createPasswordInvite = (data: PasswordInviteParams) =>
    api
      .post(this.changePasswordLink, {
        ...data,
        redirectUrl: this.redirectLink,
        invitation: true,
      })
      .then(({ data }) => data);

  createPasswordInviteAuthenticated = (data: PasswordInviteParams) =>
    api
      .post(this.changePasswordAuthenticatedLink, {
        ...data,
        redirectUrl: this.redirectLink,
        invitation: true,
      })
      .then(({ data }) => data);

  createPassword = (data: PasswordInviteWithCaptcha) =>
    api.post(this.changePasswordLink, { ...data, redirectUrl: this.redirectLink }).then(({ data }) => data);

  createPasswordAuthenticated = (data: PasswordInviteParams) =>
    api
      .post(this.changePasswordAuthenticatedLink, { ...data, redirectUrl: this.redirectLink })
      .then(({ data }) => data);

  updatePassword = (data: PasswordUpdateParams) => api.put(this.changePasswordLink, data).then(({ data }) => data);

  updateCurrentUserPassword = (data: PasswordUpdateParams) =>
    api.put(this.changePasswordLink, data).then(({ data }) => data);

  verifyPasswordReset = (dataToSend: VerifyPasswordResetParams) =>
    api.post(`${this.verifyPasswordResetLink}`, dataToSend).then(({ data }) => data);
}

export const passwordModel = new PasswordModel();
