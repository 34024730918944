import { AxiosError } from 'axios';
import { getI18n } from 'react-i18next';

import { BASE } from 'contexts/ServerErrorContext/consts';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { UploadInfo } from 'contexts/UploadStatusContext/types';
import {
  DataServerErrors,
  ErrorType,
  ServerErrors,
  ServerErrorsArray,
  UploadData,
  UploadDataSection,
} from 'types/common';
import { getErrorValue, getServerError } from 'utils/serverErrors';

export const getTotalProgressValues = (uploads: UploadData[]): Omit<UploadData, 'totalCount' | 'loadedCount'> =>
  uploads.reduce(
    (acc, { loaded, total }) => {
      acc.loaded += loaded;
      acc.total += total;

      return acc;
    },
    { loaded: 0, total: 0 },
  );

export const getProgress = ({ loaded, total }: Omit<UploadData, 'totalCount' | 'loadedCount'>): number =>
  Math.round((loaded / total) * 100);

export const getTotalProgress = (uploads: UploadData[]): number => getProgress(getTotalProgressValues(uploads));

export const getProgressBySections = (uploads: UploadDataSection[]): Record<string, UploadData> =>
  uploads.reduce(
    (acc, { section, loaded, total }) => ({
      ...acc,
      [section]: acc[section]
        ? {
            loaded: acc[section].loaded + loaded,
            total: acc[section].total + total,
            totalCount: acc[section].totalCount + 1,
            loadedCount: loaded && loaded === total ? acc[section].loadedCount + 1 : acc[section].loadedCount,
          }
        : {
            loaded,
            total,
            totalCount: 1,
            loadedCount: loaded && loaded === total ? 1 : 0,
          },
    }),
    {} as Record<string, UploadData>,
  );

export const hasInProgressUpload = (uploads: UploadInfo[]): boolean =>
  Boolean(uploads.filter(({ loaded, total, request }) => !request || loaded !== total).length);

export const hasUnfinishedRequests = (statuses: Map<string, string>) => {
  const values = Array.from(statuses.values());

  return values.includes(UPLOAD_STATUSES.PENDING) || values.includes(UPLOAD_STATUSES.UPLOAD);
};

export const createComplexUploadError = (errors: Record<string, string[]>): DataServerErrors<ErrorType<string>[]> => {
  const i18n = getI18n();

  return getServerError(
    Object.keys(errors).reduce(
      (groupedErrors, section) => [
        ...groupedErrors,
        `${i18n.t('utils.upload.group')}: ${i18n.t(`utils.upload.section.${section}`)}`,
        ...errors[section],
      ],
      [] as string[],
    ),
  );
};

interface UploadErrorMessageParams {
  error: AxiosError<ServerErrorsArray<string> | ServerErrors<string[]>> & DataServerErrors<string[]>;
  file: File;
}

export const getUploadErrorMessage = ({ error, file }: UploadErrorMessageParams) => {
  const i18n = getI18n();

  return `${i18n.t('utils.upload.file')}: ${file.name} \n ${i18n.t('utils.upload.error')}: ${getErrorValue(error)[
    BASE
  ].join(', ')}\n`;
};
