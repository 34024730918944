import { PRODUCT_FIELD_TYPE } from 'pages/products/constants';
import { LocalizationObject } from 'types/common';

interface LanguageAttributes {
  multilanguage: boolean;
  name: string;
  type: string;
}

interface MultilanguageFields extends Omit<LocalizationObject, 'value'> {
  value: string | boolean | { fileName: null };
}

const getDefaultValue = (type: string) => {
  switch (type) {
    case PRODUCT_FIELD_TYPE.BOOL:
      return false;

    case PRODUCT_FIELD_TYPE.FILE:
      return { fileName: null };

    default:
      return '';
  }
};

export const getDefaultMultilanguageField = (
  attributes: LanguageAttributes[],
  clientLanguages: string[],
): Record<string, MultilanguageFields[]> => {
  const defaultFields: Record<string, MultilanguageFields[]> = {};

  attributes.forEach(({ multilanguage, name, type }) => {
    if (multilanguage) {
      const value = getDefaultValue(type);

      defaultFields[name] = clientLanguages.map(languageCode => ({
        languageCode,
        value,
      }));
    }
  });

  return defaultFields;
};
