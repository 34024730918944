/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';

import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';

import { ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from 'components/ecommerce/constants';
import { useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { planogramsModel } from 'models';
import { EcommerceOverlaySphereSettings } from 'pages/planograms/ManagePlanogramSettings/ecommerce/EcommerceOverlaySphereSettings';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';

import { EcommerceUserProfileSphere } from './EcommerceUserProfileSphere';

function EcommerceComponent({
  planogramId,
  planogramVersionId,
  planogramLanguage,
  children,
  setSuccessActionMessages,
}) {
  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();
  const pageLanguageTabValue = clientLanguages.findIndex(i => i === planogramLanguage);
  const [overlaysSettings, setOverlaysSettings] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getOverlaySettings();
  }, []);

  const getOverlaySettings = withPageProgressHandler(() =>
    planogramsModel
    .getOverlaySettings(planogramId, planogramVersionId)
    .then(setOverlaysSettings)
    .catch(handleServerError)
  );

  const getOverlaySettingsByType = type => overlaysSettings.find(({ overlayType }) => overlayType === type) || {};

  /* Common method to share logic with toggle for all components */
  const onToggleSettingCard = async (nextSwitchState, settingId, settingType, toggleSwitcher) => {
    try {
      if (nextSwitchState) {
        const data = await planogramsModel.createOverlaySettings(planogramId, planogramVersionId, settingType);

        toggleSwitcher();

        return data;
      }
      await planogramsModel.deleteOverlaySettings(planogramId, planogramVersionId, settingId);
      toggleSwitcher();
    } catch (error) {
      handleServerError(error);
    }
  };

  return (
    <>
      {children}
      <EcommerceUserProfileSphere
        planogramId={planogramId}
        planogramVersionId={planogramVersionId}
        onToggleSettingCard={onToggleSettingCard}
        buttonsSettings={getOverlaySettingsByType(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.buttons)}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <EcommerceOverlaySphereSettings
        pageLanguageTabValue={pageLanguageTabValue}
        planogramId={planogramId}
        planogramVersionId={planogramVersionId}
        onToggleSettingCard={onToggleSettingCard}
        overlaySettings={getOverlaySettingsByType(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp)}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp}
        key={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <EcommerceOverlaySphereSettings
        pageLanguageTabValue={pageLanguageTabValue}
        planogramId={planogramId}
        planogramVersionId={planogramVersionId}
        onToggleSettingCard={onToggleSettingCard}
        overlaySettings={getOverlaySettingsByType(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn)}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn}
        key={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <EcommerceOverlaySphereSettings
        pageLanguageTabValue={pageLanguageTabValue}
        planogramId={planogramId}
        planogramVersionId={planogramVersionId}
        onToggleSettingCard={onToggleSettingCard}
        overlaySettings={getOverlaySettingsByType(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart)}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart}
        key={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart}
        setSuccessActionMessages={setSuccessActionMessages}
      />
    </>
  );
}

EcommerceComponent.propTypes = {
  ...withSuccessActionsDialogProps,
  planogramId: PropTypes.string.isRequired,
  planogramVersionId: PropTypes.number.isRequired,
  planogramLanguage: PropTypes.string.isRequired,
};

EcommerceComponent.defaultProps = {
  ...withSuccessActionsDialogProps,
};

export const Ecommerce = compose(withSuccessActionsDialog)(EcommerceComponent);
