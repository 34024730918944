import { ECommerceOverlaySettings } from 'types/overlays';

import { api } from './setupApi';

class EcommerceOverlayModel {
  link = '/ecommerce_overlay_settings';

  getSettings = () => api.get<ECommerceOverlaySettings>(this.link).then(({ data }) => data);

  postSettings = ({ id, ...ecommerceOverlaySetting }: ECommerceOverlaySettings): Promise<ECommerceOverlaySettings> =>
    api.put(`${this.link}/${id}`, { ecommerceOverlaySetting }).then(({ data }) => data);
}

export const ecommerceOverlayModel = new EcommerceOverlayModel();
