import { Box, Typography as MuiTypography, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

const counterName = 'count-table';

export const Table = withStyles({
  root: {
    counterReset: counterName,
    color: COLORS.greyText,
  },
})(Box);

export const TableHeader = withStyles({
  root: {
    paddingLeft: '37px',
    marginBottom: '20px',
    textTransform: 'uppercase',
  },
})(MuiTypography);

export const TableRow = withStyles({
  root: {
    padding: '0 0 48px 37px',
    counterIncrement: counterName,
    position: 'relative',

    '&:before': {
      content: `attr(data-count-index)`,
      color: COLORS.black,
      fontWeight: 'bold',
      position: 'absolute',
      left: 0,
      top: 0,
    },

    '& .MuiTypography-root, img': {
      marginBottom: '20px',
      '&:last-child': {
        marginBottom: '0',
      },
    },

    '& .margin-0': {
      margin: '0',
    },
  },
})(Box);

export const TableRowTitle = withStyles({
  root: {
    marginBottom: '8px !important',
    color: COLORS.black,

    '&:last-child': {
      marginBottom: '0 !important',
    },

    '&+img': {
      marginTop: '20px',
    },
  },
})(MuiTypography);
