import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function VisibilityOffIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <defs>
        <path id="rm78x6n2va" d="M7.417 3.75c-.084-.5.166-.917.666-1 .584-.167 1.25-.25 1.917-.25 6.25 0 9.75 6.833 9.917 7.167.083.25.083.5 0 .75-.5 1-1.167 1.916-1.917 2.833-.167.167-.417.333-.667.333-.166 0-.416-.083-.5-.166-.333-.334-.416-.834-.083-1.167.583-.667 1.083-1.333 1.5-2.083C17.5 8.833 14.5 4.333 10 4.333c-.5 0-1.083.084-1.583.167-.417-.083-.834-.333-1-.75zm12.333 16c-.167.167-.333.25-.583.25-.25 0-.417-.083-.584-.25L14.833 16c-1.416 1-3.083 1.5-4.833 1.5-6.25 0-9.75-6.833-9.917-7.167-.083-.25-.083-.5 0-.75C1 7.833 2.333 6.25 3.833 5L.25 1.417C-.083 1.083-.083.583.25.25c.333-.333.833-.333 1.167 0L19.75 18.583c.333.334.333.834 0 1.167zm-11.5-9.667c0 .417.167.834.5 1.167.5.417 1.083.5 1.667.333L8.333 9.5c0 .25-.083.417-.083.583zm5.417 4.75l-2-1.916c-.5.333-1.167.5-1.75.5-.834 0-1.667-.334-2.25-.917-.667-.583-1-1.417-1.084-2.333 0-.667.167-1.334.5-1.917L5 6.167C3.75 7.25 2.667 8.5 1.75 10c.75 1.333 3.75 5.833 8.25 5.833 1.25 0 2.5-.333 3.667-1z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-724 -2630) translate(724 2630)">
            <use fill="currentColor" fillRule="nonzero" xlinkHref="#rm78x6n2va"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
