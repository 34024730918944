/**
 *  Util for disabling native events from DOM
 *
 *  @param condition Bool
 *
 *  @return function
 *  */
export const disableEvent = (condition: boolean) => (e: KeyboardEvent) => {
  if (condition) {
    e.preventDefault();
    e.stopPropagation();
  }
};
