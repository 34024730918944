import React from 'react';

export function SearchIcon() {
  /* eslint-disable */
  return (
    <svg className="MuiSvgIcon-root" width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule="nonzero" clipRule='evenodd'
            d='m11.467 10.534 3 3a.644.644 0 0 1 0 .933.72.72 0 0 1-.466.2.72.72 0 0 1-.467-.2l-3-3c-1 .734-2.2 1.2-3.533 1.2a5.662 5.662 0 0 1-5.667-5.666 5.662 5.662 0 0 1 5.667-5.667 5.662 5.662 0 0 1 5.666 5.667c0 1.333-.466 2.6-1.2 3.533zM7.001 2.667a4.325 4.325 0 0 0-4.334 4.334c0 2.4 1.934 4.333 4.334 4.333a4.28 4.28 0 0 0 3.066-1.267 4.28 4.28 0 0 0 1.267-3.066c0-2.4-1.933-4.334-4.333-4.334z'
      />
      <mask id='6vipuzp4ga' maskUnits='userSpaceOnUse' x='1' y='1' width='14' height='14'>
        <path fillRule="nonzero" clipRule='evenodd'
              d='m11.467 10.534 3 3a.644.644 0 0 1 0 .933.72.72 0 0 1-.466.2.72.72 0 0 1-.467-.2l-3-3c-1 .734-2.2 1.2-3.533 1.2a5.662 5.662 0 0 1-5.667-5.666 5.662 5.662 0 0 1 5.667-5.667 5.662 5.662 0 0 1 5.666 5.667c0 1.333-.466 2.6-1.2 3.533zM7.001 2.667a4.325 4.325 0 0 0-4.334 4.334c0 2.4 1.934 4.333 4.334 4.333a4.28 4.28 0 0 0 3.066-1.267 4.28 4.28 0 0 0 1.267-3.066c0-2.4-1.933-4.334-4.333-4.334z'
        />
      </mask>
    </svg>

  );
}
