import React from 'react';

import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';

export function SuccessActionsDialog({ open, onClose, messages }) {
  return (
    <>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <DialogContent>
            {messages.map(message => (
              <div key={message}>{message}</div>
            ))}
          </DialogContent>
          <DialogActions>
            <ButtonComponent onClick={onClose} color="primary" text="Ok" />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

SuccessActionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
};

SuccessActionsDialog.defaultProps = { messages: [] };
