import React from 'react';

import { Dialog, Table, TableBody, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from 'components/propTypesConsts';
import { getInlineFontStyles } from 'utils';

import { cookiesTableIcons } from './constants';

import { Box, TableRow, TableHead } from './style';
import { Image } from 'components/style';

export function CookiesTablePreviewDialog({
  openDialog,
  backgroundColor,
  handleClose,
  currentLanguageCode,
  fontColor,
  titleFontSettings,
  colHeadersFontSettings,
  tableTextFontSettings,
  cookiesSettings,
  pageLanguageTabValue
}) {
  const { t } = useTranslation();
  const getTransPath = key => `pages.settings.cookiesSettings.cookiesTable.${key}`;

  return (
    <Dialog fullWidth classes={{ paper: 'dialog-container' }} open={openDialog} onClose={handleClose}>
      <Box style={backgroundColor ? { backgroundColor } : {}} className="overlay-container">
        <Box className="buttons-wrapper">
          <Box className="overlay-button overlay-button-back" style={{ color: fontColor }}>
            <span />
          </Box>
          <Box className="overlay-button overlay-button-close" onClick={handleClose} style={{ color: fontColor }}>
            <span />
          </Box>
        </Box>
        <CustomFontWrapper
          className="title"
          font={titleFontSettings?.font}
          style={getInlineFontStyles(titleFontSettings)}
        >
          <Box>{t(getTransPath('preview.title'), { lng: currentLanguageCode })}</Box>
        </CustomFontWrapper>

        <Box className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <CustomFontWrapper
                    font={colHeadersFontSettings?.font}
                    style={getInlineFontStyles(colHeadersFontSettings)}
                  >
                    {t(getTransPath('preview.cookie'), { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </TableCell>
                <TableCell>
                  <CustomFontWrapper
                    font={colHeadersFontSettings?.font}
                    style={getInlineFontStyles(colHeadersFontSettings)}
                  >
                    {t(getTransPath('preview.dataCollected'), { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </TableCell>
                <TableCell>
                  <CustomFontWrapper
                    font={colHeadersFontSettings?.font}
                    style={getInlineFontStyles(colHeadersFontSettings)}
                  >
                    {t(getTransPath('preview.type'), { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </TableCell>
                <TableCell>
                  <CustomFontWrapper
                    font={colHeadersFontSettings?.font}
                    style={getInlineFontStyles(colHeadersFontSettings)}
                  >
                    {t(getTransPath('preview.purpose'), { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </TableCell>
                <TableCell>
                  <CustomFontWrapper
                    font={colHeadersFontSettings?.font}
                    style={getInlineFontStyles(colHeadersFontSettings)}
                  >
                    {t(getTransPath('preview.expiry'), { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {t(getTransPath('tableData'), { returnObjects: true, lng: currentLanguageCode })?.map(
                ({ name, dataCollected, type, description, expiry }) => (
                  <TableRow key={name}>
                    <TableCell>
                      <Box className="image-wrapper">
                        <Image src={cookiesTableIcons[name]} />
                      </Box>
                    </TableCell>
                    <TableCell className="word-break-all">
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {name}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {dataCollected}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {type}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {description}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {expiry}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ),
              )}
              {cookiesSettings?.length ? (
                cookiesSettings.map((fields, i) => (
                  <TableRow key={`cookies-data-${i}`}>
                    <TableCell>
                      <Box className="image-wrapper">
                        <Image src={cookiesTableIcons[
                          Object.keys(cookiesTableIcons)[i]
                          ]} />
                      </Box>
                    </TableCell>
                    <TableCell className="word-break-all">
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {fields.name[pageLanguageTabValue]?.value}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {fields.cookieData[pageLanguageTabValue]?.value}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {fields.cookieType[pageLanguageTabValue]?.value}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {fields.purpose[pageLanguageTabValue]?.value}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell>
                      <CustomFontWrapper
                        font={tableTextFontSettings?.font}
                        style={getInlineFontStyles(tableTextFontSettings)}
                      >
                        {fields.expiry[pageLanguageTabValue]?.value}
                      </CustomFontWrapper>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )
              )) : ''}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Dialog>
  );
}

CookiesTablePreviewDialog.propTypes = {
  backgroundColor: PropTypes.string,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentLanguageCode: PropTypes.string.isRequired,
  titleFontSettings: fontSettingsProp,
  colHeadersFontSettings: fontSettingsProp,
  tableTextFontSettings: fontSettingsProp,
  fontColor: PropTypes.string,
  cookiesSettings: PropTypes.array,
  pageLanguageTabValue: PropTypes.number.isRequired,
};

CookiesTablePreviewDialog.defaultProps = {
  backgroundColor: '',
  titleFontSettings: {},
  colHeadersFontSettings: {},
  tableTextFontSettings: {},
  fontColor: '',
};
