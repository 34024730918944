import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { v4 as uuidv4 } from 'uuid';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import config from 'config/Config';
import {
  Tag,
  FileValidator,
  FileWithUploadData,
  ActiveStorageBlob,
  DataServerErrors,
  ErrorType,
  FileWithSignedId,
  FileLoadFunc,
  FileValidationError,
} from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useUploadStatusActions } from 'contexts/UploadStatusContext';
import { checkIsExtensionsAllowed, getServerError } from 'utils';
import { FILE_ERROR_CODES } from 'constants/technical';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { setCaptchaHeadersToLocalStorage } from 'utils/captcha';

import { DropzoneTypography, DropzoneStyledComponent, DropzoneBox } from './style';

interface DropzoneProps {
  loadFile: FileLoadFunc<number | null, void>;
  section: string;
  accept: string;
  activeTag?: Tag;
  maxFiles?: number;
  readonly?: boolean;
  withCaptcha?: boolean;
  dropzoneText?: ReactNode;
  additionalValidators?: FileValidator[];
  noDrag?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  setIsFileLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  getFilesFromEvent?: (event: DropEvent) => Promise<File[]>;
}

const DropzoneComponent = forwardRef(({
  loadFile,
  section,
  accept,
  activeTag,
  maxFiles,
  readonly,
  withCaptcha,
  dropzoneText,
  additionalValidators = [],
  noDrag,
  children,
  setIsFileLoading,
  getFilesFromEvent,
  onClose,
}: DropzoneProps, dropzoneRef) => {
  const { handleServerError } = useServerErrorActions();
  const { createUpload, failUpload } = useUploadStatusActions();
  const { t } = useTranslation();

  const activeTagIdRef = useRef<number | null>(null);
  const captchaRef = useRef<HCaptcha | null>(null);

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (activeTag) {
      activeTagIdRef.current = activeTag.id;
    }
  }, [activeTag?.id]);

  const extensionValidator: FileValidator = {
    rule: checkIsExtensionsAllowed,
    message: t('errorsTexts.fileType'),
    code: FILE_ERROR_CODES.FILE_TYPE,
  };

  const uploadFile = async (file: File, callback: FileLoadFunc<number | null, void>) => {
    const fileToUpload: FileWithUploadData = Object.assign(file, {
      upload: {
        uuid: uuidv4(),
        filename: file.name,
      },
    });

    setIsFileLoading && setIsFileLoading(true);

    createUpload(
      fileToUpload,
      section,
      (error: DataServerErrors<ErrorType<string>[]> | null, blob: ActiveStorageBlob) => {
        if (error) {
          handleServerError(error as ServerErrorType);
          setIsFileLoading && setIsFileLoading(false);
          failUpload(fileToUpload.upload.uuid);
          return Promise.reject(error);
        }

        const uploadedImage: FileWithSignedId = Object.assign(fileToUpload, {
          signed_id: blob.signed_id,
        });

        return callback(uploadedImage, activeTagIdRef?.current).then(() => setIsFileLoading && setIsFileLoading(false));
      },
    );
  };

  const onCaptchaVerify = (image: File) => async (token: string) => {
    setCaptchaHeadersToLocalStorage({ hCaptchaResponse: token });
    await uploadFile(image, loadFile);
  };

  const onFileAdded = async (addedFile: File, callback: FileLoadFunc<number | null, void>) => {
    if (withCaptcha) {
      setFile(addedFile);
      captchaRef.current?.execute();
    } else {
      await uploadFile(addedFile, callback);
    }
  };

  const fileValidation =
    (validators: FileValidator[]) =>
    (file: File): FileValidationError[] | null => {
      const errors = validators
        .filter(({ rule }) => !rule(file, accept))
        .map(({ message, code }) => ({ message, code }));

      return errors.length ? errors : null;
    };

  const handleFiles =
    (loadFunc: FileLoadFunc<number | null, void>) => async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      await Promise.all(acceptedFiles.map(file => onFileAdded(file, loadFunc)));

      if (rejectedFiles.length) {
        const { errors } = rejectedFiles[0];
        const error = rejectedFiles[0].errors[errors.length - 1].message;

        handleServerError(getServerError(error));
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (onClose) {
        onClose();
      }
    };

  const hasChildren = React.Children.count(children) !== 0;

  const {
    getRootProps,
    getInputProps,
    inputRef,
  } = useDropzone({
    accept,
    maxFiles,
    noDrag: readonly || noDrag,
    onDrop: handleFiles(loadFile),
    validator: fileValidation([extensionValidator, ...additionalValidators]),
    ...(getFilesFromEvent ? { getFilesFromEvent } : {}),
    ...(onClose ? { onFileDialogCancel: onClose } : {}),
  });
  const { ref, ...rootProps } = getRootProps();

  return (
    <DropzoneBox {...{ ref: dropzoneRef }} className={readonly ? 'disabled' : ''} {...rootProps}>
      <DropzoneStyledComponent className={!hasChildren ? 'empty' : ''}>
        <input {...getInputProps()} />
        {children}
        {!hasChildren && !readonly && (
          <DropzoneTypography>{dropzoneText || t('components.imageUploader.dropzoneText')}</DropzoneTypography>
        )}
      </DropzoneStyledComponent>
      {withCaptcha && (
        <Box display="none">
          <HCaptcha sitekey={config.captcha_key!} onVerify={onCaptchaVerify(file!)} ref={captchaRef} />
        </Box>
      )}
    </DropzoneBox>
  );
});

export default DropzoneComponent;
