import React from 'react';

import { FormControlLabel } from '@material-ui/core';
import PropTypes, { oneOfType } from 'prop-types';

import { withLocalization } from 'utils/enhancers';

function MultilanguageCheckboxComponent({ value, fieldName, handleChange, ...props }) {
  return <FormControlLabel {...props} checked={Boolean(value)} id={fieldName} onChange={handleChange} />;
}

MultilanguageCheckboxComponent.propTypes = {
  value: oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export const MultilanguageCheckbox = withLocalization(MultilanguageCheckboxComponent);
