import { useFontSettingsActions } from '../contexts/FontSettingsContext';

type ReturnFunc = () => void;

const useResetClientGlobalContexts = (): ReturnFunc => {
  const { resetFontSettings } = useFontSettingsActions();

  return () => {
    resetFontSettings();
    // call others contexts if needed
  };
};

export default useResetClientGlobalContexts;
