import React, { useState } from 'react';

import { Box, Divider, Grid } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { CardComponent, SettingsForm, TabsComponent } from 'components';
import { LINKS } from 'components/constants';
import { useSubmittingState } from 'hooks';
import { AnimationSettingsType, AnimationType } from 'types/animation';

import InputSlider from '../InputSlider/InputSlider';

import { Caption, Description, Highlight, Link, Title } from './style';
import { Switch } from 'components/style';

const DEFAULT_ANIMATION_SETTINGS: AnimationSettingsType = {
  duration: 500,
  autoplayDelay: 4000,
  transitionType: 'linear',
  panBeforeZoom: false,
};

interface TransitionMapEntry {
  translationKey: string;
  tabName: string;
}

const transitionMap: Record<AnimationType, TransitionMapEntry> = {
  linear: {
    translationKey: 'pages.settings.animationSettings.presets.linearDescription',
    tabName: 'Linear',
  },
  easeIn: {
    translationKey: 'pages.settings.animationSettings.presets.easeInDescription',
    tabName: 'Ease In',
  },
  easeOut: {
    translationKey: 'pages.settings.animationSettings.presets.easeOutDescription',
    tabName: 'Ease Out',
  },
  easeInOut: {
    translationKey: 'pages.settings.animationSettings.presets.easeInOutDescription',
    tabName: 'Ease In Out',
  },
  bounce: {
    translationKey: 'pages.settings.animationSettings.presets.bounceDescription',
    tabName: 'Bounce',
  },
};

interface AnimationSettingsCardProps {
  onSaveAnimationSettings: (settings: AnimationSettingsType | undefined) => Promise<void>;
  animationSettings: AnimationSettingsType | undefined;
  descriptionKey: string;
  expanded?: boolean;
  onToggleExpanded?: () => void;
}

const AnimationSettingsCard = ({
  onSaveAnimationSettings,
  animationSettings,
  descriptionKey,
  expanded,
  onToggleExpanded,
}: AnimationSettingsCardProps) => {
  const { t } = useTranslation();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const [state, setState] = useState<AnimationSettingsType | undefined>(animationSettings);

  const handleChangeInput = (fieldName: keyof AnimationSettingsType, value: number | string | boolean) => {
    setState((prevState: AnimationSettingsType | undefined) => ({
      ...(prevState ?? DEFAULT_ANIMATION_SETTINGS),
      [fieldName]: value,
    }));
  };

  const handleExpand = () => {
    if (!expanded)
      setState((prevState: AnimationSettingsType | undefined) => ({
        ...(prevState ?? DEFAULT_ANIMATION_SETTINGS),
        ...animationSettings,
      }));
    else setState(undefined);
    onToggleExpanded?.();
  };

  const handleSave = withHandlingSubmittingState(() => onSaveAnimationSettings(state));

  const isSaveButtonVisible = JSON.stringify(state) !== JSON.stringify(animationSettings) && !isSubmitting;

  const renderNav = () => (expanded === undefined ? null : <Switch checked={expanded} onChange={handleExpand} />);

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={handleSave}>
      <CardComponent
        title={t('pages.settings.animationSettings.presets.title')}
        sm={8}
        CardHeaderNav={renderNav}
        isLoading={isSubmitting}
      >
        {expanded !== false && state && (
          <Grid container spacing={6} direction="column">
            <Grid item>
              <Description>
                <Trans i18nKey={descriptionKey} components={{ bold: <Highlight /> }} />
                <Link href={LINKS.toysphereAutoplay} target="_blank" rel="noopener noreferrer">
                  {LINKS.toysphereAutoplay}
                </Link>
              </Description>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.animationSettings.presets.durationTitle')}</Title>
              <InputSlider
                value={state.duration}
                min={0}
                max={5000}
                step={100}
                onChange={value => handleChangeInput('duration', value)}
              />
              <Description>{t('pages.settings.animationSettings.presets.durationDescription')}</Description>
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.animationSettings.presets.delayTitle')}</Title>
              <InputSlider
                value={state.autoplayDelay}
                min={0}
                max={5000}
                step={100}
                onChange={value => handleChangeInput('autoplayDelay', value)}
              />
              <Description>{t('pages.settings.animationSettings.presets.delayDescription')}</Description>
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.animationSettings.presets.transitionTitle')}</Title>
              <Box paddingTop="20px" paddingBottom="10px">
                <TabsComponent
                  value={Object.keys(transitionMap).indexOf(state.transitionType)}
                  setValue={(index: number) => handleChangeInput('transitionType', Object.keys(transitionMap)[index])}
                  tabNames={Object.values(transitionMap).map(({ tabName }) => tabName)}
                  rootClassName="animationTabs"
                />
              </Box>
              <Description>{t(transitionMap[state.transitionType].translationKey)}</Description>
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.animationSettings.presets.orderTitle')}</Title>
              <Description>{t('pages.settings.animationSettings.presets.orderDescription')}</Description>
              <Box paddingTop="20px" display="flex" alignItems="center">
                <Switch
                  checked={state.panBeforeZoom}
                  onChange={() => handleChangeInput('panBeforeZoom', !state.panBeforeZoom)}
                />
                <Caption>{t('pages.settings.animationSettings.presets.orderCaption')}</Caption>
              </Box>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Description>
                <Trans
                  i18nKey="pages.settings.animationSettings.presets.knowledgeBase"
                  components={{
                    Link: <Link href={LINKS.animationKnowledgeBase} target="_blank" rel="noopener noreferrer" />,
                  }}
                />
              </Description>
            </Grid>
          </Grid>
        )}
      </CardComponent>
    </SettingsForm>
  );
};

export default AnimationSettingsCard;
