import React, { useEffect, useState } from 'react';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import Helmet from 'react-helmet';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { authModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getQueryParams, getTWithPathKey } from 'utils';

import { AuthMessage } from '../AuthMessage/AuthMessage';

import { MobileSuccessMessage } from './MobileSuccessMessage';

import { AuthCard, StyledButtonComponent, TextValidator } from '../style';

function SetPassword() {
  const [passwordSetupSuccess, setPasswordSetupSuccess] = useState(false);
  const [state, setState] = useState({
    [authModel.fields.password]: '',
    [authModel.fields.passwordConfirmation]: '',
    [authModel.fields.token]: '',
  });
  const { handleServerError } = useServerErrorActions();
  const getT = getTWithPathKey('pages.auth.setPassword');
  const history = useHistory();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { withHandlingSubmittingState, isSubmitting } = useSubmittingState();

  useEffect(() => {
    const verifyEmailToken = getQueryParams().token;

    if (verifyEmailToken) {
      authModel
        .verifyEmailToken({ token: verifyEmailToken })
        .then(data => {
          setState(prevState => ({
            ...prevState,
            [authModel.fields.token]: data.setPasswordToken,
          }));
        })
        .catch(() => {
          history.push(ROUTES.linkExpired);
        });
    } else {
      history.push(ROUTES.authSignIn);
    }
  }, []);

  const handlePost = withHandlingSubmittingState(async () => {
    if (state[authModel.fields.password] !== state[authModel.fields.passwordConfirmation]) {
      return handleServerError({
        response: {
          data: {
            errors: {
              [authModel.fields.passwordConfirmation]: ['Password does not match'],
            },
          },
        },
      });
    }
    return authModel
      .setPassword({
        [authModel.fields.password]: state[authModel.fields.password],
        [authModel.fields.token]: state[authModel.fields.token],
      })
      .then(() => {
        setPasswordSetupSuccess(true);
      })
      .catch(error => {
        handleServerError(error);
      });
  });

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const onContinueToSignIn = () => history.push(ROUTES.authSignIn);

  const disableResendButton = [state[authModel.fields.password], state[authModel.fields.passwordConfirmation]].some(
    value => !value,
  );

  if (passwordSetupSuccess && isMobile) {
    return <MobileSuccessMessage />;
  }

  if (passwordSetupSuccess && !isMobile) {
    return (
      <AuthMessage
        tabTitle={getT('tabTitle')}
        title={getT('successTitle')}
        buttonText={getT('successBtn')}
        onClick={onContinueToSignIn}
      />
    );
  }

  return (
    <AuthCard>
      <Helmet title={getT('tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{getT('title')}</Typography>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={handlePost}>
            <TextValidator
              validators={[VALIDATORS_MAP.required]}
              label={getT('passwordInputLabel')}
              value={state[authModel.fields.password]}
              fieldName={authModel.fields.password}
              handleChange={handleChange}
              type="password"
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required]}
              label={getT('passwordConfirmInputLabel')}
              value={state[authModel.fields.passwordConfirmation]}
              fieldName={authModel.fields.passwordConfirmation}
              handleChange={handleChange}
              type="password"
            />
            <StyledButtonComponent
              fullWidth
              color="primary"
              type="submit"
              text={getT('submitButton')}
              disabled={disableResendButton}
              loading={isSubmitting}
            />
          </ValidatorForm>
        </Grid>
      </Grid>
    </AuthCard>
  );
}

export default SetPassword;
