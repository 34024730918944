import { styled, Box as MuiBox } from "@material-ui/core";

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)(({ theme }) => ({
  '&.icon-container': {
    padding: '6px 19.5px',
    lineHeight: 0,
    borderRadius: '15px',
    backgroundColor: COLORS.greyDefault,
    color: COLORS.greyDefault,
  },
  '.selected & .icon-container': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  '&.top': {
    marginBottom: '28px',
  },
  '&.bottom': {
    marginTop: '28px',
  }
}));
