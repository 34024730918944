import React, { DragEvent } from 'react';

import { CircularProgress, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import classnames from 'classnames';
import { get, includes, isEmpty } from 'lodash';

import { AccessibilityLabelIcon, AddToGalleryIcon, AddToGalleryLabelIcon } from 'components/CustomIcons';
import { DEFAULT_FUNC } from 'constants/technical';
import { ImageStatus, ImageWithInlineStyles } from 'types/images';
import { getImagePreviewByStatus } from 'utils';

import { ButtonComponent } from '../ButtonComponent/ButtonComponent';
import { DotsComponent } from '../DotsComponent/DotsComponent';
import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';

import { ERRORS_STATUSES, IN_PROGRESS_STATUSES } from './constants';
import { EditImageIconWithDialog } from './EditImageIconWithDialog/EditImageIconWithDialog';

import { Image } from '../style';
import { Box, Checkbox } from './style';

export interface DropzoneImageProps {
  image: ImageWithInlineStyles;
  withCheckbox: boolean;
  selectedImages: number[];
  status: ImageStatus | null;
  smallView?: boolean;
  thumbsOnly?: boolean;
  withEdit?: boolean;
  withInfo?: boolean;
  withRemove?: boolean;
  withTags?: boolean;
  withUseOnSphere?: boolean;
  onChangeSelected: (id: number) => void;
  handleEdit?: (imageId: number, image: ImageWithInlineStyles) => void;
  handleInfo?: (image: ImageWithInlineStyles) => void;
  onClickUnselect?: (id: number) => void;
  onRemoveImage?: (id: number) => void;
  withAddToGallery?: boolean;
  handleAddToGallery?: (image: ImageWithInlineStyles) => void;
  showAddToGallery?: boolean;
}

export const DropzoneImage = ({
  image,
  onChangeSelected,
  selectedImages,
  onClickUnselect = DEFAULT_FUNC,
  onRemoveImage = DEFAULT_FUNC,
  withCheckbox = false,
  withRemove = false,
  smallView = false,
  withEdit = false,
  handleEdit = DEFAULT_FUNC,
  status,
  withUseOnSphere = false,
  withTags = false,
  withInfo = false,
  handleInfo = DEFAULT_FUNC,
  thumbsOnly = false,
  withAddToGallery = false,
  handleAddToGallery = DEFAULT_FUNC,
  showAddToGallery = true,
}: DropzoneImageProps): JSX.Element => {
  const onCheckedChange = () => onChangeSelected(image.id);
  const getChecked = () => includes(selectedImages, image.id);
  const onDragStart = (id: number) => (event: DragEvent) => event.dataTransfer.setData('pictureId', String(id));
  const onDetach = () => onClickUnselect(image.id);

  const showCheckbox = withUseOnSphere && image.usedOnPlanogram;

  const renderCheckbox = (): JSX.Element =>
    showCheckbox ? (
      <Box className="top left absolute">
        <ButtonComponent className="sphere-button no-display-on-hover" text="Sphere" />
        <Checkbox color="primary" checked onChange={onDetach} />
      </Box>
    ) : (
      <Checkbox className="top left absolute" color="primary" checked={getChecked()} onChange={onCheckedChange} />
    );

  const renderStatusIcon = (): JSX.Element | null => {
    if (!status) {
      return null;
    }

    const className = `status-icon ${status}`;

    if (status === ImageStatus.GENERATED) {
      return <CheckCircleIcon className={className} />;
    }
    if (ERRORS_STATUSES.includes(status)) {
      return <ErrorIcon color="error" className={className} />;
    }
    if (IN_PROGRESS_STATUSES.includes(status)) {
      return <CircularProgress size={24} className={className} />;
    }

    return null;
  };

  const getDotsColors = () => get(image, 'tags', []).map(tag => tag.color);

  const onImageBlockClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();

  const showAccessibilityIcon = !withInfo && withEdit && !image.accessibilityDescription;
  const showAddToGalleryIcon = showAddToGallery && withAddToGallery;
  const showGalleryIcon = showAddToGalleryIcon && image.isGallery;
  const showRemoveIcon = withRemove && !image.isSystem;
  const showTags = withTags && !isEmpty(getDotsColors());

  return (
    <Box
      className={classnames('preview-block file-preview', {
        small: smallView,
        selected: getChecked()
      })}
      onClick={onImageBlockClick}
    >
      <Box className="preview">
        {showTags && (
          <DotsComponent dotsColors={getDotsColors()} className="no-display-on-hover max-height top right absolute" />
        )}
        {withCheckbox && renderCheckbox()}
        {showRemoveIcon && (
          <IconWithConfirmation
            buttonClassName="top right absolute on-hover-button"
            message="image"
            value={image.id}
            handleConfirm={onRemoveImage}
          />
        )}
        {withEdit && (
          <EditImageIconWithDialog
            className="bottom right absolute on-hover-button"
            image={image}
            handleEdit={handleEdit}
          />
        )}
        {withInfo && (
          <IconButton className="bottom right absolute on-hover-button" onClick={() => handleInfo(image)}>
            <InfoOutlinedIcon />
          </IconButton>
        )}

        {showAddToGalleryIcon && (
          <IconButton
            className={classnames('bottom', 'left', 'absolute', 'on-hover-button', {
              active: image.isGallery,
            })}
            onClick={() => handleAddToGallery(image)}
          >
            <AddToGalleryIcon />
          </IconButton>
        )}

        {showGalleryIcon && (
          <Box className="absolute bottom left cursor-default no-display-on-hover">
            <AddToGalleryLabelIcon />
          </Box>
        )}

        {showAccessibilityIcon && (
          <Box className="absolute bottom right cursor-default no-display-on-hover">
            <AccessibilityLabelIcon />
          </Box>
        )}

        {renderStatusIcon()}
        <Box className="image-block" draggable="true" onDragStart={onDragStart(image.id)}>
          <Image
            className="img obj-fit-sd"
            data-dz-thumbnail
            src={getImagePreviewByStatus(image, thumbsOnly)}
            alt={image.seoTitle || image.imageName}
            style={image.inlineStyles || {}}
          />
        </Box>
      </Box>
      <Box className={getChecked() ? 'file-name selected' : 'file-name'}>{image.imageName}</Box>
      {!!image.seoTitle && (
        <Box className={getChecked() ? 'file-name seo selected' : 'file-name seo'}>SEO: {image.seoTitle}</Box>
      )}
    </Box>
  );
};
