import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';


const useDeleteSelectedAssets = () => {
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError } = useServerErrorActions();

  const handleDeleteAllRequest = withPageProgressHandler((
    assetsIds: number[],
    deleteAssets: (id: number) => Promise<unknown>,
    getAssets: () => void): Promise<void> => {
    const actions = assetsIds.map(id =>
      deleteAssets(id).catch(handleServerError)
    );

    return Promise.allSettled(actions).then(getAssets);
  });

  return { handleDeleteAllRequest }
}

export default useDeleteSelectedAssets;
