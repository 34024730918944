export const SEARCH_SETTINGS_VALUES = {
  default: {
    title: 'Default settings',
    text: 'All published spheres will be used in the search result.',
    value: 'default',
  },
  custom: {
    title: 'Custom',
    text: 'Exclude not needed published spheres from the search result.',
    value: 'custom',
  },
  allowedSearchAttributes: ['identifier', 'name', 'description'],
  sortOrder: { ASC: 'Ascending', DESC: 'Descending' },
  defaultSearchOrder: { name: 'Best match', id: 'best_match', value: '' },
  iconStateTypes: {
    custom: 'custom',
    system: 'system',
  },
};

export const FORM_ID = 'searchSettings';

export const defaultSettings = {
  excludePlanogramIds: [],
  allowedExcludePlanogramVersion: [],
  searchAttributes: [],
  enabled: false,
};
