import PropTypes from 'prop-types';

export const fontProp = PropTypes.shape({
  filename: PropTypes.string,
  name: PropTypes.string,
  fileUrl: PropTypes.string,
  id: PropTypes.number,
});

export const fontSettingsProp = PropTypes.shape({
  font: fontProp,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
});
