import React, { useEffect } from 'react';

import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { siteSettingsModel } from 'models';
import { getServerError, getTWithPathKey } from 'utils';
import { withErrorHandling } from 'utils/enhancers';

const requestDelay = 1000 * 60;
const openDialogDelay = 1000 * 60;

function PremaintenanceChecker() {
  const getT = getTWithPathKey('components.dialogs.premaintenanceDialog');
  const { handleServerError } = useServerErrorActions();

  let requestTimeoutId: ReturnType<typeof setTimeout>;
  let dialogIntervalId: ReturnType<typeof setInterval>;

  const getError = (endTime: number) => {
    const endTimeString = new Date(endTime).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    return getServerError([`${getT('startAt')} ${endTimeString}.`, getT('saveProgress')]);
  };

  const showDialogWithInterval = (endTime: number): ReturnType<typeof setInterval> => {
    const msEndTime = endTime * 1000;
    const error = getError(msEndTime);

    handleServerError(error);
    const intervalId = setInterval(() => {
      handleServerError(error);
      if (Date.now() + openDialogDelay > msEndTime) {
        clearInterval(intervalId);
      }
    }, openDialogDelay);

    return intervalId;
  };

  const checkPreMaintenanceMode = () => {
    siteSettingsModel.getMaintenanceMode().then(data => {
      if (data.preMaintenance) {
        clearTimeout(requestTimeoutId);
        dialogIntervalId = showDialogWithInterval(data.maintenanceAt);
        return;
      }

      requestTimeoutId = setTimeout(() => {
        checkPreMaintenanceMode();
      }, requestDelay);
    });
  };

  useEffect(() => {
    checkPreMaintenanceMode();
    return () => {
      clearTimeout(requestTimeoutId);
      clearInterval(dialogIntervalId);
    };
  }, []);

  return <></>;
}

export const PremaintenanceDialog = withErrorHandling(PremaintenanceChecker);
