import React from 'react';

import { ROLES, ROUTES } from 'routes/constants';
import { Role } from 'types/enums';
import { ReactComponent as ImageIcon } from 'assets/icons/system/image-icon.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/system/users-icon.svg';
import { ReactComponent as ListIcon } from 'assets/icons/system/list-icon.svg';
import { ReactComponent as SphereIcon } from 'assets/icons/system/sphere-icon.svg';
import { ReactComponent as TagIcon } from 'assets/icons/system/tag-icon.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/system/product-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/system/settings-icon.svg';
import { ReactComponent as SocialIcon } from 'assets/icons/system/social-icon.svg';

interface SidebarItem {
  id: string;
  path: string;
  icon: JSX.Element;
  header?: string;
}

interface SidebarList {
  header: string;
  items: SidebarItem[];
}

const manageClients = {
  id: 'Manage Spaces',
  path: ROUTES.manageClients,
  icon: <UsersIcon />,
};

const manageSpace = {
  id: 'Manage Space',
  path: ROUTES.manageSpace,
  icon: <UsersIcon />,
};

const manageClientModerators = {
  id: 'Space Moderators',
  path: ROUTES.manageClientModerators,
  icon: <UsersIcon />,
};

const manageAdmins = {
  id: 'Space Admins',
  path: ROUTES.manageClientAdmins,
  icon: <UsersIcon />,
};

const manageSystemAdmins = {
  id: 'System Admins',
  path: ROUTES.manageSystemAdmins,
  icon: <UsersIcon />,
};

const manageProducts = {
  id: 'Products',
  path: ROUTES.manageProducts,
  icon: <ProductIcon />,
};

const manageSocialContent = {
  id: 'Social Content',
  path: ROUTES.socialContent,
  icon: <SocialIcon />
}

const manageSpheres = {
  id: 'Spheres',
  path: ROUTES.manageSpheres,
  icon: <SphereIcon />,
};

const manageCategories = {
  id: 'Categories',
  path: ROUTES.manageCategories,
  icon: <ListIcon />,
};

const manageTags = {
  id: 'Tags',
  path: ROUTES.manageTags,
  icon: <TagIcon />,
};

const manageImages = {
  id: 'Images',
  path: ROUTES.manageImages,
  icon: <ImageIcon />,
};

const manageAssets = {
  id: 'Assets',
  path: ROUTES.assets,
  icon: <ListIcon />,
};

const manageSettings = {
  id: 'Settings',
  path: ROUTES.settings,
  icon: <SettingsIcon />,
};

const manageReservedClients = {
  id: 'Reserved Spaces',
  path: ROUTES.manageReservedClients,
  icon: <UsersIcon />,
};

const maintenance = {
  id: 'Maintenance',
  path: ROUTES.maintenance,
  icon: <SettingsIcon />,
};

const systemAdminSidebarList = [
  {
    header: 'USER MANAGEMENT',
    items: [
      manageSystemAdmins,
      manageReservedClients,
      manageClients,
      manageAdmins,
      manageClientModerators,
      maintenance,
    ],
  },
];

const clientOwnerSidebarList = [
  {
    header: 'CURATOR',
    items: [manageImages, manageAssets],
  },
  {
    header: 'COZMOS',
    items: [manageSpheres, manageProducts, manageSocialContent, manageCategories, manageTags],
  },
  {
    header: 'USER MANAGEMENT',
    items: [manageSpace, manageClientModerators, manageAdmins, manageSettings],
  },
];

const clientAdminSidebarList = [
  {
    header: 'CURATOR',
    items: [manageImages, manageAssets],
  },
  {
    header: 'COZMOS',
    items: [manageSpheres, manageProducts, manageSocialContent, manageCategories, manageTags],
  },
  {
    header: 'USER MANAGEMENT',
    items: [manageClientModerators, manageAdmins, manageSettings],
  },
];

const clientModeratorSidebarList = [
  {
    header: 'CURATOR',
    items: [manageImages, manageAssets],
  },
  {
    header: 'COZMOS',
    items: [manageSpheres, manageProducts, manageSocialContent, manageCategories, manageTags, manageSettings],
  },
];

const roleSidebars: Partial<Record<Role, SidebarList[]>> = {
  [ROLES.systemAdmin]: systemAdminSidebarList,
  [ROLES.clientOwner]: clientOwnerSidebarList,
  [ROLES.clientAdmin]: clientAdminSidebarList,
  [ROLES.clientModerator]: clientModeratorSidebarList,
};

export const sidebarList = (role: Role) => {
  const sidebarList = roleSidebars[role] ?? [];

  return sidebarList
    .map(section => {
      if (section.items[0]) {
        section.items[0] = { ...section.items[0], header: section.header };
      }
      return section.items;
    })
    .flat();
};
