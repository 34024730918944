import { Box as MuiBox, styled } from '@material-ui/core';
import { DragIndicator, FlagOutlined } from '@material-ui/icons';

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)({
  backgroundColor: COLORS.cozmosBlueLight,
  minHeight: '36px',
  margin: '8px 0',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  cursor: 'pointer',
  '& > .MuiBox-root': {
    backgroundColor: 'transparent',
  },
  '&.disabled, &.disabled > .MuiBox-root': {
    color: COLORS.greyText,
    backgroundColor: COLORS.bgLight,
    cursor: 'default',
  },
  '&.no-display': { display: 'none' },
  '&.no-minHeight': { minHeight: 'auto' },
  '&.no-margin': { margin: 0 },
  '& > svg': { marginRight: '16px' },
});

export const DragIndicatorIcon = styled(DragIndicator)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: '0 14px 0 6px',
  '&.entrance-animation-icon': { margin: '0 8px 0 6px'},
  '.disabled &': { color: COLORS.greyIcon },
}));

export const FlagIcon = styled(FlagOutlined)(({ theme }) => ({
  padding: '2px 8px',
  borderRadius: '12px',
  color: COLORS.white,
  background: theme.palette.primary.main,
  boxSizing: 'content-box',
}));
