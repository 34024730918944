import React from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CustomDomains, PageHeader, PageHeaderNav } from 'components';
import { ROUTES } from 'routes/constants';

import { InfoBlock } from './InfoBlock/InfoBlock';

const DomainManagementSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t('pages.settings.domainManagementSettings.title')} backLink={ROUTES.settings}>
        <PageHeaderNav />
      </PageHeader>
      <Grid container>
        <Grid item xs={8}>
          <InfoBlock />
          <CustomDomains />
        </Grid>
      </Grid>
    </>
  );
};

export default DomainManagementSettings;
