import React from 'react';

import { IconButton } from '@material-ui/core';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { FontSettingsPropTypes } from 'components/Dialogs/FontSettingsEditDialog/constants';
import { useFontSettingsActions } from 'contexts/FontSettingsContext';

import { Box, TextFieldsRoundedIcon, FileCopyOutlinedIcon } from './style';

export function FontSettingsControlWrapper({
  fontSettings,
  handleSettingsEdit,
  children,
  wrapperClassName,
  multiLangValue,
}) {
  const { saveFontSettings } = useFontSettingsActions();

  const onSettingsSave = () => saveFontSettings(fontSettings);

  const onSettingsEdit = () => handleSettingsEdit(fontSettings);

  const getMultilangStyles = () => {
    if (isArray(multiLangValue) && multiLangValue.length === 1) {
      return 'mb-auto';
    }
    if (isArray(multiLangValue) && multiLangValue.length > 1) {
      return 'display-flex';
    }
    return '';
  };

  return (
    <>
      {children}
      <Box className={`${getMultilangStyles()} ${wrapperClassName}`}>
        <IconButton size="small" color={isEmpty(fontSettings) ? 'default' : 'primary'} onClick={onSettingsEdit}>
          <TextFieldsRoundedIcon />
        </IconButton>
        <IconButton size="small" color="primary" disabled={isEmpty(fontSettings)} onClick={onSettingsSave}>
          <FileCopyOutlinedIcon />
        </IconButton>
      </Box>
    </>
  );
}

FontSettingsControlWrapper.propTypes = {
  fontSettings: FontSettingsPropTypes,
  handleSettingsEdit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.node]),
  wrapperClassName: PropTypes.string,
  multiLangValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

FontSettingsControlWrapper.defaultProps = {
  fontSettings: null,
  children: null,
  wrapperClassName: '',
  multiLangValue: null,
};
