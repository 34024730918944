import React, { useRef, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Grid, Divider, Hidden, Typography, useMediaQuery } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { VALIDATORS_MAP } from 'components/FormComponents/const';
import config from 'config/Config';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { authModel, passwordModel } from 'models';
import { ROUTES } from 'routes/constants';

import { AuthMessage } from '../AuthMessage/AuthMessage';

import { AuthCard, FormLink, SmallInfoText, StyledButtonComponent, TextValidator } from '../style';

function ResetPassword() {
  const captchaRef = useRef();
  const [state, setState] = useState({
    [authModel.fields.space]: '',
    [authModel.fields.email]: '',
  });
  const [validFields, setValidFields] = useState({
    [authModel.fields.space]: true,
    [authModel.fields.email]: true,
  });
  const [emailSent, setEmailSent] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handlePost = () => {
    captchaRef.current.execute();
  };

  const onCaptchaVerify = token => {
    passwordModel
      .createPassword({ ...state, [authModel.fields.hCaptcha]: token })
      .then(() => {
        setEmailSent(true);
      })
      .catch(error => handleServerError(error));
  };

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const onBackToSignIn = () => history.push(ROUTES.authSignIn);

  const disableFormButton =
    Object.values(state).some(value => !value) || Object.values(validFields).some(value => !value);

  if (emailSent) {
    return (
      <AuthMessage
        tabTitle={t('pages.auth.ResetPassword.tabTitle')}
        title={t('pages.auth.ResetPassword.emailSentTitle')}
        text={t('pages.auth.ResetPassword.emailSentText')}
        buttonText={isMobile ? undefined : t('pages.auth.ResetPassword.return')}
        onClick={onBackToSignIn}
      />
    );
  }

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.ResetPassword.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.ResetPassword.title')}</Typography>
        </Grid>
        <Grid item>
          <SmallInfoText>{t('pages.auth.ResetPassword.subtext')}</SmallInfoText>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={handlePost}>
            <TextValidator
              validators={[VALIDATORS_MAP.lowercaseLettersNumbersUnderscoreDashes]}
              label={t('common.forms.labels.spaceName')}
              value={state[authModel.fields.space]}
              fieldName={authModel.fields.space}
              handleChange={handleChange}
              autoComplete="on"
              onValidationChange={isValid =>
                setValidFields(prevState => ({ ...prevState, [authModel.fields.space]: isValid }))
              }
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
              label={t('common.forms.labels.email')}
              value={state[authModel.fields.email]}
              fieldName={authModel.fields.email}
              type="email"
              handleChange={handleChange}
              autoComplete="email"
              onValidationChange={isValid =>
                setValidFields(prevState => ({ ...prevState, [authModel.fields.email]: isValid }))
              }
            />
            <StyledButtonComponent
              fullWidth
              color="primary"
              type="submit"
              text={t('pages.auth.ResetPassword.submit')}
              disabled={disableFormButton}
            />
            <HCaptcha onVerify={onCaptchaVerify} sitekey={config.captcha_key} ref={captchaRef} size="invisible" />
          </ValidatorForm>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <FormLink variant="text" text={t('pages.auth.ResetPassword.return')} onClick={onBackToSignIn} />
          </Grid>
        </Hidden>
      </Grid>
    </AuthCard>
  );
}

export default ResetPassword;
