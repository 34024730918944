import React, { useState, useEffect, useMemo } from 'react';

import { Trans } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';

import { CardComponent, ImageUploader, PageHeader } from 'components';
import { getTWithPathKey } from 'utils';
import { ROUTES } from 'routes/constants';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { videosModel } from 'models/VideosModel';
import { useDeleteSelectedAssets, useTimeoutUpdate } from 'hooks';
import { FileWithSignedId, Image, PaginationProps } from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DropzoneVideo } from 'components/DropzoneVideo/DropzoneVideo';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { withPagination } from 'utils/enhancers';
import config from 'config/Config';

export default withPagination(
  ({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }: PaginationProps) => {
    const { handleServerError } = useServerErrorActions();
    const { withPageProgressHandler } = useBusyProgressActions();
    const { handleDeleteAllRequest } = useDeleteSelectedAssets();
    const getT = getTWithPathKey('pages.assets.Video');

    const [videos, setVideos] = useState<any[]>([]);

    const updateContents = () =>
      videosModel.getVideos({ ...paginationParams, instagram: false }).then(r => {
        setPaginationItemsCount(r.count);
        setVideos(r.videos);
      });

    useEffect(() => {
      updateContents();
    }, [paginationParams]);

    const loadVideo = withPageProgressHandler((file: FileWithSignedId) =>
      videosModel
        .createVideo({
          seoDesc: '',
          seoTitle: file.name,
          h1Tag: '',
          signedId: file.signed_id,
        })
        .then(resetPaginationPage)
        .catch(handleServerError),
    );

    const images = useMemo<Image[]>(
      () =>
        videos.map<Image>(v => ({
          ...v,
          accessibilityDescription: v.seoDesc,
        })),
      [videos],
    );

    const unloadVideo = withPageProgressHandler((imageId: number) =>
      videosModel.deleteVideo(imageId).then(updateContents).catch(handleServerError),
    );

    const isVideoProcessing = useMemo(
      () => !images.every(video => video.curatorReady), [images]
    );

    const handleEdit = withPageProgressHandler((imageId: number, image: Image) =>
      videosModel
        .updateVideo(imageId, {
          seoDesc: image.accessibilityDescription ?? '',
          seoTitle: image.seoTitle ?? image.imageName,
        })
        .then(updateContents)
        .catch(handleServerError),
    );

    useTimeoutUpdate(isVideoProcessing, updateContents, images);

    return (
      <>
        <PageHeader title={`${getT('title')}: ${paginationItemsCount}`} backLink={ROUTES.assets} />
        <Grid container spacing={6}>
          <CardComponent sm={12} title={getT('cardTitle')}>
            <Box>
              <Trans i18nKey={getT('description')} components={{ br: <br /> }} />
            </Box>
            <ImageUploader
              images={images}
              loadImage={loadVideo}
              section={DOWNLOAD_SECTIONS.video}
              unloadImage={unloadVideo}
              handleDeleteAll={assets => handleDeleteAllRequest(assets, videosModel.deleteVideo, updateContents)}
              handleServerError={handleServerError}
              withEdit
              handleEdit={handleEdit}
              withUploadButton
              uploadButtonText={getT('uploadButton')}
              text=""
              accept={config.app_env !== 'prod' ? ACCEPT_EXTENSIONS.video : '.mp4'}
              fallbacks={{ 'video/mp4': DropzoneVideo, 'video/webm': DropzoneVideo }}
            />
          </CardComponent>
        </Grid>
      </>
    );
  },
);
