import React, { useEffect } from 'react';

import { get } from 'lodash';

import { ErrorMessage } from 'components/Dialogs/ErrorMessage/ErrorMessage';
import { useServerErrorState, useServerErrorActions } from 'contexts/ServerErrorContext';

export const withErrorHandling =
  <T,>(Component: React.ComponentType<T>) =>
  (props: T): JSX.Element => {
    const { resetServerErrors } = useServerErrorActions();
    const baseServerError = get(useServerErrorState(), 'base', []);

    // returned function will be called on component unmount
    useEffect(() => resetServerErrors, []);

    return (
      <>
        <Component {...props} />
        <ErrorMessage messagesArr={baseServerError} handleClose={resetServerErrors} />
      </>
    );
  };
