import React from 'react';

import PropTypes from 'prop-types';

import { Container, Border, Content } from './style';

function DividerWithText({ text }) {
  return (
    <Container>
      <Border />
      <Content>{text}</Content>
      <Border />
    </Container>
  );
}

DividerWithText.propTypes = {
  text: PropTypes.string,
};

export default DividerWithText;
