import { kebabCase } from 'lodash';

const TEST_IDS = {
  manageSpheres: {
    list: {
      buttons: {
        edit: '',
        delete: '',
      },
    },
  },
  manageAdmins: {
    list: {
      buttons: {
        toggleEnabled: '',
        edit: '',
        delete: '',
      },
    },
  },
  manageProducts: {
    list: {
      buttons: {
        edit: '',
        delete: '',
      },
    },
  },
  manageCategories: {
    list: {
      buttons: {
        addSubcategory: '',
        edit: '',
        delete: '',
      },
    },
  },
  manageTags: {
    list: {
      buttons: {
        edit: '',
        delete: '',
      },
    },
  },
  manageClients: {
    list: {
      buttons: {
        edit: '',
        delete: '',
      },
    },
  },
};

type NestedStringsRecord = Record<string, object | string>;
// fills out the testIds object with kebab-case string values made from object keys (e.g. 'main-menu/add-object')
const recursivelyPopulateTestIds = (idTree: NestedStringsRecord, keyPrefix: string = '') => {
  Object.keys(idTree).forEach(key => {
    const kebabKey = kebabCase(key);
    const fullKey = keyPrefix + kebabKey;
    const value = idTree[key] as NestedStringsRecord | string;

    if (typeof value === 'string') {
      idTree[key] = fullKey;
    } else {
      recursivelyPopulateTestIds(value, `${fullKey}/`);
    }
  });
};

recursivelyPopulateTestIds(TEST_IDS);

export default TEST_IDS;
