import {
  Image,
  AddPdfPictureParams,
  AddPictureParams,
  EditPictureParams,
  PicturesGetParams,
  PicturesListResponse,
  UseOnPlanogramImagesParams,
  PdfStatus,
} from 'types/images';

import { api } from './setupApi';

class PicturesModel {
  link = '/pictures';

  pdfLink = '/pdf_pictures';

  imageBulkLink = `${this.link}/bulk_update`;

  getPictures = (params: PicturesGetParams): Promise<PicturesListResponse> =>
    api.get<PicturesListResponse>(this.link, { params }).then(({ data }) => data);

  getPictureById = (id: number): Promise<Image> => api.get<Image>(`${this.link}/${id}`).then(({ data }) => data);

  addPicture = (data: AddPictureParams) => api.post(this.link, data).then(({ data: { picture } }) => picture);

  addPdfPictures = (data: AddPdfPictureParams): Promise<PdfStatus> =>
    api.post(this.pdfLink, data).then(({ data }) => data);

  getPdfPictures = (id: number, productId?: string): Promise<PdfStatus> =>
    api
      .get(`${this.pdfLink}/${id}`, {
        params: {
          productId,
        },
      })
      .then(({ data }) => data);

  deletePicture = (id: number) => api.delete(`${this.link}/${id}`);

  editPicture = (id: number, data: EditPictureParams) =>
    api.put(`${this.link}/${id}`, data).then(({ data: { productImage } }) => productImage);

  useImageOnSphere = (id: number, data: UseOnPlanogramImagesParams) =>
    api.put(`${this.link}/${id}`, data).then(({ data: { productImage } }) => productImage);

  useImagesOnSphere = (pictures: UseOnPlanogramImagesParams[]) => api.post(this.imageBulkLink, { pictures });
}

export const picturesModel = new PicturesModel();
