import { OktaIcon } from 'components/CustomIcons';

export const SSO_PROVIDERS: Record<string, string>[] = [
  {
    title: 'Okta',
    id: 'okta',
  },
];

export const PROVIDERS_ICONS_MAP: Record<string, () => JSX.Element> = {
  Okta: OktaIcon,
};
