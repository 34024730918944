import React, { useState, useEffect } from 'react';

import { Typography, Grid, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { LINKS } from 'components/constants';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { AuthModelFields } from 'models/AuthModel';
import { AUTH_HEADERS } from 'models/constants';
import { invitesModel } from 'models/InvitesModel';
import { ROUTES } from 'routes/constants';
import { getQueryParams } from 'utils';

import {
  StyledButtonComponent,
  AuthCard,
  SmallInfoText,
  RedirectText,
  TermsAndConditionsText,
  TextValidator,
  FormLink,
} from '../style';

export interface DecodedGoogleToken {
  email: string;
  first_name: string;
  last_name: string;
}

const GoogleSignUpWithInvitePage = () => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const history = useHistory();

  const [state, setState] = useState({
    [AuthModelFields.TOKEN]: '',
    [AuthModelFields.SPACE]: '',
    [AuthModelFields.EMAIL]: '',
    [AuthModelFields.FIRST_NAME]: '',
    [AuthModelFields.LAST_NAME]: '',
    [AuthModelFields.TERMS]: false,
  });

  useEffect(() => {
    const googleToken = getQueryParams().token as string;
    const inviteToken = getQueryParams().original_invite_token as string;

    if (googleToken && inviteToken) {
      const { email, first_name: firstName, last_name: lastName }: DecodedGoogleToken = jwtDecode(googleToken);

      invitesModel
        .verifyToken({ token: inviteToken })
        .then(({ client }) => {
          setState(prevState => ({
            ...prevState,
            [AuthModelFields.TOKEN]: googleToken,
            [AuthModelFields.SPACE]: client,
            [AuthModelFields.EMAIL]: email,
            [AuthModelFields.FIRST_NAME]: firstName,
            [AuthModelFields.LAST_NAME]: lastName,
          }));
        })
        .catch(() => history.push(ROUTES.linkExpired));
    } else {
      history.push(ROUTES.home);
    }
  }, []);

  const submitForm = () => {
    invitesModel
      .socialSignUpWithInvite({
        token: state[AuthModelFields.TOKEN],
        firstName: state[AuthModelFields.FIRST_NAME],
        lastName: state[AuthModelFields.LAST_NAME],
      })
      .then(({ pubtoolToken }) => {
        localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, pubtoolToken);
        history.push(ROUTES.home);
      })
      .catch(handleServerError);
  };

  const handleChange = (item: { [key: string]: string }) => {
    setState(prevState => ({
      ...prevState,
      ...item,
    }));
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      [AuthModelFields.TERMS]: event.target.checked,
    }));
  };

  const disableSignUpButton = Object.values(state).some(item => !item);

  const onBackToSetup = () => {
    const originalInviteToken = getQueryParams().original_invite_token;

    history.push({
      pathname: ROUTES.inviteSelector,
      search: `?token=${originalInviteToken}`,
    });
  };

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signUpWithInvitePage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signUpWithInvitePage.title')}</Typography>
          <SmallInfoText>{t('pages.auth.googleSignUpPage.subTitle')}</SmallInfoText>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={submitForm} style={{ width: '100%' }}>
            <TextValidator label={t('common.forms.labels.spaceName')} value={state[AuthModelFields.SPACE]} disabled />
            <TextValidator label={t('common.forms.labels.email')} value={state[AuthModelFields.EMAIL]} disabled />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.firstName')}
              value={state[AuthModelFields.FIRST_NAME]}
              fieldName={AuthModelFields.FIRST_NAME}
              handleChange={handleChange}
              autoComplete="given-name"
              autoFocus
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.lastName')}
              value={state[AuthModelFields.LAST_NAME]}
              fieldName={AuthModelFields.LAST_NAME}
              handleChange={handleChange}
              autoComplete="family-name"
            />
            <Grid container direction="column" spacing={6}>
              <Grid item container alignItems="center" wrap="nowrap">
                <FormControlLabel
                  style={{
                    border: 'none',
                    paddingTop: '20px',
                  }}
                  control={
                    <Checkbox color="primary" checked={state[AuthModelFields.TERMS]} onChange={handleTermsChange} />
                  }
                  label={
                    <TermsAndConditionsText>
                      {`${t('pages.auth.common.termsAndConditionsCheckbox')}`}
                      <Link href={LINKS.terms}>{t('pages.auth.common.terms')}</Link>
                      {` ${t('pages.auth.common.and')} `}
                      <Link href={LINKS.privacyPolicy}>{t('pages.auth.common.privacy')}</Link>
                    </TermsAndConditionsText>
                  }
                />
              </Grid>
              <Grid item>
                <StyledButtonComponent
                  fullWidth
                  color="primary"
                  type="submit"
                  text={t('pages.auth.signUpWithInvitePage.continueBtn')}
                  disabled={disableSignUpButton}
                />
              </Grid>
              <Grid item>
                <RedirectText>
                  {`${t('pages.auth.signUpWithInvitePage.backToSetupText')} `}
                  <FormLink
                    variant="text"
                    onClick={onBackToSetup}
                    className="no-padding"
                    text={t('pages.auth.signUpWithInvitePage.backToSetupLink')}
                    color="primary"
                  />
                </RedirectText>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default GoogleSignUpWithInvitePage;
