import { Box as MuiBox, styled } from '@material-ui/core';
import { BlockPicker as ReactBlockPicker } from 'react-color';

import { COLORS } from 'theme/constants';

export const ColorPickerButton = styled('button')({
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  minWidth: '28px',
  border: `1px solid ${COLORS.cozmosPurpleLight}`,
  background:
    // eslint-disable-next-line
    'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',
  padding: 0,
  overflow: 'hidden',
  cursor: 'pointer',
  marginRight: '12px',
  '&:focus': { outline: 'none' },
});

export const BlockPicker = styled(ReactBlockPicker)({
  boxShadow: 'none!important',
  '& div': { display: 'none!important' },
  '& div:last-child': {
    display: 'block!important',
    padding: '0!important',
    '& input': {
      height: 'auto!important',
      border: `1px solid ${COLORS.greyDefault}!important`,
      boxShadow: 'none!important',
      padding: '10.5px 18.5px!important',
    },
  },
});

export const Box = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
});
