import React from 'react';

export function OktaIcon() {
  /* eslint-disable */
  return (
    <svg viewBox="0 0 120 60" version="1.1" className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#00297a"
        d="M22.58 21.607c-6.931 0-12.58 5.609-12.58 12.58a12.58 12.58 0 1 0 25.16 0c0-6.971-5.649-12.58-12.58-12.58zm0 18.87c-3.485 0-6.29-2.805-6.29-6.29s2.805-6.29 6.29-6.29 6.29 2.804 6.29 6.29-2.804 6.29-6.29 6.29zm22.075-2.084c0-1.001 1.202-1.482 1.883-.76l8.374 8.733c.08.08.16.2.44.28.12.04.321.04.521.04h5.69c1.001 0 1.322-1.161.84-1.762l-9.414-9.616-.481-.52c-1.082-1.282-.962-1.763.28-3.085l7.492-8.294c.481-.6.16-1.762-.881-1.762h-5.128c-.2 0-.36 0-.48.04-.321.08-.402.2-.482.28-.04.04-4.166 4.487-6.69 7.212-.682.76-1.923.24-1.923-.762V14.155c0-.722-.601-1.002-1.082-1.002h-4.207c-.721 0-1.082.48-1.082.922v31.65c0 .721.601.922 1.082.922h4.207c.64 0 1.082-.481 1.082-.962v-.32zm34.295 7.172-.44-4.207a1.035 1.035 0 0 0-1.162-.881l-.962.08c-3.365 0-6.09-2.644-6.25-5.93V29.14c0-.681.481-1.202 1.162-1.202h5.609c.4 0 1.002-.32 1.002-1.082v-3.966c0-.762-.481-1.202-.922-1.202h-5.649c-.64 0-1.202-.481-1.202-1.122v-6.37c0-.4-.28-1.002-1.082-1.002h-4.166c-.521 0-1.042.32-1.042.962v20.472c.16 6.771 5.73 12.22 12.58 12.22.561 0 1.122-.04 1.683-.12.48-.08.921-.601.841-1.162zm30.289-5.289c-3.566 0-4.087-1.282-4.087-6.05V22.729c0-.4-.28-1.081-1.081-1.081h-4.207c-.521 0-1.082.4-1.082 1.081v.521a12.489 12.489 0 0 0-6.21-1.642c-6.931 0-12.58 5.609-12.58 12.58a12.552 12.552 0 0 0 12.58 12.58c3.125 0 5.97-1.122 8.173-3.005 1.202 1.803 3.085 3.005 6.05 3.005.52 0 3.205.08 3.205-1.162v-4.487c-.04-.4-.36-.842-.761-.842zm-16.667.2c-3.485 0-6.29-2.804-6.29-6.29s2.805-6.29 6.29-6.29 6.29 2.805 6.29 6.29c-.04 3.486-2.844 6.29-6.29 6.29z"
        fillRule="nonzero"
      />
    </svg>
  );
}
