interface SortByNameParams extends Record<string, string> {
  name: string;
}

export const sortByName = (list: SortByNameParams[]) =>
  list.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
