import React from 'react';

import { Box, Typography, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FigmaIcon } from 'assets/icons/assets/figma-connected-icon.svg';
import { ToggleWrapper } from 'layouts/Auth/style';
import ModeToggle from 'components/ModeToggle/ModeToggle';

const PageContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  alignItems: 'center',
  paddingTop: '160px',
  backgroundColor: theme.palette.auth.background,
  color: theme.palette.auth.formText,
}));

const Header = styled(Typography)({
  fontSize: '31px',
  lineHeight: '1.2',
});

const ConnectFigma = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Box style={{ textAlign: 'center' }}>
        <Header>{t('pages.auth.figmaConnectPage.header')}</Header>
        <Box style={{ margin: '56px 0' }}>
          <FigmaIcon />
        </Box>
        <Typography>{t('pages.auth.figmaConnectPage.description')}</Typography>
      </Box>
      <ToggleWrapper>
        <ModeToggle />
      </ToggleWrapper>
    </PageContainer>
  );
};

export default ConnectFigma;
