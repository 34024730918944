import React from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { BodyText, Box, SmallText, Title, CozmosLogo } from './style';

export const DefaultLandingMessage = ({ title, body, footer, bodyLink, footerLink, displayLogo, button }) => (
  <Box
    style={
      displayLogo && {
        display: 'flex',
        flexGrow: 0.75,
      }
    }
  >
    <Grid container spacing={10} justify="center" alignItems="center" direction="column">
      {displayLogo && (
        <Grid item>
          <CozmosLogo />
        </Grid>
      )}
      <Grid item container spacing={3} direction="column">
        <Grid item>
          <Title>{title}</Title>
        </Grid>
        <Grid item>
          <BodyText>
            <Trans
              i18nKey={body}
              components={[
                <a href={bodyLink} target="_blank" rel="noreferrer">
                  {body}
                </a>,
              ]}
            />
          </BodyText>
        </Grid>
      </Grid>
      {button && <Grid item>{button}</Grid>}
      {footer && (
        <Grid item>
          <SmallText>
            <Trans
              i18nKey={footer}
              components={[
                <a href={footerLink} target="_blank" rel="noreferrer">
                  {footer}
                </a>,
              ]}
            />
          </SmallText>
        </Grid>
      )}
    </Grid>
  </Box>
);

DefaultLandingMessage.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  bodyLink: PropTypes.string,
  footerLink: PropTypes.string,
  displayLogo: PropTypes.bool,
  button: PropTypes.node,
};
