import { OverlayStyling, OverlayStylingUpdateParams } from 'types/overlays';

import { api } from './setupApi';

class OverlaysStylingModel {
  link = '/overlays_styling';

  getOverlaysStyling = (): Promise<OverlayStyling> => api.get<OverlayStyling>(this.link).then(({ data }) => data);

  updateOverlaysStyling = (data: OverlayStylingUpdateParams, id: number) =>
    api.put(`${this.link}/${id}`, data).then(({ data }) => data);
}

export const overlaysStylingModel = new OverlaysStylingModel();
