import React, { useState } from 'react';

import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

import { ColorPickerButton, BlockPicker, Box } from './style';

export function ColorPicker({ color, setColor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const transparent = { a: '0' };

  const handleClose = () => setAnchorEl(null);

  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);

  const handleSetColor = color => setColor(color);

  const getColor = () => (color.hex ? color : transparent);

  return (
    <Box>
      <ColorPickerButton type="button" onClick={handleClick} style={{ background: color.hex }} />
      <BlockPicker color={getColor()} onChangeComplete={handleSetColor} />
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <ChromePicker color={getColor()} onChange={handleSetColor} disableAlpha />
      </Popover>
    </Box>
  );
}

ColorPicker.propTypes = {
  color: PropTypes.shape({ hex: PropTypes.string }).isRequired,
  setColor: PropTypes.func.isRequired,
};
