import moment from 'moment';

const dateFormat = 'YYYY-MM-DDTHH:mm';
const serverDateFormat = 'D MMM, h:mmA, YYYY';
const timeFormat = 'HH:mm';

export const getFormattedDate = (value: string): string => {
  const date = value ? moment.utc(value) : moment.utc();

  return date.format(dateFormat);
};

export const getFormattedTime = (value: string | null, format = timeFormat): string | null => {
  const date = value ? moment(value, format) : null;

  return date?.toISOString() ?? null;
}

export const getParsedTime = (value: string | null): string =>
  moment(value, serverDateFormat).isValid() ?
    moment(value, serverDateFormat).format(timeFormat) :
    '';
