import { getTranslation } from './localization';

export const getMinMaxValidatedValue = (value: number, { min, max }: Record<'min' | 'max', number>): number => {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
};

export const imageSizeValidator = (size: number) => ({
  rule: (file: File) => file.size < size,
  message: getTranslation('errorsTexts.imageSize'),
  code: 'image-size-error',
});
