import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

import { AuthMessage } from '../AuthMessage/AuthMessage';

export const LinkExpired = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onContinueToSignIn = () => {
    history.push(ROUTES.authSignIn);
  };

  return (
    <AuthMessage
      tabTitle={t('pages.auth.linkExpired.tabTitle')}
      title={t('pages.auth.linkExpired.linkExpiredTitle')}
      text={t('pages.auth.linkExpired.linkExpiredText')}
      buttonText={t('pages.auth.linkExpired.linkExpiredBtn')}
      onClick={onContinueToSignIn}
    />
  );
};
