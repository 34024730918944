import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  styled,
} from '@material-ui/core';

export const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    padding: '0 22px',
    borderRadius: '6px',
    maxWidth: '601px',
  },
});

export const DialogTitle = styled(MuiDialogTitle)({
  padding: '16px 0',
  fontSize: '14px',

  '& .MuiTypography-h6': {
    fontWeight: 'bold',
  },
});

export const DialogContent = styled(MuiDialogContent)({
  padding: '25px 0 8px',
  display: 'flex',
  flexDirection: 'column',
});

export const DialogActions = styled(MuiDialogActions)({
  display: 'flex',
  paddingBottom: '30px',
});
