import { mapKeys, camelCase, snakeCase, isPlainObject, Dictionary } from 'lodash';

import { DeepRecord } from 'types/common';

type Nested = DeepRecord<string, any>;

export const getDataWithCamelCaseItems = <T>(data: Dictionary<T>): Dictionary<T> =>
  mapKeys(data, (_, key) => camelCase(key));

export const getDataWithSnakeCaseItems = <T>(data: Dictionary<T>): Dictionary<T> =>
  mapKeys(data, (_, key) => snakeCase(key));

export const deepObjectKeysToCamelCase = <T extends Nested>(obj: T[] | T): T[] | T => {
  if (Array.isArray(obj)) {
    return obj.map<T>(v => deepObjectKeysToCamelCase(v) as T);
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) =>
        ({
          ...result,
          [camelCase(key)]: deepObjectKeysToCamelCase(obj[key]),
        } as T),
      {} as T,
    );
  }

  return obj;
};

export const deepObjectKeysToSnakeCase = <T extends Nested>(obj: T[] | T): T[] | T => {
  if (Array.isArray(obj)) {
    return obj.map<T>(v => deepObjectKeysToSnakeCase(v) as T);
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) =>
        ({
          ...result,
          [snakeCase(key)]: deepObjectKeysToSnakeCase(obj[key]),
        } as T),
      {} as T,
    );
  }

  return obj;
};

export const getNameFromSnakeCase = (data: string): string => data.split('_').join(' ');
