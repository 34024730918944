import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { categoriesModel } from 'models';
import { useSubmittingState } from 'hooks';
import { getTWithPathKey } from 'utils';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '../style';

const FORM_ID = 'categoryDialog';

export const maxCategoryNameLength = 50;

export const CategoryPopupForm = ({ category, afterAction, handleServerError, handleClose }) => {
  const [currentName, setCurrentName] = useState(category.name);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const getT = getTWithPathKey('common.buttons');
  const getCategoriesT = getTWithPathKey('pages.categories');

  useEffect(() => {
    setCurrentName(category.name);
  }, [category.name]);

  const handleSave = () =>
    category.id
      ? categoriesModel
        .editCategory(category.id, { name: currentName, parentId: category.parentId })
        .then(afterAction)
        .catch(error => handleServerError(error, FORM_ID))
      : categoriesModel
        .addCategory({ name: currentName, parentId: category.parentId })
        .then(afterAction)
        .catch(error => handleServerError(error, FORM_ID));

  const handleChangeName = item => setCurrentName(item.name);

  const getName = () => {
    if (category.id) {
      return getCategoriesT('editCategory');
    }
    if (!category.parentId) {
      return getCategoriesT('addCategory');
    }
    return getCategoriesT('addSubcategory');
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>{getName()}</DialogTitle>
      <ValidatorForm onSubmit={() => withHandlingSubmittingState(handleSave)()} id={FORM_ID}>
        <DialogContent>
          <TextValidatorComponent
            validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.maxStringLength(maxCategoryNameLength)]}
            label={getCategoriesT('name')}
            value={currentName}
            fieldName="name"
            formId={FORM_ID}
            handleChange={handleChangeName}
            autoFocus
          />
          <p />
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={handleClose} text={getT('cancel')} />
          <ButtonComponent
            type="submit"
            color="primary"
            text={getT('save')}
            loaderSize="1.35em"
            loading={isSubmitting}
          />
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

CategoryPopupForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    parentId: PropTypes.number,
    name: PropTypes.string,
  }),
  afterAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
};

CategoryPopupForm.defaultProps = {
  category: { name: '', id: null, parentId: null },
};
