import { createMuiTheme } from '@material-ui/core/styles';

import breakpoints from './breakpoints';
import overrides from './overrides';
import shadows from './shadows';
import { lightModePalette, darkModePalette } from './theme';
import typography from './typography';

const globals = {
  spacing: 4,
  breakpoints,
  overrides,
  typography,
  shadows,
};

export const lightMode = createMuiTheme({
  ...globals,
  palette: lightModePalette,
});

export const darkMode = createMuiTheme({
  ...globals,
  palette: darkModePalette,
});
