import { Country, CountryCurrencyRelation, Currency, CurrencyListItem } from 'types/other';
import { BuyButton } from 'types/overlays';

import { api } from './setupApi';

class CurrenciesModel {
  link = '/settings';

  countriesLink = '/countries';

  currenciesLink = '/currencies';

  countriesCurrenciesLink = '/countries_currencies';

  buttonsLink = '/buttons';

  getCurrencies = async (): Promise<Record<'data', CurrencyListItem[]>> => {
    const [{ data: currencies }, { data: relations }] = await Promise.all([
      api.get<Currency[]>(this.currenciesLink),
      api.get<CountryCurrencyRelation[]>(this.countriesCurrenciesLink),
    ]);
    const data: CurrencyListItem[] = currencies.map(c => ({ ...c, countryIds: [] }));

    relations.forEach(r => {
      const currency = data.find(c => c.id === r.currencyId);

      if (currency) {
        currency.countryIds = [...currency.countryIds, r.countryId];
      }
    });
    return { data };
  };

  saveCurrencyRelations = (data: CountryCurrencyRelation[]) =>
    api.post(`${this.countriesCurrenciesLink}/update_all`, { countries_currencies: data });

  getCountriesList = () => api.get<Country[]>(this.countriesLink);

  getBuyButtons = () => api.get<BuyButton[]>(this.buttonsLink).then(({ data }) => data);

  saveBuyButtons = (data: BuyButton[]) => api.post(`${this.buttonsLink}/update_all`, { buttons: data });

  saveBuyButtonById = (id: number, data: BuyButton) => api.put(`${this.buttonsLink}/${id}`, data);

  getProductFields = () => api.get<string[]>(`${this.buttonsLink}/fields_in_url`);
}

export const currenciesModel = new CurrenciesModel();
