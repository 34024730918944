import React, { useEffect, useState } from 'react';

import EntranceAnimationSettingsCard from 'components/EntranceAnimationSettingsCard/EntranceAnimationSettingsCard';
import { AnimationTypes, EntranceAnimationTypes } from 'types/entranceAnimation';
import { planogramsModel } from 'models';
import { PlanogramVersion } from 'types';
import { getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';

interface EntranceAnimationSettingsProps {
  planogramVersion: PlanogramVersion;
  planogramId: number;
  handleServerError: (err: any) => void;
  setSuccessActionMessages: (messages: string[]) => void;
}

export function EntranceAnimationSettings({
  planogramVersion,
  planogramId,
  handleServerError,
  setSuccessActionMessages,
}: EntranceAnimationSettingsProps) {
  const [animationSettings, setAnimationSettings] = useState<EntranceAnimationTypes>();

  useEffect(() => {
    getEntranceAnimationSettings();
  }, []);

  const getEntranceAnimationSettings = () =>
    setAnimationSettings({
      ...planogramVersion?.entranceAnimation,
      entranceAnimationFonts:
        (planogramVersion.entranceAnimation as EntranceAnimationTypes).entranceAnimationFonts.map(item => (
          { ...item, desktop: getFontSettingsWithNums(item.desktop), mobile: getFontSettingsWithNums(item.mobile) }
        )) as AnimationTypes[]
    });

  const onEntranceAnimationSettingsSave = (settings: EntranceAnimationTypes) => {
    const dataToSave = {
      ...settings,
      entranceAnimationFonts: settings.entranceAnimationFonts.map(item => {
        const { otherAsset, ...rest } = item;
        const data = {
          ...rest,
          desktop: getFontSettingsWithPx(rest.desktop),
          mobile: getFontSettingsWithPx(rest.mobile)
        };

        if (otherAsset?.id) {
          data.otherAssetId = otherAsset.id;
        }

        return data;
      })
    };

    return planogramsModel
      .editVersion(planogramId, planogramVersion.id, {entranceAnimation: dataToSave})
      .then(data => {
        setSuccessActionMessages(data.success);
        setAnimationSettings(data.planogramVersion.entranceAnimation ?? {});
      })
      .catch(handleServerError);
  }

  return (
    <>
      {animationSettings && (
        <EntranceAnimationSettingsCard
          settings={animationSettings as EntranceAnimationTypes}
          onSettingsSave={onEntranceAnimationSettingsSave}
          handleServerError={handleServerError}
          expanded={false}
        />
      )}
    </>
  )
}
