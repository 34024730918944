import React, { PropsWithChildren } from 'react';

import { RouterLink } from '../style';
import { Link, LinkButton } from './style';

export interface LinkComponentProps {
  to?: string;
  className?: string;
  href?: string;
  target?: string;
  onClick?: () => void;
}

export function LinkComponent({
  to = '',
  href = '',
  children,
  className = '',
  target = 'self',
  onClick,
}: PropsWithChildren<LinkComponentProps>) {
  return (
    <>
      {(to && (
        <RouterLink to={to} color="inherit" className={className}>
          {children || to}
        </RouterLink>
      )) ||
        (href && (
          <Link href={href} color="inherit" className={className} target={target}>
            {children || href}
          </Link>
        )) ||
        (onClick && (
          <LinkButton onClick={onClick} className={className} component="span">
            {children}
          </LinkButton>
        ))}
    </>
  );
}
