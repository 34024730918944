import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { pick } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { FontEditParams, FontItem } from 'types/fonts';
import { useSubmittingState } from 'hooks';
import { getTWithPathKey } from 'utils';

import { ButtonComponent } from '../ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from '../FormComponents';
import { VALIDATORS_MAP } from '../FormComponents/const';
import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import { ItemWithData } from '../ItemWithData/ItemWithData';

import { FontPreview } from './FontPreview/FontPreview';

import { Box, GridBtnContainer, GridContainer, OpenDialogButton } from './style';

export const FONT_FORM_ID = 'fontComponent';

export interface FontComponentProps {
  font: FontItem;
  onDelete: (id: number, formId: string) => void;
  onSave: (id: number, data: FontEditParams, formId: string) => Promise<void>;
  openFontFeatureDialog: () => void;
}

export function FontComponent({ font, onDelete, onSave, openFontFeatureDialog }: FontComponentProps) {
  const [currentFontValue, setCurrentFontValue] = useState<FontItem>(font);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const getT = getTWithPathKey('components.fontComponent');

  const handleDeleteFont = () => onDelete(font.id, getFormId());

  const handleChangeFont = (item: Partial<FontItem>) =>
    setCurrentFontValue((prevState: FontItem) => ({ ...prevState, ...item }));

  const handleSave = () =>
    onSave(font.id, pick(currentFontValue, ['name', 'fontWeight', 'fontFamily', 'fontFeature']), getFormId());

  const getFormId = () => `${FONT_FORM_ID}${font.id}`;

  return (
    <ValidatorForm onSubmit={() => withHandlingSubmittingState(handleSave)()} id={getFormId()}>
      <GridContainer container>
        <Grid item xs={11}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <ItemWithData title={getT('uploadedFont')} data={currentFontValue.filename} />
              <ItemWithData title="Font Name" className="mb-0">
                <TextValidatorComponent
                  validators={[VALIDATORS_MAP.required]}
                  label={getT('fontName')}
                  variant="outlined"
                  value={currentFontValue.name}
                  size="small"
                  fieldName="name"
                  handleChange={handleChangeFont}
                  disabled={currentFontValue.isSystem}
                  formId={getFormId()}
                  fullWidth
                />
              </ItemWithData>
              <ItemWithData title="Font Weight" className="mb-0">
                <TextValidatorComponent
                  label={getT('fontWeight')}
                  variant="outlined"
                  value={currentFontValue.fontWeight || ''}
                  size="small"
                  fieldName="fontWeight"
                  handleChange={handleChangeFont}
                  disabled={currentFontValue.isSystem}
                  formId={getFormId()}
                  fullWidth
                />
              </ItemWithData>
              <ItemWithData title="Font Family" className="mb-0">
                <TextValidatorComponent
                  label={getT('fontFamily')}
                  variant="outlined"
                  value={currentFontValue.fontFamily || ''}
                  size="small"
                  fieldName="fontFamily"
                  handleChange={handleChangeFont}
                  disabled={currentFontValue.isSystem}
                  formId={getFormId()}
                  fullWidth
                />
              </ItemWithData>
              <ItemWithData title="Font Feature" className="mb-0">
                <TextValidatorComponent
                  validators={[VALIDATORS_MAP.fontFeature]}
                  label={getT('fontFeature')}
                  variant="outlined"
                  value={currentFontValue.fontFeature || ''}
                  size="small"
                  fieldName="fontFeature"
                  handleChange={handleChangeFont}
                  disabled={currentFontValue.isSystem}
                  formId={getFormId()}
                  fullWidth
                  disableMinHeight
                />
                <OpenDialogButton type="button" onClick={openFontFeatureDialog}>
                  {getT('fontFeatureButton')}
                </OpenDialogButton>
              </ItemWithData>
              {!font.isSystem && (
                <GridBtnContainer>
                  <ButtonComponent variant="text" text="Save" className="no-padding grey" disabled={isSubmitting} type="submit" />
                </GridBtnContainer>
              )}
            </Grid>
            <Grid item xs={6}>
              <FontPreview font={currentFontValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Box>
            {font.isSystem ? (
              <InfoTooltip size="middle" tooltip={getT('fontFeatureButton')} />
            ) : (
              <IconWithConfirmation message="font" handleConfirm={handleDeleteFont} value={currentFontValue} />
            )}
          </Box>
        </Grid>
      </GridContainer>
    </ValidatorForm>
  );
}
