import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { set, pick, isEqual, get, includes, cloneDeep, isArray, startCase } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import {
  CardComponent,
  PageHeader,
  PageHeaderNav,
  RadioGroupComponent,
  SortableList,
  SortableListWrapper,
} from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { currenciesModel, purchasingModel } from 'models';
import { ROUTES } from 'routes/constants';
import { COLORS } from 'theme/constants';
import { findIndexById, getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';
import { withPageLanguage, withPageLanguageDefaultProps, withPageLanguagePropTypes } from 'utils/enhancers';

import { ButtonSettings } from './ButtonSettings/ButtonSettings';
import { FORM_ID, HAS_CUSTOM_SETTINGS, HAS_WEBSIE_SETTINGS, PURCHASING_TYPES_OPTIONS } from './constants';
import { MultilanguageButtonPrewiew } from './MultilanguageButtonPrewiew/MultilanguageButtonPrewiew';

import { Box } from './style';

function PurchasingFlowSettings({ pageLanguageTabValue, children }) {
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const [buttonsSPH, setButtonsSPH] = useState({});
  const [buttonsOrderList, setButtonsOrderList] = useState({});
  const [initialButtons, setInitialButtons] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);
  const [countriesList, setCountries] = useState([]);
  const [purchasingValue, setPurchasingValue] = useState('');
  const [purchasingFlowId, setPurchasingFlowId] = useState();
  const [initialPurchasingValue, setInitialPurchasingValue] = useState('');
  const [properties, setProperties] = useState({});
  const [infoIconUrl, setInfoIconUrl] = useState('');

  const history = useHistory();

  useEffect(() => {
    handleUpdate();
  }, []);

  useEffect(() => {
    setProperties({ ...PURCHASING_TYPES_OPTIONS.find(({ value }) => value === purchasingValue) });
  }, [purchasingValue]);

  const handleUpdate = withPageProgressHandler(async () => {
    try {
      const { data: countriesList } = await currenciesModel.getCountriesList();
      const { data: productFieldNames } = await currenciesModel.getProductFields();
      const { purchasingFlow, id: purchasingId, defaultInfoIconUrl } = await purchasingModel.getSettings();
      const productFields = productFieldNames.map(n => ({ id: n, name: n, title: startCase(n) }));
      const buttonsList = await currenciesModel.getBuyButtons();
      const defaultCountry = countriesList.find(c => c.code === 'DEFAULT');
      const updatedButtons = buttonsList.map(button => {
        const color = { hex: button.color };
        const borderColor = { hex: button.borderColor };
        const updatedButton = { ...button, color, borderColor };

        if (checkIsCustomButton(button)) {
          updatedButton.buttonFont = button.buttonFont ? getFontSettingsWithNums(button.buttonFont) : button.buttonFont;
        }
        if (checkHasCountries(button)) {
          const websites = get(button, 'buttonCountries', []);
          const website = websites.find(w => w.countryId === defaultCountry.id) || {
            countryId: defaultCountry.id,
          };

          updatedButton.buttonCountries = [website, ...websites.filter(w => w.countryId !== defaultCountry.id)];
        }

        return updatedButton;
      });

      const buttonsByTypes = {};

      PURCHASING_TYPES_OPTIONS.forEach(type => {
        buttonsByTypes[type.value] = updatedButtons.filter(button => includes(type.types, button.buttonType));
      });

      setCountries(countriesList);
      setFieldsList(productFields);
      setInitialButtons(cloneDeep(buttonsByTypes));
      setButtonsSPH({ ...buttonsByTypes });
      setButtonsOrderList(cloneDeep(buttonsByTypes));
      setPurchasingValue(purchasingFlow);
      setPurchasingFlowId(purchasingId);
      setInitialPurchasingValue(purchasingFlow);
      setInfoIconUrl(defaultInfoIconUrl);
    } catch (error) {
      handleServerError(error);
    }
  });

  const handleSetButtonsOrderList = newList => setButtonsOrderList(prev => ({ ...prev, [purchasingValue]: newList }));

  const checkIsCustomButton = ({ buttonType }) => includes(HAS_CUSTOM_SETTINGS, buttonType);
  const checkHasCountries = ({ buttonType }) => includes(HAS_WEBSIE_SETTINGS, buttonType);

  const handleSaveSettings = withPageProgressHandler(async ({ target: { id } }) => {
    if (id !== FORM_ID) {
      return;
    }

    let initialButtonsList = [];
    let buttonsForSaving = [];
    let buttonsOrderSaving = [];

    PURCHASING_TYPES_OPTIONS.forEach(type => {
      initialButtonsList = [...initialButtonsList, ...cloneDeep(initialButtons[type.value])];
      buttonsForSaving = [...buttonsForSaving, ...cloneDeep(buttonsSPH[type.value])];
      buttonsOrderSaving = [...buttonsOrderSaving, ...cloneDeep(buttonsOrderList[type.value])];
    });

    await Promise.all([
      ...buttonsForSaving.map(button => {
        const initialButton = initialButtonsList.find(b => b.id === button.id);

        if (
          isEqual(initialButton, {
            ...button,
            position: findIndexById(buttonsOrderSaving, button.id),
          })
        ) {
          return Promise.resolve(true);
        }

        if (checkIsCustomButton(button)) {
          const buttonCountries = button.buttonCountries.filter(w => w.countryId && w.websiteUrl && w.fieldInUrl);

          const buttonFont = button.buttonFont ? getFontSettingsWithPx(button.buttonFont) : button.buttonFont;

          return currenciesModel.saveBuyButtonById(button.id, {
            ...pick({ ...button, buttonCountries }, [
              'enabled',
              'title',
              'signedId',
              'removeImage',
              'buttonCountries',
              'buttonFonts',
              'borderWidth',
              'useBorder',
            ]),
            color: get(button, 'color.hex', ''),
            borderColor: get(button, 'borderColor.hex', ''),
            position: findIndexById(buttonsOrderSaving, button.id),
            buttonFont,
          });
        }

        return currenciesModel.saveBuyButtonById(button.id, {
          ...pick(button, ['enabled']),
          position: findIndexById(buttonsOrderSaving, button.id),
        });
      }),
      isEqual(purchasingValue, initialPurchasingValue)
        ? Promise.resolve(true)
        : purchasingModel.saveSettings(purchasingValue, purchasingFlowId),
    ])
      .then(result => {
        if (result.every(Boolean)) {
          history.push(ROUTES.settings);
        }
      })
      .catch(handleServerError);
  });

  const handleButtonChange = index => button => {
    changeOrderList(button);

    setButtonsSPH(prevState => ({
      ...prevState,
      [purchasingValue]: [...set(prevState[purchasingValue], [index], button)],
    }));
  };

  const changeOrderList = button => {
    const indexWithOrder = findIndexById(buttonsOrderList[purchasingValue], button.id);

    if (buttonsOrderList[purchasingValue][indexWithOrder].enabled !== button.enabled) {
      setButtonsOrderList(prevState => ({
        ...prevState,
        [purchasingValue]: [...set(prevState[purchasingValue], `${[indexWithOrder]}.enabled`, button.enabled)],
      }));
    }
  };

  const checkIsDisabled = button =>
    !button.enabled && buttonsSPH[purchasingValue].reduce((acc, b) => (b.enabled ? acc + 1 : acc), 0) >= 3;

  const renderButtons = () =>
    !!buttonsSPH[purchasingValue]?.length && (
      <>
        <Grid item xs={12} sm={8}>
          {buttonsSPH[purchasingValue].map((button, i) => (
            <ButtonSettings
              button={button}
              disabled={checkIsDisabled(button)}
              setSettings={handleButtonChange(i)}
              key={button.id}
              handleServerError={handleServerError}
              countriesList={countriesList}
              fieldsList={fieldsList}
              checkIsCustomButton={checkIsCustomButton}
              checkHasCountries={checkHasCountries}
              withNav={properties?.withNav}
              pageLanguageTabValue={pageLanguageTabValue}
            >
              <MultilanguageButtonPrewiew
                multilanguage={checkIsCustomButton(button) && isArray(button.title)}
                value={button.title}
                button={button}
                infoButton={{ iconUrl: infoIconUrl, color: COLORS.infoIcon }}
                isCustomButton={checkIsCustomButton(button)}
                pageLanguageTabValue={pageLanguageTabValue}
              />
            </ButtonSettings>
          ))}
        </Grid>
        {properties?.withOrder && (
          <Grid item sm={4}>
            <CardComponent title="BUTTONS ORDER" sm={12}>
              <SortableListWrapper list={buttonsOrderList[purchasingValue]} setList={handleSetButtonsOrderList}>
                <SortableList list={buttonsOrderList[purchasingValue]} textPath="buttonType" />
              </SortableListWrapper>
            </CardComponent>
          </Grid>
        )}
      </>
    );

  return (
    <ValidatorForm onSubmit={handleSaveSettings} id={FORM_ID}>
      <PageHeader title="Purchasing Flow" backLink={ROUTES.settings}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      {children}
      <Grid container spacing={6}>
        <CardComponent title="Type of purchasing flow" sm={8} wrapperClassName="mb-0">
          <Box mb={4}>
            <Typography variant="h6">You can choose one of the flows which will be used in your spheres.</Typography>
          </Box>
          <Box className="title-container">
            <Typography variant="h4">Select one</Typography>
          </Box>
          <RadioGroupComponent
            groupValue={purchasingValue}
            setGroupValue={setPurchasingValue}
            items={PURCHASING_TYPES_OPTIONS}
          />
        </CardComponent>
        {renderButtons()}
      </Grid>
    </ValidatorForm>
  );
}

PurchasingFlowSettings.propTypes = {
  ...withPageLanguagePropTypes,
};

PurchasingFlowSettings.defaultProps = {
  ...withPageLanguageDefaultProps,
};

export default withPageLanguage(PurchasingFlowSettings);
