import React, { useEffect, useRef, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link, Hidden, Divider, Grid, Typography, Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import Helmet from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'components';
import { LINKS } from 'components/constants';
import DividerWithText from 'components/DividerWithText/DividerWithText';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import config from 'config/Config';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { authModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getQueryParams } from 'utils';

import { AuthMessage } from '../AuthMessage/AuthMessage';

import {
  StyledButtonComponent,
  TermsAndConditionsText,
  RedirectText,
  AuthCard,
  FormLink,
  TextValidator,
} from '../style';

import { ReactComponent as GoogleIcon } from 'assets/img/google.svg';

const SignUpPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleServerError } = useServerErrorActions();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const captchaRef = useRef();
  const [resendEmailToken, setResendEmailToken] = useState(false);
  const [timeoutActive, setTimeoutActive] = useState(false);
  const [state, setState] = useState({
    [authModel.fields.space]: '',
    [authModel.fields.firstName]: '',
    [authModel.fields.lastName]: '',
    [authModel.fields.email]: '',
    [authModel.fields.terms]: false,
  });

  useEffect(() => {
    const preAuthToken = getQueryParams().pre_auth_token;

    if (preAuthToken) {
      history.push({
        pathname: ROUTES.authGoogleSignUp,
        search: `?pre_auth_token=${preAuthToken}`,
      });
    }
  }, []);

  const submitForm = () => {
    captchaRef.current.execute();
  };

  const onCaptchaVerify = token => {
    authModel
      .signUp({ ...state, [authModel.fields.hCaptcha]: token })
      .then(data => {
        setResendEmailToken(data.resendEmailToken);
      })
      .catch(error => {
        handleServerError(error);
      });
  };

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const onSignIn = () => history.push(ROUTES.authSignIn);

  const handleTimeout = timeToExpire => {
    if (timeToExpire > 0) {
      setTimeout(() => {
        authModel.getResendEmailTimeout({ token: resendEmailToken }).then(data => {
          handleTimeout(data.resendEmailTimeout * 1000);
        });
      }, timeToExpire);
    } else {
      setTimeoutActive(false);
    }
  };

  const onResendEmail = () => {
    setTimeoutActive(true);
    authModel
      .resendEmail({ token: resendEmailToken })
      .then(data => {
        handleTimeout(data.resendEmailTimeout * 1000);
      })
      .catch(error => {
        handleServerError(error);
      });
  };

  const onSignUpWithGoogle = () => {
    authModel.redirectToGoogleAuth(authModel.googleFlow.signUp);
  };

  const disableSignUpButton = Object.values(state).some(item => !item);

  if (resendEmailToken) {
    return (
      <AuthMessage
        tabTitle={t('pages.auth.signUpPage.tabTitle')}
        title={
          timeoutActive ? t('pages.auth.signUpPage.emailSentTimeoutTitle') : t('pages.auth.signUpPage.emailSentTitle')
        }
        text={
          timeoutActive ? (
            t('pages.auth.signUpPage.emailSentTimeoutMessage')
          ) : (
            <Trans i18nKey="pages.auth.signUpPage.emailSentMessage" values={{ email: state[authModel.fields.email] }} />
          )
        }
        buttonText={t('pages.auth.signUpPage.resendEmailBtn')}
        onClick={onResendEmail}
        disabled={timeoutActive}
        footer={
          isMobile ? undefined : (
            <ButtonComponent
              variant="text"
              onClick={onSignIn}
              className="no-padding"
              text={t('pages.auth.signUpPage.return')}
              color="primary"
            />
          )
        }
      />
    );
  }

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signUpPage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signUpPage.title')}</Typography>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={submitForm} style={{ width: '100%' }}>
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.spaceName]}
              label={t('common.forms.labels.spaceName')}
              value={state[authModel.fields.space]}
              fieldName={authModel.fields.space}
              handleChange={handleChange}
              helperText={t('pages.auth.signUpPage.spaceNameInfo')}
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.firstName')}
              value={state[authModel.fields.firstName]}
              fieldName={authModel.fields.firstName}
              handleChange={handleChange}
              autoComplete="given-name"
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.lastName')}
              value={state[authModel.fields.lastName]}
              fieldName={authModel.fields.lastName}
              handleChange={handleChange}
              autoComplete="family-name"
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
              label={t('common.forms.labels.email')}
              value={state[authModel.fields.email]}
              fieldName={authModel.fields.email}
              handleChange={handleChange}
              type="email"
              autoComplete="email"
            />
            <Grid container direction="column" spacing={6}>
              <Grid item container alignItems="center" wrap="nowrap">
                <FormControlLabel
                  style={{
                    border: 'none',
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      value={state.terms}
                      onChange={() => handleChange({ terms: !state.terms })}
                    />
                  }
                  label={
                    <TermsAndConditionsText>
                      {`${t('pages.auth.common.termsAndConditionsCheckbox')} `}
                      <Link href={LINKS.terms} target="_blank" rel="noopener noreferrer">
                        {t('pages.auth.common.terms')}
                      </Link>
                      {` ${t('pages.auth.common.and')} `}
                      <Link href={LINKS.privacyPolicy} target="_blank" rel="noopener noreferrer">
                        {t('pages.auth.common.privacy')}
                      </Link>
                    </TermsAndConditionsText>
                  }
                />
              </Grid>
              <Grid item>
                <StyledButtonComponent
                  fullWidth
                  color="primary"
                  type="submit"
                  text={t('pages.auth.signUpPage.emailSignUpBtn')}
                  disabled={disableSignUpButton}
                />
                <HCaptcha onVerify={onCaptchaVerify} sitekey={config.captcha_key} ref={captchaRef} size="invisible" />
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
        <Grid item>
          <DividerWithText text={t('pages.auth.common.divider')} />
        </Grid>
        <Grid item>
          <StyledButtonComponent
            fullWidth
            color="primary"
            text={t('pages.auth.signUpPage.googleSignUpBtn')}
            variant="outlined"
            Icon={GoogleIcon}
            onClick={onSignUpWithGoogle}
          />
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <RedirectText>
              {`${t('pages.auth.signUpPage.infoToSignIn')} `}
              <FormLink variant="text" onClick={onSignIn} text={t('pages.auth.signUpPage.linkToSignIn')} />
            </RedirectText>
          </Grid>
        </Hidden>
      </Grid>
    </AuthCard>
  );
};

export default SignUpPage;
