import { Box as MuiBox, Typography as MuiTypography, styled, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const ImagesContainer = styled(MuiBox)({
  border: `2px dashed ${COLORS.cozmosBlue}70`,
  borderRadius: '5px',
  padding: '20px 12px',
  marginBottom: '12px',
  display: 'flex',
  flexWrap: 'wrap',
  '& .dz-preview': { margin: '8px' },
});

export const Typography = withStyles({
  root: {
    fontSize: '14px',
    marginBottom: '16px',
  },
})(MuiTypography);
