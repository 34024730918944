import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'components';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useAuthActions } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { tfaModel } from 'models';
import { AuthCard, TextValidator } from 'pages/auth/style';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey, navigateByReturnToUrl } from 'utils';

import { LINKS } from './constants';

import { AuthenticatorButton, Box } from './style';
import { Image } from 'components/style';

import appStore from 'assets/img/app-store.jpeg';
import googlePlay from 'assets/img/google-play.png';

export default function TwoFactorSignIn() {
  const [code, setCode] = useState('');
  const [qrCodeAsSvg, setQrCodeAsSvg] = useState(null);
  const [firstStepGA, setFirstStepGA] = useState(true);
  const { login } = useAuthActions();
  const { handleServerError } = useServerErrorActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const history = useHistory();
  const getT = getTWithPathKey('pages.auth.TwoFactorSetup');

  useEffect(() => {
    tfaModel.getNewSettings().then(setQrCodeAsSvg).catch(handleServerError);
  }, []);

  const handleNext = () => setFirstStepGA(false);
  const handleBack = () => setFirstStepGA(true);

  const onSubmit = () =>
    tfaModel
      .postSettings({
        twoFa: { code },
      })
      .then(attributes => {
        if (attributes.enabled) {
          navigateByReturnToUrl();
          login({
            currentUser: {
              isLoading: false,
              isSignedIn: true,
              attributes,
            },
          });
        }
      })
      .then(() => history.push(ROUTES.home))
      .catch(handleServerError);

  const handleChange = item => setCode(item.code);

  return (
    <AuthCard>
      <Helmet title={getT('title')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{getT('title')}</Typography>
        </Grid>
        {firstStepGA ? (
          <>
            <Grid item>
              <Typography>{getT('caption')}</Typography>
            </Grid>
            <Grid item>
              <Typography component={Box}>
                {getT('appleLabel')}
                <AuthenticatorButton href={LINKS.appStore} target="_blank">
                  <Box className="image-wrapper">
                    <Image src={appStore} />
                  </Box>
                  {getT('googleAuthenticator')}
                </AuthenticatorButton>
              </Typography>
              <Typography component={Box}>
                {getT('androidLabel')}
                <AuthenticatorButton href={LINKS.googlePlay} target="_blank">
                  <Box className="image-wrapper">
                    <Image src={googlePlay} />
                  </Box>
                  {getT('googleAuthenticator')}
                </AuthenticatorButton>
              </Typography>
            </Grid>
            <Box className="flex" dangerouslySetInnerHTML={{ __html: qrCodeAsSvg }} />
            <Box mt={3} className="flex">
              <ButtonComponent color="primary" className="right" text={getT('next')} onClick={handleNext} />
            </Box>
          </>
        ) : (
          <>
            <ValidatorForm onSubmit={() => withHandlingSubmittingState(onSubmit)()}>
              <Box mt={3}>
                <TextValidator
                  validators={[VALIDATORS_MAP.required]}
                  label={getT('codeLabel')}
                  value={code}
                  fieldName="code"
                  handleChange={handleChange}
                  autoFocus
                />
              </Box>
              <Box mt={3} className="flex">
                <ButtonComponent color="primary" text={getT('back')} onClick={handleBack} />
                <ButtonComponent
                  type="submit"
                  color="primary"
                  className="right"
                  text={getT('signIn')}
                  loaderSize="1.35em"
                  loading={isSubmitting}
                />
              </Box>
            </ValidatorForm>
          </>
        )}
      </Grid>
    </AuthCard>
  );
}
