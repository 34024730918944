import React, { useEffect } from 'react';

import { get, keys } from 'lodash';
import PropTypes from 'prop-types';

import { DropzoneImage } from 'components/DropzoneImage/DropzoneImage';
import { getCountAndDataPath } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

import { DialogTitle, DialogContent } from '../style';
import { ImagesContainer, Typography } from './style';

export function SelectImageWithPagination({
  title,
  text,
  getPictures,
  images,
  setImages,
  selectedImagesIds,
  onChangeSelected,
  paginationItemsCount,
  paginationParams,
  setPaginationItemsCount,
}) {
  useEffect(() => {
    getPictures(paginationParams).then(data => {
      if (data) {
        const { countPath, dataPath } = getCountAndDataPath(keys(data));

        setImages(get(data, dataPath, []));
        setPaginationItemsCount(get(data, countPath, 0));
      }
    });
  }, [paginationParams]);

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {title} {paginationItemsCount}
      </DialogTitle>
      <DialogContent>
        {text && <Typography>{text}</Typography>}
        <ImagesContainer>
          {images.map(image => (
            <DropzoneImage
              key={image.id}
              image={image}
              selectedImages={selectedImagesIds}
              onChangeSelected={onChangeSelected}
              withRemove={false}
              withCheckbox
            />
          ))}
        </ImagesContainer>
      </DialogContent>
    </>
  );
}

SelectImageWithPagination.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  getPictures: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setImages: PropTypes.func.isRequired,
  selectedImagesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  ...withPaginationPropTypes,
};

SelectImageWithPagination.defaultProps = {
  text: '',
};

export default withPagination(SelectImageWithPagination);
