import { useState, useEffect } from 'react';

export const REQUEST_TIMEOUT_MS = 5000;

const useTimeoutUpdate = (
  condition: boolean,
  getData: () => Promise<void> = () => Promise.resolve(),
  updateReason: any,
  delay = REQUEST_TIMEOUT_MS,
) => {
  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
      setUpdateTimeout(null);
    }

    if (condition) {
      setUpdateTimeout(setTimeout(getData, delay));
    }
  }, [updateReason, condition]);
};

export default useTimeoutUpdate;
