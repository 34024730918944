import React, { memo } from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from 'components/propTypesConsts';
import { getInlineFontStyles, getTWithPathKey } from 'utils';
import { withLocalization } from 'utils/enhancers';

import { BUTTON_TYPES, FONT_TYPES } from '../constants';

import { OverlayButtonPreview } from './OverlayButtonPreview';
import { OverlayInputPreview } from './OverlayInputPreview';

import { Box } from '../style';

function SignInOverlayPreviewComponent({ settings, currentLanguageCode }) {
  const getT = getTWithPathKey('components.ecommerce.signInOverlayPreview');

  const getFontSettings = name => settings?.ecommerceFonts.find(({ assignment }) => assignment === name);

  const getButtonSettings = btnType => settings.ecommerceButtons.find(({ buttonType }) => buttonType === btnType);

  const getButtonStyles = btnType => {
    const { color, useBorder, borderWidth, borderColor } = getButtonSettings(btnType);

    return {
      backgroundColor: color?.hex || 'transparent',
      border: useBorder ? `${borderWidth}px solid ${borderColor.hex}` : 'none',
    };
  };

  return (
    <Box className="table-wrapper" p={7} style={{ backgroundColor: settings.backgroundColor.hex }}>
      <Grid container justify="flex-end">
        <Grid item>
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.header).font}
            style={{
              ...getInlineFontStyles(getFontSettings(FONT_TYPES.header)),
              textTransform: 'uppercase',
            }}
          >
            <Box className="btn-wrap regular">
              <Box className="overlay-button overlay-button-close">
                <span />
              </Box>
            </Box>
          </CustomFontWrapper>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ margin: '30px 0' }}>
        <Grid item>
          <CustomFontWrapper
            font={getFontSettings(FONT_TYPES.header).font}
            style={{
              ...getInlineFontStyles(getFontSettings(FONT_TYPES.header)),
              textTransform: 'uppercase',
            }}
          >
            {getT('header')}
          </CustomFontWrapper>
        </Grid>
      </Grid>
      <Grid container alignItems="center" direction="column">
        <CustomFontWrapper font={getFontSettings(FONT_TYPES.textFieldInfo).font}>
          <Grid item md={12} style={{ marginBottom: '15px' }}>
            <OverlayInputPreview
              value={getT('loginPlaceholder', { lng: currentLanguageCode })}
              style={{
                borderColor: settings.ecommerceTextField.borderColor.hex,
                borderWidth: `${settings.ecommerceTextField.borderWidth}px`,
                ...getInlineFontStyles(getFontSettings(FONT_TYPES.textFieldInfo)),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <OverlayInputPreview
              value={getT('passPlaceholder', { lng: currentLanguageCode })}
              style={{
                borderColor: settings.ecommerceTextField.borderColor.hex,
                borderWidth: `${settings.ecommerceTextField.borderWidth}px`,
                ...getInlineFontStyles(getFontSettings(FONT_TYPES.textFieldInfo)),
              }}
            />
          </Grid>
        </CustomFontWrapper>
      </Grid>
      <Grid container alignItems="center" direction="column" style={{ margin: '45px 0' }}>
        <Grid item style={{ marginBottom: '5px' }}>
          <CustomFontWrapper font={getFontSettings(FONT_TYPES.primaryButton).font}>
            <OverlayButtonPreview
              value={getButtonSettings(BUTTON_TYPES.primary).title}
              style={{
                ...getInlineFontStyles(getFontSettings(FONT_TYPES.primaryButton)),
                ...getButtonStyles(BUTTON_TYPES.primary),
              }}
              currentLanguageCode={currentLanguageCode}
            />
          </CustomFontWrapper>
        </Grid>
        <Grid item>
          <CustomFontWrapper
            font={getFontSettings('link').font}
            style={{
              ...getInlineFontStyles(getFontSettings('link')),
              textDecoration: 'underline',
            }}
          >
            {getT('resetPassBtn', { lng: currentLanguageCode })}
          </CustomFontWrapper>
        </Grid>
      </Grid>

      <Grid container justify="center">
        <CustomFontWrapper font={getFontSettings(FONT_TYPES.secondaryButton).font}>
          <OverlayButtonPreview
            value={getButtonSettings(BUTTON_TYPES.secondary).title}
            style={{
              ...getInlineFontStyles(getFontSettings(FONT_TYPES.secondaryButton)),
              ...getButtonStyles(BUTTON_TYPES.secondary),
            }}
            currentLanguageCode={currentLanguageCode}
          />
        </CustomFontWrapper>
      </Grid>
    </Box>
  );
}

SignInOverlayPreviewComponent.propTypes = {
  settings: PropTypes.shape({
    ecommerceFonts: PropTypes.arrayOf(fontSettingsProp),
    ecommerceButtons: PropTypes.arrayOf(PropTypes.shape({})),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }).isRequired,
    ecommerceTextField: PropTypes.shape({
      borderColor: PropTypes.shape({
        hex: PropTypes.string,
      }),
      borderWidth: PropTypes.number,
    }),
  }).isRequired,
  currentLanguageCode: PropTypes.string.isRequired,
};

export const SignInOverlayPreview = withLocalization(memo(SignInOverlayPreviewComponent));
