import { styled } from '@material-ui/core';
import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButton as MuiToggleButton } from '@material-ui/lab';

import { COLORS } from 'theme/constants';

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  flexWrap: 'wrap',
});

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  ...theme.typography.caption,
  '&.Mui-selected': {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.dark,
  },
  borderRadius: '4px!important',
  backgroundColor: COLORS.bgLight,
  color: COLORS.greyText,
  height: '36px',
  border: 'none',
  margin: '0 8px 8px 0',
}));
