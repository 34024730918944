import { CookiesTable } from 'types/overlays';
import { deepObjectKeysToCamelCase, deepObjectKeysToSnakeCase } from 'utils';

import { api } from './setupApi';

class CookiesTablesModel {
  link = '/cookies_tables';

  getData = (): Promise<CookiesTable> => api.get(this.link).then(({ data }) => deepObjectKeysToCamelCase(data));

  putData = ({ id, ...data }: CookiesTable): Promise<CookiesTable> =>
    api.put(`${this.link}/${id}`, deepObjectKeysToSnakeCase(data)).then(({ data }) => deepObjectKeysToCamelCase(data));
}

export const cookiesTablesModel = new CookiesTablesModel();
