import { ImageStatus } from 'types/images';

import error from 'assets/img/error.png';
import generating from 'assets/img/generating.png';
import previewUnavailable from 'assets/img/preview_unavailable.png';

export const STATUSES_MAP = {
  [ImageStatus.PENDING]: generating,
  [ImageStatus.DOWNLOADING]: generating,
  [ImageStatus.CREATING_VARIANTS]: generating,
  [ImageStatus.VALIDATION_DIMENSIONS]: generating,
  [ImageStatus.DIMENSIONS_OK]: generating,
  [ImageStatus.DIMENSIONS_ERROR]: error,
  [ImageStatus.ERROR_GENERATING]: error,
  [ImageStatus.PREPARING_TO_CUT_TILES]: '',
  [ImageStatus.CUTTING_TILES]: '',
  [ImageStatus.UPLOADING_TILES]: '',
  [ImageStatus.GENERATED]: '',
  noImage: previewUnavailable,
};

export const IN_PROGRESS_STATUSES = [
  ImageStatus.PENDING,
  ImageStatus.UPLOADING_TILES,
  ImageStatus.DOWNLOADING,
  ImageStatus.CREATING_VARIANTS,
  ImageStatus.VALIDATION_DIMENSIONS,
  ImageStatus.DIMENSIONS_OK,
  ImageStatus.PREPARING_TO_CUT_TILES,
  ImageStatus.CUTTING_TILES,
];

export const ERRORS_STATUSES = [ImageStatus.DIMENSIONS_ERROR, ImageStatus.ERROR_GENERATING];
