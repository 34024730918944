import React from 'react';

import { func, shape, string } from 'prop-types';

import { getTWithPathKey } from 'utils';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { Box, PreviewBox, Typography, TitleBox, InvertColorsIcon } from './style';

const getT = getTWithPathKey('components.overlayColorsComponent');

export const OverlayColorsComponent = ({ colors, onColorChange, fieldsHelpText }) => {
  const renderPreviewTitle = title => (
    <TitleBox>
      <InvertColorsIcon />
      <Typography variant="h2">{title}</Typography>
    </TitleBox>
  );

  return (
    <>
      <Box mt={3}>
        <ColorPicker setColor={onColorChange('primaryColor')} color={{ hex: colors.primaryColor }} />
        <Box ml={2}>
          <Typography variant="caption">{fieldsHelpText.primaryColor}</Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <ColorPicker setColor={onColorChange('secondaryColor')} color={{ hex: colors.secondaryColor }} />
        <Box ml={2}>
          <Typography variant="caption">{fieldsHelpText.secondaryColor}</Typography>
        </Box>
      </Box>
      <PreviewBox className="primary" style={{ backgroundColor: colors.primaryColor }}>
        {renderPreviewTitle('Primary color')}
        <PreviewBox className="secondary" style={{ backgroundColor: colors.secondaryColor }}>
          {renderPreviewTitle('Secondary color')}
        </PreviewBox>
      </PreviewBox>
    </>
  );
};

OverlayColorsComponent.defaultProps = {
  fieldsHelpText: {
    primaryColor: getT('primaryColorHelpText'),
    secondaryColor: getT('secondaryColorHelpText'),
  },
};

OverlayColorsComponent.propTypes = {
  onColorChange: func.isRequired,
  colors: shape({
    primaryColor: string,
    secondaryColor: string,
  }).isRequired,
  fieldsHelpText: shape({
    primaryColor: string,
    secondaryColor: string,
  }),
};
