import React, { useState, useEffect, memo, useMemo, Dispatch, SetStateAction, useRef } from 'react';

import { Box, IconButton } from '@material-ui/core';
import { get, slice, includes, set } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ImageUploader, TabsComponent } from 'components';
import { DotsIcon, SmallDotsIcon } from 'components/CustomIcons';
import { useAuthState } from 'contexts/AuthContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { activeStorageModel, picturesModel } from 'models';
import { HandleServerErrorFunc, PaginationProps } from 'types/common';
import { FileWithSignedId, Image, PdfStatus } from 'types/images';
import { ProductVariant, VariantColumns } from 'types/product';
import { findIndexById, getServerError, getTranslation, getTWithPathKey } from 'utils';
import { withPagination, withCardWrapper } from 'utils/enhancers';
import { ComponentWithCardWrapperProps } from 'utils/enhancers/withCardWrapper';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';

import { PICTURE_TYPES_FILTER, PICTURE_TYPES_MAP, PICTURES_TYPES } from '../constants';

import VariantDetailsDialog from './VariantDetailsDialog/VariantDetailsDialog';

const imagesPerPage = 10;
const VIEW_SIZE_MAP = { regular: 'regular', small: 'small' };

type ProductFilter = Record<string, (image: Image) => boolean>;

interface ProductPicturesProps extends PaginationProps, ComponentWithCardWrapperProps {
  columns: VariantColumns[];
  pictures: Image[];
  sectionType: string;
  id?: string | null;
  readonly?: boolean;
  unloadImageErrorText?: string;
  variants?: ProductVariant[];
  withFilterTabs?: boolean;
  withPdfLoader?: boolean;
  withUseOnSphere?: boolean;
  withShoppingPlatformCheck?: boolean;
  withHeaderText?: boolean;
  setPictures: Dispatch<SetStateAction<Image[]>>;
  handleServerError: HandleServerErrorFunc;
  setImagesToDelete: (imageId: number) => void;
  withAddToGallery?: boolean;
  withAllPicturesRemove?: boolean;
  pdfStatuses?: PdfStatus[];
  updatePdfStatuses?: Dispatch<SetStateAction<PdfStatus[]>>;
}

const ProductPicturesComponent = ({
  id = null,
  pictures,
  setPictures,
  withFilterTabs = false,
  withPdfLoader = false,
  withUseOnSphere = false,
  withShoppingPlatformCheck = false,
  withHeaderText = false,
  handleServerError,
  setImagesToDelete,
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
  setPaginationPerPage,
  readonly = false,
  columns,
  variants = [],
  sectionType,
  setLoader,
  unloadImageErrorText = getTranslation('common.errors.noBlankPictures'),
  withAddToGallery = false,
  withAllPicturesRemove = false,
  pdfStatuses = [],
  updatePdfStatuses,
}: ProductPicturesProps) => {
  const { t } = useTranslation();
  const pdfStatusesRef = useRef<PdfStatus[]>([]);
  const [imagesCurPage, setImagesCurPage] = useState<Image[]>([]);
  const [viewSize, setViewSize] = useState(VIEW_SIZE_MAP.regular);
  const [currentTab, setCurrentTab] = useState(PICTURE_TYPES_MAP.all);
  const [variantImage, setVariantImage] = useState<Image | null>(null);
  const {
    currentUser: {
      attributes: { productImagesRequired, shoppingPlatformEnabled, shoppingPlatformName },
    },
  } = useAuthState();
  const getT = getTWithPathKey(`pages.products.manageProduct.${sectionType}`);
  const getButtonT = getTWithPathKey(`components.imageUploader`);

  const isShoppingPlatformExists = shoppingPlatformEnabled && !!shoppingPlatformName;
  const useShoppingPlatformInfo = withShoppingPlatformCheck && isShoppingPlatformExists;

  const filteredProductPictures = useMemo(
    () =>
      currentTab === PICTURE_TYPES_MAP.all
        ? pictures
        : pictures.filter((PICTURE_TYPES_FILTER as ProductFilter)[currentTab]), // TODO:remove as after refactor ../constants
    [pictures, currentTab],
  );

  useEffect(() => {
    if (setPaginationPerPage) {
      viewSize === VIEW_SIZE_MAP.regular
        ? setPaginationPerPage(imagesPerPage)
        : setPaginationPerPage(filteredProductPictures.length);
    }
  }, [viewSize, filteredProductPictures]);

  useEffect(() => {
    setPaginationItemsCount(filteredProductPictures?.length);
  }, [filteredProductPictures]);

  useEffect(() => {
    const { startImgNum, endImgNum } = getStartEndImgNum();

    setImagesCurPage(slice(filteredProductPictures, startImgNum, endImgNum));
  }, [paginationParams.page, filteredProductPictures]);

  useEffect(() => resetPaginationPage(), [currentTab]);

  const isSmallView = useMemo(() => viewSize === VIEW_SIZE_MAP.small, [viewSize]);

  const imageUploaderText = useMemo(
    () => (useShoppingPlatformInfo ? getT('shopifyImageUploaderText') : getT('imageUploaderText')),
    [useShoppingPlatformInfo],
  );

  const handleEditImage = (imgId: number, data: Image) =>
    setPictures(prev => prev.map(item => (item.id === imgId ? { ...item, ...data } : item)));

  const loadImage = (image: FileWithSignedId) => {
    const signedId = get(image, 'signed_id');
    const productId = id || 'new_product';

    return picturesModel.addPicture({ signedId, productId }).then((picture: Image) => {
      setPictures(prevProductPictures => [
        {
          ...picture,
          ...(withAddToGallery ? { isGallery: true } : {}),
        },
        ...prevProductPictures,
      ]);
      resetPaginationPage();
    });
  };

  const unloadImage = (imageId: number) => {
    const isPictureRemoveAllowed = withAllPicturesRemove || pictures.length > 1 || !productImagesRequired;

    if (isPictureRemoveAllowed) {
      setImagesToDelete(imageId);
    } else {
      handleServerError(getServerError(unloadImageErrorText));
    }
  };

  const getPdfPictures = async (pdfId: number, index: number) => {
    const productId = id || undefined;
    const { status, pictures } = await picturesModel.getPdfPictures(pdfId, productId);

    if (status === 'finished' && pictures) {
      setPictures(prevProductPictures => [...pictures.map(i => ({ ...i, created: true })), ...prevProductPictures]);

      pdfStatusesRef.current.splice(index, 1);

      await Promise.resolve(true);
    } else if (!['error', 'finished'].includes(status)) {
      await new Promise(resolve => {
        setTimeout(async () => {
          resolve(await getPdfPictures(pdfId, index));
        }, 2500);
      });
    }
  };

  useEffect(() => {
    pdfStatusesRef.current = [...pdfStatuses];

    if (pdfStatusesRef.current.length) {
      setLoader(true);

      Promise.all(pdfStatusesRef.current.map((pdf, index) => getPdfPictures(pdf.id, index))).finally(() => {
        if (updatePdfStatuses) {
          updatePdfStatuses(pdfStatusesRef.current);
        }

        setLoader(false);
      });
    }
  }, [pdfStatuses]);

  const uploadPdf = async (file: FileWithSignedId) => {
    const signedId = get(file, 'signed_id');
    const productId = id || undefined;

    setLoader(true);

    try {
      const pdfPicturesStatus = await picturesModel.addPdfPictures({ documentSignedId: signedId, productId });

      if (updatePdfStatuses) {
        updatePdfStatuses(prevStatuses => [...prevStatuses, pdfPicturesStatus]);
      }

      resetPaginationPage();
    } catch (error) {
      await activeStorageModel.deleteUploads(signedId);
      handleServerError(error as ServerErrorType);
      setLoader(false);
    }
  };

  const setImagesOnSphere = (selectedImagesIds: number[]) =>
    setPictures(prev => prev.map(img => ({ ...img, shouldUsedOnPlanogram: includes(selectedImagesIds, img.id) })));

  const removeImageFromSphere = (imageId: number) =>
    setPictures(prevProductPictures => [
      ...set(prevProductPictures, `[${findIndexById(prevProductPictures, imageId)}][usedOnPlanogram]`, false),
    ]);

  const getStartEndImgNum = () => {
    const startImgNum = paginationParams.page * imagesPerPage;
    const endImgNum =
      startImgNum + imagesPerPage < filteredProductPictures.length
        ? startImgNum + imagesPerPage
        : filteredProductPictures.length;

    return { startImgNum, endImgNum };
  };

  const handleSetProductPictures = (newImages: Image[]) => {
    if (!isSmallView) {
      setPictures(prevProductPictures => {
        const { startImgNum, endImgNum } = getStartEndImgNum();
        const productPicturesSet = new Set([
          ...slice(filteredProductPictures, 0, startImgNum),
          ...newImages,
          ...slice(filteredProductPictures, endImgNum),
          ...prevProductPictures,
        ]);

        return Array.from(productPicturesSet);
      });
    } else {
      setPictures(prevProductPictures => {
        const productPicturesSet = new Set([...newImages, ...prevProductPictures]);

        return Array.from(productPicturesSet);
      });
    }

    setImagesCurPage(newImages);
  };

  const handleSetViewSize = (size: string) => () => setViewSize(size);

  const handleInfo = (image: Image) => setVariantImage(image);

  const closeVariantDialog = () => setVariantImage(null);

  const images = useMemo(
    () => (isSmallView ? filteredProductPictures : imagesCurPage),
    [isSmallView, filteredProductPictures, imagesCurPage],
  );

  const handleTabChange = (value: string) => {
    setCurrentTab(value);
  };

  const isVariantsTab = currentTab === PICTURE_TYPES_MAP.variants;
  const isImageReadOnly = (image: Image) => (PICTURE_TYPES_FILTER.uploaded(image) && !isVariantsTab ? false : readonly);

  const handleAddToGallery = (image: Image) =>
    setPictures(prev =>
      prev.map(prevImage => {
        if (image.id === prevImage.id) {
          prevImage.isGallery = !prevImage.isGallery;
        }
        return prevImage;
      }),
    );

  const renderImgViewButtons = () => (
    <Box display="flex" flexWrap="no-wrap">
      <IconButton onClick={handleSetViewSize(VIEW_SIZE_MAP.regular)} color={!isSmallView ? 'primary' : 'default'}>
        <DotsIcon />
      </IconButton>
      <IconButton onClick={handleSetViewSize(VIEW_SIZE_MAP.small)} color={isSmallView ? 'primary' : 'default'}>
        <SmallDotsIcon />
      </IconButton>
    </Box>
  );

  const getLabelTranslate = (labelTab: string) =>
    t(`pages.products.manageProduct.productPictures.dropzoneTabs.${labelTab}`);

  const renderTabs = (): JSX.Element | boolean =>
    !!withFilterTabs &&
    !!useShoppingPlatformInfo && (
      <TabsComponent
        tabNames={PICTURES_TYPES}
        value={currentTab}
        setValue={handleTabChange}
        tabClassName="pictureTab"
        rootClassName="pictureTabs"
        useIndex={false}
        useTranslate
        getTranslate={getLabelTranslate}
      />
    );

  const isHeaderTextVisible = withHeaderText && !useShoppingPlatformInfo;
  const uploaderHeaderText = isHeaderTextVisible ? getT('imageUploaderHeaderText') : '';
  const uploaderAdditionalText = withPdfLoader ? getT('imageUploaderAdditionalText') : '';

  const showAddToGalleryIcon = withAddToGallery && !isVariantsTab;

  return (
    <>
      <ImageUploader
        images={images}
        loadPdf={uploadPdf}
        loadImage={loadImage}
        unloadImage={unloadImage}
        handleServerError={handleServerError}
        onSelect={setImagesOnSphere}
        handleUnselect={removeImageFromSphere}
        setImagesWithOrder={handleSetProductPictures}
        headerText={uploaderHeaderText}
        text={imageUploaderText}
        additionalText={uploaderAdditionalText}
        smallView={isSmallView}
        renderNav={renderImgViewButtons}
        renderTabs={renderTabs}
        withEdit={!isVariantsTab}
        handleEdit={handleEditImage}
        section={DOWNLOAD_SECTIONS.productImages}
        withUploadButton
        withPdfUpload={withPdfLoader}
        withOrder
        withUseOnSphere={withUseOnSphere}
        isUploadEnabled={useShoppingPlatformInfo}
        isImageReadonly={isImageReadOnly}
        readonly={readonly}
        isSelectable={!withPdfLoader && !isVariantsTab}
        hasMultiselect={false}
        handleAddToGallery={handleAddToGallery}
        withAddToGallery={withAddToGallery}
        showAddToGallery={showAddToGalleryIcon}
        withInfo={isVariantsTab}
        handleInfo={handleInfo}
        uploadButtonText={getButtonT('uploadPNG')}
      />
      {shoppingPlatformName && variantImage && (
        <VariantDetailsDialog
          variantImage={variantImage}
          variants={variants}
          handleClose={closeVariantDialog}
          columns={columns}
        />
      )}
    </>
  );
};

export const ProductPictures = memo(withCardWrapper(withPagination(ProductPicturesComponent)));
