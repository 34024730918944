import React from 'react';

import { UploadStatusBar } from 'components/UploadStatusBar/UploadStatusBar';

export const withDownloadsStatus =
  <T,>(Component: React.ComponentType<T>) =>
  (props: T): JSX.Element =>
    (
      <>
        <Component {...props} />
        <UploadStatusBar />
      </>
    );
