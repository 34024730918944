import React from 'react';

import { CardComponent, ItemWithData, PageHeader, PageHeaderNav, SettingsForm } from 'components';
import { useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { ROLES } from 'routes/constants';
import { AuthProvider } from 'types';
import { getFullName } from 'utils';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';
import { formatSSOProviderType } from 'utils/helpers/stringHelpers';

import { TFASwitch, ChangePassword, PersonalInfo, ClientInfo } from '../settings';

function ProfileAdmins({ setSuccessActionMessages }) {
  const {
    currentUser: { attributes },
  } = useAuthState();
  const { handleServerError } = useServerErrorActions();

  const checkIsSystemAdmin = () => attributes.role === ROLES.systemAdmin;

  return (
    <>
      <PageHeader title={getFullName(attributes)}>
        <PageHeaderNav />
      </PageHeader>
      <PersonalInfo
        handleServerError={handleServerError}
        setSuccessActionMessages={setSuccessActionMessages}
        adminInfo={attributes}
      />
      {attributes.provider === AuthProvider.Email && (
        <>
          <ChangePassword handleServerError={handleServerError} setSuccessActionMessages={setSuccessActionMessages} />
          <TFASwitch
            disabledSwitch={checkIsSystemAdmin()}
            handleServerError={handleServerError}
            adminId={attributes.id}
            setSuccessActionMessages={setSuccessActionMessages}
          />
        </>
      )}
      <SettingsForm>
        <CardComponent title="ACCOUNT ACCESS" xs={9}>
          <ItemWithData title="Validated login" data={attributes.email} />
          <ItemWithData title="SSO Type" data={formatSSOProviderType(attributes.provider)} />
        </CardComponent>
      </SettingsForm>
      <ClientInfo admin={attributes} />
    </>
  );
}

ProfileAdmins.propTypes = {
  ...withSuccessActionsDialogProps,
};

export default withSuccessActionsDialog(ProfileAdmins);
