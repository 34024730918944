import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function AddToGalleryIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="-1 -1 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 4.66V2.918H14V1.75h-1.75V0h-1.167v1.75h-1.75c.006.006 0 1.167 0 1.167h1.75V4.66c.006.006 1.167 0 1.167 0zM10.5 3.5v1.75h1.75v4.667a1.17 1.17 0 0 1-1.167 1.166h-7a1.17 1.17 0 0 1-1.166-1.166v-7A1.17 1.17 0 0 1 4.083 1.75H8.75V3.5h1.75zM5.833 7.583l-1.75 2.334h7L8.75 7 7 9.333l-1.167-1.75zM.583 12.25V4.083H1.75v8.167h8.167v1.167H1.75A1.17 1.17 0 0 1 .583 12.25z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
