import { Divider as MuiDivider, Grid as MuiGrid, Typography as MuiTypography, styled } from '@material-ui/core';

export const Divider = styled(MuiDivider)({
  margin: '28px 0 24px',
});

export const GridTitleWrapper = styled(MuiGrid)({
  display: 'inline-flex',
  flexDirection: 'column',
});

export const GridChildrenWrapper = styled(MuiGrid)({
  display: 'inline-flex',
  alignItems: 'flex-end',
});

export const Typography = styled(MuiTypography)({
  marginTop: 'auto',
  marginBottom: '0',
});
