import { Box as MuiBox, Typography as MuiTypography, styled } from '@material-ui/core';
import { InvertColors } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = styled(MuiBox)({
  display: 'flex',
  alignContent: 'center',
});

export const Typography = styled(MuiTypography)({
  alignSelf: 'center',
});

export const PreviewBox = styled(MuiBox)({
  '&.primary': {
    height: '164px',
    position: 'relative',
    padding: '34px 20px',
    marginTop: '28px',
  },
  '&.secondary': {
    height: '132px',
    width: '318px',
    maxWidth: '50%',
    position: 'absolute',
    top: '14px',
    right: 0,
    padding: '22px 20px',
  },
  borderRadius: '4px',
});

export const TitleBox = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  color: COLORS.white,
});

export const InvertColorsIcon = styled(InvertColors)({
  height: ICON_SIZE.regular,
  width: ICON_SIZE.regular,
  marginRight: '6px',
});
