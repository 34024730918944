import React, { useState, memo, useEffect } from 'react';

import { pick, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, SettingsForm } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { useSubmittingState } from 'hooks';
import { adminsModel } from 'models';

function PersonalInfoComponent({ adminInfo, handleServerError, setSuccessActionMessages }) {
  const {
    currentUser: { attributes },
  } = useAuthState();
  const { updateUserData } = useAuthActions();
  const [admin, setAdmin] = useState({});
  const [initialAdmin, setInitialAdmin] = useState({});

  useEffect(() => {
    setAdmin(adminInfo);
    setInitialAdmin(adminInfo);
  }, [adminInfo]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      const data = await adminsModel.editAdmin(pick(admin, ['firstName', 'lastName', 'id']));

      if (data.admin.id === attributes.id) {
        updateUserData(pick(data.admin, ['firstName', 'lastName']));
      }
      setSuccessActionMessages(data.success);
      setInitialAdmin(data.admin);
      setAdmin(data.admin);
    } catch (err) {
      handleServerError(err);
    }
  });

  const handleChange = item =>
    setAdmin(prevState => ({
      ...prevState,
      ...item,
    }));

  const getIsSaveButtonVisible = () => !isSubmitting && !isEqual(admin, initialAdmin);

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent title="PERSONAL INFO" xs={9} isLoading={isSubmitting}>
        <TextValidatorComponent
          validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
          label="First name"
          value={admin.firstName}
          fieldName="firstName"
          handleChange={handleChange}
          autoFocus
          disabled={adminInfo.isOktaUser}
        />
        <TextValidatorComponent
          validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
          label="Last name"
          value={admin.lastName}
          fieldName="lastName"
          handleChange={handleChange}
          disabled={adminInfo.isOktaUser}
        />
      </CardComponent>
    </SettingsForm>
  );
}

PersonalInfoComponent.propTypes = {
  adminInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};

export const PersonalInfo = memo(PersonalInfoComponent);
