import React from 'react';

import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Box } from './style';

export function ColoredBox({ variant, text, children }) {
  return (
    <Box className={variant} key={`${variant}-${text}`}>
      {children}
      {capitalize(text)}
    </Box>
  );
}

ColoredBox.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]),
};

ColoredBox.defaultProps = {
  variant: '',
  text: '',
  children: null,
};
