import { Box, styled, Typography as MuiTypography } from '@material-ui/core';

export const Typography = styled(MuiTypography)({
  marginBottom: '8px',
  '&.MuiTypography-h2': {
    marginTop: '24px',
  }
});

export const TextFieldWrapper = styled(Box)({
  flexGrow: '1',
});

export const TextComponentWrapper = styled(Box)({
  marginBottom: '-28px',
});

export const HeaderHelpText = styled('span')({
  textTransform: 'initial',
  fontWeight: 'normal',
});
