import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@material-ui/core';

import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { ButtonComponent } from 'components';
import { adminsModel } from 'models';
import { ConfirmationDialog } from 'components/Dialogs';

export default function DeleteSpace() {
  const { t } = useTranslation();

  const { currentUser } = useAuthState();
  const { logOut } = useAuthActions();
  const { clientName } = currentUser.attributes;

  const [inputName, setInputName] = useState('');

  const [confirmation, setConfirmation] = useState(false);

  const onSubmit = () => {
    if (inputName === clientName) adminsModel.deleteOwnedClient().then(logOut);
  };

  return (
    <>
      <Box mb={4}>
        <Typography style={{ whiteSpace: 'pre-line' }} variant="h6">
          {t('pages.owners.manageSpace.delete.warning')}
        </Typography>
      </Box>
      <Box>
        <ButtonComponent
          text={t('pages.owners.manageSpace.delete.button')}
          onClick={() => setConfirmation(true)}
          color="primary"
        />
      </Box>
      {confirmation && (
        <ConfirmationDialog
          open
          title={t('pages.owners.manageSpace.delete.title')}
          message={t('pages.owners.manageSpace.delete.message')}
          confirmText={t('pages.owners.manageSpace.delete.button')}
          handleYes={() => onSubmit()}
          handleNo={() => setConfirmation(false)}
        >
          <Box mt={4}>
            <TextField
              variant="standard"
              label={t('pages.owners.manageSpace.delete.hint')}
              value={inputName}
              fullWidth
              onChange={e => setInputName(e.target.value)}
            />
          </Box>
        </ConfirmationDialog>
      )}
    </>
  );
}
