import React, { createContext, PropsWithChildren, useState, useRef } from 'react';

import { DEFAULT_FUNC } from 'constants/technical';
import { generateContextHook } from 'utils/helpers/contextHelpers';

export interface BusyProgressDispatchType {
  setBusyState: (isBusy: boolean) => void;
  withPageProgressHandler: <T extends any[]>(fn: (...args: T) => Promise<void>) => (...args: T) => Promise<void>;
}

const initialState = { isBusy: false };

export const BusyProgressStateContext = createContext<Record<'isBusy', boolean>>(initialState);
export const BusyProgressDispatchContext = createContext<BusyProgressDispatchType>({
  setBusyState: DEFAULT_FUNC,
  withPageProgressHandler: () => () => Promise.resolve(),
});

const BusyProgressProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, setState] = useState<Record<'isBusy', boolean>>(initialState);
  const requests = useRef(0);

  const dispatchValue: BusyProgressDispatchType = {
    setBusyState: isBusy => setState({ isBusy }),
    withPageProgressHandler:
      fn =>
      (...args) => {
        requests.current++;
        dispatchValue.setBusyState(true);
        return fn(...args).finally(() => {
          requests.current--;
          if (requests.current === 0) {
            dispatchValue.setBusyState(false);
          }
        });
      },
  };

  return (
    <BusyProgressStateContext.Provider value={state}>
      <BusyProgressDispatchContext.Provider value={dispatchValue}>{children}</BusyProgressDispatchContext.Provider>
    </BusyProgressStateContext.Provider>
  );
};

const useBusyProgressState = generateContextHook(BusyProgressStateContext, 'BusyProgress');
const useBusyProgressActions = generateContextHook(BusyProgressDispatchContext, 'BusyProgress');

export { BusyProgressProvider, useBusyProgressState, useBusyProgressActions };
