import React from 'react';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';

import { moveItem } from 'utils';

export function SortableListWrapper({ list, setList, children, ...rest }) {
  const handleSetList = newList => (!isEqual(list, newList) ? setList(newList) : null);

  // TODO: remove onEnd and set handleSetList as setList when react-sortable.js issue will be fixed
  //  and lib will be updated
  //  should be fixed in https://github.com/SortableJS/react-sortablejs/issues/175

  const onEnd = ({ oldIndex, newIndex }) =>
    oldIndex === undefined || newIndex === undefined ? null : handleSetList(moveItem(list, oldIndex, newIndex));

  return (
    <ReactSortable list={list} filter={rest.filter} setList={() => {}} onEnd={onEnd} {...rest}>
      {children}
    </ReactSortable>
  );
}

SortableListWrapper.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  filter: PropTypes.string,
  setList: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]).isRequired,
};

SortableListWrapper.defaultProps = {
  list: [],
};
