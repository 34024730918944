import { Typography as MuiTypography, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Subtext = styled(MuiTypography)({
  paddingTop: '10px',
  fontSize: '14px',
  color: COLORS.cozmosPurple2,
  whiteSpace: 'pre-line',
});

export const DisclaimerText = styled(MuiTypography)({
  fontSize: '12px',
  color: COLORS.cozmosPurple2,
});
