import React, { createContext, PropsWithChildren, useEffect, useReducer } from 'react';

import { planogramsModel } from 'models';
import { generateContextHook } from 'utils/helpers/contextHelpers';
import { REQUEST_TIMEOUT_MS } from 'hooks/useTimeoutUpdate';
import { DEFAULT_FUNC } from 'constants/technical';
import { PlanogramCloningStatus } from 'types';

import { useServerErrorActions } from '../ServerErrorContext';

import { PlanogramCloningState, PlanogramCloningTypes } from './types';
import planogramCloningReducer from './reducer';

const PlanogramDispatchCloningContext = createContext<any>({
  storePlanogramId: DEFAULT_FUNC,
  storePlanogramStatus: DEFAULT_FUNC,
  clearState: DEFAULT_FUNC,
});

const initialState: PlanogramCloningState = {
  id: null,
  planogramDestinationId: undefined,
  planogramDestinationGroupId: undefined,
  sourceGroupId: undefined,
  status: null,
};

const PlanogramCloningContext = createContext<PlanogramCloningState>(initialState);

const PlanogramCloningProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const [state, dispatch] = useReducer(planogramCloningReducer, initialState);
  const { handleServerError } = useServerErrorActions();

  const getPlanogramCloningStatus = (): Promise<void> => {
    if (state.id) {
      return planogramsModel
        .getPlanogram(state.id)
        .then(({plStatus}) => dispatchValue.storePlanogramStatus(plStatus))
        .catch(error => handleServerError(error));
    }

    return Promise.resolve();
  };

  const dispatchValue: any = {
    storePlanogramId: (id: number) => dispatch(
      { type: PlanogramCloningTypes.STORE_PLANOGRAM_ID, payload: id }
    ),
    storePlanogramStatus: (status: PlanogramCloningStatus) => dispatch(
      { type: PlanogramCloningTypes.STORE_PLANOGRAM_STATUS, payload: { status }}
    ),
    clearState: () => dispatch(
      { type: PlanogramCloningTypes.CLEAR_PLANOGRAM_STATE, payload: initialState }
    )
  }

  useEffect(() => {
    const interval = setInterval(() => getPlanogramCloningStatus(), REQUEST_TIMEOUT_MS);

    if (state.status === PlanogramCloningStatus.SUCCESS || state.status === PlanogramCloningStatus.ERROR) {
      dispatchValue.clearState();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [state]);

  return (
    <PlanogramCloningContext.Provider value={state}>
      <PlanogramDispatchCloningContext.Provider value={dispatchValue}>
        {children}
      </PlanogramDispatchCloningContext.Provider>
    </PlanogramCloningContext.Provider>
  );
}

const usePlanogramCloningState = generateContextHook(PlanogramCloningContext, 'PlanogramCloning');
const usePlanogramCloningActions = generateContextHook(PlanogramDispatchCloningContext, 'PlanogramCloning');

export { PlanogramCloningProvider, usePlanogramCloningState, usePlanogramCloningActions };
