import { styled } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const DoneIcon = styled(Done)({
  color: COLORS.greenDeep,
  fontSize: ICON_SIZE.regular,
  marginRight: '4px',
});
