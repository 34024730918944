import React, { useState, useEffect } from 'react';

import { Grid, MenuItem, Typography } from '@material-ui/core';
import { set, head, isArray } from 'lodash';
import PropTypes from 'prop-types';

import {
  ButtonComponent,
  CardComponent,
  ColorPicker,
  FontSettingsControlWrapper,
  IconWithConfirmation,
  LoadFileComponent,
  TextFieldComponent,
} from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { FontSettingsPropTypes } from 'components/Dialogs/FontSettingsEditDialog/constants';
import { MultilanguageTextValidator } from 'components/FormComponents';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { activeStorageModel } from 'models';
import { getBlobUrl, getTWithPathKey, handleGetTextValue } from 'utils';

import { BUTTON_TYPE, BUTTON_VIEW_PARAMS } from '../constants';

import { StyledGrid, Box } from '../style';
import { Switch } from 'components/style';
import { Checkbox } from './style';

export function ButtonSettings({
  button,
  disabled,
  setSettings,
  handleServerError,
  fieldsList,
  countriesList,
  checkIsCustomButton,
  checkHasCountries,
  children,
  withNav,
  pageLanguageTabValue,
}) {
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});
  const getT = getTWithPathKey('pages.settings.purchasingFlowSettings.buttonSettings');

  useEffect(() => {
    if (checkIsCustomButton(button) && button.useBorder !== Boolean(button.borderColor.hex)) {
      handleSetField('useBorder')(Boolean(button.borderColor.hex));
    }
  }, [button.borderColor]);

  const handleChangeEnabled = ({ target: { checked } }) => setSettings({ ...button, enabled: checked });

  const handleSetField = field => value => setSettings({ ...button, [field]: value });
  const handleSetTitle = title => setSettings({ ...button, ...title });
  const handleSetButtonCountries = buttonCountries => setSettings({ ...button, buttonCountries });
  const handleSetWidth = ({ target: { value } }) => {
    const { minWidth, maxWidth } = BUTTON_VIEW_PARAMS;

    if (value) {
      handleSetField('borderWidth')(Math.max(minWidth, Math.min(maxWidth, value)));
    } else {
      handleSetField('borderWidth')(value);
    }
  };

  const handleCheckedColorChange = ({ target: { value } }) =>
    button[value].hex ? handleSetField(value)({ hex: '' }) : handleSetField(value)(BUTTON_VIEW_PARAMS.defaultHexColor);

  const handleFontSettingsSave = settings => {
    setFontSettingsEdit(null);
    handleSetField('buttonFont')(settings);
  };

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsEdit = settings => () => setFontSettingsEdit(settings || { buttonId: button.id });

  const renderNav = () =>
    withNav ? <Switch disabled={disabled} checked={button.enabled} onChange={handleChangeEnabled} /> : null;

  const handleSetLogoFile = file => {
    if (file) {
      setIsLoadingLogo(true);
      activeStorageModel.createUpload(file).create((error, blob) => {
        setIsLoadingLogo(false);
        if (error) {
          handleServerError(error);
        } else {
          setSettings({
            ...button,
            iconUrl: getBlobUrl(blob.key),
            signedId: blob.signed_id,
            removeImage: false,
          });
        }
      });
    } else {
      setSettings({ ...button, iconUrl: '', removeImage: true });
    }
  };

  const handleWebsiteFieldChange =
    index =>
    field =>
    ({ target: { value } }) =>
      handleSetButtonCountries([...set(button.buttonCountries, [index, field], value)]);

  const handleFieldInUrlChange = ({ target: { value } }) =>
    handleSetButtonCountries(button.buttonCountries.map(c => ({ ...c, fieldInUrl: value })));

  const handleAddWebsite = () =>
    handleSetButtonCountries([...button.buttonCountries, { fieldInUrl: getFirstFieldInUrl() }]);

  const handleRemoveWebsite = website => handleSetButtonCountries(button.buttonCountries.filter(w => w !== website));

  const getDefaultCountry = () => countriesList.find(c => c.code === 'DEFAULT');

  const getFirstFieldInUrl = () => head(button.buttonCountries).fieldInUrl;

  const getAvailableCountries = website => {
    const usedIds = button.buttonCountries.filter(w => w.countryId !== website.countryId).map(c => c.countryId);

    return countriesList.filter(c => !usedIds.includes(c.id) && c.id !== getDefaultCountry().id);
  };

  const isDefaultWebsite = website => website.countryId === getDefaultCountry().id;

  const canAddNewWebsite = () => button.buttonCountries.every(w => w.countryId && w.websiteUrl);

  return (
    <CardComponent title={button.buttonType} sm={12} alignItems="center" CardHeaderNav={renderNav}>
      {button.enabled && (
        <Grid container direction="row" alignItems="center">
          {checkIsCustomButton(button) && (
            <>
              <Typography variant="h6">{getT('customText')}</Typography>
              <Box className="title-container">
                <Typography variant="h4">Text</Typography>
              </Box>
              <Grid container>
                <FontSettingsControlWrapper
                  wrapperClassName="mb-6 mt-auto"
                  fontSettings={button.buttonFont}
                  handleSettingsEdit={handleFontSettingsEdit(button.buttonFont)}
                  multiLangValue={button.title}
                >
                  <Grid item sm={6}>
                    <MultilanguageTextValidator
                      fieldName="title"
                      label="Text"
                      value={button.title}
                      handleChange={handleSetTitle}
                      multilanguage={isArray(button.title)}
                      handleGetValue={handleGetTextValue('title')}
                      pageLanguageTabValue={pageLanguageTabValue}
                    />
                  </Grid>
                </FontSettingsControlWrapper>
              </Grid>
              <Box className="title-container">
                <Typography variant="h4">Background color</Typography>
              </Box>
              <Checkbox
                color="primary"
                value="color"
                checked={Boolean(button.color.hex)}
                onChange={handleCheckedColorChange}
              />
              <ColorPicker setColor={handleSetField('color')} color={button.color} />
              <Box className="title-container">
                <Typography variant="h4">Border</Typography>
              </Box>
              <Checkbox
                color="primary"
                value="borderColor"
                checked={Boolean(button.borderColor.hex)}
                onChange={handleCheckedColorChange}
              />
              <ColorPicker setColor={handleSetField('borderColor')} color={button.borderColor} />
              <TextFieldComponent
                className="ml-4"
                label="Width"
                type="number"
                onChange={handleSetWidth}
                value={button.borderWidth}
                disabled={!button.useBorder}
              />
              <Box className="title-container">
                <Typography variant="h4">Icon</Typography>
              </Box>
              <LoadFileComponent
                accept={ACCEPT_EXTENSIONS.imgIcon}
                text="Import logo"
                file={button.iconUrl}
                loadFile={handleSetLogoFile}
                handleServerError={handleServerError}
                isLoading={isLoadingLogo}
              />
              <FontSettingsEditDialog
                settings={fontSettingsEdit}
                handleClose={closeFontSettingsDialog}
                handleSet={handleFontSettingsSave}
              />
            </>
          )}
          <Box className="title-container">
            <Typography variant="h4">Preview</Typography>
          </Box>
          {children}
          {checkHasCountries(button) && (
            <>
              <Box className="subtitle-container">
                <Typography variant="h4">For substitution to website url</Typography>
              </Box>
              <Grid item sm={11}>
                <Grid container spacing={2} direction="row" alignItems="center">
                  <Grid item sm={6}>
                    <TextFieldComponent
                      fullWidth
                      select
                      label="Url selection"
                      value={getFirstFieldInUrl() || ''}
                      onChange={handleFieldInUrlChange}
                    >
                      {fieldsList.map(field => (
                        <MenuItem key={field.id} value={field.name}>
                          {field.title}
                        </MenuItem>
                      ))}
                    </TextFieldComponent>
                  </Grid>
                </Grid>
              </Grid>

              <Box className="subtitle-container">
                <Typography variant="h4">Set up link redirection</Typography>
              </Box>

              <Grid container spacing={4} direction="row" alignItems="center">
                {button.buttonCountries.map((website, i) => (
                  <React.Fragment key={website.countryId || `new-${i}`}>
                    <Grid item sm={11}>
                      <Grid container spacing={2} direction="row" alignItems="center">
                        <Grid item sm={6}>
                          {isDefaultWebsite(website) ? (
                            <Typography variant="h6">Default Website</Typography>
                          ) : (
                            <TextFieldComponent
                              fullWidth
                              select
                              label="Country"
                              value={website.countryId || ''}
                              onChange={handleWebsiteFieldChange(i)('countryId')}
                            >
                              {getAvailableCountries(website).map(country => (
                                <MenuItem key={country.id} value={country.id}>
                                  {country.name}
                                </MenuItem>
                              ))}
                            </TextFieldComponent>
                          )}
                        </Grid>
                        <Grid item sm={6}>
                          <TextFieldComponent
                            fullWidth
                            label="Enter a complete url with http(s)://"
                            value={website.websiteUrl || ''}
                            onChange={handleWebsiteFieldChange(i)('websiteUrl')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sm={1}>
                      {!isDefaultWebsite(website) && (
                        <IconWithConfirmation message="country" value={website} handleConfirm={handleRemoveWebsite} />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}

                {canAddNewWebsite() && (
                  <StyledGrid item sm={11}>
                    <ButtonComponent text="Add Website" onClick={handleAddWebsite} />
                  </StyledGrid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </CardComponent>
  );
}

ButtonSettings.propTypes = {
  button: PropTypes.shape({
    buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
    enabled: PropTypes.bool,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ hex: PropTypes.string })]),
    borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ hex: PropTypes.string })]),
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    useBorder: PropTypes.bool,
    iconUrl: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({ languageCode: PropTypes.string, value: PropTypes.string })),
    ]),
    buttonCountries: PropTypes.arrayOf(
      PropTypes.shape({
        countryId: PropTypes.number,
        websiteUrl: PropTypes.string,
        fieldInUrl: PropTypes.string,
      }),
    ),
    buttonFont: FontSettingsPropTypes,
    id: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool,
  setSettings: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  countriesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fieldsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkIsCustomButton: PropTypes.func.isRequired,
  checkHasCountries: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]),
  withNav: PropTypes.bool,
  pageLanguageTabValue: PropTypes.number,
};

ButtonSettings.defaultProps = {
  disabled: false,
  children: null,
  withNav: true,
  pageLanguageTabValue: 0,
};
