import { api } from './setupApi';

class ProductVariantsModel {
  link = '/product_variants';

  updatePicture = (variantId: number, pictureId: number) =>
    api
      .post(`${this.link}/${variantId}/update_picture`, {
        picture_variant: { product_picture_id: pictureId },
      })
      .then(({ data }) => data);

  removePicture = (variantId: number) => api.post(`${this.link}/${variantId}/remove_picture`).then(({ data }) => data);
}

export const productVariantsModel = new ProductVariantsModel();
