import React from 'react';

import { TextField } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { withLocalization } from 'utils/enhancers';

import { FieldValidator } from '../FieldValidator/FieldValidator';
import { withFormValidation } from '../withFormValidation/withFormValidation';

import { Autocomplete } from './style';
import './style.css';

export function AutocompleteComponent({
  value,
  validators,
  fieldName,
  selectList,
  inputClassName,
  label,
  itemsNamePath,
  serverError,
  onChange,
  errorMessages,
  disabled,
  disableClearable,
  disablePortal,
  disableMinHeight,
  ...props
}) {
  const handleChange = (event, value) =>
    onChange({
      target: {
        value: value === null ? value : selectList.find(item => item[itemsNamePath] === value[itemsNamePath])?.id,
      },
    });
  const getValue = () => selectList?.find(({ id }) => id === value) || '';
  const getOptionLabel = option => option[itemsNamePath] || '';
  const renderInput = params => <TextField {...params} label={label} variant="outlined" margin="dense" fullWidth />;

  return (
    <FieldValidator
      disableMinHeight={disableMinHeight}
      validators={validators}
      errorMessages={errorMessages}
      serverError={serverError}
      value={getValue()}
    >
      <Autocomplete
        value={getValue()}
        onChange={handleChange}
        disabled={disabled}
        options={selectList.map(option => ({ [itemsNamePath]: option[itemsNamePath] }))}
        id={fieldName}
        size="small"
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        className={inputClassName}
        disableClearable={disableClearable}
        disablePortal={disablePortal}
        popupIcon={<KeyboardArrowDown />}
        {...props}
      />
    </FieldValidator>
  );
}

AutocompleteComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  serverError: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemsNamePath: PropTypes.string.isRequired,
  selectList: PropTypes.arrayOf(PropTypes.shape({})),
  disableClearable: PropTypes.bool,
  disablePortal: PropTypes.bool,
  disableMinHeight: PropTypes.bool,
};

AutocompleteComponent.defaultProps = {
  value: '',
  selectList: [],
  label: undefined,
  disabled: false,
  disableClearable: false,
  disablePortal: false,
  disableMinHeight: false,
};

export const AutocompleteValidatorComponent = withFormValidation(AutocompleteComponent);
export const MultilanguageAutocompleteValidator = withLocalization(withFormValidation(AutocompleteComponent));
