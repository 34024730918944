import { Typography as MuiTypography, Grid as MuiGrid, Box as MuiBox, withStyles } from '@material-ui/core';

export const Typography = withStyles(theme => ({
  root: {
    padding: '8px 16px 0 0',
    '&.name': { marginRight: 'auto', fontWeight: '700' },
    '&.accessibility-hint-text': { fontWeight: '600' },
    '&.text-wrapper': { alignSelf: 'center', marginRight: 'auto' },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': { textDecoration: 'underline' },
    },
    whiteSpace: 'pre-line',
  },
}))(MuiTypography);

export const Grid = withStyles({
  root: {
    marginTop: '10px',
    '& .right': { marginLeft: 'auto' },
  },
})(MuiGrid);

export const Box = withStyles(theme => ({
  root: {
    background: 'white',
    textAlign: 'left',
  },
}))(MuiBox);
