const arrayToQueryStrings = (arr: string[]) => arr.join(',');

/**
 *  Function to create query for url
 *
 *  @param
 *  url {String}
 *  queries {Object}
 *
 *  @return url {String}
 * */
export const buildUrlWithQuery = (url: string, queries: { [key: string]: string[] | string }) =>
  `${url}?${Object.keys(queries)
    .map(k =>
      Array.isArray(queries[k]) ? `${k}=${arrayToQueryStrings(queries[k] as string[])}` : `${k}=${queries[k]}`,
    )
    .join('&')}`;
