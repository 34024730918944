import { Typography as MuiTypography, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Subtext = styled(MuiTypography)({
  paddingTop: '12px',
  fontSize: '14px',
  color: COLORS.cozmosPurple2,
  whiteSpace: 'pre-line',
});

export const DisclaimerText = styled(MuiTypography)({
  fontSize: '12px',
  color: COLORS.cozmosPurple2,
});

export const UserName = styled(MuiTypography)({
  fontSize: '16px',
  marginBottom: '5px',
  lineHeight: '1.2',
  color: COLORS.cozmosPurple4,
});

export const ProfileName = styled(MuiTypography)({
  fontSize: '12px',
  lineHeight: '1.2',
  color: COLORS.cozmosPurple4,
});
