import React from 'react';

import { Box, DialogContentText } from '@material-ui/core';

import { GuideList } from 'components';
import { GuideDialog } from 'components/Dialogs';
import { getTWithPathKey } from 'utils';

import { Typography, List } from './style';

import OktaStep1A from 'assets/img/okta-guide/step1a.jpg';
import OktaStep1B from 'assets/img/okta-guide/step1b.jpg';
import OktaStep3A from 'assets/img/okta-guide/step3a.jpg';
import OktaStep3B from 'assets/img/okta-guide/step3b.jpg';
import OktaStep3C from 'assets/img/okta-guide/step3c.jpg';
import OktaStep4A from 'assets/img/okta-guide/step4a.jpg';
import OktaStep4B from 'assets/img/okta-guide/step4b.jpg';
import OktaStep4C from 'assets/img/okta-guide/step4c.jpg';
import OktaStep4D from 'assets/img/okta-guide/step4d.jpg';
import OktaStep5A from 'assets/img/okta-guide/step5a.jpg';
import OktaStep5B from 'assets/img/okta-guide/step5b.jpg';
import OktaStep5C from 'assets/img/okta-guide/step5c.jpg';
import OktaStep5D from 'assets/img/okta-guide/step5d.jpg';
import OktaStep6A from 'assets/img/okta-guide/step6a.jpg';
import OktaStep7A from 'assets/img/okta-guide/step7a.jpg';

export interface SsoGuideProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const OktaGuide = ({ isOpen, handleClose }: SsoGuideProps) => {
  const getT = getTWithPathKey('pages.ssoProvider.okta.guide');

  const handleCloseDialog = () => {
    handleClose();
  };

  const storeIntegrationGuide = [
    {
      title: getT('steps.1.title'),
      children: (
        <>
          <Typography>{getT('steps.1.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep1A} alt={getT('steps.1.img-alt-a')} />
          <Typography>{getT('steps.1.b')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep1B} alt={getT('steps.1.img-alt-b')} />
          <Typography className="black">{getT('steps.1.c')}</Typography>
        </>
      ),
    },
    {
      title: getT('steps.2.title'),
      children: <Typography>{getT('steps.2.caption')}</Typography>,
    },
    {
      title: getT('steps.3.title'),
      children: (
        <>
          <Typography>{getT('steps.3.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep3A} alt={getT('steps.3.img-alt-a')} />
          <Typography>{getT('steps.3.b')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep3B} alt={getT('steps.3.img-alt-b')} />
          <Typography>{getT('steps.3.c')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep3C} alt={getT('steps.3.img-alt-c')} />
        </>
      ),
    },
    {
      title: getT('steps.4.title'),
      children: (
        <>
          <Typography>{getT('steps.4.caption')}</Typography>
          <Typography>{getT('steps.4.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep4A} alt={getT('steps.4.img-alt-a')} />
          <Typography>{getT('steps.4.b')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep4B} alt={getT('steps.4.img-alt-b')} />
          <Typography>{getT('steps.4.c')}</Typography>
          <List>
            <li>
              <Typography className="black">{getT('steps.4.emailField')}</Typography>
            </li>
            <li>
              <Typography className="black">{getT('steps.4.firstNameField')}</Typography>
            </li>
            <li>
              <Typography className="black">{getT('steps.4.lastNameField')}</Typography>
            </li>
          </List>
          <img style={{ width: '100%' }} src={OktaStep4C} alt={getT('steps.4.img-alt-c')} />
          <Typography>{getT('steps.4.d')}</Typography>
          <List>
            <li>
              <Typography className="black">{getT('steps.4.groupsField')}</Typography>
            </li>
          </List>
          <img style={{ width: '100%' }} src={OktaStep4D} alt={getT('steps.4.img-alt-d')} />
          <Typography>{getT('steps.4.e')}</Typography>
        </>
      ),
    },
    {
      title: getT('steps.5.title'),
      children: (
        <>
          <Typography>{getT('steps.5.caption')}</Typography>
          <Typography>{getT('steps.5.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep5A} alt={getT('steps.5.img-alt-a')} />
          <Typography>{getT('steps.5.b')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep5B} alt={getT('steps.5.img-alt-b')} />
          <Typography>{getT('steps.5.c')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep5C} alt={getT('steps.5.img-alt-c')} />
          <Typography>{getT('steps.5.d')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep5D} alt={getT('steps.5.img-alt-d')} />
          <Typography>{getT('steps.5.e')}</Typography>
        </>
      ),
    },
    {
      title: getT('steps.6.title'),
      children: (
        <>
          <Typography>{getT('steps.6.caption')}</Typography>
          <Typography>{getT('steps.6.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep6A} alt={getT('steps.6.img-alt-a')} />
          <Typography>{getT('steps.6.b')}</Typography>
          <Typography>{getT('steps.6.c')}</Typography>
        </>
      ),
    },
    {
      title: getT('steps.7.title'),
      children: (
        <>
          <Typography>{getT('steps.7.a')}</Typography>
          <img style={{ width: '100%' }} src={OktaStep7A} alt={getT('steps.7.img-alt-a')} />
        </>
      ),
    },
    {
      title: getT('steps.8.title'),
      children: <Typography>{getT('steps.8.caption')}</Typography>,
    },
  ];

  return (
    <GuideDialog isOpen={isOpen} handleClose={handleCloseDialog} title={getT('dialogTitle')} disableTransition>
      <Box mb={2}>
        <Typography className="uppercase" variant="h2">
          {getT('guideTitle')}
        </Typography>
      </Box>
      <Box mb={5}>
        <DialogContentText>{getT('guideDescription')}</DialogContentText>
      </Box>
      <GuideList list={storeIntegrationGuide} />
    </GuideDialog>
  );
};
