import { Box, Button } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { COLORS } from 'theme/constants';

export const StyledButtonComponent = styled(ButtonComponent)({
  marginTop: '12px',
  marginLeft: '28px',
  width: '102px',
  height: '27px',
});

export const DeleteButton = styled(Delete)({
  cursor: 'pointer',
  height: '20px',
  color: COLORS.greyText,
});

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 14,
});

export const RootDomainBadge = withStyles({
  root: {
    ' &.MuiButton-root': {
      fontSize: '12px',
      borderRadius: '6px',
      padding: '6px 12px',
      backgroundColor: COLORS.cozmosRedLight,
      color: COLORS.cozmosRed,
      height: '27px',
    },
  },
})(Button);
