import React, { memo } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { BuyButtonPreview } from 'components/BuyButtonPreview/BuyButtonPreview';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from 'components/propTypesConsts';
import { getInlineFontStyles, getTWithPathKey } from 'utils';

import { BUTTON_TYPES, BUTTON_SHAPES, FONT_TYPES } from '../constants';

import { Box, CloseIcon } from '../style';

function ProductOverlayModePreviewComponent({ settings, lang, currentLanguageCode, disabled }) {
  const getT = getTWithPathKey('components.ecommerce.shoppingCart');

  const getButtonForPreview = button => ({
    ...button,
    title: button.title[lang].value,
    color: disabled ? button.colorDisabled : button.color,
    borderColor: disabled ? button.borderColorDisabled : button.borderColor,
    borderWidth: disabled ? button.borderWidthDisabled : button.borderWidth,
    useBorder: disabled ? button.useBorderDisabled : button.useBorder,
  });

  const getButtonByType = type => settings.ecommerceButtons.find(({ buttonType }) => type === buttonType);

  const checkoutBtn = getButtonForPreview(getButtonByType(BUTTON_TYPES.checkout));
  const controlsColor = settings?.ecommerceCustomColors.find(({ assignment }) => assignment === 'controls').color.hex;
  const getFontSettings = name => settings?.ecommerceFonts.find(({ assignment }) => assignment === name);

  const getFontStyles = name => getInlineFontStyles(getFontSettings(name));

  return (
    <Box className="table-wrapper" p={6} style={{ backgroundColor: settings.backgroundColor.hex }}>
      <Grid container justify="space-between" wrap="nowrap" alignItems="center">
        <CustomFontWrapper
          font={getFontSettings(FONT_TYPES.header).font}
          style={{
            ...getFontStyles(FONT_TYPES.header),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {getT('name', { lng: currentLanguageCode })}
        </CustomFontWrapper>
        <Box className="btn-wrap regular" style={{ color: controlsColor }}>
          <Box className="overlay-button overlay-button-close">
            <span />
          </Box>
        </Box>
      </Grid>
      <Box className="shopping-cart-product" pt={11}>
        {disabled ? (
          <Box mt={15.5} align="center" flex="auto">
            <CustomFontWrapper
              font={getFontSettings(FONT_TYPES.cartIsEmpty).font}
              style={getFontStyles(FONT_TYPES.cartIsEmpty)}
            >
              {getT('cartIsEmpty', { lng: currentLanguageCode })}
            </CustomFontWrapper>
          </Box>
        ) : (
          <>
            <Box className="img-wrap" mr={4}>
              <Typography className="information" align="center">
                {getT('productImg', { lng: currentLanguageCode })}
              </Typography>
            </Box>
            <Box className="product-wrap">
              <Grid container justify="space-between" wrap="nowrap">
                <Box>
                  <CustomFontWrapper
                    font={getFontSettings(FONT_TYPES.productName).font}
                    style={getFontStyles(FONT_TYPES.productName)}
                  >
                    {getT('productName', { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </Box>
                <Box>
                  <CloseIcon style={{ color: controlsColor }} />
                </Box>
              </Grid>
              <Grid container>
                <Box mb={2}>
                  <CustomFontWrapper
                    font={getFontSettings(FONT_TYPES.options).font}
                    style={getFontStyles(FONT_TYPES.options)}
                  >
                    <Box mr={2} display="inline">
                      {getT('color', { lng: currentLanguageCode })}
                    </Box>
                    {getT('size', { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                </Box>
              </Grid>
              <Grid container justify="space-between" wrap="nowrap" alignItems="center">
                <Box display="flex">
                  <Box className="btn-wrap small" mr={5} style={{ color: controlsColor }}>
                    <Box className="overlay-button overlay-button-minus">
                      <span />
                    </Box>
                  </Box>
                  <CustomFontWrapper
                    font={getFontSettings(FONT_TYPES.stepper).font}
                    style={{
                      ...getFontStyles(FONT_TYPES.stepper),
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {getT('count', { lng: currentLanguageCode })}
                  </CustomFontWrapper>
                  <Box className="btn-wrap small" ml={5} style={{ color: controlsColor }}>
                    <Box className="overlay-button overlay-button-plus">
                      <span />
                    </Box>
                  </Box>
                </Box>
                <CustomFontWrapper
                  font={getFontSettings(FONT_TYPES.price).font}
                  style={getFontStyles(FONT_TYPES.price)}
                >
                  {getT('price', { lng: currentLanguageCode })}
                </CustomFontWrapper>
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Box mb={4}>
        <Divider style={{ borderBottom: `1px solid ${controlsColor}` }} />
      </Box>
      <Grid container justify="space-between" wrap="nowrap">
        <CustomFontWrapper
          font={getFontSettings(FONT_TYPES.cartSubtotal).font}
          style={getFontStyles(FONT_TYPES.cartSubtotal)}
        >
          {getT('cartSubtotal', { lng: currentLanguageCode })}
        </CustomFontWrapper>
        <CustomFontWrapper
          font={getFontSettings(FONT_TYPES.subtotalPrice).font}
          style={getFontStyles(FONT_TYPES.subtotalPrice)}
        >
          {getT('subtotalPrice', { lng: currentLanguageCode })}
        </CustomFontWrapper>
      </Grid>
      <CustomFontWrapper font={getFontSettings(FONT_TYPES.button).font} style={getFontStyles(FONT_TYPES.button)}>
        <Box className={`${settings.buttonShape} checkout`}>
          <BuyButtonPreview key={checkoutBtn.id} button={checkoutBtn} isCustomButton />
        </Box>
      </CustomFontWrapper>
    </Box>
  );
}

ProductOverlayModePreviewComponent.propTypes = {
  settings: PropTypes.shape({
    ecommerceFonts: PropTypes.arrayOf(fontSettingsProp),
    ecommerceButtons: PropTypes.arrayOf(PropTypes.shape({})),
    buttonShape: PropTypes.oneOf(Object.values(BUTTON_SHAPES)),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }).isRequired,
    ecommerceCustomColors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  disabled: PropTypes.bool,
  lang: PropTypes.number.isRequired,
  currentLanguageCode: PropTypes.string.isRequired,
};

ProductOverlayModePreviewComponent.defaultProps = {
  disabled: false,
};

export const ShoppingCartModePreview = memo(ProductOverlayModePreviewComponent);
