import { PicturesGetParams } from 'types/images';
import { SocialContentParams, SocialContent, SocialContentToken } from 'types';

import { api } from './setupApi';
import { videosModel } from './VideosModel';

class SocialIntegrationModel {
  link = '/social_medias';

  instagramLink = '/instagram';

  updateLink = '/client_social_medias';

  instagramTokenLink = '/exchange_instagram_token';

  synchronizationLink = '/start_synchronization';

  instagramDisconnectLink = '/instagram_disconnect';

  pictures = '/pictures/search';

  getSocialProviders = (): Promise<SocialContent[]> => api.get(this.link).then(({ data }) => data);

  getSocialProvider = (id: number | string): Promise<SocialContent> =>
    api.get(`${this.link}/${id}`).then(({ data }) => data);

  updateSocialProvider = ({ socialMediaId, id, ...data }: Partial<SocialContentParams>):
    Promise<SocialContentParams> =>
    api.put(`${this.link}/${socialMediaId}${this.updateLink}/${id}`, data)
      .then(({ data }) => data)

  authCodeForToken = (id: number | string, code: string): Promise<SocialContentToken | any> =>
    api.post(`${this.link}/${id}${this.instagramLink}${this.instagramTokenLink}`, { code });

  startSynchronization = (id: number | string) =>
    api.get(`${this.link}/${id}${this.instagramLink}${this.synchronizationLink}`);

  startInstagramDisconnect = (id: number | string, clientName: string) =>
    api.post(`${this.link}/${id}${this.instagramLink}${this.instagramDisconnectLink}`, {
      spaceName: clientName,
    });

  getPosts = (params: PicturesGetParams): Promise<any> =>
    api.get(`${this.pictures}`, { params }).then(({ data }) => data);

  // eslint-disable-next-line class-methods-use-this
  getVideos = (params: PicturesGetParams) => videosModel.getVideos(params);
}

export const socialIntegrationModel = new SocialIntegrationModel();
