import React from 'react';

import { isEmpty } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { withFormValidation, withFormValidationPropTypes } from '../withFormValidation/withFormValidation';

import { FormControl } from '../style';
import { TextValidator } from './style';

function DatePickerComponent({
  label,
  value,
  type,
  disabled,
  min,
  max,
  serverError,
  onChange,
  fullWidth,
  fieldName,
  validators,
  errorMessages,
  disableMinHeight,
  ...props
}) {
  return (
    <FormControl fullWidth={fullWidth} className={classnames({
        'no-min-height': disableMinHeight
      })}>
      <TextValidator
        id={fieldName}
        validators={validators}
        errorMessages={errorMessages}
        margin="dense"
        label={label}
        fullWidth={fullWidth}
        variant="outlined"
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        inputProps={{ min, max }}
        type={type}
        error={!isEmpty(serverError)}
        helperText={serverError}
        {...props}
      />
    </FormControl>
  );
}

DatePickerComponent.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  disableMinHeight: PropTypes.bool,
  label: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  ...withFormValidationPropTypes,
};

DatePickerComponent.defaultProps = {
  type: 'datetime-local',
  disableMinHeight: false,
  disabled: false,
  min: '',
  max: '',
  fullWidth: false,
};

export default withFormValidation(DatePickerComponent);
