import React from 'react';

import PropTypes from 'prop-types';

import { ToggleButtonGroup, ToggleButton } from './style';

export function ToggleButtonGroupComponent({ items, groupValues, setGroupValues }) {
  const handleDefaultChange = (event, newValues) => {
    setGroupValues(newValues);
  };

  return (
    <ToggleButtonGroup value={groupValues} onChange={handleDefaultChange}>
      {items.map(item => (
        <ToggleButton key={item.id} value={item.id}>
          {item.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

ToggleButtonGroupComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  groupValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  setGroupValues: PropTypes.func.isRequired,
};

ToggleButtonGroupComponent.defaultProps = {
  items: [],
};
