import { Typography as MuiTypography } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/styles';

export const Typography = withStyles({
  root: {
    '&.uppercase': {
      textTransform: 'uppercase',
    },
    '&.black': {
      color: 'black',
    },
  },
})(MuiTypography);

export const List = styled('ul')({
  color: 'black',
});
