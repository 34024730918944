import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export function TableHeader({ title, subtitle }) {
  return (
    <>
      <Box mb={0.5}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Typography variant="subtitle1" className="information">
        {subtitle}
      </Typography>
    </>
  );
}

TableHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TableHeader.defaultProps = {
  title: '',
  subtitle: '',
};
