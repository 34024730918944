import React, { createContext, useContext, useState } from 'react';

import { ThemeProvider } from '@material-ui/core';

import { darkMode, lightMode } from 'theme';
import { ThemeMode } from 'types/enums';
import { THEME_MODE_KEY } from 'constants/technical';

const initialThemeMode = (localStorage.getItem(THEME_MODE_KEY) as ThemeMode | undefined) ?? ThemeMode.LIGHT;

interface ContextType {
  themeMode: ThemeMode;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ContextType>({
  themeMode: initialThemeMode,
  toggleTheme: () => {},
});

const useThemeMode = () => {
  const { themeMode, toggleTheme } = useContext(ThemeContext);

  return { themeMode, toggleTheme };
};

const CustomThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(initialThemeMode);

  const toggleTheme = () => {
    const newThemeMode = themeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;

    setThemeMode(newThemeMode);
    localStorage.setItem(THEME_MODE_KEY, newThemeMode);
  };

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <ThemeProvider theme={themeMode === ThemeMode.DARK ? darkMode : lightMode}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { useThemeMode, ThemeContext, CustomThemeProvider };
