import { PaginationParams } from 'types/common';
import { UploadedPicture, OtherAssetsResponse } from 'types/images';

import { api } from './setupApi';

class OtherAssetsModel {
  link = '/other_assets';

  getImages = (params: PaginationParams): Promise<OtherAssetsResponse> =>
    api.get<OtherAssetsResponse>(this.link, { params }).then(({ data }) => data);

  getImage = (id: number) => api.get<UploadedPicture>(`${this.link}/${id}`).then(({ data }) => data);

  deleteImage = (id: number) => api.delete(`${this.link}/${id}`);

  postImage = (data: Record<'signedId', string>) => api.post(this.link, data);

  editImage = (id: number, data: UploadedPicture) => api.put(`${this.link}/${id}`, data);
}

export const otherAssetsModel = new OtherAssetsModel();
