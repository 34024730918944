import React, { useMemo, useState } from 'react';

import { Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse/Collapse';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { CustomDomain } from 'types/domains';
import { CustomDomainVerificationStatus } from 'types/enums';

import { STATUS_TEXT_MAP } from '../constants';

import { RecordsTable } from './RecordsTable/RecordsTable';

import {
  ButtonWrapper,
  DeleteButton,
  DomainName,
  TitleWrapper,
  ExpandableDomainCard,
  ExpandButton,
  Disclaimer,
  Description,
  DescriptionBox,
  RecordErrorMessage,
  DomainStatusBadge,
} from '../style';

export interface CollapsableDomainProps {
  domain: CustomDomain;
  onDelete: (id: number) => void;
  onCheckStatus: (id: number) => void;
}

export const CollapsableDomain = ({ domain, onDelete, onCheckStatus }: CollapsableDomainProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const { txtRecords, certRecords, aRecords, status, host, id, errorMessage } = domain;
  const hasRecords = txtRecords || certRecords || aRecords;

  const domainStepText = useMemo(() => {
    switch (true) {
      case txtRecords?.status === CustomDomainVerificationStatus.UNVERIFIED:
        return t('components.customDomains.txtUnverified');
      case certRecords?.status === CustomDomainVerificationStatus.UNVERIFIED:
        return t('components.customDomains.cNameUnverified');
      default:
        return null;
    }
  }, [domain]);

  return (
    <ExpandableDomainCard variant="outlined">
      <Grid container>
        <TitleWrapper>
          <DomainName>{host}</DomainName>
        </TitleWrapper>
        {!expanded && (
          <ButtonWrapper>
            <DomainStatusBadge disabled variant="contained" status={status}>
              {STATUS_TEXT_MAP[status]}
            </DomainStatusBadge>
          </ButtonWrapper>
        )}
        {(hasRecords || errorMessage) && (
          <ExpandButton
            onClick={() => setExpanded(prev => !prev)}
            color="primary"
            variant="text"
            text={expanded ? t('components.customDomains.hideDetails') : t('components.customDomains.showDetails')}
            endIcon={expanded ? <ArrowDropUp /> : <ArrowDropDown />}
            disableRipple
          />
        )}
        <ButtonWrapper>
          <DeleteButton onClick={() => onDelete(id)} />
        </ButtonWrapper>
      </Grid>
      <Collapse in={expanded} timeout="auto">
        <Grid container direction="column" spacing={4} style={{ paddingTop: '20px' }}>
          {hasRecords && <RecordsTable title={t('components.customDomains.dnsRecordTitle')} />}
          {txtRecords && (
            <>
              <RecordsTable
                title={t('components.customDomains.txtRecordsTitle')}
                status={txtRecords.status}
                records={[{ item: 'TXT', value: txtRecords.value }]}
              />
              <Grid item>
                <DescriptionBox>
                  <Description>{t('components.customDomains.txtTip')}</Description>
                </DescriptionBox>
              </Grid>
            </>
          )}
          {certRecords && (
            <RecordsTable
              title={t('components.customDomains.cRecordsTitle')}
              status={certRecords.status}
              records={[
                { item: 'CNAME', value: certRecords.cnameHost },
                { item: 'Value', value: certRecords.cnameValue },
              ]}
              showRecords={certRecords.showRecords}
            />
          )}
          {aRecords && (
            <RecordsTable
              title={t('components.customDomains.aRecordsTitle')}
              status={aRecords.status}
              records={[
                { item: 'A', value: aRecords.aRecords[0] },
                { item: 'A', value: aRecords.aRecords[1] },
              ]}
            />
          )}
          {domainStepText && (
            <Grid item>
              <DescriptionBox>
                <Description>{domainStepText}</Description>
              </DescriptionBox>
            </Grid>
          )}
          <Grid item>{errorMessage && <RecordErrorMessage>{errorMessage}</RecordErrorMessage>}</Grid>
          {status !== CustomDomainVerificationStatus.CONNECTED && (
            <Grid item container justify="flex-end" alignItems="center" spacing={4}>
              <Grid item>
                <Disclaimer>{t('components.customDomains.checkStatusDisclaimer')}</Disclaimer>
              </Grid>
              <Grid item>
                <ButtonComponent
                  variant="contained"
                  color="primary"
                  text={t('components.customDomains.checkStatusButton')}
                  onClick={() => onCheckStatus(id)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </ExpandableDomainCard>
  );
};
