import React, { memo } from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Box } from './style';

function LanguageCodeComponent({ code, className }) {
  return (
    code && (
      <Box className={className}>
        <Typography variant="caption">{code}</Typography>
      </Box>
    )
  );
}
LanguageCodeComponent.propTypes = {
  code: PropTypes.string,
  className: PropTypes.string,
};

LanguageCodeComponent.defaultProps = { code: null, className: '' };

export const LanguageCode = memo(LanguageCodeComponent);
