import { styled, Table as MuiTable } from '@material-ui/core';

export const Table = styled(MuiTable)({
  '& > thead > tr > th': {
    whiteSpace: 'nowrap',
  },
  '& > tbody > tr > td > p.plClone': {
    fontSize: '12px',
    fontWeight: '500'
  }
});
