import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { ConfirmationDialog } from 'components/Dialogs';
import { planogramsModel } from 'models';

import { PLANOGRAM_ACTIONS, PLANOGRAM_VERSION_STATUS } from '../../constants';

import {
  CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION,
  CONFIRMATION_MESSAGE_NEW_VERSION,
  CONFIRM_TEXT,
} from './constants';

export function PlanogramVersionConfirmation({
  planogramId,
  updatePlanogram,
  handleServerError,
  havePublished,
  haveGenerated,
  haveGenerating,
  haveDraft,
  havePublishedDrafted,
  setSuccessActionMessages,
  confirmationParams,
  handleCloseConfirmationDialog,
  handleSetWaitingForUpdate,
}) {
  const handleCloneToDraft = versionId =>
    planogramsModel.cloneToDraftVersion(planogramId, versionId).then(updatePlanogram).catch(handleServerError);

  const handlePublish = versionId =>
    planogramsModel.publishVersion(planogramId, versionId).then(updatePlanogram).catch(handleServerError);

  const handleGenerate = versionId =>
    planogramsModel.generateVersion(planogramId, versionId).then(updatePlanogram).catch(handleServerError);

  const handleStopGenerating = versionId =>
    planogramsModel
      .stopGeneratingVersion(planogramId, versionId)
      .then(({ data: { success = [] } }) => {
        setSuccessActionMessages(success);
        updatePlanogram();
      })
      .catch(handleServerError);

  const getConfirmAction = () => {
    const { action, versionId } = confirmationParams;

    if (action === PLANOGRAM_VERSION_STATUS.drafting) {
      return () => {
        handleCloseConfirmationDialog();
        handleCloneToDraft(versionId);
        handleSetWaitingForUpdate({ [action]: true });
      };
    }

    if (action === PLANOGRAM_VERSION_STATUS.publishing) {
      return () => {
        handleCloseConfirmationDialog();
        handlePublish(versionId);
        handleSetWaitingForUpdate({ [action]: true });
      };
    }
    if (action === PLANOGRAM_VERSION_STATUS.generating) {
      return () => {
        handleCloseConfirmationDialog();
        handleGenerate(versionId);
        handleSetWaitingForUpdate({ [action]: true });
      };
    }
    if (action === PLANOGRAM_VERSION_STATUS.publishedDrafted) {
      return () => {
        handleCloseConfirmationDialog();
        handleCloneToDraft(versionId);
        handleSetWaitingForUpdate({ [action]: true });
      };
    }
    if (action === PLANOGRAM_ACTIONS.stopGenerating) {
      return () => {
        handleCloseConfirmationDialog();
        handleStopGenerating(versionId);
      };
    }

    return handleCloseConfirmationDialog;
  };

  const getConfirmationMessage = () => {
    const { action } = confirmationParams;

    if (action === PLANOGRAM_VERSION_STATUS.drafting) {
      return haveDraft ? CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION[action] : CONFIRMATION_MESSAGE_NEW_VERSION[action];
    }

    if (action === PLANOGRAM_VERSION_STATUS.publishing) {
      return havePublished
        ? CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION[action]
        : CONFIRMATION_MESSAGE_NEW_VERSION[action];
    }
    if (action === PLANOGRAM_VERSION_STATUS.generating) {
      return haveGenerated
        ? CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION[action]
        : CONFIRMATION_MESSAGE_NEW_VERSION[action];
    }
    if (action === PLANOGRAM_VERSION_STATUS.publishedDrafted) {
      return havePublishedDrafted
        ? CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION[action]
        : CONFIRMATION_MESSAGE_NEW_VERSION[action];
    }
    if (action === PLANOGRAM_ACTIONS.stopGenerating) {
      return haveGenerating
        ? CONFIRMATION_MESSAGE_WITH_PREVIOUS_VERSION[action]
        : CONFIRMATION_MESSAGE_NEW_VERSION[action];
    }

    return '';
  };

  return (
    <>
      {confirmationParams.action && (
        <ConfirmationDialog
          open={!isEmpty(confirmationParams.action)}
          message={getConfirmationMessage()}
          handleYes={getConfirmAction()}
          handleNo={handleCloseConfirmationDialog}
          confirmText={CONFIRM_TEXT[confirmationParams.action]}
        />
      )}
    </>
  );
}

PlanogramVersionConfirmation.propTypes = {
  confirmationParams: PropTypes.shape({
    versionId: PropTypes.number,
    action: PropTypes.string,
  }).isRequired,
  updatePlanogram: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
  handleCloseConfirmationDialog: PropTypes.func.isRequired,
  planogramId: PropTypes.number.isRequired,
  havePublished: PropTypes.bool.isRequired,
  haveGenerated: PropTypes.bool.isRequired,
  haveGenerating: PropTypes.bool.isRequired,
  haveDraft: PropTypes.bool.isRequired,
  havePublishedDrafted: PropTypes.bool.isRequired,
  handleSetWaitingForUpdate: PropTypes.func.isRequired,
};
