import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { COLORS } from 'theme/constants';

const useInputStyles = makeStyles({
  root: {
    backgroundColor: COLORS.white,
    padding: '10px 32px',
    textTransform: 'capitalize',
    fontFamily: 'inherit',
  },
});

const OverlayButtonPreview = ({ style, value, currentLanguageCode }) => {
  const classes = useInputStyles();

  const getValue = () => {
    if (!Array.isArray(value)) return value;

    return value.find(({ languageCode }) => languageCode === currentLanguageCode).value || '';
  };

  return (
    <button type="button" className={classes.root} style={style}>
      {getValue().toLowerCase()}
    </button>
  );
};

OverlayButtonPreview.defaultProps = {
  style: {},
  value: '',
  currentLanguageCode: 'en',
};

OverlayButtonPreview.propTypes = {
  style: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  currentLanguageCode: PropTypes.string,
};

export { OverlayButtonPreview };
