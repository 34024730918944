import { Box as MuiBox, withStyles } from '@material-ui/core';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '&.container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: '112px',
      height: '128px',
      padding: '16px 12px',
      backgroundColor: `${COLORS.cozmosBlue}10`,
      color: COLORS.darkText,
      border: `solid 1px ${COLORS.cozmosBlue}20`,
      borderRadius: '4.7px',
      cursor: 'pointer',
      marginRight: '42px',
      '& .MuiTypography-caption': {
        whiteSpace: 'pre-line',
      },
    },
    '&.icon-wrapper': {
      display: 'inline-block',
      paddingBottom: '10px',
      '& svg': {
        height: ICON_SIZE.big,
        width: ICON_SIZE.big,
      },
    },
  },
})(MuiBox);
