import React, { useEffect, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { SSO_LOGIN_ERROR_QUERY_PARAM } from 'constants/technical';
import { AUTH_HEADERS } from 'models/constants';
import { ROUTES } from 'routes/constants';

const SsoCompletePage = () => {
  const history = useHistory();
  const location = useLocation();

  const pbToken = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get(AUTH_HEADERS.PUBTOOL_TOKEN);
  }, [location]);

  useEffect(() => {
    if (pbToken) {
      history.push(`/${location.search}`);
    } else {
      history.push(`${ROUTES.authSignIn}?${SSO_LOGIN_ERROR_QUERY_PARAM}=Something+went+wrong.+Try+again.`);
    }
  }, [pbToken, history]);

  return null;
};

export default SsoCompletePage;
