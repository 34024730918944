import React, { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { tfaModel } from 'models';
import { ROUTES } from 'routes/constants';

import { AuthCard, FormLink } from '../style';

export default function ResetToken() {
  const history = useHistory();
  const { handleServerError } = useServerErrorActions();

  useEffect(() => {
    const params = queryString.parse(window.location.search);

    if (!isEmpty(params)) {
      tfaModel.resetTFA(params).then(handleGoToSignIn).catch(handleServerError);
    } else {
      handleGoToSignIn();
    }
  }, []);

  const handleGoToSignIn = () => history.push(ROUTES.authSignIn);

  return (
    <AuthCard>
      <Helmet title="Reset Token" />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">Reset Token</Typography>
        </Grid>
        <Grid item>
          <FormLink onClick={handleGoToSignIn} variant="text" text="Back to Sign in" />
        </Grid>
      </Grid>
    </AuthCard>
  );
}
