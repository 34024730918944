import { Box, Typography, Grid, Divider as MuiDivider, styled } from '@material-ui/core';

export const InfoOverlaySectionCaption = styled(Typography)({
  fontSize: '12px',
  margin: '20px 0',
});

export const InfoOverlaySectionTitle = styled(Typography)({
  fontSize: '12px',
  fontWeight: '600',
});

export const TextComponentWrapper = styled(Box)({
  marginBottom: '-28px',
});

export const DividerWrapper = styled(Grid)({
  paddingTop: '0 !important',
});

export const Divider = styled(MuiDivider)({
  width: '100%',
});

export const TextFieldWrapper = styled(Box)({
  flexGrow: '1',
});
