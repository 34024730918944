import { Grid as MuiGrid, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const GridContainer = styled(MuiGrid)({
  '&.mb0': { marginBottom: 0 },
  '&.mb2': { marginBottom: '12px' },
  '&.mb4': { marginBottom: '24px' },
  '&.mt2': { marginTop: '12px' },
});

export const GridTitle = styled(MuiGrid)({
  minWidth: '85px',
  '& .MuiSvgIcon-root': {
    fontSize: '12px',
    color: COLORS.greyText,
    marginRight: '4px',
  },
});

export const Span = styled('span')({
  color: COLORS.greyText,
});
