import { Slider as MuiSlider, Box as MuiBox, styled, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Slider = withStyles({
  root: { padding: '0!important' },
  thumb: {
    height: 14,
    width: 14,
    marginTop: '-6px',
    marginLeft: '-6px!important',
    '&:focus, &:hover, &:active': {
      boxShadow: '0 2px 6px rgba(0,0,0,.3)',
      '&::after': { content: 'none' },
    },
    '&::after': { content: 'none' },
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: COLORS.black,
      fontSize: '12px',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
  },
  mark: {
    height: 4,
    marginTop: -1,
  },
  markActive: {
    opacity: 1,
  },
  markLabel: {
    color: COLORS.greyText,
    fontSize: '12px',
  },
})(MuiSlider);

export const Box = styled(MuiBox)({
  padding: '32px 14px 0',
});
