import React from 'react';

import PropTypes from 'prop-types';

import { WrapperScroll, WrapperDisplayTable } from 'components/style';

export const TableHorizontalScrollWrapper = ({ children }) => (
  <WrapperDisplayTable>
    <WrapperScroll>{children}</WrapperScroll>
  </WrapperDisplayTable>
);

TableHorizontalScrollWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]).isRequired,
};
