import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { getServerError } from 'utils';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface ConfirmTrafficRedirectDialogProps {
  serviceName: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const FIELD_NAME = 'service-name-field';

export function ConfirmTrafficRedirectDialog({ serviceName, onClose, onSubmit }: ConfirmTrafficRedirectDialogProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const { handleServerError } = useServerErrorActions();

  const handleChange = (value: { [FIELD_NAME]: string }) => setInputValue(value[FIELD_NAME]);

  const handleConfirm = () => {
    withHandlingSubmittingState(onSubmit)();
  };

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>{t('components.dialogs.trafficRedirectDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ paddingLeft: 0 }}>
          {t('components.dialogs.trafficRedirectDialog.description')}
        </DialogContentText>
        <ValidatorForm onSubmit={handleConfirm} autoComplete="off">
          <TextValidatorComponent
            validators={[VALIDATORS_MAP.required]}
            label={t('components.dialogs.trafficRedirectDialog.fieldName')}
            value={inputValue}
            fieldName={FIELD_NAME}
            handleChange={handleChange}
            autoFocus
          />
          <DialogActions>
            <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
            <ButtonComponent
              text={t('components.dialogs.trafficRedirectDialog.confirmButton')}
              type="submit"
              color="primary"
              disabled={inputValue !== serviceName}
              loading={isSubmitting}
            />
          </DialogActions>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
}
