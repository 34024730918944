import React, { useState } from 'react';

import { isEmpty } from 'lodash';

import { PageHeader, PageHeaderNav } from 'components';
import { SuccessActionsDialog } from 'components/Dialogs';
import { ROUTES } from 'routes/constants';
import {getTWithPathKey} from 'utils';
import { withPageLanguage, withPageLanguageDefaultProps, withPageLanguagePropTypes } from 'utils/enhancers';

import { CookiesPopup } from './CookiesPopup/CookiesPopup';
import { CookiesTable } from './CookiesTable/CookiesTable';
import { CookiesData } from './CookiesData/CookiesData';

function CookiesSettings({ pageLanguageTabValue, handleValidationCheck, children }) {
  const getT = getTWithPathKey('pages.settings.cookiesSettings');
  const [successActionMessages, setSuccessActionMessages] = useState([]);
  const handleCloseOnSuccessDialog = () => setSuccessActionMessages([]);
  const [cookiesSettings, setCookiesSettings] = useState([]);

  return (
    <>
      <PageHeader title={getT('title')} backLink={ROUTES.settings}>
        <PageHeaderNav />
      </PageHeader>
      {children}
      <CookiesPopup
        pageLanguageTabValue={pageLanguageTabValue}
        handleValidationCheck={handleValidationCheck}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <CookiesTable
        cookiesDataSettings={cookiesSettings}
        pageLanguageTabValue={pageLanguageTabValue}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <CookiesData
        cookiesSettings={cookiesSettings}
        handleValidationCheck={handleValidationCheck}
        setCookiesSettings={setCookiesSettings}
        pageLanguageTabValue={pageLanguageTabValue}
        setSuccessActionMessages={setSuccessActionMessages}
      />
      <SuccessActionsDialog
        open={!isEmpty(successActionMessages)}
        onClose={handleCloseOnSuccessDialog}
        messages={successActionMessages}
      />
    </>
  );
}

CookiesSettings.propTypes = {
  ...withPageLanguagePropTypes,
};

CookiesSettings.defaultProps = {
  ...withPageLanguageDefaultProps,
};

export default withPageLanguage(CookiesSettings);
