import { Box as MuiBox, TextField as MuiTextField, Grid, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const Box = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
})(MuiBox);

export const TextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '12px',
      fontWeight: '500',
    },
    '& .MuiFormLabel-root': {
      fontSize: 'unset',
    },
  },
})(MuiTextField);

export const GridBtnContainer = withStyles({
  root: {
    textAlign: 'right',
    marginTop: '12px',
  },
})(Grid);

export const GridContainer = withStyles({
  root: {
    marginTop: '32px',
  },
})(Grid);

export const OpenDialogButton = withStyles({
  root: {
    fontSize: '10px',
    marginTop: '8px',
    cursor: 'pointer',
  },
})(Link);
