import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { PageHeader } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { adminsModel } from 'models';
import { ROUTES } from 'routes/constants';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';

import { AccountAccess, ClientInfo, PersonalInfo, TFASwitch } from '../settings';

function EditSystemAdmin({
  match: {
    params: { id },
  },
  setSuccessActionMessages,
}) {
  const [admin, setAdmin] = useState({});
  const [adminsCount, setAdminsCount] = useState(0);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getAdmin();
  }, [id]);

  const getAdmin = withPageProgressHandler(() =>
    adminsModel
      .getAdmin(id)
      .then(data => {
        setAdmin(data.admin);
        setAdminsCount(data.adminsCount);
      })
      .catch(handleServerError)
  );

  return (
    <>
      <PageHeader backLink={ROUTES.manageSystemAdmins} title="SYSTEM ADMIN DETAILS" />
      <PersonalInfo
        handleServerError={handleServerError}
        setSuccessActionMessages={setSuccessActionMessages}
        adminInfo={admin}
      />
      {!isEmpty(admin) && (
        <TFASwitch
          handleServerError={handleServerError}
          adminId={admin.id}
          setSuccessActionMessages={setSuccessActionMessages}
          disabledSwitch
        />
      )}
      <AccountAccess
        handleServerError={handleServerError}
        setSuccessActionMessages={setSuccessActionMessages}
        admin={admin}
      />
      <ClientInfo admin={admin} adminsCount={adminsCount} />
    </>
  );
}

EditSystemAdmin.propTypes = {
  ...withSuccessActionsDialogProps,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default withSuccessActionsDialog(EditSystemAdmin);
