import React from 'react';

import { compose } from 'lodash/fp';
import { Table, TableBody, TableHead, TableRow, Typography, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';

import { withExpand, withExpandPropTypes } from 'utils/enhancers';

import { Box } from './style';

function OneColumnExpandedTableComponent({ handleToggleExpand, expanded, renderExpand, title, caption, children }) {
  return (
    <Box className="table-wrapper" mb={2}>
      <Table>
        <TableHead>
          <TableRow hover onClick={handleToggleExpand}>
            <TableCell>
              <Box mb={2}>
                <Typography variant="h6">{title}</Typography>
              </Box>
              <Typography variant="subtitle1" className="information">
                {caption}
              </Typography>
            </TableCell>
            <TableCell align="right">{renderExpand()}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{expanded && children}</TableBody>
      </Table>
    </Box>
  );
}

OneColumnExpandedTableComponent.propTypes = {
  ...withExpandPropTypes,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export const OneColumnExpandedTable = compose(withExpand)(OneColumnExpandedTableComponent);
