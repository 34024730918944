import React, { memo, useEffect, useState } from 'react';

import { Box, Checkbox, Typography } from '@material-ui/core';
import { pick, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { matchCustomRegexpRule, VALIDATORS_MAP } from 'components/FormComponents/const';
import { getTWithPathKey, handleGetCheckboxValue } from 'utils';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '../style';

export function PlanogramEditDialogComponent({ open, planogram, handleEdit, handleClose }) {
  const getT = key => getTWithPathKey('components.dialogs.planogramEditDialog')(key);
  const initialState = {
    ...planogram,
    accessCheckbox: !planogram.accessibilityDescription,
  };
  const [formFields, setFormFields] = useState(initialState);

  useEffect(() => {
    ValidatorForm.addValidationRule('matchCustomRegexp', value => !matchCustomRegexpRule.test(value));
  });

  useEffect(() => {
    setFormFields(initialState);
  }, [planogram]);

  const submitForm = () => {
    const prevData = pick(planogram, ['id', 'isLanding', 'seoTitle', 'seoDesc', 'accessibilityDescription']);
    const newData = {
      ...pick(formFields, ['id', 'isLanding', 'seoTitle', 'seoDesc']),
      accessibilityDescription: formFields.accessCheckbox ? '' : formFields.accessibilityDescription,
    };

    if (!isEqual(prevData, newData)) {
      handleEdit(newData);
    }
    onClose();
  };

  const onClose = () => {
    setFormFields(initialState);
    handleClose();
  };

  const handleChange = item => {
    setFormFields(prevState => ({
      ...prevState,
      ...item,
    }));
  };

  const handleCheckboxSwitch = item => {
    setFormFields(prevState => ({
      ...prevState,
      accessCheckbox: handleGetCheckboxValue(item),
    }));
  };

  return (
    open && (
      <Dialog open={open} fullWidth>
        <DialogTitle className="pb-0">{getT('dialogTitle')}</DialogTitle>
        <ValidatorForm onSubmit={submitForm}>
          <DialogContent>
            <TextValidatorComponent
              validators={[VALIDATORS_MAP.antiXSSRegexp]}
              label={getT('seoTitle')}
              value={formFields.seoTitle || ''}
              fieldName="seoTitle"
              handleChange={handleChange}
            />
            <TextValidatorComponent
              validators={[VALIDATORS_MAP.antiXSSRegexp]}
              label={getT('seoDesc')}
              value={formFields.seoDesc || ''}
              fieldName="seoDesc"
              rows={5}
              multiline
              handleChange={handleChange}
            />
            <Box display="flex" alignItems="flex-start" mt={4} mb={4}>
              <Box mt={-2.25} ml={-3} position="absolute">
                <Checkbox color="primary" checked={formFields.accessCheckbox} onChange={handleCheckboxSwitch} />
              </Box>
              <Box ml={10}>
                <Typography variant="caption">{getT('accessibilityCheckboxLabel')}</Typography>
              </Box>
            </Box>
            {!formFields.accessCheckbox && (
              <Box mb={4}>
                <TextValidatorComponent
                  validators={[VALIDATORS_MAP.antiXSSRegexp, VALIDATORS_MAP.required]}
                  label="Accessibility Scene Description"
                  value={formFields.accessibilityDescription || ''}
                  fieldName="accessibilityDescription"
                  rows={5}
                  multiline
                  handleChange={handleChange}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonComponent onClick={onClose} text="Cancel" />
            <ButtonComponent type="submit" color="primary" text="Save" />
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  );
}

PlanogramEditDialogComponent.propTypes = {
  planogram: PropTypes.shape({
    seoTitle: PropTypes.string,
    seoDesc: PropTypes.string,
    accessibilityDescription: PropTypes.string,
    isLanding: PropTypes.bool,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export const PlanogramEditDialog = memo(PlanogramEditDialogComponent);
