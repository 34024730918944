import { FormControlLabel as MuiFormControlLabel, Radio as MuiRadio, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Radio = styled(MuiRadio)({
  color: COLORS.greyDefault,
});

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '&.default.selected': { border: `1px solid ${theme.palette.primary.main}` },
  margin: 0,
  border: `1px solid ${COLORS.greyBorder}`,
  minHeight: '60px',
  borderRadius: '4px',
  position: 'relative',
  padding: '14px 16px',
  width: '100%',
  height: '100%',
  '&.default .MuiRadio-root': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  '& > .MuiFormControlLabel-label': { height: '100%' },
  '&.cloneDialog': {
    minHeight: '25px',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',

    '& .MuiBox-root': {
      margin: 0,
    }
  }
}));
