import { Box, styled, withStyles } from '@material-ui/core';
import MuiImageIcon from '@material-ui/icons/Image';

import { COLORS } from 'theme/constants';

export const ImageUploadWrapper = withStyles({
  root: {
    position: 'relative',
    border: '1px dashed rgba(45, 99, 243, 0.5)',
    borderRadius: '4px',
    backgroundColor: COLORS.cozmosBlueLight,
    textAlign: 'center',
    padding: '1rem',
    '&.size-medium': {
      padding: '47px 16px',
    },
  },
})(Box);

export const UploadedImage = styled('img')({
  height: '100%',
  width: '100%',
  maxHeight: '90px',
  objectFit: 'contain',
});

export const ImageIcon = withStyles({
  root: { color: COLORS.cozmosBlue },
})(MuiImageIcon);
