import {
  Box as MuiBox,
  List as MuiList,
  ListItem,
  Typography as MuiTypography,
  Drawer as MuiDrawer,
  styled,
} from '@material-ui/core';

import { drawerWidth } from 'layouts/Dashboard/constants';

import { ReactComponent as CozmosSVG } from 'assets/img/cozmos-icon.svg';

export const Typography = styled(MuiTypography)({
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const List = styled(MuiList)({
  height: '100%',
});

export const Brand = styled(ListItem)(({ theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.sidebar.text,
  backgroundColor: theme.palette.sidebar.background,
  minHeight: '64px',
  padding: '0 24px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
}));

export const CozmosIcon = styled(CozmosSVG)(({ theme }) => ({
  marginRight: '18px',
  height: '28px',
  width: '28px',
}));

export const SidebarSection = styled(MuiBox)(({ theme }) => ({
  ...theme.typography.h4Heading,
  color: theme.palette.sidebar.headerText,
  display: 'flex',
  alignItems: 'center',
  height: '42px',
  paddingLeft: '24px',
}));

export const SidebarFooter = styled(MuiBox)(({ theme }) => ({
  ...theme.typography.h3,
  padding: '8px 24px 20px',
  borderTop: `1px solid ${theme.palette.sidebar.headerText}`,
  marginTop: '5px',
}));

export const HelpContainer = styled(MuiBox)({
  paddingLeft: '24px',
  paddingBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  '& > *:not(:last-child)': {
    paddingBottom: '8px',
  },
});

export const TermsContainer = styled(MuiBox)({
  display: 'flex',
  paddingBottom: '20px',
  '& > *:first-child': {
    paddingRight: '14px',
  },
});

export const SidebarLink = styled('a')(({ theme }) => ({
  ...theme.typography.linkNav,
  textDecoration: 'none',
  color: theme.palette.sidebar.links,
  fontSize: '12px',
  fontWeight: 600,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: theme.palette.sidebar.background,
    color: theme.palette.sidebar.text,
  },
}));

export const SpaceName = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.h2Heading,
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
