import { asyncFileReader } from './asyncFileReader';

export const getAllowedExtensions = (acceptedFiles: string): string[] => acceptedFiles.replace(/[. ]/g, '').split(',');

export const checkIsExtensionsAllowed = ({ name }: File, acceptedFiles?: string): boolean => {
  if (!acceptedFiles) {
    return false;
  }

  const allowedExtensions = getAllowedExtensions(acceptedFiles);
  const fileExtension = name.split('.').pop()?.toLowerCase();

  return allowedExtensions.includes(fileExtension || '');
};

export const checkPdfFile = async (file: File): Promise<boolean> => {
  const PDF_FILE_REGEX = new RegExp('%PDF-1.[0-7]'); // check for PDF version in beginning of real PDF file

  try {
    const fileResult = await asyncFileReader(file, 8);

    return !!fileResult.match(PDF_FILE_REGEX);
  } catch (error) {
    return false;
  }
};
