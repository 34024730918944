import React from 'react';

import { Button } from '@material-ui/core';
import Helmet from 'react-helmet';

import { PageHeader, PageHeaderNav } from 'components';

export interface PageWithHeaderProps {
  tabTitle: string;
  headerText: string;
  button?: {
    text: string;
    onClick: () => void;
  };
  children: React.ReactNode;
}

const PageWithHeader = ({ tabTitle, headerText, button, children }: PageWithHeaderProps) => (
  <>
    {tabTitle && <Helmet title={tabTitle} />}
    <PageHeader title={headerText}>
      <PageHeaderNav>
        {button && (
          <Button color="primary" variant="contained" size="small" onClick={button.onClick}>
            {button.text}
          </Button>
        )}
      </PageHeaderNav>
    </PageHeader>
    {children}
  </>
);

export default PageWithHeader;
