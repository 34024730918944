import React from 'react';

import PropTypes from 'prop-types';

import { TextField, CalendarTodayIcon } from './style';

export function DateComponent({ value }) {
  return (
    <>
      <CalendarTodayIcon />
      <TextField
        label=""
        format="MMMM dd,yyyy"
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
}

DateComponent.propTypes = {
  value: PropTypes.string,
};

DateComponent.defaultProps = {
  value: '',
};
