import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { LINKS } from 'components/constants';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface ConfirmReservedSubdomainDialogProps {
  subdomain: string;
  onClose: () => void;
  onSubmit: () => void;
}

export function ConfirmReservedSubdomainDialog({ subdomain, onClose, onSubmit }: ConfirmReservedSubdomainDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>{t('components.internalSubdomains.reservedTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ paddingLeft: 0 }}>
          <Trans i18nKey="components.internalSubdomains.reservedDescription" values={{ subdomain }}>
            The subdomain name <strong>{{ subdomain }}</strong> is a domain name from the reserved list. This will block
            any other System Admin from using it. Be sure this is required before continuing.\n\nView all
            <a target="_blank" rel="noreferrer" href={LINKS.reservedClientNames}>
              reserved domains
            </a>
            .
          </Trans>
        </DialogContentText>
        <DialogActions>
          <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
          <ButtonComponent
            text={t('components.internalSubdomains.saveAnyway')}
            color="primary"
            onClick={() => onSubmit()}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
