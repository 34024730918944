import { AxiosError } from 'axios';

import { DataServerErrors, ServerErrorsArray, ServerErrors } from 'types/common';

export enum ServerErrorActions {
  HANDLE_SERVER_ERROR = 'HANDLE_SERVER_ERROR',
  RESET_SERVER_ERRORS = 'RESET_SERVER_ERRORS',
  RESET_SERVER_FORM_ERROR = 'RESET_SERVER_FORM_ERROR',
}

export type ServerErrorType =
  | AxiosError<ServerErrorsArray<string> | ServerErrors<string[]>>
  | DataServerErrors<string[]>;

export interface ServerErrorDispatchType {
  handleServerError: (error: ServerErrorType, formId?: string) => void;
  resetServerErrors: () => void;
  resetServerFormError: (item: string) => void;
}
