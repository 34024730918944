export const asyncFileReader = async (file: File, readBytes: number = 0): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const fileToRead = readBytes ? file.slice(0, readBytes) : file;

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = reject;

    reader.readAsText(fileToRead);
  });
