import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { tagsModel } from 'models';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '../style';

const defaultTag = {
  id: null,
  name: '',
  errorMessages: {},
};

export class NewTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: isEmpty(props.tag) ? defaultTag : props.tag,
      open: true,
      errorMessages: {},
      isLoading: false
    };
  }

  submitForm = () => {
    const { afterAction, handleServerError } = this.props;
    const {
      tag: { name, id },
    } = this.state;

    this.setState(oldStates => ({ ...oldStates, request: true, isLoading: true }));

    const tagPromise = id ? tagsModel.editTag(id, { name }) : tagsModel.addTag(name);

    return tagPromise
      .then(() => afterAction(!!id))
      .catch(handleServerError)
      .finally(() =>
        this.setState(oldStates => ({ ...oldStates, isLoading: false }))
      );
  };

  handleClose = () => {
    const { afterAction } = this.props;

    this.setState(oldStates => ({ ...oldStates, open: false }));
    afterAction({});
  };

  handleChange = item =>
    this.setState(prevState => ({
      ...prevState,
      tag: { ...prevState.tag, ...item },
    }));

  getMessage = () => {
    const { message, tag } = this.props;

    if (message) {
      return message;
    }

    return !isEmpty(tag) && tag.id ? 'Edit the tag' : 'Add new tag';
  };

  render() {
    const {
      open,
      tag: { name },
    } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth>
        <DialogTitle className="pb-0">{this.getMessage()}</DialogTitle>
        <ValidatorForm onSubmit={this.submitForm}>
          <DialogContent>
            <TextValidatorComponent
              validators={[VALIDATORS_MAP.required]}
              label="Name"
              value={name}
              fieldName="name"
              handleChange={this.handleChange}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <ButtonComponent onClick={this.handleClose} text="Cancel" />
            <ButtonComponent
              type="submit"
              color="primary"
              loaderSize="1.35em"
              loading={this.state.isLoading}
              text="Save"
            />
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
}

NewTag.propTypes = {
  message: PropTypes.string,
  afterAction: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    color: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  handleServerError: PropTypes.func.isRequired,
};

NewTag.defaultProps = {
  tag: defaultTag,
  message: '',
};
