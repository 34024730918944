import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ColoredBox } from 'components/ColoredBox/ColoredBox';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';

import { PLANOGRAM_VERSION_STATUS } from '../constants';

import { DoneIcon } from './style';

const VARIANTS = { green: 'green', orange: 'orange', violet: 'violet', failed: 'failed', sync_failed: 'sync-failed' };

export function StatusComponent({ status, statusText, useIcon = true, ...rest }) {
  const getVariant = () => {
    if (status === PLANOGRAM_VERSION_STATUS.published || status === UPLOAD_STATUSES.SUCCESS) {
      return VARIANTS.green;
    }
    if (status === PLANOGRAM_VERSION_STATUS.generated) {
      return VARIANTS.orange;
    }
    if (status === PLANOGRAM_VERSION_STATUS.draft) {
      return VARIANTS.violet;
    }
    if (status === UPLOAD_STATUSES.ERROR) {
      return VARIANTS.failed;
    }
    if (status === UPLOAD_STATUSES.SYNC_FAILED) {
      return VARIANTS.sync_failed;
    }
    return '';
  };

  return (
    <Box mr={2} {...rest}>
      <ColoredBox variant={getVariant()} text={statusText || status}>
        {status === PLANOGRAM_VERSION_STATUS.published && useIcon ? <DoneIcon /> : null}
      </ColoredBox>
    </Box>
  );
}

StatusComponent.propTypes = {
  status: PropTypes.string.isRequired,
  statusText: PropTypes.string,
};

StatusComponent.defaultProps = {
  statusText: '',
};
