import {
  Typography as MuiTypography,
  styled,
} from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Typography = styled(MuiTypography)(() => ({
  color: COLORS.cozmosPurple2,

  '&.MuiTypography-h5': {
    color: COLORS.cozmosPurple4,
  },
}))
