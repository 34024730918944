import { SsoProviderForm, SsoProviderType } from 'types';

import { api } from './setupApi';

class SsoProvidersModel {
  link = '/sso_providers';

  getProvider = (id: string): Promise<SsoProviderType> =>
    api.get<SsoProviderType>(`${this.link}/${id}`).then(({ data }) => data);

  updateProvider = (id: string, data: SsoProviderForm): Promise<SsoProviderType> =>
    api.put<SsoProviderType>(`${this.link}/${id}`, { ssoProvider: data }).then(({ data }) => data);
}

export const ssoProvidersModel = new SsoProvidersModel();
