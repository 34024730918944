import React from 'react';

import PropTypes from 'prop-types';

import { FontSettingsPropTypes } from 'components/Dialogs/FontSettingsEditDialog/constants';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { getInlineFontStyles } from 'utils';

import { ShareButton, ShareButtonMobile, ShareButtonIcon } from './style';

export function ShareButtonPreview({ imageUrl, title, backgroundColor, fontSettings, isMobile }) {
  return isMobile ? (
    <CustomFontWrapper font={fontSettings?.font}>
      <ShareButtonMobile style={{ backgroundColor, ...getInlineFontStyles(fontSettings) }}>
        <span>{title}</span>
        <ShareButtonIcon src={imageUrl} />
      </ShareButtonMobile>
    </CustomFontWrapper>
  ) : (
    <ShareButton style={{ backgroundColor }}>
      <ShareButtonIcon src={imageUrl} />
    </ShareButton>
  );
}

ShareButtonPreview.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  fontSettings: FontSettingsPropTypes,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  isMobile: PropTypes.bool,
};

ShareButtonPreview.defaultProps = {
  title: '',
  backgroundColor: 'transparent',
  isMobile: false,
  fontSettings: null,
};
