import React, { useState, useEffect } from 'react';

import { Checkbox, Grid, Box, RadioGroup, FormControlLabel, Radio, Divider } from '@material-ui/core';
import { capitalize, pick, includes, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  ButtonPreviewWrapper,
  ColorPicker,
  FontSettingsControlWrapper,
  LoadFileComponent,
  RadioGroupComponent,
  SearchPreview,
  ToggleButtonGroupComponent,
} from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { AutocompleteValidatorComponent, TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { NAVIGATION_BUTTON_TYPES } from 'constants/technical';
import { activeStorageModel } from 'models';
import {
  getBlobUrl,
  getFontSettingsWithNums,
  getFontSettingsWithPx,
  getNameFromSnakeCase,
  sortByName,
} from 'utils';

import { SEARCH_SETTINGS_VALUES, defaultSettings } from './const';
import { NoSearchResults } from './NoSearchResults/NoSearchResults';

import { Typography } from '../style';

export function SearchSettings({
  planogramVersion,
  handleServerError,
  setSettings,
  tabSettings,
}) {
  const [searchSettings, setSearchSettings] = useState({ ...defaultSettings });
  const [controlButton, setControlButton] = useState({});
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});
  const [settingsRadioValue, setSettingsRadioValue] = useState('');
  const [activeIcon, setActiveIcon] = useState({});
  const [isLoadingActiveIcon, setIsLoadingActiveIcon] = useState(false);
  const [includedPlanogramIds, setIncludedPlanogramIds] = useState([]);
  const [initialIncludedPlanogramIds, setInitialIncludedPlanogramIds] = useState([]);
  const [initialControlButton, setInitialControlButton] = useState({});
  const [initialNoResultsSettings, setInitialNoResultsSettings] = useState({});
  const [noResultsSettings, setNoResultsSettings] = useState({});

  useEffect(() => {
    getSearchSettings();
  }, []);

  useEffect(() => {
    if (isEmpty(controlButton) && !isEmpty(initialControlButton)) {
      setControlButton(initialControlButton);
    }
  }, [initialControlButton]);

  useEffect(() => {
    if (isEmpty(noResultsSettings) && !isEmpty(initialNoResultsSettings)) {
      setNoResultsSettings(initialNoResultsSettings);
    }
  }, [initialNoResultsSettings]);

  useEffect(() => {
    const data = getDataToSave();

    setSettings({
      searchActiveIcon: { ...activeIcon },
      searchSettingFont: { ...data.searchSettingFont },
      noResultSearchSettingFont: { ...data.noResultSearchSettingFont },
      searchSetting: { ...data },
    });
  }, [searchSettings, noResultsSettings, controlButton, activeIcon, includedPlanogramIds]);

  useEffect(() => {
    includedPlanogramIds?.length === searchSettings.allowedExcludePlanogramVersion?.length
      ? setSettingsRadioValue(SEARCH_SETTINGS_VALUES.default.value)
      : setSettingsRadioValue(SEARCH_SETTINGS_VALUES.custom.value);
  }, [searchSettings.allowedExcludePlanogramVersion, includedPlanogramIds]);

  const saveSettings = settings => {
    const { searchSettingsData, noSearchResultsData } = getSettings(settings);
    const initialIncludedPlIds = searchSettingsData?.allowedExcludePlanogramVersion
      .filter(({ id }) => !includes(searchSettingsData.excludePlanogramIds, id))
      .map(({ id }) => id);

    setSearchSettings(searchSettingsData);
    setInitialControlButton(settings.controlButton);
    setActiveIcon(searchSettingsData.searchActiveIcon);
    setIncludedPlanogramIds(initialIncludedPlIds);
    setInitialIncludedPlanogramIds(initialIncludedPlIds);
    setInitialNoResultsSettings(noSearchResultsData);
    setSettings({ searchSetting: settings });
  }

  const getSearchSettings = () => {
    if (!tabSettings.initialSettings && tabSettings.elementType === NAVIGATION_BUTTON_TYPES.SEARCH) {
      return saveSettings(tabSettings.searchSetting);
    }

    return saveSettings(planogramVersion.defaultNavigation.searchSetting);
  };

  const getSettings = ({ controlButton, noResultSearchSettingFont, noResultImage, noResultSearchTitle, ...data }) => ({
    searchSettingsData: {
      ...data,
      searchSettingFont: data.searchSettingFont
        ? {
            ...getFontSettingsWithNums(data.searchSettingFont),
            font: data.searchSettingFont.font,
            fieldName: 'searchSettingFont',
          }
        : data.searchSettingFont,

      allowedSearchAttributes: data.allowedSearchAttributes.filter(attr =>
        includes(SEARCH_SETTINGS_VALUES.allowedSearchAttributes, attr),
      ),
      allowedExcludePlanogramVersion: sortByName(data.allowedExcludePlanogramVersion),
      allowedSearchOrderBy: [
        SEARCH_SETTINGS_VALUES.defaultSearchOrder,
        ...getAttrsWithNames(data.allowedSearchOrderBy),
      ],
      searchOrderBy:
        data.searchOrderBy === SEARCH_SETTINGS_VALUES.defaultSearchOrder.value
          ? SEARCH_SETTINGS_VALUES.defaultSearchOrder.id
          : data.searchOrderBy,
    },
    noSearchResultsData: {
      noResultSearchSettingFont: noResultSearchSettingFont
        ? {
            ...getFontSettingsWithNums(noResultSearchSettingFont),
            font: noResultSearchSettingFont.font,
            fieldName: 'noResultSearchSettingFont',
          }
        : noResultSearchSettingFont,

      noResultImage,
      noResultSearchTitle,
    },
  });

  const getAttrsWithNames = (list = []) =>
    list.map(attr => ({
      name: capitalize(getNameFromSnakeCase(attr)),
      id: attr,
    }));

  const setRadioGroupValue = value => {
    switch (value) {
      case SEARCH_SETTINGS_VALUES.default.value:
        return setIncludedPlanogramIds(searchSettings.allowedExcludePlanogramVersion.map(item => item.id));
      case SEARCH_SETTINGS_VALUES.custom.value:
        return setIncludedPlanogramIds([]);
      default:
        return null;
    }
  };

  const handleEditSearchSettings = item => value => setSearchSettings(prev => ({ ...prev, [item]: value }));

  const onCheckedCheckboxChange = ({ target: { value, checked } }) =>
    checked
      ? handleEditSearchSettings('searchAttributes')([...searchSettings.searchAttributes, value])
      : handleEditSearchSettings('searchAttributes')(
          searchSettings.searchAttributes?.filter(setting => setting !== value),
        );
  const onSelectChange = item => setSearchSettings(prev => ({ ...prev, ...item }));
  const onSortRadioChange = ({ target: { value } }) => setSearchSettings(prev => ({ ...prev, searchOrderDir: value }));

  const onChangeColor =
    setColor =>
    path =>
    ({ hex }) =>
      setColor(prev => ({ ...prev, [path]: hex }));

  const handleLoadIcon = (setIcon, setIsLoadingIcon) => file => {
    if (file) {
      setIsLoadingIcon(true);
      activeStorageModel.createUpload(file).create((error, blob) => {
        setIsLoadingIcon(false);
        if (error) {
          handleServerError(error);
        } else {
          setIcon(prev => ({
            ...prev,
            url: getBlobUrl(blob.key),
            signedId: blob.signed_id,
            removeImage: false,
          }));
        }
      });
    } else {
      setIcon(prev => ({
        ...prev,
        url: '',
        removeImage: true,
      }));
    }
  };

  const handleFontSettingsSave = settings => {
    setFontSettingsEdit(null);
    handleEditSearchSettings(settings.fieldName)(settings);
  };

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsEdit = settings => () =>
    setFontSettingsEdit(settings ? { ...settings } : { planogramVersionId: planogramVersion.id });

  const getDataToSave = () => ({
    ...pick(searchSettings, ['enabled', 'searchAttributes', 'searchOrderBy', 'searchOrderDir', 'searchTitle']),
    searchOrderBy:
      searchSettings.searchOrderBy === SEARCH_SETTINGS_VALUES.defaultSearchOrder.id
        ? SEARCH_SETTINGS_VALUES.defaultSearchOrder.value
        : searchSettings.searchOrderBy,
    searchSettingFont: searchSettings.searchSettingFont
      ? getFontSettingsWithPx(searchSettings.searchSettingFont)
      : null,
    excludePlanogramIds: searchSettings.allowedExcludePlanogramVersion
      .filter(({ id }) => !includes(includedPlanogramIds, id))
      .map(({ id }) => id),
    noResultSearchSettingFont: noResultsSettings.noResultSearchSettingFont
      ? getFontSettingsWithPx(noResultsSettings.noResultSearchSettingFont)
      : null,
    noResultSearchTitle: noResultsSettings.noResultSearchTitle,
    noResultImage: noResultsSettings.noResultImage,
    controlButton,
    noResultImageId: noResultsSettings.noResultImage?.id,
    controlButtonId: controlButton.id,
    allowedSearchAttributes: searchSettings.allowedSearchAttributes,
    allowedExcludePlanogramVersion: searchSettings.allowedExcludePlanogramVersion,
    allowedSearchOrderDir: searchSettings.allowedSearchOrderDir,
    searchActiveIcon: activeIcon,
  });

  const renderSortOrderLabel = item => SEARCH_SETTINGS_VALUES.sortOrder[item];

  return (
    <>
      <Grid item sm={12}>
        <Box mt={6} mb={4}>
          <Typography variant="h4" display="inline">
            Active state
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={6}>
            <Box mb={2}>
              <Typography variant="caption" display="inline" className="information">
                Button
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb={2}>
              <Typography variant="caption" display="inline" className="information">
                Search bar accent color
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={6}>
            <Box mb={4}>
              <ColorPicker
                setColor={onChangeColor(setActiveIcon)('buttonColor')}
                color={{ hex: activeIcon?.buttonColor }}
              />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb={4}>
              <ColorPicker
                setColor={onChangeColor(setActiveIcon)('inputColor')}
                color={{ hex: activeIcon?.inputColor }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Box mb={2}>
          <Typography variant="caption" display="inline" className="information">
            Icon
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <LoadFileComponent
          accept={ACCEPT_EXTENSIONS.imgIcon}
          text="Import icon"
          file={activeIcon.url}
          loadFile={handleLoadIcon(setActiveIcon, setIsLoadingActiveIcon)}
          handleServerError={handleServerError}
          isLoading={isLoadingActiveIcon}
        />
      </Grid>

      <Grid item sm={12}>
        <Box mt={6} mb={4}>
          <Typography variant="h4" display="inline">
            Text settings
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={12}>
        <Grid container>
          <FontSettingsControlWrapper
            fontSettings={searchSettings.searchSettingFont}
            handleSettingsEdit={handleFontSettingsEdit(searchSettings.searchSettingFont)}
          >
            <Grid item sm={6}>
              <TextValidatorComponent
                fieldName="searchTitle"
                label="Label"
                value={searchSettings.searchTitle}
                handleChange={onSelectChange}
              />
            </Grid>
          </FontSettingsControlWrapper>
          <FontSettingsEditDialog
            settings={fontSettingsEdit}
            handleClose={closeFontSettingsDialog}
            handleSet={handleFontSettingsSave}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Box mb={2}>
          <Typography variant="h4" display="inline">
            Preview
          </Typography>
        </Box>
      </Grid>
      <ButtonPreviewWrapper font={searchSettings?.searchSettingFont?.font}>
        <SearchPreview
          searchSettingFont={searchSettings?.searchSettingFont}
          activeIcon={activeIcon}
          color={searchSettings.searchSettingFont?.color}
          searchTitle={searchSettings.searchTitle}
        />
      </ButtonPreviewWrapper>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box mt={6}>
            <Divider />
          </Box>
          <Typography variant="h2" className="uppercase">
            Product Search configuration
          </Typography>
          <Typography variant="h6">
            The search enables users ability to look for certain products within spheres.
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h4" display="inline">
            Select spheres where search works
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <RadioGroupComponent
            items={[SEARCH_SETTINGS_VALUES.default, SEARCH_SETTINGS_VALUES.custom]}
            groupValue={settingsRadioValue}
            setGroupValue={setRadioGroupValue}
          />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="caption" display="inline">
            Exclude spheres from the search result
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <ToggleButtonGroupComponent
            items={searchSettings.allowedExcludePlanogramVersion}
            groupValues={includedPlanogramIds}
            setGroupValues={setIncludedPlanogramIds}
          />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h4" display="inline">
            Which field to search in
          </Typography>
        </Grid>
        <Grid item sm={12}>
          {getAttrsWithNames(searchSettings.allowedSearchAttributes)?.map(({ id, name }) => (
            <Box key={id}>
              <Checkbox
                color="primary"
                checked={includes(searchSettings.searchAttributes, id)}
                onChange={onCheckedCheckboxChange}
                value={id}
              />
              {name}
            </Box>
          ))}
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h4" display="inline">
            Sort results by
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <AutocompleteValidatorComponent
            validators={[VALIDATORS_MAP.required]}
            value={searchSettings.searchOrderBy}
            fieldName="searchOrderBy"
            handleChange={onSelectChange}
            selectList={searchSettings.allowedSearchOrderBy}
            itemsNamePath="name"
            label="Select field"
            disableClearable
            disableMinHeight
          />
        </Grid>
        <Grid item sm={6}>
          {searchSettings.searchOrderBy !== SEARCH_SETTINGS_VALUES.defaultSearchOrder.id && (
            <RadioGroup value={searchSettings.searchOrderDir} onChange={onSortRadioChange}>
              <Grid container spacing={2}>
                {searchSettings?.allowedSearchOrderDir?.map(item => (
                  <Grid item sm={6} key={item}>
                    <FormControlLabel
                      value={item}
                      control={<Radio color="primary" size="small" />}
                      label={renderSortOrderLabel(item)}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          )}
        </Grid>
      </Grid>
      <NoSearchResults
        noResultsSettings={noResultsSettings}
        setNoResultsSettings={setNoResultsSettings}
        handleServerError={handleServerError}
      />
    </>
  );
}

SearchSettings.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  handleServerError: PropTypes.func.isRequired,
};
