import { COLORS } from 'theme/constants';

export const BUTTON_TYPE = {
  EBAY: 'ebay',
  AMAZON: 'amazon',
  CUSTOM: 'custom',
  CUSTOM_PIN: 'custom_pin',
  CHECKOUT: 'checkout',
  RAKUTEN: 'rakuten',
};

export const BUTTON_VIEW_PARAMS = {
  minWidth: 0,
  defaultWidth: 1,
  maxWidth: 15,
  defaultHexColor: { hex: COLORS.white },
};

export const FORM_ID = 'buy-button-settings';

export const HAS_CUSTOM_SETTINGS = [
  BUTTON_TYPE.CUSTOM,
  BUTTON_TYPE.RAKUTEN,
  BUTTON_TYPE.CUSTOM_PIN,
  BUTTON_TYPE.CHECKOUT,
];

export const HAS_WEBSIE_SETTINGS = [BUTTON_TYPE.CUSTOM, BUTTON_TYPE.CUSTOM_PIN];

export const PURCHASING_VALUES = {
  buyButton: 'buy-button',
  checkout: 'checkout',
};
export const PURCHASING_TYPES_OPTIONS = [
  {
    value: PURCHASING_VALUES.buyButton,
    title: 'Buy button flow',
    text: 'Buy button will navigate the customer directly to the selected website',
    types: [BUTTON_TYPE.AMAZON, BUTTON_TYPE.EBAY, BUTTON_TYPE.CUSTOM, BUTTON_TYPE.CUSTOM_PIN, BUTTON_TYPE.RAKUTEN],
    withOrder: true,
    withNav: true,
  },
  {
    value: PURCHASING_VALUES.checkout,
    title: 'Affiliate checkout flow',
    text: 'All results from affiliates will be represented in the sphere overlay.',
    types: [BUTTON_TYPE.CHECKOUT],
    withOrder: false,
    withNav: false,
  },
];
