// TODO: move to enum types after typescript refactor complete
export const DOWNLOAD_SECTIONS = {
  logo: 'logo',
  background: 'background',
  favicon: 'favicon',
  controlButton: 'controlButton',
  images: 'images',
  productImages: 'productImages',
  overlayContent: 'overlayContent',
  video: 'video',
  otherAssets: 'otherAssets',
  audio: 'audio',
};

export const UPLOAD_STATUSES = {
  UPLOAD: 'upload',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success',
  SYNC_FAILED: 'sync-failed',
};
