import { PlanogramCloningStatus } from 'types';

export enum PlanogramCloningTypes {
  STORE_PLANOGRAM_ID = 'store_planogram_id',
  STORE_PLANOGRAM_STATUS = 'store_plangoram_status',
  CLEAR_PLANOGRAM_STATE = 'clear_planogram_state',
}

export interface PlanogramCloningState {
  id: number | null;
  planogramDestinationId: number | undefined;
  planogramDestinationGroupId: number | undefined;
  sourceGroupId: number | undefined;
  status: PlanogramCloningStatus | null;
}
