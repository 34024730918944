import React, { useState } from 'react';

import { Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { SelectImage } from '../Dialogs';

import { Image } from '../style';
import { ImageIcon, ImageUploadLabel, ImageUploadWrapper, Typography, RemoveIconContainer, IconButton } from './style';

export function ImageChooseComponent({
  image,
  setImage,
  handleServerError,
  dialogTitle,
  getPictures,
  withDelete,
  withLabel = true,
  placeholderIcon,
  className,
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleToggleDialog = () => setOpenDialog(prevState => !prevState);
  const handleDeleteImage = () => setImage({ id: null, imageName: '', url: '' });

  return (
    <>
      {image?.id ? (
        <ImageUploadWrapper className={className} onClick={handleToggleDialog}>
          <Image src={image.url} />
          {withLabel && <ImageUploadLabel>Replace file</ImageUploadLabel>}
          {withDelete && (
            <RemoveIconContainer>
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  handleDeleteImage();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </RemoveIconContainer>
          )}
        </ImageUploadWrapper>
      ) : (
        <ImageUploadWrapper onClick={handleToggleDialog} className="pointer">
          {placeholderIcon ?? <ImageIcon fontSize="large" />}
          {withLabel && <Typography variant="caption">Choose file</Typography>}
        </ImageUploadWrapper>
      )}
      <SelectImage
        open={openDialog}
        handleClose={handleToggleDialog}
        handleServerError={handleServerError}
        selectedImage={image}
        setImage={setImage}
        title={dialogTitle}
        getPictures={getPictures}
        withDelete={withDelete}
      />
    </>
  );
}
ImageChooseComponent.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  }),
  handleServerError: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  getPictures: PropTypes.func.isRequired,
  withDelete: PropTypes.bool,
  className: PropTypes.string,
  withLabel: PropTypes.bool,
  placeholderIcon: PropTypes.node,
};

ImageChooseComponent.defaultProps = {
  image: { id: null, url: '' },
  withDelete: false,
  className: '',
};
