import { set, includes, get } from 'lodash';

import { DispatchAction } from 'types/common';
import { FileWithUploadData } from 'types/images';
import { findIndexById, hasInProgressUpload, getUploadErrorMessage } from 'utils';

import { UpdateUploadParams, UploadActionTypes, UploadErrorParams, UploadInfo, UploadStatusStateType } from './types';

export type AddUploadAction = DispatchAction<UploadActionTypes.ADD_UPLOAD, UploadInfo>;
export type UpdateUploadAction = DispatchAction<UploadActionTypes.UPDATE_UPLOAD, UpdateUploadParams>;
export type CompleteUploadAction = DispatchAction<UploadActionTypes.COMPLETE_UPLOAD>;
export type ResetAllAction = DispatchAction<UploadActionTypes.RESET_ALL>;
export type HandleUploadErrorAction = DispatchAction<UploadActionTypes.HANDLE_UPLOAD_ERROR, UploadErrorParams>;
export type ResetErrorsAction = DispatchAction<UploadActionTypes.RESET_ERRORS>;
export type ResetUploadStatusesAction = DispatchAction<UploadActionTypes.RESET_UPLOAD_STATUSES>;
export type SetUploadStatusAction = DispatchAction<
  UploadActionTypes.SET_UPLOAD_STATUS,
  { file: FileWithUploadData; status: string }
>;
export type FailUploadAction = DispatchAction<UploadActionTypes.FAIL_UPLOAD, string>;

type ActionTypes =
  | AddUploadAction
  | UpdateUploadAction
  | CompleteUploadAction
  | ResetAllAction
  | HandleUploadErrorAction
  | ResetErrorsAction
  | ResetUploadStatusesAction
  | SetUploadStatusAction
  | FailUploadAction;

export default (state: UploadStatusStateType, action: ActionTypes) => {
  switch (action.type) {
    case UploadActionTypes.ADD_UPLOAD: {
      return { ...state, uploads: [...state.uploads, action.payload] };
    }

    case UploadActionTypes.UPDATE_UPLOAD: {
      const uploadIndex = findIndexById(state.uploads, action.payload.id);

      if (includes(state.delayResetIds, action.payload.id)) {
        action.payload.request.abort();

        return {
          ...state,
          delayResetIds: state.delayResetIds.filter(id => id !== action.payload.id),
        };
      }
      const uploads = [
        ...set(state.uploads, [uploadIndex], {
          ...state.uploads[uploadIndex],
          ...action.payload,
        }),
      ];

      return {
        ...state,
        uploads,
      };
    }

    case UploadActionTypes.COMPLETE_UPLOAD: {
      return {
        ...state,
        uploads: hasInProgressUpload(state.uploads) ? state.uploads : [],
      };
    }

    case UploadActionTypes.FAIL_UPLOAD: {
      return {
        ...state,
        uploads: state.uploads.filter(({ id }) => id !== action.payload),
      };
    }

    case UploadActionTypes.RESET_ALL: {
      const resetIds: string[] = [];

      state.uploads.forEach(({ loaded, total, request, id }) => {
        if (request && loaded !== total) {
          request.abort();
        } else {
          resetIds.push(id);
        }
      });
      return {
        ...state,
        uploads: [],
        delayResetIds: [...state.delayResetIds, ...resetIds],
      };
    }
    case UploadActionTypes.HANDLE_UPLOAD_ERROR: {
      const { error, file, section } = action.payload;

      return {
        ...state,
        errors: {
          [section]: [...get(state.errors, section, []), getUploadErrorMessage({ error, file })],
        },
      };
    }
    case UploadActionTypes.RESET_ERRORS: {
      return {
        ...state,
        errors: {},
      };
    }
    case UploadActionTypes.RESET_UPLOAD_STATUSES: {
      return {
        ...state,
        requestStatuses: new Map(),
      };
    }
    case UploadActionTypes.SET_UPLOAD_STATUS: {
      return {
        ...state,
        requestStatuses: new Map([...Array.from(state.requestStatuses), [action.payload.file, action.payload.status]]),
      };
    }
    default: {
      return state;
    }
  }
};
