import {
  InviteParams,
  SignUpWithInviteParams,
  SignUpWithInviteResponse,
  SocialSignUpWithInviteParams,
  SocialSignUpWithInviteResponse,
  VerifyTokenParams,
  VerifyTokenResponse,
} from 'types/invites';

import { api } from './setupApi';

class InvitesModel {
  inviteLink = '/invites/send';

  verifyTokenLink = 'invites/verify_invite_token';

  signUpWithInviteLink = 'invites/sign_up';

  socialSignUpWithInviteLink = '/auth/omniauth/invite_sign_up';

  invite = (dataToSend: InviteParams) => api.post(this.inviteLink, dataToSend).then(({ data }) => data);

  verifyToken = (dataToSend: VerifyTokenParams) =>
    api.post<VerifyTokenResponse>(this.verifyTokenLink, dataToSend).then(({ data }) => data);

  signUpWithInvite = (dataToSend: SignUpWithInviteParams) =>
    api.post<SignUpWithInviteResponse>(this.signUpWithInviteLink, dataToSend).then(({ data }) => data);

  socialSignUpWithInvite = (dataToSend: SocialSignUpWithInviteParams) =>
    api.post<SocialSignUpWithInviteResponse>(this.socialSignUpWithInviteLink, dataToSend).then(({ data }) => data);
}

export const invitesModel = new InvitesModel();
