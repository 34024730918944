import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { EcommerceOverlaySettingsForm } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { planogramsModel } from 'models';

import { Switch } from 'components/style';

export function EcommerceOverlaySphereSettings({
  pageLanguageTabValue,
  overlaySettings,
  planogramId,
  planogramVersionId,
  onToggleSettingCard,
  overlayType,
  setSuccessActionMessages,
}) {
  const [settings, setSettings] = useState({});
  const [enabled, setEnabled] = useState(false);
  const { handleServerError } = useServerErrorActions();

  useEffect(() => {
    if (!isEmpty(overlaySettings)) {
      setSettings(overlaySettings);
      setEnabled(Boolean(overlaySettings.id));
    }
  }, [overlaySettings]);

  const handleSaveSettings = newSettings => {
    planogramsModel
      .updateEcommerceSettings(newSettings, planogramId, planogramVersionId)
      .then(({ ecommerceOverlaySetting, success }) => {
        setSettings(ecommerceOverlaySetting);
        setSuccessActionMessages(success);
      })
      .catch(handleServerError);
  };

  const onSwitchChange = () =>
    onToggleSettingCard(!enabled, settings.id, overlayType, () => setEnabled(prev => !prev)).then(data =>
      setSettings(data ? data.ecommerceOverlaySetting : {}),
    );

  const renderHeaderNav = () => <Switch checked={enabled} onChange={onSwitchChange} />;

  return (
    <EcommerceOverlaySettingsForm
      overlaySettings={settings}
      enabled={enabled}
      onSave={handleSaveSettings}
      pageLanguageTabValue={pageLanguageTabValue}
      renderHeaderNav={renderHeaderNav}
      overlayType={overlayType}
    />
  );
}

EcommerceOverlaySphereSettings.propTypes = {
  pageLanguageTabValue: PropTypes.number.isRequired,
  planogramId: PropTypes.string.isRequired,
  planogramVersionId: PropTypes.number.isRequired,
  overlaySettings: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onToggleSettingCard: PropTypes.func.isRequired,
  overlayType: PropTypes.string.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
