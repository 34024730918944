import React, { useEffect, useRef, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link, Divider, Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { pick } from 'lodash';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'components';
import { LINKS } from 'components/constants';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import config from 'config/Config';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { authModel } from 'models';
import { AUTH_HEADERS } from 'models/constants';
import { ROUTES } from 'routes/constants';
import { getQueryParams } from 'utils';

import {
  StyledButtonComponent,
  TermsAndConditionsText,
  RedirectText,
  SmallInfoText,
  AuthCard,
  TextValidator,
  FormLink,
} from '../style';

const GoogleSignUpPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleServerError } = useServerErrorActions();
  const captchaRef = useRef();

  const [state, setState] = useState({
    [authModel.fields.space]: '',
    [authModel.fields.firstName]: '',
    [authModel.fields.lastName]: '',
    [authModel.fields.email]: '',
    [authModel.fields.terms]: false,
    [authModel.fields.token]: getQueryParams().pre_auth_token,
  });

  useEffect(() => {
    const preAuthToken = state[authModel.fields.token];

    if (preAuthToken) {
      const decodedToken = jwtDecode(preAuthToken);

      setState(prevState => ({
        ...prevState,
        [authModel.fields.firstName]: decodedToken.first_name,
        [authModel.fields.lastName]: decodedToken.last_name,
        [authModel.fields.email]: decodedToken.email,
      }));
    } else history.push(ROUTES.authSignIn);
  }, []);

  const executeCaptcha = () => {
    captchaRef.current.execute();
  };

  const submitForm = captchaToken => {
    authModel
      .socialSignUp({
        [authModel.fields.hCaptcha]: captchaToken,
        ...pick(state, [
          authModel.fields.space,
          authModel.fields.firstName,
          authModel.fields.lastName,
          authModel.fields.token,
        ]),
      })
      .then(data => {
        if (data.pubtoolToken) {
          localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, data.pubtoolToken);
          history.push(ROUTES.home);
        }
      })
      .catch(handleServerError);
  };

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const handleTermsChange = () => {
    handleChange({ [authModel.fields.terms]: !state[authModel.fields.terms] });
  };

  const onBackToSignUp = () => history.push(ROUTES.authSignUp);

  const disableSignUpButton = Object.values(state).some(item => !item);

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.googleSignUpPage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.googleSignUpPage.title')}</Typography>
          <SmallInfoText>{t('pages.auth.googleSignUpPage.subTitle')}</SmallInfoText>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={executeCaptcha} style={{ width: '100%' }}>
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
              label={t('common.forms.labels.email')}
              value={state[authModel.fields.email]}
              fieldName={authModel.fields.email}
              type="email"
              disabled
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.firstName')}
              value={state[authModel.fields.firstName]}
              fieldName={authModel.fields.firstName}
              handleChange={handleChange}
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.lastName')}
              value={state[authModel.fields.lastName]}
              fieldName={authModel.fields.lastName}
              handleChange={handleChange}
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.spaceName]}
              label={t('common.forms.labels.spaceName')}
              value={state[authModel.fields.space]}
              fieldName={authModel.fields.space}
              handleChange={handleChange}
              autoComplete="on"
              helperText={t('pages.auth.signUpPage.spaceNameInfo')}
              inputProps={{
                autoCapitalize: 'none',
              }}
              autoFocus
            />
            <Grid container direction="column" spacing={6}>
              <Grid item container alignItems="center" wrap="nowrap">
                <FormControlLabel
                  style={{
                    border: 'none',
                    paddingTop: '20px',
                  }}
                  control={
                    <Checkbox color="primary" checked={state[authModel.fields.terms]} onChange={handleTermsChange} />
                  }
                  label={
                    <TermsAndConditionsText>
                      {`${t('pages.auth.common.termsAndConditionsCheckbox')}`}
                      <Link href={LINKS.terms}>{t('pages.auth.common.terms')}</Link>
                      {` ${t('pages.auth.common.and')} `}
                      <Link href={LINKS.privacyPolicy}>{t('pages.auth.common.privacy')}</Link>
                    </TermsAndConditionsText>
                  }
                />
              </Grid>
              <Grid item>
                <StyledButtonComponent
                  fullWidth
                  color="primary"
                  type="submit"
                  text={t('pages.auth.signUpPage.emailSignUpBtn')}
                  disabled={disableSignUpButton}
                />
                <HCaptcha onVerify={submitForm} sitekey={config.captcha_key} ref={captchaRef} size="invisible" />
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <RedirectText>
            {`${t('pages.auth.googleSignUpPage.backToSignUpText')} `}
            <FormLink
              variant="text"
              onClick={onBackToSignUp}
              className="no-padding"
              text={t('pages.auth.googleSignUpPage.backToSignUpLink')}
              color="primary"
            />
          </RedirectText>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default GoogleSignUpPage;
