import React, { useEffect, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DefaultLandingMessage, TableComponent } from 'components';
import { LINKS } from 'components/constants';
import { AddAdminDialog, ConfirmationDialog } from 'components/Dialogs';
import { InviteAdminDialog } from 'components/Dialogs/InviteAdminDialog/InviteAdminDialog';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { useBusyProgressActions, useBusyProgressState } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { adminsModel, clientsModel } from 'models';
import { ROUTES, ROLES } from 'routes/constants';
import { AdminInfo, ClientInfo } from 'types';
import { ActionType, Role } from 'types/enums';
import { TableData, TableParams } from 'types/other';
import { getFullName } from 'utils';
import { formatSSOProviderType } from 'utils/helpers/stringHelpers';
import TEST_IDS from 'constants/testIds';

enum DialogType {
  ADD,
  INVITE,
  SWITCH,
  DELETE,
}

interface Dialog {
  type: DialogType;
  admin?: AdminInfo;
}

interface ManageUsersProps {
  adminRole: Role.CLIENT_ADMIN | Role.CLIENT_MODERATOR;
}

function ManageAdmins({ adminRole }: ManageUsersProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = useAuthState();
  const { updateUserData } = useAuthActions();
  const { isBusy } = useBusyProgressState();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError, resetServerErrors } = useServerErrorActions();

  const [dialog, setDialog] = useState<Dialog>();
  const [admins, setAdmins] = useState<AdminInfo[]>([]);
  const [clients, setClients] = useState<ClientInfo[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({ ...DEFAULT_TABLE_PARAMS });
  const [paginationItemsCount, setPaginationItemsCount] = useState(0);

  useEffect(() => {
    getAdmins();
  }, [tableParams]);

  useEffect(() => {
    if (isSysAdmin) getClients();
  }, []);

  const isSysAdmin = currentUser.attributes.role === ROLES.systemAdmin;

  const getAdmins = withPageProgressHandler(() => {
    const { filterId, search, ...rest } = tableParams;

    const names = search ? search.split(' ') : [];
    const firstName = names[0];
    const lastName = names[1] || firstName;

    return adminsModel
      .getAdmins({
        ...rest,
        role: adminRole,
        ...{ firstName, lastName },
      })
      .then(data => {
        setAdmins(data.admins);
        setPaginationItemsCount(data.adminsCount);
      })
      .catch(handleServerError);
  });

  const getClients = () =>
    clientsModel
      .getClients({ perPage: 'all' })
      .then(data => setClients(data.clients))
      .catch(handleServerError);

  const handleRedirect = (admin: AdminInfo) => {
    const nameSpace =
      adminRole === Role.CLIENT_MODERATOR ? ROUTES.editClientModeratorNamespace : ROUTES.editClientAdminNamespace;

    history.push(`${nameSpace}${admin.id}`);
  };

  const handleBlock = withPageProgressHandler((admin: AdminInfo) =>
    adminsModel.blockAdmin(admin.id, !admin.enabled).then(getAdmins).catch(handleServerError)
  );

  const handleSwitch = ({ id }: AdminInfo) =>
    adminsModel
      .switchAdmin(id)
      .then(data => {
        updateUserData(data);
        history.push(ROUTES.home);
      })
      .catch(handleServerError);

  const handleDelete = withPageProgressHandler(({ id }) => {
    setDialog(undefined);
    return adminsModel.deleteAdmin(id).then(getAdmins).catch(handleServerError);
  });

  const afterActions = () => setTableParams({ ...tableParams, page: 0 });

  const openDialog = (type: DialogType, admin?: AdminInfo) => setDialog({ type, admin });

  const tableData = useMemo(
    () =>
      ({
        headers: [
          t('components.tableComponent.headers.name'),
          t('components.tableComponent.headers.space'),
          t('components.tableComponent.headers.spheres'),
          t('components.tableComponent.headers.status'),
          t('components.tableComponent.headers.sso'),
          t('components.tableComponent.headers.updated'),
        ],
        rows: admins.map(admin => ({
          id: admin.id,
          cells: [
            getFullName(admin),
            admin.client.name,
            admin.planogramsQuantity,
            admin.enabled ? 'Active' : 'Blocked',
            formatSSOProviderType(admin.provider),
            admin.updatedAt,
          ],
          onClick: () => handleRedirect(admin),
          actions: [
            isSysAdmin
              ? {
                  type: admin.enabled ? ActionType.SWITCH : ActionType.NONE,
                  onClick: () => openDialog(DialogType.SWITCH, admin),
                }
              : undefined,
            {
              type: admin.enabled ? ActionType.BLOCK : ActionType.UNBLOCK,
              onClick: () => handleBlock(admin),
              testId: TEST_IDS.manageAdmins.list.buttons.toggleEnabled,
            },
            {
              type: ActionType.EDIT,
              onClick: () => handleRedirect(admin),
              testId: TEST_IDS.manageAdmins.list.buttons.edit,
            },
            {
              type: ActionType.DELETE,
              onClick: () => openDialog(DialogType.DELETE, admin),
              testId: TEST_IDS.manageAdmins.list.buttons.delete,
            },
          ].filter(Boolean),
        })),
      } as TableData),
    [admins],
  );

  const getButtonText = () => {
    if (isSysAdmin && adminRole === Role.CLIENT_ADMIN) return t('pages.admins.manageAdmins.addNewClientAdmin');
    if (isSysAdmin && adminRole === Role.CLIENT_MODERATOR) return t('pages.moderators.addNewModerator');
    if (adminRole === Role.CLIENT_ADMIN) return t('pages.admins.manageAdmins.invite');
    return t('pages.moderators.invite');
  };

  const title = adminRole === Role.CLIENT_ADMIN ? t('pages.admins.manageAdmins.title') : t('pages.moderators.title');

  return (
    <PageWithHeader
      tabTitle={title}
      headerText={`${title}: ${paginationItemsCount}`}
      button={{
        text: getButtonText(),
        onClick: () => openDialog(isSysAdmin ? DialogType.ADD : DialogType.INVITE),
      }}
    >
      <TableComponent
        data={tableData}
        count={paginationItemsCount}
        tableParams={tableParams}
        onParamsChange={params => setTableParams(prev => ({ ...prev, ...params }))}
        enableSearch
      />
      {!isBusy && paginationItemsCount === 0 && (
        <DefaultLandingMessage
          title={
            adminRole === Role.CLIENT_ADMIN
              ? t('pages.admins.manageAdmins.defaultLandingTitle')
              : t('pages.moderators.defaultLandingTitle')
          }
          body={
            adminRole === Role.CLIENT_ADMIN
              ? t('pages.admins.manageAdmins.defaultLandingMessage')
              : t('pages.moderators.defaultLandingMessage')
          }
          bodyLink={LINKS.userManagementKnowledgeBase}
        />
      )}
      {dialog?.type === DialogType.ADD && (
        <AddAdminDialog
          open
          handleClose={() => setDialog(undefined)}
          handleServerError={handleServerError}
          clients={clients}
          creatingAdminRole={adminRole}
          afterActions={afterActions}
          resetServerErrors={resetServerErrors}
        />
      )}
      {dialog?.type === DialogType.INVITE && (
        <InviteAdminDialog
          handleClose={() => setDialog(undefined)}
          role={adminRole}
          title={adminRole === Role.CLIENT_ADMIN ? t('pages.admins.manageAdmins.invite') : t('pages.moderators.invite')}
        />
      )}
      {dialog?.type === DialogType.SWITCH && dialog.admin && (
        <ConfirmationDialog
          open
          message={`${t('components.dialogs.common.switchTo')} ${dialog.admin.email}?`}
          handleYes={() => handleSwitch(dialog.admin!)}
          handleNo={() => setDialog(undefined)}
          confirmText={t('components.dialogs.common.switch')}
        />
      )}
      {dialog?.type === DialogType.DELETE && dialog.admin && (
        <ConfirmationDialog
          open
          message={
            adminRole === Role.CLIENT_ADMIN
              ? t('pages.admins.manageAdmins.confirmDelete')
              : t('pages.moderators.confirmDelete')
          }
          handleYes={() => handleDelete(dialog.admin)}
          handleNo={() => setDialog(undefined)}
        />
      )}
    </PageWithHeader>
  );
}

export default ManageAdmins;
