import React from 'react';

import { Grid } from '@material-ui/core';

import { STATUS_TEXT_MAP } from 'components/CustomDomains/constants';
import { CustomDomainVerificationStatus } from 'types/enums';

import { ButtonWrapper, RecordTable, RecordTitle, TitleWrapper, CopyToClipboard, DomainStatusBadge } from '../../style';

interface TableRecord {
  item: string;
  value?: string;
}

export interface RecordsTableProps {
  title: string;
  status?: CustomDomainVerificationStatus;
  records?: TableRecord[];
  showRecords?: boolean;
}

export const RecordsTable = ({ title, status, records = [], showRecords = true }: RecordsTableProps) => (
  <Grid item container spacing={4}>
    <Grid item container>
      <TitleWrapper>
        <RecordTitle>{title}</RecordTitle>
      </TitleWrapper>
      {status && (
        <DomainStatusBadge disabled variant="contained" status={status}>
          {STATUS_TEXT_MAP[status]}
        </DomainStatusBadge>
      )}
    </Grid>
    {records.length > 0 && (
      <Grid item xs>
        <RecordTable>
          <tbody>
            {showRecords &&
              records.map(record => (
                <tr key={record.value}>
                  <td>{record.item}</td>
                  <td>{record.value}</td>
                  <td>
                    <ButtonWrapper>
                      <CopyToClipboard text={record.value || ''} />
                    </ButtonWrapper>
                  </td>
                </tr>
              ))}
          </tbody>
        </RecordTable>
      </Grid>
    )}
  </Grid>
);
