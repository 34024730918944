import { Product, ProductAttributes, ProductsGetParams, ProductsListResponse } from 'types/product';
import { trimMultilanguageItems } from 'utils';

import { api } from './setupApi';

class ProductModel {
  link = '/products';

  productAttributes = `${this.link}/product_attributes`;

  newProductLink = `${this.link}/new_product`;

  getProducts = (params: ProductsGetParams): Promise<ProductsListResponse> =>
    api.get<ProductsListResponse>(this.link, { params }).then(({ data }) => data);

  getProduct = (id: number): Promise<Product> => api.get<Product>(`${this.link}/${id}`).then(({ data }) => data);

  getProductAttributes = (): Promise<ProductAttributes> =>
    api.get<ProductAttributes>(this.productAttributes).then(({ data }) => data);

  getNewProduct = (): Promise<Product> => api.get<Product>(this.newProductLink).then(({ data }) => data);

  addProduct = (data: Partial<Product>) => api.post(this.link, trimMultilanguageItems(data, ['name']));

  editProduct = (id: number, data: Partial<Product>) =>
    api.put(`${this.link}/${id}`, trimMultilanguageItems(data, ['name']));

  deleteProduct = (id: number) => api.delete(`${this.link}/${id}`);
}

export const productModel = new ProductModel();
