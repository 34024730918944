import React from 'react';

import Menu from '@material-ui/core/Menu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { MenuItem, IconButton } from './style';

export function ButtonMenu({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="button-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        variant="contained"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu id="button-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {React.Children.map(children, child => (
          <MenuItem key={get(child, 'props.text')} onClick={handleClose}>
            {child}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ButtonMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]).isRequired,
};
