module.exports = {
  app_env: process.env.REACT_APP_STAGE || 'dev',
  api_host: process.env.REACT_APP_API_HOST || 'http://localhost:3001/api/v1',
  frontend_host: process.env.REACT_APP_FRONTEND_HOST || 'http://localhost:3000',
  cdn_host: process.env.REACT_APP_CDN_HOST || 'https://d23cvv0osjt5ft.cloudfront.net',
  proxy_api_url: process.env.REACT_APP_PROXY_API_URL,
  auth_username: process.env.REACT_APP_AUTH_USERNAME,
  auth_password: process.env.REACT_APP_AUTH_PASSWORD,
  captcha_key: process.env.REACT_APP_CAPTCHA_KEY,
  s3_bucket_root: process.env.REACT_APP_S3_BUCKET_ROOT || 'test_images',
  fe_build_number: process.env.REACT_APP_FE_BUILD_NUMBER || 'there is no fe build number in dev mode',
  be_build_number: process.env.REACT_APP_BE_BUILD_NUMBER || 'there is no be build number in dev mode',
  jenkins_fe_build_number:
    process.env.REACT_APP_JENKINS_FE_BUILD_NUMBER || 'there is no jenkins fe build number in dev mode',
  jenkins_be_build_number:
    process.env.REACT_APP_JENKINS_BE_BUILD_NUMBER || 'there is no jenkins be build number in dev mode',
  subscription_table_id: process.env.REACT_APP_SUBSCRIPTION_TABLE_ID,
  stripe_public_key: process.env.REACT_APP_STRIPE_PUB_KEY,
  stripe_portal_url: process.env.REACT_APP_STRIPE_PORTAL,
};
