import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ButtonComponent, ColoredBox, ItemWithData, LoadFileComponent } from 'components';
import { activeStorageModel, planogramsModel } from 'models';
import { downloadFile } from 'utils';

import { Typography, Box } from './style';

export function LegacyAdditionalFields({
  planogramId,
  planogramName,
  planogramVersion,
  isLegacy,
  handleServerError,
  updatePlanogram,
}) {
  const [isLoadingPNG, setIsLoadingPNG] = useState(false);
  const [isLoadingSVG, setIsLoadingSVG] = useState(false);

  const handleSetPNGFile = file => {
    if (file === '') {
      handleDetachPNG();
    } else {
      setIsLoadingPNG(true);
      activeStorageModel.createUpload(file).create((error, blob) => {
        if (error) {
          handleServerError(error);
          setIsLoadingPNG(false);
        } else {
          handleEditPlanogramVersion({ planogramVersion: { signedId: blob.signed_id } }, setIsLoadingPNG);
        }
      });
    }
  };

  const handleSetSVGFile = svgData => {
    if (svgData) {
      setIsLoadingSVG(true);
    }
    handleEditPlanogramVersion({ planogramVersion: { svgData } }, setIsLoadingSVG);
  };

  const handleDownloadPNG = () =>
    planogramsModel.getDownloadPNGLink(planogramId, planogramVersion.id).then(downloadFile).catch(handleServerError);

  const handleDownloadSVG = () =>
    planogramsModel
      .getDownloadSVGFile(planogramId, planogramVersion.id)
      .then(data => downloadFile(window.URL.createObjectURL(data), `${planogramName}.svg`))
      .catch(handleServerError);

  const handleRevalidate = () => handleSetSVGFile(planogramVersion.svgData);

  const handleEditPlanogramVersion = (data, setLoading) =>
    planogramsModel
      .editVersion(planogramId, planogramVersion.id, data)
      .then(() => {
        updatePlanogram();
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleServerError(error);
      });

  const handleDetachPNG = () =>
    planogramsModel
      .detachPNG(planogramId, planogramVersion.id)
      .then(() => updatePlanogram())
      .catch(error => handleServerError(error));

  return (
    planogramVersion &&
    isLegacy && (
      <>
        <ItemWithData title="PNG">
          <LoadFileComponent
            accept=".png"
            text="Import PNG"
            file={planogramVersion.imageUrl}
            loadFile={handleSetPNGFile}
            handleServerError={handleServerError}
            isLoading={isLoadingPNG}
          />
          {planogramVersion.imageUrl && (
            <ButtonComponent
              variant="text"
              text="Download PNG"
              className="blue no-padding"
              onClick={handleDownloadPNG}
            />
          )}
        </ItemWithData>
        <ItemWithData title="SVG">
          <LoadFileComponent
            accept=".svg"
            text="Import SVG"
            file={planogramVersion.svgData}
            loadFile={handleSetSVGFile}
            handleServerError={handleServerError}
            validationError={planogramVersion.validationError}
            isLoading={isLoadingSVG}
            loadAsText
          />
          {planogramVersion.svgData && (
            <ButtonComponent
              variant="text"
              text="Download SVG"
              className="blue no-padding"
              onClick={handleDownloadSVG}
            />
          )}
          {planogramVersion.svgData && (
            <ButtonComponent className="ml-4" text="Revalidate" disabled={isLoadingSVG} onClick={handleRevalidate} />
          )}
          {planogramVersion.svgData && planogramVersion.validationError && (
            <Box>
              <ColoredBox variant="error">
                <Typography>{planogramVersion.validationError}</Typography>
              </ColoredBox>
            </Box>
          )}
        </ItemWithData>
      </>
    )
  );
}

LegacyAdditionalFields.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    svgData: PropTypes.string,
    validationError: PropTypes.string,
  }),
  planogramId: PropTypes.number.isRequired,
  planogramName: PropTypes.string.isRequired,
  isLegacy: PropTypes.bool,
  handleServerError: PropTypes.func.isRequired,
  updatePlanogram: PropTypes.func.isRequired,
};

LegacyAdditionalFields.defaultProps = { isLegacy: false, planogramVersion: null };
