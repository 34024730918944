import React, { useEffect, useState } from 'react';

import { ButtonComponent, CardComponent } from 'components';
import { ChangeThemeFieldsDialog } from 'components/Dialogs';
import { AutocompleteValidatorComponent } from 'components/FormComponents';
import { HtmlTemplate, HtmlTemplateInput } from 'types';
import { getTWithPathKey } from 'utils';

interface Props {
  selectedHtmlTemplate: number | null;
  htmlTemplates: HtmlTemplate[];
  setSelectedTemplate: (templateId: number) => void;
  onTemplateChange: (id: number, inputFields: HtmlTemplateInput[]) => Promise<void>;
}

const ProductTheme = ({ selectedHtmlTemplate, htmlTemplates, setSelectedTemplate, onTemplateChange }: Props) => {
  const [htmlTemplate, setHtmlTemplate] = useState<HtmlTemplate | null>(null);
  const [open, setOpen] = useState(false);
  const getT = getTWithPathKey('pages.products.manageProduct.productTheme');

  useEffect(() => {
    if (selectedHtmlTemplate) {
      setHtmlTemplate(htmlTemplates.find(i => i.id === selectedHtmlTemplate)!);
    }
  }, [selectedHtmlTemplate, htmlTemplates]);

  const handleChange = ({ templateId }: Record<string, number>) => {
    setSelectedTemplate(templateId);
  };

  const getOptionSelected = (option: HtmlTemplate, value: number) => option.id === value;

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isButtonShow = htmlTemplate && htmlTemplate?.role !== 'default_theme';

  const saveChangedFields = async (inputFields: HtmlTemplateInput[]) => {
    if (htmlTemplate) {
      await onTemplateChange(htmlTemplate.id, inputFields);
    }
  };

  return (
    <>
      <CardComponent title={getT('title')} sm={12}>
        <AutocompleteValidatorComponent
          selectList={htmlTemplates}
          value={selectedHtmlTemplate}
          label={getT('select')}
          fieldName="templateId"
          disableClearable
          highlightSelected
          disabled={!htmlTemplates.length}
          handleChange={handleChange}
          itemsNamePath="roleHumanize"
          getOptionSelected={getOptionSelected}
          multiple={false}
          inputClassName="theme-select"
        />
        {isButtonShow && <ButtonComponent text={getT('edit')} onClick={openModal} />}
      </CardComponent>
      {htmlTemplate && open && (
        <ChangeThemeFieldsDialog
          key={htmlTemplate.id}
          open={open}
          onClose={closeModal}
          fields={htmlTemplate.inputFields}
          templateName={htmlTemplate.roleHumanize}
          onSave={saveChangedFields}
        />
      )}
    </>
  );
};

export default ProductTheme;
