import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useSubmittingState } from 'hooks';
import { AuthModelFields } from 'models/AuthModel';
import { invitesModel } from 'models/InvitesModel';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface InviteAdminDialogProps {
  handleClose: () => void;
  title: string;
  role: string;
}

export function InviteAdminDialog({ handleClose, title, role }: InviteAdminDialogProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState('');
  const { handleServerError } = useServerErrorActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const { t } = useTranslation();

  const handleSubmit = () =>
    invitesModel
      .invite({ email, role })
      .then(() => {
        setShowConfirmation(true);
      })
      .catch(handleServerError);

  const handleChange = (value: { [AuthModelFields.EMAIL]: string }) => setEmail(value[AuthModelFields.EMAIL]);

  if (showConfirmation)
    return (
      <Dialog fullWidth open onClose={handleClose}>
        <DialogTitle>{t('components.dialogs.invite.confirmationTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingLeft: 0 }}>
            {t('components.dialogs.invite.confirmationText', { email })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent text={t('components.dialogs.invite.close')} onClick={handleClose} />
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <ValidatorForm onSubmit={() => withHandlingSubmittingState(handleSubmit)()} autoComplete="off">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextValidatorComponent
            validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
            label="Email"
            value={email}
            fieldName={AuthModelFields.EMAIL}
            type="email"
            handleChange={handleChange}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <ButtonComponent text={t('components.dialogs.invite.cancel')} onClick={handleClose} />
          <ButtonComponent
            text={t('components.dialogs.invite.send')}
            type="submit"
            color="primary"
            loaderSize="1.35em"
            loading={isSubmitting}
          />
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}
