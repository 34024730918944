import React, { ChangeEvent } from 'react';

import { Box, Checkbox, Grid, Typography } from '@material-ui/core';

import { IFrameColorComponentProps } from 'components/IFrameColorComponent/IFrameColorComponent';
import { COLORS } from 'theme/constants';
import { getTWithPathKey } from 'utils';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { Subtext } from './style';

export const IFrameScrollbarComponent = ({
  primaryColorKey,
  primaryColorValue,
  secondaryColorKey,
  secondaryColorValue,
  onColorChange,
}: IFrameColorComponentProps) => {
  const getT = getTWithPathKey('components.iframeScrollbarComponent');

  const handleCheckedColorChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    onColorChange(secondaryColorKey)(checked ? { hex: '' } : { hex: COLORS.white });

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Box mb={4}>
          <Typography variant="h4" display="inline">Overlay content scroll bar</Typography>
        </Box>
        <Box my={6}>
          <Subtext>{getT('subtitle')}</Subtext>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          <ColorPicker
            setColor={onColorChange(primaryColorKey)}
            color={{ hex: primaryColorValue }}
          />
          <Box ml={2}>
            <Typography variant="caption">{getT('primaryColorHelperText')}</Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          <ColorPicker
            setColor={onColorChange(secondaryColorKey)}
            color={{ hex: secondaryColorValue }}
          />
          <Box ml={2}>
            <Typography variant="caption">{getT('secondaryColorHelpText')}</Typography>
          </Box>
        </Box>
        <Box ml={7}>
          <Typography variant="caption">
            <Checkbox color="primary" checked={!secondaryColorValue} onChange={handleCheckedColorChange} />
            <span>{getT('checkboxHelpText')}</span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
