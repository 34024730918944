import React, { useEffect, useState } from 'react';

import { Grid, Box, Typography, Divider } from '@material-ui/core';
import { VisibilityOff as VisibilityOffIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { get, merge } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import {
  ButtonComponent,
  ButtonMenu,
  CardComponent,
  CustomDomains,
  InternalSubdomains,
  PageHeader,
  PageHeaderNav,
} from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { clientsModel } from 'models';
import { PlanogramInfo } from 'pages/admins/settings';
import { ROLES, ROUTES } from 'routes/constants';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { ConfirmationDialog } from 'components/Dialogs';
import { Subtext } from 'pages/settings/AnalyticsProviders/GoogleTagManager/style';

import AdminsBlock from './AdminsBlock/AdminsBlock';

import { GridWrapper } from './style';

const adminsBlockPaginationParams = {
  page: 0,
  perPage: 10,
};

export default function EditClient({
  match: {
    params: { id },
  },
}) {
  const [client, setClient] = useState({});
  const [title, setTitle] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [planogramsInfo, setPlanogramsInfo] = useState({});
  const [matomoSettings, setMatomoSettings] = useState({});
  const [initialMatomoSettings, setInitialMatomoSettings] = useState({});
  const [customDomains, setCustomDomains] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getClient();
  }, [id]);

  useEffect(() => {
    setMatomoSettings(prev => ({
      ...prev,
      matomoSiteIds: merge(
        customDomains.map(domain => ({
          [domain.id]: ''
        })),
        client.matomoSiteIds
      )
    }));
    setInitialMatomoSettings(prev => ({
      ...prev,
      matomoSiteIds:  merge(
        customDomains.map(domain => ({
          [domain.id]: ''
        })),
        client.matomoSiteIds
      )
    }));
  }, [client, customDomains]);

  const getClient = withPageProgressHandler(() =>
    clientsModel
      .getClient(id)
      .then(data => {
        setClient(data);
        setMatomoSettings(prev => ({
          ...prev,
          matomoUrl: data.matomoUrl
        }));
        setInitialMatomoSettings(prev => ({
          ...prev,
          matomoUrl: data.matomoUrl
        }));
        setTitle(data.name);
        setPlanogramsInfo(get(data, 'planogramsInfo', {}));
      })
      .catch(handleServerError)
  );

  const handleSave = withPageProgressHandler(async ({ data= {} }) => {
    await clientsModel.editClient(id, { ...matomoSettings, ...data });
    getClient();
  });

  const handleChange = item =>
    setMatomoSettings(prev => ({
      ...prev,
      ...item
    }));

  const handleSiteIdChange = index => value => {
    setMatomoSettings(prev => ({
      ...prev,
      matomoSiteIds: prev.matomoSiteIds?.map((item, i) => (
        index === i ?
          { ...item, ...value } :
          { ...item }
      ))
    }));
  };

  const handleDeleteMatomo = () => {
    setShowDialog(false);
    const defaultValues = {
      matomoSiteIds: [],
      matomoUrl: ''
    };

    handleSave({ data: defaultValues });
  }

  const handleRedirect = () => history.push(ROUTES.manageClients);

  const handleDelete = withPageProgressHandler(() =>
    clientsModel.deleteClient(id).then(handleRedirect).catch(handleServerError),
  );

  return (
    <ValidatorForm onSubmit={handleSave}>
      <PageHeader backLink={ROUTES.manageClients} title={title}>
        <PageHeaderNav cancelLink={ROUTES.manageClients} withSubmit>
          <ButtonMenu>
            <ButtonComponent
              text={t('pages.clients.editClient.disableClient')}
              Icon={VisibilityOffIcon}
              className="flex-start"
              fullWidth
              disabled
            />
            <ButtonComponent
              text={t('pages.clients.editClient.delete')}
              onClick={handleDelete}
              Icon={DeleteIcon}
              className="flex-start"
              fullWidth
            />
          </ButtonMenu>
        </PageHeaderNav>
      </PageHeader>
      <GridWrapper container>
        <Grid item>
          <ButtonComponent text={t('pages.clients.editClient.active')} disabled />
        </Grid>
      </GridWrapper>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          <CardComponent title={t('pages.clients.editClient.title')} sm={12}>
            <TextValidatorComponent
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.lettersNumbersSpacesUnderscoresDash]}
              label={t('pages.clients.name')}
              value={client.name || ''}
              fieldName="name"
              handleChange={handleChange}
              disabled
            />
            <TextValidatorComponent
              label={t('pages.clients.legalName')}
              value={client.fullName || ''}
              fieldName="fullName"
              handleChange={handleChange}
              disabled
            />
            <TextValidatorComponent
              label={t('pages.clients.domainName')}
              value={client.internalHost}
              fieldName="domain"
              handleChange={handleChange}
              disabled
            />
          </CardComponent>
          <InternalSubdomains clientId={id} />
          <CustomDomains clientId={id} setDomains={setCustomDomains} />
          <PlanogramInfo planogramsInfo={planogramsInfo} sm={12} xs={12} />
          {!!customDomains.length && (
            <CardComponent title={t('pages.clients.editClient.matomoAnalytic')} sm={12}>
              <Grid container spacing={6} direction="column">
                <Grid item>
                  <Box mb={4}>
                    <TextValidatorComponent
                      style={{ maxWidth: '468px' }}
                      label={t('pages.settings.analyticsProviders.matomo.inputLabel')}
                      disabled={initialMatomoSettings.matomoUrl}
                      validators={[VALIDATORS_MAP.notEmpty('Matomo url'), VALIDATORS_MAP.https('Matomo url')]}
                      value={matomoSettings.matomoUrl ?? ''}
                      fieldName="matomoUrl"
                      handleChange={handleChange}
                      disableMinHeight
                    />
                  </Box>
                  {customDomains.map((domain, index) =>
                    <Box display="flex" flexWrap="wrap" alignItems="center" key={`custom-domains-${index}`}>
                      <Box mr={5} style={{ minWidth: '210px' }}>
                        <Typography variant="h4">{t('pages.settings.analyticsProviders.matomo.siteIdColumnDomainTitle')}</Typography>
                        <Subtext>{domain.host}</Subtext>
                      </Box>
                      <Box>
                        <Typography variant="h4">{t('pages.settings.analyticsProviders.matomo.siteIdColumnSiteIdTitle')}</Typography>
                        <TextValidatorComponent
                          InputLabelProps={{ shrink: false }}
                          disabled={initialMatomoSettings.matomoSiteIds?.[index]?.[domain.id]}
                          validators={[VALIDATORS_MAP.notEmpty('a site ID'), VALIDATORS_MAP.numbersOnly]}
                          value={matomoSettings.matomoSiteIds?.[index]?.[domain.id] ?? ''}
                          fieldName={domain.id.toString()}
                          disableMinHeight
                          noWrap
                          handleChange={handleSiteIdChange(index)}
                          style={{ width: '4vw', minWidth: '88px' }}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <Box display="flex">
                    <StatusComponent
                      statusText="Delete Matomo Settings"
                      status={UPLOAD_STATUSES.SYNC_FAILED}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowDialog(true)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardComponent>
          )}
        </Grid>
        <Grid item sm={4}>
          <AdminsBlock initialPaginationParams={adminsBlockPaginationParams} clientId={id} role={ROLES.clientAdmin} />
          <Box mt={6}>
            <AdminsBlock
              initialPaginationParams={adminsBlockPaginationParams}
              clientId={id}
              role={ROLES.clientModerator}
            />
          </Box>
        </Grid>
      </Grid>
      {showDialog &&
        <ConfirmationDialog
          open
          title={t('pages.settings.analyticsProviders.matomo.confirmationDialogTitle')}
          showWarningIcon={false}
          message={t('pages.settings.analyticsProviders.matomo.confirmationDialogMessage')}
          confirmText={t('pages.settings.analyticsProviders.matomo.confirmationDialogConfirmText')}
          handleYes={() => handleDeleteMatomo()}
          handleNo={() => setShowDialog(false)}
        />
      }
    </ValidatorForm>
  );
}

EditClient.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};
