import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Trans } from 'react-i18next';

import { CardComponent, PageHeader, ImageUploader } from 'components';
import { LINKS } from 'components/constants';
import { IN_PROGRESS_STATUSES } from 'components/DropzoneImage/constants';
import { ACCEPT_EXTENSIONS, EXTENSIONS_NAMES } from 'components/ImageUploader/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useTimeoutUpdate, useDeleteSelectedAssets } from 'hooks';
import { backgroundImagesModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey, imageSizeValidator } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

const BG_IMAGE_MAX_SIZE = 5368709120;

function BackgroundImages({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }) {
  const [images, setImages] = useState([]);
  const [shouldImagesBeUpdated, setShouldImagesBeUpdated] = useState(true);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleDeleteAllRequest } = useDeleteSelectedAssets();
  const getT = getTWithPathKey('pages.backgroundImages');

  useEffect(() => {
    getImages();
  }, [paginationParams]);

  const getImages = withPageProgressHandler(() =>
    backgroundImagesModel
      .getImages(paginationParams)
      .then(({ backgroundImages, count }) => {
        setImages(backgroundImages);
        setPaginationItemsCount(count);
        setShouldImagesBeUpdated(backgroundImages.some(({ status }) => IN_PROGRESS_STATUSES.includes(status)));
      })
      .catch(handleServerError)
  );

  const loadImage = image => {
    const signedId = get(image, 'signed_id');

    return backgroundImagesModel.postImage({ signedId }).then(resetPaginationPage);
  };

  const unloadImage = imageId => backgroundImagesModel.deleteImage(imageId).then(getImages).catch(handleServerError);

  useTimeoutUpdate(shouldImagesBeUpdated, getImages, images);

  return (
    <>
      <PageHeader title={getT('header', { paginationItemsCount })} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title={getT('title')}>
          <ImageUploader
            images={images}
            loadImage={loadImage}
            unloadImage={unloadImage}
            handleServerError={handleServerError}
            handleDeleteAll={assets => handleDeleteAllRequest(assets, backgroundImagesModel.deleteImage, getImages)}
            text={
              <Trans
                i18nKey="pages.backgroundImages.imageUploaderText"
                components={[
                  <a href={LINKS.backgroundsKnowledgeBase} target="_blank" rel="noopener noreferrer">
                    {getT('imageUploaderText')}
                  </a>,
                ]}
              />
            }
            additionalValidators={[imageSizeValidator(BG_IMAGE_MAX_SIZE)]}
            accept={`${ACCEPT_EXTENSIONS.img}, .${EXTENSIONS_NAMES.pdf}`}
            maxFiles={1}
            section={DOWNLOAD_SECTIONS.background}
            withUploadButton
            thumbsOnly
            withCaptcha
            dropzoneText={getT('dropzoneText')}
          />
        </CardComponent>
      </Grid>
    </>
  );
}

BackgroundImages.propTypes = {
  ...withPaginationPropTypes,
};

export default withPagination(BackgroundImages);
