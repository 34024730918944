import React, { memo } from 'react';

import { includes } from 'lodash';
import PropTypes from 'prop-types';

import { PLANOGRAM_VERSION_STATUS } from 'pages/planograms/constants';

import { DoneIcon } from './style';

function PublishedStatusComponent({ planogram }) {
  return <>{includes(planogram.planogramVersionsStatuses, PLANOGRAM_VERSION_STATUS.published) && <DoneIcon />}</>;
}

PublishedStatusComponent.propTypes = {
  planogram: PropTypes.shape({
    planogramVersionsStatuses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export const PublishedStatus = memo(PublishedStatusComponent);
