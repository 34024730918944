import React, { useMemo } from 'react';

import { IconButton, TableCell } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { OrderByDirection, OrderByParameter } from 'types/enums';
import { SortParams, TableColumnHeader } from 'types/other';

import { InsetTableRow } from './style';

export interface TableComponentHeaderProps {
  columns: (TableColumnHeader | string)[];
  withActions?: boolean;
  sortParams?: SortParams;
  onChangeSort: (params: SortParams) => void;
}

export const TableHeaders = ({ columns, withActions = false, sortParams, onChangeSort }: TableComponentHeaderProps) => {
  const { t } = useTranslation();

  const headers = useMemo(
    () =>
      columns.map(column => {
        if (typeof column === 'string') {
          return { title: column, sortKey: undefined };
        }
        return column;
      }),
    [columns],
  );

  const handleSort = (sortKey: OrderByParameter) => {
    let orderDir = OrderByDirection.ASC;

    if (sortParams?.orderBy === sortKey) {
      orderDir = sortParams.orderDir === OrderByDirection.ASC ? OrderByDirection.DESC : OrderByDirection.ASC;
    }

    onChangeSort({ orderBy: sortKey, orderDir });
  };

  return (
    <InsetTableRow>
      {headers.map(({ title, sortKey }) => (
        <TableCell key={title}>
          {title}
          {sortKey && sortParams && (
            <IconButton onClick={() => handleSort(sortKey)}>
              <ImportExport />
            </IconButton>
          )}
        </TableCell>
      ))}
      {withActions && <TableCell align="center">{t('components.tableComponent.headers.actions')}</TableCell>}
    </InsetTableRow>
  );
};
