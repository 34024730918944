import React from 'react';

export function DotsIcon() {
  /* eslint-disable */
  return (
    <svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M4.6,11.8 C6.5882251,11.8 8.2,13.4117749 8.2,15.4 C8.2,17.3882251 6.5882251,19 4.6,19 C2.6117749,19 1,17.3882251 1,15.4 C1,13.4117749 2.6117749,11.8 4.6,11.8 Z M15.4,11.8 C17.3882251,11.8 19,13.4117749 19,15.4 C19,17.3882251 17.3882251,19 15.4,19 C13.4117749,19 11.8,17.3882251 11.8,15.4 C11.8,13.4117749 13.4117749,11.8 15.4,11.8 Z M4.6,1 C6.5882251,1 8.2,2.6117749 8.2,4.6 C8.2,6.5882251 6.5882251,8.2 4.6,8.2 C2.6117749,8.2 1,6.5882251 1,4.6 C1,2.6117749 2.6117749,1 4.6,1 Z M15.4,1 C17.3882251,1 19,2.6117749 19,4.6 C19,6.5882251 17.3882251,8.2 15.4,8.2 C13.4117749,8.2 11.8,6.5882251 11.8,4.6 C11.8,2.6117749 13.4117749,1 15.4,1 Z" />
    </svg>
  );
}
