import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { get, isEqual, pick } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, CheckoutInfoPreview, ColorPicker, ImageUploadComponent, SettingsForm } from 'components';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { COLORS } from 'theme/constants';
import { getBlobUrl } from 'utils';

import { Switch } from 'components/style';
import { Checkbox, TitleContainer } from '../SharingButtonSettings/style';

export function GalleryOverlaySettings({ planogramVersion, planogramId, handleServerError, setSuccessActionMessages }) {
  const [enabledGalleryOverlay, setEnabledGalleryOverlay] = useState(false);
  const [initialGalleryOverlaySettings, setInitialGalleryOverlaySettings] = useState(false);
  const [infoButtonSettings, setInfoButtonSettings] = useState({});
  const [initialInfoButtonSettings, setInitialInfoButtonSettings] = useState({});

  useEffect(() => {
    const enabled = get(planogramVersion, 'enabledGalleryOverlay', false);
    const infoBtn = get(planogramVersion, 'infoButtonSetting', {});

    setEnabledGalleryOverlay(enabled);
    setInitialGalleryOverlaySettings(enabled);
    setInfoButtonSettings(infoBtn);
    setInitialInfoButtonSettings(infoBtn);
  }, [planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleSetInfoBtnColor = ({ hex }) => setInfoButtonSettings(prevValue => ({ ...prevValue, color: hex }));

  const handleCheckedColorChange = ({ target: { checked } }) =>
    setInfoButtonSettings(prevValue => ({ ...prevValue, color: checked ? COLORS.white : '' }));

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      let dataToSend = {};

      if (!isEqual(enabledGalleryOverlay, initialGalleryOverlaySettings)) {
        dataToSend = { ...dataToSend, enabledGalleryOverlay };
      }

      if (!isEqual(infoButtonSettings, initialInfoButtonSettings)) {
        dataToSend = {
          ...dataToSend,
          infoButtonSetting: pick(infoButtonSettings, ['id', 'customIconSignedId', 'color', 'customIconRemoveImage']),
        };
      }

      const data = await planogramsModel.editVersion(planogramId, planogramVersion.id, dataToSend);

      const infoBtn = get(data, 'planogramVersion.infoButtonSetting', {});

      setSuccessActionMessages(data.success);
      setInitialGalleryOverlaySettings(get(data, 'planogramVersion.enabledGalleryOverlay', false));
      setInfoButtonSettings(infoBtn);
      setInitialInfoButtonSettings(infoBtn);
    } catch (err) {
      handleServerError(err);
    }
  });

  const handleImageFileSet = blob => {
    if (blob) {
      setInfoButtonSettings(prev => ({
        ...prev,
        customIconUrl: getBlobUrl(blob.key),
        customIconSignedId: blob.signed_id,
        customIconRemoveImage: false,
      }));
    } else {
      setInfoButtonSettings(({ customIconSignedId, ...restPrev }) => ({
        ...restPrev,
        customIconUrl: '',
        customIconRemoveImage: true,
      }));
    }
  };

  const getIsSaveButtonVisible = () => {
    const hasEnabledChanges = !isEqual(enabledGalleryOverlay, initialGalleryOverlaySettings);
    const hasInfoButtonChanges = !isEqual(infoButtonSettings, initialInfoButtonSettings);

    return !isSubmitting && (hasEnabledChanges || hasInfoButtonChanges);
  };

  const handleChangeEnabled = ({ target: { checked } }) => setEnabledGalleryOverlay(checked);

  const getButtonIconItemUrl = () => infoButtonSettings.customIconUrl || infoButtonSettings.defaultIconUrl;

  const checkIsIconDefault = () => Boolean(infoButtonSettings.customIconUrl);

  const renderNav = () => <Switch checked={enabledGalleryOverlay} onChange={handleChangeEnabled} />;

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent
        title="PRODUCT OVERLAY - GALLERY MODE"
        xs={9}
        alignItems="center"
        isLoading={isSubmitting}
        CardHeaderNav={renderNav}
      >
        {enabledGalleryOverlay && (
          <>
            <Typography variant="h6">
              Manage displaying gallery mode. Customize. Switch mode button color in this overlay.
            </Typography>
            <TitleContainer>
              <Typography variant="h2">Info button</Typography>
            </TitleContainer>
            <Grid container item sm={12}>
              <TitleContainer>
                <Typography variant="h4">Background color</Typography>
              </TitleContainer>
              <Checkbox
                color="primary"
                value="color"
                checked={Boolean(infoButtonSettings.color)}
                onChange={handleCheckedColorChange}
              />
              <ColorPicker setColor={handleSetInfoBtnColor} color={{ hex: infoButtonSettings.color }} />
            </Grid>
            <Grid item sm={6}>
              <TitleContainer>
                <Typography variant="h4">Icon</Typography>
              </TitleContainer>
              <ImageUploadComponent
                accept={ACCEPT_EXTENSIONS.imgIcon}
                imageUrl={getButtonIconItemUrl()}
                handleImageUpload={handleImageFileSet}
                handleServerError={handleServerError}
                withDelete={checkIsIconDefault()}
              />
            </Grid>
            <TitleContainer>
              <Typography variant="h4">Preview</Typography>
            </TitleContainer>
            <CheckoutInfoPreview infoButton={{ color: infoButtonSettings.color, iconUrl: getButtonIconItemUrl() }} />
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
}

GalleryOverlaySettings.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    enabledGalleryOverlay: PropTypes.bool,
    infoButtonSettings: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      customIconUrl: PropTypes.string,
      defaultIconUrl: PropTypes.string,
    }),
  }).isRequired,
  planogramId: PropTypes.string.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
