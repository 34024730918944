import React, { useEffect } from 'react';

import { Hidden, withWidth, Drawer, CircularProgress } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Sidebar } from 'components/Sidebar/Sidebar';
import { useBusyProgressState, useBusyProgressActions } from 'contexts/BusyProgressContext';

import { disableEvent } from '../../utils';

import { Box, Backdrop } from './style';

export function Dashboard({ children, width }) {
  const { isBusy } = useBusyProgressState();
  const { setBusyState } = useBusyProgressActions();
  const history = useHistory();

  useEffect(() => history.listen(() => setBusyState(false)), []);

  return (
    <Box className="container">
      <Box className="dashboard">
        <Hidden smDown implementation="css">
          <Sidebar />
        </Hidden>
      </Box>
      <Box className="content-wrapper" onKeyDown={disableEvent(isBusy)}>
        {isBusy && (
          <Backdrop open={isBusy}>
            <CircularProgress size={40} thickness={4} />
          </Backdrop>
        )}
        <Box p={isWidthUp('lg', width) ? 10 : 5} className="content">
          {children}
        </Box>
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  width: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withWidth()(Dashboard);
