import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AudioMuteIcon from 'assets/icons/assets/audio-defualt-mute.svg';
import AudioUnmuteIcon from 'assets/icons/assets/audio-defualt-unmute.svg';
import { CardComponent, ColorPicker, ImageChooseComponent, SettingsForm } from 'components';
import AudioStopIcon from 'assets/icons/assets/audio-default-stop-icon.svg';
import { useSubmittingState } from 'hooks';
import { AudioAsset, AudioSettingsType } from 'types/audio';
import { AutocompleteValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { COLORS } from 'theme/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { OtherAssets, PaginationParams } from 'types';
import { otherAssetsModel } from 'models';
import { audioModel } from 'models/AudioModel';
import InputSlider from 'components/InputSlider/InputSlider';

import AudioButtonsPreview from './AudioButtonsPreview';

import { Switch } from 'components/style';
import { Description, PreviewDescription, Title } from './style';

const DEFAULT_AUDIO_SETTINGS: AudioSettingsType = {
  audioId: undefined,
  audioBackgroundColor: '#6238f9',
  stopOtherAssetId: undefined,
  muteOtherAssetId: undefined,
  unmuteOtherAssetId: undefined,
  volume: 80,
};

const DEFAUL_STOP_BUTTON = { id: 1, url: AudioStopIcon };
const DEFAUL_MUTE_BUTTON = { id: 1, url: AudioMuteIcon };
const DEFAUL_UNMUTE_BUTTON = { id: 1, url: AudioUnmuteIcon };

interface AnimationSettingsCardProps {
  onSaveAudioSettings: (settings: AudioSettingsType | undefined) => Promise<void>;
  audioSettings?: AudioSettingsType;
  otherAssets: OtherAssets[];
  expanded?: boolean;
  onToggleExpanded?: () => void;
}

const AudioSettingsCard = ({
  onSaveAudioSettings,
  audioSettings,
  otherAssets,
  expanded,
  onToggleExpanded,
}: AnimationSettingsCardProps) => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const [state, setState] = useState<AudioSettingsType | undefined>(audioSettings);
  const [audios, setAudios] = useState<AudioAsset[]>([]);
  const [buttons, setButtons] = useState({
    stop: otherAssets.find(asset => asset.title === 'stop') ?? DEFAUL_STOP_BUTTON,
    mute: otherAssets.find(asset => asset.title === 'mute') ?? DEFAUL_MUTE_BUTTON,
    unmute: otherAssets.find(asset => asset.title === 'unmute') ?? DEFAUL_UNMUTE_BUTTON,
  });

  const handleSetButtons = (title: 'stop' | 'mute' | 'unmute', button: { id: number; url: string }) => {
    setButtons(prevState => ({ ...prevState, [title]: button.id ? button : undefined }));
  };

  const getImages = (params: PaginationParams) => otherAssetsModel.getImages(params).catch(handleServerError);

  useEffect(() => {
    audioModel
      .getAudio()
      .then(data => {
        setAudios(data.audios);
      })
      .catch(handleServerError);
  }, []);

  const handleChangeInput = (fieldName: keyof AudioSettingsType, value: number | string | boolean) => {
    setState((prevState: AudioSettingsType | undefined) => ({
      ...(prevState ?? DEFAULT_AUDIO_SETTINGS),
      [fieldName]: value,
    }));
  };

  const handleExpand = () => {
    if (!expanded)
      setState((prevState: AudioSettingsType | undefined) => ({
        ...(prevState ?? DEFAULT_AUDIO_SETTINGS),
        ...audioSettings,
      }));
    else setState(undefined);
    onToggleExpanded?.();
  };

  const handleSave = withHandlingSubmittingState(() => onSaveAudioSettings(state));

  const isSaveButtonVisible = JSON.stringify(state) !== JSON.stringify(audioSettings) && !isSubmitting;

  const renderNav = () => (expanded === undefined ? null : <Switch checked={expanded} onChange={handleExpand} />);

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={handleSave}>
      <CardComponent
        title={t('pages.settings.audioSettings.title')}
        sm={8}
        CardHeaderNav={renderNav}
        isLoading={isSubmitting}
      >
        {expanded !== false && state && (
          <Grid container spacing={6} direction="column">
            <Grid item>
              <Description>{t('pages.settings.audioSettings.description')}</Description>
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.audioSettings.selectTitle')}</Title>
              <Box style={{ width: '50%' }}>
                <AutocompleteValidatorComponent
                  value={state.audioId}
                  fieldName="navigationValueSelect"
                  handleChange={(value: { navigationValueSelect: number }) =>
                    handleChangeInput('audioId', value.navigationValueSelect)
                  }
                  selectList={audios}
                  itemsNamePath="fileName"
                  label={t('pages.settings.audioSettings.selectValue')}
                  validators={[VALIDATORS_MAP.required]}
                />
              </Box>
              <Description>{t('pages.settings.audioSettings.disclaimer')}</Description>
            </Grid>
            <Grid item>
              <Typography variant="h2" style={{ color: COLORS.black, paddingBottom: 2 }}>
                {t('pages.settings.audioSettings.volumeTitle')}
              </Typography>
              <Description>{t('pages.settings.audioSettings.volumeDescription')}</Description>
              <InputSlider
                value={state.volume ?? 80}
                min={0}
                max={100}
                step={1}
                onChange={value => handleChangeInput('volume', value)}
                suffix="%"
              />
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="h2" style={{ color: COLORS.black, paddingBottom: 2 }}>
                {t('pages.settings.audioSettings.uiSettings.title')}
              </Typography>
              <Description>{t('pages.settings.audioSettings.uiSettings.description')}</Description>
            </Grid>
            <Grid item>
              <Title>{t('pages.settings.audioSettings.uiSettings.bgColorTitle')}</Title>
              <br />
              <ColorPicker
                setColor={color => handleChangeInput('audioBackgroundColor', color.hex)}
                color={{ hex: state.audioBackgroundColor }}
              />
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={4}>
                <Title style={{ paddingBottom: '10px' }}>
                  {t('pages.settings.audioSettings.uiSettings.muteIconTitle')}
                </Title>
                <ImageChooseComponent
                  image={buttons.mute ?? DEFAUL_MUTE_BUTTON}
                  setImage={val => {
                    handleSetButtons('mute', val);
                    handleChangeInput('muteOtherAssetId', val.id ?? undefined);
                  }}
                  handleServerError={handleServerError}
                  dialogTitle={t('pages.settings.audioSettings.muteButtonChoose')}
                  getPictures={getImages}
                  withDelete={!!state.muteOtherAssetId}
                  className="audio-settings-icon"
                />
                <PreviewDescription>
                  {t('pages.settings.audioSettings.uiSettings.muteIconDescription')}
                </PreviewDescription>
              </Grid>
              <Grid item xs={4}>
                <Title style={{ paddingBottom: '10px' }}>
                  {t('pages.settings.audioSettings.uiSettings.unmuteIconTitle')}
                </Title>
                <ImageChooseComponent
                  image={buttons.unmute ?? DEFAUL_UNMUTE_BUTTON}
                  setImage={val => {
                    handleSetButtons('unmute', val);
                    handleChangeInput('unmuteOtherAssetId', val.id ?? undefined);
                  }}
                  handleServerError={handleServerError}
                  dialogTitle={t('pages.settings.audioSettings.unmuteButtonChoose')}
                  getPictures={getImages}
                  withDelete={!!state.unmuteOtherAssetId}
                  className="audio-settings-icon"
                />
                <PreviewDescription>
                  {t('pages.settings.audioSettings.uiSettings.unmuteIconDescription')}
                </PreviewDescription>
              </Grid>
              <Grid item xs={4}>
                <Title style={{ paddingBottom: '10px' }}>
                  {t('pages.settings.audioSettings.uiSettings.stopIconTitle')}
                </Title>
                <ImageChooseComponent
                  image={buttons.stop ?? DEFAUL_STOP_BUTTON}
                  setImage={val => {
                    handleSetButtons('stop', val);
                    handleChangeInput('stopOtherAssetId', val.id ?? undefined);
                  }}
                  handleServerError={handleServerError}
                  dialogTitle={t('pages.settings.audioSettings.stopButtonChoose')}
                  getPictures={getImages}
                  withDelete={!!state.stopOtherAssetId}
                  className="audio-settings-icon"
                />
                <PreviewDescription>
                  {t('pages.settings.audioSettings.uiSettings.stopIconDescription')}
                </PreviewDescription>
              </Grid>
            </Grid>
            <Grid item>
              <Title style={{ paddingBottom: '10px' }}>
                {t('pages.settings.audioSettings.uiSettings.previewTitle')}
              </Title>
              <AudioButtonsPreview
                stopButtonUrl={buttons.stop?.url ?? DEFAUL_STOP_BUTTON.url}
                muteButtonUrl={buttons.mute?.url ?? DEFAUL_MUTE_BUTTON.url}
                unmuteButtonUrl={buttons.unmute?.url ?? DEFAUL_UNMUTE_BUTTON.url}
                backgroundColor={state.audioBackgroundColor}
              />
            </Grid>
          </Grid>
        )}
      </CardComponent>
    </SettingsForm>
  );
};

export default AudioSettingsCard;
