import React from 'react';

export function AddToGalleryLabelIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#2D63F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 7.66V5.918H17V4.75h-1.75V3h-1.167v1.75h-1.75c.006.006 0 1.167 0 1.167h1.75V7.66c.006.006 1.167 0 1.167 0zM13.5 6.5v1.75h1.75v4.667a1.17 1.17 0 0 1-1.167 1.166h-7a1.17 1.17 0 0 1-1.166-1.166v-7A1.17 1.17 0 0 1 7.083 4.75h4.667V6.5h1.75zm-4.667 4.083-1.75 2.334h7L11.75 10 10 12.333l-1.167-1.75zm-5.25 4.667V7.083H4.75v8.167h8.167v1.167H4.75a1.17 1.17 0 0 1-1.167-1.167z"
        fill="#fff"
      />
    </svg>
  );
}
