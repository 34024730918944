import { Button as MuiButton, withStyles, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Button = withStyles(theme => ({
  root: {
    color: COLORS.cozmosBlue,
    ...theme.typography.h5,
    padding: 0,
    '& .input-file': { display: 'none' },
  },
}))(MuiButton);

export const Input = styled('input')({
  display: 'none',
});
