import React from 'react';

import { Typography, Box, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardComponent, ItemWithData } from 'components';

import { IN_PROGRESS_VERSION_TEXT } from './constants';

export function InProgressVersion({ planogramVersion }) {
  return (
    <>
      {planogramVersion && (
        <CardComponent title="In progress" sm={12}>
          <Typography variant="h2">{IN_PROGRESS_VERSION_TEXT[planogramVersion.status]}</Typography>
          <Box my={5}>
            <LinearProgress color="primary" />
          </Box>
          <ItemWithData title="Remaining time" data="1 min" />
          <ItemWithData title="Current step" data={planogramVersion.step} />
        </CardComponent>
      )}
    </>
  );
}

InProgressVersion.propTypes = {
  planogramVersion: PropTypes.shape({
    status: PropTypes.string,
    step: PropTypes.string,
  }),
};

InProgressVersion.defaultProps = {
  planogramVersion: null,
};
