import React from 'react';

import { useTranslation } from 'react-i18next';

import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';

import { TrafficRedirect } from './TrafficRedirect/TrafficRedirect';

export const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <PageWithHeader tabTitle="neki" headerText={t('pages.manageMaintenance.title')}>
      <TrafficRedirect />
    </PageWithHeader>
  );
};
