import { api } from './setupApi';

class ReservedClientsModel {
  link = '/reserved_clients';

  getReservedClients = params => api.get(this.link, { params }).then(({ data }) => data);

  deleteReservedClient = id => api.delete(`${this.link}/${id}`);
}

export const reservedClientsModel = new ReservedClientsModel();
