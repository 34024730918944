import { Typography as MuiTypography } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';

import { COLORS } from 'theme/constants';

import { ReactComponent as CozmosSVGDark } from 'assets/img/cozmos-logo-dark.svg';
import { ReactComponent as CozmosSVGWhite } from 'assets/img/cozmos-logo-white.svg';

export const GlobalStyle = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    'html, body, #root': {
      height: '100%',
    },
    body: {
      background: COLORS.background,
    },
  },
})(() => null);

export const Root = styled('div')({
  maxWidth: '560px',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  padding: '24px 20px',
});

export const Typography = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.h5,
  textAlign: 'center',
  color: COLORS.cozmosPurple2,
}));

export const CozmosLogoLight = styled(CozmosSVGWhite)(({ theme }) => ({
  display: 'block',
  margin: '0 auto',
  width: '182px',
  height: '64px',
}));

export const CozmosLogoDark = styled(CozmosSVGDark)(({ theme }) => ({
  display: 'block',
  margin: '0 auto',
  width: '182px',
  height: '64px',
}));

export const Link = styled('a')(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.auth.link,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const ToggleWrapper = styled('div')({
  position: 'absolute',
  bottom: '0',
  left: '0',
  padding: '20px',
});

export const PageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.auth.background,
  color: theme.palette.auth.formText,
}));
