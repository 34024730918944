import { camelCase } from 'lodash';

import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { CAPTCHA_HEADERS } from 'models/constants';

export const SECTIONS_WITH_CAPTCHA = [DOWNLOAD_SECTIONS.background];

export const getCaptchaHeadersFromLocalStorage = (): Record<string, string> => {
  const token = localStorage.getItem(CAPTCHA_HEADERS.CAPTCHA_TOKEN);

  return token ? { [CAPTCHA_HEADERS.CAPTCHA_TOKEN]: token } : {};
};

export const setCaptchaHeadersToLocalStorage = (headers: Record<string, string>) =>
  Object.values(CAPTCHA_HEADERS).forEach(headerName =>
    localStorage.setItem(headerName, headers[camelCase(headerName)]),
  );
