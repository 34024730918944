import React, { useState, useEffect } from 'react';

import { Grid, FormControl, InputLabel, Input, MenuItem, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { set, flatten } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { CardComponent, PageHeader, PageHeaderNav } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { currenciesModel } from 'models';
import { ROUTES } from 'routes/constants';

import { CountryChip, CountryChipContainer, CountrySelect } from './style';

export default function CurrencySettings() {
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultCountryId, setDefaultCountryId] = useState(null);
  const [defaultCurrencyId, setDefaultCurrencyId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    async function init() {
      try {
        const { data: countriesList } = await currenciesModel.getCountriesList();
        const { data: currenciesList } = await currenciesModel.getCurrencies();
        const country = countriesList.find(c => c.code === 'DEFAULT');
        let currency = currenciesList.find(c => c.countryIds.some(id => country.id === id));

        if (!currency) [currency] = currenciesList;
        currency.countryIds = currency.countryIds.filter(id => id !== country.id);
        setDefaultCurrencyId(currency.id);
        setDefaultCountryId(country.id);
        setCountries(countriesList.filter(c => c.id !== country.id));
        setCurrencies(currenciesList);
      } catch (error) {
        handleServerError(error);
      }
    }
    withPageProgressHandler(init)();
  }, []);

  const handleSubmit = withPageProgressHandler(() => {
    const data = flatten(currencies.map(c => c.countryIds.map(id => ({ country_id: id, currency_id: c.id }))));

    data.push({ country_id: defaultCountryId, currency_id: defaultCurrencyId });
    return currenciesModel
      .saveCurrencyRelations(data)
      .then(() => history.push(ROUTES.settings))
      .catch(handleServerError);
  });

  const getCountryName = id => countries.find(c => c.id === id).name;

  const getAvailableCountries = currency => {
    const usedIds = flatten(currencies.filter(c => c.id !== currency.id).map(c => c.countryIds));

    return countries.filter(c => !usedIds.some(id => c.id === id));
  };

  const handleDefaultChange = ({ target: { value } }) => {
    setDefaultCurrencyId(+value);
  };

  const handleSelectChange =
    item =>
    ({ target: { value } }) => {
      setCurrencies(prevState => {
        const index = currencies.indexOf(item);

        return [...set(prevState, [index, 'countryIds'], value)];
      });
    };

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <PageHeader title="Currencies management" backLink={ROUTES.settings}>
        <PageHeaderNav withSubmit />
      </PageHeader>
      <Grid container spacing={6}>
        <Grid item sm={8} xs={12}>
          {currencies.map(currency => (
            <CardComponent sm={12} key={currency.name} title={currency.name}>
              <Grid container>
                <FormControl>
                  <InputLabel>Countries</InputLabel>
                  <CountrySelect
                    multiple
                    value={currency.countryIds}
                    onChange={handleSelectChange(currency)}
                    input={<Input />}
                    renderValue={selected => (
                      <CountryChipContainer>
                        {selected.map(id => (
                          <CountryChip key={id} label={getCountryName(id)} />
                        ))}
                      </CountryChipContainer>
                    )}
                  >
                    {getAvailableCountries(currency).map(country => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </CountrySelect>
                </FormControl>
              </Grid>
            </CardComponent>
          ))}
        </Grid>
        <Grid item sm={4} xs={12}>
          <CardComponent sm={12} title="Default Currency">
            <RadioGroup value={defaultCurrencyId} onChange={handleDefaultChange}>
              {currencies.map(currency => (
                <FormControlLabel key={currency.id} value={currency.id} control={<Radio />} label={currency.name} />
              ))}
            </RadioGroup>
          </CardComponent>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
