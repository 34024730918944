import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { CardComponent, SettingsForm } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { getMinMaxValidatedValue } from 'utils';

import { CameraPositionSlider } from './CameraPositionSlider/CameraPositionSlider';
import { CAMERA_POSITION_SETTINGS } from './constants';

import { Typography } from '../style';
import { Box } from './style';

export function CameraVerticalPosition({ planogramVersion, planogramId, handleServerError, setSuccessActionMessages }) {
  const [cameraPosition, setCameraPosition] = useState(planogramVersion.cameraPosition);
  const [initialCameraPosition, setInitialCameraPosition] = useState(planogramVersion.cameraPosition);

  useEffect(() => {
    setInitialCameraPosition(planogramVersion.cameraPosition);
    setCameraPosition(planogramVersion.cameraPosition);
  }, [planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleInputBlur = useCallback(() => {
    if (cameraPosition === '') {
      setCameraPosition(CAMERA_POSITION_SETTINGS.values.middle);
    }
  }, [cameraPosition]);

  const handleChangeInput = useCallback(
    value => setCameraPosition(+getMinMaxValidatedValue(value.cameraPosition, CAMERA_POSITION_SETTINGS.values)),
    [],
  );

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      const data = await planogramsModel.editVersion(planogramId, planogramVersion.id, { cameraPosition });

      setSuccessActionMessages(data.success);
      setInitialCameraPosition(data.planogramVersion.cameraPosition);
    } catch (err) {
      handleServerError(err);
    }
  });

  const isSaveButtonVisible = !isSubmitting && cameraPosition !== initialCameraPosition;

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={handleSave}>
      <CardComponent title="Sphere Camera Vertical Position" xs={9} alignItems="center" isLoading={isSubmitting}>
        <Box>
          <Typography variant="h6" display="inline">
            Set up camera vertical line by drag-n-move slider or enter a value:
          </Typography>
          <TextValidatorComponent
            validators={[
              VALIDATORS_MAP.required,
              VALIDATORS_MAP.maxNumber(CAMERA_POSITION_SETTINGS.values.max),
              VALIDATORS_MAP.minNumber(CAMERA_POSITION_SETTINGS.values.min),
            ]}
            label="Enter value"
            value={cameraPosition}
            fieldName="cameraPosition"
            handleChange={handleChangeInput}
            onBlur={handleInputBlur}
            fullWidth={false}
            type="number"
            inputProps={{
              min: CAMERA_POSITION_SETTINGS.values.min,
              max: CAMERA_POSITION_SETTINGS.values.max,
              step: CAMERA_POSITION_SETTINGS.step,
            }}
          />
        </Box>
        <Typography variant="h4">Vertical line</Typography>
        <CameraPositionSlider value={Number(cameraPosition) || 0} setValue={setCameraPosition} />
      </CardComponent>
    </SettingsForm>
  );
}

CameraVerticalPosition.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    cameraPosition: PropTypes.number,
  }).isRequired,
  planogramId: PropTypes.string.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
