import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { PageHeader, PageHeaderNav } from 'components';
import { ROUTES } from 'routes/constants';
import AnimationSettingsCard from 'components/AnimationSettingsCard/AnimationSettingsCard';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { AnimationSettingsType } from 'types/animation';
import { AnimationTypes, EntranceAnimationTypes } from 'types/entranceAnimation';
import { SphereLoader } from 'types/sphereLoader';
import { clientsModel } from 'models';
import { useAuthState } from 'contexts/AuthContext';
import { SuccessActionsDialog } from 'components/Dialogs';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';
import EntranceAnimationSettingsCard from 'components/EntranceAnimationSettingsCard/EntranceAnimationSettingsCard';
import SphereLoaderCard from 'components/SphereLoaderCard/SphereLoaderCard';

const AnimationSettings = () => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const [animationSettings, setAnimationSettings] = useState<AnimationSettingsType>();
  const [guidanceSettings, setGuidanceSettings] = useState<EntranceAnimationTypes>();
  const [sphereLoaderSettings, setSphereLoaderSettings] = useState<SphereLoader>();
  const { currentUser } = useAuthState();
  const [successActionMessages, setSuccessActionMessages] = useState<string[]>([]);

  useEffect(() => {
    getAnimationSettings();
  }, []);

  const getAnimationSettings = withPageProgressHandler(() =>
    clientsModel.getClient(currentUser.attributes.clientId).then(data => {
      setAnimationSettings(data.animationSettings);
      setSphereLoaderSettings(data.sphereLoader);
      setGuidanceSettings({
        ...data.entranceAnimation,
        entranceAnimationFonts: data.entranceAnimation.entranceAnimationFonts.map(item => (
          { ...item, desktop: getFontSettingsWithNums(item.desktop), mobile: getFontSettingsWithNums(item.mobile) }
        )) as AnimationTypes[]
      });
    }),
  );

  const onSaveAnimationSettings = (settings: AnimationSettingsType | undefined) =>
    clientsModel
      .editClient(currentUser.attributes.clientId, { animationSettings: settings })
      .then(data => {
        setAnimationSettings(settings);
        setSuccessActionMessages(data.success);
      })
      .catch(handleServerError);

  const onSaveSphereLoaderSettings = (settings: SphereLoader) => {
    const { otherAsset, ...rest } = settings;
    const dataToSave = {
      ...rest
    };

    if (otherAsset?.id) {
      dataToSave.otherAssetId = otherAsset.id;
    }

    return clientsModel.editClient(currentUser.attributes.clientId, { sphereLoader: dataToSave })
      .then(data => {
        setSphereLoaderSettings(settings);
        setSuccessActionMessages(data.success);
      })
      .catch(handleServerError);
  }


  const onSaveEntranceAnimationSettings = (settings: EntranceAnimationTypes) => {
    const dataToSave = {
      ...settings,
      entranceAnimationFonts: settings.entranceAnimationFonts.map(item => {
        const { otherAsset, ...rest } = item;
        const data = {
          ...rest,
          desktop: getFontSettingsWithPx(rest.desktop),
          mobile: getFontSettingsWithPx(rest.mobile)}

        if (otherAsset?.id) {
          data.otherAssetId = otherAsset.id;
        }

        return data;
      })
    };

    return clientsModel
      .editClient(currentUser.attributes.clientId, { entranceAnimation: dataToSave })
      .then(data => {
        setGuidanceSettings(settings);
        setSuccessActionMessages(data.success);
      })
      .catch(handleServerError);
  }


  return (
    <>
      <PageHeader title={t('pages.settings.animationSettings.title')} backLink={ROUTES.settings}>
        <PageHeaderNav />
      </PageHeader>
      {animationSettings && (
        <AnimationSettingsCard
          onSaveAnimationSettings={onSaveAnimationSettings}
          animationSettings={animationSettings}
          descriptionKey="pages.settings.animationSettings.presets.description"
        />
      )}
      {sphereLoaderSettings && (
        <SphereLoaderCard
          onSettingsSave={onSaveSphereLoaderSettings}
          handleServerError={handleServerError}
          settings={sphereLoaderSettings}
        />
      )}
      {guidanceSettings && (
        <EntranceAnimationSettingsCard
          onSettingsSave={onSaveEntranceAnimationSettings}
          handleServerError={handleServerError}
          settings={guidanceSettings}
        />
      )}
      <SuccessActionsDialog
        open={successActionMessages.length > 0}
        onClose={() => setSuccessActionMessages([])}
        messages={successActionMessages}
      />
    </>
  );
};

export default AnimationSettings;
