import { PaginationParams } from 'types/common';
import {
  DeletePlanogramParams,
  PlanogramGroup,
  PlanogramGroupListResponse,
  PlanogramGroupParams,
  ShowUsedAsErrorResponse,
} from 'types/planograms';
import { trimItems } from 'utils';

import { api } from './setupApi';

class PlanogramGroupsModel {
  link = '/planogram_groups';

  showErrorResponseLink = '/show_used_as_error_response';

  getPlanogramGroups = (params: PaginationParams): Promise<PlanogramGroupListResponse> =>
    api.get<PlanogramGroupListResponse>(this.link, { params }).then(({ data }) => data);

  getPlanogramGroup = (id: number): Promise<PlanogramGroup> =>
    api.get<PlanogramGroup>(`${this.link}/${id}`).then(({ data }) => data);

  addPlanogramGroup = (data: PlanogramGroupParams) => api.post(this.link, trimItems(data, ['name']));

  editPlanogramGroup = ({ id, ...data }: PlanogramGroupParams) =>
    api.put(`${this.link}/${id}`, trimItems(data, ['name']));

  deletePlanogramGroup = (id: number) => api.delete(`${this.link}/${id}`, { data: { ignoreWarnings: true } });

  checkUsedAsErrorResponse = (id: number) =>
    api.get<ShowUsedAsErrorResponse>(`${this.link}/${id}${this.showErrorResponseLink}`).then(({ data }) => data);

  getPlanogramGroupsList = (): Promise<PlanogramGroup[]> =>
    api
      .get<{ planogramGroups: PlanogramGroup[] }>(`${this.link}/list_for_cloning`)
      .then(({ data }) => data.planogramGroups);
}

export const planogramGroupsModel = new PlanogramGroupsModel();
