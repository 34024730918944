import { styled, Box as MuiBox, Typography as MuiTypography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

import { ReactComponent as CozmosSVG } from 'assets/img/cozmos-logo-dark.svg';

export const Box = styled(MuiBox)({
  maxWidth: '640px',
  margin: '0 auto',
  alignItems: 'center',
  padding: '20px 0',
  textAlign: 'center',
  marginTop: '60px',
  whiteSpace: 'pre-line',
});

export const Title = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.h1,
  fontSize: '22px',
  letterSpacing: '-0.66px',
  lineHeight: 1.05,
  color: COLORS.greyText,
}));

export const BodyText = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '-0.66px',
  color: COLORS.greyText,
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const SmallText = styled(MuiTypography)(({ theme }) => ({
  ...theme.typography.caption,
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: 2,
  color: COLORS.greyText,
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const Link = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const CozmosLogo = styled(CozmosSVG)({
  width: '254px',
  height: '67px',
});
