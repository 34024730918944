import { Box as MuiBox, styled, Typography as MuiTypography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)({
  backgroundColor: COLORS.bgLight,
  height: '100%',
  padding: '10px',
});

export const Typography = styled(MuiTypography)({
  '&.title': {
    color: COLORS.greyText,
    display: 'block',
    marginBottom: '7px',
    wordWrap: 'break-word',
  },
  fontFamily: 'inherit',
});
