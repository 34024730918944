import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { CardComponent, ItemWithData } from 'components';
import { ROLES_DISPLAY_MAP } from 'routes/constants';

function ClientInfoComponent({ admin, adminsCount }) {
  return (
    <CardComponent title="SPACE" xs={9}>
      <ItemWithData title="Space" data={admin?.clientName || admin?.client?.name} />
      <ItemWithData title="Role" data={ROLES_DISPLAY_MAP[admin?.role]} />
    </CardComponent>
  );
}

ClientInfoComponent.propTypes = {
  admin: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    clientName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  adminsCount: PropTypes.number,
};

ClientInfoComponent.defaultProps = {
  adminsCount: null,
};

export const ClientInfo = memo(ClientInfoComponent);
