import { PaginationParams } from 'types/common';
import { UploadedPicture, LogosListResponse } from 'types/images';

import { api } from './setupApi';

class LogosModel {
  link = '/logos';

  getLogo = (params: PaginationParams): Promise<LogosListResponse> =>
    api.get<LogosListResponse>(this.link, { params }).then(({ data }) => data);

  deleteLogo = (id: number) => api.delete(`${this.link}/${id}`);

  postLogo = (data: Record<'signedId', string>) => api.post(this.link, data);

  editLogo = (id: number, data: UploadedPicture) => api.put(`${this.link}/${id}`, data);
}

export const logosModel = new LogosModel();
