import { Tooltip as MuiTooltip, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const InfoOutlinedIcon = styled(InfoOutlined)({
  '&.regular': { height: ICON_SIZE.regular, width: ICON_SIZE.regular },
  '&.middle': { height: ICON_SIZE.middle, width: ICON_SIZE.middle },
  cursor: 'pointer',
  color: COLORS.greyIcon,
  marginLeft: '4px',
});

export const Tooltip = withStyles({
  tooltip: { backgroundColor: COLORS.dark, color: COLORS.white },
})(MuiTooltip);
