import PropTypes from 'prop-types';

import { COLORS } from 'theme/constants';

export const FONT_STYLES = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

export const DEFAULT_SETTINGS = {
  fontWeight: FONT_STYLES[3],
  fontSize: '16',
  letterSpacing: '0',
  lineHeight: '1.2',
  color: COLORS.white,
};

export const FontPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  filename: PropTypes.string,
  fileUrl: PropTypes.string,
});

export const FontSettingsPropTypes = PropTypes.shape({
  id: PropTypes.number,
  buttonId: PropTypes.number,
  sharingButtonId: PropTypes.number,
  planogramInfoId: PropTypes.number,
  fontId: PropTypes.number,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  assignment: PropTypes.string,
  font: FontPropTypes,
});
