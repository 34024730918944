import { Divider as MuiDivider, Box as MuiBox, styled, withStyles } from '@material-ui/core';
import { CheckCircle, Done } from '@material-ui/icons';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '& > .MuiGrid-container': {
      maxWidth: '460px',
      margin: '0 auto',
      padding: '30px 24px',
      '&:first-child': {
        borderRadius: '20px 20px 0 0',
      },
      '&:last-child': {
        borderRadius: '0 0 20px 20px',
      },
    },
    '& .header-image': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .subtitle': { padding: '24px 20px 0 20px' },
    '& .m-auto': { margin: '0 auto' },
    '& .buttons-wrapper': { paddingTop: '24px', '& .MuiGrid-item': { maxWidth: '45%' } },
    '& .flex': { display: 'flex' },
    '& .inline-block': { display: 'inline-block' },
    '& .align-items-center': { alignItems: 'center' },
    '& .text-align-center': { textAlign: 'center' },
    '& .justify-content-center': { justifyContent: 'center' },
    '& .outlined': {
      border: `1px solid ${COLORS.white}`,
    },
    '& .settings-button': {
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: '20px',
      '& .text': { margin: '0 5px' },
      '& .MuiSvgIcon-root': { fontSize: ICON_SIZE.brand },
    },
    '& .green': { color: COLORS.greenSphere },
    '& .control-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: '4px',
    },
    '& .switch-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '& .underline': { textDecoration: 'underline' },
    '& .switch-text': { color: 'hsla(0,0%,100%,.7)', paddingRight: '15px' },
    '& .switch': {
      width: '54px',
      padding: '2px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '16px',
      outline: '2px solid currentColor',
      '&::before': {
        content: '""',
        height: ICON_SIZE.brand,
        width: ICON_SIZE.brand,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        display: 'block',
      },
    },
    '& .experience-toggle': {
      '&.before': {
        backgroundColor: 'currentColor',
      },
    },
    '& .MuiSwitch-track': { backgroundColor: COLORS.greySwitchBb, height: '175%' },
    '& .done-icon-wrapper': {
      margin: '0 26px 0 16px',
      borderRadius: '50%',
      height: '24px',
      width: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .cookies-btn': {
      height: '2.92vw',
      minHeight: '48px',
      padding: '0 2%',
    },
  },
})(MuiBox);

export const CheckCircleIcon = withStyles({
  root: {
    margin: '0 26px 0 16px',
  },
})(CheckCircle);

export const DoneIcon = withStyles({
  root: {
    fontSize: ICON_SIZE.middle,
  },
})(Done);

export const Divider = styled(MuiDivider)({
  margin: '20px 0',
  backgroundColor: COLORS.white,
  opacity: 0.2,
});
