import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';

import { getTWithPathKey } from 'utils';

import { MobileContainer, MobileInfoText } from '../style';

export const MobileSuccessMessage = () => {
  const getT = getTWithPathKey('pages.auth.setPassword');

  return (
    <MobileContainer>
      <Helmet title={getT('tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="h2">{getT('mobileSuccessTitle')}</Typography>
        </Grid>
        <Grid item>
          <MobileInfoText>
            {getT('mobileSuccessText1')}
            <br />
            <br />
            {getT('mobileSuccessText2')}
          </MobileInfoText>
        </Grid>
      </Grid>
    </MobileContainer>
  );
};
