import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { ImageChooseComponent } from 'components/ImageChooseComponent/ImageChooseComponent';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { getTWithPathKey } from 'utils';

import { BUTTON_TYPES } from '../constants';

import { Box } from '../style';
import { Image, Switch } from 'components/style';

export const EcommerceUserProfileButton = ({
  buttonSettings,
  setButtonSettings,
  getImages,
  additionalSettings,
  setAdditionalSettings,
  uploadSectionTitle,
  previewSectionTitle,
  toggleButtonTitle,
}) => {
  const { handleServerError } = useServerErrorActions();
  const getT = getTWithPathKey('components.ecommerce.userProfile');

  const setColor = color => setButtonSettings({ color });
  const setAdditionalColor = itemName => color => setAdditionalSettings({ [itemName]: color });
  const onSwitchChange = () => setButtonSettings({
    ...buttonSettings,
    enabled: !buttonSettings.enabled
  });

  return (
    <Box mt={6}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h4">{uploadSectionTitle}</Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="caption" className="information">
                {getT('uploaderLabel')}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <ImageChooseComponent
                image={buttonSettings.otherAsset}
                setImage={val => setButtonSettings({ otherAsset: val, otherAssetId: val.id })}
                handleServerError={handleServerError}
                dialogTitle={getT('imageChooseDialogTitle')}
                getPictures={getImages}
                withDelete
              />
              <Typography variant="subtitle1" className="information" style={{ marginTop: '10px' }}>
                {getT('uploaderHint')}
              </Typography>
              <Grid item sm={12}>
                <Typography variant="h4">
                  {toggleButtonTitle}
                  <Switch checked={!buttonSettings.enabled} onChange={onSwitchChange} />
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Box mb={3}>
                <Typography variant="caption" className="information">
                  {getT('bgColorPickerLabel')}
                </Typography>
              </Box>
              <ColorPicker setColor={setColor} color={buttonSettings.color || {}} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h4">{previewSectionTitle}</Typography>
            </Grid>
            <Grid item sm={12}>
              <ButtonPreviewWrapper>
                <Box className="image-preview-wrapper" style={{ backgroundColor: buttonSettings.color?.hex }}>
                  <Image src={buttonSettings.otherAsset?.url} style={{ backgroundColor: buttonSettings.color?.hex }} />
                  {additionalSettings && (
                    <Box
                      className="counter"
                      style={{
                        color: additionalSettings.secondaryColor.hex,
                        backgroundColor: additionalSettings.backgroundColor.hex,
                      }}
                    >
                      {getT('count')}
                    </Box>
                  )}
                </Box>
              </ButtonPreviewWrapper>
            </Grid>
          </Grid>
        </Grid>
        {additionalSettings && (
          <Box mt={6}>
            <Grid item sm={6}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Box mb={1.5}>
                    <Typography variant="h4">{getT('cartItemCounter')}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="caption" className="information">
                    {getT('backgroundColor')}
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="caption" className="information">
                    {getT('textColor')}
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <ColorPicker
                    setColor={setAdditionalColor('backgroundColor')}
                    color={additionalSettings.backgroundColor || {}}
                  />
                </Grid>
                <Grid item sm={6}>
                  <ColorPicker
                    setColor={setAdditionalColor('secondaryColor')}
                    color={additionalSettings.secondaryColor || {}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

EcommerceUserProfileButton.propTypes = {
  setButtonSettings: PropTypes.func.isRequired,
  buttonSettings: PropTypes.shape({
    otherAsset: PropTypes.shape({ url: PropTypes.string }),
    otherAssetId: PropTypes.number,
    color: PropTypes.shape({ hex: PropTypes.string }),
    buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
    id: PropTypes.number,
  }).isRequired,
  getImages: PropTypes.func.isRequired,
  additionalSettings: PropTypes.shape({
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
  }),
  setAdditionalSettings: PropTypes.func,
  uploadSectionTitle: PropTypes.string.isRequired,
  previewSectionTitle: PropTypes.string.isRequired,
  toggleButtonTitle: PropTypes.string.isRequired,
};

EcommerceUserProfileButton.defaultProps = {
  additionalSettings: null,
  setAdditionalSettings: () => null,
};
