import { styled, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Title = styled(Typography)({
  fontSize: '12px',
  fontWeight: 'bold',
  color: COLORS.cozmosPurple4,
});

export const Description = styled(Typography)({
  fontSize: '14px',
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
});

export const PreviewDescription = styled(Typography)({
  fontSize: '12px',
  color: COLORS.greyText,
  whiteSpace: 'pre-line',
  paddingTop: '10px',
});

export const PreviewContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  height: '120px',
  width: '100%',
});

export const PreviewContainerText = styled('span')({
  position: 'absolute',
  top: '10px',
  left: '10px',
});

export const ImageCircleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '64px',
  height: '64px',
  minWidth: '64px',
  minHeight: '64px',
  overflow: 'hidden',
  '& img': {
    height: 'auto',
    maxHeight: '54%',
    maxWidth: '54%',
    objectFit: 'contain',
    objectPosition: 'center',
    width: 'auto',
  },
});
