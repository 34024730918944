import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { CardComponent } from 'components/CardComponent/CardComponent';
import { DeleteInternalSubdomainDialog } from 'components/Dialogs/DeleteInternalSubdomainDialog/DeleteInternalSubdomainDialog';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { internalSubdomainsModel } from 'models';
import { InternalSubdomain } from 'types/domains';

import { InternalSubdomainEntry } from './InternalSubdomainEntry';

interface InternalSubdomainsProps {
  clientId: number;
}

export const InternalSubdomains = ({ clientId }: InternalSubdomainsProps) => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();

  const [internalSubdomains, setInternalSubdomains] = useState<Map<number, InternalSubdomain>>(new Map());
  const [host, setHost] = useState<string>('');
  const [subdomainToDelete, setSubdomainToDelete] = useState<InternalSubdomain | undefined>();

  const [displayAdditionalEntry, setDisplayAdditionalEntry] = useState<boolean>(false);

  useEffect(() => {
    getInternalSubdomains();
  }, []);

  const getInternalSubdomains = () =>
    internalSubdomainsModel
      .getInternalSubdomains(clientId)
      .then(({ internalSubdomains }) => {
        const subdomainMap = new Map<number, InternalSubdomain>();

        if (internalSubdomains.length > 0)
          setHost(internalSubdomains[0].host.substring(internalSubdomains[0].subdomain.length));

        internalSubdomains.forEach(subdomain => subdomainMap.set(subdomain.id, subdomain));
        setInternalSubdomains(subdomainMap);
      })
      .catch(handleServerError);

  const updateSubdomain = (subdomain: string, id: number) =>
    internalSubdomainsModel
      .updateInternalSubdomain(subdomain, id, clientId)
      .then(getInternalSubdomains)
      .then(() => Promise.resolve(true))
      .catch(handleServerError);

  const createSubdomain = (newSubdomain: string) =>
    internalSubdomainsModel
      .createInternalSubdomain(newSubdomain, clientId)
      .then(() => {
        getInternalSubdomains();
        setDisplayAdditionalEntry(false);
      })
      .catch(handleServerError);

  const deleteSubdomain = (id: number) =>
    internalSubdomainsModel
      .deleteInternalSubdomain(id, clientId)
      .then(() => {
        getInternalSubdomains();
      })
      .catch(handleServerError)
      .finally(() => setSubdomainToDelete(undefined));

  return (
    <CardComponent title={t('components.internalSubdomains.title')} sm={12}>
      <Grid container spacing={4} direction="column">
        {internalSubdomains.size > 0 && (
          <Grid item container direction="column" spacing={4}>
            {Array.from(internalSubdomains).map(([, subdomain]) => (
              <InternalSubdomainEntry
                key={subdomain.id}
                subdomain={subdomain}
                disableDelete={subdomain.isRoot || internalSubdomains.size === 1}
                onSave={(updatedSubdomain: string) => updateSubdomain(updatedSubdomain, subdomain.id)}
                onDelete={() => setSubdomainToDelete(subdomain)}
              />
            ))}
          </Grid>
        )}
        {displayAdditionalEntry && (
          <Grid item container direction="column" spacing={4}>
            <InternalSubdomainEntry
              subdomain={{ isEditable: true, host }}
              onSave={createSubdomain}
              onDelete={() => setDisplayAdditionalEntry(false)}
            />
          </Grid>
        )}
        <Grid item container justify="flex-end">
          <ButtonComponent
            disabled={displayAdditionalEntry}
            color="primary"
            text={t('components.internalSubdomains.add')}
            onClick={() => setDisplayAdditionalEntry(true)}
          />
        </Grid>
      </Grid>
      {subdomainToDelete && (
        <DeleteInternalSubdomainDialog
          onSubmit={() => deleteSubdomain(subdomainToDelete.id)}
          onClose={() => setSubdomainToDelete(undefined)}
          host={subdomainToDelete.host}
        />
      )}
    </CardComponent>
  );
};
