import React, { useState, useEffect } from 'react';

import { Typography, Grid, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'components';
import { LINKS } from 'components/constants';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { authModel } from 'models';
import { AuthModelFields } from 'models/AuthModel';
import { AUTH_HEADERS } from 'models/constants';
import { invitesModel } from 'models/InvitesModel';
import { ROUTES } from 'routes/constants';
import { getQueryParams, getServerError } from 'utils';

import {
  StyledButtonComponent,
  AuthCard,
  TermsAndConditionsText,
  RedirectText,
  TextValidator,
  FormLink,
} from '../style';

const SignUpWithInvitePage = () => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();
  const history = useHistory();

  const [state, setState] = useState({
    [AuthModelFields.TOKEN]: '',
    [AuthModelFields.SPACE]: '',
    [AuthModelFields.EMAIL]: '',
    [AuthModelFields.FIRST_NAME]: '',
    [AuthModelFields.LAST_NAME]: '',
    [AuthModelFields.PASSWORD]: '',
    [AuthModelFields.PASSWORD_CONFIRMATION]: '',
    [AuthModelFields.TERMS]: false,
  });

  useEffect(() => {
    const { token } = getQueryParams() as { token: string };

    if (token)
      invitesModel
        .verifyToken({ token })
        .then(({ client, email }) => {
          setState(prevState => ({
            ...prevState,
            [AuthModelFields.TOKEN]: token,
            [AuthModelFields.SPACE]: client,
            [AuthModelFields.EMAIL]: email,
          }));
        })
        .catch(() => history.push(ROUTES.linkExpired));
    else history.push(ROUTES.home);
  }, []);

  const submitForm = () => {
    if (state.password !== state.passwordConfirmation) {
      handleServerError(getServerError(t('errorsTexts.passwordMismatch'), authModel.fields.passwordConfirmation));
      return;
    }

    invitesModel
      .signUpWithInvite({
        token: state[AuthModelFields.TOKEN],
        firstName: state[AuthModelFields.FIRST_NAME],
        lastName: state[AuthModelFields.LAST_NAME],
        password: state[AuthModelFields.PASSWORD],
      })
      .then(({ pubtoolToken }) => {
        localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, pubtoolToken);
        history.push(ROUTES.home);
      })
      .catch(handleServerError);
  };

  const handleChange = (item: { [key: string]: string }) => {
    setState(prevState => ({
      ...prevState,
      ...item,
    }));
  };

  const disableSignUpButton = Object.values(state).some(item => !item);

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      [AuthModelFields.TERMS]: event.target.checked,
    }));
  };

  const onBackToSetup = () =>
    history.push({
      pathname: ROUTES.inviteSelector,
      search: window.location.search,
    });

  if (!state[AuthModelFields.TOKEN]) return null;

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signUpWithInvitePage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signUpWithInvitePage.title')}</Typography>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={submitForm} style={{ width: '100%' }}>
            <TextValidator label={t('common.forms.labels.spaceName')} value={state[AuthModelFields.SPACE]} disabled />
            <TextValidator label={t('common.forms.labels.email')} value={state[AuthModelFields.EMAIL]} disabled />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.firstName')}
              value={state[AuthModelFields.FIRST_NAME]}
              fieldName={AuthModelFields.FIRST_NAME}
              handleChange={handleChange}
              autoComplete="given-name"
              autoFocus
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
              label={t('common.forms.labels.lastName')}
              value={state[AuthModelFields.LAST_NAME]}
              fieldName={AuthModelFields.LAST_NAME}
              handleChange={handleChange}
              autoComplete="family-name"
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required]}
              label={t('common.forms.labels.password')}
              value={state[AuthModelFields.PASSWORD]}
              fieldName={AuthModelFields.PASSWORD}
              handleChange={handleChange}
              type="password"
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required]}
              label={t('common.forms.labels.passwordConfirmation')}
              value={state[AuthModelFields.PASSWORD_CONFIRMATION]}
              fieldName={AuthModelFields.PASSWORD_CONFIRMATION}
              handleChange={handleChange}
              type="password"
            />
            <Grid container direction="column" spacing={6}>
              <Grid item container alignItems="center" wrap="nowrap">
                <FormControlLabel
                  style={{
                    border: 'none',
                    paddingTop: '20px',
                  }}
                  control={<Checkbox color="primary" checked={state.terms} onChange={handleTermsChange} />}
                  label={
                    <TermsAndConditionsText>
                      {`${t('pages.auth.common.termsAndConditionsCheckbox')}`}
                      <Link href={LINKS.terms}>{t('pages.auth.common.terms')}</Link>
                      {` ${t('pages.auth.common.and')} `}
                      <Link href={LINKS.privacyPolicy}>{t('pages.auth.common.privacy')}</Link>
                    </TermsAndConditionsText>
                  }
                />
              </Grid>
              <Grid item>
                <StyledButtonComponent
                  fullWidth
                  color="primary"
                  type="submit"
                  text={t('pages.auth.signUpWithInvitePage.continueBtn')}
                  disabled={disableSignUpButton}
                />
              </Grid>
              <Grid item>
                <RedirectText>
                  {`${t('pages.auth.signUpWithInvitePage.backToSetupText')} `}
                  <FormLink
                    variant="text"
                    onClick={onBackToSetup}
                    text={t('pages.auth.signUpWithInvitePage.backToSetupLink')}
                  />
                </RedirectText>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default SignUpWithInvitePage;
