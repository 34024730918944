import React from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';

import { FieldValidator } from 'components/FormComponents/FieldValidator/FieldValidator';
import {
  withFormValidation,
  withFormValidationPropTypes,
} from 'components/FormComponents/withFormValidation/withFormValidation';
import config from 'config/Config';

export const Captcha = ({ onChange, validators, errorMessages, serverError, value, captchaRef }) => {
  const handleChange = token => {
    onChange({ target: { value: token } });
  };

  const handleExpire = () => {
    onChange({ target: { value: null } });
    return captchaRef?.current.resetCaptcha();
  };

  return (
    <FieldValidator validators={validators} errorMessages={errorMessages} serverError={serverError} value={value}>
      <HCaptcha sitekey={config.captcha_key} onVerify={handleChange} onExpire={handleExpire} ref={captchaRef} />
    </FieldValidator>
  );
};

Captcha.propTypes = {
  ...withFormValidationPropTypes,
};

export const CaptchaValidatorComponent = withFormValidation(Captcha);
