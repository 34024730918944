import { InputBase, styled } from '@material-ui/core';
import { darken } from 'polished';

import { COLORS } from 'theme/constants';

export const Search = styled('div')(({ theme }) => ({
  borderRadius: '2px',
  backgroundColor: COLORS.white,
  display: 'none',
  position: 'relative',
  width: '100%',

  '&:hover': {
    backgroundColor: darken(0.05, COLORS.white),
  },

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const SearchIconWrapper = styled('div')({
  width: '50px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    width: '22px',
    height: '22px',
  },
});

export const Input = styled(InputBase)(({ theme }) => ({
  color: 'inherit',

  '& input': {
    color: COLORS.cozmosBlue,
    padding: `${theme.spacing(2.5)}px`,
    paddingLeft: `${theme.spacing(12)}px`,
    width: '160px',
  },
}));
