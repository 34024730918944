import { Typography as MuiTypography, Box as MuiBox, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const DropzoneStyledComponent = styled(MuiBox)({
  '&.empty': {
    marginTop: '4px',
    height: '100%',
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export const DropzoneTypography = styled(MuiTypography)({
  fontSize: '14px',
  textAlign: 'center',
  pointerEvents: 'none',
  lineHeight: 1.71,
  letterSpacing: '-0.66px',
  fontWeight: 500,
  color: COLORS.greyText,
  maxWidth: '663px',
});

export const DropzoneBox = styled(MuiBox)({
  background: 'white',
  textAlign: 'left',
  width: '100%',
  margin: '14px 0 8px 0',
  padding: '20px',
  border: `2px dashed ${COLORS.cozmosBlue}70`,
  borderRadius: '5px',
  '&:active': { borderStyle: 'solid' },
  '&.disabled': {
    borderColor: COLORS.greyBorder,
    borderStyle: 'dashed',
    pointerEvents: 'none',
  },
});
