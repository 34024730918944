import React from 'react';

import { Typography } from '@material-ui/core';

import { ReactComponent as RadioItemIcon } from '../../assets/icons/assets/animation-position-icon.svg';

import { Box } from './style';

export const EntranceAnimationRadioItem = ({ item }: Record<string, any>) => (
  <Box>
    <Typography variant="caption">{item.title}</Typography>
    <Box className={`icon-container ${item.value}`}>
      <RadioItemIcon />
    </Box>
  </Box>
);
