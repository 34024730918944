import React, { ChangeEvent } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import { SocialContentInterface } from 'types';
import { DisclaimerText } from 'pages/settings/SocialIntegration/InstagramIntegration/style';
import { getTWithPathKey } from 'utils';

import { ColorPicker } from '../ColorPicker/ColorPicker';
import { CardComponent } from '../CardComponent/CardComponent';
import { IFrameColorComponent } from '../IFrameColorComponent/IFrameColorComponent';
import { IFrameScrollbarComponent } from '../IFrameScrollbarComponent/IFrameScrollbarComponent';

import { Switch } from '../style';

interface IFrameStylesComponentInterface {
  socialMediaSettings: SocialContentInterface,
  setSocialMediaSettings: Function
}

export const IFrameStylesComponent = ({
  socialMediaSettings,
  setSocialMediaSettings
}: IFrameStylesComponentInterface) => {
  const onSwitchChange = (propName: string) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => setSocialMediaSettings((prevState: any) => ({
    ...prevState,
    [propName]: checked
  }));
  const getT = getTWithPathKey('components.iframeStylesComponent');

  const onColorChange =
    (name: string) =>
      ({ hex }: any) =>
        setSocialMediaSettings((prevColors: any) => ({ ...prevColors, [name]: hex }));

  return (
    <CardComponent>
      <Box mb={6}>
        <Typography variant="h2" style={{ textTransform: 'uppercase' }} gutterBottom>Overlay Settings</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h4" display="inline">{getT('title')}</Typography>
      </Box>
      <Box my={6} ml={-2}>
        <Typography variant="h4">
          <Switch
            checked={socialMediaSettings.showLoader ?? false}
            onChange={event => onSwitchChange('showLoader')(event)}
          />
          {getT('showLoaderTitle')}
        </Typography>
      </Box>
      <Box my={6} ml={-2}>
        <Typography variant="h4">
          <Switch
            checked={socialMediaSettings.hideOverlayContainer ?? false}
            onChange={event => onSwitchChange('hideOverlayContainer')(event)}
          />
          {getT('primaryToggleTitle')}
        </Typography>
      </Box>
      {!socialMediaSettings.hideOverlayContainer && (
        <Box mt={2} display="flex" alignItems="center">
          <ColorPicker setColor={onColorChange('containerColor')} color={{ hex: socialMediaSettings.containerColor }} />
          <Box ml={2}>
            <Typography variant="caption">{getT('colorHelperText')}</Typography>
          </Box>
        </Box>
      )}
      <Box my={6} ml={-2}>
        <Typography variant="h4">
          <Switch
            checked={socialMediaSettings.containerTransparent ?? false}
            onChange={onSwitchChange('containerTransparent')}
          />
          {getT('secondaryToggleTitle')}
        </Typography>
      </Box>
      <DisclaimerText>{getT('hintText')}</DisclaimerText>
      <Box my={6}>
        <Divider />
      </Box>
      <IFrameColorComponent
        {...{
          onColorChange,
          primaryColorKey: 'closeIconColor',
          primaryColorValue: socialMediaSettings.closeIconColor,
          secondaryColorKey: 'closeBgColor',
          secondaryColorValue: socialMediaSettings.closeBgColor,
          useTitle: false,
          subTitle: 'Close Overlay Icon'
        }}
      />
      <Box my={6}>
        <Divider />
      </Box>
      <IFrameScrollbarComponent
        {...{
          onColorChange,
          primaryColorKey: 'scrollIndicatorFill',
          primaryColorValue: socialMediaSettings.scrollIndicatorFill,
          secondaryColorKey: 'scrollContainerFill',
          secondaryColorValue: socialMediaSettings.scrollContainerFill
        }}
      />
    </CardComponent>
  );
}
