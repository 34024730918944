import React, { memo } from 'react';

import { Grid } from '@material-ui/core';
import { includes } from 'lodash';
import PropTypes from 'prop-types';

import { PLANOGRAM_VERSION_STATUS } from 'pages/planograms/constants';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';

function UnderConstructionComponent({ planogram }) {
  return (
    <Grid container>
      {includes(planogram.planogramVersionsStatuses, PLANOGRAM_VERSION_STATUS.generated) && (
        <StatusComponent status={PLANOGRAM_VERSION_STATUS.generated} />
      )}
      {includes(planogram.planogramVersionsStatuses, PLANOGRAM_VERSION_STATUS.draft) && (
        <StatusComponent status={PLANOGRAM_VERSION_STATUS.draft} />
      )}
    </Grid>
  );
}

UnderConstructionComponent.propTypes = {
  planogram: PropTypes.shape({
    planogramVersionsStatuses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export const UnderConstruction = memo(UnderConstructionComponent);
