import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { isEqual } from 'lodash';

import { CardComponent, SettingsForm, ImageChooseComponent } from 'components';
import { getTWithPathKey } from 'utils';
import { useSubmittingState } from 'hooks';
import { PaginationParams } from 'types';
import { SphereLoader } from 'types/sphereLoader';
import { otherAssetsModel } from 'models';

import { Description } from '../EntranceAnimationSettingsCard/style';
import { Switch } from '../style';

interface SphereLoaderCardProps {
  settings: SphereLoader;
  handleServerError: (err: any) => void;
  onSettingsSave: (settings: any) => Promise<void>;
}

const SphereLoaderCard = ({
  settings,
  handleServerError,
  onSettingsSave
}: SphereLoaderCardProps) => {
  const getT = getTWithPathKey('pages.settings.sphereLoader');
  const [state, setState] = useState<SphereLoader>(settings);
  const [initialState, setInitialState] = useState<SphereLoader>(settings);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    setState(settings);
    setInitialState(settings);
  }, [settings]);

  const getImages = (params: PaginationParams) => otherAssetsModel.getImages(params);

  const handleChange = (item: Record<string, any>) =>
    setState(prevState => ({
      ...prevState,
      ...item
    }));

  const renderNav = () =>
    <Switch checked={state.enabled} onChange={e => handleChange({ enabled: e.target.checked })} />;

  const getIsSaveButtonVisible = () => !isSubmitting && !isEqual(initialState, state);

  const handleSave = withHandlingSubmittingState(() => onSettingsSave(state));

  return (
    <>
      <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
        <CardComponent
          title={getT('title')}
          sm={8}
          CardHeaderNav={renderNav}
          isLoading={isSubmitting}
        >
          <Grid container spacing={6}>
            {state.enabled && (
              <>
                <Grid item>
                  <Description>{getT('caption')}</Description>
                </Grid>
                <Grid item sm={4}>
                  <ImageChooseComponent
                    image={state.otherAsset}
                    setImage={val => handleChange({ otherAsset: val })}
                    handleServerError={handleServerError}
                    dialogTitle="Choose file"
                    getPictures={getImages}
                    withDelete
                    className="max-height"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </CardComponent>
      </SettingsForm>
    </>
  );
}

export default SphereLoaderCard;
