import React, { useState } from 'react';

import { TableCell, TableRow, Typography } from '@material-ui/core';
import { camelCase, isArray } from 'lodash';
import PropTypes from 'prop-types';

import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { MultilanguageTextValidator } from 'components/FormComponents';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { LoadFileComponent } from 'components/LoadFileComponent/LoadFileComponent';
import { TextFieldComponent } from 'components/TextFieldComponent/TextFieldComponent';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { activeStorageModel } from 'models';
import { BUTTON_VIEW_PARAMS } from 'pages/settings/PurchasingFlowSettings/constants';
import { getBlobUrl, getTWithPathKey, handleGetTextValue } from 'utils';
import { withExpand, withExpandPropTypes } from 'utils/enhancers';

import { ECOMMERCE_OVERLAY_BUTTON_ADDITIONAL_PROPS } from '../constants';

import { TableHeader } from './TableHeader';

import { Box } from '../style';
import { Checkbox } from 'components/Dialogs/style';

function EcommerceOverlayButtonComponent({
  button,
  setButton,
  handleToggleExpand,
  expanded,
  renderExpand,
  pageLanguageTabValue,
  isLanguageCodeVisible,
}) {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const { handleServerError } = useServerErrorActions();
  const additionalProps = ECOMMERCE_OVERLAY_BUTTON_ADDITIONAL_PROPS[button.buttonType];

  const getBtnT = getTWithPathKey(`components.ecommerce.buttonStyles.${camelCase(button.buttonType)}`);
  const getStylesT = getTWithPathKey('components.ecommerce.buttonStyles');

  const handleSetField = (fieldName, useBorder) => value => {
    const newBtnSettings = { ...button, [fieldName]: value };

    if (useBorder) newBtnSettings[useBorder] = true;

    return setButton(newBtnSettings);
  };

  const getTitle = title =>
    ECOMMERCE_OVERLAY_BUTTON_ADDITIONAL_PROPS[button.buttonType].isUppercase
      ? title.map(item => ({
          ...item,
          value: item.value?.toUpperCase(),
        }))
      : title;

  const handleSetTextField = field =>
    setButton({
      ...button,
      title: getTitle(field.title),
    });

  const handleSetLogoFile = file => {
    if (file) {
      setIsLoadingFile(true);
      activeStorageModel.createUpload(file).create((error, blob) => {
        setIsLoadingFile(false);
        if (error) {
          handleServerError(error);
        } else {
          setButton({
            ...button,
            iconUrl: getBlobUrl(blob.key),
            signedId: blob.signed_id,
            removeImage: false,
          });
        }
      });
    } else {
      setButton({ ...button, iconUrl: '', removeImage: true });
    }
  };

  const handleSetWidth =
    fieldName =>
    ({ target: { value } }) => {
      const { minWidth, maxWidth } = BUTTON_VIEW_PARAMS;

      if (value) {
        handleSetField(fieldName)(Math.max(minWidth, Math.min(maxWidth, value)));
      } else {
        handleSetField(fieldName)(value);
      }
    };

  const handleCheckedColorChange =
    additionalField =>
    ({ target: { value } }) => {
      const currentButton = {
        ...button,
        [value]: button[value].hex ? { hex: '' } : BUTTON_VIEW_PARAMS.defaultHexColor,
      };

      if (additionalField) {
        currentButton[additionalField] = Boolean(!button[value].hex);
      }

      setButton(currentButton);
    };

  const renderButton = () => (
    <>
      <Box mt={5}>
        <Typography variant="h6" className="information">
          {getStylesT('text')}
        </Typography>
      </Box>
      <Box mt={2}>
        <MultilanguageTextValidator
          fieldName="title"
          label={getStylesT('text')}
          value={getTitle(button.title)}
          disabled={additionalProps.titleDisabled}
          handleChange={handleSetTextField}
          multilanguage={isArray(button.title)}
          handleGetValue={handleGetTextValue('title')}
          pageLanguageTabValue={pageLanguageTabValue}
          isLanguageCodeVisible={isLanguageCodeVisible}
        />
      </Box>
      {additionalProps.withButtonIconUpload && (
        <>
          <Box mb={2}>
            <Typography variant="h6" className="information">
              {getStylesT('icon')}
            </Typography>
          </Box>
          <LoadFileComponent
            accept={ACCEPT_EXTENSIONS.imgIcon}
            text={getStylesT('importFile')}
            file={button.iconUrl}
            loadFile={handleSetLogoFile}
            handleServerError={handleServerError}
            isLoading={isLoadingFile}
          />
        </>
      )}
      <Box mt={6} mb={1.5}>
        <Typography variant="h2">{getStylesT('activeState.title')}</Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="subtitle1" className="information">
          {getStylesT('activeState.caption')}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h6" className="information">
          {getStylesT('color')}
        </Typography>
      </Box>
      <Box mt={2} display="flex" className="color-picker-wrap">
        <Checkbox
          color="primary"
          value="color"
          checked={Boolean(button.color.hex)}
          onChange={handleCheckedColorChange()}
        />
        <ColorPicker setColor={handleSetField('color')} color={button.color} />
      </Box>
      <Box mt={3.5}>
        <Typography variant="h6" className="information">
          {getStylesT('border')}
        </Typography>
      </Box>
      <Box display="flex" className="color-picker-wrap">
        <Checkbox
          color="primary"
          value="borderColor"
          checked={Boolean(button.borderColor.hex)}
          onChange={handleCheckedColorChange('useBorder')}
        />
        <ColorPicker setColor={handleSetField('borderColor', 'useBorder')} color={button.borderColor} />
        <TextFieldComponent
          className="ml-4"
          label="Width"
          type="number"
          onChange={handleSetWidth('borderWidth')}
          value={button.borderWidth}
          disabled={!button.useBorder}
        />
      </Box>
      {additionalProps.withDisabledState && (
        <>
          <Box mt={10}>
            <Typography variant="h2">{getStylesT('disabledState.title')}</Typography>
          </Box>
          <Box mt={1.5} mb={4}>
            <Typography variant="subtitle1" className="information">
              {getStylesT('disabledState.caption')}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h6" className="information">
              {getStylesT('color')}
            </Typography>
          </Box>
          <Box mt={2} display="flex" className="color-picker-wrap">
            <Checkbox
              color="primary"
              value="colorDisabled"
              checked={Boolean(button.colorDisabled.hex)}
              onChange={handleCheckedColorChange()}
            />
            <ColorPicker setColor={handleSetField('colorDisabled')} color={button.colorDisabled} />
          </Box>
          <Box>
            <Typography variant="h6" className="information">
              {getStylesT('border')}
            </Typography>
          </Box>
          <Box display="flex" className="color-picker-wrap">
            <Checkbox
              color="primary"
              value="borderColorDisabled"
              checked={Boolean(button.borderColorDisabled.hex)}
              onChange={handleCheckedColorChange('useBorderDisabled')}
            />
            <ColorPicker
              setColor={handleSetField('borderColorDisabled', 'useBorderDisabled')}
              color={button.borderColorDisabled}
            />
            <TextFieldComponent
              className="ml-4"
              label="Width"
              type="number"
              onChange={handleSetWidth('borderWidthDisabled')}
              value={button.borderWidthDisabled}
              disabled={!button.useBorderDisabled}
            />
          </Box>
        </>
      )}
    </>
  );

  return (
    <>
      <TableRow hover onClick={handleToggleExpand}>
        <TableCell>
          <TableHeader title={getBtnT('title')} subtitle={getBtnT('caption')} />
        </TableCell>
        <TableCell>{renderExpand()}</TableCell>
      </TableRow>
      {expanded && (
        <TableRow hover>
          <TableCell colSpan={2}>{renderButton()}</TableCell>
        </TableRow>
      )}
    </>
  );
}

EcommerceOverlayButtonComponent.propTypes = {
  ...withExpandPropTypes,
  button: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({ languageCode: PropTypes.string, value: PropTypes.string })),
    ]),
    buttonType: PropTypes.string,
    iconUrl: PropTypes.string,
    color: PropTypes.shape({ hex: PropTypes.string }),
    borderColor: PropTypes.shape({ hex: PropTypes.string }),
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderWidthDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    useBorder: PropTypes.bool,
    useBorderDisabled: PropTypes.bool,
    colorDisabled: PropTypes.shape({ hex: PropTypes.string }),
    borderColorDisabled: PropTypes.shape({ hex: PropTypes.string }),
  }).isRequired,
  isLanguageCodeVisible: PropTypes.bool,
};

EcommerceOverlayButtonComponent.defaultProps = {
  isLanguageCodeVisible: false,
};

export const EcommerceOverlayButton = withExpand(EcommerceOverlayButtonComponent);
