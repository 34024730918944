import {
  styled,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  IconButton as MuiIconButton,
} from '@material-ui/core';

interface TableRowProps {
  level?: number;
  blocked?: boolean;
}

interface IconButtonProps {
  visibility?: 'visible' | 'hidden';
}

export const TableRow = styled(MuiTableRow)(({ level = 0, blocked }: TableRowProps) => ({
  opacity: blocked ? 0.7 : 1,

  '& > *:first-child': {
    paddingLeft: `${16 + level * 40}px`,
  },
}));

export const IconButton = styled(MuiIconButton)(({ visibility }: IconButtonProps) => ({
  visibility,
  marginRight: '12px',
}));
