import React, { memo, useMemo } from 'react';

import { Checkbox, Box } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { MultilanguageTextValidator, MultilanguageAutocompleteValidator } from 'components/FormComponents';
import { MATCH_REGEXP, VALIDATORS_MAP } from 'components/FormComponents/const';
import { INPUT_TYPES } from 'constants/technical';
import { PRODUCT_FIELD_TYPE } from 'pages/products/constants';
import { handleGetCheckboxValue, handleGetLoadedFile, handleGetTextValue } from 'utils';

import { MultilanguageCheckbox } from './MultilanguageCheckbox/MultilanguageCheckbox';
import { MultilanguageLoadFile } from './MultilanguageLoadFile/MultilanguageLoadFile';

function ProductFieldComponent({ field, handleChangeProduct, handleServerError, additionalClassName, ...restProps }) {
  const getPropsForTextField = () => ({
    key: field.name,
    fieldName: field.name,
    label: field.caption,
    disabled: field.readonly,
    validators: field.mandatory ? [VALIDATORS_MAP.required] : [],
    handleChange: handleChangeProduct,
    multilanguage: Boolean(field.multilanguage),
    handleGetValue: handleGetTextValue(field.name),
    additionalClassName,
    additionalValidators: [
      {
        rule: `${MATCH_REGEXP}${get(field, 'validation', '')}`,
        message: `${get(field, 'errorMessage', '')}`,
      },
    ],
  });

  const getSelectList = options => options.map(({ value, ...rest }) => ({ id: value, ...rest }));

  const getProps = useMemo(() => {
    switch (field.type) {
      case PRODUCT_FIELD_TYPE.STRING:
        return getPropsForTextField();
      case PRODUCT_FIELD_TYPE.TEXT:
        return {
          ...getPropsForTextField(),
          rows: 5,
          multiline: true,
        };
      case PRODUCT_FIELD_TYPE.FLOAT:
        return {
          ...getPropsForTextField(),
          type: INPUT_TYPES.NUMBER,
          inputProps: { step: 0.01 },
        };
      case PRODUCT_FIELD_TYPE.INTEGER:
        return {
          ...getPropsForTextField(),
          type: INPUT_TYPES.NUMBER,
        };
      case PRODUCT_FIELD_TYPE.SELECT:
        return {
          ...getPropsForTextField(),
          itemsNamePath: 'label',
          selectList: getSelectList(field.options),
        };
      case PRODUCT_FIELD_TYPE.BOOL:
        return {
          ml: -3,
          key: field.name,
          control: <Checkbox color="primary" />,
          label: field.caption,
          disabled: field.readonly,
          fieldName: field.name,
          handleChange: handleChangeProduct,
          handleGetValue: handleGetCheckboxValue,
          multilanguage: true,
        };
      case PRODUCT_FIELD_TYPE.FILE:
        return {
          accept: field.accept,
          text: 'Upload file',
          key: field.name,
          handleChange: handleChangeProduct,
          multilanguage: Boolean(field.multilanguage),
          title: field.caption,
          fieldName: field.name,
          handleGetValue: handleGetLoadedFile,
          handleServerError,
        };

      default:
        return {};
    }
  }, [field.readonly]);

  const renderField = () => {
    const props = { ...getProps, ...restProps };

    switch (field.type) {
      case PRODUCT_FIELD_TYPE.STRING:
      case PRODUCT_FIELD_TYPE.TEXT:
      case PRODUCT_FIELD_TYPE.FLOAT:
      case PRODUCT_FIELD_TYPE.INTEGER:
        return <MultilanguageTextValidator {...props} />;
      case PRODUCT_FIELD_TYPE.SELECT:
        return <MultilanguageAutocompleteValidator {...props} />;
      case PRODUCT_FIELD_TYPE.BOOL:
        return (
          <Box mb={2}>
            <MultilanguageCheckbox {...props} />
          </Box>
        );
      case PRODUCT_FIELD_TYPE.FILE:
        return <MultilanguageLoadFile {...props} />;
      default:
        return null;
    }
  };

  return field?.name && renderField();
}

ProductFieldComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    validation: PropTypes.string,
    errorMessage: PropTypes.string,
    caption: PropTypes.string,
    readonly: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(PRODUCT_FIELD_TYPE)),
    accept: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    mandatory: PropTypes.bool,
  }),
  fieldValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        languageCode: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.shape({ fieldName: PropTypes.string }),
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      }),
    ),
    PropTypes.shape({ fieldName: PropTypes.string }),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  handleChangeProduct: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  pageLanguageTabValue: PropTypes.number,
  handleValidationCheck: PropTypes.func,
  additionalClassName: PropTypes.string,
};

ProductFieldComponent.defaultProps = {
  field: {},
  additionalClassName: '',
};

export const ProductField = memo(ProductFieldComponent);
