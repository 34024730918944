import { Box as MuiBox, CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';

import { drawerWidth } from 'layouts/Dashboard/constants';
import { COLORS, ICON_SIZE } from 'theme/constants';

export const BoxContainer = withStyles({
  root: {
    position: 'fixed',
    bottom: '14px',
    left: `calc(${drawerWidth}px + (100% - ${drawerWidth}px)/2)`,
    width: '600px',
    transform: 'translateX(-50%)',
    backgroundColor: COLORS.white,
    borderRadius: '8px',
    zIndex: 1000,
    padding: '10px 48px 10px 20px',
    boxShadow: '0 4px 12px 0 rgba(201, 201, 201, 0.6)',
    '&.success': {
      width: '230px',
      color: COLORS.greenDeep,
      backgroundColor: COLORS.greenLight,
      border: `solid 1px ${COLORS.green}`,
      padding: '15px',
      '& > .MuiBox-root': { display: 'flex', alignItems: 'center' },
    },
  },
})(MuiBox);

export const CloseWrapBox = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
  },
})(MuiBox);

export const CloseIcon = withStyles({
  root: {
    height: ICON_SIZE.brand,
    width: ICON_SIZE.brand,
    color: COLORS.red,
  },
})(Close);

export const CircularProgress = withStyles({
  root: {
    height: `${ICON_SIZE.regular}!important`,
    width: `${ICON_SIZE.regular}!important`,
    marginLeft: '5px',
  },
})(MuiCircularProgress);

export const DoneIcon = withStyles({ root: { color: COLORS.greenDeep } })(Done);
