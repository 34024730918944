import React, { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { LINKS } from 'components/constants';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useServerErrorActions } from 'contexts/ServerErrorContext';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

export function AddNewClientDialog({ open, handleSubmit, onClose, resetServerErrors, isClientDataSubmitting }) {
  const defaultClient = {
    name: '',
    fullName: '',
    phone: '',
    email: '',
  };
  const [client, setClient] = useState(defaultClient);
  const [reservedWarning, setReservedWarning] = useState(false);
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();

  useEffect(() => resetForm, [open]);

  const resetForm = () => {
    setClient(defaultClient);
    resetServerErrors();
  };

  const handleChange = item =>
    setClient(prevState => ({
      ...prevState,
      ...item,
    }));

  const onSubmit = () => {
    handleSubmit(client).catch(e => {
      if (e.response.data.warning === 'reserved_name') setReservedWarning(true);
      else handleServerError(e);
    });
  };

  const handleAcceptWarning = () => {
    handleSubmit(client, true).catch(handleServerError);
  };

  return (
    <>
      {reservedWarning ? (
        <Dialog fullWidth open={open} onClose={() => setReservedWarning(false)}>
          <DialogTitle>{t('pages.clients.reservedWarningTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ paddingLeft: 0 }}>
              <Trans
                i18nKey="pages.clients.reservedWarningMessage"
                components={{
                  b: <b />,
                  a: (
                    <a href={LINKS.reservedClientNames} target="_blank" rel="noreferrer">
                      link
                    </a>
                  ),
                }}
                values={{ name: client.name }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonComponent onClick={() => setReservedWarning(false)} text={t('common.buttons.cancel')} />
            <ButtonComponent text={t('common.buttons.confirm')} onClick={handleAcceptWarning} color="primary" />
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog fullWidth open={open} onClose={onClose}>
          <ValidatorForm onSubmit={onSubmit}>
            <DialogTitle>{t('pages.clients.addNewClient')}</DialogTitle>
            <DialogContent>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.lettersNumbersSpacesUnderscoresDash]}
                label={t('pages.clients.name')}
                value={client.name}
                fieldName="name"
                handleChange={handleChange}
                autoFocus
              />
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.withoutSpecialSymbols]}
                label={t('pages.clients.legalName')}
                value={client.fullName}
                fieldName="fullName"
                handleChange={handleChange}
              />
              <Typography variant="h2">{t('pages.clients.domainTitle')}</Typography>
              <TextValidatorComponent
                label={t('pages.clients.domainTitle')}
                value={`${client.name}.cozmos.com`}
                disabled
              />
              <Typography variant="h2">{t('pages.clients.contactsTitle')}</Typography>
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.numbersOnly]}
                label={t('pages.clients.phone')}
                value={client.phone}
                fieldName="phone"
                handleChange={handleChange}
                type="phone"
              />
              <TextValidatorComponent
                validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
                label={t('pages.clients.email')}
                value={client.email}
                fieldName="email"
                handleChange={handleChange}
                type="email"
              />
            </DialogContent>
            <DialogActions>
              <ButtonComponent onClick={onClose} text={t('common.buttons.cancel')} />
              <ButtonComponent
                type="submit"
                color="primary"
                text={t('common.buttons.save')}
                loaderSize="1.35em"
                loading={isClientDataSubmitting}
                disabled={isClientDataSubmitting}
              />
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      )}
    </>
  );
}

AddNewClientDialog.defaultProps = {};
