import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { get } from 'lodash';

import { CardComponent, PageHeader, ImageUploader } from 'components';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { faviconsModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';
import { useDeleteSelectedAssets } from 'hooks';

function Favicon({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }) {
  const [favicons, setFavicons] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleDeleteAllRequest } = useDeleteSelectedAssets();
  const getT = getTWithPathKey('pages.assets.Favicon');

  useEffect(() => {
    getFavicons();
  }, [paginationParams]);

  const getFavicons = withPageProgressHandler(() =>
    faviconsModel
      .getFavicons(paginationParams)
      .then(data => {
        setFavicons(data.favicons);
        setPaginationItemsCount(data.count);
      })
      .catch(handleServerError)
  );

  const loadImage = image => {
    const signedId = get(image, 'signed_id');

    return faviconsModel.addFavicon({ signedId }).then(resetPaginationPage);
  };

  const unloadImage = imageId => {
    faviconsModel.deleteFavicon(imageId).then(getFavicons).catch(handleServerError);
  };

  const handleEditImage = (id, data) => faviconsModel.editFavicon(id, data).then(getFavicons).catch(handleServerError);

  return (
    <>
      <PageHeader title={`Favicon: ${paginationItemsCount}`} backLink={ROUTES.assets} />
      <Grid container spacing={6}>
        <CardComponent sm={12} title={getT('title')}>
          <ImageUploader
            accept={ACCEPT_EXTENSIONS.imgIcon}
            images={favicons}
            loadImage={loadImage}
            unloadImage={unloadImage}
            handleServerError={handleServerError}
            handleDeleteAll={assets => handleDeleteAllRequest(assets, faviconsModel.deleteFavicon, getFavicons)}
            text={getT('imageUploaderText')}
            section={DOWNLOAD_SECTIONS.favicon}
            withEdit
            handleEdit={handleEditImage}
            withUploadButton
            maxFiles={20}
          />
        </CardComponent>
      </Grid>
    </>
  );
}

Favicon.propTypes = {
  ...withPaginationPropTypes,
};

export default withPagination(Favicon);
