import { AxiosResponse } from 'axios';

import {
  ClientsListResponse,
  AddClientParams,
  ClientExtendedInfo,
  EditClientParams,
  GetClientsParams,
  ReservedClientName,
  SubscriptionResponse,
  EditClientResponse,
} from 'types/clients';
import { trimItems } from 'utils';

import { api } from './setupApi';

class ClientsModel {
  link = '/clients';

  getClients = (params: GetClientsParams): Promise<ClientsListResponse> =>
    api.get<ClientsListResponse>(`${this.link}/search`, { params }).then(({ data }) => data);

  getClient = (id: number): Promise<ClientExtendedInfo> =>
    api.get<ClientExtendedInfo>(`${this.link}/${id}`).then(({ data }) => data);

  addClient = (data: AddClientParams) =>
    api.post(this.link, trimItems(data, ['name', 'fullName'])).then(({ data: { client } }) => client);

  editClient = (id: number, data: EditClientParams) =>
    api.put<EditClientResponse>(`${this.link}/${id}`, trimItems(data, ['name', 'fullName'])).then(({ data }) => data);

  deleteClient = (id: number) => api.delete(`${this.link}/${id}`);

  checkReservedName = (name: string) =>
    api.get<ReservedClientName>(`${this.link}/check_reserved_name`, { params: { name } });

  getCurrentSubscripiton = (id: number): Promise<AxiosResponse<SubscriptionResponse | null>> =>
    api.get(`${this.link}/${id}/current_subscription`);
}

export const clientsModel = new ClientsModel();
