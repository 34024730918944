import React, { useEffect, useState } from 'react';

import { TableRow, TableCell, Typography } from '@material-ui/core';
import { set, head } from 'lodash';
import PropTypes from 'prop-types';

import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { TabsComponent } from 'components/TabsComponent/TabsComponent';
import { getTWithPathKey } from 'utils';

import { BUTTON_SHAPES, ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from '../constants';

import { EcommerceOverlayButton } from './EcommerceOverlayButton';

import { Switch } from 'components/style';
import { Box } from '../style';

const getButtonIndex = shapeName => Object.values(BUTTON_SHAPES).indexOf(shapeName);

export function ButtonsTable({
  ecommerceSettings,
  setSettings,
  pageLanguageTabValue,
  isLanguageCodeVisible,
  withShapeConfiguration,
  withControlColor,
}) {
  const [tabValue, setTabValue] = useState(getButtonIndex(ecommerceSettings.buttonShape));

  useEffect(() => {
    setTabValue(getButtonIndex(ecommerceSettings.buttonShape));
  }, [ecommerceSettings.buttonShape]);

  const getT = getTWithPathKey('components.ecommerce.buttonStyles');

  const handleSetButton = index => settings =>
    setSettings(prev => ({
      ...prev,
      ecommerceButtons: [...set(prev.ecommerceButtons, [[index]], settings)],
    }));

  const handleSetButtonShape = key =>
    setSettings(prev => ({ ...prev, buttonShape: Object.values(BUTTON_SHAPES)[key] }));

  const handleSetEcommerceCustomColors = color =>
    setSettings(prev => ({
      ...prev,
      ecommerceCustomColors: [{ ...head(prev.ecommerceCustomColors), color }],
    }));

  const handleSwitchChange = () =>
    setSettings(prev => ({ ...prev, enableButtons: !ecommerceSettings.enableButtons }));

  return (
    <OneColumnExpandedTable title={getT('title')} caption={getT('caption')}>
      {withControlColor && (
        <TableRow hover>
          <TableCell colSpan={2}>
            <Box my={2.5}>
              <Typography variant="h6" className="information">
                {getT('controls.title')}
              </Typography>
            </Box>
            <ColorPicker
              setColor={handleSetEcommerceCustomColors}
              color={head(ecommerceSettings.ecommerceCustomColors).color}
            />
          </TableCell>
        </TableRow>
      )}
      {ecommerceSettings.overlayType === ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp && (
        <TableRow>
          <TableCell colSpan={2}>
            <Box mb={0.5}>
              <Typography variant="h6">Button visibility</Typography>
            </Box>
            <Box my={2.5} display="flex" alignItems="center" className="m0">
              <Typography variant="h4">Hide buttons</Typography>
              <Switch checked={!ecommerceSettings?.enableButtons} onChange={handleSwitchChange} />
            </Box>
          </TableCell>
        </TableRow>
      )}
      {ecommerceSettings.ecommerceButtons.map((button, index) => (
        <EcommerceOverlayButton
          key={button.id}
          button={button}
          setButton={handleSetButton(index)}
          pageLanguageTabValue={pageLanguageTabValue}
          isLanguageCodeVisible={isLanguageCodeVisible}
        />
      ))}
      {withShapeConfiguration && (
        <TableRow hover>
          <TableCell colSpan={2}>
            <Box mt={2}>
              <Typography variant="h6" className="information">
                {getT('shapeStyle.title')}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <TabsComponent
                value={tabValue}
                setValue={handleSetButtonShape}
                tabNames={[getT(`shapeStyle.${BUTTON_SHAPES.square}`), getT(`shapeStyle.${BUTTON_SHAPES.round}`)]}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </OneColumnExpandedTable>
  );
}

ButtonsTable.defaultProps = {
  withShapeConfiguration: true,
  isLanguageCodeVisible: false,
};

ButtonsTable.propTypes = {
  ecommerceSettings: PropTypes.shape({
    ecommerceButtons: PropTypes.arrayOf(PropTypes.shape({})),
    buttonShape: PropTypes.string,
    ecommerceCustomColors: PropTypes.arrayOf(
      PropTypes.shape({ assignment: PropTypes.string, color: PropTypes.shape({}) }),
    ),
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
  pageLanguageTabValue: PropTypes.number.isRequired,
  isLanguageCodeVisible: PropTypes.bool,
  withShapeConfiguration: PropTypes.bool,
  withControlColor: PropTypes.bool.isRequired,
};
