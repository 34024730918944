import React, { useState } from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { getTWithPathKey } from 'utils';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from './style';

interface Props {
  open: boolean;
  onSave: (hide: boolean) => void;
  onClose: () => void;
}

export const UnlockDescriptionDialog = ({ open, onSave, onClose }: Props) => {
  const [isChecked, setChecked] = useState(false);
  const getT = getTWithPathKey('pages.products.manageProduct.shopifyDescription');
  const getCommonT = getTWithPathKey('common.buttons');

  const handleCheck = () => {
    setChecked(prevState => !prevState);
  };

  const handleSave = () => {
    onSave(isChecked);
    onClose();
  };

  return (
    <Dialog onClick={event => event.stopPropagation()} open={open} onClose={onClose} fullWidth>
      <DialogTitle>{getT('unlock')}</DialogTitle>
      <DialogContent>
        <Typography variant="caption">{getT('dialogText')}</Typography>
        <FormControlLabel
          label={getT('checkbox')}
          checked={isChecked}
          id="dialog-checkbox"
          onChange={handleCheck}
          control={<Checkbox color="primary" />}
        />
      </DialogContent>
      <DialogActions>
        <ButtonComponent onClick={onClose} text={getCommonT('cancel')} />
        <ButtonComponent onClick={handleSave} color="primary" text={getT('continue')} />
      </DialogActions>
    </Dialog>
  );
};
