import React from 'react';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { GridContainer, GridTitle, Span } from './style';

export function ItemWithData({ title, data, TitleIcon, children, className, hideColon = false }) {
  return (
    <GridContainer container wrap="nowrap" className={className}>
      <GridTitle item xs={12} sm={4}>
        {TitleIcon && <TitleIcon />}
        <Span>{`${title}${hideColon ? '' : ':'}`}</Span>
      </GridTitle>
      <Grid item xs={12}>
        {data}
        {children}
      </Grid>
    </GridContainer>
  );
}
ItemWithData.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]),
  TitleIcon: PropTypes.elementType,
  className: PropTypes.string,
};
ItemWithData.defaultProps = {
  title: '',
  data: '',
  children: null,
  TitleIcon: null,
  className: 'mb2',
};
