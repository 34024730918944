import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';
import { getInlineFontStyles } from 'utils';

import { FontSettingsPropTypes } from './constants';

import { PreviewBox } from './style';

export function FontSettingsEditDialogPreview({ settings, text }) {
  return (
    <PreviewBox
      contentEditable
      suppressContentEditableWarning
      style={{
        ...getInlineFontStyles(settings),
      }}
    >
      <ButtonPreviewWrapper>
        <Box my={2}>{text}</Box>
      </ButtonPreviewWrapper>
    </PreviewBox>
  );
}

FontSettingsEditDialogPreview.propTypes = {
  settings: FontSettingsPropTypes.isRequired,
  text: PropTypes.string,
};

FontSettingsEditDialogPreview.defaultProps = {
  text: 'Almost before we knew it, we had left the ground.',
};
