import React from 'react';

export function SmallDotsIcon() {
  /* eslint-disable */
  return (
    <svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" version="1.1">
      <path d="M3,15 C4.1045695,15 5,15.8954305 5,17 C5,18.1045695 4.1045695,19 3,19 C1.8954305,19 1,18.1045695 1,17 C1,15.8954305 1.8954305,15 3,15 Z M10,15 C11.1045695,15 12,15.8954305 12,17 C12,18.1045695 11.1045695,19 10,19 C8.8954305,19 8,18.1045695 8,17 C8,15.8954305 8.8954305,15 10,15 Z M17,15 C18.1045695,15 19,15.8954305 19,17 C19,18.1045695 18.1045695,19 17,19 C15.8954305,19 15,18.1045695 15,17 C15,15.8954305 15.8954305,15 17,15 Z M3,8 C4.1045695,8 5,8.8954305 5,10 C5,11.1045695 4.1045695,12 3,12 C1.8954305,12 1,11.1045695 1,10 C1,8.8954305 1.8954305,8 3,8 Z M10,8 C11.1045695,8 12,8.8954305 12,10 C12,11.1045695 11.1045695,12 10,12 C8.8954305,12 8,11.1045695 8,10 C8,8.8954305 8.8954305,8 10,8 Z M17,8 C18.1045695,8 19,8.8954305 19,10 C19,11.1045695 18.1045695,12 17,12 C15.8954305,12 15,11.1045695 15,10 C15,8.8954305 15.8954305,8 17,8 Z M3,1 C4.1045695,1 5,1.8954305 5,3 C5,4.1045695 4.1045695,5 3,5 C1.8954305,5 1,4.1045695 1,3 C1,1.8954305 1.8954305,1 3,1 Z M10,1 C11.1045695,1 12,1.8954305 12,3 C12,4.1045695 11.1045695,5 10,5 C8.8954305,5 8,4.1045695 8,3 C8,1.8954305 8.8954305,1 10,1 Z M17,1 C18.1045695,1 19,1.8954305 19,3 C19,4.1045695 18.1045695,5 17,5 C15.8954305,5 15,4.1045695 15,3 C15,1.8954305 15.8954305,1 17,1 Z" />
    </svg>
  );
}
