import React from 'react';

import { Typography, Grid, Button, Box } from '@material-ui/core';
import Helmet from 'react-helmet';

import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';

import { RouterLink } from 'components/style';

function Page500() {
  const getT = getTWithPathKey('pages.auth.Page500');

  return (
    <Box padding="100px 0">
      <Helmet title={getT('title')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography component="h1" variant="h1" align="center">
            {getT('title')}
          </Typography>
          <Typography component="h2" variant="h5" align="center">
            {getT('caption')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="body1" align="center">
            {getT('text')}
          </Typography>
        </Grid>
        <Grid item align="center">
          <Button component={RouterLink} to={ROUTES.home} variant="contained" color="primary" size="small">
            {getT('returnToWebsite')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Page500;
