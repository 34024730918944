import { camelCase, Dictionary, isEmpty } from 'lodash';
import queryString, { ParsedQuery } from 'query-string';

import { RESTRICTED_ROUTE_PERMISSIONS } from 'routes/constants';
import { Role } from 'types/enums';

import { AUTH_HEADERS, KEYS_IN_REDIRECT_URL } from '../models/constants';

import { getDataWithCamelCaseItems } from './changeCase';

export const getHeadersFromLocalStorage = (): Record<string, string> => {
  const token = localStorage.getItem(AUTH_HEADERS.PUBTOOL_TOKEN);

  return token ? { [AUTH_HEADERS.PUBTOOL_TOKEN]: token } : {};
};

export const setHeadersToLocalStorage = <T extends string>(headers: Record<string, T>) =>
  Object.values(AUTH_HEADERS).forEach(headerName => localStorage.setItem(headerName, headers[camelCase(headerName)]));

export const checkHasToken = () => Boolean(localStorage.getItem(AUTH_HEADERS.PUBTOOL_TOKEN));

export const savePermissionsToLS = (perm?: string[]) => {
  if (perm) {
    localStorage.setItem('permissions', perm.join(','));
  }
};

export const getQueryParams = (): ParsedQuery => queryString.parse(window.location.search);

export const saveHeadersFromQueryParams = () => {
  const queries = getQueryParams();

  if (!isEmpty(queries) && Object.keys(queries).every(q => KEYS_IN_REDIRECT_URL.includes(q))) {
    setHeadersToLocalStorage(getDataWithCamelCaseItems(queries as Dictionary<string>));
  }
};

export const getAuthorizedRoutes = (role: Role) =>
  Object.keys(RESTRICTED_ROUTE_PERMISSIONS).filter(path => RESTRICTED_ROUTE_PERMISSIONS[path].includes(role));
