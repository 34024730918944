import { Box as MuiBox, TableRow as MuiTableRow, TableHead as MuiTableHead, withStyles } from '@material-ui/core';

import { ICON_SIZE } from 'theme/constants';

export const Box = withStyles({
  root: {
    '&.overlay-container': { flexWrap: 'nowrap' },
    '& .title': { textAlign: 'center', width: '100%', margin: 'calc(.7vw + 1.875vh) 0' },
    '& .table-wrapper': {
      width: '90%',
      margin: '0 auto',
      overflow: 'auto,',
      overflowX: 'hidden',
      paddingRight: '1.46vw',
      scrollbarColor: 'RGBA(255, 255, 255, 0.3) transparent',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '3px',
        backgroundColor: 'RGBA(255, 255, 255, 0.3)',
      },
    },
    '& .image-wrapper': { height: ICON_SIZE.big, width: ICON_SIZE.big },
    '& .buttons-wrapper': {
      display: 'flex',
      position: 'relative',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
})(MuiBox);

export const TableRow = withStyles({
  root: {
    '& .MuiTableCell-root': {
      borderBottom: '1px solid hsla(0,0%,100%,.5)',
    },
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
    '& .word-break-all': { wordBreak: 'break-all' },
  },
})(MuiTableRow);

export const TableHead = withStyles({
  root: {
    textTransform: 'uppercase',
  },
})(MuiTableHead);
