import React, { FunctionComponent, PropsWithChildren } from 'react';

import { CssBaseline } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import Helmet from 'react-helmet';

import { PremaintenanceDialog } from 'components/Dialogs';
import Maintenance from 'pages/Maintenance/Maintenance';
import Routes from 'routes/Routes';
import { getTWithPathKey } from 'utils';
import { withGlobalContextsProviders } from 'utils/enhancers';
import { ShoppingProviderStatusProvider } from 'contexts/ShoppingProviderContext';
import DisconnectStatusBar from 'components/DisconnectStatus/DisconnectStatusBar';

import 'theme/webfonts/InterV/InterV.css';
import './theme/style.scss';

import './i18n'; // just needed to be bundled.

interface Props {
  maintenance: boolean;
}

const App: FunctionComponent<PropsWithChildren<Props>> = ({ maintenance }) => {
  const getT = getTWithPathKey('app');

  const renderBody = () =>
    maintenance ? (
      <Maintenance />
    ) : (
      <>
        <ShoppingProviderStatusProvider>
          <Routes />
          <DisconnectStatusBar />
        </ShoppingProviderStatusProvider>
        <PremaintenanceDialog />
      </>
    );

  return (
    <>
      <Helmet titleTemplate={`%s | ${getT('titleTemplate')}`} defaultTitle={getT('defaultTitle')} />
      <CssBaseline />
      <StylesProvider injectFirst>{renderBody()}</StylesProvider>
    </>
  );
};

export default withGlobalContextsProviders(App);
