import React, { memo } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardComponent } from 'components';
import { TextValidatorComponent, DatePickerComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';

import { Switch } from 'components/style';

export const defaultReleaseCountdown = {
  preRelease: false,
  releaseDescription: '',
  releaseTime: '',
  releaseTitle: '',
};

function ReleaseCountdownComponent({ productReleaseCountdown, setProductReleaseCountdown }) {
  const handleChangeReleaseCountdownItem = item => setProductReleaseCountdown(prevState => ({ ...prevState, ...item }));

  const handleChangeCountdownEnabled = ({ target: { checked } }) =>
    setProductReleaseCountdown(prevState => ({ ...prevState, preRelease: checked }));

  const renderCountdownNav = () => (
    <Switch checked={productReleaseCountdown.preRelease} onChange={handleChangeCountdownEnabled} />
  );

  return (
    <CardComponent title="Release countdown" sm={12} CardHeaderNav={renderCountdownNav}>
      {productReleaseCountdown.preRelease && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Box mb={2} mt={6}>
              <Typography variant="h5">Will be released at:</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <DatePickerComponent
              validators={[VALIDATORS_MAP.required]}
              label="Start day and time*"
              fieldName="releaseTime"
              value={productReleaseCountdown?.releaseTime}
              handleChange={handleChangeReleaseCountdownItem}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className="information">
              *Time zone is UTC
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Release information:</Typography>
          </Grid>
          <TextValidatorComponent
            label="Title"
            validators={[VALIDATORS_MAP.lettersNumbersSpecialSymbols]}
            value={productReleaseCountdown?.releaseTitle}
            fieldName="releaseTitle"
            handleChange={handleChangeReleaseCountdownItem}
          />
          <TextValidatorComponent
            label="Description"
            validators={[VALIDATORS_MAP.lettersNumbersSpecialSymbols]}
            value={productReleaseCountdown?.releaseDescription}
            fieldName="releaseDescription"
            handleChange={handleChangeReleaseCountdownItem}
            rows={5}
            multiline
          />
        </Grid>
      )}
    </CardComponent>
  );
}

ReleaseCountdownComponent.propTypes = {
  productReleaseCountdown: PropTypes.shape({
    preRelease: PropTypes.bool,
    releaseDescription: PropTypes.string,
    releaseTime: PropTypes.string,
    releaseTitle: PropTypes.string,
  }).isRequired,
  setProductReleaseCountdown: PropTypes.func.isRequired,
};

export const ReleaseCountdown = memo(ReleaseCountdownComponent);
