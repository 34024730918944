import React from 'react';

import { Grid } from '@material-ui/core';

import { CardComponent, PageHeader, AssetsItemComponent } from 'components';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { ReactComponent as LogoIcon } from 'assets/icons/assets/logo-icon.svg';
import { ReactComponent as ControlButtonIcon } from 'assets/icons/assets/control-button-icon.svg';
import { ReactComponent as FaviconIcon } from 'assets/icons/assets/favicon-icon.svg';
import { ReactComponent as FontsIcon } from 'assets/icons/assets/fonts-icon.svg';
import { ReactComponent as BackgroundIcon } from 'assets/icons/assets/background-icon.svg';
import { ReactComponent as ImageAssetsIcon } from 'assets/icons/assets/ui-assets-icon.svg';
import { ReactComponent as OverlayContentIcon } from 'assets/icons/assets/overlay-content-icon.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/assets/video-icon.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/assets/audio-icon.svg';

export default function Assets() {
  const getT = getTWithPathKey('pages.assets.Assets');

  return (
    <>
      <PageHeader title="Assets" />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Grid container spacing={6}>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<LogoIcon />}
                title={getT('logo.title')}
                caption={getT('logo.caption')}
                to={ROUTES.logo}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<ControlButtonIcon />}
                title={getT('controlButton.title')}
                caption={getT('controlButton.caption')}
                to={ROUTES.controlButtonAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<FaviconIcon />}
                title={getT('favicon.title')}
                caption={getT('favicon.caption')}
                to={ROUTES.faviconAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<FontsIcon />}
                title={getT('fonts.title')}
                caption={getT('fonts.caption')}
                to={ROUTES.fontsAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<BackgroundIcon />}
                title={getT('backgroundImages.title')}
                caption={getT('backgroundImages.caption')}
                to={ROUTES.backgroundImagesAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<ImageAssetsIcon />}
                title={getT('otherAssets.title')}
                caption={getT('otherAssets.caption')}
                to={ROUTES.otherAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<OverlayContentIcon />}
                title={getT('overlayContent.title')}
                caption={getT('overlayContent.caption')}
                to={ROUTES.overlayContent}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<VideoIcon />}
                title={getT('video.title')}
                caption={getT('video.caption')}
                to={ROUTES.videoAssets}
              />
            </Grid>
            <Grid item sm={6}>
              <AssetsItemComponent
                icon={<AudioIcon />}
                title={getT('audio.title')}
                caption={getT('audio.caption')}
                to={ROUTES.audioAssets}
              />
            </Grid>
          </Grid>
        </CardComponent>
      </Grid>
    </>
  );
}
