import React, { PropsWithChildren } from 'react';

import { Box, DialogActions, DialogContent, Typography } from '@material-ui/core';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { getTWithPathKey } from 'utils';

import { BackButton, Dialog, DialogHeader } from './style';

export interface GuideDialogProps {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  handleBack?: () => void;
  backText?: string;
  disableTransition?: boolean;
}

export const GuideDialog = ({
  isOpen,
  title,
  handleClose,
  children,
  handleBack,
  backText = '',
  disableTransition = false,
}: PropsWithChildren<GuideDialogProps>) => {
  const getT = getTWithPathKey('components.dialogs.guideDialog');

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      scroll="body"
      onBackdropClick={handleClose}
      transitionDuration={disableTransition ? 0 : undefined}
    >
      <DialogContent>
        <DialogHeader>
          {backText && handleBack && (
            <Box>
              <BackButton variant="text" onClick={handleBack} size="small" disableRipple disableFocusRipple>
                {`< ${backText}`}
              </BackButton>
            </Box>
          )}
          <Typography variant="h1">{title}</Typography>
        </DialogHeader>
        {children}
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent={handleBack ? 'space-between' : 'flex-end'}>
          {handleBack && <ButtonComponent onClick={handleBack} text={getT('back')} />}
          <ButtonComponent onClick={handleClose} text={getT('close')} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
