import React, { useEffect } from 'react';

import { Box, Checkbox, Divider, Typography } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';

import { PlanogramVersion, PlanogramVersionControlButton } from 'types';
import DraggableMenuItems from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/DraggableMenuItems';
import { StyledButtonComponent } from 'pages/auth/style';
import { PlanogramObjectData } from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/helper';

interface MenuButtonSettingsProps {
  planogramVersion: PlanogramVersion;
  settings: PlanogramVersionControlButton;
  setSettings: (settings: Partial<PlanogramVersionControlButton>) => void;
  planogramObjectData: PlanogramObjectData[];
}

export function MenuButtonSettings({
  planogramVersion,
  settings,
  setSettings,
  planogramObjectData,
}: MenuButtonSettingsProps) {
  useEffect(() => {
    if (settings.menu?.menuItems?.length === 0) {
      handleAddItem();
    }
  }, [settings]);

  const handleAddItem = () => {
    const defaultItem = planogramVersion.defaultNavigation.menuItem;

    setSettings({
      menu: { ...settings.menu, menuItems: [...(settings.menu?.menuItems ?? []), defaultItem] },
    });
  };

  return (
    <Box mt={6}>
      <Divider />
      <Box mt={6}>
        <Typography variant="h4">Menu items</Typography>
      </Box>
      <Box mt={3}>
        <DraggableMenuItems
          items={settings.menu?.menuItems ?? []}
          onChange={items => setSettings({ ...settings, menu: { ...settings.menu, menuItems: items } })}
          planogramObjectData={planogramObjectData}
        />
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
        <Box ml={-3}>
          <Typography variant="caption">
            <Checkbox
              color="primary"
              checked={settings.menu?.showShadow ?? false}
              onChange={e => setSettings({ ...settings, menu: { ...settings.menu, showShadow: e.target.checked } })}
            />
            <span>Show shadow</span>
          </Typography>
        </Box>
        <Box>
          <StyledButtonComponent
            fullWidth
            color="primary"
            text="Add item"
            variant="outlined"
            Icon={AddOutlined}
            onClick={handleAddItem}
            style={{ borderRadius: '60px', width: '120px', height: '40px' }}
            disabled={settings.menu?.menuItems?.length >= 12}
          />
        </Box>
      </Box>
    </Box>
  );
}
