import { HtmlTemplate } from 'types';

import { api } from './setupApi';

class HtmlTemplatesModel {
  link = '/html_templates';

  getTemplates = (): Promise<HtmlTemplate[]> => api.get<HtmlTemplate[]>(this.link).then(({ data }) => data);

  updateTemplate = (id: number, inputFields: Partial<HtmlTemplate>): Promise<HtmlTemplate> =>
    api.put(`${this.link}/${id}`, { inputFields }).then(({ data }) => data.htmlTemplate);
}

export const htmlTemplatesModel = new HtmlTemplatesModel();
