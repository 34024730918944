import { styled, Chip, Select } from '@material-ui/core';

export const CountryChip = styled(Chip)({
  marginLeft: '8px',
  marginBottom: '4px',
});

export const CountrySelect = styled(Select)({
  minWidth: '150px',
});

export const CountryChipContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});
