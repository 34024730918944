import {
  ErrorResponseData,
  ErrorResponseParams,
  HostErrorResponseData,
  HostErrorResponsePostParams,
} from 'types/other';

import { api } from './setupApi';

class ErrorResponseModel {
  link = '/error_responses';

  hostErrorResponseLink = '/host_error_responses';

  getErrorResponse = (): Promise<ErrorResponseData[]> =>
    api.get<ErrorResponseData[]>(this.link).then(({ data }) => data);

  addErrorResponse = (data: ErrorResponseParams) => api.post(this.link, data);

  editErrorResponse = (data: ErrorResponseParams, id: number) => api.put(`${this.link}/${id}`, data);

  destroyErrorResponse = (id: number) => api.delete(`${this.link}/${id}`);

  getHostErrorResponses = (): Promise<HostErrorResponseData> =>
    api.get<HostErrorResponseData>(this.hostErrorResponseLink).then(({ data }) => data);

  editHostErrorResponse = (data: HostErrorResponsePostParams) => api.put(this.hostErrorResponseLink, data);
}

export const errorResponseModel = new ErrorResponseModel();
