import React, { useState } from 'react';

import { isEqual, pick } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CardComponent, IFrameColorComponent, OverlayColorsComponent, SettingsForm } from 'components';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { COLORS } from 'theme/constants';

import { Switch } from 'components/style';
import { HeaderHelpText } from '../style';

const COLOR_KEYS = ['primaryColor', 'secondaryColor', 'iframePrimaryColor', 'iframeSecondaryColor'];
const DEFAULT_COLORS = {
  primaryColor: COLORS.darkPreview,
  secondaryColor: COLORS.lightPreview,
  iframePrimaryColor: COLORS.white,
  iframeSecondaryColor: ''
};

export function CustomOverlaySettings({ planogramId, planogramVersion, handleServerError, setSuccessActionMessages }) {
  const [colors, setColors] = useState(pick(planogramVersion, COLOR_KEYS));
  const [colorsInitial, setColorsInitial] = useState(pick(planogramVersion, COLOR_KEYS));
  const [enabledOverlayColors, setEnabledOverlayColors] = useState(planogramVersion.enabledOverlayColors);
  const [enabledOverlayColorsInitial, setEnabledOverlayColorsInitial] = useState(planogramVersion.enabledOverlayColors);
  const { t } = useTranslation();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const onSubmit = withHandlingSubmittingState(async () => {
    try {
      const dataToSend =
        enabledOverlayColors !== enabledOverlayColorsInitial && !enabledOverlayColors
          ? { enabledOverlayColors, colors: DEFAULT_COLORS }
          : { ...colors, enabledOverlayColors };

      const data = await planogramsModel.editVersion(planogramId, planogramVersion.id, dataToSend);
      const updatedColors = pick(data.planogramVersion, COLOR_KEYS);
      const updatedEnabledOverlayColors = data.planogramVersion.enabledOverlayColors;

      setColors(updatedColors);
      setColorsInitial(updatedColors);

      setEnabledOverlayColors(updatedEnabledOverlayColors);
      setEnabledOverlayColorsInitial(updatedEnabledOverlayColors);
      setSuccessActionMessages(data.success);
    } catch (err) {
      handleServerError(err);
    }
  });

  const isSaveButtonVisible =
    !isSubmitting &&
    (enabledOverlayColors !== enabledOverlayColorsInitial || (enabledOverlayColors && !isEqual(colors, colorsInitial)));

  const onColorChange =
    name =>
    ({ hex }) =>
      setColors(prevColors => ({ ...prevColors, [name]: hex }));

  const renderTitle = () => (
    <>
      {t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.title')} &nbsp;
      <HeaderHelpText>{t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.titleHint')}</HeaderHelpText>
    </>
  );

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={onSubmit}>
      <CardComponent
        title={renderTitle()}
        isLoading={isSubmitting}
        CardHeaderNav={() => (
          <Switch checked={enabledOverlayColors} onChange={() => setEnabledOverlayColors(prev => !prev)} />
        )}
      >
        {enabledOverlayColors && (
          <>
            <OverlayColorsComponent {...{ onColorChange, colors }} />
            <IFrameColorComponent {...{
              primaryColorKey: 'iframePrimaryColor',
              primaryColorValue: colors.iframePrimaryColor,
              secondaryColorKey: 'iframeSecondaryColor',
              secondaryColorValue: colors.iframeSecondaryColor,
              onColorChange
            }} />
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
}

CustomOverlaySettings.propTypes = {
  planogramId: PropTypes.string.isRequired,
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    enabledOverlayColors: PropTypes.bool,
  }).isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
