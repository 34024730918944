import React, { Fragment } from 'react';

import { TableRow, TableCell, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from 'components/ecommerce/constants';
import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { getTWithPathKey } from 'utils';

import { Box } from '../style';

export function BackgroundTable({ ecommerceSettings, setSettings }) {
  const getT = getTWithPathKey(`components.ecommerce.overlay.${ecommerceSettings.overlayType}.backgroundStyles`);

  const handleSetField = fieldName => value => setSettings(prev => ({ ...prev, [fieldName]: value }));

  const renderSetting = settingName => (
    <Fragment key={settingName}>
      <Box mt={4} mb={0.5}>
        <Typography variant="h6">{getT(`${settingName}.title`)}</Typography>
      </Box>
      <Box mb={2.5}>
        <Typography variant="subtitle1" className="information">
          {getT(`${settingName}.caption`)}
        </Typography>
      </Box>
      <ColorPicker setColor={handleSetField(settingName)} color={ecommerceSettings[settingName]} />
    </Fragment>
  );

  return (
    <OneColumnExpandedTable title={getT('title')} caption={getT('caption')}>
      <TableRow hover>
        <TableCell colSpan={2}>{['backgroundColor', 'secondaryColor'].map(renderSetting)}</TableCell>
      </TableRow>
    </OneColumnExpandedTable>
  );
}

BackgroundTable.propTypes = {
  ecommerceSettings: PropTypes.shape({
    overlayType: PropTypes.oneOf(Object.values(ECOMMERCE_OVERLAYS_SETTINGS_TYPES)),
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
};
