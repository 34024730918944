import React from 'react';

import { Grid, Divider } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { CardComponent } from 'components/CardComponent/CardComponent';

import { Definitions, Description, Highlight } from '../style';

export const InfoBlock = () => {
  const { t } = useTranslation();

  return (
    <CardComponent title={t('pages.settings.domainManagementSettings.infoBlock.title')} sm={12}>
      <Grid container spacing={6} direction="column">
        <Grid item>
          <Description>
            <Trans
              i18nKey="pages.settings.domainManagementSettings.infoBlock.description"
              components={{ bold: <Highlight /> }}
            />
          </Description>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Definitions>
            <Trans
              i18nKey="pages.settings.domainManagementSettings.infoBlock.definitions"
              components={{ bold: <Highlight /> }}
            />
          </Definitions>
        </Grid>
      </Grid>
    </CardComponent>
  );
};
