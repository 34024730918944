import { ChangeEvent } from 'react';

export const handleGetTextValue =
  (fieldName: string) =>
    (item: Record<string, string>): string =>
      item[fieldName];
export const handleGetCheckboxValue = ({ target: { checked } }: ChangeEvent<HTMLInputElement>): boolean => checked;

// TODO: remove this redundant function
export const handleGetLoadedFile = (file: File): File => file;
