export const BUTTON_TYPES = {
  addToCart: 'add_to_cart',
  buyNow: 'buy_now',
  primary: 'primary',
  secondary: 'secondary',
  shoppingCart: 'shopping_cart',
  userProfile: 'user_profile',
  checkout: 'checkout',
};

export const FONT_TYPES = {
  header: 'header',
  price: 'price',
  captions: 'captions',
  options: 'options',
  button: 'button',
  body: 'body',
  textFieldInfo: 'text_field_info',
  link: 'link',
  validation: 'validation',
  primaryButton: 'primary_button',
  secondaryButton: 'secondary_button',
  productName: 'product_name',
  stepper: 'stepper',
  cartSubtotal: 'cart_subtotal',
  subtotalPrice: 'subtotal_price',
  cartIsEmpty: 'cart_is_empty',
};

export const BUTTON_SHAPES = {
  square: 'square',
  round: 'round',
};

// This types are returned from BE to indicated this settings is what what overlay
export const ECOMMERCE_OVERLAYS_SETTINGS_TYPES = {
  pdp: 'pdp',
  signIn: 'sign_in',
  buttons: 'buttons',
  shoppingCart: 'shopping_cart',
};

export const OVERLAY_CONFIGURATION = {
  [ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp]: {
    fonts: [
      FONT_TYPES.header,
      FONT_TYPES.price,
      FONT_TYPES.captions,
      FONT_TYPES.options,
      FONT_TYPES.button,
      FONT_TYPES.body,
    ],
    withShapeConfiguration: true,
    withTextFields: false,
    withControlColor: false,
  },
  [ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn]: {
    fonts: [
      FONT_TYPES.header,
      FONT_TYPES.textFieldInfo,
      FONT_TYPES.link,
      FONT_TYPES.validation,
      FONT_TYPES.primaryButton,
      FONT_TYPES.secondaryButton,
    ],
    withShapeConfiguration: false,
    withTextFields: true,
    withControlColor: false,
  },
  [ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart]: {
    fonts: [
      FONT_TYPES.header,
      FONT_TYPES.productName,
      FONT_TYPES.options,
      FONT_TYPES.stepper,
      FONT_TYPES.price,
      FONT_TYPES.cartSubtotal,
      FONT_TYPES.subtotalPrice,
      FONT_TYPES.button,
      FONT_TYPES.cartIsEmpty,
      FONT_TYPES.validation,
    ],
    withShapeConfiguration: false,
    withTextFields: false,
    withControlColor: true,
  },
};

export const ECOMMERCE_OVERLAY_BUTTON_ADDITIONAL_PROPS = {
  [BUTTON_TYPES.addToCart]: {
    titleDisabled: false,
    withButtonIconUpload: true,
    withDisabledState: true,
    isUppercase: true,
  },
  [BUTTON_TYPES.buyNow]: {
    titleDisabled: false,
    withButtonIconUpload: true,
    withDisabledState: true,
    isUppercase: true,
  },
  [BUTTON_TYPES.primary]: {
    titleDisabled: true,
    withButtonIconUpload: false,
    withDisabledState: false,
  },
  [BUTTON_TYPES.secondary]: {
    titleDisabled: true,
    withButtonIconUpload: false,
    withDisabledState: false,
  },
  [BUTTON_TYPES.checkout]: {
    titleDisabled: true,
    withButtonIconUpload: false,
    withDisabledState: true,
  },
};
