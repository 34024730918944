import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { PageHeaderNav, PageHeader, SettingsForm } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { adminsModel } from 'models';
import { ROLES, ROUTES } from 'routes/constants';
import { AuthProvider } from 'types';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';

import { AccountAccess, ClientInfo, PersonalInfo, PlanogramInfo, TFASwitch } from '../settings';

const ROLE_CONFIG = {
  [ROLES.clientOwner]: { title: 'SPACE OWNER DETAILS', route: ROUTES.manageClientAdmins },
  [ROLES.clientAdmin]: { title: 'SPACE ADMIN DETAILS', route: ROUTES.manageClientAdmins },
  [ROLES.clientModerator]: {
    title: 'SPACE MODERATOR DETAILS',
    route: ROUTES.manageClientModerators,
  },
};

function EditClientAdmin({
  match: {
    params: { id },
  },
  setSuccessActionMessages,
}) {
  const [admin, setAdmin] = useState({});
  const [adminsCount, setAdminsCount] = useState(0);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getAdmin();
  }, [id]);

  const getAdmin = withPageProgressHandler(() =>
    adminsModel
      .getAdmin(id)
      .then(data => {
        setAdmin(data.admin);
        setAdminsCount(data.adminsCount);
      })
      .catch(handleServerError)
  );

  return (
    <>
      <PageHeader backLink={ROLE_CONFIG[admin.role]?.route} title={ROLE_CONFIG[admin.role]?.title}>
        <PageHeaderNav />
      </PageHeader>
      <PersonalInfo
        handleServerError={handleServerError}
        setSuccessActionMessages={setSuccessActionMessages}
        adminInfo={admin}
      />
      {!isEmpty(admin) && admin.provider === AuthProvider.Email && (
        <TFASwitch
          handleServerError={handleServerError}
          adminId={admin.id}
          setSuccessActionMessages={setSuccessActionMessages}
        />
      )}
      <AccountAccess
        handleServerError={handleServerError}
        setSuccessActionMessages={setSuccessActionMessages}
        admin={admin}
      />
      <SettingsForm>
        <ClientInfo admin={admin} adminsCount={adminsCount} />
      </SettingsForm>
      {![ROLES.systemAdmin].includes(admin?.role) && <PlanogramInfo planogramsInfo={admin} />}
    </>
  );
}

EditClientAdmin.propTypes = {
  ...withSuccessActionsDialogProps,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default withSuccessActionsDialog(EditClientAdmin);
