import { CustomDomain, CustomDomainsListResponse, CustomDomainsGetParams } from 'types/domains';

import { api } from './setupApi';

class CustomDomainsModel {
  link = '/external_hosts';

  getLink = (clientId?: number) => (clientId ? `clients/${clientId}${this.link}` : this.link);

  getCustomDomains = (params?: CustomDomainsGetParams, clientId?: number): Promise<CustomDomainsListResponse> =>
    api.get<CustomDomainsListResponse>(this.getLink(clientId), { params }).then(({ data }) => data);

  createCustomDomain = (host: string, clientId?: number): Promise<CustomDomainsListResponse> =>
    api.post<CustomDomainsListResponse>(this.getLink(clientId), { host }).then(({ data }) => data);

  deleteCustomDomain = (id: number, clientId?: number) => api.delete(`${this.getLink(clientId)}/${id}`);

  checkCustomDomainStatus = (id: number, clientId?: number): Promise<CustomDomain> =>
    api.post<CustomDomain>(`${this.getLink(clientId)}/${id}/check_status`).then(({ data }) => data);
}

export const customDomainsModel = new CustomDomainsModel();
