import { CookiesPopUpDataParams, CookiesPopUp } from 'types/overlays';
import { deepObjectKeysToCamelCase, deepObjectKeysToSnakeCase } from 'utils';

import { api } from './setupApi';

class CookiesPopupModel {
  link = '/cookies_pop_ups';

  getData = (): Promise<CookiesPopUp> => api.get(this.link).then(({ data }) => deepObjectKeysToCamelCase(data));

  putData = ({ id, ...data }: CookiesPopUpDataParams) =>
    api.put(`${this.link}/${id}`, deepObjectKeysToSnakeCase(data)).then(({ data }) => deepObjectKeysToCamelCase(data));
}

export const cookiesPopupModel = new CookiesPopupModel();
