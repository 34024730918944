import React, { memo } from 'react';

import { Button } from '@material-ui/core';
import { head } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, ItemWithData } from 'components';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { getTWithPathKey } from 'utils';

import { ListItem } from 'components/style';

function PlanogramInfoComponent({ planogramsInfo, sm, xs }) {
  const getT = getTWithPathKey('pages.admins.settings.planogramInfo');

  return (
    <CardComponent title={getT('sphereInfo')} sm={sm} xs={xs}>
      <ItemWithData
        title={getT('amountOfSpheres')}
        data={planogramsInfo?.planogramsQuantity || planogramsInfo?.planogramsCount}
      />
      <ItemWithData
        title={getT('lastUpdate')}
        data={planogramsInfo?.lastPlanogramsUpdate || planogramsInfo?.lastUpdate}
      />
      <ItemWithData title={getT('link')}>
        {(planogramsInfo.planogramVersions || []).map(planogramVersion => (
          <ListItem key={planogramVersion.id}>
            <Button href={planogramVersion.url} color="default" size="small">
              {head(planogramVersion.url.split('?'))}
            </Button>
            <StatusComponent status={planogramVersion.status} />
          </ListItem>
        ))}
      </ItemWithData>
    </CardComponent>
  );
}

PlanogramInfoComponent.propTypes = {
  planogramsInfo: PropTypes.shape({
    planogramsQuantity: PropTypes.number,
    planogramsCount: PropTypes.number,
    lastUpdate: PropTypes.string,
    lastPlanogramsUpdate: PropTypes.string,
    planogramVersions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
  sm: PropTypes.number,
  xs: PropTypes.number,
};

PlanogramInfoComponent.defaultProps = {
  xs: 9,
  sm: 8,
};

export const PlanogramInfo = memo(PlanogramInfoComponent);
