import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { isEqual, omit } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, ImageChooseComponent, LinkBehavior, SettingsForm } from 'components';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { NAVIGATION_TYPE } from 'components/LinkBehavior/constants';
import { useSubmittingState } from 'hooks';
import { logosModel, planogramsModel } from 'models';
import { getSettingsWithNavigationValues, getServerError, withBackgroundForSystemImg, getTWithPathKey } from 'utils';

import { ImageUploadCaption, Switch } from 'components/style';

const defaultLogoSettings = {
  logo: { id: null, imageName: '', url: '' },
  navigationValueSelect: null,
  navigationValueUrl: null,
  navigationType: NAVIGATION_TYPE.internal,
  enabled: false,
  openInNewPage: false,
};

export function LogoSettings({ planogramVersion, planogramId, handleServerError, setSuccessActionMessages }) {
  const [logoSettings, setLogoSettings] = useState(defaultLogoSettings);
  const [savedLogoSettings, setSavedLogoSettings] = useState(defaultLogoSettings);
  const getT = getTWithPathKey('common.errors');

  useEffect(() => {
    const settings = getSettingsFromPlanogramVersion(planogramVersion);

    setLogoSettings(settings);
    setSavedLogoSettings(settings);
  }, [planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const getSettingsFromPlanogramVersion = version =>
    version?.planogramVersionLogo ? getSettingsWithNavigationValues(version.planogramVersionLogo) : defaultLogoSettings;

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      if (logoSettings.enabled && !logoSettings.logo.id) {
        handleServerError(getServerError(getT('noImage')));
        return;
      }

      const data = await planogramsModel.editVersion(planogramId, planogramVersion.id, {
        logoId: logoSettings.logo.id,
        enabled: logoSettings.enabled,
        navigationType: logoSettings.navigationType,
        openInNewPage: logoSettings.openInNewPage,
        navigationValue:
          logoSettings.navigationType === NAVIGATION_TYPE.external
            ? logoSettings.navigationValueUrl
            : logoSettings.navigationValueSelect,
      });

      setSuccessActionMessages(data.success);
      setSavedLogoSettings(getSettingsFromPlanogramVersion(data.planogramVersion));
      setLogoSettings(getSettingsFromPlanogramVersion(data.planogramVersion));
    } catch (err) {
      handleServerError(err);
    }
  });

  const getIsSaveButtonVisible = () => {
    const hasEnabledChanges = !(!savedLogoSettings.enabled && !logoSettings.enabled);
    let hasLogoChanges;

    if (logoSettings.navigationType === NAVIGATION_TYPE.internal) {
      hasLogoChanges = !isEqual(savedLogoSettings, {
        ...logoSettings,
        navigationValueUrl: defaultLogoSettings.navigationValueUrl,
      });
    } else {
      hasLogoChanges = !isEqual(savedLogoSettings, {
        ...logoSettings,
        navigationValueSelect: defaultLogoSettings.navigationValueSelect,
      });
    }

    return !isSubmitting && hasEnabledChanges && hasLogoChanges;
  };

  const handleChange = item =>
    setLogoSettings(prevState => ({
      ...prevState,
      ...item,
    }));

  const handleChangeItem =
    item =>
    ({ target: { checked } }) =>
      handleChange({ [item]: checked });

  const setLogo = logo => handleChange({ logo });

  const getLogos = params =>
    logosModel
      .getLogo(params)
      .then(data => ({ ...data, logos: data.logos.map(withBackgroundForSystemImg) }))
      .catch(handleServerError);

  const renderNav = () => <Switch checked={logoSettings.enabled} onChange={handleChangeItem('enabled')} />;

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent title="Logo" xs={9} alignItems="center" CardHeaderNav={renderNav} isLoading={isSubmitting}>
        {logoSettings.enabled && (
          <>
            <Grid container spacing={6}>
              <Grid item sm={4} style={{ minWidth: '150px' }}>
                <ImageChooseComponent
                  image={logoSettings.logo}
                  setImage={setLogo}
                  handleServerError={handleServerError}
                  dialogTitle="Choose logo"
                  getPictures={getLogos}
                  withDelete
                />
              </Grid>
              <Grid item sm={8}>
                <ImageUploadCaption>{IMAGE_UPLOAD_CAPTION.logo}</ImageUploadCaption>
              </Grid>
            </Grid>
            <LinkBehavior
              handleChange={handleChange}
              handleServerError={handleServerError}
              navigationType={logoSettings.navigationType}
              availableNavigationTypes={Object.values(omit(NAVIGATION_TYPE, 'back'))}
              tabNames={['Internal', 'External']}
              navigationValueSelect={logoSettings.navigationValueSelect}
              navigationValueUrl={logoSettings.navigationValueUrl}
              checkedOpenInNewPage={logoSettings.openInNewPage}
              setOpenInNewPage={handleChangeItem('openInNewPage')}
              name="logo"
            />
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
}

LogoSettings.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  planogramId: PropTypes.string.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
