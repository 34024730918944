import { Tooltip as MuiTooltip, Grid, withStyles } from '@material-ui/core';

export const Tooltip = withStyles({
  tooltip: { backgroundColor: 'transparent' },
})(MuiTooltip);

export const GridCaptionContainer = withStyles({
  root: {
    '& .MuiTypography-root': { display: 'flex', justifyContent: 'space-between' },
    '& .MuiSvgIcon-root': { alignSelf: 'center' },
  },
})(Grid);
