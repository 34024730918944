import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { CardComponent } from 'components/CardComponent/CardComponent';
import { RadioGroupComponent } from 'components';
import { ConfirmTrafficRedirectDialog } from 'components/Dialogs';
import { maintenanceModel } from 'models';
import { useServerErrorActions } from 'contexts/ServerErrorContext';

const SERVICE_NAMES = ['spheres-api', 'spheres-api-second'];
const radioOptions = SERVICE_NAMES.map(name => ({ value: name, title: name }));

export const TrafficRedirect = () => {
  const { t } = useTranslation();
  const { handleServerError } = useServerErrorActions();

  const [selectedName, setSelectedName] = useState<string>('');
  const [isConfirmingSave, setIsConfirmingSave] = useState(false);

  const openConfirmDialog = () => {
    if (selectedName !== '') setIsConfirmingSave(true);
  };

  const cancelConfirmation = () => {
    setIsConfirmingSave(false);
  };

  const saveChoice = () => {
    setIsConfirmingSave(false);
    return maintenanceModel.setIngressName(selectedName).catch(handleServerError);
  };

  return (
    <CardComponent title={t('pages.manageMaintenance.traffic.title')} sm={12}>
      <Grid container spacing={4} direction="column">
        <Grid item>{t('pages.manageMaintenance.traffic.description')}</Grid>
        <Grid item>
          <RadioGroupComponent items={radioOptions} groupValue={selectedName} setGroupValue={setSelectedName} />
        </Grid>
        <Grid item container justify="flex-end">
          <ButtonComponent color="primary" text={t('common.buttons.save')} onClick={openConfirmDialog} />
        </Grid>
      </Grid>
      {isConfirmingSave && (
        <ConfirmTrafficRedirectDialog serviceName={selectedName} onClose={cancelConfirmation} onSubmit={saveChoice} />
      )}
    </CardComponent>
  );
};
