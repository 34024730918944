import React from 'react';

import { Box, Grid } from '@material-ui/core';

import { CardComponent, SettingsProviderButton, PageHeader } from 'components';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';

import { SSO_PROVIDERS, PROVIDERS_ICONS_MAP } from './config';

const SsoProviders = () => {
  const getT = getTWithPathKey('pages.settings.ssoProviders');

  return (
    <>
      <PageHeader title={getT('title')} backLink={ROUTES.settings} />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Box display="flex">
            {SSO_PROVIDERS.map(({ title, id }) => (
              <SettingsProviderButton
                key={title}
                Icon={PROVIDERS_ICONS_MAP[title]}
                title={title}
                to={`${ROUTES.ssoProviderSettingsNamespace}${id}`}
              />
            ))}
          </Box>
        </CardComponent>
      </Grid>
    </>
  );
};

export default SsoProviders;
