import React from 'react';

export function ShopifyIcon() {
  /* eslint-disable */
  return (
    <svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" version="1.1">
      <path
        fill="#95BF47"
        fillRule="nonzero"
        d="M39.996 14.935c-.029-.174-.175-.29-.321-.29-.146 0-2.718-.058-2.718-.058s-2.162-2.089-2.367-2.292c-.205-.203-.643-.145-.79-.087 0 0-.408.116-1.08.32-.117-.378-.293-.813-.526-1.277-.76-1.45-1.9-2.234-3.244-2.234-.088 0-.176 0-.292.03-.03-.059-.088-.088-.117-.146-.585-.638-1.345-.928-2.25-.899-1.754.058-3.507 1.305-4.91 3.539-.994 1.566-1.754 3.539-1.987 5.076-2.017.61-3.42 1.044-3.449 1.073-1.023.32-1.052.348-1.169 1.306C14.69 19.72 12 40.17 12 40.17L34.327 44 44 41.621c-.03-.029-3.974-26.512-4.004-26.686zm-8.387-2.06c-.526.145-1.11.348-1.724.522 0-.87-.117-2.117-.526-3.161 1.315.26 1.958 1.74 2.25 2.64zm-2.922.9c-1.17.348-2.455.754-3.74 1.13.35-1.363 1.051-2.726 1.87-3.625.32-.32.76-.696 1.256-.928.526 1.015.643 2.436.614 3.422zM26.32 9.161c.409 0 .76.087 1.052.261-.468.261-.935.61-1.374 1.074-1.11 1.189-1.958 3.045-2.309 4.815-1.052.319-2.104.638-3.068.928.614-2.785 2.98-6.99 5.699-7.078z"
        transform="translate(-360.000000, -167.000000) translate(332.000000, 151.000000) translate(28.000000, 16.000000)"
      />
      <g fillRule="nonzero">
        <path
          fill="#5E8E3E"
          d="M21.772 2.495c-.146 0-2.718-.058-2.718-.058S16.89.348 16.687.145C16.599.058 16.51.029 16.395 0v31.821l9.673-2.379S22.122 2.96 22.093 2.756c-.03-.145-.175-.261-.321-.261z"
          transform="translate(-360.000000, -167.000000) translate(332.000000, 151.000000) translate(28.000000, 16.000000) translate(17.903196, 12.150015)"
        />
        <path
          fill="#FFF"
          d="M11.017 7.426l-1.11 4.177s-1.257-.58-2.747-.464c-2.192.145-2.192 1.508-2.192 1.856.117 1.857 5.056 2.263 5.348 6.643.205 3.452-1.841 5.801-4.793 5.975C1.958 25.845 0 23.757 0 23.757l.76-3.19s1.958 1.479 3.536 1.362c1.023-.058 1.403-.899 1.373-1.479-.146-2.436-4.179-2.291-4.442-6.294-.204-3.365 2.017-6.788 6.926-7.078 1.9-.145 2.864.348 2.864.348z"
          transform="translate(-360.000000, -167.000000) translate(332.000000, 151.000000) translate(28.000000, 16.000000) translate(17.903196, 12.150015)"
        />
      </g>
    </svg>
  );
}
