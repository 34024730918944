import { Box as MuiBox, Typography as MuiTypography, withStyles } from '@material-ui/core';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const ButtonPreviewBox = withStyles({
  root: {
    borderRadius: '22px',
    height: '44px',

    '&.button-preview-icon': {
      flex: 'none',
      width: '44px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.button-preview-box-search': {
      position: 'relative',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .button-preview-box': {
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& .text': {
        margin: '0 12px',
        wordBreak: 'break-all',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: 'inherit',
      },
    },
  },
})(MuiBox);

export const Typography = withStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    color: COLORS.greyTabText,
    textTransform: 'uppercase',
    fontSize: '10px',
  },
}))(MuiTypography);

export const ImageBox = withStyles({
  root: {
    height: ICON_SIZE.middle,
    width: ICON_SIZE.middle,
  },
})(MuiBox);
