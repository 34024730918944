import React, { PropsWithChildren, useEffect, useState } from 'react';

import { Box, Grid, Typography, Checkbox } from '@material-ui/core';
import { cloneDeep, isEqual, set } from 'lodash';

import {
  CardComponent,
  SettingsForm,
  SortableListWrapper,
  SortableEntranceAnimationList,
  ColorPicker,
  LanguageCode
} from 'components';
import { EntranceAnimationDialog } from 'components/Dialogs/EntranceAnimationDialog/EntranceAnimationDialog';
import { EntranceAnimationRadioGroup } from 'components/EntranceAnimationRadioGroup/EntranceAnimationRadioGroup';
import { useAuthState } from 'contexts/AuthContext';
import { getTWithPathKey } from 'utils';
import { withPageLanguage } from 'utils/enhancers';
import { AnimationTypes, EntranceAnimationTypes } from 'types/entranceAnimation';
import { ComponentWithPageLanguageProps } from 'utils/enhancers/withPageLanguage';
import { useSubmittingState } from 'hooks';

import { ANIMATION_SETTINGS } from './constants';
import { EntranceAnimationPreview } from './EntranceAnimationPreview';

import { Switch } from '../style';
import { Description } from './style';

interface EntranceAnimationSettingsCardProps {
  settings: EntranceAnimationTypes;
  expanded?: boolean;
  handleServerError: (err: any) => void;
  onSettingsSave: (settings: EntranceAnimationTypes) => Promise<void>;
}

const EntranceAnimationSettingsCard =
  <T,>({
    pageLanguageTabValue,
    children,
    settings,
    expanded = true,
    handleServerError,
    onSettingsSave
  }:PropsWithChildren<T & ComponentWithPageLanguageProps & EntranceAnimationSettingsCardProps>) => {
  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();
  const getT = getTWithPathKey('pages.settings.entranceAnimation');
  const currentLanguageCode = clientLanguages[pageLanguageTabValue ?? 0];
  const [state, setState] = useState<EntranceAnimationTypes>(cloneDeep(settings));
  const [initialState, setInitialState] = useState<EntranceAnimationTypes>(cloneDeep(settings));
  const [index, setIndex] = useState(0);
  const [dialogSettings, setDialogSettings] = useState<AnimationTypes | {}>({});
  const [toggleDialog, setToggleDialog] = useState(false);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    setState(cloneDeep(settings));
    setInitialState(cloneDeep(settings));
  }, [settings]);

  const handleAnimationSettings = () => {
    handleDialog();
    setState((prevState: EntranceAnimationTypes) => ({
      ...set(prevState, ['entranceAnimationFonts', index], { ...dialogSettings })
    }));
  };

  const handleSave = withHandlingSubmittingState(() => onSettingsSave(state));

  const handleDialogSettingsChange = (item: Record<string, any>) =>
    setDialogSettings(prevState => ({
      ...prevState,
      ...item
    }));

  const renderNav = () => !expanded ?
    <Switch checked={state.enabled} onChange={e => handleChange({ enabled: e.target.checked })} /> :
    null;

  const handleToggle = (index: number) => {
    setState(prevState => ({
      ...set(prevState, ['entranceAnimationFonts', index, 'hidden'],
      !prevState.entranceAnimationFonts[index].hidden)
    }));
  }

  const handleDialogSettings = (index: number) => {
    setDialogSettings(state.entranceAnimationFonts[index]);
    setIndex(index);
  }

  const onRadioGroupChange = (value: string) => {
    setState(prevState => ({
      ...prevState,
      position: value
    }))
  }

  const handleDialog = () => setToggleDialog(prevState => !prevState);

  const handleSetButtonsOrderList = (newList: AnimationTypes[]) => {
    setState(prev => ({ ...prev, entranceAnimationFonts: newList }));
  }

  const handleChange = (item: Record<string, any>) =>
    setState(prevState => ({
      ...prevState,
      ...item
    }));

  const onChangeColor =
    (name: string) =>
      ({ hex }: any) =>
        setState((prevColors: any) => ({ ...prevColors, [name]: hex }));

  const getIsSaveButtonVisible = () => !isSubmitting && !isEqual(initialState, state);

  return (
    <>
      <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
        <CardComponent
          title={getT('title')}
          sm={8}
          CardHeaderNav={renderNav}
          isLoading={isSubmitting}
        >
          {(state.enabled && !expanded || expanded) && (
            <>
              {children}
              <Grid container spacing={6}>
                <Grid item>
                  <Description>{getT('caption')}</Description>
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="h4">{getT('steps.title')}</Typography>
                  <SortableListWrapper list={cloneDeep(state.entranceAnimationFonts) ?? []} filter=".disabled" setList={handleSetButtonsOrderList}>
                    <SortableEntranceAnimationList
                      list={cloneDeep(state.entranceAnimationFonts) ?? []}
                      handleVisibility={handleToggle}
                      toggleDialog={handleDialog}
                      setDialogSettings={handleDialogSettings}
                      pageLanguageTabValue={pageLanguageTabValue}
                    />
                  </SortableListWrapper>
                  <Box ml={-2}>
                    <Typography variant="caption">
                      <Checkbox
                        color="primary"
                        checked={state.hideGuidance}
                        onChange={e => handleChange({ hideGuidance: e.target.checked })}
                      />
                      <span>{getT('steps.checkboxLabel')}</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box mb={2}>
                    <Typography variant="h2" className="uppercase">{getT('animation.title')}</Typography>
                  </Box>
                  <Description>{getT('animation.caption')}</Description>
                  <Box mt={2} ml={-2}>
                    <Typography variant="caption">
                      <Checkbox
                        color="primary"
                        checked={state.withAnimation}
                        onChange={e => handleChange({ withAnimation: e.target.checked })}
                      />
                      <span>{getT('animation.checkboxLabel')}</span>
                    </Typography>
                  </Box>
                  <Box mt={2} ml={-2}>
                    <Typography variant="caption">
                      <Checkbox
                        color="primary"
                        checked={state.repeatAnimation}
                        onChange={e => handleChange({ repeatAnimation: e.target.checked })}
                      />
                      <span>{getT('animation.repeatAnimationLabel')}</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box mb={3}>
                    <Typography variant="h4">{getT('positionTitle')}</Typography>
                  </Box>
                  <EntranceAnimationRadioGroup
                    items={[ANIMATION_SETTINGS.center, ANIMATION_SETTINGS.top, ANIMATION_SETTINGS.bottom]}
                    groupValue={state.position}
                    setGroupValue={onRadioGroupChange}
                  />
                </Grid>
                <Grid item>
                  <Box mt={4} mb={2}>
                    <Typography variant="h2" className="uppercase">{getT('style.title')}</Typography>
                  </Box>
                  <Box my={3}>
                    <Typography variant="h4">{getT('style.colorPickerTitle')}</Typography>
                  </Box>
                  <ColorPicker
                    setColor={onChangeColor('backgroundColor')}
                    color={{
                      hex: state?.backgroundColor,
                    }}
                  />
                  <Box mt={2} ml={-2}>
                    <Typography variant="caption">
                      <Checkbox
                        color="primary"
                        checked={state.disableGlow}
                        onChange={e => handleChange({ disableGlow: e.target.checked })}
                      />
                      <span>{getT('style.checkboxLabel')}</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box mb={3}>
                    <Typography variant="h4">Preview</Typography>
                  </Box>
                  <Box mb={3}>
                    <LanguageCode code={currentLanguageCode} />
                  </Box>
                  <EntranceAnimationPreview
                    settings={state}
                    pageLanguageTabValue={pageLanguageTabValue}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </CardComponent>
        {toggleDialog && (
          <EntranceAnimationDialog
            title="EDIT"
            settings={dialogSettings as AnimationTypes}
            handleSettingsChange={handleDialogSettingsChange}
            isOpen={toggleDialog}
            handleClose={handleDialog}
            handleSave={handleAnimationSettings}
            handleServerError={handleServerError}
            pageLanguageTabValue={pageLanguageTabValue}
          />
        )}
      </SettingsForm>
    </>
  )
}

export default withPageLanguage(EntranceAnimationSettingsCard);
