import React from 'react';

import { compose } from 'lodash/fp';
import { Grid } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';

import { CookieIcon } from 'components/CustomIcons';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { FontSettingsType } from 'types';
import { useAuthState } from 'contexts/AuthContext';
import { getInlineFontStyles } from 'utils';
import { withLocalization } from 'utils/enhancers';

import { Box, DoneIcon } from './style';
import { ButtonPreviewBox } from 'components/BuyButtonPreview/style';

export interface CookiesPopupProps {
  backgroundColor: string;
  buttonColor: string;
  secondaryButtonColor: string;
  headerText: string;
  headerTextFontSettings: FontSettingsType;
  description: string;
  descriptionFontSettings: FontSettingsType;
  buttonFontSettings: FontSettingsType;
  titlesFontSettings: FontSettingsType;
  textFontSettings: FontSettingsType;
  experienceOptionsSettings: FontSettingsType;
  experienceTitleSettings: FontSettingsType;
  useImage: boolean;
  headerImageLink: string;
  pageLanguageTabVal: number;
  analyticText: string;
  experienceImageLink: string;
  experienceBackgroundColor: string;
  hideIcon: boolean;
  experienceTitle: string;
}

export const CookiesPopupPreview = ({
  backgroundColor = '',
  buttonColor = '',
  secondaryButtonColor = '',
  headerText = '',
  headerTextFontSettings,
  description = '',
  descriptionFontSettings,
  buttonFontSettings,
  titlesFontSettings,
  textFontSettings,
  useImage,
  headerImageLink = '',
  pageLanguageTabVal = 0,
  analyticText = '',
  experienceImageLink = '',
  experienceBackgroundColor = '',
  hideIcon = false,
  experienceOptionsSettings,
  experienceTitleSettings,
  experienceTitle = '',
}: CookiesPopupProps): JSX.Element  => {
  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();
  const { t } = useTranslation();

  const currentLanguageCode = clientLanguages[pageLanguageTabVal];
  const getTransPath = (key: string) => `pages.settings.cookiesSettings.cookiesPopup.${key}`;

  return (
    <Box>
      <Grid container style={{ backgroundColor: experienceBackgroundColor }}>
        <Grid item sm={12} className="text-align-center">
          {experienceImageLink && !hideIcon && (
            <Box className="inline-block" mb={5}>
              <img alt="" src={experienceImageLink} />
            </Box>
          )}
          <Box mb={5}>
            <CustomFontWrapper
              font={experienceTitleSettings?.font}
              style={getInlineFontStyles(experienceTitleSettings)}
            >
              {experienceTitle}
            </CustomFontWrapper>
          </Box>
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <Box display="flex" alignItems="center" mb={5}>
              <Grid item sm={6} className="flex align-items-center">
                <CustomFontWrapper
                  font={experienceOptionsSettings?.font}
                  style={getInlineFontStyles(experienceOptionsSettings)}
                >
                  {t(getTransPath('preview.audioTitle'), { lng: currentLanguageCode })}
                </CustomFontWrapper>
              </Grid>
              <Grid item sm={6}>
                <Box className="control-wrapper" mb={1}>
                  <span className="switch-text" style={{ color: experienceOptionsSettings?.color }}>
                    {t(getTransPath('preview.off'), { lng: currentLanguageCode })}
                  </span>
                  <Box className="switch experience-toggle" style={{ color: experienceOptionsSettings?.color }} />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <Box display="flex" alignItems="center">
              <Grid item sm={6} className="flex align-items-center">
                <CustomFontWrapper
                  font={experienceOptionsSettings?.font}
                  style={getInlineFontStyles(experienceOptionsSettings)}
                >
                  {t(getTransPath('preview.fullscreenTitle'), { lng: currentLanguageCode })}
                </CustomFontWrapper>
              </Grid>
              <Grid item sm={6}>
                <Box className="control-wrapper" mb={1}>
                  <span className="switch-text" style={{ color: experienceOptionsSettings?.color }}>
                    {t(getTransPath('preview.off'), { lng: currentLanguageCode })}
                  </span>
                  <Box className="switch experience-toggle" style={{ color: experienceOptionsSettings?.color }} />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ backgroundColor }}>
        <Grid item sm={12} className="text-align-center">
          {useImage ? (
            headerImageLink && (
              <Box className="inline-block m-auto">
                <img alt="" src={headerImageLink} />
              </Box>
            )
          ) : (
            <CustomFontWrapper font={headerTextFontSettings?.font} style={getInlineFontStyles(headerTextFontSettings)}>
              {headerText}
            </CustomFontWrapper>
          )}
        </Grid>
        {description && (
          <Grid item sm={12} className="text-align-center subtitle">
            <CustomFontWrapper
              font={descriptionFontSettings?.font}
              style={getInlineFontStyles(descriptionFontSettings)}
            >
              {description}
            </CustomFontWrapper>
          </Grid>
        )}
        <Grid item sm={12} className="buttons-wrapper">
          <CustomFontWrapper font={buttonFontSettings?.font} style={getInlineFontStyles(buttonFontSettings)}>
            <Grid container justify="space-between">
              <Grid item sm={6}>
                <ButtonPreviewBox className="cookies-btn justify-content-center" style={{ color: backgroundColor, backgroundColor: buttonColor }}>
                  {t(getTransPath('preview.buttons.acceptAll'), { lng: currentLanguageCode })}
                </ButtonPreviewBox>
              </Grid>
              <Grid item sm={6}>
                <ButtonPreviewBox className="cookies-btn justify-content-center outlined" style={{ borderColor: secondaryButtonColor, color: secondaryButtonColor }}>
                  {t(getTransPath('preview.buttons.justNecessary'), { lng: currentLanguageCode })}
                </ButtonPreviewBox>
              </Grid>
            </Grid>
            <Grid item sm={12} className="text-align-center m-auto">
              <Box className="settings-button">
                <CookieIcon />
                <span className="text">
                  {t(getTransPath('preview.buttons.settings'), { lng: currentLanguageCode })}
                </span>
                <ExpandLessIcon />
              </Box>
            </Grid>
          </CustomFontWrapper>
        </Grid>
        <Grid item sm={12}>
          <Box display="flex" alignItems="center" mt={5}>
            <Grid item sm={6} className="flex align-items-center">
              <CustomFontWrapper font={titlesFontSettings?.font} style={getInlineFontStyles(titlesFontSettings)}>
                {t(getTransPath('preview.necessaryCookies.title'), { lng: currentLanguageCode })}
              </CustomFontWrapper>
            </Grid>
            <Grid item sm={6}>
              <Box className="control-wrapper" style={{ color: buttonColor }}>
                {t(getTransPath('preview.alwaysOn'), { lng: currentLanguageCode })}
                <Box className="done-icon-wrapper" style={{ color: backgroundColor, backgroundColor: buttonColor }}>
                  <DoneIcon />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box my={5}>
            <CustomFontWrapper font={textFontSettings?.font} style={getInlineFontStyles(textFontSettings)}>
              {t(getTransPath('preview.necessaryCookies.text'), { lng: currentLanguageCode })}
              <span className="underline">{t(getTransPath('preview.link'), { lng: currentLanguageCode })}</span>
            </CustomFontWrapper>
          </Box>
        </Grid>
        <Grid item sm={6} className="flex align-items-center">
          <CustomFontWrapper font={titlesFontSettings?.font} style={getInlineFontStyles(titlesFontSettings)}>
            {t(getTransPath('preview.analyticCookies.title'), { lng: currentLanguageCode })}
          </CustomFontWrapper>
        </Grid>
        <Grid item sm={6}>
          <Box className="control-wrapper">
            <span className="switch-text">
              {t(getTransPath('preview.off'), { lng: currentLanguageCode })}
            </span>
            <Box className="switch" style={{ color: buttonColor }} />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box mt={5}>
            <CustomFontWrapper font={textFontSettings?.font} style={getInlineFontStyles(textFontSettings)}>
              {analyticText || t(getTransPath('preview.analyticCookies.text'), { lng: currentLanguageCode })}
              <span className="underline">{t(getTransPath('preview.link'), { lng: currentLanguageCode })}</span>
            </CustomFontWrapper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export const MultilanguageCookiesPopupPreview = compose(withLocalization)(CookiesPopupPreview);
