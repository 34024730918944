import { AxiosError } from 'axios';
import { Dictionary, has, isArray, mapKeys } from 'lodash';

import { BASE } from 'contexts/ServerErrorContext/consts';
import { ServerErrors, ServerErrorsArray, DataServerErrors } from 'types/common';
import { getDataWithCamelCaseItems } from 'utils/changeCase';
import { getTWithPathKey } from 'utils/localization';

const getError = (error: string | string[], fieldName = BASE): Record<string, string[]> => ({
  [fieldName]: Array.isArray(error) ? [...error] : [error],
});

export const getServerError = (text: string | string[], fieldName: string = BASE): DataServerErrors<string[]> => ({
  response: { data: { errors: getError(text, fieldName) } },
});

export const normalizeServerError = (error: AxiosError<ServerErrorsArray<string>>): Record<string, string[]> => {
  const getT = getTWithPathKey('common.errors');

  try {
    const listOfErrors = error.response?.data.errors;

    return !!listOfErrors && listOfErrors?.length ? getError(listOfErrors) : getError(getT('defaultErrorMessage'));
  } catch (err) {
    return getError(getT('defaultErrorMessage'));
  }
};

export const getErrorValue = (
  error: AxiosError<ServerErrorsArray<string> | ServerErrors<string[]>> | DataServerErrors<string[]>,
  formId: string = '',
): Dictionary<string[]> => {
  const { response } = error;
  // multiple messages
  const curErrorMessages =
    has(response, 'data.errors') && !isArray(response?.data.errors)
      ? mapKeys(response?.data.errors, (_, key) => (key === BASE ? key : `${formId}${key}`))
      : normalizeServerError(error as AxiosError<ServerErrorsArray<string>>);

  return getDataWithCamelCaseItems<string[]>(curErrorMessages);
};
