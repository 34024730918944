import React from 'react';

import {
  Box,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { ButtonComponent } from 'components';
import { getImageThumbUrl, getTWithPathKey } from 'utils';

import { Dialog, DialogContentText, DialogTitle, ImageContainer, TableRow } from './style';

function VariantDetailsDialog({ variantImage, variants, handleClose, columns }) {
  const getT = getTWithPathKey('pages.products.manageProduct.productPictures.variantDetailsDialog');

  const columnsArray = Object.entries(columns)
    .map(([key, label]) => ({ key, label }))
    .filter(({ key }) => key !== 'picture');

  const productVariants = variants.filter(item => item.picture?.id === variantImage.id);

  const renderCellContent = (column, variant) => {
    switch (column.key) {
      case 'price':
        return (
          <>
            {!variant.pricesInClientCurrencies && <Typography key={variant.price}>{variant.price}</Typography>}
            {variant.pricesInClientCurrencies?.map(({ price }) => (
              <Typography key={price.currencyCode}>{`${price.currencyCode} ${price.amount}`}</Typography>
            ))}
          </>
        );
      default:
        return <Typography>{variant[column.key]?.toString()}</Typography>;
    }
  };

  return (
    <Dialog open={!!variantImage} onBackdropClick={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{getT('headerText')}</DialogTitle>
      <DialogContent>
        <Box mb={10}>
          <DialogContentText>{getT('text')}</DialogContentText>
        </Box>
        <Box display="flex">
          <ImageContainer>
            <img src={getImageThumbUrl(variantImage)} alt={variantImage.seoTitle} />
          </ImageContainer>
          <TableContainer>
            <Table>
              <TableBody>
                {productVariants.map(variant => (
                  <TableRow key={variant.id}>
                    {columnsArray.map(column => (
                      <TableCell key={column.key}>
                        <Box mb={2}>
                          <Typography variant="h4">{column.label}</Typography>
                        </Box>
                        {renderCellContent(column, variant)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonComponent onClick={handleClose} text={getT('close')} />
      </DialogActions>
    </Dialog>
  );
}

VariantDetailsDialog.propTypes = {
  variantImage: PropTypes.shape({
    seoTitle: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string,
      pricesInClientCurrencies: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.shape({
            currencyCode: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  columns: PropTypes.shape({}).isRequired,
};

export default VariantDetailsDialog;
