import React from 'react';

import PropTypes from 'prop-types';

import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';

import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';

import { Box, Typography, DoneIcon, CircularProgress } from './style';

export const text = { success: 'File upload', loading: 'File is being uploaded…' };

export function FileUpload({ handleDelete, validationError, isLoading }) {
  const renderFile = () => (
    <>
      <DoneIcon className={validationError ? UPLOAD_STATUSES.ERROR : UPLOAD_STATUSES.SUCCESS} />
      <Typography
        display="inline"
        variant="h5"
        className={validationError ? UPLOAD_STATUSES.ERROR : UPLOAD_STATUSES.SUCCESS}
      >
        {text.success}
      </Typography>
      <IconWithConfirmation message="file" handleConfirm={handleDelete} />
    </>
  );

  const renderLoading = () => (
    <>
      <CircularProgress />
      <Typography display="inline" variant="h5" className="isLoading">
        {text.loading}
      </Typography>
    </>
  );

  return <Box>{isLoading ? renderLoading() : renderFile()}</Box>;
}
FileUpload.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

FileUpload.defaultProps = { validationError: null };
