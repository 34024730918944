import React, { useState, useEffect, useMemo } from 'react';

import { Box, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent, AutocompleteValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useAuthState } from 'contexts/AuthContext';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '../style';

const defaultPlanogramGroup = { name: '', id: null, legacy: false, isLanding: false };

export function PlanogramGroupDialog({ open, planogramGroup, handleSubmit, title, handleClose, domains, isLoading }) {
  const { t } = useTranslation();
  const { clientInternalHost } = useAuthState().currentUser.attributes;

  const [state, setState] = useState({});

  useEffect(() => {
    if (!open) {
      setState({});
      return;
    }
    planogramGroup ? setState(planogramGroup) : setState(defaultPlanogramGroup);
  }, [open]);

  const handleCheckboxChange = itemName => () =>
    setState(prevState => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const onSubmit = () => handleSubmit(state);

  const visibleDomains = useMemo(() => {
    if (domains.length > 1) return [{ id: null, host: 'All' }, ...domains];
    if (domains.length === 0) return [{ id: null, host: clientInternalHost }];
    return domains;
  }, [domains, clientInternalHost]);

  const disableSelectDomains = visibleDomains.length < 2;

  return (
    open && (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle className="pb-0">{title}</DialogTitle>
        <ValidatorForm onSubmit={onSubmit}>
          <DialogContent>
            <TextValidatorComponent
              validators={[
                VALIDATORS_MAP.required,
                VALIDATORS_MAP.lowercaseLettersNumbersUnderscoreDashes,
                VALIDATORS_MAP.maxStringLength(50),
              ]}
              label={t('pages.planograms.managePlanograms.dialog.name')}
              value={state?.name}
              fieldName="name"
              handleChange={handleChange}
              autoFocus
            />
            <AutocompleteValidatorComponent
              validators={[VALIDATORS_MAP.required]}
              value={disableSelectDomains ? visibleDomains[0].id : state.externalHostId}
              fieldName="externalHostId"
              handleChange={handleChange}
              selectList={visibleDomains}
              label={t('pages.planograms.managePlanograms.dialog.domain')}
              itemsNamePath="host"
              disabled={disableSelectDomains}
            />
            {!!state?.legacy && (
              <Box mb={4}>
                <Typography variant="caption">
                  <Checkbox
                    color="primary"
                    id="legacy"
                    checked={state?.legacy}
                    onChange={handleCheckboxChange('legacy')}
                    disabled={Boolean(state?.id)}
                  />
                  <span>{t('pages.planograms.managePlanograms.dialog.legacy')}</span>
                </Typography>
              </Box>
            )}
            <Box mb={4}>
              <Typography variant="caption">
                <Checkbox
                  color="primary"
                  id="isLanding"
                  checked={state?.isLanding}
                  onChange={handleCheckboxChange('isLanding')}
                />
                <span>{t('pages.planograms.managePlanograms.dialog.landing')}</span>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <ButtonComponent onClick={handleClose} text={t('common.buttons.cancel')} />
            <ButtonComponent
              disabled={isLoading}
              loaderSize="1.35em"
              loading={isLoading}
              type="submit"
              color="primary"
              text={t('common.buttons.save')}
            />
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  );
}

PlanogramGroupDialog.propTypes = {
  planogramGroup: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    clientSubdomainId: PropTypes.number,
    legacy: PropTypes.bool,
  }),
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      host: PropTypes.string,
    }),
  ),
};

PlanogramGroupDialog.defaultProps = {
  planogramGroup: null,
  handleSubmit: () => {},
};
