import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { TableComponent } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { reservedClientsModel } from 'models/ReservedClientsModel';
import { ReservedClient } from 'types';
import { ActionType, OrderByParameter } from 'types/enums';
import { TableParams } from 'types/other';

function ManageReservedClients() {
  const { t } = useTranslation();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError } = useServerErrorActions();

  const [reservedClients, setReservedClients] = useState<ReservedClient[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({ ...DEFAULT_TABLE_PARAMS });
  const [paginationItemsCount, setPaginationItemsCount] = useState(0);
  const [clientToDelete, setClientToDelete] = useState<ReservedClient>();

  useEffect(() => {
    getReservedClients();
  }, [tableParams]);

  const getReservedClients = withPageProgressHandler(() => {
    const { search, ...rest } = tableParams;

    return reservedClientsModel
      .getReservedClients({
        ...rest,
        query: search,
      })
      .then(data => {
        setReservedClients(data.reservedClients);
        setPaginationItemsCount(data.reservedClientsCount);
      })
      .catch(handleServerError);
  });

  const handleDelete = withPageProgressHandler(({ id }) => {
    setClientToDelete(undefined);
    return reservedClientsModel.deleteReservedClient(id).then(getReservedClients).catch(handleServerError);
  });

  const tableData = useMemo(
    () => ({
      headers: [
        t('components.tableComponent.headers.name'),
        t('components.tableComponent.headers.owner'),
        { title: t('components.tableComponent.headers.created'), sortKey: OrderByParameter.CREATED_AT },
      ],
      rows: reservedClients.map(client => ({
        id: client.id,
        cells: [client.clientName, client.email, client.createdAt],
        actions: [{ type: ActionType.DELETE, onClick: () => setClientToDelete(client) }],
      })),
    }),
    [reservedClients],
  );

  return (
    <PageWithHeader
      tabTitle={t('pages.reservedClients.title')}
      headerText={`${t('pages.reservedClients.title')}: ${paginationItemsCount}`}
    >
      <TableComponent
        data={tableData}
        count={paginationItemsCount}
        tableParams={tableParams}
        onParamsChange={params => setTableParams(prev => ({ ...prev, ...params }))}
        enableSearch
      />
      {clientToDelete && (
        <ConfirmationDialog
          open
          title={t('pages.reservedClients.confirmDeleteTitle')}
          message={t('pages.reservedClients.confirmDeleteBody', { clientName: clientToDelete.clientName })}
          confirmText={t('pages.reservedClients.confirmDeleteButton')}
          handleYes={() => handleDelete(clientToDelete)}
          handleNo={() => setClientToDelete(undefined)}
        />
      )}
    </PageWithHeader>
  );
}

export default ManageReservedClients;
