import { Dictionary, get, omit } from 'lodash';

import { DispatchAction } from 'types/common';

import { ServerErrorActions } from './types';

export type HandleServerErrorAction = DispatchAction<ServerErrorActions.HANDLE_SERVER_ERROR, Dictionary<string[]>>;
export type ResetServerErrorsAction = DispatchAction<ServerErrorActions.RESET_SERVER_ERRORS>;
export type ResetFormErrorAction = DispatchAction<ServerErrorActions.RESET_SERVER_FORM_ERROR, string>;

type ActionTypes = HandleServerErrorAction | ResetServerErrorsAction | ResetFormErrorAction;

export default (state: Dictionary<string[]>, action: ActionTypes): Dictionary<string[]> => {
  switch (action.type) {
    case ServerErrorActions.HANDLE_SERVER_ERROR: {
      return { ...state, ...action.payload };
    }
    case ServerErrorActions.RESET_SERVER_ERRORS: {
      return {};
    }
    case ServerErrorActions.RESET_SERVER_FORM_ERROR: {
      if (get(state, action.payload, false)) {
        return omit(state, [action.payload]);
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
