import React from 'react';

import { TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from 'components/ecommerce/constants';
import { getTWithPathKey } from 'utils';

import { Box } from 'components/ecommerce/style';
import { Switch } from 'components/style';


export function DisplayProperties({ ecommerceSettings, setSettings }) {
  const getT = getTWithPathKey(`components.ecommerce.overlay.pdp.displayProperties`);

  const handleSwitchChange = () =>
    setSettings(prev => ({ ...prev, showDetails: !ecommerceSettings.showDetails }));


  return (
    <OneColumnExpandedTable title={getT('title')} caption={getT('caption')}>
      <TableRow>
        <TableCell colSpan={2}>
          <Box mb={2}>
            <Typography variant="h6">{getT('productDetails.title')}</Typography>
          </Box>
          <Typography variant="subtitle1" className="information">{getT('productDetails.caption')}</Typography>
          <Box my={2.5} display="flex" alignItems="center" className="m0">
            <Typography variant="h4">{getT('productDetails.toggleCaption')}</Typography>
            <Switch checked={ecommerceSettings?.showDetails} onChange={handleSwitchChange} />
          </Box>
        </TableCell>
      </TableRow>
    </OneColumnExpandedTable>
  );
}

DisplayProperties.propTypes = {
  ecommerceSettings: PropTypes.shape({
    overlayType: PropTypes.oneOf(Object.values(ECOMMERCE_OVERLAYS_SETTINGS_TYPES)),
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
};
