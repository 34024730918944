import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { TextFieldComponent } from 'components/TextFieldComponent/TextFieldComponent';
import { getTWithPathKey } from 'utils';

import { Box } from '../style';

export function TextFieldsTable({ textFieldSettings, setTextFieldSettings }) {
  const getT = getTWithPathKey('components.ecommerce.textFields');

  const handleChange = ({ target }) =>
    setTextFieldSettings({
      ...textFieldSettings,
      borderWidth: target.value,
    });

  return (
    <OneColumnExpandedTable title={getT('title')} caption={getT('caption')}>
      <TableRow hover>
        <TableCell colSpan={2}>
          <Box display="flex" className="color-picker-wrap">
            <ColorPicker
              setColor={val => setTextFieldSettings({ ...textFieldSettings, borderColor: val })}
              color={textFieldSettings.borderColor}
            />
            <TextFieldComponent
              className="ml-4"
              label="Width"
              type="number"
              onChange={handleChange}
              value={textFieldSettings.borderWidth}
            />
          </Box>
        </TableCell>
      </TableRow>
    </OneColumnExpandedTable>
  );
}

TextFieldsTable.propTypes = {
  textFieldSettings: PropTypes.shape({
    borderColor: PropTypes.shape({
      hex: PropTypes.string,
    }),
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  setTextFieldSettings: PropTypes.func.isRequired,
};
