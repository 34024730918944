import React, { useState, useEffect, useMemo, useRef } from 'react';

import { Divider, useMediaQuery, Typography, Grid } from '@material-ui/core';
import { pick } from 'lodash';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonComponent } from 'components';
import DividerWithText from 'components/DividerWithText/DividerWithText';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { OKTA_LOGIN_URL, SSO_LOGIN_ERROR_QUERY_PARAM } from 'constants/technical';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useResetClientGlobalContexts, useSubmittingState } from 'hooks';
import { authModel, tfaModel } from 'models';
import { ROUTES } from 'routes/constants';
import { checkHasToken, getServerError, navigateByReturnToUrl } from 'utils';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';

import { SignInPageMobile } from './SignInPageMobile';

import { StyledButtonComponent, AuthCard, ErrorText, FormLink, TextValidator } from '../style';

import { ReactComponent as GoogleIcon } from 'assets/img/google.svg';
import { ReactComponent as OktaIcon } from 'assets/img/okta.svg';

const SignInPage = ({ setSuccessActionMessages }) => {
  const {
    currentUser: { isSignedIn, attributes },
  } = useAuthState();

  const [state, setState] = useState({
    [authModel.fields.space]: '',
    [authModel.fields.email]: '',
    [authModel.fields.password]: '',
  });
  const [autoFill, setAutoFill] = useState({
    [authModel.fields.email]: false,
    [authModel.fields.password]: false,
  });
  const [firstStepAuth, setFirstStepAuth] = useState(true);
  const history = useHistory();
  const { login } = useAuthActions();
  const { handleServerError } = useServerErrorActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const resetClientGlobalContexts = useResetClientGlobalContexts();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const location = useLocation();
  const otpRef = useRef(null);

  const ssoError = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get(SSO_LOGIN_ERROR_QUERY_PARAM);
  }, [location]);

  useEffect(() => {
    resetClientGlobalContexts();
  }, []);

  useEffect(() => {
    if (!firstStepAuth && otpRef.current) {
      otpRef.current.focus();
    }
  }, [firstStepAuth]);

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }
    if (attributes.enabled) {
      if (checkHasToken() && attributes.otpNeedEnable && !attributes.otpRequiredForLogin) {
        history.push(`${ROUTES.tFASettingsNew}${window.location.search}`);
      } else if (attributes.id && checkHasToken()) {
        navigateByReturnToUrl();
        history.push(ROUTES.home);
      }
    } else {
      handleServerError(getServerError(t('common.errors.noAccess')));
    }
  }, [isSignedIn, attributes, checkHasToken()]);

  const onResetGA = () =>
    tfaModel
      .sendResetToEmail(pick(state, ['email', 'clientName']))
      .then(({ success }) => setSuccessActionMessages(success))
      .catch(handleServerError);

  const submitForm = () =>
    authModel
      .signIn(state)
      .then(attributes => {
        if (attributes?.enabled) {
          login({
            currentUser: {
              isLoading: false,
              isSignedIn: true,
              attributes,
            },
          });
        } else if (attributes?.id && !attributes?.enabled) {
          handleServerError(getServerError(t('common.errors.noAccess')));
        } else if (!attributes?.id && firstStepAuth) {
          setFirstStepAuth(false);
        } else {
          handleServerError(getServerError(t('common.errors.noAccess')));
        }
      })
      .catch(handleServerError);

  const handleChange = item => {
    const key = Object.keys(item)[0];

    setAutoFill({ ...autoFill, [key]: false });
    setState(prevState => ({
      ...prevState,
      ...item,
    }));
  };

  const onSignUp = () => history.push(ROUTES.authSignUp);

  const onForgotPassword = () => history.push(ROUTES.resetPassword);

  const onSignInWithOkta = () => {
    window.open(OKTA_LOGIN_URL, '_blank');
  };

  const onSignInWithGoogle = () => {
    authModel.redirectToGoogleAuth();
  };

  const disableSignInButton = [authModel.fields.email, authModel.fields.password].some(
    field => state[field] === '' && !autoFill[field],
  );

  if (isMobile) {
    return <SignInPageMobile />;
  }

  if (!firstStepAuth) {
    return (
      <AuthCard>
        <Helmet title={t('pages.auth.signInPage.tabTitle')} />
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h2">{t('pages.auth.signInPage.title')}</Typography>
          </Grid>
          <Grid item>
            <ValidatorForm onSubmit={() => withHandlingSubmittingState(submitForm)()} style={{ width: '100%' }}>
              <TextValidator
                validators={[VALIDATORS_MAP.required]}
                label={t('pages.auth.signInPage.googleCodeInputLabel')}
                value={state.otpAttempt}
                fieldName="otpAttempt"
                handleChange={handleChange}
                autoFocus
                inputRef={otpRef}
              />
              <Grid container justify="space-between">
                <Grid item>
                  <FormLink onClick={onResetGA} variant="text" text={t('pages.auth.signInPage.resetPassBtn')} />
                </Grid>
                <Grid item>
                  <ButtonComponent color="primary" type="submit" text={t('pages.auth.signInPage.2faSignInBtn')} />
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </AuthCard>
    );
  }

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signInPage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signInPage.title')}</Typography>
        </Grid>
        <Grid item>
          <ValidatorForm onSubmit={() => withHandlingSubmittingState(submitForm)()} style={{ width: '100%' }}>
            <TextValidator
              validators={[VALIDATORS_MAP.spaceName]}
              label={t('common.forms.labels.spaceName')}
              value={state[authModel.fields.space]}
              fieldName={authModel.fields.space}
              handleChange={handleChange}
              autoComplete="on"
              autoFocus
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isEmail]}
              label={t('common.forms.labels.email')}
              value={state[authModel.fields.email]}
              fieldName={authModel.fields.email}
              handleChange={handleChange}
              autoComplete="email"
              type="email"
              onAutoFill={() => setAutoFill(prevState => ({ ...prevState, [authModel.fields.email]: true }))}
            />
            <TextValidator
              validators={[VALIDATORS_MAP.required]}
              label={t('common.forms.labels.password')}
              value={state[authModel.fields.password]}
              fieldName={authModel.fields.password}
              handleChange={handleChange}
              autoComplete="current-password"
              type="password"
              onAutoFill={() => setAutoFill(prevState => ({ ...prevState, [authModel.fields.password]: true }))}
            />
            <StyledButtonComponent
              fullWidth
              color="primary"
              type="submit"
              text={t('pages.auth.signInPage.emailSignInBtn')}
              loading={isSubmitting}
              disabled={disableSignInButton}
            />
          </ValidatorForm>
        </Grid>
        <Grid item>
          <DividerWithText text={t('pages.auth.common.divider')} />
        </Grid>
        <Grid item container spacing={6}>
          <Grid item xs>
            <StyledButtonComponent
              fullWidth
              color="primary"
              text={t('pages.auth.signInPage.oktaSignInBtn')}
              variant="outlined"
              Icon={OktaIcon}
              onClick={onSignInWithOkta}
            />
          </Grid>
          <Grid item xs>
            <StyledButtonComponent
              fullWidth
              color="primary"
              text={t('pages.auth.signInPage.googleSignInBtn')}
              variant="outlined"
              Icon={GoogleIcon}
              onClick={onSignInWithGoogle}
            />
          </Grid>
        </Grid>
        {!!ssoError && <ErrorText>{ssoError}</ErrorText>}
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs style={{ textAlign: 'center' }}>
            <FormLink text={t('pages.auth.signInPage.createNewProfileBtn')} variant="text" onClick={onSignUp} />
          </Grid>
          <Grid item xs style={{ textAlign: 'center' }}>
            <FormLink text={t('pages.auth.signInPage.forgotPassword')} variant="text" onClick={onForgotPassword} />
          </Grid>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

SignInPage.propTypes = {
  ...withSuccessActionsDialogProps,
};

export default withSuccessActionsDialog(SignInPage);
