import React, { memo, useState } from 'react';

import { Grid } from '@material-ui/core';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, ImageChooseComponent, SettingsForm } from 'components';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { useSubmittingState } from 'hooks';
import { controlButtonModel, planogramsModel } from 'models';

import { Tooltip, GridCaptionContainer } from './style';
import { InfoOutlinedIcon } from 'components/InfoTooltip/style';
import { ImageUploadCaption, Image } from 'components/style';

import img from 'assets/img/preview.jpg';

const FORM_ID = 'searchIcon';

function SearchIconSettingsComponent({
  planogramId,
  planogramVersion,
  handleServerError,
  setSuccessActionMessages,
}) {
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const [iconSettings, setIconSettings] = useState(planogramVersion?.planogramVersionSetting?.controlButton);
  const [initialIconSettings, setInitialIconSettings] = useState(
    planogramVersion?.planogramVersionSetting?.controlButton
  );

  const getImages = params => controlButtonModel.getImages(params).catch(handleServerError);

  const handleSaveSearchIcon = withHandlingSubmittingState(() => {
    const data = {
      planogramVersionSetting: {
        controlButton: { ...iconSettings },
        controlButtonId: iconSettings.id ?? null
      }
    };

    return planogramsModel
      .editVersion(planogramId, planogramVersion.id, data)
      .then(({ planogramVersion, success }) => {
        const icon = planogramVersion.planogramVersionSetting.controlButton;

        setSuccessActionMessages(success);
        setIconSettings(icon);
        setInitialIconSettings(icon);
      })
      .catch(handleServerError)
  });

  const renderTooltip = () => <Image src={img} />;

  return (
    <SettingsForm
      isSaveButtonVisible={!isSubmitting && !isEqual(iconSettings, initialIconSettings)}
      handleSave={handleSaveSearchIcon}
      id={FORM_ID}
    >
      <CardComponent title="Search icon" xs={9} alignItems="center" isLoading={isSubmitting}>
        <Grid item sm={12}>
          <Grid container spacing={3}>
            <Grid item sm={4} style={{ minWidth: '150px' }}>
              <ImageChooseComponent
                image={iconSettings}
                setImage={setIconSettings}
                handleServerError={handleServerError}
                dialogTitle="Choose search icon"
                getPictures={getImages}
              />
            </Grid>
            <GridCaptionContainer item sm={8}>
              <ImageUploadCaption>
                <span>{IMAGE_UPLOAD_CAPTION.searchIcon}</span>
                <Tooltip title={renderTooltip()} placement="right-start">
                  <InfoOutlinedIcon className="middle" />
                </Tooltip>
              </ImageUploadCaption>
            </GridCaptionContainer>
          </Grid>
        </Grid>
      </CardComponent>
    </SettingsForm>
  );
}

SearchIconSettingsComponent.propTypes = {
  planogramVersion: PropTypes.shape({
    controlButtonId: PropTypes.number,
    controlButton: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  planogramId: PropTypes.string.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};

export const SearchIconSettings = memo(SearchIconSettingsComponent);
