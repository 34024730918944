import React, { useEffect, useMemo, useState, useRef } from 'react';

import { pick, omit, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { CardComponent, SettingsForm, SortableListWrapper, FontSettingsControlWrapper, LinkBehavior } from 'components';
import { AutocompleteValidatorComponent, TextValidatorComponent } from 'components/FormComponents';
import { getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';
import { errorResponseModel, planogramsModel } from 'models';
import { useSubmittingState } from 'hooks';
import {
  FontSettingsType,
  PlanogramObjectsResponse,
  PlanogramVersion,
  PlanogramVersionControlButton,
  PlanogramVersionUpdateParams,
} from 'types';
import { FontSettingsEditDialog } from 'components/Dialogs';
import {
  ALIGNMENTS,
  ALIGNMENTS_OPTIONS,
  NAVIGATION_BUTTON_TYPES,
  NAVIGATION_BUTTON_TYPES_OPTIONS,
} from 'constants/technical';
import { MenuButtonSettings } from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/MenuButtonSettings';
import {
  PlanogramObjectData
} from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/helper';
import ControlButtonLinkBehaviour from 'pages/planograms/ManagePlanogramSettings/NavigationSettings/ControlButtonLinkBehaviour';
import { ControlButtonNavigationType } from 'types/enums';

import { ControlButtonSettings } from '../ControlButtonSettings/ControlButtonSettings';
import { SearchSettings } from '../SearchSettings/SearchSettings';

import {
  controlSettingsValues,
  internalSearchSettingsValues,
  menuSettingsValues,
  searchSettingsValues,
} from './constants';

import { Switch } from 'components/style';
import { InfoOutlinedIcon } from 'components/InfoTooltip/style';
import { NavigationAddButton, Chip } from './style';

const FORM_ID = 'navigationSettingsForm';

interface InternalButtonProps extends Partial<PlanogramVersionControlButton> {
  internalId?: string;
  initialSettings?: boolean;
}

const defaultNavigationSettings: InternalButtonProps = {
  internalId: uuidv4(),
  initialSettings: true,
  title: 'Control button',
};

const defaultSettings: Partial<PlanogramVersion> = {
  navigationAlignment: ALIGNMENTS.MIDDLE,
  navigationDistributeEvenly: false,
  planogramVersionControlButtons: [],
};

const AllowedSphereObjects = {
  clusters: 'clusters',
  curves: 'curves',
  paths: 'paths',
  pictures: 'pictures',
  products: 'products',
  shapes: 'shapes',
  textAreas: 'textAreas',
  videos: 'videos',
}

interface NavigationSettingsProps {
  planogramVersion: PlanogramVersion;
  planogramId: number;
  handleServerError: (err: any) => void;
  setSuccessActionMessages: (messages: string[]) => void;
}

const NavigationSettings = ({
  planogramId,
  planogramVersion,
  handleServerError,
  setSuccessActionMessages,
}: NavigationSettingsProps) => {
  const [activeTab, setActiveTab] = useState<InternalButtonProps>();
  const [toggle, setToggle] = useState(!!planogramVersion.planogramVersionControlButtons.length);
  const [fontSettingsEdit, setFontSettingsEdit] = useState<FontSettingsType | null>(null);
  const [settings, setSettings] = useState(defaultSettings);
  const [initialSettings, setInitialSettings] = useState(defaultSettings);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const formRef = useRef<ValidatorForm>(null);
  const [planogramObjectData, setPlanogramObjectData] = useState<PlanogramObjectData[]>([]);

  useEffect(() => {
    getNavigationSettings(planogramVersion);
    setPlanogramObjectData([]);
    errorResponseModel.getHostErrorResponses().then(({ planograms }) => {
      const formattedPlanograms = planograms.map(planogram => {
        const [lang, name] = planogram.urlPath.split('/');
        const formattedName = `${name}_${lang}`;
        const planogramObjects = {} as PlanogramObjectsResponse;

        Object.keys(AllowedSphereObjects).forEach(key => {
          planogramObjects[key as keyof PlanogramObjectsResponse] = []
        });

        return {
          planogramName: formattedName,
          id: planogram.id,
          objects: planogramObjects,
        }
      });

      setPlanogramObjectData(formattedPlanograms);
    });
  }, []);

  const controlButton = useMemo(
    () =>
      settings.planogramVersionControlButtons?.find(
        (item: InternalButtonProps) => item?.internalId === activeTab?.internalId,
      ),
    [settings, activeTab],
  );

  const getNavigationSettings = (settings: PlanogramVersion) => {
    const parsedSettings = {
      navigationAlignment: settings.navigationAlignment,
      planogramVersionControlButtons: settings.planogramVersionControlButtons?.map(button => ({
        ...button,
        navigationValueSelect:
          button.navigationType === ControlButtonNavigationType.INTERNAL ? button.navigationValue : null,
        navigationValueUrl:
          button.navigationType === ControlButtonNavigationType.EXTERNAL ? button.navigationValue : null,
        planogramVersionControlButtonFont: getFontSettingsWithNums(
          button.planogramVersionControlButtonFont,
        ) as FontSettingsType,
        internalId: uuidv4(),
      })),
      navigationDistributeEvenly: settings.navigationDistributeEvenly,
    } as Partial<PlanogramVersion>;

    setActiveTab(parsedSettings.planogramVersionControlButtons?.[0]);
    setSettings(parsedSettings);
    setInitialSettings(parsedSettings);
  };

  const validateTabs = async () => {
    const formsValidation = settings.planogramVersionControlButtons?.map(button => {
      setActiveTab(button);

      return formRef.current?.isFormValid(true);
    }) as Promise<boolean>[];

    return Promise.all(formsValidation).then(formsState =>
      formsState.every((isValid, index) => {
        if (!isValid) {
          setActiveTab(settings.planogramVersionControlButtons?.[index]);
          // @ts-ignore
          formRef.current?.submit();
        }

        return isValid;
      }),
    );
  };

  const onSubmit = withHandlingSubmittingState(async () => {
    const hasValidationError = await validateTabs();
    const dataToSave = {
      ...settings,
      planogramVersionControlButtons: settings.planogramVersionControlButtons?.map(buttonSettings => {
        let navigationValue = null;

        if (buttonSettings.navigationType === ControlButtonNavigationType.INTERNAL)
          navigationValue = buttonSettings.navigationValueSelect;
        if (buttonSettings.navigationType === ControlButtonNavigationType.EXTERNAL)
          navigationValue = buttonSettings.navigationValueUrl;
        if (buttonSettings.elementType === NAVIGATION_BUTTON_TYPES.CONTROL_BUTTON) {
          return {
            ...buttonSettings,
            controlButtonId: buttonSettings.controlButton?.id ?? buttonSettings.controlButtonId,
            navigationValue,
            planogramVersionControlButtonFont: getFontSettingsWithPx(buttonSettings.planogramVersionControlButtonFont),
          };
        }

        return {
          ...buttonSettings,
          controlButtonId: buttonSettings.controlButton?.id ?? buttonSettings.controlButtonId,
          planogramVersionControlButtonFont: getFontSettingsWithPx(buttonSettings.planogramVersionControlButtonFont),
        };
      }),
    };

    if (!hasValidationError) {
      return Promise.resolve();
    }

    return planogramsModel
      .editVersion(planogramId, planogramVersion.id, dataToSave as Partial<PlanogramVersionUpdateParams>)
      .then(data => {
        setSuccessActionMessages(data.success);
        getNavigationSettings(data.planogramVersion);
      })
      .catch(handleServerError);
  });

  const handleDelete = (id: string) => {
    setSettings(prevState => {
      const items =
        prevState.planogramVersionControlButtons?.filter((item: InternalButtonProps) => item.internalId !== id) ?? [];

      if (!items.length) {
        setToggle(false);
      }

      if (activeTab?.internalId === id) {
        setActiveTab(items[0]);
      }

      return {
        ...prevState,
        planogramVersionControlButtons: items,
      };
    });
  };

  const handleButtonChanges = (item: Partial<PlanogramVersionControlButton>) => {
    setSettings(
      prevState =>
        ({
          ...prevState,
          planogramVersionControlButtons: prevState.planogramVersionControlButtons?.map((button: InternalButtonProps) =>
            button?.internalId === activeTab?.internalId
              ? {
                  ...getAllowedProperties(button),
                  ...item,
                }
              : { ...button },
          ),
        } as Partial<PlanogramVersion>),
    );
  };

  const handleChangeElementType = (item: Partial<PlanogramVersionControlButton>) => {
    if (item.elementType === NAVIGATION_BUTTON_TYPES.MENU) {
      handleButtonChanges({
        elementType: item.elementType,
        menu: {
          ...planogramVersion.defaultNavigation.menu,
          menuItems: [planogramVersion.defaultNavigation.menuItem],
        },
      });
    }
    if (item.elementType === NAVIGATION_BUTTON_TYPES.SEARCH) {
      handleButtonChanges({
        elementType: item.elementType,
        searchSetting: {
          ...planogramVersion.defaultNavigation.searchSetting,
        },
      });
    }
    if (item.elementType === NAVIGATION_BUTTON_TYPES.CONTROL_BUTTON) {
      handleButtonChanges({
        elementType: item.elementType,
      });
    }
  };

  const handleFontSettingsSave = (settings: FontSettingsType) => {
    setFontSettingsEdit(null);
    handleButtonChanges({
      planogramVersionControlButtonFont: { ...settings },
    });
  };

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsEdit = (settings: FontSettingsType) => setFontSettingsEdit({ ...settings });

  const handleOrderChanges = (list: PlanogramVersionControlButton[]) =>
    setSettings(prevState => ({
      ...prevState,
      planogramVersionControlButtons: [...list],
    }));

  const handleToggle = () => {
    if (!settings.planogramVersionControlButtons?.length) {
      handleAdd();
    }

    setToggle(prevState => !prevState);
  };

  const handleChange = (item: Record<string, boolean>) =>
    setSettings(prevState => ({
      ...prevState,
      ...item,
    }));

  const getAllowedProperties = (item: InternalButtonProps) => {
    switch (item.elementType) {
      case NAVIGATION_BUTTON_TYPES.CONTROL_BUTTON:
        return pick(item, controlSettingsValues);
      case NAVIGATION_BUTTON_TYPES.SEARCH:
        return pick(item, searchSettingsValues);
      case NAVIGATION_BUTTON_TYPES.MENU:
        return pick(item, menuSettingsValues);
      default:
        return {};
    }
  };

  const handleAdd = () =>
    setSettings(prevState => {
      const newItem = {
        ...planogramVersion.defaultNavigation.planogramVersionControlButton,
        ...defaultNavigationSettings,
        internalId: uuidv4(),
        planogramVersionControlButtonFont: getFontSettingsWithNums(
          planogramVersion.defaultNavigation.planogramVersionControlButton.planogramVersionControlButtonFont,
        ) as FontSettingsType,
      };

      if (!prevState.planogramVersionControlButtons?.length) {
        setActiveTab(newItem);
      }

      return {
        ...prevState,
        planogramVersionControlButtons: [
          ...(prevState.planogramVersionControlButtons as PlanogramVersionControlButton[]),
          newItem,
        ],
      } as Partial<PlanogramVersion>;
    });

  const isSettingsChanged = () => {
    const data = {
      ...settings,
      planogramVersionControlButtons: settings.planogramVersionControlButtons?.map(button => {
        if (button.elementType === NAVIGATION_BUTTON_TYPES.SEARCH) {
          return {
            ...omit(getAllowedProperties(button), [
              'searchActiveIcon',
              'searchSettingFont',
              'noResultSearchSettingFont',
            ]),
            searchSetting: {
              ...pick(button.searchSetting, internalSearchSettingsValues),
              searchSettingFont: { ...omit(button.searchSetting.searchSettingFont, 'fieldName') },
              noResultSearchSettingFont: { ...omit(button.searchSetting.noResultSearchSettingFont, 'fieldName') },
            },
          };
        }
        return {
          ...omit(getAllowedProperties(button), ['searchActiveIcon', 'searchSettingFont', 'noResultSearchSettingFont']),
        };
      }),
    };
    const initialData = {
      ...initialSettings,
      planogramVersionControlButtons: initialSettings.planogramVersionControlButtons?.map((button, index) => {
        if (button.elementType === NAVIGATION_BUTTON_TYPES.SEARCH) {
          return {
            ...getAllowedProperties(button),
            searchSetting: pick(button.searchSetting, internalSearchSettingsValues),
          };
        }
        return getAllowedProperties(button);
      }),
    };

    return !isEqual(data, initialData);
  };

  return (
    <SettingsForm
      isSaveButtonVisible={!isSubmitting && isSettingsChanged()}
      handleSave={onSubmit}
      id={FORM_ID}
      refForm={formRef}
    >
      <CardComponent
        title="Navigation"
        isLoading={isSubmitting}
        CardHeaderNav={() => <Switch checked={toggle} onChange={handleToggle} />}
      >
        {toggle && settings.planogramVersionControlButtons && (
          <>
            <Grid container alignItems="center">
              <Grid item style={{ flexGrow: 1, minWidth: 0 }}>
                <Box display="flex" flexGrow={1} mr={5} minWidth={0}>
                  <SortableListWrapper
                    list={settings.planogramVersionControlButtons}
                    setList={handleOrderChanges}
                    forceFallback
                    fallbackTolerance={3}
                    style={{ display: 'flex', maxWidth: 'calc(100% - 34px)' }}
                  >
                    {settings.planogramVersionControlButtons.map((button: InternalButtonProps, index: number) => (
                      <Chip
                        key={`navigation-settings-${index}`}
                        color={button.internalId === activeTab?.internalId ? 'primary' : 'default'}
                        label={button.title}
                        deleteIcon={<CloseIcon />}
                        onClick={() => {
                          setActiveTab(button);
                        }}
                        onDelete={() => handleDelete(button.internalId ?? '')}
                      />
                    ))}
                  </SortableListWrapper>
                  <NavigationAddButton
                    disabled={settings.planogramVersionControlButtons.length >= 5}
                    Icon={AddIcon}
                    onClick={handleAdd}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" my={2}>
                  <InfoOutlinedIcon className="regular" />
                  <Box ml={1}>
                    <Typography color="textSecondary" variant="caption">
                      You can add up to 5 navigation elements and drag & drop to change their order.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Box mt={6} mb={3}>
                <Typography variant="h4">Navigation layout</Typography>
              </Box>
              <Grid item sm={4} style={{ maxWidth: '12.5vw' }}>
                <AutocompleteValidatorComponent
                  value={settings.navigationAlignment ?? ''}
                  fieldName="navigationAlignment"
                  handleChange={(state: Record<string, string>) =>
                    setSettings(prevState => ({ ...prevState, ...state }))
                  }
                  itemsNamePath="label"
                  disableMinHeight
                  disableClearable
                  selectList={ALIGNMENTS_OPTIONS}
                  label="Alignment"
                />
              </Grid>
              <Box ml={-2.5} display="flex" alignItems="center">
                <Switch
                  checked={settings.navigationDistributeEvenly}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ navigationDistributeEvenly: event.target.checked })
                  }
                />
                <Typography variant="caption">Distribute evenly</Typography>
              </Box>
              <Box my={6}>
                <Divider />
              </Box>
              <Grid item sm={12}>
                <Box mb={3}>
                  <Typography variant="h4">Navigation element</Typography>
                </Box>
                <Grid container>
                  <FontSettingsControlWrapper
                    fontSettings={controlButton?.planogramVersionControlButtonFont}
                    handleSettingsEdit={handleFontSettingsEdit}
                  >
                    <Grid item sm={5}>
                      <TextValidatorComponent
                        label="Title"
                        value={controlButton?.title ?? ''}
                        fieldName="title"
                        handleChange={handleButtonChanges}
                      />
                    </Grid>
                  </FontSettingsControlWrapper>
                  <FontSettingsEditDialog
                    settings={fontSettingsEdit}
                    handleClose={closeFontSettingsDialog}
                    handleSet={handleFontSettingsSave}
                  />
                  <Grid item style={{ marginLeft: 'auto' }}>
                    <Box mt={2}>
                      <Typography variant="caption">Show title on screen</Typography>
                      <Switch
                        checked={controlButton?.showTitle ?? false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleButtonChanges({ showTitle: event.target.checked })
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item sm={5}>
                  <AutocompleteValidatorComponent
                    value={controlButton?.elementType ?? ''}
                    fieldName="elementType"
                    handleChange={handleChangeElementType}
                    itemsNamePath="label"
                    disableMinHeight
                    disableClearable
                    selectList={NAVIGATION_BUTTON_TYPES_OPTIONS}
                    getOptionDisabled={(option: Record<string, string>) =>
                      option.label === 'Product Search' &&
                      settings.planogramVersionControlButtons?.length &&
                      settings.planogramVersionControlButtons.findIndex(
                        item => item.elementType === NAVIGATION_BUTTON_TYPES.SEARCH,
                      ) >= 0
                    }
                    label="Type"
                  />
                </Grid>
                {controlButton?.elementType === NAVIGATION_BUTTON_TYPES.SEARCH && (
                  <Box mt={1} ml={-1} display="flex">
                    <InfoOutlinedIcon className="regular" />
                    <Box ml={1}>
                      <Typography color="textSecondary" variant="caption">
                        Product Search should only be added if you are using Products in your sphere as search is linked
                        to product data.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              {controlButton?.elementType === NAVIGATION_BUTTON_TYPES.CONTROL_BUTTON && (
                <Grid item sm={12}>
                  <Box mt={6}>
                    <ControlButtonSettings
                      planogramVersion={planogramVersion}
                      handleServerError={handleServerError}
                      setSettings={handleButtonChanges}
                      tabSettings={activeTab}
                    />
                    <ControlButtonLinkBehaviour
                      handleChange={handleButtonChanges}
                      navigationType={controlButton.navigationType ?? ControlButtonNavigationType.EXTERNAL}
                      navigationValueSelect={controlButton.navigationValueSelect ?? undefined}
                      navigationValueUrl={controlButton.navigationValueUrl ?? undefined}
                      checkedOpenInNewPage={controlButton.openInNewPage ?? false}
                      withoutNavigation={controlButton.withoutNavigation ?? false}
                      planogramObjectData={planogramObjectData}
                    />
                  </Box>
                </Grid>
              )}
              {controlButton?.elementType === NAVIGATION_BUTTON_TYPES.MENU && (
                <Grid item sm={12}>
                  <Box mt={6}>
                    <ControlButtonSettings
                      planogramVersion={planogramVersion}
                      handleServerError={handleServerError}
                      setSettings={handleButtonChanges}
                      tabSettings={activeTab}
                    />
                    <MenuButtonSettings
                      planogramVersion={planogramVersion}
                      settings={controlButton}
                      setSettings={handleButtonChanges}
                      planogramObjectData={planogramObjectData}
                    />
                  </Box>
                </Grid>
              )}
              {controlButton?.elementType === NAVIGATION_BUTTON_TYPES.SEARCH && (
                <Grid item sm={12}>
                  <Box mt={9}>
                    <ControlButtonSettings
                      planogramVersion={planogramVersion}
                      handleServerError={handleServerError}
                      setSettings={handleButtonChanges}
                      tabSettings={activeTab}
                      title="Default state"
                    />
                    <SearchSettings
                      planogramVersion={planogramVersion}
                      handleServerError={handleServerError}
                      setSettings={handleButtonChanges}
                      tabSettings={activeTab}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
};

export default NavigationSettings;
