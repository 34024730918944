import { Box, Dialog as MuiDialog, withStyles, Button } from '@material-ui/core';

export const Dialog = withStyles({
  root: {
    '& .MuiDialog-paperWidthMd': {
      width: '732px',
    },

    '& .MuiDialogContent-root': {
      padding: '28px 28px 0',
    },

    '& .MuiDialogActions-root': {
      padding: '0 28px 28px',
    },
  },
})(MuiDialog);

export const DialogHeader = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: '52px',
    marginBottom: '48px',
  },
})(Box);

export const BackButton = withStyles(theme => ({
  root: {
    ...theme.typography.h5,
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
      background: 'inherit',
    },
  },
}))(Button);
