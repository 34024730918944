import { styled, withStyles, MenuItem as MuiMenuItem, Chip, Box, Tooltip as MuiTooltip } from '@material-ui/core';
import { TextValidator as ReactTextValidator } from 'react-material-ui-form-validator';

import { COLORS } from 'theme/constants';
import { ButtonComponent } from 'components';

export const HeaderText = styled('span')({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '1.5',
  letterSpacing: '0.12px',
  color: COLORS.cozmosGrey,
  marginLeft: '14px',
  paddingBottom: '8px',
  paddingRight: '12px',
});

export const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
  lineHeight: '1.85',
  color: COLORS.cozmosPurple4,
  '&.selected': {
    color: COLORS.white,
    background: COLORS.cozmosBlue,
  },
});

export const NoOptionsText = styled('span')({
  fontSize: '14px',
  lineHeight: '1.85',
  color: COLORS.cozmosGrey,
  margin: '16px',
});

export const ChipItem = styled(Chip)({
  borderRadius: '6px',
  maxWidth: '82.5%',
  textOverflow: 'ellipsis',
});

export const Button = styled(ButtonComponent)({
  minWidth: '80px',
});

export const StickyItem = styled(Box)({
  position: 'sticky',
  bottom: '0',
  backgroundColor: COLORS.white,
});

export const TextValidator = styled(ReactTextValidator)({
  '& .popup-label': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  '& .MuiSelect-root': {
    width: 'auto',
  },
  '& .MuiSelect-iconOutlined': {
    right: '4px',
  },
  '& .MuiSelect-outlined': {
    paddingRight: '40px',
  },
  '& .MuiInputBase-root .action-icon': {
    width: '6px',
    minWidth: '6px',
    height: '6px',
    outline: `2px solid ${COLORS.cozmosGrey}`,
    borderRadius: '50%',
    position: 'relative',
    right: '32px',
    cursor: 'pointer',
    zIndex: 1,
    '&.active': {
      outlineColor: COLORS.cozmosGreen,
      background: COLORS.cozmosGreen,
    },
  },
});

export const Tooltip = withStyles({
  tooltip: {
    padding: '10px 12px',
    fontSize: '14px',
    fontWeight: 500,
    maxWidth: '400px',
    backgroundColor: COLORS.white,
    color: COLORS.cozmosGreyText,
    filter: 'drop-shadow(0 0 7px #0000001F)',
  },
  arrow: {
    color: COLORS.white,
  }
})(MuiTooltip);
