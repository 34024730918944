import React from 'react';

import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardComponent } from 'components';
import { getTWithPathKey } from 'utils';

export function ErrorsLogComponent({ notificationMessages }) {
  const getT = getTWithPathKey('pages.ecommerceProvider.notifications');

  return (
    <CardComponent title={getT('header', { count: notificationMessages.length })} xs={9}>
      {notificationMessages.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{getT('title')}</TableCell>
              <TableCell>{getT('message')}</TableCell>
              <TableCell>{getT('date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationMessages.map(message => (
              <TableRow key={`${message.title}${message.body}${message.createdAt}`}>
                <TableCell>{message.title}</TableCell>
                <TableCell>{message.body}</TableCell>
                <TableCell>{message.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </CardComponent>
  );
}

ErrorsLogComponent.propTypes = {
  notificationMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
