import { Box as MuiBox, Backdrop as MuiBackdrop, withStyles } from '@material-ui/core';

import { drawerWidth } from './constants';

export const Box = withStyles(theme => ({
  root: {
    '&.container': {
      minHeight: '100vh',
      display: 'flex',
    },
    '&.dashboard': {
      [theme.breakpoints.up('md')]: {
        width: `${drawerWidth}px`,
        flexShrink: 0,
        zIndex: 101,
      },
    },
    '&.content-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '&.content': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
}))(MuiBox);

export const Backdrop = withStyles(() => ({
  root: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: '100%',
    top: '0',
    right: '0',
    left: 'unset',
    backgroundColor: 'rgba(238,238,238,.5)',
    backdropFilter: 'blur(3px)',
    zIndex: '100',
    'user-select': 'none',
  },
}))(MuiBackdrop);
