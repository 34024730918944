import { Box, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const AddImageContainer = withStyles({
  root: {
    border: `2px dashed ${COLORS.greyDarkBorder}`,
    borderRadius: '4px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.greyText,
    cursor: 'pointer',

    '& .MuiTypography-root': {
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: 1.2,
      textAlign: 'center',
    },
  },
})(Box);

export const UploadedImageContainer = withStyles({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    textAlign: 'center',

    '& .on-hover-delete': {
      display: 'none',
      position: 'absolute',
      top: '0',
      right: '0',
    },

    '&:hover': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(16px)',
        backgroundColor: 'rgba(252, 252, 252, 0.5)',
      },

      '& .on-hover-delete': {
        display: 'block',
      },
    },

    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
})(Box);
