import { styled, Typography as MuiTypography, Box as MuiBox, IconButton as MuiIconButton, } from '@material-ui/core';
import { Edit, ErrorOutline } from '@material-ui/icons';

import { COLORS } from 'theme/constants';

export const EditIcon = styled(Edit)({
  color: COLORS.greyIcon,
});

export const ErrorOutlineIcon = styled(ErrorOutline)({
  color: COLORS.red,
  fontSize: '20px',
  marginRight: '8px',
});

export const Typography = styled(MuiTypography)({
  margin: '18px 12px',
  lineHeight: '20px',
});

export const Box = styled(MuiBox)({
  display: 'flex',
  '& > div': { display: 'inline-block', minHeight: 'auto' },
});

export const GreenTypography = styled(MuiTypography)({
  color: COLORS.greenDeep,
});

export const IconButton = styled(MuiIconButton)({
  '&.menuButton': {
    marginRight: '9px'
  }
})
