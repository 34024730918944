import React, { useState } from 'react';

import { Box, DialogActions, DialogContent, Grid, Typography, Checkbox } from '@material-ui/core';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isArray } from 'lodash';

import { ImageChooseComponent, FontSettingsControlWrapper } from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { MultilanguageTextValidator } from 'components/FormComponents';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { getTWithPathKey, handleGetTextValue } from 'utils';
import { FontSettingsType, PaginationParams } from 'types';
import { AnimationTypes } from 'types/entranceAnimation';
import { ComponentWithPageLanguageProps } from 'utils/enhancers/withPageLanguage';
import { otherAssetsModel } from 'models';

import { Dialog, DialogTitle } from '../style';
import { ImageUploadCaption } from 'components/style';

interface DialogSettings extends FontSettingsType {
  device: 'desktop' | 'mobile';
}

export interface EntranceAnimationDialogProps {
  isOpen: boolean;
  title: string;
  settings: AnimationTypes;
  handleClose: () => void;
  handleSave: () => void;
  handleServerError: (err: any) => void;
  handleSettingsChange: (item: Record<string, any>) => void;
}

export const EntranceAnimationDialog = ({
  isOpen,
  title,
  settings,
  handleClose,
  handleSave,
  handleSettingsChange,
  handleServerError,
  pageLanguageTabValue
}: EntranceAnimationDialogProps & ComponentWithPageLanguageProps) => {
  const getT = getTWithPathKey('common.buttons');
  const [fontSettings, setFontSettings] = useState<DialogSettings | null>(null);

  const getImages = (params: PaginationParams) => otherAssetsModel.getImages(params);

  const closeFontSettingsDialog = () => setFontSettings(null);

  const handleFontSettingsSave = (fontSettings: DialogSettings) => {
    const data = {
      ...settings,
      [fontSettings.device]: {
        ...settings[fontSettings.device],
        ...fontSettings
      }
    }

    closeFontSettingsDialog();
    handleSettingsChange(data);
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onBackdropClick={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <ValidatorForm onSubmit={handleSave} id="entrance-animation">
        <Grid container spacing={4}>
          <Grid item sm={4} style={{ minWidth: '150px' }}>
            <ImageChooseComponent
              image={settings.otherAsset}
              setImage={val => handleSettingsChange({ otherAsset: val })}
              handleServerError={handleServerError}
              dialogTitle="Choose image"
              getPictures={getImages}
              withDelete
              className="max-height"
            />
          </Grid>
          <Grid item sm={8}>
            <ImageUploadCaption>{IMAGE_UPLOAD_CAPTION.entranceAnimationIcon}</ImageUploadCaption>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Box display="flex" alignItems="center" mt={6} mb={2}>
            <DesktopWindowsIcon fontSize="small" color="action" />
            <Box mx={1.5}>
              <Typography variant="h4">For desktop devices</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">(up to 40 symbols)</Typography>
          </Box>
          <Grid container>
            <FontSettingsControlWrapper
              fontSettings={{ ...settings.desktop, device: 'desktop' } as DialogSettings}
              handleSettingsEdit={setFontSettings}
              multiLangValue={settings.desktop.title}
            >
              <Grid item sm={10}>
                <MultilanguageTextValidator
                  label="Text"
                  value={settings.desktop.title}
                  fieldName="title"
                  handleChange={val => handleSettingsChange({ desktop: { ...settings.desktop, ...val } })}
                  pageLanguageTabValue={pageLanguageTabValue}
                  multilanguage={isArray(settings.desktop.title)}
                  handleGetValue={handleGetTextValue('title')}
                />
              </Grid>
            </FontSettingsControlWrapper>
          </Grid>
          <Box display="flex" alignItems="center" mb={2}>
            <SmartphoneIcon fontSize="small" color="action" />
            <Box mx={1.5}>
              <Typography variant="h4">For mobile devices</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">(up to 40 symbols)</Typography>
          </Box>
          <Grid container>
            <FontSettingsControlWrapper
              fontSettings={{ ...settings.mobile, device: 'mobile' } as DialogSettings}
              handleSettingsEdit={setFontSettings}
              multiLangValue={settings.mobile.title}
            >
              <Grid item sm={10}>
                <MultilanguageTextValidator
                  label="Text"
                  value={settings.mobile.title}
                  fieldName="title"
                  handleChange={val => handleSettingsChange({ mobile: { ...settings.mobile, ...val } })}
                  pageLanguageTabValue={pageLanguageTabValue}
                  multilanguage={isArray(settings.mobile.title)}
                  handleGetValue={handleGetTextValue('title')}
                />
              </Grid>
            </FontSettingsControlWrapper>
          </Grid>
          <Box pt={2}>
            <Typography variant="h4">Guidance animation display</Typography>
            <Box my={2}>
              <Typography color="textSecondary">The guidance animation is displayed once and will not be shown again in the same session.</Typography>
            </Box>
            <Box ml={-3}>
              <Typography variant="caption">
                <Checkbox
                  checked={settings.complete}
                  onChange={e => handleSettingsChange({ complete: e.target.checked })}
                />
                <span>Complete the animation on this step</span>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <DialogActions>
          <ButtonComponent onClick={handleClose} text={getT('cancel')} />
          <ButtonComponent type="submit" color="primary" text={getT('save')} />
        </DialogActions>
      </ValidatorForm>
      <FontSettingsEditDialog
        settings={fontSettings}
        handleClose={closeFontSettingsDialog}
        handleSet={handleFontSettingsSave}
      />
    </Dialog>
  );
};
