import React, { memo, useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CardComponent, SettingsForm } from 'components';
import { useAuthActions, useAuthState } from 'contexts/AuthContext';
import { useSubmittingState } from 'hooks';
import { tfaModel } from 'models';

import { Switch } from 'components/style';
import { Box, Subtext, Typography } from './style';

export function TFASwitchComponent({ disabledSwitch, handleServerError, adminId, setSuccessActionMessages }) {
  const {
    currentUser: { attributes },
  } = useAuthState();
  const { logOut } = useAuthActions();

  const [initialTfaSettings, setInitialTfaSettings] = useState({});
  const [tfaSettings, setTfaSettings] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    tfaModel
      .getSettings(adminId)
      .then(data => {
        setTfaSettings(data);
        setInitialTfaSettings(data);
      })
      .catch(handleServerError);
  }, []);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const onChange = ({ target: { checked } }) => setTfaSettings(prev => ({ ...prev, otpNeedEnable: checked }));

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      const { otpNeedEnable } = tfaSettings;

      if (otpNeedEnable) {
        const { data, success } = await tfaModel.putSettings({ id: adminId, twoFa: { otpNeedEnable } });

        setInitialTfaSettings(data);
        setTfaSettings(data);
        setSuccessActionMessages(success);
        if (attributes.id === data.id) {
          logOut();
        }
      } else {
        const data = await tfaModel.deleteSettings(adminId);

        setInitialTfaSettings(data);
        setTfaSettings(data);
        setSuccessActionMessages(data.success);
      }
    } catch (err) {
      handleServerError(err);
    }
  });

  const getIsSaveButtonVisible = () => !isSubmitting && !isEqual(initialTfaSettings, tfaSettings);

  const renderNav = () => <Switch checked={tfaSettings.otpNeedEnable} onChange={onChange} disabled={disabledSwitch} />;

  const renderTitle = () => (
    <Box>
      <Typography variant="h2">Two-Factor authentication</Typography>
    </Box>
  );

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent xs={9} title={renderTitle()} CardHeaderNav={renderNav} isLoading={isSubmitting}>
        <Subtext variant="body1">{t('pages.admins.settings.TFASwitch.subtext')}</Subtext>
      </CardComponent>
    </SettingsForm>
  );
}

TFASwitchComponent.propTypes = {
  adminId: PropTypes.number.isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
  disabledSwitch: PropTypes.bool,
};

TFASwitchComponent.defaultProps = {
  disabledSwitch: false,
};

export const TFASwitch = memo(TFASwitchComponent);
