import React, { useEffect, useRef, useState } from 'react';

import { Box, List, ListItem } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/ChevronRight';

import { PlanogramVersionControlButtonMenuItem } from 'types';

import { Popper } from 'components/style';

interface OptionItemProps {
  option: { label: string | null | undefined; };
  setProperty: (property: keyof PlanogramVersionControlButtonMenuItem, value: boolean, index: number) => void;
  setIsOpen: (val: boolean) => void;
  index: number;
}

const OptionItem = ({
  option,
  setProperty,
  setIsOpen,
  index
}: OptionItemProps) => {
  const elemRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (elemRef.current) {
      setAnchorEl(elemRef.current);
    }
  }, []);

  const handleClick = (e: React.MouseEvent, val: boolean) => {
    e.stopPropagation();
    setProperty(
      'openInNewPage',
      val,
      index,
    );
    setIsOpen(false);
  };

  return (
    <>
      {option.label === 'External' ? (
        <>
          <Box display="flex">
            <span ref={elemRef}>{option.label}</span>
            <KeyboardArrowRightIcon color="disabled" fontSize="small" />
          </Box>
          {anchorEl && (
            <Popper className="MuiAutocomplete-popper" open anchorEl={anchorEl} placement="right-start">
              <Box className="MuiPaper-root MuiAutocomplete-paper MuiPaper-elevation1 MuiPaper-rounded">
                <List className="MuiAutocomplete-listbox">
                  <ListItem
                    className="MuiAutocomplete-option"
                    onClick={e => handleClick(e, false)}
                  >Same Tab</ListItem>
                  <ListItem
                    className="MuiAutocomplete-option"
                    onClick={e => handleClick(e, true)}
                  >New Tab</ListItem>
                </List>
              </Box>
            </Popper>
          )}
        </>
      ) : (
        <span ref={elemRef}>{option.label}</span>
      )}
    </>
  );
};

export default OptionItem;
