import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

import { AuthMessage } from '../AuthMessage/AuthMessage';

export const ProfileSetupErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onBackToSetup = () => {
    history.replace({
      pathname: ROUTES.inviteSelector,
      search: window.location.search,
    });
  };

  return (
    <AuthMessage
      tabTitle={t('pages.auth.profileSetupError.tabTitle')}
      title={t('pages.auth.profileSetupError.title')}
      text={t('pages.auth.profileSetupError.body')}
      buttonText={t('pages.auth.profileSetupError.button')}
      onClick={onBackToSetup}
      disclaimer={t('pages.auth.profileSetupError.footer')}
    />
  );
};
