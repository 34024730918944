import { styled, Box as MuiBox, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)({
  '&#entrance-guide': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '&.guide-indicator': {
    display: 'inline-flex',
    height: '46px',
    padding: '15px 46px',
    borderRadius: '46px',
    boxSizing: 'content-box',
    '&.hidden': {
      display: 'none'
    },
  },
  '&.indicator-container': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '&.drag-indicator .indicator-text, &.zoom-indicator .indicator-text': {
    margin: '0 16px 0 24px',
    '&.without-icon': {
      margin: '0 24px',
    }
  },
  '& .indicator-text': {
    textTransform: 'uppercase',
  },
  '& .indicator-icon': {
    height: '100%',
    width: 'auto',
  },
})

export const Description = styled(Typography)({
  color: COLORS.cozmosPurple2,
});
