import React, { useState } from 'react';

import { Box, DialogContentText, Typography } from '@material-ui/core';

import { LinkComponent, GuideList } from 'components';
import { GuideDialog } from 'components/Dialogs';
import { getTWithPathKey } from 'utils';

import { UppercaseTypography } from './style';

import AppPermissionsStep1 from 'assets/img/shopify-guide/app-permissions/step-1.webp';
import AppPermissionsStep10 from 'assets/img/shopify-guide/app-permissions/step-10.webp';
import AppPermissionsStep2 from 'assets/img/shopify-guide/app-permissions/step-2.webp';
import AppPermissionsStep3 from 'assets/img/shopify-guide/app-permissions/step-3.webp';
import AppPermissionsStep4 from 'assets/img/shopify-guide/app-permissions/step-4.webp';
import AppPermissionsStep5 from 'assets/img/shopify-guide/app-permissions/step-5.webp';
import AppPermissionsStep9 from 'assets/img/shopify-guide/app-permissions/step-9.webp';
import StoreIntegrationStep1 from 'assets/img/shopify-guide/store-integration/step-1.webp';
import StoreIntegrationStep2 from 'assets/img/shopify-guide/store-integration/step-2.webp';
import StoreIntegrationStep3 from 'assets/img/shopify-guide/store-integration/step-3.webp';
import StoreIntegrationStep4 from 'assets/img/shopify-guide/store-integration/step-4.webp';
import StoreIntegrationStep5 from 'assets/img/shopify-guide/store-integration/step-5.webp';
import StoreIntegrationStep6 from 'assets/img/shopify-guide/store-integration/step-6.webp';

enum DIALOGS {
  STORE_INTEGRATION = 'storeIntegration',
  APP_PERMISSIONS = 'appPermissions',
}

export interface EcommerceGuideProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const EcommerceGuide = ({ isOpen, handleClose }: EcommerceGuideProps) => {
  const [activeDialog, setActiveDialog] = useState(DIALOGS.STORE_INTEGRATION);
  const [disableTransition, setDisableTransition] = useState(false);

  const getT = getTWithPathKey('pages.ecommerceProvider.ecommerceGuide');

  const isDialogOpen = (name: DIALOGS): boolean => isOpen && activeDialog === name;
  const switchDialog = (name: DIALOGS) => {
    !disableTransition && setDisableTransition(true);
    setActiveDialog(name);
  };

  const handleCloseDialog = () => {
    setDisableTransition(false);
    handleClose();
    setActiveDialog(DIALOGS.STORE_INTEGRATION);
  };

  const storeIntegrationGuide = [
    {
      title: getT('storeIntegration.guide.1.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.1.text-1')}</Typography>
          <img style={{ width: '100%' }} src={StoreIntegrationStep1} alt={getT('storeIntegration.guide.1.img-alt')} />
          <Typography>{getT('storeIntegration.guide.1.text-2')}</Typography>
          <Typography>
            {getT('storeIntegration.guide.1.text-3-start')}
            <LinkComponent className="underline" onClick={() => switchDialog(DIALOGS.APP_PERMISSIONS)}>
              {getT('storeIntegration.guide.1.text-3-link')}
            </LinkComponent>
            {getT('storeIntegration.guide.1.text-3-end')}
          </Typography>
          <Typography>{getT('storeIntegration.guide.1.text-4')}</Typography>
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.2.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.2.text')}</Typography>
          <img style={{ width: '522px' }} src={StoreIntegrationStep2} alt={getT('storeIntegration.guide.2.img-alt')} />
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.3.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.3.text')}</Typography>
          <img style={{ width: '100%' }} src={StoreIntegrationStep3} alt={getT('storeIntegration.guide.3.img-alt')} />
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.4.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.4.text')}</Typography>
          <img style={{ width: '100%' }} src={StoreIntegrationStep4} alt={getT('storeIntegration.guide.4.img-alt')} />
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.5.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.4.text')}</Typography>
          <img style={{ width: '100%' }} src={StoreIntegrationStep5} alt={getT('storeIntegration.guide.5.img-alt')} />
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.6.title'),
      children: (
        <>
          <Typography>{getT('storeIntegration.guide.6.text')}</Typography>
          <img style={{ width: '100%' }} src={StoreIntegrationStep6} alt={getT('storeIntegration.guide.6.img-alt')} />
        </>
      ),
    },
    {
      title: getT('storeIntegration.guide.7.title'),
    },
  ];

  const adminApiGuide = [
    {
      title: getT('appPermissions.guide.1.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.1.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep1} alt={getT('appPermissions.guide.1.img-alt')} />
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.2.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.2.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep2} alt={getT('appPermissions.guide.2.img-alt')} />
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.3.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.3.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep3} alt={getT('appPermissions.guide.3.img-alt')} />
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.4.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.4.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep4} alt={getT('appPermissions.guide.4.img-alt')} />
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.5.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.5.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep5} alt={getT('appPermissions.guide.5.img-alt')} />
        </>
      ),
    },
  ];

  const storefrontApiGuide = [
    {
      title: getT('appPermissions.guide.6.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.6.text')}</Typography>
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.7.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.7.text')}</Typography>
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.8.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.8.text')}</Typography>
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.9.title'),
      children: (
        <>
          <Typography>{getT('appPermissions.guide.9.text')}</Typography>
          <img style={{ width: '100%' }} src={AppPermissionsStep9} alt={getT('appPermissions.guide.9.img-alt')} />
        </>
      ),
    },
    {
      title: getT('appPermissions.guide.10.title'),
      children: (
        <img style={{ width: '100%' }} src={AppPermissionsStep10} alt={getT('appPermissions.guide.10.img-alt')} />
      ),
    },
  ];

  return (
    <>
      <GuideDialog
        isOpen={isDialogOpen(DIALOGS.STORE_INTEGRATION)}
        handleClose={handleCloseDialog}
        title={getT('storeIntegration.dialogTitle')}
        disableTransition={disableTransition}
      >
        <Box mb={2}>
          <UppercaseTypography variant="h2">{getT('storeIntegration.guideTitle')}</UppercaseTypography>
        </Box>
        <Box mb={5}>
          <DialogContentText>{getT('storeIntegration.guideDescription')}</DialogContentText>
        </Box>
        <GuideList list={storeIntegrationGuide} />
      </GuideDialog>
      <GuideDialog
        isOpen={isDialogOpen(DIALOGS.APP_PERMISSIONS)}
        handleClose={handleCloseDialog}
        handleBack={() => switchDialog(DIALOGS.STORE_INTEGRATION)}
        title={getT('appPermissions.dialogTitle')}
        backText={getT('appPermissions.backText')}
        disableTransition={disableTransition}
      >
        <Box mb={2}>
          <UppercaseTypography variant="h2">{getT('appPermissions.guideTitle')}</UppercaseTypography>
        </Box>
        <Box mb={5}>
          <DialogContentText>{getT('appPermissions.guideDescription')}</DialogContentText>
        </Box>
        <GuideList title={getT('appPermissions.pretext-1')} list={adminApiGuide} />
        <GuideList title={getT('appPermissions.pretext-2')} list={storefrontApiGuide} startCount={6} />
      </GuideDialog>
    </>
  );
};
