import { Box as MuiBox, styled } from '@material-ui/core';

import bitmap from 'assets/img/bitmap.png';
import donut from 'assets/img/donut.png';

export const PreviewWrapper = styled(MuiBox)({
  background: `center/144px url(${donut}) no-repeat, center/cover url(${bitmap}) no-repeat`,
  height: '164px',
  position: 'relative',
  width: '100%',
});

export const ButtonsWrapper = styled(MuiBox)({
  position: 'absolute',
  bottom: '16px',
  right: '16px',
  display: 'flex',
  alignItems: 'center',
});

export const ButtonContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  '& img': { height: '40px', maxWidth: '40px' },
  '&.info': {
    height: '64px',
    width: '64px',
  },
  '&.checkout': {
    height: '84px',
    width: '84px',
    marginLeft: '20px',
  },
});
