import React, { useState, useEffect } from 'react';

import { Grid, Button } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { set, pick, isEqual, cloneDeep, omit } from 'lodash';
import marked from 'marked';
import PropTypes from 'prop-types';

import { CardComponent, ImageUploadComponent, FontSettingsControlWrapper, SettingsForm } from 'components';
import { InfoOverlayPreviewDialog, FontSettingsEditDialog } from 'components/Dialogs';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { getBlobUrl, findIndexById, getFontSettingsWithPx, getFontSettingsWithNums } from 'utils';

import { Switch } from 'components/style';
import {
  InfoOverlaySectionCaption,
  InfoOverlaySectionTitle,
  TextComponentWrapper,
  DividerWrapper,
  Divider,
  TextFieldWrapper,
} from './style';

marked.setOptions({
  headerIds: false,
  sanitize: true,
});

const FORM_ID = 'infoOverlaysForm';

export function InfoOverlaysComponent({ planogramId, planogramVersion, handleServerError, setSuccessActionMessages }) {
  const [infos, setInfos] = useState([]);
  const [initialInfos, setInitialInfos] = useState([]);
  const [preview, setPreview] = useState(null);
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    if (!planogramId || !planogramVersion.id) return;

    handleUpdate();
  }, [planogramId, planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleUpdate = withPageProgressHandler(() =>
    planogramsModel.getPlanogramInfos(planogramId, planogramVersion.id).then(updateLists).catch(handleServerError)
  );

  const updateLists = infosList => {
    const infosListWithChangedFonts = setInfosListFontSettingsWithNums(infosList);

    setInitialInfos(cloneDeep(infosListWithChangedFonts));
    setInfos(infosListWithChangedFonts);
  };

  const setInfosListFontSettingsWithNums = infos =>
    infos.map(info => ({
      ...info,
      planogramInfoFonts: info.planogramInfoFonts.map(getFontSettingsWithNums),
    }));

  const handleInfoChange = index => value => {
    setInfos(prev => [...set(prev, [index], { ...prev[index], ...value })]);
  };

  const handleContentChange = index => value => {
    handleInfoChange(index)({ ...value, content: marked(value.contentMarkdown) });
  };

  const handleSwitchChange =
    index =>
    ({ target: { checked } }) => {
      handleInfoChange(index)({ enabled: checked });
    };

  const handleFontSettingsSave = settings => {
    setFontSettingsEdit(undefined);
    setInfos(prev => {
      const infoIndex = findIndexById(prev, settings.planogramInfoId);
      const fontSettingsIndex = findIndexById(prev[infoIndex].planogramInfoFonts, settings.id);

      return [...set(prev, [infoIndex, 'planogramInfoFonts', fontSettingsIndex], settings)];
    });
  };

  const getIsSaveButtonVisible = () => !isSubmitting && !isEqual(infos, initialInfos);

  const closeFontSettingsDialog = () => setFontSettingsEdit(undefined);

  const onSubmit = withHandlingSubmittingState(async ({ target: { id } }) => {
    if (id !== FORM_ID) return;

    try {
      await Promise.all(
        infos.map(info => {
          const initialInfo = initialInfos.find(i => i.id === info.id);

          if (isEqual(omit(initialInfo, 'fontSettings'), omit(info, 'fontSettings'))) {
            return Promise.resolve(true);
          }
          return planogramsModel
            .editPlanogramInfo(planogramId, planogramVersion.id, info.id, {
              planogramInfo: pick(info, ['enabled', 'title', 'content', 'contentMarkdown', 'signedId', 'removeImage']),
              planogramInfoFonts: info.planogramInfoFonts.map(font => getFontSettingsWithPx(font)),
            })
            .then(res => {
              updateLists(res.data.planogramInfos);
              setSuccessActionMessages(res.data.success);
            });
        }),
      );
    } catch (err) {
      handleServerError(err);
    }
  });

  const handleImageFileSet = index => blob => {
    const handler = handleInfoChange(index);

    if (blob) {
      handler({
        iconUrl: getBlobUrl(blob.key),
        signedId: blob.signed_id,
        removeImage: false,
      });
    } else {
      handler({ iconUrl: '', removeImage: true });
    }
  };

  const getInfoValidation = enabled => (enabled ? [VALIDATORS_MAP.required] : []);

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={onSubmit} id={FORM_ID}>
      <>
        <CardComponent title="INFO OVERLAYS" sm={8} alignItems="center" isLoading={isSubmitting}>
          <InfoOverlaySectionCaption>
            Info overlays are used for information such as; legal disclaimers, copyright and privacy policies. Set a
            title and description for each enabled overlay and preview your changes before saving and generating.
          </InfoOverlaySectionCaption>
          {infos.map((info, i) => (
            <Grid container key={info.id} spacing={6} alignItems="center" className="mt4">
              <Grid item sm={8}>
                <InfoOverlaySectionTitle>{info.slug}</InfoOverlaySectionTitle>
              </Grid>
              <Grid item sm={4} align="right">
                <Switch checked={info.enabled} onChange={handleSwitchChange(i)} />
              </Grid>
              {info.enabled && (
                <>
                  <Grid item sm={3}>
                    <ImageUploadComponent
                      imageUrl={info.iconUrl}
                      handleImageUpload={handleImageFileSet(i)}
                      handleServerError={handleServerError}
                      withDelete
                    />
                  </Grid>
                  <Grid container item sm={12}>
                    <FontSettingsControlWrapper
                      fontSettings={info.planogramInfoFonts.find(({ assignment }) => assignment === 'title')}
                      handleSettingsEdit={setFontSettingsEdit}
                    >
                      <TextFieldWrapper>
                        <TextComponentWrapper>
                          <TextValidatorComponent
                            validators={getInfoValidation(info.enabled)}
                            fieldName="title"
                            label="Title"
                            value={info.title}
                            handleChange={handleInfoChange(i)}
                          />
                        </TextComponentWrapper>
                      </TextFieldWrapper>
                    </FontSettingsControlWrapper>
                  </Grid>
                  <Grid container item sm={12}>
                    <FontSettingsControlWrapper
                      fontSettings={info.planogramInfoFonts.find(({ assignment }) => assignment === 'content')}
                      handleSettingsEdit={setFontSettingsEdit}
                    >
                      <TextFieldWrapper>
                        <TextComponentWrapper>
                          <TextValidatorComponent
                            validators={getInfoValidation(info.enabled)}
                            fieldName="contentMarkdown"
                            label="Content"
                            value={info.contentMarkdown || ''}
                            multiline
                            rows={12}
                            handleChange={handleContentChange(i)}
                          />
                        </TextComponentWrapper>
                      </TextFieldWrapper>
                    </FontSettingsControlWrapper>
                  </Grid>
                  <Grid item sm={12} align="right">
                    <Button
                      color="secondary"
                      size="small"
                      startIcon={<VisibilityOutlinedIcon />}
                      onClick={() => setPreview(info)}
                    >
                      Preview
                    </Button>
                  </Grid>
                </>
              )}
              {i < infos.length - 1 && (
                <DividerWrapper item sm={12}>
                  <Divider />
                </DividerWrapper>
              )}
            </Grid>
          ))}
        </CardComponent>
        <InfoOverlayPreviewDialog
          overlay={preview}
          planogramVersion={planogramVersion}
          handleClose={() => setPreview(null)}
        />
        <FontSettingsEditDialog
          settings={fontSettingsEdit}
          handleClose={closeFontSettingsDialog}
          handleSet={handleFontSettingsSave}
        />
      </>
    </SettingsForm>
  );
}

InfoOverlaysComponent.propTypes = {
  planogramId: PropTypes.string.isRequired,
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
