import { NAVIGATION_TYPE } from '../components/LinkBehavior/constants';
import { PlanogramVersionControlButton } from '../types';

export const getSettingsWithNavigationValues = (settings: Partial<PlanogramVersionControlButton>):
  Partial<PlanogramVersionControlButton> => {
  const settingsWithNav = { ...settings };

  settingsWithNav.navigationValueUrl =
    settings.navigationType === NAVIGATION_TYPE.external ? settings.navigationValue : null;
  settingsWithNav.navigationValueSelect =
    settings.navigationType === NAVIGATION_TYPE.internal ? settings.navigationValue : null;

  return settingsWithNav;
};
