import { Box as MuiBox, styled, withStyles } from '@material-ui/core';

import { ICON_SIZE } from 'theme/constants';

import bitmap from 'assets/img/bitmap.png';

export const Box = withStyles({
  root: {
    '&.wrapper': {
      background: `center/cover url(${bitmap}) no-repeat`,
      padding: '40px 0 40px',
    },
    '&.image-wrapper': {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
    },
  },
})(MuiBox);

export const ImageBox = withStyles({
  root: { height: ICON_SIZE.large, width: ICON_SIZE.large, marginBottom: '30px' },
})(MuiBox);

export const Image = styled('img')({
  display: 'block',
  height: '100%',
  width: '100%',
  objectPosition: 'center',
  objectFit: 'contain',
});
