import React from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ButtonComponent } from 'components';
import { authModel } from 'models';
import { ROUTES } from 'routes/constants';

import { AuthCard, SmallInfoText, StyledButtonComponent } from '../style';

import { ReactComponent as GoogleIcon } from 'assets/img/google.svg';

export const AccountNotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSignUpWithGoogle = () => {
    authModel.redirectToGoogleAuth(authModel.googleFlow.signUp);
  };

  const onBackToSignIn = () => history.push(ROUTES.authSignIn);

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signInPage.accountNotFound.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signInPage.accountNotFound.title')}</Typography>
        </Grid>
        <Grid item>
          <SmallInfoText>{t('pages.auth.signInPage.accountNotFound.subtext')}</SmallInfoText>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <ButtonComponent
            variant="text"
            onClick={onBackToSignIn}
            className="no-padding"
            text={t('pages.auth.common.toSignInBtn')}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <StyledButtonComponent
            fullWidth
            color="primary"
            text={t('pages.auth.signUpPage.googleSignUpBtn')}
            variant="outlined"
            Icon={GoogleIcon}
            onClick={onSignUpWithGoogle}
          />
        </Grid>
      </Grid>
    </AuthCard>
  );
};
