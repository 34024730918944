import { Box as MuiBox, Typography as MuiTypography, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)({
  '&.shopify': {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '6px',
    '&.dark': {
      borderColor: COLORS.greyBorder,
    },
    '&.light': {
      borderColor: COLORS.greyBorder,
    },
  },
  '&.dark': {
    backgroundColor: COLORS.darkPreview,
    '& .MuiTypography-root': { color: COLORS.greyDefault },
    '& .text-container': { color: COLORS.white },
  },
  '&.light': {
    backgroundColor: COLORS.bgLightPreview,
    '& .MuiTypography-root': { color: COLORS.greyBtnText },
  },
  '& .text': { color: COLORS.greyText },
  padding: '12px',
});

export const Typography = styled(MuiTypography)({
  alignSelf: 'start',
});

export const TextContainer = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
});
