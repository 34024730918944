import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

import { MobileContainer, MobileInfoText, StyledButtonComponent } from '../style';

export const SignInPageMobile = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onShareLink = () => {
    if (navigator.share) {
      navigator.share({
        url: '',
      });
    }
  };

  const onSignUp = () => history.push(ROUTES.authSignUp);

  return (
    <MobileContainer>
      <Helmet title={t('pages.auth.signInPage.tabTitle')} />
      <Grid container direction="column" spacing={6} justify="center">
        <Grid item>
          <Typography variant="h2" align="center">
            {t('pages.auth.signInPage.mobileTitle')}
          </Typography>
        </Grid>
        <Grid item>
          <MobileInfoText>
            {t('pages.auth.signInPage.mobileInfoParagraph1')}
            <br />
            <br />
            {t('pages.auth.signInPage.mobileInfoParagraph2')}
          </MobileInfoText>
        </Grid>
        <Grid item>
          <StyledButtonComponent
            fullWidth
            color="primary"
            text={t('pages.auth.signInPage.continueBtn')}
            onClick={onSignUp}
          />
        </Grid>
        <Grid item>
          <StyledButtonComponent
            fullWidth
            color="primary"
            variant="outlined"
            text={t('pages.auth.signInPage.shareBtn')}
            onClick={onShareLink}
          />
        </Grid>
      </Grid>
    </MobileContainer>
  );
};
