import React, { useState, useEffect, useRef } from 'react';

import { Grid, Typography, Box, GridSize } from '@material-ui/core';
import { isArray, isEqual, cloneDeep, reduce } from 'lodash';

import {
  CardComponent,
  SettingsForm,
  ButtonComponent,
  IconWithConfirmation
} from 'components';
import { MultilanguageTextValidator } from 'components/FormComponents';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useAuthState } from 'contexts/AuthContext';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { useSubmittingState } from 'hooks';
import { cookiesDataModel } from 'models';
import { CookiesDataProps as CookiesDataPropsInterface } from 'types/overlays';
import { getTWithPathKey, handleGetTextValue } from 'utils';

import { RowField } from './styles';

import { Divider } from 'components/PageHeader/style';

const FORM_ID = 'cookiesData';
const DEFAULT_FIELDS = [
  {
    id: 'name',
    title: 'Name',
    spacing: 1,
    minWidth: '92px'
  },
  {
    id: 'cookieData',
    title: 'Data Collected',
    spacing: 1,
    minWidth: '154px'
  },
  {
    id: 'cookieType',
    title: 'Type',
    spacing: 1,
    minWidth: '92px'
  },
  {
    id: 'purpose',
    title: 'Cookie Purpose',
    spacing: 1,
    minWidth: '382px'
  },
  {
    id: 'expiry',
    title: 'Expiry',
    spacing: 1,
    minWidth: '74px'
  }
];

interface CookiesDataProps {
  pageLanguageTabValue?: number;
  cookiesSettings: Array<CookiesDataPropsInterface>;
  setCookiesSettings: (settings: any) => void;
  setSuccessActionMessages: (message: string) => void;
}

export function CookiesData({
  pageLanguageTabValue,
  cookiesSettings,
  setCookiesSettings,
  setSuccessActionMessages,
}: CookiesDataProps) {
  const [initialCookiesSettings, setInitialCookiesSettings] = useState<Array<CookiesDataPropsInterface>>([]);
  const refForm = useRef();
  const getT = getTWithPathKey('pages.settings.cookiesSettings.cookiesData');

  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    getCookiesData();
  }, []);

  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();

  const getCookiesData = withPageProgressHandler(() =>
    cookiesDataModel.getData().then(setNewSettings).catch(handleServerError),
  );

  const setNewSettings = (data: any) => {
    setInitialCookiesSettings(cloneDeep([...data]));
    setCookiesSettings([...data]);
  };

  const handleSubmit = withHandlingSubmittingState(async ({target: {id}}) => {
    try {
      if (id !== FORM_ID) {
        return;
      }

      const data = await cookiesDataModel.updateData(cookiesSettings);

      setNewSettings(data.cookies);
      setSuccessActionMessages(data.success);
    } catch (err) {
      handleServerError(err as ServerErrorType);
    }
  });

  const getIsSaveButtonVisible = () =>
    !isSubmitting && !isEqual(initialCookiesSettings, cookiesSettings);

  const handleChange = (index: number, fieldName: string) => (value: CookiesDataPropsInterface) =>
    setCookiesSettings((prevState: Array<CookiesDataPropsInterface>) =>
      prevState.map((item, i) =>
        i === index ? { ...item, [fieldName]: value[fieldName] }  : { ...item }
      ));

  const handleAdd = () => setCookiesSettings((prevState: Array<CookiesDataPropsInterface>) => ([
    ...prevState,
    reduce(
      DEFAULT_FIELDS,
      (acc, field) => ({ ...acc, [field.id]: clientLanguages.map(lang => ({
          languageCode: lang,
          value: ''
        })) }),
      {}
    ) as CookiesDataPropsInterface
  ]));

  const handleDelete = (index: number) => setCookiesSettings((prevState: Array<CookiesDataPropsInterface>) => (
    prevState.filter((item, i) => i !== index)
  ));

  return (
    <SettingsForm
      isSaveButtonVisible={getIsSaveButtonVisible()}
      handleSave={handleSubmit}
      id={FORM_ID}
      refForm={refForm}
    >
      <CardComponent sm={8} title={getT('title')} isLoading={isSubmitting}>
        <Grid container>
          <Grid item sm={12}>
            <Typography variant="subtitle1">{getT('subtitle')}</Typography>
          </Grid>
          <Grid container>
            {cookiesSettings.map((data, i) => (
            <React.Fragment key={`cookies-row-${i}`}>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid container alignItems="flex-end" key={`cookies-data-${i}`}>
                {DEFAULT_FIELDS.map((field, index) =>
                  <RowField item sm={field.spacing as GridSize} style={{ minWidth: field.minWidth }} key={field.id}>
                    <Box mt={3}>
                      <Typography variant="h4" className="uppercase">{field.title}</Typography>
                    </Box>
                    <Box mr={index === DEFAULT_FIELDS.length - 1 ? '' : 2.5}>
                      <MultilanguageTextValidator
                        InputLabelProps={{ shrink: false }}
                        value={data[field.id]}
                        fieldName={field.id}
                        handleChange={val => handleChange(i, field.id)(val)}
                        pageLanguageTabValue={pageLanguageTabValue}
                        multilanguage={isArray(data[field.id])}
                        handleGetValue={handleGetTextValue(field.id)}
                      />
                    </Box>
                  </RowField>
                )}
                <RowField item sm={1}>
                  <Box mb={-0.5}>
                    <IconWithConfirmation
                      value={i}
                      message="data"
                      handleConfirm={handleDelete}
                    />
                  </Box>
                </RowField>
              </Grid>
            </React.Fragment>
            ))}
          </Grid>
          <Box mt={6}>
            <ButtonComponent color="primary" className="filled" text={getT('buttonTitle')} onClick={handleAdd} />
          </Box>
        </Grid>
      </CardComponent>
    </SettingsForm>
  );
}
