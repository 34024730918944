import React, { useState } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { getTWithPathKey } from 'utils';

import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Dialogs/style';

export function EditImageIconWithDialog({ image, handleEdit, className }) {
  const getT = key => getTWithPathKey('components.dropzoneImage.editImageIconWithDialog')(key);
  const initialState = {
    openDialog: false,
    seoTitle: image.seoTitle || '',
    accessibilityDescription: image.accessibilityDescription || '',
  };
  const [state, setState] = useState(initialState);

  const handleToggleDialog = () => setState(prevState => ({ ...prevState, openDialog: !prevState.openDialog }));

  const handleCancel = () => setState(initialState);

  const handleSave = () => {
    handleEdit(image.id, {
      seoTitle: state.seoTitle,
      accessibilityDescription: state.accessibilityDescription,
    });
    handleToggleDialog();
  };

  const handleChange = item => setState(prevState => ({ ...prevState, ...item }));

  return (
    <>
      <IconButton className={className} onClick={handleToggleDialog}>
        <EditIcon />
      </IconButton>
      {state.openDialog && (
        <Dialog open={state.openDialog} onClose={handleToggleDialog} fullWidth>
          <DialogTitle className="pb-0">Edit image information</DialogTitle>
          <ValidatorForm onSubmit={handleSave}>
            <DialogContent>
              <Box mt={1}>
                <TextValidatorComponent
                  validators={[VALIDATORS_MAP.required]}
                  label={getT('seoTitleLabel')}
                  value={state.seoTitle}
                  fieldName="seoTitle"
                  handleChange={handleChange}
                  autoFocus
                />
              </Box>
              <Box mt={-6} mb={7}>
                <Typography variant="caption">{getT('seoTitleHint')}</Typography>
              </Box>
              <TextValidatorComponent
                label={getT('accessibilityDescriptionLabel')}
                value={state.accessibilityDescription}
                fieldName="accessibilityDescription"
                handleChange={handleChange}
                rows={5}
                multiline
              />
              <Box mt={-0.5} mb={7}>
                <Typography variant="caption">{getT('accessibilityHint')}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <ButtonComponent onClick={handleCancel} text="Cancel" />
              <ButtonComponent onClick={handleSave} type="submit" color="primary" text="Save" />
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      )}
    </>
  );
}

EditImageIconWithDialog.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seoTitle: PropTypes.string,
    accessibilityDescription: PropTypes.string,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

EditImageIconWithDialog.defaultProps = { className: '' };
