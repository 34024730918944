import React, { memo } from 'react';

import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { PICTURE_TYPES_FILTER } from 'pages/products/ManageProduct/constants';
import { getImageThumbUrl, getTWithPathKey } from 'utils';

import VariantImageUpload from './VariantImageUpload/VariantImageUpload';

import { Paper, TableContainer, ImageContainer } from './style';

function ProductVariantsTableComponent({ variants, columns, productPictures, uploadVariantImage, removeVariantImage }) {
  const getT = getTWithPathKey('pages.products.manageProduct.productPictures.productVariantsTable');

  const columnsArray = Object.entries(columns).map(([key, label]) => ({ key, label }));

  const getImages = () =>
    Promise.resolve({
      otherAssets: productPictures,
      assetsCount: productPictures.length,
    });

  const isPictureSynced = picture => picture && PICTURE_TYPES_FILTER.synced(picture);

  const renderColumn = (column, variant) => {
    const removeImage = image => removeVariantImage(variant.id, image);
    const setImage = image => uploadVariantImage(variant.id, image);

    switch (column.key) {
      case 'picture':
        return (
          <TableCell key={column.key}>
            <ImageContainer>
              {isPictureSynced(variant.picture) ? (
                <img style={{ maxHeight: '120px' }} src={getImageThumbUrl(variant[column.key])} alt={column.title} />
              ) : (
                <VariantImageUpload
                  image={variant.picture}
                  removeImage={removeImage}
                  getImages={getImages}
                  setImage={setImage}
                />
              )}
            </ImageContainer>
          </TableCell>
        );

      case 'price':
        return (
          <TableCell key={column.key}>
            {!variant.pricesInClientCurrencies && <Typography key={variant.price}>{variant.price}</Typography>}
            {variant.pricesInClientCurrencies?.map(({ price }) => (
              <Typography key={price.currencyCode}>{`${price.currencyCode} ${price.amount}`}</Typography>
            ))}
          </TableCell>
        );
      default:
        return <TableCell key={column.key}>{variant[column.key]?.toString()}</TableCell>;
    }
  };

  return (
    <Paper>
      <Box mb={6}>
        <Typography variant="h2">{getT('title')}</Typography>
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columnsArray.map(col => (
                <TableCell key={col.key}>{col.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {variants.map(variant => (
              <TableRow hover key={variant.id}>
                {columnsArray.map(col => renderColumn(col, variant))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

ProductVariantsTableComponent.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.shape({}).isRequired,
  productPictures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadVariantImage: PropTypes.func.isRequired,
  removeVariantImage: PropTypes.func.isRequired,
};

export const ProductVariantsTable = memo(ProductVariantsTableComponent);
