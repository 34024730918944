import { styled, Card as MuiCard, Typography as MuiTypography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { COLORS } from 'theme/constants';

export const Card = styled(MuiCard)({
  padding: '24px 29px',
});

export const CardText = styled(MuiTypography)({
  color: COLORS.greyText,
  fontSize: '14px',
  opacity: 0.8,
});

export const ArrowForwardIcon = styled(ArrowForwardIos)({
  fontSize: 12,
});
