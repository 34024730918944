import React, { useState, useEffect, useMemo } from 'react';

import { Grid, Box } from '@material-ui/core';
import { isEqual, get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import {
  CardComponent,
  PageHeader,
  LinkComponent,
  SettingsForm,
  ColoredBox,
} from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useAuthState } from 'contexts/AuthContext';
import { UPLOAD_STATUSES } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { ssoProvidersModel } from 'models';
import { StatusComponent } from 'pages/planograms/StatusComponent/StatusComponent';
import { ROUTES } from 'routes/constants';
import { SsoProviderType, SsoProviderForm, DataServerErrors } from 'types';
import { getErrorValue, getTWithPathKey } from 'utils';

import { OktaGuide, SsoGuideProps } from '../OktaGuide';

import { Switch } from 'components/style';
import { CopyToClipboard, Typography } from './style';

const COPY_DATA: (keyof SsoProviderType)[] = ['clientAdminGroup', 'clientModeratorGroup', 'callbackUrl', 'issuer'];

const GUIDES_MAP: Record<string, (props: SsoGuideProps) => JSX.Element> = {
  okta: OktaGuide,
};

const SsoProvider = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const [ssoProvider, setSsoProvider] = useState<SsoProviderType | null>(null);
  const [initialSsoProviderForm, setInitialSsoProviderForm] = useState<SsoProviderForm | null>(null);
  const [ssoProviderForm, setSsoProviderForm] = useState<SsoProviderForm | null>(null);
  const [isSyncLoading, setSyncLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const { withPageProgressHandler } = useBusyProgressActions();

  const {
    currentUser: {
      attributes: { isOktaUser },
    },
  } = useAuthState();

  const getT = getTWithPathKey('pages.ssoProvider');

  useEffect(() => {
    getProvider();
  }, [id]);

  const getProvider = withPageProgressHandler(() =>
    ssoProvidersModel
      .getProvider(id)
      .then(data => {
        if (data.ssoProvider.isEnabled) {
          setVerificationStatus('statusConnect');
        }

        setSsoProvider(data);
        setInitialSsoProviderForm({
          isEnabled: data.ssoProvider.isEnabled,
          idpMetadataUrl: data.ssoProvider.idpMetadataUrl,
        });
        setSsoProviderForm({
          isEnabled: data.ssoProvider.isEnabled,
          idpMetadataUrl: data.ssoProvider.idpMetadataUrl,
        });
      })
      .catch((e: DataServerErrors<string[]>) => {
        const errors = getErrorValue(e);

        setServerErrors(get(errors, 'base', []));
      })
  );

  const handleEnable = () => {
    setSsoProviderForm(prevState => {
      if (!prevState) {
        return prevState;
      }

      return {
        ...prevState,
        isEnabled: !prevState.isEnabled,
      };
    });
  };

  const handleChangeUrl = (item: Partial<SsoProviderType>) =>
    setSsoProviderForm(prevState => {
      if (!prevState) {
        return prevState;
      }

      return {
        ...prevState,
        ...item,
      };
    });

  const handleSave = async () => {
    try {
      setSyncLoading(true);

      const data = await ssoProvidersModel.updateProvider(id, ssoProviderForm!);

      setSsoProvider(data);
      setInitialSsoProviderForm({
        isEnabled: data.ssoProvider.isEnabled,
        idpMetadataUrl: data.ssoProvider.idpMetadataUrl,
      });
      setSsoProviderForm({
        isEnabled: data.ssoProvider.isEnabled,
        idpMetadataUrl: data.ssoProvider.idpMetadataUrl,
      });

      setVerificationStatus(data.ssoProvider.isEnabled ? 'statusConnect' : '');
      setServerErrors([]);
    } catch (e) {
      setVerificationStatus('statusError');

      const errors = getErrorValue(e as DataServerErrors<string[]>);

      setServerErrors(get(errors, 'base', []));
    } finally {
      setSyncLoading(false);
    }
  };

  const getIsSaveButtonVisible = () => !isEqual(ssoProviderForm, initialSsoProviderForm);

  const toggleGuide = () => setIsGuideOpen(prev => !prev);

  const GuideDialog = useMemo(() => GUIDES_MAP[id], [id]);

  const renderProviderSwitch = () => (
    <Switch disabled={isOktaUser} checked={ssoProviderForm?.isEnabled} onChange={handleEnable} />
  );

  const statusStyle = verificationStatus === 'statusConnect' ? UPLOAD_STATUSES.SUCCESS : UPLOAD_STATUSES.ERROR;

  return (
    <>
      <PageHeader title={getT(`${id}.title`)} backLink={ROUTES.ssoProviders} />
      <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
        <CardComponent
          isLoading={isSyncLoading}
          title={getT('cardTitle', { provider: id })}
          CardHeaderNav={renderProviderSwitch}
          xs={9}
        >
          {ssoProvider && ssoProviderForm?.isEnabled && (
            <Grid container>
              <Grid item sm={12}>
                <Box mt={5} mb={4}>
                  <Typography className="information">
                    {getT(`${id}.captionStart`)}
                    <LinkComponent className="blueUnderline" href="https://www.okta.com">
                      {getT(`${id}.link`)}
                    </LinkComponent>
                    {getT(`${id}.captionMiddle`)}
                    <LinkComponent className="underline" onClick={toggleGuide}>
                      {getT(`${id}.followGuide`)}
                    </LinkComponent>
                    {getT(`${id}.captionEnd`)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <TextValidatorComponent
                  validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.https(getT('idpMetadataUrl'))]}
                  label={getT('idpMetadataUrl')}
                  value={ssoProviderForm?.idpMetadataUrl}
                  fieldName="idpMetadataUrl"
                  handleChange={handleChangeUrl}
                  disabled={isOktaUser}
                />
              </Grid>
              <Grid item sm={4}>
                <Box display="flex" alignItems="center" ml={5} mt={3.5}>
                  {verificationStatus && (
                    <StatusComponent statusText={getT(`${id}.${verificationStatus}`)} status={statusStyle} />
                  )}
                </Box>
              </Grid>
              {!!serverErrors.length && (
                <Grid item sm={8}>
                  <Box mb={6}>
                    <ColoredBox variant="error">
                      <Box my={3} mx={2}>
                        {serverErrors.map(error => (
                          <Typography key={error}>{error}</Typography>
                        ))}
                      </Box>
                    </ColoredBox>
                  </Box>
                </Grid>
              )}
              <Grid item sm={12}>
                <Box mt={3} mb={4}>
                  <Typography className="settingsText">{getT(`${id}.settings`)}</Typography>
                </Box>
                <Box mb={2}>
                  <Typography className="information">{getT(`${id}.settingsCaption`)}</Typography>
                </Box>
                {COPY_DATA.map(key => (
                  <Grid key={key} item sm={12}>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                      <Box display="flex" alignItems="center">
                        <Typography className="lineTitle">{`${getT(`${id}.${key}`)}:`}</Typography>
                        <Typography className="lineValue">{ssoProvider[key]}</Typography>
                      </Box>
                      {!isOktaUser && (
                        <CopyToClipboard
                          text={ssoProvider[key] as string}
                          size="medium"
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
                <Box mt={4}>
                  <Typography className="information">{getT(`${id}.additionalInfo`)}</Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </CardComponent>
      </SettingsForm>
      {isGuideOpen && <GuideDialog isOpen={isGuideOpen} handleClose={toggleGuide} />}
    </>
  );
};

export default SsoProvider;
