import { Button as MuiButton, styled, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Button = withStyles(theme => ({
  root: {
    ...theme.typography.h5,
    minWidth: '100px',
    padding: '6px 12px',
    '&.ml': { marginLeft: '10px' },
    '&.ml-4': { marginLeft: '16px' },
    '&.right': { marginLeft: 'auto' },
    '&.withIcon': { padding: '4px 24px' },
    '&.flex-start': { justifyContent: 'flex-start' },
    '&.blue': { color: COLORS.cozmosBlue },
    '&.grey': { color: COLORS.greyBtnText },
    '&.filled': {
      fontSize: '12px',
      lineHeight: 1.2,
      padding: '13px 16px',
      borderRadius: '46px',
      fontWeight: 'bold',
      minWidth: 'auto'
    },
    '&.no-padding': { padding: '0', minWidth: 'auto' },
    '&.theme-reset': {
      minWidth: 'auto',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    '&.shopify-description': {
      order: 1,
      marginLeft: 'auto',
    },
  },
}))(MuiButton);
