import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';

import { ErrorOutlineIcon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '../style';

export function ErrorMessage({ messagesArr, handleClose }) {
  if (!isEmpty(messagesArr)) {
    return (
      <Dialog open onClose={handleClose} fullWidth>
        <DialogTitle id="alert-dialog-title">
          <ErrorOutlineIcon />
          <span>Error message</span>
        </DialogTitle>
        <DialogContent>
          {messagesArr.map(message => (
            <DialogContentText id="alert-dialog-description" key={message}>
              {message}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <ButtonComponent text="Close" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    );
  }
  return null;
}

ErrorMessage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  messagesArr: PropTypes.arrayOf(PropTypes.string).isRequired,
};
