import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CardComponent, SettingsProviderButton, PageHeader } from 'components';
import { ROUTES } from 'routes/constants';
import { ReactComponent as AbTestingIcon } from 'assets/icons/settings/social-media.svg';

import { ReactComponent as GoogleTagManagerIcon } from 'assets/img/google-tag-manager.svg';
import { ReactComponent as MatomoIcon } from 'assets/img/matomo-logo.svg';

const AnalyticsProviders = () => {
  const { t } = useTranslation();

  const analyticsOptions = [
    {
      title: t('pages.settings.analyticsProviders.abTesting.pageTitle'),
      path: ROUTES.AbTesting,
      icon: AbTestingIcon,
    },
    {
      title: t('pages.settings.analyticsProviders.gtm.pageTitle'),
      path: ROUTES.googleTagManager,
      icon: GoogleTagManagerIcon,
    },
    {
      title: t('pages.settings.analyticsProviders.matomo.pageTitle'),
      path: ROUTES.matomoAnalytics,
      icon: MatomoIcon,
    }
  ];

  return (
    <>
      <PageHeader title={t('pages.settings.analyticsProviders.title')} backLink={ROUTES.settings} />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Box display="flex">
            {analyticsOptions.map(({ title, path, icon }) => (
              <SettingsProviderButton key={title} Icon={icon} title={title} to={path} />
            ))}
          </Box>
        </CardComponent>
      </Grid>
    </>
  );
};

export default AnalyticsProviders;
