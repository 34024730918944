import React from 'react';

import { Grid, Typography, Box, Divider, Checkbox } from '@material-ui/core';

import { useAuthState } from 'contexts/AuthContext';
import { getTWithPathKey } from 'utils';

export const LocalizedContentData = () => {
  const {
    currentUser: {
      attributes: { clientLanguagesFull },
    },
  } = useAuthState();

  const getT = getTWithPathKey('pages.ecommerceProvider.localized');

  return (
    <>
      <Grid item sm={12}>
        <Box mt={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box mt={8}>
          <Typography variant="h2" className="uppercase">
            {getT('title')}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box mt={6}>
          <Typography className="information">{getT('caption')}</Typography>
        </Box>
      </Grid>
      <Grid item sm={7}>
        <Box mt={6} mb={6}>
          {clientLanguagesFull.map(lanTitle => (
            <Box key={lanTitle}>
              <Checkbox disabled checked />
              <Typography variant="caption">{lanTitle}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
};
