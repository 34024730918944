import React, { useState, memo } from 'react';

import { pick } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, SettingsForm } from 'components';
import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useSubmittingState } from 'hooks';
import { passwordModel } from 'models';

const changePasswordDefaultState = {
  password: '',
  passwordConfirmation: '',
};

function ChangePasswordComponent({ handleServerError, setSuccessActionMessages }) {
  const [state, setState] = useState({ ...changePasswordDefaultState });
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleSave = withHandlingSubmittingState(() =>
    passwordModel
      .updateCurrentUserPassword(pick(state, ['password', 'passwordConfirmation']))
      .then(({ success }) => {
        setState({ ...changePasswordDefaultState });
        setSuccessActionMessages(success);
      })
      .catch(handleServerError)
  );

  const handleChange = item =>
    setState(prevState => ({
      ...prevState,
      ...item,
    }));

  const getIsSaveButtonVisible = () => !isSubmitting && Boolean(state.password && state.passwordConfirmation);

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent title="CHANGE PASSWORD" isLoading={isSubmitting} xs={9}>
        <TextValidatorComponent
          validators={[VALIDATORS_MAP.required]}
          label="New password"
          value={state.password}
          fieldName="password"
          handleChange={handleChange}
          type="password"
        />
        <TextValidatorComponent
          validators={[VALIDATORS_MAP.required]}
          label="Password confirmation"
          value={state.passwordConfirmation}
          fieldName="passwordConfirmation"
          handleChange={handleChange}
          type="password"
        />
      </CardComponent>
    </SettingsForm>
  );
}

ChangePasswordComponent.propTypes = {
  handleServerError: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};

export const ChangePassword = memo(ChangePasswordComponent);
