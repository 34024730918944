import { DashboardInfo } from 'types/overlays';

import { api } from './setupApi';

class DashboardModel {
  link = '/dashboards';

  getDashboardInfo = () => api.get<DashboardInfo>(this.link).then(({ data }) => data);
}

export const dashboardModel = new DashboardModel();
