import React, { useState, useEffect, ChangeEvent } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { isEqual, findIndex, cloneDeep, set, find, some } from 'lodash';
import { ColorResult } from 'react-color';

import {
  ButtonPreviewWrapper,
  CardComponent,
  ColorPicker,
  FontSettingsControlWrapper,
  ImageUploadComponent,
  SettingsForm,
} from 'components';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { TextValidatorComponent } from 'components/FormComponents';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { useAuthState } from 'contexts/AuthContext';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { COLORS } from 'theme/constants';
import {
  ActiveStorageBlob,
  SharingButton,
  SharingButtonFont,
  SharingButtonIcon,
  SharingButtonProvider,
  ValueOf,
  Props,
} from 'types';
import { getFontSettingsWithNums, getFontSettingsWithPx, getBlobUrl, getServerError, getTWithPathKey } from 'utils';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';

import { ShareButtonPreview } from './ShareButtonPreview';

import { Switch } from 'components/style';
import { TextFieldWrapper, TextComponentWrapper } from '../style';
import {
  ButtonContainer,
  Checkbox,
  TitleContainer,
  ShareIconPreviewContainer,
  ShopifyMobileButtonContainer,
} from './style';

const FORM_ID = 'sharingButtonForm';

const BUTTON_ICON_STATE = {
  active: 'active',
  default: 'default',
};

export const SharingButtonSettings = ({
  planogramVersion,
  planogramId,
  handleServerError,
  setSuccessActionMessages,
}: Props) => {
  const [buttonSettings, setButtonSettings] = useState<SharingButton | null>(null);
  const [initialButtonSettings, setInitialButtonSettings] = useState<SharingButton | null>(null);
  const [fontSettingsEdit, setFontSettingsEdit] = useState<Partial<SharingButtonFont> | null>(null);
  const getT = getTWithPathKey('common.errors');
  const {
    currentUser: {
      attributes: { shoppingPlatformName },
    },
  } = useAuthState();

  useEffect(() => {
    planogramsModel
      .getPlanogramShareButtons(planogramId, planogramVersion.id)
      .then((settings: SharingButton) => initSharingButton(settings))
      .catch(handleServerError);
  }, [planogramVersion]);

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const onSubmit = withHandlingSubmittingState(async ({ target: { id } }) => {
    try {
      if (id !== FORM_ID) return;
      if (!buttonSettings) return;

      if (!some(buttonSettings.sharingProviders, 'enabled')) {
        handleServerError(getServerError(getT('atLeastOneShareIcon')));
        return;
      }
      const sharingButtonFont = buttonSettings.sharingButtonFont
        ? (getFontSettingsWithPx(buttonSettings.sharingButtonFont) as SharingButtonFont)
        : null;

      const data = await planogramsModel.editPlanogramShareButtons(
        planogramId,
        planogramVersion.id,
        buttonSettings.id,
        {
          ...buttonSettings,
          sharingButtonFont,
        },
      );

      setSuccessActionMessages(data.success);
      initSharingButton(data.sharingButton);
    } catch (err) {
      handleServerError(err as ServerErrorType);
    }
  });

  const initSharingButton = (settings: SharingButton) => {
    if (settings.sharingButtonFont) {
      set(settings, 'sharingButtonFont', getFontSettingsWithNums(settings.sharingButtonFont));
    }
    setButtonSettings(cloneDeep(settings));
    setInitialButtonSettings(settings);
  };

  const getIsSaveButtonVisible = () => {
    if (!buttonSettings || !initialButtonSettings) return false;

    const isEnabledChanged = buttonSettings.enabled !== initialButtonSettings.enabled;

    return (
      !isSubmitting &&
      (isEnabledChanged || (Boolean(buttonSettings.enabled) && !isEqual(buttonSettings, initialButtonSettings)))
    );
  };

  const handleChange = (item: Partial<SharingButton>) =>
    setButtonSettings(prevState => {
      const state = prevState as SharingButton;

      return {
        ...state,
        ...item,
      };
    });

  const handleSetField = (field: string) => (value: ValueOf<SharingButton>) => handleChange({ [field]: value });

  const handleEnableSwitch =
    (item: string) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
      handleChange({ [item]: checked });

  const handleFontSettingsSave = (settings: SharingButtonFont) => {
    closeFontSettingsDialog();
    handleSetField('sharingButtonFont')(settings);
  };

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsEdit = (settings: Partial<SharingButtonFont> | null) => () =>
    setFontSettingsEdit(settings || { sharingButtonId: buttonSettings?.id });

  const setButtonBgColor = ({ hex }: ColorResult) => handleSetField('color')(hex);

  const handleCheckedColorChange = ({ target: { value, checked } }: ChangeEvent<HTMLInputElement>) =>
    handleSetField(value)(checked ? COLORS.white : '');

  const getButtonIconItem = (type: string): SharingButtonIcon | null | undefined => {
    if (!buttonSettings) return null;

    const userIcon = find(buttonSettings.buttonIcons, ['iconState', type]);

    return (userIcon?.url && userIcon) || find(buttonSettings.buttonIcons, ['iconState', `system_${type}`]);
  };

  const getButtonIconItemUrl = (type: string) => getButtonIconItem(type)?.url || '';

  const isCustomShareIcon = (type: string) => getButtonIconItem(type)?.iconState === type;

  const setButtonIcon = (type: string) => (value: SharingButtonIcon) => {
    if (!buttonSettings) return;

    const iconsList = [...buttonSettings.buttonIcons];
    const iconIndex = findIndex(iconsList, ['iconState', type]);

    if (iconIndex >= 0) {
      const { signedId, ...oldIcon } = iconsList[iconIndex];

      iconsList[iconIndex] = { ...oldIcon, ...value };
    } else {
      iconsList.push({ ...value, iconState: type });
    }

    handleSetField('buttonIcons')(iconsList);
  };

  const handleImageFileSet = (type: string) => (blob: ActiveStorageBlob) => {
    const handler = setButtonIcon(type);

    if (blob) {
      handler({
        url: getBlobUrl(blob.key),
        signedId: blob.signed_id,
        deleteIcon: false,
        iconState: '',
      });
    } else {
      handler({ iconState: '', url: '', deleteIcon: true });
    }
  };

  const handleShareIconsChange = (index: number) => (value: Partial<SharingButtonProvider>) => {
    if (!buttonSettings) return;

    const iconsList = buttonSettings.sharingProviders;
    const { signedId, ...oldIcon } = iconsList[index];
    const newIconValue = { ...oldIcon, ...value };

    handleSetField('sharingProviders')([...set(iconsList, index, newIconValue)]);
  };

  const handleShareIconSwitch =
    (index: number) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
      handleShareIconsChange(index)({ enabled: checked });

  const handleShareIconFileSet = (index: number) => (blob: ActiveStorageBlob) => {
    const handler = handleShareIconsChange(index);

    if (blob) {
      handler({
        iconCustomUrl: getBlobUrl(blob.key),
        signedId: blob.signed_id,
        removeImage: false,
      });
    } else {
      handler({ iconCustomUrl: '', removeImage: true });
    }
  };

  const renderNav = () => <Switch checked={buttonSettings?.enabled} onChange={handleEnableSwitch('enabled')} />;

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={onSubmit} id={FORM_ID}>
      <CardComponent title="Sharing Button and Icons" sm={8} CardHeaderNav={renderNav} isLoading={isSubmitting}>
        {!!buttonSettings && buttonSettings.enabled && (
          <Grid container direction="row" alignItems="flex-start" spacing={4}>
            <Grid item sm={12}>
              <Typography variant="h6">Create your own design for sharing buttons.</Typography>
            </Grid>
            <Grid container item sm={8}>
              <TitleContainer>
                <Typography variant="h4">Text</Typography>
              </TitleContainer>
              <FontSettingsControlWrapper
                wrapperClassName="mt-auto"
                fontSettings={buttonSettings.sharingButtonFont}
                handleSettingsEdit={handleFontSettingsEdit(buttonSettings.sharingButtonFont)}
              >
                <TextFieldWrapper>
                  <TextComponentWrapper>
                    <TextValidatorComponent
                      fieldName="title"
                      label="Text"
                      value={buttonSettings.title}
                      handleChange={handleChange}
                    />
                  </TextComponentWrapper>
                </TextFieldWrapper>
              </FontSettingsControlWrapper>
            </Grid>
            <Grid container item sm={12}>
              <TitleContainer>
                <Typography variant="h4">Background color</Typography>
              </TitleContainer>
              <Checkbox
                color="primary"
                value="color"
                checked={Boolean(buttonSettings.color)}
                onChange={handleCheckedColorChange}
              />
              <ColorPicker setColor={setButtonBgColor} color={{ hex: buttonSettings.color || '' }} />
            </Grid>
            {!shoppingPlatformName ? (
              <>
                <Grid item sm={6}>
                  <TitleContainer>
                    <Typography variant="h4">Default Icon</Typography>
                  </TitleContainer>
                  <ImageUploadComponent
                    accept={ACCEPT_EXTENSIONS.imgIcon}
                    imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.default)}
                    handleImageUpload={handleImageFileSet(BUTTON_ICON_STATE.default)}
                    handleServerError={handleServerError}
                    withDelete={isCustomShareIcon(BUTTON_ICON_STATE.default)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TitleContainer>
                    <Typography variant="h4">Active Icon</Typography>
                  </TitleContainer>
                  <ImageUploadComponent
                    accept={ACCEPT_EXTENSIONS.imgIcon}
                    imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.active)}
                    handleImageUpload={handleImageFileSet(BUTTON_ICON_STATE.active)}
                    handleServerError={handleServerError}
                    withDelete={isCustomShareIcon(BUTTON_ICON_STATE.active)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TitleContainer>
                    <Typography variant="h4">Preview Desktop</Typography>
                  </TitleContainer>
                  <ButtonPreviewWrapper>
                    <ButtonContainer>
                      <ShareButtonPreview
                        backgroundColor={buttonSettings.color}
                        imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.default)}
                      />
                      <ShareButtonPreview
                        backgroundColor={buttonSettings.color}
                        imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.active)}
                      />
                    </ButtonContainer>
                  </ButtonPreviewWrapper>
                </Grid>
                <Grid item sm={12}>
                  <TitleContainer>
                    <Typography variant="h4">Preview Mobile</Typography>
                  </TitleContainer>
                  <ButtonPreviewWrapper>
                    <ButtonContainer>
                      <ShareButtonPreview
                        isMobile
                        backgroundColor={buttonSettings.color}
                        imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.default)}
                        title={buttonSettings.title}
                        fontSettings={buttonSettings.sharingButtonFont}
                      />
                    </ButtonContainer>
                  </ButtonPreviewWrapper>
                </Grid>
              </>
            ) : (
              <Grid container item sm={12} spacing={4} alignItems="center">
                <Grid item sm={12}>
                  <TitleContainer>
                    <Typography variant="h4">Preview Mobile</Typography>
                  </TitleContainer>
                </Grid>
                <Grid item sm={4}>
                  <ImageUploadComponent
                    accept={ACCEPT_EXTENSIONS.imgIcon}
                    imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.default)}
                    handleImageUpload={handleImageFileSet(BUTTON_ICON_STATE.default)}
                    handleServerError={handleServerError}
                    withDelete={isCustomShareIcon(BUTTON_ICON_STATE.default)}
                  />
                </Grid>
                <Grid item sm={8}>
                  <ButtonPreviewWrapper isShopify>
                    <ShopifyMobileButtonContainer>
                      <ShareButtonPreview
                        isMobile
                        backgroundColor={buttonSettings.color}
                        imageUrl={getButtonIconItemUrl(BUTTON_ICON_STATE.default)}
                        title={buttonSettings.title}
                        fontSettings={buttonSettings.sharingButtonFont}
                      />
                    </ShopifyMobileButtonContainer>
                  </ButtonPreviewWrapper>
                </Grid>
              </Grid>
            )}
            <Grid item sm={12}>
              <TitleContainer>
                <Typography variant="h3">Sharing Icons</Typography>
              </TitleContainer>
            </Grid>
            {buttonSettings.sharingProviders.map((provider, i) => (
              <Grid container item sm={12} spacing={4} alignItems="center" key={provider.id}>
                <Grid item sm={12}>
                  <TitleContainer>
                    <Typography variant="h4">{provider.title}</Typography>
                    <Switch size="small" checked={provider.enabled} onChange={handleShareIconSwitch(i)} />
                  </TitleContainer>
                </Grid>
                {provider.enabled && (
                  <>
                    <Grid item sm={4}>
                      <ImageUploadComponent
                        accept={ACCEPT_EXTENSIONS.imgIcon}
                        imageUrl={provider.iconCustomUrl || provider.iconDefaultUrl}
                        handleImageUpload={handleShareIconFileSet(i)}
                        handleServerError={handleServerError}
                        withDelete={Boolean(provider.iconCustomUrl)}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <ShareIconPreviewContainer className="dark-theme">
                        <img src={provider.iconCustomUrl || provider.iconDefaultUrl} alt="" />
                        <span>Dark Background</span>
                      </ShareIconPreviewContainer>
                    </Grid>
                    <Grid item sm={4}>
                      <ShareIconPreviewContainer>
                        <img src={provider.iconCustomUrl || provider.iconDefaultUrl} alt="" />
                        <span>Light Background</span>
                      </ShareIconPreviewContainer>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </CardComponent>
      <FontSettingsEditDialog
        settings={fontSettingsEdit}
        handleClose={closeFontSettingsDialog}
        handleSet={handleFontSettingsSave}
      />
    </SettingsForm>
  );
};
