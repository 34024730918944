import { PaginationParams } from 'types/common';
import { ControlButton, ControlButtonListResponse } from 'types/overlays';

import { api } from './setupApi';

class ControlButtonModel {
  link = '/control_buttons';

  getImages = (params: PaginationParams): Promise<ControlButtonListResponse> =>
    api.get<ControlButtonListResponse>(this.link, { params }).then(({ data }) => data);

  deleteImage = (id: number) => api.delete(`${this.link}/${id}`);

  postImage = (data: Record<'signedId', string>) => api.post(this.link, data);

  editImage = (id: number, data: ControlButton) => api.put(`${this.link}/${id}`, data);
}

export const controlButtonModel = new ControlButtonModel();
