import React, { useEffect, useState } from 'react';

import { Grid, TextField } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  ButtonComponent,
  CardComponent,
  DateComponent,
  ItemWithData,
  LinkComponent,
  CopyToClipboardComponent,
} from 'components';
import { ROUTES } from 'routes/constants';
import { getCurrentHostAndProtocol } from 'utils';

import { PLANOGRAM_VERSION_STATUS } from '../../constants';
import { StatusComponent } from '../../StatusComponent/StatusComponent';
import { DraftedHistory } from '../DraftedHistory/DraftedHistory';

import { Switch } from 'components/style';
import { Box } from '../style';

export function PlanogramVersion({
  planogramId,
  planogramName,
  planogramVersion,
  title,
  handleSetConfirmationParams,
  updatedVersion,
  isLegacy,
  children,
  publishedDrafted,
  editPlanogramVersion,
  regenerateCredentials,
  handleCloneAction,
}) {
  const [animationClassName, setAnimationClassName] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (updatedVersion && planogramVersion?.status === updatedVersion) {
      setAnimationClassName('animated');
      setTimeout(() => {
        setAnimationClassName('stop-animate');
      }, 2000);
    } else if (animationClassName) {
      setAnimationClassName('');
    }
  }, [updatedVersion]);

  const getCurateLink = () => `${getCurrentHostAndProtocol()}${ROUTES.curatorNamespace}${planogramId}`;

  const isPublished = planogramVersion?.status === PLANOGRAM_VERSION_STATUS.published;

  const handlePublishProtection = async () => {
    if (!editPlanogramVersion) {
      return;
    }

    try {
      setLoading(true);
      await editPlanogramVersion({
        id: planogramId,
        versionId: planogramVersion.id,
        data: {
          publishedProtected: !planogramVersion.publishedProtected,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRegeneration = async () => {
    if (!regenerateCredentials) {
      return;
    }

    try {
      setLoading(true);
      await regenerateCredentials({
        id: planogramId,
        versionId: planogramVersion.id,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloneSphere = e => {
    e.stopPropagation();

    handleCloneAction({
      id: planogramId,
      planogramVersionId: planogramVersion.id,
      name: planogramName,
      version: planogramVersion.status,
    });
  };

  const renderNav = () => {
    switch (planogramVersion.status) {
      case PLANOGRAM_VERSION_STATUS.published:
        return (
          <div>
            <ButtonComponent text="Copy Sphere" onClick={handleCloneSphere} />
            <ButtonComponent
              className="ml"
              text="Clone"
              color="primary"
              onClick={handleSetConfirmationParams(PLANOGRAM_VERSION_STATUS.drafting, planogramVersion.id)}
            />
          </div>
        );
      case PLANOGRAM_VERSION_STATUS.generated:
        return (
          <div>
            <ButtonComponent text="Copy Sphere" onClick={handleCloneSphere} />
            <ButtonComponent
              className="ml"
              color="primary"
              text="Publish"
              onClick={handleSetConfirmationParams(PLANOGRAM_VERSION_STATUS.publishing, planogramVersion.id)}
            />
          </div>
        );
      case PLANOGRAM_VERSION_STATUS.draft:
        if (!isLegacy) {
          return (
            <div>
              <LinkComponent to={ROUTES.manageSpheresSettings.replace(':id', planogramId)}>
                <ButtonComponent text="Settings" />
              </LinkComponent>
              <ButtonComponent className="ml" text="Copy Sphere" onClick={handleCloneSphere} />
              <LinkComponent href={getCurateLink()} target="_blank">
                <ButtonComponent text="Curate" color="primary" className="ml" />
              </LinkComponent>
            </div>
          );
        }
        return (
          <div>
            <LinkComponent to={ROUTES.manageSpheresSettings.replace(':id', planogramId)}>
              <ButtonComponent text="Settings" />
            </LinkComponent>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!isEmpty(planogramVersion) && (
        <CardComponent
          title={title}
          sm={12}
          CardHeaderNav={renderNav}
          cardClassName="active"
          wrapperClassName={animationClassName}
          titleClassName="mb"
          isLoading={isLoading}
        >
          <Grid container style={{ marginBottom: '20px', alignItems: 'center' }}>
            <StatusComponent status={planogramVersion.status} useIcon={false} />
            <DateComponent value={planogramVersion.createdAt} />
            {planogramVersion.status === PLANOGRAM_VERSION_STATUS.generated && (
              <ButtonComponent
                className="blue"
                variant="text"
                text="Clone to draft"
                style={{ marginLeft: 'auto' }}
                onClick={handleSetConfirmationParams(PLANOGRAM_VERSION_STATUS.drafting, planogramVersion.id)}
              />
            )}
          </Grid>
          <ItemWithData title="Editor" data={planogramVersion.editor} />
          <ItemWithData title="Items count" data={planogramVersion.itemsCount} />
          {planogramVersion.url && (
            <>
              <ItemWithData title="Sphere link" hideColon />
              <ItemWithData title="Internal">
                <LinkComponent href={planogramVersion.url} className="blue" target="_blank" />
              </ItemWithData>
              {planogramVersion.externalUrls?.length > 0 && (
                <ItemWithData title="External">
                  {planogramVersion.externalUrls.map((url, index) => (
                    <span key={url}>
                      <LinkComponent key={url} href={url} className="blue" target="_blank" />
                      {index < planogramVersion.externalUrls.length - 1 && <span>, </span>}
                    </span>
                  ))}
                </ItemWithData>
              )}
            </>
          )}
          {isPublished && (
            <ItemWithData title="Password enabled">
              <Box mt="-4px">
                <Switch size="small" checked={planogramVersion.publishedProtected} onChange={handlePublishProtection} />
              </Box>
              {planogramVersion.publishedLogin && (
                <>
                  <Box mt={2.5}>
                    <TextField
                      label="login"
                      value={planogramVersion.publishedLogin}
                      margin="dense"
                      variant="outlined"
                      disabled
                    />
                    <CopyToClipboardComponent text={planogramVersion.publishedLogin} />
                  </Box>
                  <Box>
                    <TextField
                      label="password"
                      value={planogramVersion.publishedPassword}
                      margin="dense"
                      variant="outlined"
                      disabled
                    />
                    <CopyToClipboardComponent text={planogramVersion.publishedPassword} />
                  </Box>
                  <Box mt={2.5}>
                    <ButtonComponent text="Generate new credentials" onClick={handleRegeneration} />
                  </Box>
                </>
              )}
            </ItemWithData>
          )}
          {planogramVersion.generatedLogin && (
            <>
              <ItemWithData title="Temporary credentials">
                <Box>
                  <TextField
                    label="login"
                    value={planogramVersion.generatedLogin}
                    margin="dense"
                    variant="outlined"
                    disabled
                  />
                  <CopyToClipboardComponent text={planogramVersion.generatedLogin} />
                </Box>
                <Box>
                  <TextField
                    label="password"
                    value={planogramVersion.generatedPassword}
                    margin="dense"
                    variant="outlined"
                    disabled
                  />
                  <CopyToClipboardComponent text={planogramVersion.generatedPassword} />
                </Box>
              </ItemWithData>
            </>
          )}

          {publishedDrafted && (
            <DraftedHistory
              publishedDrafted={publishedDrafted}
              handleSetConfirmationParams={handleSetConfirmationParams}
            />
          )}

          {children}
        </CardComponent>
      )}
    </>
  );
}

PlanogramVersion.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    editor: PropTypes.string,
    createdAt: PropTypes.string,
    itemsCount: PropTypes.number,
    url: PropTypes.string,
    generatedLogin: PropTypes.string,
    generatedPassword: PropTypes.string,
    publishedLogin: PropTypes.string,
    publishedPassword: PropTypes.string,
    publishedProtected: PropTypes.bool,
    imageUrl: PropTypes.string,
    svgData: PropTypes.string,
    validationError: PropTypes.string,
  }),
  title: PropTypes.string,
  handleSetConfirmationParams: PropTypes.func.isRequired,
  editPlanogramVersion: PropTypes.func,
  regenerateCredentials: PropTypes.func,
  planogramId: PropTypes.number.isRequired,
  planogramName: PropTypes.string.isRequired,
  updatedVersion: PropTypes.string.isRequired,
  isLegacy: PropTypes.bool,
  children: PropTypes.node,
  publishedDrafted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      editor: PropTypes.string,
      createdAt: PropTypes.string,
      itemsCount: PropTypes.number,
      url: PropTypes.string,
      generatedLogin: PropTypes.string,
      generatedPassword: PropTypes.string,
      publishedLogin: PropTypes.string,
      publishedPassword: PropTypes.string,
      publishedProtected: PropTypes.bool,
      imageUrl: PropTypes.string,
      svgData: PropTypes.string,
      validationError: PropTypes.string,
    }),
  ),
  handleCloneAction: PropTypes.func,
};

PlanogramVersion.defaultProps = {
  title: '',
  isLegacy: false,
  children: null,
  planogramVersion: null,
  publishedDrafted: null,
};
