import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { useSubmittingState } from 'hooks';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '../style';

interface DeleteInternalSubdomainDialogProps {
  host: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export function DeleteInternalSubdomainDialog({ host, onClose, onSubmit }: DeleteInternalSubdomainDialogProps) {
  const { t } = useTranslation();
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const handleSubmit = () => {
    withHandlingSubmittingState(onSubmit)();
  }

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>{t('components.internalSubdomains.deleteTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ paddingLeft: 0 }}>
          <Trans
            i18nKey="components.internalSubdomains.deleteDescription"
            components={{ b: <b /> }}
            values={{ host }}
          />
        </DialogContentText>
        <DialogActions>
          <ButtonComponent text={t('common.buttons.cancel')} onClick={onClose} />
          <ButtonComponent
            text={t('common.buttons.delete')}
            color="primary"
            loaderSize="1.35em"
            loading={isSubmitting}
            onClick={handleSubmit}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
