import { Box as MuiBox, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const FaviconBox = styled(MuiBox)({
  '&.light': { backgroundColor: COLORS.white, borderColor: COLORS.greyBorder },
  '&.dark': { backgroundColor: COLORS.dark, borderColor: COLORS.dark, color: COLORS.white },
  borderRadius: '6px',
  borderWidth: '1px',
  borderStyle: 'solid',
  height: '32px',
  padding: '6px 8px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& img': { marginRight: '6px' },
});
