import React, { useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  BUTTON_SHAPES,
  ECOMMERCE_OVERLAYS_SETTINGS_TYPES,
  OVERLAY_CONFIGURATION,
} from 'components/ecommerce/constants';
import { fontSettingsProp } from 'components/propTypesConsts';
import { useAuthState } from 'contexts/AuthContext';
import { getTWithPathKey } from 'utils';

import { ButtonsTable, TypographyTable, BackgroundTable, TextFieldsTable, DisplayProperties } from '../common';

import { Box } from '../style';

export function EcommerceOverlaySettings({
  settings,
  setSettings,
  pageLanguageTabValue,
  isLanguageCodeVisible,
  Preview,
}) {
  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();
  const currentLanguageCode = clientLanguages[pageLanguageTabValue];
  const getT = getTWithPathKey('components.ecommerce.overlay');
  const config = OVERLAY_CONFIGURATION[settings?.overlayType];
  const typographyTableItems = useMemo(
    () =>
      config?.fonts.map(item => ({
        title: getT(`${settings.overlayType}.typographyOptions.${item}.title`),
        subtitle: getT(`${settings.overlayType}.typographyOptions.${item}.caption`),
        id: item,
      })),
    [settings?.overlayType],
  );

  const setField = fieldName => value => setSettings(prev => ({ ...prev, [fieldName]: value }));

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box mb={4}>
          <Typography variant="h6" className="information">
            {getT(`${settings.overlayType}.caption`)}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={7} style={{ paddingRight: '24px' }}>
        <Box mt={6} mb={3}>
          <Typography variant="h4">{getT('stylesCapture')}</Typography>
        </Box>
        {!isEmpty(settings) && (
          <>
            <TypographyTable
              ecommerceFonts={settings.ecommerceFonts ?? []}
              tableItems={typographyTableItems}
              setFonts={setField('ecommerceFonts')}
            />
            {config?.withTextFields && (
              <TextFieldsTable
                textFieldSettings={settings.ecommerceTextField}
                setTextFieldSettings={setField('ecommerceTextField')}
              />
            )}
            <ButtonsTable
              ecommerceSettings={settings ?? {}}
              setSettings={setSettings}
              pageLanguageTabValue={pageLanguageTabValue}
              withShapeConfiguration={config?.withShapeConfiguration}
              withControlColor={config.withControlColor}
              isLanguageCodeVisible={isLanguageCodeVisible}
            />
            <BackgroundTable ecommerceSettings={settings ?? {}} setSettings={setSettings} />
            {settings.overlayType === ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp && (
              <DisplayProperties ecommerceSettings={settings ?? {}} setSettings={setSettings} />
            )}
          </>
        )}
      </Grid>
      <Grid item sm={5}>
        <Box mt={6} mb={3}>
          <Typography variant="h4">{getT('previewCapture')}</Typography>
        </Box>
        {!isEmpty(settings) && (
          <Preview
            settings={settings}
            pageLanguageTabValue={pageLanguageTabValue}
            currentLanguageCode={currentLanguageCode}
            isLanguageCodeVisible={isLanguageCodeVisible}
          />
        )}
      </Grid>
    </Grid>
  );
}

EcommerceOverlaySettings.propTypes = {
  settings: PropTypes.shape({
    ecommerceFonts: PropTypes.arrayOf(fontSettingsProp),
    ecommerceButtons: PropTypes.array,
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
    buttonShape: PropTypes.oneOf(Object.values(BUTTON_SHAPES)),
    overlayType: PropTypes.oneOf(Object.values(ECOMMERCE_OVERLAYS_SETTINGS_TYPES)),
    ecommerceTextField: PropTypes.shape({}),
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
  pageLanguageTabValue: PropTypes.number.isRequired,
  Preview: PropTypes.func.isRequired,
  isLanguageCodeVisible: PropTypes.bool,
};

EcommerceOverlaySettings.defaultProps = {
  isLanguageCodeVisible: false,
};
