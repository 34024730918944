import React, { useState, useEffect, ChangeEvent } from 'react';

import { Grid, Box, Divider } from '@material-ui/core';
import { isEqual } from 'lodash';

import { CardComponent, SettingsForm } from 'components';
import { TabsComponent } from 'components/TabsComponent/TabsComponent';
import { DatePickerComponent, AutocompleteValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { useSubmittingState } from 'hooks';
import { planogramsModel } from 'models';
import { PlanogramVersion, Props } from 'types';
import { getFormattedTime, getParsedTime } from 'utils';

import { Switch } from 'components/style';
import { Typography } from './style';

interface AutoRefreshProps {
  autoGen: boolean;
  autoGenTime: string | null;
  autoGenFrequency: string | null;
}

const defaultLogoSettings = {
  autoGen: false,
  autoGenTime: null,
  autoGenFrequency: null
} as AutoRefreshProps;

const frequencyTypes = ['Hourly', 'Scheduled'];
const hoursArr = Array(24).fill('')
  .map((item, index) => ({
    id: `${index + 1} ${index > 0 ? 'hours' : 'hour'}`
  }));
const frequencyValues = ['1 day', '1 week', '1 month'];
const isScheduled = (frequency: string | null): boolean =>
  frequencyValues.includes(frequency as string);

export const AutoRefreshSettings = ({
  planogramVersion,
  planogramId,
  handleServerError,
  setSuccessActionMessages
}: Props) => {
  const [refreshSettings, setRefreshSettings] = useState<AutoRefreshProps>(defaultLogoSettings);
  const [savedRefreshSettings, setSavedRefreshSettings] = useState<AutoRefreshProps>(defaultLogoSettings);
  const [tabValue, setTabValue] = useState(0);
  const [frequencyType, setFrequencyType] = useState(0);
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    const { autoGen, autoGenTime, autoGenFrequency } = planogramVersion as PlanogramVersion;
    const index = frequencyValues.indexOf(autoGenFrequency as string);

    if (isScheduled(autoGenFrequency)) {
      setFrequencyType(1);
    }
    setTabValue(index > -1 ? index : 0);
    setRefreshSettings({
      autoGen,
      autoGenTime: getParsedTime(autoGenTime),
      autoGenFrequency: autoGenFrequency ?? frequencyValues[tabValue]
    });
    setSavedRefreshSettings({
      autoGen,
      autoGenTime: getParsedTime(autoGenTime),
      autoGenFrequency: autoGenFrequency ?? frequencyValues[tabValue]
    });
  }, [planogramVersion]);

  const getIsSaveButtonVisible = () => {
    const hasEnabledChanges = !(!savedRefreshSettings.autoGen && !refreshSettings.autoGen);
    const hasNewChanges = !isEqual(savedRefreshSettings, refreshSettings);

    return !isSubmitting && hasEnabledChanges && hasNewChanges;
  };

  const handleChange = (item: Record<string, string | boolean>) =>
    setRefreshSettings(prevState => ({
      ...prevState,
      ...item,
    }));

  const handleChangeItem =
    (item: any) =>
      ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
        handleChange({ [item]: checked });

  const handleSave = withHandlingSubmittingState(async () => {
    try {
      const settings = {
        ...refreshSettings,
        autoGenFrequency: frequencyType === 1 && !isScheduled(refreshSettings.autoGenFrequency) ?
          frequencyValues[tabValue] :
          refreshSettings.autoGenFrequency,
        autoGenTime: frequencyType === 0 ?
          null :
          getFormattedTime(refreshSettings.autoGenTime)
      } as Record<string, any>;
      const data = await planogramsModel
        .editVersion(planogramId, planogramVersion.id, settings) as Record<string, any>;
      const { autoGen, autoGenTime, autoGenFrequency } = data.planogramVersion;

      setSuccessActionMessages(data.success);
      setSavedRefreshSettings({
        autoGen,
        autoGenTime: getParsedTime(autoGenTime),
        autoGenFrequency
      });
      setRefreshSettings({
        autoGen,
        autoGenTime: getParsedTime(autoGenTime),
        autoGenFrequency
      });
    } catch (err) {
      handleServerError(err as ServerErrorType);
    }
  });

  const renderNav = () => <Switch checked={refreshSettings.autoGen} onChange={handleChangeItem('autoGen')} />;

  return (
    <SettingsForm isSaveButtonVisible={getIsSaveButtonVisible()} handleSave={handleSave}>
      <CardComponent title="Auto-publish sphere" xs={9} CardHeaderNav={renderNav} isLoading={isSubmitting}>
        {refreshSettings.autoGen && (
          <>
            <Grid container spacing={6}>
              <Grid item sm={12}>
                <Box mb={5}>
                  <Typography>If you have auto-publish enabled on your sphere, we will regenerate and republish
                    the sphere at set times.This will update any Social Content objects which have auto publish enabled.
                    <br /><br />
                    You may want to set this to match your post schedule.
                  </Typography>
                </Box>
                <Box mb={5}>
                  <TabsComponent
                    value={frequencyType}
                    setValue={(index: number) => setFrequencyType(index)}
                    tabNames={frequencyTypes}
                    tabClassName="frequencyTypeTab"
                  />
                </Box>
                <Grid container spacing={5}>
                  {frequencyType === 0 ? (
                    <Grid item sm={3}>
                      <Typography variant="h5">Select Frequency</Typography>
                      <AutocompleteValidatorComponent
                        value={refreshSettings.autoGenFrequency}
                        fieldName="autoGenFrequency"
                        handleChange={handleChange}
                        disableClearable
                        disableMinHeight
                        selectList={hoursArr}
                        itemsNamePath="id"
                        validators={[VALIDATORS_MAP.required]}
                        style={{ marginBottom: '4px' }}
                      />
                      <Typography variant="caption">From time of publishing</Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid item sm={3}>
                        <Typography variant="h5">Set Time</Typography>
                        <DatePickerComponent
                          validators={[VALIDATORS_MAP.required]}
                          value={refreshSettings.autoGenTime}
                          fieldName="autoGenTime"
                          disableMinHeight
                          fullWidth
                          type="time"
                          handleChange={handleChange}
                          style={{ margin: '8px 0' }}
                        />
                        <Typography variant="caption">Time is local to your location</Typography>
                      </Grid>
                      <Grid item sm={9}>
                        <Typography variant="h5">Set Frequency</Typography>
                        <TabsComponent
                          value={tabValue}
                          setValue={(index: number) => {
                            setTabValue(index);
                            handleChange({ autoGenFrequency: frequencyValues[index] })
                          }}
                          tabNames={frequencyValues}
                          tabClassName="refreshTab"
                          rootClassName="my8"
                        />
                        <Typography variant="caption">From date of most recent publish</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box my={5}>
                  <Divider />
                </Box>
                <Typography variant="caption">Please note: Access to the sphere will be
                  momentarily blocked during the auto-publish process
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </CardComponent>
    </SettingsForm>
  );
}
