import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as DragIcon } from 'assets/icons/assets/draggable-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/assets/delete-icon.svg';
import { FontSettingsType, PlanogramVersionControlButtonMenuItem } from 'types';
import { FontSettingsControlWrapper, ImageChooseComponent } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { controlButtonModel, planogramsModel } from 'models';
import { ImageChooseWrapper } from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/DraggableMenuItems.style';
import { ReactComponent as LandscapeIcon } from 'assets/icons/assets/landscape.svg';
import { AutocompleteValidatorComponent, TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { NAVIGATION_TYPE } from 'components/LinkBehavior/constants';
import { FontSettingsEditDialog } from 'components/Dialogs';
import { getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';
import { HostErrorResponsePlanogramData } from 'types/other';
import InternalObjectSelector from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/InternalObjectSelector';
import { PlanogramObjectData } from 'pages/planograms/ManagePlanogramSettings/MenuButtonSettings/DraggableMenuItems/InternalObjectSelect/helper';

import OptionItem from '../OptionItem/OptionItem';

import { Switch } from 'components/style';

const ITEM_TYPE_OPTIONS = [
  {
    id: 'internal',
    label: 'Internal',
  },
  {
    id: 'external',
    label: 'External',
  },
  {
    id: 'custom/same',
    label: 'External/same tab',
  },
  {
    id: 'custom/new',
    label: 'External/new tab',
  },
];

interface DraggableMenuItemsProps {
  items: PlanogramVersionControlButtonMenuItem[];
  planogramObjectData: PlanogramObjectData[];
  onChange: (items: PlanogramVersionControlButtonMenuItem[]) => void;
}

const DraggableMenuItems = ({ items, planogramObjectData, onChange }: DraggableMenuItemsProps) => {
  const { handleServerError } = useServerErrorActions();
  const [fontSettingsEdit, setFontSettingsEdit] = useState<FontSettingsType | null>(null);
  const [currentFontEditIndex, setCurrentFontEditIndex] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  useEffect(() => {
    const newItems = [...items];

    newItems.map(item => ({ ...item, id: uuidv4() }));
    onChange(newItems);
  }, []);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const itemsCopy = Array.from(items);
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);

    itemsCopy.splice(result.destination.index, 0, reorderedItem);
    onChange(itemsCopy);
  };

  const setProperty = (property: keyof PlanogramVersionControlButtonMenuItem, value: any, index: number) => {
    const newItems = [...items];
    const item = newItems[index];
    const updatedItem = { ...item, [property]: value };

    newItems[index] = updatedItem as never;
    onChange(newItems);
  };

  const setProperties = (properties: (keyof PlanogramVersionControlButtonMenuItem)[], values: any[], index: number) => {
    const newItems = [...items];
    const item = newItems[index];
    const updatedItem = { ...item };

    properties.forEach((property, i) => {
      updatedItem[property] = values[i] as never;
    });

    newItems[index] = updatedItem as never;
    onChange(newItems);
  };

  const getImages = (params: any) => controlButtonModel.getImages(params).catch(handleServerError);

  const handleDelete = (index: number) => {
    const newItems = [...items];

    newItems.splice(index, 1);
    onChange(newItems);
  };

  const getNavigationTypeValue = (menuItem: PlanogramVersionControlButtonMenuItem) => {
    if (menuItem.navigationType === NAVIGATION_TYPE.external) {
      return `custom/${menuItem.openInNewPage ? 'new' : 'same'}`;
    }

    return menuItem.navigationType;
  };

  const handleFontSettingsEdit = (settings: FontSettingsType) => setFontSettingsEdit({ ...settings });

  const closeFontSettingsDialog = () => setFontSettingsEdit(null);

  const handleFontSettingsSave = (settings: FontSettingsType, index: number) => {
    setFontSettingsEdit(null);
    const pxSettings = getFontSettingsWithPx(settings);

    setProperty('menuItemFont', { ...pxSettings }, index);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {provided => (
          <Grid container {...provided.droppableProps} ref={provided.innerRef}>
            {currentFontEditIndex !== undefined && (
              <FontSettingsEditDialog
                settings={fontSettingsEdit}
                handleClose={closeFontSettingsDialog}
                handleSet={fontSettings => handleFontSettingsSave(fontSettings, currentFontEditIndex)}
              />
            )}
            {items.map((item, index) => (
              <Grid item xs={12} key={item.id ?? index}>
                <Draggable draggableId={item.id?.toString() ?? index.toString()} index={index}>
                  {provided => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <Box display="flex" flexDirection="row" height="75px" paddingY="9.5px" ml="-6px">
                        <Box display="flex" alignItems="center" mr="8px" {...provided.dragHandleProps}>
                          <DragIcon width="20px" height="20px" />
                        </Box>
                        <Box display="flex" alignItems="center" width="20px" mr="8px">
                          <Typography>{index + 1}.</Typography>
                        </Box>
                        <Box mr="20px">
                          <ImageChooseWrapper>
                            <ImageChooseComponent
                              image={item.controlButton}
                              setImage={image =>
                                setProperties(['controlButton', 'controlButtonId'], [image, image.id], index)
                              }
                              handleServerError={handleServerError}
                              dialogTitle="Choose image"
                              getPictures={getImages}
                              withLabel={false}
                              placeholderIcon={<LandscapeIcon />}
                            />
                          </ImageChooseWrapper>
                        </Box>
                        <Box width="110px" mr="20px">
                          <AutocompleteValidatorComponent
                            open={isOpen && index === currentIndex}
                            value={getNavigationTypeValue(item)}
                            onOpen={() => {
                              setCurrentIndex(index);
                              setIsOpen(true);
                            }}
                            onClose={() => {
                              setCurrentIndex(null);
                              setIsOpen(false);
                            }}
                            fieldName="navigationType"
                            disableListWrap
                            handleChange={(val: { navigationType: string }) => {
                              setProperties(
                                ['navigationType', 'navigationValue', 'openInNewPage'],
                                [val.navigationType, undefined, false],
                                index,
                              );
                            }}
                            filterOptions={(options: { label: string | null | undefined }[]) =>
                              options.filter(option => !option.label?.includes('tab'))
                            }
                            renderOption={(option: { label: string | null | undefined }) => (
                              <OptionItem
                                option={option}
                                setProperty={setProperty}
                                setIsOpen={setIsOpen}
                                index={index}
                              />
                            )}
                            itemsNamePath="label"
                            disableMinHeight
                            disableClearable
                            selectList={ITEM_TYPE_OPTIONS}
                            label="Type"
                          />
                        </Box>
                        <Box width="256px" mr="20px" minWidth={0}>
                          {item.navigationType === 'internal' && (
                            <InternalObjectSelector
                              planogramObjectData={planogramObjectData}
                              value={item.navigationValue}
                              useNavigation={item.withoutNavigation}
                              handleChange={(val: { navigationValueSelect: string, withoutNavigation?: boolean }) => {
                                setProperties(
                                  ['navigationValue', 'withoutNavigation'],
                                  [val.navigationValueSelect, val.withoutNavigation],
                                  index
                                )
                              }}
                            />
                          )}
                          {item.navigationType === 'external' && (
                            <TextValidatorComponent
                              label="URL"
                              value={item.navigationValue ?? ''}
                              fieldName="navigationValue"
                              handleChange={(val: { navigationValue: string }) =>
                                setProperty('navigationValue', val.navigationValue, index)
                              }
                              validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.isValidDomain]}
                            />
                          )}
                        </Box>
                        <Box width="209px">
                          <TextValidatorComponent
                            label="Title"
                            value={item.title ?? ''}
                            fieldName="title"
                            handleChange={(val: { title: string }) => setProperty('title', val.title, index)}
                            disableMinHeight
                          />
                        </Box>
                        <Grid container style={{ maxWidth: '68px', marginRight: '20px' }}>
                          <FontSettingsControlWrapper
                            fontSettings={getFontSettingsWithNums(item.menuItemFont)}
                            handleSettingsEdit={settings => {
                              setCurrentFontEditIndex(index);
                              handleFontSettingsEdit(settings);
                            }}
                          />
                        </Grid>
                        <Box mr="28px" display="flex" alignItems="center" pb={2}>
                          <Typography variant="caption" noWrap>
                            Show title
                          </Typography>
                          <Switch
                            checked={item.showTitle}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setProperty('showTitle', event.target.checked, index)
                            }
                          />
                        </Box>
                        <Box display="flex" alignItems="center" pb={2}>
                          <DeleteIcon width="24px" height="24px" onClick={() => handleDelete(index)} cursor="pointer" />
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              </Grid>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableMenuItems;
