import React from 'react';

import { Redirect } from 'react-router-dom';

import { useAuthState } from 'contexts/AuthContext';
import { getHomeRoute } from 'utils';

function Home() {
  const {
    currentUser: {
      attributes: { role },
    },
  } = useAuthState();

  return <Redirect to={getHomeRoute(role)} />;
}

export default Home;
