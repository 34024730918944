import React, { ChangeEvent, ComponentType, useState } from 'react';

import { TextFieldProps } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import { INPUT_TYPES } from 'constants/technical';

export interface WithHandleNumberTypeProps {
  validators: string[];
  value: string | number;
}

type WithHandleNumberTypePropsType = WithHandleNumberTypeProps & TextFieldProps;

const DEFAULT_FUNC = () => {};

export const withHandleNumberType =
  <T,>(Component: ComponentType<T & TextFieldProps>) =>
  ({ onChange = DEFAULT_FUNC, type, validators, value, ...props }: WithHandleNumberTypePropsType): JSX.Element => {
    const [isNumber] = useState(type === INPUT_TYPES.NUMBER);

    const validateNumberInput = (validateValue?: string): string | boolean | undefined =>
      /^-?([0-9]+)?([0-9]\.([0-9]+)?)?$/.test(validateValue!) || validateValue === '';

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { target: { value } = {} } = event;
      const isValueValid = validateNumberInput(value);

      if (isValueValid) {
        onChange(event);
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const modifiedEvent = cloneDeep(event);
      const { target } = modifiedEvent;

      if (target.value.length === 1 && target.value[0] === '-') target.value = '0';
      if (target.value.slice(-1) === '.') target.value += 0;

      onChange(modifiedEvent);
    };

    return isNumber ? (
      <Component
        {...(props as T)}
        validators={validators}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        type={INPUT_TYPES.TEXT}
      />
    ) : (
      <Component {...(props as T)} validators={validators} value={value} onChange={onChange} type={type} />
    );
  };
