import { PaginationParams } from 'types/common';
import { BGImagesResponse } from 'types/images';

import { api } from './setupApi';

class BackgroundImagesModel {
  link = '/background_images';

  getImages = (params: PaginationParams): Promise<BGImagesResponse> =>
    api.get<BGImagesResponse>(this.link, { params }).then(({ data }) => data);

  deleteImage = (id: number) => api.delete(`${this.link}/${id}`);

  postImage = (data: Record<'signedId', string>) => api.post(this.link, data);
}

export const backgroundImagesModel = new BackgroundImagesModel();
