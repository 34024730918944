import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { EcommerceUserProfileForm } from 'components';
import { BUTTON_TYPES, ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from 'components/ecommerce/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { planogramsModel } from 'models';

import { Switch } from 'components/style';

export function EcommerceUserProfileSphere({
  buttonsSettings,
  planogramId,
  planogramVersionId,
  onToggleSettingCard,
  setSuccessActionMessages,
}) {
  const [settings, setSettings] = useState({});
  const [enabled, setEnabled] = useState(false);
  const { handleServerError } = useServerErrorActions();

  useEffect(() => {
    if (!isEmpty(buttonsSettings)) {
      setSettings(buttonsSettings);
      setEnabled(Boolean(buttonsSettings.id));
    }
  }, [buttonsSettings]);

  const handleSaveSettings = newSettings =>
    planogramsModel
      .updateEcommerceSettings(newSettings, planogramId, planogramVersionId)
      .then(({ ecommerceOverlaySetting, success }) => {
        setSettings(ecommerceOverlaySetting);
        setSuccessActionMessages(success);
      })
      .catch(handleServerError);

  const onSwitchChange = () =>
    onToggleSettingCard(!enabled, settings.id, ECOMMERCE_OVERLAYS_SETTINGS_TYPES.buttons, () =>
      setEnabled(prev => !prev),
    ).then(data => setSettings(data ? data?.ecommerceOverlaySetting : {}));

  const renderHeaderNav = () => <Switch checked={enabled} onChange={onSwitchChange} />;

  return (
    <EcommerceUserProfileForm
      buttonsSettings={settings}
      enabled={enabled}
      onSave={handleSaveSettings}
      renderHeaderNav={renderHeaderNav}
    />
  );
}

EcommerceUserProfileSphere.propTypes = {
  planogramId: PropTypes.string.isRequired,
  planogramVersionId: PropTypes.number.isRequired,
  buttonsSettings: PropTypes.shape({
    ecommerceIconButtons: PropTypes.arrayOf(
      PropTypes.shape({
        buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
        color: PropTypes.string,
        otherAsset: PropTypes.shape({}),
        otherAssetId: PropTypes.number,
      }),
    ),
    id: PropTypes.number,
  }).isRequired,
  onToggleSettingCard: PropTypes.func.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
