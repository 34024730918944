import React, { useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { SelectImage } from 'components/Dialogs';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { getImageThumbUrl, getTWithPathKey } from 'utils';

import { AddImageContainer, UploadedImageContainer } from './style';

function VariantImageUpload({ image, getImages, setImage, removeImage }) {
  const getT = getTWithPathKey('pages.products.manageProduct.productPictures.productVariantsTable');
  const { handleServerError } = useServerErrorActions();
  const [openDialog, setOpenDialog] = useState(false);
  const handleToggleDialog = () => setOpenDialog(prevState => !prevState);
  const handleRemoveImage = () => removeImage(image);

  return (
    <>
      {image ? (
        <UploadedImageContainer>
          <img alt={image.seoTitle} src={getImageThumbUrl(image)} />
          <IconButton size="small" className="on-hover-delete" onClick={handleRemoveImage}>
            <DeleteIcon />
          </IconButton>
        </UploadedImageContainer>
      ) : (
        <AddImageContainer onClick={handleToggleDialog}>
          <AddIcon />
          <Typography>{getT('addPicture')}</Typography>
        </AddImageContainer>
      )}
      <SelectImage
        selectedImage={image}
        open={openDialog}
        handleClose={handleToggleDialog}
        handleServerError={handleServerError}
        setImage={setImage}
        title={getT('selectImageTitle')}
        getPictures={getImages}
        text={getT('selectImageText')}
        withDelete
      />
    </>
  );
}

VariantImageUpload.propTypes = {
  image: PropTypes.shape({
    seoTitle: PropTypes.string,
  }),
  getImages: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
};

VariantImageUpload.defaultProps = {
  image: null,
};

export default VariantImageUpload;
