import { camelCase, snakeCase, uniqBy } from 'lodash';

import { PlanogramObjectsResponse } from 'types';

export interface MenuItemType {
  id: string;
  label: string;
}

export interface PlanogramObjectData {
  planogramName: string;
  id: number;
  objects: PlanogramObjectsResponse;
}

export const CATEGORY_OPTIONS = [
  { id: 'path', label: 'Animation Path' },
  { id: 'cluster', label: 'Cluster' },
  { id: 'product', label: 'Product' },
  { id: 'picture', label: 'Image' },
  { id: 'textArea', label: 'Text' },
  { id: 'shape', label: 'Shape' },
  { id: 'line', label: 'Curve' },
  { id: 'video', label: 'Video' },
];

export const formatNavigationValue = (planogramName: string, category: string, itemId: string, withAction?: string) =>
  `${planogramName}/${snakeCase(category)}/${itemId}${withAction}`;

export const getPlanogramMenuItems = (planogramObjectData: PlanogramObjectData[]): MenuItemType[] =>
  planogramObjectData
    .map(planogram => ({
      id: planogram.id.toString(),
      label: planogram.planogramName,
    }))
    .reverse();

export const getCategoryOptions = (planogramObjectData: PlanogramObjectData[], planogramId: string): MenuItemType[] =>
  CATEGORY_OPTIONS.filter(categoryOption =>
    getObjectMenuItems(
      categoryOption.id,
      planogramId,
      planogramObjectData
    ).length > 0,
  );

export const getObjectMenuItems = (
  category: string,
  planogramId: string,
  planogramObjectData: PlanogramObjectData[],
): MenuItemType[] => {
  const planogram = planogramObjectData.find(planogram => planogram.id.toString() === planogramId);

  if (!planogram) return [];

  let objects: MenuItemType[] = [];

  switch (category) {
    case 'video':
      objects = planogram.objects.videos.map(video => ({
        id: video.id,
        label: video.name,
      }));
      break;
    case 'textArea':
      objects = planogram.objects.textAreas.map(textArea => ({
        id: textArea.id,
        label: textArea.name,
      }));
      break;
    case 'cluster':
      objects = planogram.objects.clusters.map(cluster => ({
        id: cluster.id,
        label: cluster.name,
      }));
      break;
    case 'shape':
      objects = planogram.objects.shapes.map(shape => ({
        id: shape.id,
        label: shape.name,
      }));
      break;
    case 'line': {
      objects = planogram.objects.curves.map(curve => ({
        id: curve.id,
        label: curve.name,
      }));
      break;
    }
    case 'path':
      objects = planogram.objects.paths?.map(path => ({
        id: path.name,
        label: path.name,
      })) ?? [];
      break;
    case 'picture':
      objects = planogram.objects.pictures.map(picture => ({
        id: picture.id,
        label: picture.name,
      }));
      break;
    case 'product':
      objects = planogram.objects.products.map(product => ({
        id: product.data.product.identifier,
        label: product.data.product.name,
      }));
      break;
    default:
      objects = [];
  }

  return uniqBy(objects.filter(object => object.label && object.id), 'id');
};

export const getDisplayValue = (value: string, planogramObjectData: PlanogramObjectData[]): string | undefined => {
  if (!value) return undefined;

  const [planogramName, category, itemId] = value.split('/');

  if (category === 'planogram') return planogramName;

  const planogramObjects = planogramObjectData.find(planogram => planogram.planogramName === planogramName);

  if (!planogramObjects) return undefined;

  let label;

  switch (camelCase(category)) {
    case 'video':
      label = planogramObjects.objects.videos.find(video => video.id === itemId)?.name;
      break;
    case 'textArea':
      label = planogramObjects.objects.textAreas.find(textArea => textArea.id === itemId)?.name;
      break;
    case 'cluster':
      label = planogramObjects.objects.clusters.find(cluster => cluster.id === itemId)?.name;
      break;
    case 'shape':
      label = planogramObjects.objects.shapes.find(shape => shape.id === itemId)?.name;
      break;
    case 'line':
      label = planogramObjects.objects.curves.find(curve => curve.id === itemId)?.name;
      break;
    case 'picture':
      label = planogramObjects.objects.pictures.find(picture => picture.id === itemId)?.name;
      break;
    case 'product':
      label = planogramObjects.objects.products
        .find(product => product.data.product.identifier === itemId)?.data.product.name;
      break;
    case 'path':
      label = planogramObjects.objects.paths?.find(path => path.name === itemId)?.name;
      break;
    default:
      label = itemId;
  }

  if (!label) return undefined;

  return `${planogramName}/${label}`;
};
