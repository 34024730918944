import React from 'react';

import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { CardComponent, ItemWithData } from 'components';

import { ErrorOutlineIcon } from '../style';

export function ErrorVersion({ planogramVersion }) {
  const renderTitle = () => (
    <>
      <ErrorOutlineIcon /> <span>Error</span>
    </>
  );

  return (
    <>
      {planogramVersion && (
        <CardComponent title={renderTitle()} sm={12} wrapperClassName="error">
          <Box style={{ marginBottom: '12px' }}>
            <Typography variant="h2">
              {`Some error has occured. ${planogramVersion.step}. Please, try again.`}
            </Typography>
          </Box>
          <ItemWithData title="Started at" data={planogramVersion.startedAt} />
        </CardComponent>
      )}
    </>
  );
}

ErrorVersion.propTypes = {
  planogramVersion: PropTypes.shape({
    status: PropTypes.string,
    step: PropTypes.string,
    startedAt: PropTypes.string,
    updatedAt: PropTypes.string,
    editor: PropTypes.string,
    version: PropTypes.number,
  }),
};

ErrorVersion.defaultProps = {
  planogramVersion: null,
};
