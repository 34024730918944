import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getInlineFontStyles } from 'utils';

import { CustomFontWrapper } from '../FontComponent/CustomFontWrapper/CustomFontWrapper';
import { fontSettingsProp } from '../propTypesConsts';

import { Image } from '../style';
import { ButtonPreviewBox, ButtonContainer } from './style';

export function BuyButtonPreview({ button, isCustomButton }) {
  const getStyles = () => {
    const styles = getInlineFontStyles(button.buttonFont);

    if (button?.color?.hex) {
      styles.backgroundColor = button.color.hex;
    }

    if (button.useBorder && button.borderColor.hex) {
      styles.border = `${button.borderWidth}px solid ${button.borderColor.hex}`;
    }

    return styles;
  };

  if (!button || isEmpty(button)) {
    return null;
  }

  return (
    <CustomFontWrapper font={button.buttonFont?.font}>
      <ButtonContainer>
        <ButtonPreviewBox style={getStyles()} className={button.buttonType}>
          {button.iconUrl && <Image src={button.iconUrl} />}
          {isCustomButton && <span>{button.title}</span>}
        </ButtonPreviewBox>
      </ButtonContainer>
    </CustomFontWrapper>
  );
}

BuyButtonPreview.propTypes = {
  isCustomButton: PropTypes.bool,
  button: PropTypes.shape({
    color: PropTypes.shape({ hex: PropTypes.string }),
    borderColor: PropTypes.shape({ hex: PropTypes.string }),
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconUrl: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({ languageCode: PropTypes.string, value: PropTypes.string })),
    ]),
    buttonType: PropTypes.string,
    useBorder: PropTypes.bool,
    buttonFont: fontSettingsProp,
  }),
};

BuyButtonPreview.defaultProps = {
  isCustomButton: false,
  button: null,
};
