import axios from 'axios';

import { getCurrentHostAndProtocol } from 'utils';

class VersionModel {
  link = '/version.txt';

  getVersionBE = (): Promise<string> =>
    axios.get<string>(`${getCurrentHostAndProtocol()}${this.link}`).then(({ data }) => data);
}

export const versionModel = new VersionModel();
