export const CAMERA_POSITION_SETTINGS = {
  values: {
    min: -1,
    max: 1,
    optimalMin: -0.27,
    optimalMax: 0.27,
    middle: 0,
  },
  step: 0.01,
  markStep: 0.1,
  blockHeight: 300,
};
