import React, { useState, useEffect } from 'react';

import { Grid, Typography, Box, Button } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { isEqual, set, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CardComponent, ColorPicker, FontSettingsControlWrapper, LanguageCode, SettingsForm } from 'components';
import { CookiesTablePreviewDialog, FontSettingsEditDialog } from 'components/Dialogs';
import { useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useSubmittingState } from 'hooks';
import { cookiesTablesModel } from 'models';
import { getFontSettingsWithNums, getFontSettingsWithPx } from 'utils';

import { assignmentMap, FORM_ID, defaultCookiesSettings } from './constants';

import { PreviewButton } from './style';

export function CookiesTable({ pageLanguageTabValue, setSuccessActionMessages, cookiesDataSettings }) {
  const [initialCookiesSettings, setInitialCookiesSettings] = useState(defaultCookiesSettings);
  const [cookiesSettings, setCookiesSettings] = useState(defaultCookiesSettings);
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const [fontSettingsIndexMap, setFontSettingsIndexMap] = useState({});
  const { withPageProgressHandler } = useBusyProgressActions();
  const { t } = useTranslation();

  const { handleServerError } = useServerErrorActions();
  const {
    currentUser: {
      attributes: { clientLanguages },
    },
  } = useAuthState();

  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  const currentLanguageCode = clientLanguages[pageLanguageTabValue];

  useEffect(() => {
    getCookiesTableData();
  }, []);

  const getCookiesTableData = withPageProgressHandler(() =>
    cookiesTablesModel.getData().then(setNewSettings).catch(handleServerError),
  );

  const setNewSettings = data => {
    const settings = {
      ...data,
      cookiesTableFonts: data.cookiesTableFonts.map((font, index) => {
        setFontSettingsIndexMap(prev => ({ ...prev, [font.assignment]: index }));
        return getFontSettingsWithNums(font);
      }),
    };

    setInitialCookiesSettings(cloneDeep(settings));
    setCookiesSettings(settings);
  };

  const togglePreview = () => setOpenPreview(prev => !prev);
  const handleChangeColor =
    itemName =>
    ({ hex }) =>
      setCookiesSettings(prev => ({ ...prev, [itemName]: hex }));
  const closeFontSettingsDialog = () => setFontSettingsEdit(null);
  const handleFontSettingsSave = settings => {
    const index = fontSettingsIndexMap[settings.assignment];

    closeFontSettingsDialog();
    setCookiesSettings(prev => ({
      ...prev,
      ...set(prev, ['cookiesTableFonts', [index]], settings),
    }));
  };

  const handleSubmit = withHandlingSubmittingState(async ({ target: { id } }) => {
    try {
      if (id !== FORM_ID) {
        return;
      }
      const cookiesTableFonts = cookiesSettings.cookiesTableFonts.map(getFontSettingsWithPx);
      const data = await cookiesTablesModel.putData({ ...cookiesSettings, cookiesTableFonts });

      setNewSettings(data.cookiesTable);
      setSuccessActionMessages(data.success);
    } catch (err) {
      handleServerError(err);
    }
  });

  const isSaveButtonVisible = !isSubmitting && !isEqual(initialCookiesSettings, cookiesSettings);

  const getFontsFromSettings = key => cookiesSettings.cookiesTableFonts[fontSettingsIndexMap[key]];

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={handleSubmit} id={FORM_ID}>
      <CardComponent sm={8} title={t('pages.settings.cookiesSettings.cookiesTable.title')} isLoading={isSubmitting}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Grid item sm={12}>
              <Box my={3}>
                <Typography variant="h4">Background color</Typography>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <ColorPicker
                setColor={handleChangeColor('backgroundColor')}
                color={{ hex: cookiesSettings.backgroundColor }}
              />
            </Grid>
            <Grid item sm={12}>
              <Box mt={6} mb={3}>
                <Grid container>
                  <FontSettingsControlWrapper
                    wrapperClassName="mb-auto mt-auto"
                    fontSettings={getFontsFromSettings(assignmentMap.cookiesTableTitle)}
                    handleSettingsEdit={setFontSettingsEdit}
                  >
                    <Grid item style={{ margin: 'auto 0' }}>
                      <Typography variant="h4">Table title</Typography>
                    </Grid>
                  </FontSettingsControlWrapper>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box my={3}>
                <Grid container>
                  <FontSettingsControlWrapper
                    wrapperClassName="mb-auto mt-auto"
                    fontSettings={getFontsFromSettings(assignmentMap.cookiesTableColHeaders)}
                    handleSettingsEdit={setFontSettingsEdit}
                  >
                    <Grid item style={{ margin: 'auto 0' }}>
                      <Typography variant="h4">Table column headers</Typography>
                    </Grid>
                  </FontSettingsControlWrapper>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box my={3}>
                <Grid container>
                  <FontSettingsControlWrapper
                    wrapperClassName="mb-auto mt-auto"
                    fontSettings={getFontsFromSettings(assignmentMap.cookiesTableText)}
                    handleSettingsEdit={setFontSettingsEdit}
                  >
                    <Grid item style={{ margin: 'auto 0' }}>
                      <Typography variant="h4">Table text</Typography>
                    </Grid>
                  </FontSettingsControlWrapper>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <Box my={3}>
              <Typography variant="h4">Preview</Typography>
            </Box>
            <Box mb={3}>
              <LanguageCode code={currentLanguageCode} />
            </Box>
            <PreviewButton
              onClick={togglePreview}
            >
              <VisibilityOutlinedIcon color="secondary" />
              <Typography variant="h4" color="secondary">Click to view table</Typography>
            </PreviewButton>
          </Grid>
        </Grid>
      </CardComponent>
      <FontSettingsEditDialog
        settings={fontSettingsEdit}
        handleClose={closeFontSettingsDialog}
        handleSet={handleFontSettingsSave}
      />
      <CookiesTablePreviewDialog
        openDialog={openPreview}
        backgroundColor={cookiesSettings.backgroundColor}
        handleClose={togglePreview}
        currentLanguageCode={currentLanguageCode}
        pageLanguageTabValue={pageLanguageTabValue}
        cookiesSettings={cookiesDataSettings}
        titleFontSettings={getFontsFromSettings(assignmentMap.cookiesTableTitle)}
        colHeadersFontSettings={getFontsFromSettings(assignmentMap.cookiesTableColHeaders)}
        tableTextFontSettings={getFontsFromSettings(assignmentMap.cookiesTableText)}
        fontColor={getFontsFromSettings(assignmentMap.cookiesTableText)?.color}
      />
    </SettingsForm>
  );
}

CookiesTable.propTypes = {
  pageLanguageTabValue: PropTypes.number.isRequired,
  setSuccessActionMessages: PropTypes.func.isRequired,
};
