import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TableComponent } from 'components';
import { AddNewClientDialog, ConfirmationDialog } from 'components/Dialogs';
import { DEFAULT_TABLE_PARAMS } from 'components/TableComponent/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import PageWithHeader from 'layouts/PageWithHeader/PageWithHeader';
import { clientsModel } from 'models';
import { ROUTES } from 'routes/constants';
import { Client, ClientExtendedInfo, ClientInfo } from 'types';
import { ActionType } from 'types/enums';
import { TableParams } from 'types/other';
import TEST_IDS from 'constants/testIds';

function ManageClients() {
  const history = useHistory();
  const { t } = useTranslation();
  const { withPageProgressHandler } = useBusyProgressActions();
  const { handleServerError, resetServerErrors } = useServerErrorActions();

  const [clients, setClients] = useState<Client[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<ClientInfo>();
  const [isClientDataSubmitting, setIsClientDataSubmitting] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({ ...DEFAULT_TABLE_PARAMS });
  const [paginationItemsCount, setPaginationItemsCount] = useState(0);

  useEffect(() => {
    getClients();
  }, [tableParams]);

  const getClients = withPageProgressHandler(() => {
    const { search, ...rest } = tableParams;

    return clientsModel
      .getClients({ ...rest, name: search })
      .then(data => {
        setClients(data.clients);
        setPaginationItemsCount(data.count);
      })
      .catch(handleServerError);
  });

  const handleDelete = withPageProgressHandler(({ id }) =>
    clientsModel
      .deleteClient(id)
      .then(getClients)
      .then(() => setClientToDelete(undefined))
      .catch(handleServerError),
  );

  const toggleDialog = () => setOpenDialog(prevState => !prevState);

  const handleSubmit = async (client: ClientExtendedInfo, ignoreWarnings = false) => {
    setIsClientDataSubmitting(true);

    return clientsModel
      .addClient({ ignoreWarnings, ...client })
      .then(() => {
        setTableParams({ ...tableParams, page: 0 });
        toggleDialog();
      })
      .finally(() => setIsClientDataSubmitting(false));
  };

  const handleRedirect = (client: Client) => history.push(`${ROUTES.editClientNamespace}${client.id}`);

  const tableData = useMemo(
    () => ({
      headers: [
        t('pages.clients.name'),
        t('pages.clients.legalName'),
        t('pages.clients.domainName'),
        t('pages.clients.admins'),
        t('pages.clients.updated'),
      ],
      rows: clients.map(client => ({
        id: client.id,
        cells: [client.name, client.fullName, client.domain, client.adminsQuantity, client.updatedAt],
        onClick: () => handleRedirect(client),
        actions: [
          {
            type: ActionType.EDIT,
            onClick: () => handleRedirect(client),
            testId: TEST_IDS.manageClients.list.buttons.edit,
          },
          {
            type: ActionType.DELETE,
            onClick: () => setClientToDelete(client),
            testId: TEST_IDS.manageClients.list.buttons.delete,
          },
        ],
      })),
    }),
    [clients],
  );

  return (
    <PageWithHeader
      tabTitle={t('pages.clients.tabTitle')}
      headerText={t('pages.clients.title', { count: paginationItemsCount })}
      button={{
        text: t('pages.clients.addNewClient'),
        onClick: () => toggleDialog(),
      }}
    >
      <TableComponent
        data={tableData}
        count={paginationItemsCount ?? 0}
        tableParams={tableParams}
        onParamsChange={params => setTableParams(prev => ({ ...prev, ...params }))}
        enableSearch
      />
      {openDialog && (
        <AddNewClientDialog
          open
          handleSubmit={handleSubmit}
          onClose={toggleDialog}
          resetServerErrors={resetServerErrors}
          isClientDataSubmitting={isClientDataSubmitting}
        />
      )}
      {clientToDelete && (
        <ConfirmationDialog
          open
          message={t('pages.clients.deleteConfirmation')}
          handleYes={() => handleDelete(clientToDelete)}
          handleNo={() => setClientToDelete(undefined)}
        />
      )}
    </PageWithHeader>
  );
}

export default ManageClients;
