import React, { useEffect, useState } from 'react';

import { set } from 'lodash';
import { compose } from 'lodash/fp';

import { PageHeader, PageHeaderNav, EcommerceOverlaySettingsForm, EcommerceUserProfileForm } from 'components';
import { ECOMMERCE_OVERLAYS_SETTINGS_TYPES } from 'components/ecommerce/constants';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { ecommerceOverlayModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import {
  withPageLanguage,
  withPageLanguageDefaultProps,
  withPageLanguagePropTypes,
  withSuccessActionsDialog,
  withSuccessActionsDialogProps,
} from 'utils/enhancers';

function EcommerceOverlays({ children, pageLanguageTabValue, setSuccessActionMessages }) {
  const [overlaysSettings, setOverlaysSettings] = useState([]);
  const [typeIndexMap, setTypeIndexMap] = useState([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const getT = getTWithPathKey('pages.settings.ecommerceOverlays');

  useEffect(() => {
    getEcommerceOverlaySettings();
  }, []);

  const getEcommerceOverlaySettings = withPageProgressHandler(() =>
    ecommerceOverlayModel
      .getSettings()
      .then(settings => {
        setTypeIndexMap(settings.reduce((acc, item, index) => ({ ...acc, [item.overlayType]: index }), {}));
        setOverlaysSettings(settings);
      })
      .catch(handleServerError)
  );

  const handleSaveSettings = index => newSettings =>
    ecommerceOverlayModel
      .postSettings(newSettings)
      .then(({ ecommerceOverlaySetting, success }) => {
        setOverlaysSettings(prev => [...set(prev, [index], ecommerceOverlaySetting)]);
        setSuccessActionMessages(success);
      })
      .catch(handleServerError);

  const getIndexByMap = type => typeIndexMap[type];

  return (
    <>
      <PageHeader title={getT('title')} backLink={ROUTES.settings}>
        <PageHeaderNav />
      </PageHeader>
      {children}
      <EcommerceUserProfileForm
        buttonsSettings={overlaysSettings[getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.buttons)]}
        onSave={handleSaveSettings(getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.buttons))}
      />
      <EcommerceOverlaySettingsForm
        isLanguageCodeVisible
        pageLanguageTabValue={pageLanguageTabValue}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp}
        overlaySettings={overlaysSettings[getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp)]}
        onSave={handleSaveSettings(getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.pdp))}
      />
      <EcommerceOverlaySettingsForm
        isLanguageCodeVisible
        pageLanguageTabValue={pageLanguageTabValue}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn}
        overlaySettings={overlaysSettings[getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn)]}
        onSave={handleSaveSettings(getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.signIn))}
      />
      <EcommerceOverlaySettingsForm
        isLanguageCodeVisible
        pageLanguageTabValue={pageLanguageTabValue}
        overlayType={ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart}
        overlaySettings={overlaysSettings[getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart)]}
        onSave={handleSaveSettings(getIndexByMap(ECOMMERCE_OVERLAYS_SETTINGS_TYPES.shoppingCart))}
      />
    </>
  );
}

EcommerceOverlays.propTypes = {
  ...withPageLanguagePropTypes,
  ...withSuccessActionsDialogProps,
};

EcommerceOverlays.defaultProps = {
  ...withPageLanguageDefaultProps,
  ...withSuccessActionsDialogProps,
};

export default compose(withPageLanguage, withSuccessActionsDialog)(EcommerceOverlays);
