import React, { useState } from 'react';

import { Slider, Grid, InputAdornment } from '@material-ui/core';
import { debounce } from 'lodash';

import { TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { getMinMaxValidatedValue } from 'utils';

interface InputSliderProps {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  suffix?: string;
}

const DEBOUNCE_TIME = 200;

const InputSlider = ({ value, min, max, step, onChange, suffix }: InputSliderProps) => {
  const [input, setInput] = useState<string>(value.toString());

  const handleChange = (newValue: string) => {
    if (newValue === '') {
      setInput(newValue);
      return;
    }

    const strippedValue = newValue.replace(suffix ?? '', '');

    const validatedValue = getMinMaxValidatedValue(Number(strippedValue), { min, max });

    setInput(validatedValue.toString());
    debounce(() => onChange(validatedValue), DEBOUNCE_TIME)();
  };

  const handleBlur = () => {
    if (input === '') setInput(value.toString());
  };

  return (
    <Grid container spacing={2} alignItems="center" style={{ marginBottom: '2px' }}>
      <Grid item xs style={{ paddingRight: '20px' }}>
        <Slider
          value={Number(input)}
          onChange={(event, newValue) => handleChange(newValue.toString())}
          style={{ width: '100%', padding: '0' }}
          step={step ?? 1}
          min={min ?? 0}
          max={max ?? 100}
        />
      </Grid>
      <Grid item>
        <TextValidatorComponent
          disableMinHeight
          value={input}
          validators={[VALIDATORS_MAP.required, VALIDATORS_MAP.maxNumber(max), VALIDATORS_MAP.minNumber(min)]}
          label="Enter value"
          fieldName="value"
          handleChange={({ value }: { value: string }) => handleChange(value)}
          onBlur={handleBlur}
          fullWidth={false}
          InputProps={{
            inputProps: {
              step,
              min,
              max,
              type: 'number',
            },
            endAdornment: <InputAdornment position="start">{suffix}</InputAdornment>,
          }}
          style={{ width: '150px' }}
        />
      </Grid>
    </Grid>
  );
};

export default InputSlider;
