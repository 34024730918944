import React, { ReactChild } from 'react';

import { Box } from '@material-ui/core';

import { TableRow, TableRowTitle, Table, TableHeader } from './style';

export interface GuideListProps {
  list: Array<{
    title: string;
    children?: ReactChild;
  }>;
  title?: string;
  startCount?: number;
}

export const GuideList = ({ list, title = '', startCount = 1 }: GuideListProps) => {
  const getCountIndex = (index: number): string =>
    (startCount + index).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    });

  return (
    <Table>
      {title && <TableHeader>{title}</TableHeader>}
      {list.map((listItem, index) => {
        const countIndex = getCountIndex(index);

        return (
          <TableRow key={countIndex} data-count-index={countIndex}>
            <TableRowTitle>{listItem.title}</TableRowTitle>
            {listItem.children && <Box>{listItem.children}</Box>}
          </TableRow>
        );
      })}
    </Table>
  );
};
