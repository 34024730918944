import { styled, Typography as MuiTypography, Card as MuiCard } from '@material-ui/core';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { TextValidatorComponent } from 'components/FormComponents';
import { COLORS } from 'theme/constants';
import { ThemeMode } from 'types/enums';

export const StyledButtonComponent = styled(ButtonComponent)(({ theme }) => ({
  backgroundColor: COLORS.cozmosBlue,
  color: COLORS.white,

  height: '35px',
  '& svg': {
    opacity: '1.0',
    marginRight: '10px',
    width: '20px',
    height: '20px',
  },

  '&.Mui-disabled': {
    backgroundColor: COLORS.cozmosPurpleLight,
    color: COLORS.cozmosPurple4,
    filter: 'grayscale(100%)',
    opacity: '0.5',
  },

  '&.MuiButton-outlined': {
    color: COLORS.cozmosBlue,
    backgroundColor: COLORS.white,
  },
}));

export const MobileInfoText = styled('span')({
  fontSize: '14px',
  color: COLORS.cozmosPurple2,
});

export const MobileContainer = styled('div')({
  maxWidth: '340px',
  margin: '20px',
  padding: '10px',
});

export const SmallInfoText = styled('span')(({ theme }) => ({
  whiteSpace: 'pre-line',
  fontSize: '12px',
}));

export const TermsAndConditionsText = styled('span')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.auth.formText,

  '& a': {
    color: theme.palette.auth.formLink,
  },
}));

export const RedirectText = styled(MuiTypography)({
  fontSize: '12px',
  color: COLORS.greyText,
  fontWeight: 600,
  textAlign: 'center',
});

export const AuthCard = styled(MuiCard)(({ theme }) => ({
  width: '522px',
  borderRadius: '8px',
  padding: '24px',
  position: 'relative',
  lineHeight: 'normal',
  backgroundColor: theme.palette.auth.formBackground,
  color: theme.palette.auth.formText,

  '& .MuiCheckbox-root': {
    color: theme.palette.auth.formText,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

export const ErrorText = styled(MuiTypography)({
  margin: '4px 14px 0',
  color: COLORS.red,
  padding: 0,
  fontSize: '12px',
  fontWeight: 500,
});

export const FormLink = styled(ButtonComponent)(({ theme }) => ({
  color: theme.palette.auth.formLink,
  padding: '0 5px',
  minWidth: 'auto',
}));

export const TextValidator = styled(TextValidatorComponent)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: COLORS.cozmosPurple2,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiIconButton-root': {
    color: COLORS.cozmosPurple2,
  },

  ...(theme.palette.mode === ThemeMode.DARK && {
    colorScheme: 'dark',

    '& .MuiFormHelperText-root': {
      color: COLORS.cozmosPurple2,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: COLORS.redError,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: COLORS.white,
    },
    '& .MuiOutlinedInput-root': {
      color: COLORS.white,
      backgroundColor: COLORS.cozmosPurple5,
      '&.Mui-disabled': {
        color: `${COLORS.white}70`,
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
      },
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-error fieldset': {
        borderColor: COLORS.redError,
      },
    },
  }),
}));
