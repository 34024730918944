export const PICTURE_TYPES_MAP = {
  all: 'all',
  uploaded: 'uploaded',
  synced: 'synced',
  variants: 'variants',
};

export const PICTURES_TYPES = Object.values(PICTURE_TYPES_MAP);

export const PICTURE_TYPES_FILTER = {
  uploaded: ({ fromShopify }) => !fromShopify,
  synced: ({ fromShopify }) => !!fromShopify,
  variants: ({ hasVariant }) => !!hasVariant,
};

export const UPDATE_VARIANT_IMAGES_STATUS = {
  upload: 'upload',
  remove: 'remove',
};
