import React from 'react';

import classnames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-material-ui-form-validator';

import { FormControl } from '../style';

export class FieldValidator extends ValidatorComponent {
  getErrorsText() {
    const { isValid } = this.state;
    const { serverError } = this.props;

    return !isValid || !isEmpty(serverError) ? (!isValid && this.getErrorMessage()) || serverError : null;
  }

  render() {
    const { children } = this.props;
    const errorText = this.getErrorsText();

    return (
      <FormControl fullWidth={this.props.fullWidth} className={classnames({
        'no-min-height': this.props.disableMinHeight,
        'error': !!errorText,
      })}>
        {children}
        {errorText && <div className="MuiFormHelperText-root">{errorText}</div>}
      </FormControl>
    );
  }
}

FieldValidator.propTypes = {
  serverError: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.array]).isRequired,
};

FieldValidator.defaultProps = {
  fullWidth: true,
  validatorListener: () => {},
};
