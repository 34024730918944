import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ButtonComponent } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { getTWithPathKey } from 'utils';

export function ProductSaveConfirmation({ handleSubmit }) {
  const [open, setOpen] = useState(false);
  const getT = getTWithPathKey('pages.products.manageProduct.productSaveConfirmation');

  const handleToggleDialog = () => setOpen(prevState => !prevState);

  return (
    <>
      <ButtonComponent text="Save" onClick={handleToggleDialog} className="ml" color="primary" />
      <ConfirmationDialog
        open={open}
        handleYes={handleSubmit}
        handleNo={handleToggleDialog}
        confirmText={getT('confirmText')}
        message={getT('message')}
      />
    </>
  );
}

ProductSaveConfirmation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
