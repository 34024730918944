import React from 'react';

import { Box } from '@material-ui/core';
import { head } from 'lodash';
import PropTypes from 'prop-types';

import { fontSettingsProp } from 'components/propTypesConsts';
import { handleGetTextValue } from 'utils';

import { BUTTON_SHAPES } from '../constants';

import { ProductOverlayModePreview } from './ProductOverlayModePreview';

export function ProductOverlayPreview({ settings, pageLanguageTabValue, currentLanguageCode, isLanguageCodeVisible }) {
  const localizationValue = head(settings.ecommerceButtons)?.title;

  return (
    <>
      <Box mb={3}>
        <ProductOverlayModePreview
          settings={settings}
          value={localizationValue}
          handleGetValue={handleGetTextValue}
          pageLanguageTabValue={pageLanguageTabValue}
          lang={pageLanguageTabValue}
          currentLanguageCode={currentLanguageCode}
          isLanguageCodeVisible={isLanguageCodeVisible}
          multilanguage
        />
      </Box>
      <ProductOverlayModePreview
        settings={settings}
        value={localizationValue}
        handleGetValue={handleGetTextValue}
        pageLanguageTabValue={pageLanguageTabValue}
        lang={pageLanguageTabValue}
        currentLanguageCode={currentLanguageCode}
        isLanguageCodeVisible={isLanguageCodeVisible}
        multilanguage
        disabled
      />
    </>
  );
}

ProductOverlayPreview.propTypes = {
  settings: PropTypes.shape({
    ecommerceFonts: PropTypes.arrayOf(fontSettingsProp),
    ecommerceButtons: PropTypes.arrayOf(PropTypes.shape({})),
    buttonShape: PropTypes.oneOf(Object.values(BUTTON_SHAPES)),
    secondaryColor: PropTypes.shape({ hex: PropTypes.string }),
    backgroundColor: PropTypes.shape({ hex: PropTypes.string }).isRequired,
  }).isRequired,
  pageLanguageTabValue: PropTypes.number.isRequired,
  currentLanguageCode: PropTypes.string.isRequired,
  isLanguageCodeVisible: PropTypes.bool,
};

ProductOverlayPreview.defaultProps = {
  isLanguageCodeVisible: false,
};
