import React, { ChangeEvent } from 'react';

import { Grid, Box, Typography, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';
import { COLORS } from 'theme/constants';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import { CloseIcon, IFrameCaption } from './style';

export interface IFrameColorComponentProps {
  primaryColorKey: string;
  primaryColorValue: string;
  secondaryColorKey: string;
  secondaryColorValue: string;
  onColorChange: (props: any) => any;
  useTitle?: boolean;
  subTitle?: string;
}

export const IFrameColorComponent = ({
  primaryColorKey,
  primaryColorValue,
  secondaryColorKey,
  secondaryColorValue,
  onColorChange,
  useTitle = true,
  subTitle = ''
}: IFrameColorComponentProps) => {
  const { t } = useTranslation();

  const handleCheckedColorChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    onColorChange(secondaryColorKey)(checked ? { hex: '' } : { hex: COLORS.white });

  return (
    <>
      <Box mt={6} mb={4}>
        {useTitle && (
          <>
            <Typography variant="h2" style={{ textTransform: 'uppercase' }} gutterBottom>
              {t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.IframeSettings.title')}
            </Typography>
            <IFrameCaption>{t('pages.planograms.ManagePlanogramSettings.CustomOverlaySettings.IframeSettings.hint')}</IFrameCaption>
          </>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={7}>
          {subTitle && (
            <Box mb={4}>
              <Typography variant="h4" display="inline">{subTitle}</Typography>
            </Box>
          )}
          <Box mt={2} display="flex" alignItems="center">
            <ColorPicker
              setColor={onColorChange(primaryColorKey)}
              color={{ hex: primaryColorValue }}
            />
            <Box ml={2}>
              <Typography variant="caption">{t('components.iframeColorComponent.primaryColorHelperText')}</Typography>
            </Box>
          </Box>
          <Box mt={2} display="flex" alignItems="center">
            <ColorPicker
              setColor={onColorChange(secondaryColorKey)}
              color={{ hex: secondaryColorValue }}
            />
            <Box ml={2}>
              <Typography variant="caption">{t('components.iframeColorComponent.secondaryColorHelpText')}</Typography>
            </Box>
          </Box>
          <Box ml={7}>
            <Typography variant="caption">
              <Checkbox color="primary" checked={!secondaryColorValue} onChange={handleCheckedColorChange} />
              <span>{t('components.iframeColorComponent.checkboxHelpText')}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Grid item sm={12}>
            <Box mb={4}>
              <Typography variant="h4" display="inline">
                {t('dialogs.fontSettingsEdit.preview')}
              </Typography>
            </Box>
          </Grid>
          <ButtonPreviewWrapper>
            <CloseIcon className="btn-wrap regular" style={{
              borderColor: primaryColorValue,
              backgroundColor: secondaryColorValue
            }}>
              <span style={{ color: primaryColorValue }} />
            </CloseIcon>
          </ButtonPreviewWrapper>
        </Grid>
      </Grid>
    </>
  );
}
