import React, { memo, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { CardComponent, DotsComponent } from 'components';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { NewTag } from 'components/Dialogs';
import { tagsModel } from 'models';
import { withPagination, withPaginationPropTypes } from 'utils/enhancers';

import { Button, Box, Typography, AddCircleOutlineIcon } from './style';

export function TagsListComponent({
  handleSelect,
  activeTagId,
  setShouldUpdateTags,
  handleServerError,
  resetServerErrors,
  shouldUpdateTags,
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
  addImageToTag,
}) {
  const [openCreateTagDialog, setOpenCreateTagDialog] = useState(false);
  const [onDragTagId, setOnDragTagId] = useState(null);
  const { withPageProgressHandler } = useBusyProgressActions();

  const [tags, setTags] = useState([]);

  useEffect(() => {
    getTags();
  }, [paginationParams]);

  useEffect(() => {
    if (shouldUpdateTags) {
      getTags();
      setShouldUpdateTags(false);
      setOnDragTagId(null);
    }
  }, [shouldUpdateTags]);

  const getTags = withPageProgressHandler(() =>
    tagsModel
      .getTagsWithOrder({ ...paginationParams })
      .then(({ tags, tagsCount }) => {
        setTags(tags);
        setPaginationItemsCount(tagsCount);
      })
      .catch(handleServerError)
  );

  const handleCreate = () => setOpenCreateTagDialog(true);

  const afterAction = tag => {
    if (!isEmpty(tag) && openCreateTagDialog) {
      resetPaginationPage();
    }
    setOpenCreateTagDialog(false);
    resetServerErrors();
    getTags();
  };

  const customDrop = tagId => event => {
    const pictureId = event.dataTransfer.getData('pictureId');

    addImageToTag(tagId, pictureId);
  };

  const handleDragOver = tagId => event => {
    event.preventDefault();
    setOnDragTagId(tagId);
  };

  const handleDrugEnd = () => () => setOnDragTagId(null);

  const setColorClassName = tagId => (onDragTagId === tagId ? 'focus' : '');

  const getActiveClass = condition => (condition ? 'active' : '');

  const renderTags = () =>
    tags.map(tag => {
      const { id, picturesCount, name, color } = tag;

      return (
        <Box className="tags-item" key={id}>
          <Button
            className={`${getActiveClass(id === activeTagId)} ${setColorClassName(id)}`}
            onDragLeave={handleDrugEnd(id)}
            onDragOver={handleDragOver(id)}
            onDrop={customDrop(id)}
            onClick={handleSelect(tag)}
            variant="contained"
            size="small"
            mr={2}
            fullWidth
          >
            <Typography className="count">{picturesCount}</Typography>
            <Typography className="name">{name}</Typography>
            <DotsComponent dotsColors={[color]} />
          </Button>
        </Box>
      );
    });

  return (
    <CardComponent sm={12}>
      <Box className="tags-wrapper">
        <Box className="tags-item">
          <Button
            className={getActiveClass(!activeTagId)}
            onClick={handleSelect({ id: null })}
            variant="contained"
            size="small"
            id={0}
            mr={2}
            fullWidth
          >
            <Typography variant="h4">All Images</Typography>
          </Button>
        </Box>
        <Box className="tags-item">
          <Button className="flex" onClick={handleCreate} variant="contained" size="small" id="tags" mr={2} fullWidth>
            <Typography variant="h4">Tags</Typography>
            <AddCircleOutlineIcon />
          </Button>
        </Box>
        {renderTags()}
        {openCreateTagDialog && <NewTag afterAction={afterAction} handleServerError={handleServerError} />}
      </Box>
    </CardComponent>
  );
}

TagsListComponent.propTypes = {
  ...withPaginationPropTypes,
  handleSelect: PropTypes.func.isRequired,
  activeTagId: PropTypes.number,
  setShouldUpdateTags: PropTypes.func.isRequired,
  handleServerError: PropTypes.func.isRequired,
  resetServerErrors: PropTypes.func.isRequired,
  shouldUpdateTags: PropTypes.bool.isRequired,
  addImageToTag: PropTypes.func.isRequired,
};

TagsListComponent.defaultProps = {
  activeTagId: 0,
};

export const TagsList = memo(withPagination(TagsListComponent));
