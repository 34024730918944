import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { authModel } from 'models';
import { invitesModel } from 'models/InvitesModel';
import { ROUTES } from 'routes/constants';
import { getQueryParams } from 'utils';

import { AuthCard, SmallInfoText, StyledButtonComponent } from '../style';

import { ReactComponent as GoogleIcon } from 'assets/img/google.svg';

const InviteSelectorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { token } = getQueryParams() as { token: string };

  if (token) invitesModel.verifyToken({ token }).catch(() => history.push(ROUTES.linkExpired));
  else history.push(ROUTES.home);

  const onSetupWithEmail = () =>
    history.push({
      pathname: ROUTES.signUpWithInvite,
      search: window.location.search,
    });

  const onSetupWithGoogle = () => authModel.redirectToGoogleAuth(authModel.googleFlow.invite, token);

  return (
    <AuthCard>
      <Helmet title={t('pages.auth.signUpWithInvitePage.tabTitle')} />
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <Typography variant="h2">{t('pages.auth.signUpWithInvitePage.welcome')}</Typography>
        </Grid>
        <Grid item>
          <SmallInfoText>{t('pages.auth.signUpWithInvitePage.welcomeSubtext')}</SmallInfoText>
        </Grid>
        <Grid item container spacing={6}>
          <Grid item xs={12} sm>
            <StyledButtonComponent
              fullWidth
              color="primary"
              text={t('pages.auth.signUpWithInvitePage.emailSetupBtn')}
              variant="contained"
              onClick={onSetupWithEmail}
            />
          </Grid>
          <Grid item xs={12} sm>
            <StyledButtonComponent
              fullWidth
              color="primary"
              text={t('pages.auth.signUpWithInvitePage.googleSetupBtn')}
              variant="outlined"
              Icon={GoogleIcon}
              onClick={onSetupWithGoogle}
            />
          </Grid>
        </Grid>
        <Grid item>
          <SmallInfoText>{t('pages.auth.signUpWithInvitePage.disclaimer')}</SmallInfoText>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default InviteSelectorPage;
