import { LocalizationObject } from './common';
import { Image } from './images';
import { TableParams } from './other';

export enum ProductStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export interface Price {
  amount: string;
  currencyCode: string;
}

export interface PriceInClientCurrencies {
  compareAtPrice: any | null;
  price: Price;
}

export interface ProductVariant {
  id: number;
  inventoryQuantity: number;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  picture: Image;
  pictureFromShopify: boolean;
  price: string;
  pricesInClientCurrencies: PriceInClientCurrencies[];
  sku: string;
  updatedAt: string;
}

export type VariantColumns = Record<string, string>;

export interface ProductVideo {
  id?: number;
  videoUrl: string;
}

export interface ProductReleaseCountdown {
  id: number;
  preRelease: boolean;
  releaseTitle: string;
  releaseDescription: string;
  releaseTime: string;
}

export interface Product extends Record<string, any> {
  id: number;
  categoryId: number | null;
  categoryName: string;
  defaultPicture: Image;
  description: LocalizationObject[];
  descriptionLocked?: boolean;
  handle: string | null;
  identifier: string;
  htmlTemplateId: number | null;
  mediaReadonly: boolean;
  name: LocalizationObject[];
  pictures: Image[];
  planogramNames: LocalizationObject[];
  previewPictures: Image[];
  previewPicturesEnabled: boolean;
  productType: string;
  productVideos: ProductVideo[];
  releaseCountdown: ProductReleaseCountdown | null;
  seoTitle: LocalizationObject[];
  seoDesc: LocalizationObject[];
  showProductGalleryView: boolean;
  status: ProductStatus;
  tags: string;
  updatedAt: string;
  variantColumns: VariantColumns;
  variants: ProductVariant[];
  shopifyCreatedAt?: string;
  shopifyPublishedAt?: string;
  shopifyUpdatedAt?: string;
  storeUrl?: string;
  vendor?: string;
  videoFirst: boolean;
}

export interface ProductField {
  name: string;
  readonly: boolean;
  type: string;
  caption?: string;
}

export interface ProductMainField extends ProductField {
  mandatory: boolean;
  multilanguage: boolean;
}

export interface ProductAttributes {
  mainFields: ProductMainField[];
  additionalFields: ProductField[];
}

export interface ProductsGetParams extends TableParams {
  categoryId?: number;
  name?: string;
  identifier?: string;
}

export interface ProductPicture {
  id: number;
  curatorReady: boolean;
  imageName: string;
  path: string;
  seoTitle: string;
  thumbnails: Record<string, string>;
  url: string;
  usedOnPlanogram: boolean;
}

export interface ProductListItem {
  id: number;
  categoryName: string;
  identifier: string;
  name: string;
  pictures: ProductPicture[];
  searchMatchedAttributes: string[];
  updatedAt: string;
}

export interface ProductsListResponse {
  products: ProductListItem[];
  productsCount: number;
}

export interface HtmlTemplateInput {
  name: string;
  identifier: string;
}

export interface HtmlTemplate extends Record<string, any> {
  id: number;
  inputFields: HtmlTemplateInput[];
  role: string;
  roleHumanize: string;
}

export interface HtmlTemplateUpdateResponse {
  htmlTemplate: HtmlTemplate;
  success: string[];
}
