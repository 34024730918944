import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
