import { Button as MuiButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLORS, ICON_SIZE } from 'theme/constants';

export const Button = withStyles({
  root: {
    color: COLORS.greyIcon,
    fontSize: '12px',
    fontWeight: 500,
    '& .MuiButton-startIcon': {
      marginRight: '6px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: ICON_SIZE.regular,
    },
  },
})(MuiButton);
