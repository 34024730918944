import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { takeRight } from 'lodash';
import PropTypes from 'prop-types';

import { ButtonComponent, CollapseBox, DateComponent, LinkComponent } from 'components';
import { getTWithPathKey } from 'utils';

import { PLANOGRAM_VERSION_STATUS, DRAFTED_HISTORY_ITEMS_COUNT } from '../../constants';

export const DraftedHistory = ({ publishedDrafted, handleSetConfirmationParams }) => {
  const [visiblePublishedDrafts, setVisiblePublishedDrafts] = useState([]);
  const getT = getTWithPathKey('pages.planograms.showPlanogram.draftedHistory');

  useEffect(() => {
    setVisiblePublishedDrafts(takeRight(publishedDrafted, DRAFTED_HISTORY_ITEMS_COUNT));
  }, [publishedDrafted]);

  return (
    <CollapseBox openButtonText={getT('openHistory')} closeButtonText={getT('closeHistory')}>
      <Box display="flex" flexDirection="column-reverse">
        {visiblePublishedDrafts.map(({ id, editor, createdAt, publishedVersion, url }) => (
          <Box key={id} mb={2}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={2}>
                <LinkComponent href={url} className="blue" target="_blank">
                  Version {publishedVersion}
                </LinkComponent>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" alignItems="center">
                  <DateComponent value={createdAt} />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h4">{editor}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Box display="flex" width={1} justifyContent="flex-end">
                  <ButtonComponent
                    className="blue"
                    variant="text"
                    onClick={handleSetConfirmationParams(PLANOGRAM_VERSION_STATUS.publishedDrafted, id)}
                    text={getT('cloneToDraft')}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </CollapseBox>
  );
};

DraftedHistory.propTypes = {
  handleSetConfirmationParams: PropTypes.func.isRequired,
  publishedDrafted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      editor: PropTypes.string,
      createdAt: PropTypes.string,
      itemsCount: PropTypes.number,
      url: PropTypes.string,
      temporaryPassword: PropTypes.string,
      temporaryLogin: PropTypes.string,
      imageUrl: PropTypes.string,
      svgData: PropTypes.string,
      validationError: PropTypes.string,
    }),
  ).isRequired,
};
