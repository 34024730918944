import React, { useState } from 'react';

import { TableRow, Typography } from '@material-ui/core';
import { set, reduce } from 'lodash';
import PropTypes from 'prop-types';

import { FontSettingsEditDialog } from 'components/Dialogs';
import { FontSettingsControlWrapper } from 'components/FontComponent/FontSettingsControlWrapper/FontSettingsControlWrapper';
import { OneColumnExpandedTable } from 'components/OneColumnExpandedTable/OneColumnExpandedTable';
import { fontSettingsProp } from 'components/propTypesConsts';
import { getTWithPathKey } from 'utils';

import { Box, TableCell } from '../style';

export function TypographyTable({ ecommerceFonts, setFonts, tableItems }) {
  const [fontSettingsEdit, setFontSettingsEdit] = useState({});

  const getT = getTWithPathKey('components.ecommerce.typography');

  const closeFontSettingsDialog = () => setFontSettingsEdit(undefined);

  const fontSettingsIndexMap = reduce(ecommerceFonts, (acc, font, i) => ({ ...acc, [font.assignment]: i }), {});

  const handleFontSettingsSave = settings => {
    const index = fontSettingsIndexMap[settings.assignment];

    closeFontSettingsDialog();
    setFonts([...set(ecommerceFonts, [[index]], settings)]);
  };

  const renderTableItem = data => (
    <Box display="flex" flexWrap="nowrap" justifyContent="space-between">
      <FontSettingsControlWrapper
        wrapperClassName="mt-auto mb-auto flexShrink-0"
        fontSettings={ecommerceFonts[fontSettingsIndexMap[data.id]]}
        handleSettingsEdit={setFontSettingsEdit}
      >
        <Box flexBasis="70">
          <Box mb={0.5}>
            <Typography variant="h6">{data.title}</Typography>
          </Box>
          <Typography variant="subtitle1" className="information">
            {data.subtitle}
          </Typography>
        </Box>
      </FontSettingsControlWrapper>
    </Box>
  );

  return (
    <>
      <OneColumnExpandedTable title={getT('title')} caption={getT('caption')}>
        {tableItems.map(item => (
          <TableRow hover key={item.id}>
            <TableCell colSpan={2}>{renderTableItem(item)}</TableCell>
          </TableRow>
        ))}
      </OneColumnExpandedTable>
      <FontSettingsEditDialog
        settings={fontSettingsEdit}
        handleClose={closeFontSettingsDialog}
        handleSet={handleFontSettingsSave}
      />
    </>
  );
}

TypographyTable.propTypes = {
  ecommerceFonts: PropTypes.arrayOf(fontSettingsProp).isRequired,
  setFonts: PropTypes.func.isRequired,
  tableItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
};
