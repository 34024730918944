import React, { ComponentType, useState } from 'react';

import { IconButton, InputAdornment, TextFieldProps } from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';

export const withPasswordShowing =
  <T,>(Component: ComponentType<T & TextFieldProps>) =>
  (props: T & TextFieldProps): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const { type, disabled } = props;

    const toggleShowPassword = () => setShowPassword(prev => !prev);

    const handleStopShowPassword = () => setShowPassword(false);

    const setPasswordProps = () =>
      type === 'password'
        ? {
            type: showPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} onBlur={handleStopShowPassword} disabled={Boolean(disabled)}>
                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }
        : {};

    return <Component {...props} {...setPasswordProps()} />;
  };
