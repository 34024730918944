import { styled, Typography as MuiTypography } from '@material-ui/core';

import { CopyToClipboardComponent } from 'components';

export const Typography = styled(MuiTypography)({
  '&.settingsText': {
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  '&.lineTitle': {
    fontSize: '14px',
    fontWeight: 600,
  },

  '&.lineValue': {
    marginLeft: '5px',
    fontSize: '14px',
  },
});

export const CopyToClipboard = styled(CopyToClipboardComponent)({
  '& svg': {
    width: '18px',
    height: '18px',
  },
});
