import { DirectUpload } from '@rails/activestorage';

import config from 'config/Config';
import { getHeadersFromLocalStorage } from 'utils';
import { getCaptchaHeadersFromLocalStorage } from 'utils/captcha';

import { api } from './setupApi';

class ActiveStorageModel {
  loadImageLink = `${config.api_host}/rails/active_storage/direct_uploads`;

  link = '/rails/active_storage/direct_uploads';

  deleteUploads = (id: string) => api.delete(`${this.link}/${id}`);

  createUpload = (file: File, directUploadWillStoreFileWithXHR?: (xhr: XMLHttpRequest) => void): DirectUpload =>
    new DirectUpload(file, this.loadImageLink, {
      directUploadWillCreateBlobWithXHR: xhr => {
        // This will decorate the requests with the access token header, so you won't get a 401
        const headers = {
          'FILE-MODEL': 'BackgroundImage',
          'FILE-ATTR-NAME': 'image',
          ...getCaptchaHeadersFromLocalStorage(),
          ...getHeadersFromLocalStorage(),
        };

        Object.entries(headers).forEach(([key, value]) => xhr.setRequestHeader(key, value));
      },
      directUploadWillStoreFileWithXHR,
    });
}

export const activeStorageModel = new ActiveStorageModel();
