import React, { useEffect, useState } from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { isEmpty, find, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { CustomFontWrapper } from 'components/FontComponent/CustomFontWrapper/CustomFontWrapper';
import { AutocompleteValidatorComponent, TextValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { useFontSettingsActions, useFontSettingsState } from 'contexts/FontSettingsContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { fontsModel } from 'models';
import { COLORS } from 'theme/constants';
import { getTWithPathKey } from 'utils';

import { FONT_STYLES, DEFAULT_SETTINGS, FontSettingsPropTypes } from './constants';
import { FontSettingsEditDialogPreview } from './FontSettingsEditDialogPreview';

import { Dialog, DialogActions, DialogContent, DialogTitle, TextComponentWrapper } from './style';

const FORM_ID = 'fontSettingsForm';

const FONT_SETTINGS = {
  lineHeight: { min: 0, max: 80 },
  letterSpacing: { min: -64, max: 64, step: 0.01 },
  fontSize: { min: 0, max: 80 },
};

export function FontSettingsEditDialog({ settings: initialSettings, handleSet, handleClose }) {
  const { handleServerError } = useServerErrorActions();
  const { fontList, savedSettings } = useFontSettingsState();
  const { saveFontSettings, saveFontList } = useFontSettingsActions();
  const [currentSettings, setCurrentSettings] = useState({});
  const fontStylesList = FONT_STYLES.map(s => ({ id: s }));

  const getTComponent = getTWithPathKey('dialogs.fontSettingsEdit');
  const getTCommon = getTWithPathKey('common.buttons');

  useEffect(() => {
    if (!isEmpty(initialSettings)) {
      setCurrentSettings({ ...DEFAULT_SETTINGS, ...initialSettings });
    }
    if (!fontList.length) {
      fontsModel
        .getFonts({ perPage: 'all' })
        .then(({ fonts }) => fonts)
        .then(saveFontList)
        .catch(handleServerError);
    }
  }, [initialSettings]);

  useEffect(() => {
    const font = getSelectedFont();

    if (font && !isEqual(font, currentSettings.font)) {
      handleChange({ font });
    }
  }, [currentSettings.fontId]);

  const handleChange = item => setCurrentSettings(prevState => ({ ...prevState, ...item }));

  const getSelectedFont = () => find(fontList, ['id', currentSettings.fontId]);

  const copyFontStyle = () => saveFontSettings(currentSettings);

  const pasteFontStyle = () => setCurrentSettings({ ...currentSettings, ...savedSettings });

  const setColor = e => handleChange({ color: e.hex });

  const onSubmit = ({ target: { id } }) => {
    if (id === FORM_ID) {
      handleSet(currentSettings);
    }
  };

  return (
    <Dialog fullWidth open={!isEmpty(initialSettings)} onClose={handleClose}>
      <DialogTitle>FONT SETTINGS</DialogTitle>
      <ValidatorForm onSubmit={onSubmit} id={FORM_ID}>
        <DialogContent>
          <Grid container spacing={4} alignItems="center">
            <Grid container item sm spacing={4}>
              <Grid item sm={6}>
                <TextComponentWrapper>
                  <AutocompleteValidatorComponent
                    value={currentSettings.fontId}
                    validators={[VALIDATORS_MAP.required]}
                    fieldName="fontId"
                    handleChange={handleChange}
                    selectList={fontList}
                    label={getTComponent('font')}
                    itemsNamePath="name"
                  />
                </TextComponentWrapper>
              </Grid>
              <Grid item sm={6}>
                <TextComponentWrapper>
                  <AutocompleteValidatorComponent
                    value={currentSettings.fontWeight}
                    validators={[VALIDATORS_MAP.required]}
                    fieldName="fontWeight"
                    handleChange={handleChange}
                    selectList={fontStylesList}
                    label={getTComponent('style')}
                    itemsNamePath="id"
                  />
                </TextComponentWrapper>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <TextComponentWrapper>
                <TextValidatorComponent
                  value={currentSettings.fontSize}
                  validators={[
                    VALIDATORS_MAP.required,
                    VALIDATORS_MAP.minNumber(FONT_SETTINGS.fontSize.min),
                    VALIDATORS_MAP.maxNumber(FONT_SETTINGS.fontSize.max),
                    VALIDATORS_MAP.isPositive,
                  ]}
                  label={getTComponent('size')}
                  type="number"
                  fieldName="fontSize"
                  handleChange={handleChange}
                  inputProps={{
                    min: FONT_SETTINGS.fontSize.min,
                    max: FONT_SETTINGS.fontSize.max,
                  }}
                />
              </TextComponentWrapper>
            </Grid>
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item sm={3}>
              <TextComponentWrapper>
                <TextValidatorComponent
                  value={currentSettings.letterSpacing}
                  validators={[
                    VALIDATORS_MAP.required,
                    VALIDATORS_MAP.minNumber(FONT_SETTINGS.letterSpacing.min),
                    VALIDATORS_MAP.maxNumber(FONT_SETTINGS.letterSpacing.max),
                  ]}
                  label={getTComponent('letterSpacing')}
                  type="number"
                  fieldName="letterSpacing"
                  handleChange={handleChange}
                  inputProps={{
                    step: FONT_SETTINGS.letterSpacing.step,
                    min: FONT_SETTINGS.letterSpacing.min,
                    max: FONT_SETTINGS.letterSpacing.max,
                  }}
                />
              </TextComponentWrapper>
            </Grid>
            <Grid item sm={3}>
              <TextComponentWrapper>
                <TextValidatorComponent
                  value={currentSettings.lineHeight}
                  validators={[
                    VALIDATORS_MAP.required,
                    VALIDATORS_MAP.minNumber(FONT_SETTINGS.lineHeight.min),
                    VALIDATORS_MAP.maxNumber(FONT_SETTINGS.lineHeight.max),
                    VALIDATORS_MAP.isPositive,
                  ]}
                  label={getTComponent('lineHeight')}
                  type="number"
                  fieldName="lineHeight"
                  handleChange={handleChange}
                  inputProps={{
                    min: FONT_SETTINGS.lineHeight.min,
                    max: FONT_SETTINGS.lineHeight.max,
                  }}
                />
              </TextComponentWrapper>
            </Grid>
            <Grid item sm={3} style={{ minHeight: '64px' }}>
              <ColorPicker setColor={setColor} color={{ hex: currentSettings.color || COLORS.white }} />
            </Grid>
            <Grid item sm={12}>
              <Grid container alignItems="center">
                <VisibilityOutlinedIcon fontSize="small" />
                <Box m={2}>
                  <Typography variant="h5">{getTComponent('preview')}</Typography>
                </Box>
              </Grid>
              <CustomFontWrapper font={currentSettings.font}>
                <FontSettingsEditDialogPreview settings={currentSettings} />
              </CustomFontWrapper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={handleClose} text={getTCommon('cancel')} />
          <ButtonComponent onClick={copyFontStyle} text={getTComponent('copyStyle')} className="right" />
          <ButtonComponent
            onClick={pasteFontStyle}
            disabled={isEmpty(savedSettings)}
            text={getTComponent('pasteStyle')}
          />
          <ButtonComponent type="submit" color="primary" text={getTCommon('save')} />
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

FontSettingsEditDialog.propTypes = {
  settings: FontSettingsPropTypes,
  handleClose: PropTypes.func.isRequired,
  handleSet: PropTypes.func.isRequired,
};

FontSettingsEditDialog.defaultProps = {
  settings: {},
};
