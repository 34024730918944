import axios from 'axios';

import config from 'config/Config';
import { DESCRIPTION_DIALOG_STORAGE_KEY, THEME_MODE_KEY } from 'constants/technical';
import { ROUTES } from 'routes/constants';
import {
  deepObjectKeysToCamelCase,
  deepObjectKeysToSnakeCase,
  getHeadersFromLocalStorage,
  clearLocalStorage,
} from 'utils';

export const api = axios.create({
  baseURL: config.api_host,
});

api.interceptors.request.use(request => ({
  ...request,
  headers: {
    ...request.headers,
    ...getHeadersFromLocalStorage(),
  },
  data: deepObjectKeysToSnakeCase(request.data),
  params: deepObjectKeysToSnakeCase(request.params),
}));

api.interceptors.response.use(
  response => ({
    ...response,
    data: deepObjectKeysToCamelCase(response.data),
    headers: deepObjectKeysToCamelCase(response.headers),
  }),
  error => {
    if (error?.response?.status === 401) {
      clearLocalStorage([DESCRIPTION_DIALOG_STORAGE_KEY, THEME_MODE_KEY]);
      if (window.location.pathname !== ROUTES.authSignIn) {
        window.location.replace(ROUTES.authSignIn);
      }
    }
    return Promise.reject(error);
  },
);
