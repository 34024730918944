import React, { useState, useEffect } from 'react';

import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ImageChooseComponent, LinkBehavior } from 'components';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { NAVIGATION_TYPE } from 'components/LinkBehavior/constants';
import { controlButtonModel } from 'models';
import { getSettingsWithNavigationValues } from 'utils';

import { ImageUploadCaption } from 'components/style';

const defaultControlButtonSettings = {
  imageSize: 100,
  controlButton: { id: null, imageName: '', url: '' },
  navigationValueSelect: null,
  navigationValueUrl: null,
  navigationType: NAVIGATION_TYPE.internal,
  openInNewPage: false,
};

export function ControlButtonSettings({ planogramVersion, handleServerError, setSettings, tabSettings, title }) {
  const [controlButtonSettings, setControlButtonSettings] = useState(defaultControlButtonSettings);

  useEffect(() => {
    const settings = getSettingsFromPlanogramVersion(planogramVersion);

    setControlButtonSettings(getSettingsWithNavigationValues(settings));
  }, [planogramVersion, tabSettings]);

  const getSettingsFromPlanogramVersion = version => {
    if (!tabSettings.initialSettings) {
      return tabSettings;
    }

    return {
      ...version?.defaultNavigation.planogramVersionControlButton,
      title: 'Control button',
    };
  };

  const handleChange = item => {
    setControlButtonSettings(prevState => ({
      ...prevState,
      ...item,
    }));
    setSettings(item);
  };

  const setImage = controlButton => handleChange({ controlButton });

  const getImages = params => controlButtonModel.getImages(params).catch(handleServerError);

  return (
    <>
      <Grid container spacing={6}>
        {title && (
          <Grid item sm={12} style={{ padding: '0 12px' }}>
            <Typography variant="h4" display="inline">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item sm={4} style={{ minWidth: '150px' }}>
          <ImageChooseComponent
            image={controlButtonSettings.controlButton}
            setImage={setImage}
            handleServerError={handleServerError}
            dialogTitle="Choose control button"
            getPictures={getImages}
            withDelete
          />
          <Box ml={-2}>
            <Typography variant="caption">
              <Checkbox
                color="primary"
                checked={controlButtonSettings.showShadow ?? false}
                onChange={e => handleChange({ showShadow: e.target.checked })}
              />
              <span>Show shadow</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={8}>
          <ImageUploadCaption>{IMAGE_UPLOAD_CAPTION.controlButton}</ImageUploadCaption>
        </Grid>
      </Grid>
    </>
  );
}

ControlButtonSettings.propTypes = {
  planogramVersion: PropTypes.shape({
    id: PropTypes.number,
    favicon: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
  }).isRequired,
  handleServerError: PropTypes.func.isRequired,
  title: PropTypes.string,
};
