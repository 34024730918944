import { AxiosError } from 'axios';
import { Dictionary } from 'lodash';

import {
  ActiveStorageBlob,
  DataServerErrors,
  ErrorType,
  ServerErrors,
  ServerErrorsArray,
  UploadDataSection,
} from 'types/common';
import { FileWithUploadData } from 'types/images';

export enum UploadActionTypes {
  ADD_UPLOAD = 'ADD_UPLOAD',
  COMPLETE_UPLOAD = 'COMPLETE_UPLOAD',
  FAIL_UPLOAD = 'FAIL_UPLOAD',
  UPDATE_UPLOAD = 'UPDATE_UPLOAD',
  RESET_ALL = 'RESET_ALL',
  HANDLE_LOADING_ERROR = 'HANDLE_LOADING_ERROR',
  HANDLE_UPLOAD_ERROR = 'HANDLE_UPLOAD_ERROR',
  RESET_ERRORS = 'RESET_ERRORS',
  SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS',
  RESET_UPLOAD_STATUSES = 'RESET_UPLOAD_STATUS',
}

export interface UploadInfo extends Partial<UploadDataSection> {
  id: string;
  name: string;
  section: string;
}

export interface UploadStatusStateType {
  uploads: UploadInfo[];
  delayResetIds: string[];
  errors: Dictionary<ErrorType<string>[]>;
  requestStatuses: Map<FileWithUploadData, string>;
}

export interface AddS3RequestParams {
  id: string;
  request: XMLHttpRequest;
}

export interface UpdateUploadParams extends AddS3RequestParams {
  loaded: number;
  total: number;
}

export interface UploadErrorParams {
  error: AxiosError<ServerErrorsArray<string> | ServerErrors<string[]>> & DataServerErrors<string[]>;
  file: FileWithUploadData;
  section: string;
}

export interface UploadStatusDispatchType {
  createUpload: (
    file: FileWithUploadData,
    section: string,
    cb: (error: DataServerErrors<ErrorType<string>[]> | null, blob: ActiveStorageBlob) => Promise<void>,
  ) => void;
  addUpload: (data: UploadInfo) => void;
  addS3Request: (data: AddS3RequestParams) => void;
  updateUpload: (data: UpdateUploadParams) => void;
  failUpload: (id: string) => void;
  completeUpload: () => void;
  resetAllUploads: () => void;
  resetErrors: () => void;
  setUploadStatus: (file: FileWithUploadData, status: string) => void;
  handleUploadError: (data: UploadErrorParams) => void;
  handleUploadRequest: (request: Promise<void>, file: FileWithUploadData, section: string) => void;
  resetUploadStatuses: () => void;
}
